<app-header></app-header>
<div class="container-fluid">
    <div class="col-sm-12" style="margin-top: 10px;">
        <div id="UserManagement" class="container">	
                <ul  class="nav nav-pills" style="border: 1px solid;">
                    <li class="active"><a  href="#listofforecast" data-toggle="tab">List of Forecast</a></li>
                    <li><a href="#listofusers" data-toggle="tab">List of Users </a></li>
                    <li><a href="#listofusersgroup" data-toggle="tab">List of UsersGroups </a></li>
                </ul>
                <div class="tab-content clearfix">
                    <div class="tab-pane active" id="listofforecast">
                        <app-list-user-forecast></app-list-user-forecast>
                    </div>
                    <div class="tab-pane" id="listofusers">
                        <app-list-users></app-list-users> 
                    </div>
                    <div class="tab-pane" id="listofusersgroup">
                        <app-listsubusers></app-listsubusers> 
                    </div>
                
                </div>
        </div>
    </div>
</div>