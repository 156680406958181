import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
 

@Injectable({
  providedIn: 'root'
})
export class CurrencyServiceService {

  private headers: Headers;
  constructor( private http: HttpClient) { }

    getPeriodTabViewCollection(ForecastNodeId):Observable<any>{
      return this.http.get(environment.url+"/api/PeriodTabView/PeriodViewCollections/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
    }
    
    CurrencyCollectionBudget(NodeId):Observable<any[]>{
      return this.http.get(environment.url+"/api/Currency/CurrencyCollectionBudget/"+NodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
    }

    saveCurrencyCollection(saveprofileValues) : Observable<any>{
      let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      let url = environment.url + "/api/Currency/SaveCurrencyModifer";
      return this.http.post(url, saveprofileValues, { headers: reqHeaders })
        .pipe(map(
          (response: any) => {
            return response;
          }
        ));
    }

   /**
    * Function For get Currency information
    * @param ForecastNodeId 
    * @returns 
    */
    getCurrencyCollections(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/Currency/CurrencyCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      AddCurrency(ForecastNodeId):Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/AddCurrencyCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      DeleteCurrency(ForecastNodeId, selectedIndex):Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/DeleteCurrencyCollection/"+ForecastNodeId+"/"+selectedIndex, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      DefaultCurrency(ForecastNodeId, selectedIndex):Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/DefaultCurrency/"+ForecastNodeId+"/"+selectedIndex, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      SetAllCurrency(NodeId, SetAllValue, startIndex, endIndex):Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SaveValueList/"+NodeId+"/"+SetAllValue+"/"+startIndex+"/"+endIndex, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

      setCurrencySymbol(NodeId, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SetCurrencySymbole/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      setCurrencyTla(NodeId, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SetCurrencyTla/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      setCurrencyName(NodeId, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SetCurrencyName/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      setCurrencyindex(NodeId, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SetCurrencyIndex/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      SaveValueListByMonth(NodeId, index, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/Currency/SaveValueListByMonth/"+NodeId+"/"+index+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

}
