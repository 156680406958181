import { Component, OnInit, ElementRef, ViewChild, Injector } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientsService } from '../../service/clients.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ClientModel } from '../../model/clientModel';
import { IgxNavigationDrawerComponent } from '@infragistics/igniteui-angular';
import { Userpermission } from 'src/app/model/userpermission';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  @ViewChild(IgxNavigationDrawerComponent, { static: true })
  public drawer: IgxNavigationDrawerComponent;

  clients: ClientModel[] = [];
  public horizontal = true;
  numberofClients = 0;
  isUpdate = false;
  NewClientForm = this.formBuilder.group({
    clientName: ['', [Validators.required]],
    description: ''
  });
  clinetNameInValid = false;
  postedsuccess = false;
  valueFromDirective = "test";
  buttonText = "Save";
  userEmailID =  "";


  constructor(private ClientsService: ClientsService, private _router: Router, private formBuilder: UntypedFormBuilder,
    public element: ElementRef, private SpinnerService: NgxSpinnerService, private injector: Injector) {

    let CurrentUserID = localStorage.getItem('CurrentUserID');
    this.userEmailID = localStorage.getItem("userEmailID");
    this.ClientsService.getUserPermission(CurrentUserID).subscribe(
      (response: Userpermission) => {
        localStorage.setItem('UserPermission', JSON.stringify(response))
      }
    )
  }


  ngOnInit(): void {
    this.SpinnerService.show();
    // let accessToken = localStorage.getItem('access_token');
    // let currentuserName = localStorage.getItem('CurrentUserName');
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    localStorage.removeItem("clientID");
    localStorage.removeItem('CurrentUserNodeId');
    // console.log(CurrentUserID);


    this.getAllClients();
    let forecastNodeid = localStorage.getItem('ForecastId');
    if (forecastNodeid != "0") {
      localStorage.setItem('ForecastId', '');
      localStorage.setItem('IsActualStarted', '');
    }
    localStorage.setItem("ForecastClientCountryCollection", null);
    localStorage.removeItem("treeview");
  }

  getAllClients() {
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    let CurrentUserEmail = localStorage.getItem("userEmailID");
    this.ClientsService.getClientCollection(CurrentUserID).subscribe(
      (response: ClientModel[]) => {
        this.SpinnerService.hide();
        this.clients = response;
        this.numberofClients = this.clients.length;
      },
      error => {
        this.SpinnerService.hide();
        throw error;
      }
    )
    // this.ClientsService.getCurrentUserInformation(CurrentUserEmail).subscribe(
    //   ((response:any)=>{
    //     console.log("getClient");
    //     if(response==null){
    //       this.ClientsService.getClientCollection(CurrentUserID).subscribe(
    //         (response: ClientModel[]) => {
    //           this.SpinnerService.hide();
    //           this.clients = response;
    //           this.numberofClients = this.clients.length;
    //         },
    //         error => {
    //           this.SpinnerService.hide();
    //           throw error;
    //         }
    //       )
    //     } else {
    //       this._router.navigateByUrl("/forecast-list");
    //     }
    //   })
    // )

  }
  NewClientSave() {
    this.isUpdate = false;
    this.NewClientForm = this.formBuilder.group({
      clientName: ['', [Validators.required]],
      description: '',
      id: '',
    });
    this.buttonText = "Save";
  }

  update($event: any) {
    this.valueFromDirective = $event;
    // console.log($event)
    // console.log("inside update")

  }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);

    if (this.NewClientForm.invalid) {
      this.clinetNameInValid = true;
      return;
    }
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    if (!this.isUpdate) {
      this.SpinnerService.show();
      this.clinetNameInValid = false;


      this.ClientsService.createnewClient(this.NewClientForm.value.clientName, this.NewClientForm.value.description, CurrentUserID).subscribe(
        (response: boolean) => {
          this.postedsuccess = response;
          if (this.postedsuccess == true) {
            notifier.showSuccess("Client Created Successfully.")
            this.drawer.close();
            this.NewClientForm.reset();
            this.SpinnerService.hide();
            this.getAllClients();
          }
        },
        error => {
          this.drawer.close();
          this.NewClientForm.reset();
          this.SpinnerService.hide();
          throw error;
        }
      )
    } else {
      this.ClientsService.UpdateClient(this.NewClientForm.value.clientName, this.NewClientForm.value.description, CurrentUserID, this.NewClientForm.value.id).subscribe(
        (response: boolean) => {
          this.postedsuccess = response;
          console.log("res", response);
          if (this.postedsuccess == true) {
            notifier.showSuccess("Client updated successfully.")
            this.drawer.close();
            this.NewClientForm.reset();
            this.SpinnerService.hide();
            this.getAllClients();
          }
        },
        error => {
          this.drawer.close();
          this.NewClientForm.reset();
          this.SpinnerService.hide();
          throw error;
        }
      )
    }
  }
  editClient(client) {
    this.isUpdate = true;
    this.buttonText = "Update";

    this.NewClientForm = this.formBuilder.group({
      clientName: [client.clientName, [Validators.required]],
      description: client.description,
      id: client.id,
    });
    console.log("editClient", client, this.NewClientForm);
  }
  deleteClient(clientID, DeleteClientForm) {
    const notifier = this.injector.get(NotificationService);
    this.SpinnerService.show();
    this.ClientsService.deleteClient(clientID).subscribe(
      (response: any) => {
        if (response) {
          this.SpinnerService.hide();
          DeleteClientForm.close();
          notifier.showSuccess("Client Deleted Successfully.")
          this.getAllClients();
        }
      })
  }
  openforecastlist(clientID, clientName) {
    this.SpinnerService.show();
    let username = localStorage.getItem('CurrentUserName');
    localStorage.setItem("clientName", clientName);
    localStorage.setItem("clientID", clientID);
    this.ClientsService.navigateToClient(clientID, username).subscribe(
      (response: any) => {
        // console.log("response" + response);
        if (response > 0) {
          this.SpinnerService.hide();
          localStorage.setItem('CurrentUserNodeId', response);
          this._router.navigateByUrl('/forecast-list');
        }
        // console.log(response);
      }
    )
  }
}
