import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Navigate } from '@infragistics/igniteui-angular/lib/drop-down/drop-down.common';
import { Injector } from '@angular/core';
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private injector: Injector) {

  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let error: any
    return next.handle(request).pipe(
      catchError((err) => {
        error = (err && err.error && err.error.message) || err.statusText;
        if (err.status === 401) {
          this.authService.logoutUnAutherised();
          this.router.navigate(['login'], {
            queryParams: { returnUrl: this.router.routerState.snapshot.url },
          });
        }
        if (err.status === 544) {
          error = "Access Denied. Please check your license or contact support.";
        }
        if (err.status === 555) {
          error = "License Expired. Please check your license or contact support.";
        }
        // if (!environment.production) {
        //   console.error(err);
        // }
        console.log("error", err);
        if (err.statusText != "OK")
          return throwError(error);
        else
          return null;
      })
    );
  }
}
