import { Component, OnInit, Input, ViewChild, Output, EventEmitter, } from '@angular/core';
import { TreeviewService } from 'src/app/service/treeview.service';
import { NgxSpinnerService } from "ngx-spinner";
import { EmployeeGroupService } from 'src/app/service/EmployeeGroupService/employee-group.service';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import * as $ from 'jquery';
// import { ok } from 'assert';
import { BonusesComponent } from '../bonuses.component';

@Component({
  selector: 'app-commombonuses',
  templateUrl: './commombonuses.component.html',
  styleUrls: ['./commombonuses.component.scss']
})

export class CommombonusesComponent {


  @Input() valuelistdata;
  @Input() AllCommonDisplayBudgetData;
  @Input() valueListDerrivation;
  @Input() ValueListMonthDisplay;
  @Input() PeriodtabViewData;
  @Input() startIndex;
  @Output() recordModifiedEvent = new EventEmitter<recordSaveModel>();
  darrivationnode: any;
  inputdarrivation: any;
  displayvaluelistdata: any = [];
  displaydarrivation: any = [];
  sectiontreeview: any;
  displaynodevisehtml: any;
  months = [];
  getProfileCollection = [];
  addeddata: boolean;
  selectedProfile: any;
  selectedNodeIds: any;
  percentagevaluearray: any;
  nodarrivationarray = [];
  darrivationarray = [];
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedloanTakenRecord: any = [];
  loantakenarray: any = {}
  additionvaluearray: any
  DerivationOptions: any;
  stockcomponent: any;
  SubDerrivationOptions: any;
  ProfileCollectionBool: boolean;
  ValidDerivationSubTypes = [];
  ValidDerivationSubTypesName = [];
  derivationSubType: any;
  CommonHtmlArrayResult: any;
  PeriodtabViewResults: any = [];
  BonusesCommisionStartIndex = 12;
  BonusesCommisionEndIndex = 23;
  bonusrecordModifiedModel: recordSaveModel;
  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  constructor(
    private TreeviewServices: TreeviewService,
    private SpinnerService: NgxSpinnerService,
    private employeeGroupService: EmployeeGroupService,
    private BonusesComponent: BonusesComponent) {
    this.activeId = localStorage.getItem('ForecastId');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  @ViewChild('tree') tree;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async ngOnChanges() {
    let listarray = [];
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.selectedProfile = '';
    this.sectiontreeview = '';
    this.inputdarrivation = '';
    this.addeddata = false;
    this.DerivationOptions = '';
    this.SubDerrivationOptions = [];
    this.ValidDerivationSubTypes = [];
    this.ValidDerivationSubTypesName = [];
    this.derivationSubType = null;
    this.displaynodevisehtml = this.valuelistdata;
    if (this.valuelistdata != null && this.startIndex != undefined) {
      this.BonusesCommisionStartIndex = parseInt(this.startIndex) * 12;
      this.BonusesCommisionEndIndex = this.BonusesCommisionStartIndex + 12;
      this.BonusesCommisionStartIndex = this.BonusesCommisionStartIndex;
      this.BonusesCommisionEndIndex = this.BonusesCommisionEndIndex;
      let NodeId = this.displaynodevisehtml.recordNodeId;
      let DerivationNodeId = this.displaynodevisehtml.derivationType;
      if (this.valueListDerrivation == undefined) {
        this.PeriodtabViewResults = this.PeriodtabViewData;
        //this.displayvaluelistdata.push(this.valuelistdata);
        this.ValueListMonthDisplay = this.ValueListMonthDisplay;
        let valueNodeId = this.displaynodevisehtml.valuesNodeId;
        let totaldisplayvalue = 0;
        this.nodarrivationarray = [];

        this.displaynodevisehtml.bonusCommisionValuesArray.forEach((valuesarraylist, index) => {
          this.displaynodevisehtml.opening = this.displaynodevisehtml.opening;
          valuesarraylist = this.DisplayArray(valuesarraylist, this.displaynodevisehtml.display);

          if (Math.sign(valuesarraylist) == -1) {
            let absValue = Math.abs(valuesarraylist);
            let realvalue = "(" + absValue + ")";
            this.nodarrivationarray.push(realvalue);
          } else {
            this.nodarrivationarray.push(valuesarraylist);
          }
          //totaldisplayvalue += valuesarraylist;
        });
        let datastartIndex = this.startIndex * 12;
        let dataEndIndex = datastartIndex + 12;
        for (let t = datastartIndex; t < dataEndIndex; t++) {
          totaldisplayvalue += this.displaynodevisehtml.bonusCommisionValuesArray[t];
        }
        this.valuelistdata['total'] = totaldisplayvalue;

        this.displaynodevisehtml.valuesArray = [];
        this.displaynodevisehtml.bonusCommisionValuesArray = this.nodarrivationarray;
        this.displaynodevisehtml.valuesArray = this.displaynodevisehtml.bonusCommisionValuesArray
        this.displaydarrivation.push(this.valueListDerrivation);
        this.displayvaluelistdata.push(this.displaynodevisehtml);
        console.log(this.displayvaluelistdata);

      }



    }

  }

  DisplayArray(data, Display) {
    let result = data;
    switch (Display) {
      case 2:
        if (result < 0)
          result = 0;
        break;
      case 3:
        if (result > 0)
          result = 0;
        break;
      case 4:
        result = -result;
        break;
      case 5:
        if (result > 0)
          result = 0;
        else
          result *= -1;
        break;
      case 6:
        if (result < 0)
          result = 0;
        else
          result *= -1;
        break;
      case 7:
        result = Math.abs(result);
        break;
      case 8:
        result = -Math.abs(result);
        break;
      case 9:
        result *= 100;
        break;
    }
    return result;
  }
  IsEnabled(Index, ValueList) {

    if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget)
      return ValueList.actualsMode == this.ActualsMode.Enterable;
    else
      return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
  }

  IsActual(Index, ValueList) {
    return Index <= ValueList.currentActualsIndex;
  }

  FontWight(Index, ValueList) {

    if (this.IsActual(Index, ValueList)) {
      if (ValueList.actualsMode >= this.ActualsMode.DerivedAsBudget)
        return "Bold";
      else if (ValueList.actualsMode == this.ActualsMode.Hidden)
        return "Thin";
    }
    return "Normal";
  }

  IsVisiable(Index, ValueList) {
    return Index >= ValueList.startIndex && Index <= ValueList.endIndex && (ValueList.actualsMode > this.ActualsMode.Hidden || !this.IsActual(Index, ValueList));
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */

  NoderrivationMonthCalculation(eventname, budgetdata, $event, displaydata, Isdarrivation, valuelistindex) {
    //console.log("NoderrivationMonthCalculation");
    if ($event.target.value == '') {
      $event.target.value = 0;
    }
    // this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, valuelistindex);
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
      if (this.check(budgetdata.valuesArray[loopindexstart].toString()) == true) {
        let data = budgetdata.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
        totalgetelemtbyclassnamearray.value = parseFloat(data);
        total += parseFloat(data);
        resultarray.push(parseFloat(data).toFixed(2));
      } else {
        totalgetelemtbyclassnamearray.value = parseFloat((budgetdata.valuesArray[loopindexstart].toString()).replaceAll(',', ''));
        total += parseFloat((budgetdata.valuesArray[loopindexstart].toString()).replaceAll(',', ''));
        resultarray.push(parseFloat((budgetdata.valuesArray[loopindexstart].toString()).replaceAll(',', '')).toFixed(2))
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    let singleBonus = new recordSaveModel();
    singleBonus.recordNodeID = budgetdata.recordNodeId;
    let ValuesArray = [];
    resultarray.forEach((element, index) => {
      let ArrayValue = {
        index: index,
        value: element.toString()
      }
      ValuesArray.push(ArrayValue);
    });
    let savingModelBudgetData = [{
      hasLoanOtherOptions: false,
      isDerivation: false,
      isNodeSelection: false,
      loanCalculationModels: null,
      selectedNodes: [],
      unselectedNodes: [],
      valuelist: ValuesArray,
      valuenodeId: budgetdata.valuesNodeId
    }]
    singleBonus.budgetData = savingModelBudgetData;
    let JsonArray = {
      "setCommonBonusmodel": singleBonus
    }
    localStorage.setItem("setEmployeeCommonBonus", JSON.stringify(JsonArray));
    //this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
  }

  noDarivationsetall(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    //console.log("noDarivationsetall");
    var decimalvalue = $event.target.value.replaceAll(',', '');
    if (decimalvalue == null || decimalvalue == "") {
      return;
    }
    var total = 0.00;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
            budgetdata.valuesArray[loopindexstart] = parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
          }
        }
        else {
          if (parseFloat((decimalvalue)) != undefined) {
            total += parseFloat((decimalvalue));
            resultarray.push(parseFloat((decimalvalue)));
            budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
            totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
            //this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
            //this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
          }
        }
      } else {
        if (parseFloat((decimalvalue)) != undefined) {
          total += parseFloat((decimalvalue));
          resultarray.push(parseFloat((decimalvalue)))
          totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
          budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
          //this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
          //this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
        }
      }
    }
    $event.target.value = "";
    let singleBonus = new recordSaveModel();
    singleBonus.recordNodeID = budgetdata.recordNodeId;
    let ValuesArray = [];
    resultarray.forEach((element, index) => {
      let ArrayValue = {
        index: index,
        value: element.toString()
      }
      ValuesArray.push(ArrayValue);
    });
    let savingModelBudgetData = [{
      hasLoanOtherOptions: false,
      isDerivation: false,
      isNodeSelection: false,
      loanCalculationModels: null,
      selectedNodes: [],
      unselectedNodes: [],
      valuelist: ValuesArray,
      valuenodeId: budgetdata.valuesNodeId
    }]
    singleBonus.budgetData = savingModelBudgetData;
    let JsonArray = {
      "setCommonBonusmodel": singleBonus
    }
    localStorage.setItem("setEmployeeCommonBonus", JSON.stringify(JsonArray));
    // var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    // inputValue.value = this.converttoDisplayValue(total.toString());
  }

  modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, valuelistindex) {
    if (localStorage.getItem("recordModified") != undefined || localStorage.getItem("recordModified") != null)
      this.bonusrecordModifiedModel = JSON.parse(localStorage.getItem("recordModified"));
    if (this.bonusrecordModifiedModel == undefined || this.bonusrecordModifiedModel == null) {
      this.bonusrecordModifiedModel = new recordSaveModel();
      console.log(displaydata[0]);
      this.bonusrecordModifiedModel.recordNodeID = displaydata[0].recordNodeId;
    }
    let budgetDataAvailable: boolean = false;
    this.bonusrecordModifiedModel.budgetData.forEach(x => {
      if (x.valuenodeId == budgetdata.nodeId)
        budgetDataAvailable = true;
    })
    if (budgetDataAvailable === false) {
      let budgetData = new budgetdatamodified();
      budgetData.valuenodeId = budgetdata.nodeId;
      budgetData.isDerivation = Isdarrivation;
      if (budgetdata.englishLabel == 'Sub Result') {
        budgetData.valuenodeId = displaydata[0].valuesnodeId;
      }
      if (budgetData.valuenodeId == undefined) {
        budgetData.valuenodeId = displaydata[0].valuesNodeId;
      }
      this.bonusrecordModifiedModel.budgetData.push(budgetData);
    }

    if (this.bonusrecordModifiedModel.recordNodeID == displaydata[0].recordNodeId) {
      let budgetData: budgetdatamodified;
      let valuenodeId = budgetdata.nodeId;
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = displaydata[0].valuesNodeId;
      }
      if (valuenodeId == undefined) {
        valuenodeId = displaydata[0].valuesNodeId;
      }
      this.bonusrecordModifiedModel.budgetData.forEach(function (element) {
        if (element.valuenodeId == valuenodeId) {
          budgetData = element;
        }
      })
      if (budgetData != null) {
        let value: string = budgetdata.valuesArray[valuelistindex].replaceAll(',', '');
        if (value == '') {
          value = '0.0';
        }
        let convertInt = parseFloat(value).toFixed(2);
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '');
          convertInt = parseFloat(data).toFixed(2)
        }
        if (convertInt != budgetdata.bonusCommisionValuesArray[valuelistindex]) {
          this.removeValueModified(budgetData, valuelistindex);
          let valuelistMod = new valuelistModified();
          valuelistMod.index = valuelistindex;
          valuelistMod.value = convertInt.toString();
          budgetData.valuelist.push(valuelistMod);
          this.bonusrecordModifiedModel.budgetData.forEach(x => {
            if (x.valuenodeId == valuenodeId) {
              x = budgetData;
            }
          })
        }
      }
      localStorage.removeItem("recordModified")
      localStorage.setItem("recordModified", JSON.stringify(this.bonusrecordModifiedModel));
    }
  }

  removeValueModified(budgetData, index) {
    var valueListItem = budgetData.valuelist.findIndex(x => x.index == index);
    if (valueListItem != -1) {
      budgetData.valuelist.splice(valueListItem, 1);
    }
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    let stringArray = inputValue.id.split("-");
    if (stringArray[0] == 'Percentages') {
      number = 99;
    }
    if (eventValue != '') {
      if (eventValue == '-') {
        return true;
      }
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        eventValue = eventValue.replaceAll("-", '');
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      let values: any = this.displayvaluelistdata;
      if (values[0].nodeType == 60031) {
        if (evt.keyCode == 46) {
          return true;
        }
      }

      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  decimalvaluebeforeDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.'));
        if (afterDot.length == 0) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  converttoDisplayValue(decimalValue) {
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }
  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // console.log("validatebynumberFormate", validatebynumberFormate);
      let values: any = this.displayvaluelistdata;
      if (values[0].nodeType == 60031) {
        if (validatebynumberFormate == '.') {
          $event.target.value = "0.";
        }
      } else {
        if (validatebynumberFormate == '.') {
          $event.target.value = "0.";
        } else {
          $event.target.value = validatebynumberFormate;
        }
      }

    }
  }

}
