<!-- <div class="content-wrap displaydrawer" igxLayout>

  <main igxFlex
    [ngClass]="{mainSibebarcontextMenuActive: displaySubmenustyle!='', mainSibebarcontextMenuDiplayInActive: displaySubmenustyle==''}">
    <igx-card *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name != 'Settings'"
      style="margin: 0 0 0 40%;height: 83vh;background: rgb(234 238 239) !important;  position: relative;" elevated>
      <i _ngcontent-foc-c126="" class="fa fa-remove" id="ReportSidebarMenuClose" (click)="sidebarmenuContain()"></i>
      <div class='section__container--wide' #scrollDemo>
        <div class="row" style="padding: 22px;">
          <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12">
            <div *ngIf="result.callback.active=='true' && selected.name != 'Settings'">
              <igx-card class="col-sm-12" elevated>
                <div class="displaymenurightsidebar">
                  <div class="iconrightsidebar">
                    <igx-icon style="font-size:30px;margin-top: 20px;">{{result.icon}}</igx-icon>
                  </div>
                  <div class="titlerightsidebar">
                    <span class="displaysubmenuDrawer" *ngIf="result.callback.name !='showWhatIf'">
                      <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                        class="rightsidebarMenu">{{ result.name }}</p>
                      <input type="file" style="display:none" id="RecordlistImport"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        (change)="RecordlistImport($event)">
                      <input type="file" style="display:none" id="RecordDetailsImport"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        (change)="RecordDetailsImport($event)">
                    </span>
                    <span class="displaysubmenuDrawer" *ngIf="result.callback.name =='showWhatIf'">
                      <p> <input type="checkbox" [checked]="showWhatIfbool=='true'" class="{{showWhatIfbool}} col-sm-3"
                          style="height: 24px;margin: 1px 0 0 -12px;" (change)="showWhatIf($event)" /><span>{{result.name}}</span></p>
                    </span>
                  </div>
                </div> 
              </igx-card>
            </div>
          </div>
        </div>
      </div>
    </igx-card>
    <div *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name == 'Settings'"
      style="margin: 0 0 0 40%;height: 716px;background: rgb(234 238 239) !important;    position: relative;">
      <i _ngcontent-foc-c126="" class="fa fa-remove" (click)="sidebarmenuContain()"></i>
      <div class='section__container--wide' #scrollDemo>
        <div class="row" style="padding: 22px;">
          <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12 ">
            <igx-card class="col-sm-12" elevated>
              <div class="displaymenurightsidebar">
                <div class="iconrightsidebar">
                  <igx-icon style="font-size:30px;">{{result.icon}}</igx-icon>
                </div>
                <div class="titlerightsidebar">
                  <span class="displaysubmenuDrawer">
                    <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                      class="rightsidebarMenu">{{ result.name }}</p>
                  </span>
                </div>
              </div>
            </igx-card>
          </div>
        </div>
      </div>
    </div>
  </main>
  <igx-nav-drawer id="navigation" [pin]="true" [pinThreshold]="0" class="customthem">
    <ng-template igxDrawerMini>
      <div class="drawerSetup">
        <span igxIconButton="flat" igxToggleAction="navigation" class="togglemenudrawer" (click)="showDrawer()">
          <igx-icon family="material" class="materialsidebarfontmenu">menu</igx-icon>
        </span>
        <span *ngFor="let item of NavitemSubmenu" class="drawerpaddingspan" igxDrawerItem
          [active]="item.name === selected" igxRipple (click)="navigate(item)">
          <igx-icon family="material" data-toggle="tooltip" igxToggleAction="navigation" class="togglemenudrawer"
            (click)="mouseOverEvent()" data-placement="top" [roundShape]="true" class="materialsidebarfont">
            <span *ngIf="item.icon != 'question'" class="materialsidebarfont">{{ item.icon}}</span>
          </igx-icon>
        </span>
      </div>
    </ng-template>
    <ng-template igxDrawer>
      <div class="drawerSetup">
        <span *ngIf="selected.length==0" igxIconButton="flat" igxToggleAction="navigation"
          class="togglemenudrawer displayclosingmenu" (click)="hideDrawer()">
          <igx-icon family="material" (click)="hideDrawer()" class="materialsidebarfontmenu  ">close</igx-icon>
        </span>
        <span *ngIf="selected.length>=0" igxIconButton="flat" igxToggleAction="navigation">
          <igx-icon family="material" class="materialsidebarfontmenu"></igx-icon>
        </span>
        <span *ngFor="let item of NavitemSubmenu" class="drawerpaddingspan" igxDrawerItem
          [active]="item.name === selected" igxRipple (click)="navigate(item)">
          <igx-icon family="material" class="materialsidebarfont">{{ item.icon }}</igx-icon>
          <span style="font-size: 16px;margin: 14px 0 0 20px;" *ngIf="item.callback!=''"
            (click)="this[RightsidebarmenClickEvent](item.callback.perameter, item)">
            {{ item.name }}
          </span>
          <span *ngIf="item.callback==''" style="font-size: 16px;margin: 14px 0 0 20px;">
            {{ item.name }}
          </span>
        </span>
      </div>
    </ng-template>
  </igx-nav-drawer>
</div> -->



<div class="">
  <ul _ngcontent-ng-c315163580="" class="nav nav-tabs" style="width: 102%;background: rgb(24 145 255);;margin: 0 0 0 -12px;height: 42px;display: inline-block;">
    <li *ngFor="let item of NavitemSubmenu" 
    [active]="item.name === selected" 
    [ngClass]="{ActiveMenu: selected.length>0 && selected[0].callback=='' && item.name == selected[0].name  }" 
     (click)="navigate(item)" style="padding: 5px;" >
     <span *ngIf="item.displaysubmenu.length>0">
     <igx-icon family="material" data-toggle="tooltip" igxToggleAction="navigation" class="togglemenudrawer"
     (click)="mouseOverEvent()" data-placement="top" [roundShape]="true" class="materialsidebarfont">
     <span *ngIf="item.icon != 'question'" class="materialsidebarfont">{{ item.icon}}</span>
    </igx-icon>
      <button class="button" #button igxButton="contained" style="background: #1891ff;box-shadow: none;" [igxToggleAction]="topbarmenuDropDown" 
      [igxDropDownItemNavigation]="topbarmenuDropDown" >
        {{item.name}}<igx-icon fontSet="material" style="font-size: 20px;" >expand_more</igx-icon>
    </button>
    <igx-drop-down #topbarmenuDropDown [height]="'auto'" [class]="RightsidebarMenuDropDown" [width]="'300px'" style="margin-top: 10% !important;" >
      <div class="" >
              <div *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name != 'Settings'" style="height: 150px;" >
                <div class="arrowDownMenu" 
                style="position: absolute;content: '';width: 16px;height: 16px;background-color: #1891ff !important;top: -11px;left: 1.5rem;transform: rotate(45deg);z-index: 9999;"></div>
                <div class="row" style="padding: 0px; margin-top: 10%;" >
                  <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12">
                    <div *ngIf="result.callback.active=='true' && selected.name != 'Settings'">
                        <div class="displaymenurightsidebar" style="padding: 4px 0 8px 0;cursor: pointer;">
                          <div class="iconrightsidebar col-sm-3">
                            <igx-icon style="font-size:20px;">{{result.icon}}</igx-icon>
                          </div>
                          <div class="titlerightsidebar col-sm-8" style="padding: 0px;">
                            <span class="displaysubmenuDrawer" *ngIf="result.callback.name !='showWhatIf'">
                              <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                                class="rightsidebarMenu" style="color: black !important;">{{ result.name }}</p>
                              <input type="file" style="display:none" id="RecordlistImport"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                (change)="RecordlistImport($event)">
                              <input type="file" style="display:none" id="RecordDetailsImport"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                (change)="RecordDetailsImport($event)">
                            </span>
                            <span class="displaysubmenuDrawer" *ngIf="result.callback.name =='showWhatIf'">
                              <p> <input type="checkbox" [checked]="showWhatIfbool=='true'" class="{{showWhatIfbool}} col-sm-3"
                                  style="height: 24px;margin: 1px 0 0 -12px;" (change)="showWhatIf($event)" /><span style="color: black !important;">{{result.name}}</span></p>
                            </span>
                          </div>
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name == 'Settings'" style="height: 450px;">
                <div class="arrowDownMenu" 
                style="position: absolute;content: '';width: 16px;height: 16px;background-color: #1891ff !important;top: -11px;left: 1.5rem;transform: rotate(45deg);z-index: 9999;"></div>
                <div class="row" style="padding: 0px; margin-top: 10%;">
                  <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12">
                    <igx-card class="col-sm-12" style="border: none !important;">
                      <div class="displaymenurightsidebar" style="padding: 4px 0 8px 0;cursor: pointer;">
                        <div class="iconrightsidebar col-sm-2" style="padding: 0px;">
                          <igx-icon style="font-size:20px;">{{result.icon}}</igx-icon>
                        </div>
                        <div class="titlerightsidebar col-sm-8" style="padding: 0px;" >
                          <span class="displaysubmenuDrawer">
                            <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                              class="rightsidebarMenu" style="color: black !important;">{{ result.name }}</p>
                          </span>
                        </div>
                      </div>
                    </igx-card>
                  </div>
                </div>
              </div>
          <!-- </igx-drop-down-item> -->
          <igx-divider></igx-divider> 
      </div>
    </igx-drop-down>
    </span>
    <span *ngIf="item.displaysubmenu.length==0">
      <igx-icon family="material" data-toggle="tooltip" igxToggleAction="navigation" class="togglemenudrawer"
      (click)="mouseOverEvent()" data-placement="top" [roundShape]="true" class="materialsidebarfont">
      <span *ngIf="item.icon != 'question'" class="materialsidebarfont">{{ item.icon}}</span>
     </igx-icon>
       <button class="button button igx-button igx-button--contained" 
       *ngIf="item.callback!=''" (click)="this[RightsidebarmenClickEvent](item.callback.perameter, item)" style="background: rgb(24, 145, 255); box-shadow: none; --component-size: var(--ig-size, var(--ig-size-large)); background: #1891ff; box-shadow: none;">
         {{item.name}}
     </button>
     </span>
    </li>
  </ul>
  <!-- Old Code-->
    <!-- <igx-nav-drawer id="navigation" [pin]="true" [pinThreshold]="0" class="" style="flex-basis: auto;display: inline-block;width: 101% !important;order: 0;border-bottom: 2px solid #cccccc;margin: 0 0 0;">
      <ng-template igxDrawerMini>
        <div class="">
          <ul _ngcontent-ng-c315163580="" class="nav nav-tabs" style="width: 102%;background: rgb(24 145 255);;margin: 0 0 0 -12px;height: 42px;display: inline-block;">
            <li *ngFor="let item of NavitemSubmenu" class="drawerpaddingspan" igxDrawerItem
            [active]="item.name === selected" 
            [ngClass]="{ActiveMenu: selected.length>0 && selected[0].callback=='' && item.name == selected[0].name  }" 
            igxRipple (click)="navigate(item)" style="color: white !important;">
            <igx-icon family="material" data-toggle="tooltip" igxToggleAction="navigation" class="togglemenudrawer"
              (click)="mouseOverEvent()" data-placement="top" [roundShape]="true" class="materialsidebarfont">
              <span *ngIf="item.icon != 'question'" class="materialsidebarfont">{{ item.icon}}</span>
            </igx-icon>
            <span  class="materialsidebarfont" style="font-size: 15px;margin: -3px 0 0 8px;">{{ item.name}}</span>
            <i _ngcontent-foc-c126="" class="fa fa-caret-down" style="margin: 0 0 0 5px;"></i>
            <main igxFlex
              *ngIf="selected.length>0 && selected[0].callback==''"
              [ngClass]="{mainSibebarcontextMenuActive: displaySubmenustyle!='', 
              mainSibebarcontextMenuDiplayInActive: displaySubmenustyle=='', 'hide': item.name != selected[0].name }" 
              style="flex: 1 1 auto;order: 0;position: fixed !important;z-index: 99999999 !important;width: 14%;margin: 0px 0 0 -46px;" 
              >
              <igx-card *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name != 'Settings'"
                style="height: auto;background: #e0e3eb;position: relative;margin: 9px 0 0 0;border: 2px solid #1891ff;" elevated>
                <div class='section__container--wide' #scrollDemo>
                  <div class="row" style="padding: 0px;">
                    <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12">
                      <div *ngIf="result.callback.active=='true' && selected.name != 'Settings'">
                          <div class="displaymenurightsidebar" style="padding: 0px;">
                            <div class="iconrightsidebar col-sm-3">
                              <igx-icon style="font-size:30px;">{{result.icon}}</igx-icon>
                            </div>
                            <div class="titlerightsidebar col-sm-7" style="padding: 0px;">
                              <span class="displaysubmenuDrawer" *ngIf="result.callback.name !='showWhatIf'">
                                <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                                  class="rightsidebarMenu" style="color: black !important;">{{ result.name }}</p>
                                <input type="file" style="display:none" id="RecordlistImport"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  (change)="RecordlistImport($event)">
                                <input type="file" style="display:none" id="RecordDetailsImport"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  (change)="RecordDetailsImport($event)">
                              </span>
                              <span class="displaysubmenuDrawer" *ngIf="result.callback.name =='showWhatIf'">
                                <p> <input type="checkbox" [checked]="showWhatIfbool=='true'" class="{{showWhatIfbool}} col-sm-3"
                                    style="height: 24px;margin: 1px 0 0 -12px;" (change)="showWhatIf($event)" /><span style="color: black !important;">{{result.name}}</span></p>
                              </span>
                            </div>
                          </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </igx-card>
              <div *ngIf="selected.length>0 && selected[0].callback=='' && selected[0].name == 'Settings'"
                style="height: auto;background: #e0e3eb;position: relative;margin: 9px 0 0 0;border: 2px solid #1891ff;">
                <div class='section__container--wide' #scrollDemo>
                  <div class="row" style="padding: 0px;">
                    <div *ngFor="let result of selected[0].displaysubmenu" class="displaysubmenuDrawer col-sm-12">
                      <igx-card class="col-sm-12" style="border: none !important;">
                        <div class="displaymenurightsidebar" style="padding: 0px;">
                          <div class="iconrightsidebar col-sm-3" style="padding: 0px;">
                            <igx-icon style="font-size:30px;">{{result.icon}}</igx-icon>
                          </div>
                          <div class="titlerightsidebar col-sm-8" style="padding: 0px;" >
                            <span class="displaysubmenuDrawer">
                              <p (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)"
                                class="rightsidebarMenu" style="color: black !important;">{{ result.name }}</p>
                            </span>
                          </div>
                        </div>
                      </igx-card>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </li>
          </ul>
          
        </div>
      </ng-template>
    </igx-nav-drawer> -->

    <!--old code finish here-->
  
 </div>
<igx-dialog #SaveWhatIf [closeOnOutsideSelect]="false">
  <button class="SaveWhatIfEntry form-control hide" id="SaveWhatIfEntry" style="font-size: 15px;background: #cccccc;"
    (click)="SaveWhatIf.open()"></button>
  <igx-dialog-title class="dialogtitle">
    <div class="title-container">
      <div class="dialog-title">Forecast 5</div>
    </div>
  </igx-dialog-title>
  <div class="content-savewhatIf">
    <h2> Are you sure you want to save all the
      What If Values as Budget in a new Forecast</h2>
  </div>
  <div igxDialogActions>
    <button igxButton (click)="SaveWhatIf.close()" class="deleteCancelButtonColor">CANCEL</button>
    <button igxButton (click)="SaveWhatIfAsForecastEvent(SaveWhatIf)" class="OkSaveButtonColor">OK</button>
  </div>
</igx-dialog>
<igx-dialog #SaveWhatIfAsBudget [closeOnOutsideSelect]="false">
  <button class="SaveWhatIfAsBudget form-control hide" id="SaveWhatIfAsBudget"
    style="font-size: 15px;background: #cccccc;" (click)="SaveWhatIfAsBudget.open()"></button>
  <igx-dialog-title class="dialogtitle">
    <div class="title-container">
      <div class="dialog-title">Forecast 5</div>
    </div>
  </igx-dialog-title>
  <div class="content-savewhatIf">
    <h2> Are you sure you want to save all the What If Values as Budget? This can not be undone.</h2>
  </div>
  <div igxDialogActions>
    <button igxButton (click)="SaveWhatIfAsBudget.close()" class="deleteCancelButtonColor">CANCEL</button>
    <button igxButton (click)="SaveWhatIfAsBudgetForecastEvent(SaveWhatIfAsBudget)"
      class="OkSaveButtonColor">OK</button>
  </div>
  <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
    <button class="ResetActuals form-control hide" id="ResetActualsResponse"
      style="font-size: 15px;background: #cccccc;" (click)="ResetActualsResponse.open()">Auto Balance</button>
    <igx-dialog #ResetActualsResponse [closeOnOutsideSelect]="false">
      <div class="insidecontainer" style="width: 465px;height: 100px;">
        <igx-dialog-title class="dialogtitle">
          <div class="title-container">
            <div class="dialog-title">Reset to No Actuals</div>
          </div>
        </igx-dialog-title>
        <div class="Department row">
          <label class="col">Do you want to keep "actuals" as your budget?</label>
        </div>
      </div>
      <div igxDialogActions>
        <button igxButton (click)="this.ResetActualsResponseNo()" class="deleteCancelButtonColor">No</button>
        <button igxButton class="OkSaveButtonColor" (click)="this.ResetActualsResponseWithActuals()">Yes</button>
      </div>
    </igx-dialog>
  </div>
</igx-dialog>


