import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map, retry } from "rxjs/operators";
import { Treeview } from "./treeview";
import { Record } from '../model/record';
import { environment } from "../../environments/environment";
// import { ok } from 'assert';
import { data } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class StockComponentService {

  private headers: Headers;
  Treeviews: Treeview[] = [];

  constructor(private http: HttpClient) { }

  getAllStockComponentDetails(nodeId): Observable<[]> {
    return this.http.get(environment.url + "/api/forecast/StockComponentALL/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
   * Function For get defualt stock component by nodeId
   */
  getStockComponentDetail(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/stockComponent/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }

  /**
   * Function For add stock component
   */

  addStockComponent(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/stockcomponent/addcomponent/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          try {
            return response;
          } catch (error) {
            return error;
          }

        }
      ));
  }
  /**
   * Function For delete stock component
   */

  deleteStockComponent(nodeId, index): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/stockcomponent/deletecomponent/" + nodeId + "/" + index, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }


  getAllStockMadeComponentDetails(nodeId): Observable<[]> {
    return this.http.get(environment.url + "/api/forecast/StockMadeComponentALL/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
   * Function For get defualt stock component by nodeId
   */
  getStockMadeComponentDetail(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/stockMadeComponent/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }

  /**
   * Function For add stock component
   */

  addStockMadeComponent(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/addstockMadecomponent/addcomponent/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          try {
            return response;
          } catch (error) {
            return error;
          }

        }
      ));
  }
  /**
   * Function For delete stock component
   */

  deleteStockMadeComponent(nodeId, index): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/deletestockMadecomponent/deletecomponent/" + nodeId + "/" + index, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }
  ChangeStockComponentName(nodeId, value): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/ChangeStockComponentName/" + nodeId + "/" + value, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }
  

}
