<app-header></app-header>
<div class="EliminationHeader">
    <div style="width:90%; margin-top: 20px;">
        <h2 class="HeaderColor">Record Elimination</h2>
    </div>
    <div class="EliminationRecords">
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-toggle="tab" (click)="ProfitAndLoss()"
                    href="#profitAndLoss">Profit And Loss</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" (click)="BalanceSheettabClick()"
                    href="#BalanceSheet">BalanceSheet</a></li>
        </ul>
        <div class="tab-content">
            <div id="profitAndLoss" class="tab-pane fade in show" *ngIf="DisplayTreeProfitAndLoss=='Active'">
                <div class="row" style="height: 624px;overflow: auto; min-height: 620px;">
                    <div class="left-sidebar col-sm-12">
                        <table id="table" class="table" style="width: 100%;" *ngIf="this.pnlEliminationRecords!=''">
                            <!-- <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.profitAndLoss'>
                        </app-enter-actual-tree> -->
                            <div style="width: 100%; height: 200px;">
                                <igx-grid #grid1 [igxPreventDocumentScroll]="true" [data]="pnlEliminationRecords"
                                    height="600px" displayDensity="comfortable" [filterMode]="'excelStyleFilter'"
                                    [allowAdvancedFiltering]="true">
                                    <igx-paginator [perPage]="10"></igx-paginator>
                                    <igx-column dataType="boolean" field="isEliminated" header="Is Eliminated"
                                        width="5%">
                                        <ng-template igxCell let-cell="cell">
                                            <input type="checkbox" [ngModel]="cell.value"
                                                (ngModelChange)="cell.update($event)" />
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="recordName" header="Record" width="20%">
                                    </igx-column>
                                    <igx-column field="recordNodeid" header="RecordNodeid" [hidden]="true"></igx-column>
                                    <igx-column field="referenceForecasts" header="Source Forecasts"
                                        width="15%"></igx-column>
                                    <!-- <igx-column header="Values" headerClasses="myClass" width="100px" field="valueArray"
                                        sortable="true" [filterable]="false">
                                        <ng-template igxCell let-cell="cell">
                                            <div class="cell__inner">
                                                <div *ngFor="let a of cell.value">{{ a }}</div>
                                            </div>
                                        </ng-template>
                                    </igx-column> -->
                                    <igx-column *ngFor="let c of columnsArrayPL" field="valueArray" [header]="c.header"
                                        width="5%" [dataType]="c.type">
                                        <ng-template igxCell let-cell="cell">
                                            <div class="cell__inner">
                                                <div>{{ cell.value[c.count] }}</div>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                </igx-grid>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
            <div id="BalanceSheet" class="tab-pane fade in show" *ngIf="DisplayTreeBalanceSheet=='Active'">
                <div class="row" style="height: 616px;overflow: auto;width: 96.5%;min-height: 620px;">
                    <div class="left-sidebar col-sm-12">
                        <table id="table" class="table" style="width: 100%;" *ngIf="this.BLElimnationRecords!=''">
                            <!-- <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.BalanceSheet'>
                        </app-enter-actual-tree> -->
                            <div style="width: 100%; height: 200px;">
                                <igx-grid #grid2 [igxPreventDocumentScroll]="true" [data]="BLElimnationRecords"
                                    height="600px" displayDensity="comfortable" [filterMode]="'excelStyleFilter'"
                                    [allowAdvancedFiltering]="true">
                                    <igx-paginator [perPage]="10"></igx-paginator>
                                    <igx-column dataType="boolean" field="isEliminated" header="Is Eliminated"
                                        width="5%">
                                        <ng-template igxCell let-cell="cell">
                                            <input type="checkbox" [ngModel]="cell.value"
                                                (ngModelChange)="cell.update($event)" />
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="recordName" header="Record" width="20%">
                                    </igx-column>
                                    <igx-column field="recordNodeid" header="RecordNodeid" [hidden]="true"></igx-column>
                                    <igx-column field="referenceForecasts" header="Source Forecasts"
                                        width="25%"></igx-column>
                                    <igx-column *ngFor="let c of columnsArrayBS" field="valueArray" [header]="c.header"
                                        width="5%" [dataType]="c.type">
                                        <ng-template igxCell let-cell="cell">
                                            <div class="cell__inner">
                                                <div>{{ cell.value[c.count] }}</div>
                                            </div>
                                        </ng-template>
                                    </igx-column>


                                </igx-grid>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="EliminationButton">
        <button class="EliminateButton OkSaveButtonColor" (click)="EliminateRecords()">Eliminate</button>
        <button class="CancelButton deleteCancelButtonColor" (click)="CancelElimination()">Cancel</button>

    </div>
</div>


<!-- <div style="background-color: red;width: 100%; height: 200px;">
    <igx-grid #grid1 [igxPreventDocumentScroll]="true" [data]="pnlEliminationRecords" height="600px"
        displayDensity="comfortable" [filterMode]="'excelStyleFilter'" [allowAdvancedFiltering]="true">
        <igx-paginator [perPage]="10"></igx-paginator>
        <igx-column dataType="boolean" field="isEliminated" header="Is Eliminated" width="5%">
            <ng-template igxCell let-cell="cell">
                <input type="checkbox" [ngModel]="cell.value" (ngModelChange)="cell.update($event)" />
            </ng-template>
        </igx-column>
        <igx-column field="recordName" header="Record" width="20%">
        </igx-column>
        <igx-column field="recordNodeid" header="RecordNodeid" [hidden]="true"></igx-column>
        <igx-column field="referenceForecasts" header="Source Forecasts" width="30%"></igx-column>
        <igx-column header="Values" headerClasses="myClass" width="100px" field="valueArray" sortable="true"
            [filterable]="false">
            <ng-template igxCell let-cell="cell">
                <div class="cell__inner">
                    <div *ngFor="let a of cell.value">{{ a }}</div>
                </div>
            </ng-template>
        </igx-column>

     
</igx-grid>
</div> -->