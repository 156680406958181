import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { DetailModel } from '../detailModel/detail-model';
import { environment } from "../../../../environments/environment";
import { verify } from 'crypto';

@Injectable({
  providedIn: 'root'
})
export class DetailservicesService {
  private headers: Headers;
  DetailModels: DetailModel[] = [];

  constructor(private http: HttpClient) {


  }

  /**
   * Function For get record-detais by nodeId
   */

  getRecordDetail(nodeId): Observable<DetailModel[]> {
    return this.http.get(environment.url + "/api/forecast/putrecords/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: DetailModel[]) => {
          return response;
        }
      ));
  }
  getDCFrequency(): Observable<any[]> {
    return this.http.get(environment.url + "/api/forecast/DCFrequency/", { responseType: "json" })
      .pipe(map(
        (response: any[]) => {
          return response;
        }
      ));
  }

  UpdateForecastRecordDetails(nodeId, UpdateDetails): Observable<DetailModel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(environment.url + "/api/ForecastSetting/Updaterecords/" + nodeId, UpdateDetails, { headers: reqHeaders })
      .pipe(map(
        (response: DetailModel[]) => {
          return response;
        }
      ));
  }
  UpdateForecastBankDetails(BankNodeId, nodeId): Observable<any> {
    return this.http.get(environment.url + "/api/forecast/ChangeBankAccount/" + BankNodeId + "/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  UpdateForecastDetails(nodeId, UpdateDetails): Observable<DetailModel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(environment.url + "/api/ForecastSetting/UpdateForecastDetails/" + nodeId, UpdateDetails, { headers: reqHeaders })
      .pipe(map(
        (response: DetailModel[]) => {
          return response;
        }
      ));
  }
}
