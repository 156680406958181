import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponent } from './common/detail/detail.component';
import { ForecasttabsComponent } from './common/forecasttabs/forecasttabs.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core';
import { ListForecastComponent } from './component/list-forecast/list-forecast.component';
import { ReportComponent } from './component/report/report.component';
import { EnterOpeningComponent } from './component/enter-opening/enter-opening.component';
import { ClientsComponent } from './component/clients/clients.component';
import { EnterActualsComponent } from './component/enter-actuals/enter-actuals.component';
import { CurrencySettingComponent } from './component/Settings/CurrencySetting/currency-setting/currency-setting.component';
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { LicensePurchaseComponent } from './component/license-purchase/license-purchase.component';
import { SettingsComponent } from './component/Settings/settings/settings.component';
import { XeroAuthenticationComponent } from './component/Integration/Xero/xero-authentication/xero-authentication.component';
import { ImportDataComponent } from './component/Integration/Xero/import-data/import-data.component';
import { EliminationsComponent } from './component/eliminations/eliminations.component'
import { ConsolidationSettingsComponent } from './component/consolidation-settings/consolidation-settings.component';
import { GoodwillComponent } from './component/goodwill/goodwill.component';
import { OneTouchReportComponent } from './component/one-touch-report/one-touch-report.component';
import { AdminSettingsComponent } from './component/AdminModule/admin-settings/admin-settings.component';
import { ValidateEmailComponent } from './component/validate-email/validate-email.component';
import { SupportRequestComponent } from './component/support-request/support-request.component';
import { CreatPasswordComponent } from './component/forgot-password/creat-password/creat-password.component';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { PrintOTRComponent } from './component/print-otr/print-otr.component';
const routes: Routes = [
  { path: 'forecast', component: ForecasttabsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'currency', component: CurrencySettingComponent },
  { path: 'forecast-list', component: ListForecastComponent, canActivate: [AuthGuard] },
  { path: 'menu', component: DetailComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'EnterOpening', component: EnterOpeningComponent, canActivate: [AuthGuard] },
  { path: 'EnterActual', component: EnterActualsComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'Eliminations', component: EliminationsComponent, canActivate: [AuthGuard] },
  { path: 'ConsolidationSettings', component: ConsolidationSettingsComponent, canActivate: [AuthGuard] },
  { path: 'integration-setting', component: XeroAuthenticationComponent, canActivate: [AuthGuard] },
  { path: 'integration-import', component: ImportDataComponent, canActivate: [AuthGuard] },
  { path: 'Client', component: ClientsComponent, canActivate: [AuthGuard] },
  { path: 'UserRegistration', component: UserRegistrationComponent },
  { path: "OTRReport", component: OneTouchReportComponent, canActivate: [AuthGuard] },
  { path: 'License', component: LicensePurchaseComponent, canActivate: [AuthGuard] },
  { path: 'Goodwill', component: GoodwillComponent, canActivate: [AuthGuard] },
  { path: 'Admin-Setting', component: AdminSettingsComponent, canActivate: [AuthGuard] },
  { path: 'ValidateEmail/:userid', component: ValidateEmailComponent, },
  { path: 'SupportRequest', component: SupportRequestComponent, canActivate: [AuthGuard] },
  { path: 'new-password/:id', component: CreatPasswordComponent },
  { path: 'PrintOTR', component: PrintOTRComponent, canActivate: [AuthGuard] },
  //{path:'User-Management', component:UserManagementComponent, canActivate:[AuthGuard]},
  { path: '', redirectTo: 'Client', pathMatch: 'full', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
