import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OTRService } from 'src/app/service/OTRServices/otr.service';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-otr',
  templateUrl: './otr.component.html',
  styleUrls: ['./otr.component.scss']
})
export class OTRComponent implements OnInit {
  Validcycle: any = [];
  NavitemSubmenu: any = [];
  BudgetReportCollection: any = [];
  SchedulesReportCollection: any = [];
  selectedCyclePeriod = "";
  selectedTemplate: any = "";
  formData: FormData;
  FileSelected: boolean = false;
  isActualStarted: boolean = false;
  ForecastbudgetReport = [];
  ForecastReportBudgteNodeId: any;
  VarianceMonthColleciton = [];
  VarianceMonthSelectionIndex = 0;
  @Input() forecasts = [];
  @Input() isPrintOTR: boolean = false;
  constructor(private SpinnerService: NgxSpinnerService, private oTRService: OTRService, private ReportServices: ReportService) { }

  ngOnInit() {
    this.isActualStarted = (localStorage.getItem("IsActualStarted").toString() == 'true');
    this.RenderReportMenu();
    this.getBudgetReport();
    console.log("this.forecasts", this.forecasts);
    let ForecastId = this.forecasts[0];
    this.getForecastbudgetReport(ForecastId);
    this.getVarianceMonths(ForecastId);
    this.formData = new FormData();
    this.oTRService.getValidCyclesCollection(ForecastId).subscribe(
      ((response: any) => {
        this.Validcycle = response;
        console.log("Validcycle", this.Validcycle);
      })
    );
  }

  uploadFile(files) {

    if (files.length === 0) {
      return;
    }
    this.FileSelected = true;
    let fileToUpload = <File>files[0];
    // const formData = new FormData();
    // formData
    this.formData.append('file', fileToUpload, fileToUpload.name);
    // console.log("files", this.formData);

  }
  ForecastbudgteReporChange($event) {
    let NodeId = $event.target.value;
    this.ForecastReportBudgteNodeId = NodeId;
  }
  getVarianceMonths(forecastNodeId) {
    this.ReportServices.getVarianceMonthCollection(forecastNodeId).subscribe(
      ((response: any) => {
        this.VarianceMonthColleciton = response;
        console.log("getVarianceMonths", this.VarianceMonthColleciton);
      })
    )

  }
  changevarianceMonth(event) {
    this.VarianceMonthSelectionIndex = event.target.selectedOptions[0].id;
  }
  exportExcel() {
    this.SpinnerService.show();
    let ForecastId = this.forecasts[0];
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    let ClientId = localStorage.getItem('clientID');
    let updateArrayResult = [];
    let ReportTypes = {
      'value': 0,
      'Status': false,
    }
    this.BudgetReportCollection.forEach(element => {
      if (this.isActualStarted) {
        ReportTypes.value = element.value;
        ReportTypes.Status = element.status;
        updateArrayResult.push(element);
      } else {
        if (element.type != "Variance") {
          ReportTypes.value = element.value;
          ReportTypes.Status = element.status;
          updateArrayResult.push(element);
        }
      }

    });
    // let jsondata = {
    //   'ForecastId': ForecastId,
    //   'SelectedCyclePeriod': this.selectedCyclePeriod,
    //   'SelectedTemplate': this.selectedTemplate,
    //   'CurrentUserID': CurrentUserID,
    //   'ClientId': ClientId,
    //   'ReportType': updateArrayResult,
    //   'excelTemplate': this.formData
    // }
    // { name: 'Variance Profit and Loss', type: 'Variance', value: 990021, status: true },
    // { name: 'Variance Profit and Loss By Department', type: 'Variance', value: 990025, status: false },
    // { name: 'Variance Balance Sheet', type: 'Variance', value: 990023, status: true },
    // { name: 'Variance Funds Flow', type: 'Variance', value: 990024, status: true },
    // { name: ' Variance CashFlow', type: 'Variance', value: 990022, status: true },

    this.formData.append('ForecastId', JSON.stringify(this.forecasts));
    this.formData.append('SelectedCyclePeriod', this.selectedCyclePeriod);
    this.formData.append('CurrentUserID', CurrentUserID);
    this.formData.append('ClientId', ClientId);
    this.formData.append('ReportType', JSON.stringify(updateArrayResult));
    this.formData.append('ComparisonBudget', this.ForecastReportBudgteNodeId);
    this.formData.append('varianceMonthSelection', this.VarianceMonthSelectionIndex.toString());

    // console.log("jsondata", this.formData);
    this.oTRService.CallExportExcel(this.formData).subscribe(
      ((response: any) => {
        // this.downloadFile(response);

        if (response.type === HttpEventType.Response) {
          this.SpinnerService.hide();
          this.downloadFile(response);
          console.log("response download", response);
        }

      })
    )
    this.ngOnInit();
  }

  private downloadFile = (data: HttpResponse<Blob>) => {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    console.log("data", data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    if (this.isPrintOTR) {
      var yourDate = new Date();
      var epochTicks = 621355968000000000;
      var ticksPerMillisecond = 10000;
      var yourTicks = epochTicks + (yourDate.getTime() * ticksPerMillisecond);
      if (this.FileSelected)
        a.download = "OneTouchReport_" + yourTicks.toString().substring(1, 10) + '.xlsx';
      else
        a.download = "OneTouchReport_" + yourTicks.toString().substring(1, 10) + '.xls';
    } else {
      if (this.FileSelected)
        a.download = localStorage.getItem('ForecastName') + '.xlsx';
      else
        a.download = localStorage.getItem('ForecastName') + '.xls';
    }


    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  changecycleperiod($event) {
    // console.log("changecycleperiod", $event.target);
    if ($event.target.value == '' || $event.target.value == null) {
      return;
    } else {
      this.selectedCyclePeriod = $event.target.value;
    }
  }

  getForecastbudgetReport(ForecastNodeId) {

    this.ReportServices.getForecastBudgetReport(ForecastNodeId).subscribe(
      ((response: any) => {

        this.ForecastbudgetReport = response;
        let result: any = this.ForecastbudgetReport;
        // console.log("getForecastbudgetReport", result);
        this.ForecastReportBudgteNodeId = result[0].nodeId;

      })
    )
  }

  RenderReportMenu() {
    this.NavitemSubmenu = [];
    if (this.isActualStarted) {
      this.NavitemSubmenu = [
        { name: 'Budget Reports', icon: 'home', type: 'Reports' },
        { name: 'Schedules', icon: 'details', type: 'Schedules' },
        { name: 'Variance', icon: 'details', type: 'Variance' },
      ]
    } else {
      this.NavitemSubmenu = [
        { name: 'Budget Reports', icon: 'home', type: 'Reports' },
        { name: 'Schedules', icon: 'details', type: 'Schedules' },
      ]
    }

  }

  getBudgetReport() {
    this.BudgetReportCollection = [
      { name: 'Profit And Loss', type: 'Budget', value: 990001, status: true },
      { name: 'Profit And Loss By Department', type: 'Budget', value: 990005, status: false },
      { name: 'Balance Sheet', type: 'Budget', value: 990003, status: true },
      { name: 'CashFlow', type: 'Budget', value: 990002, status: true },
      { name: 'FundsFlow', type: 'Budget', value: 990004, status: true },
      { name: 'Fixed Asset Schedule', type: 'Budget', value: 990032, status: true },
      { name: 'Assumptions Report', type: 'Budget', value: 990040, status: false },
      { name: 'Employee Schedule', type: 'Schedules', value: 990039, status: false },
      { name: 'Loans Received Schedule', type: 'Schedules', value: 990033, status: false },
      { name: 'Loans Made Schedule', type: 'Schedules', value: 990034, status: false },
      { name: 'Sales Tax Schedule', type: 'Schedules', value: 990035, status: false },
      { name: 'Statistics And Ratios', type: 'Schedules', value: 990037, status: false },
      { name: 'Variance Profit and Loss', type: 'Variance', value: 990021, status: true },
      { name: 'Variance Profit and Loss By Department', type: 'Variance', value: 990025, status: false },
      { name: 'Variance Balance Sheet', type: 'Variance', value: 990023, status: true },
      { name: 'Variance Funds Flow', type: 'Variance', value: 990024, status: true },
      { name: ' Variance CashFlow', type: 'Variance', value: 990022, status: true },
    ]
  }

  ScheduleReportChangeevent(Values, $event, eventname) {
    let value = $event.target.value;
    let checked = $event.target.checked;
    let updatedcollectionarray: any = [];
    this.BudgetReportCollection.forEach(element => {
      if (value == element.value) {
        element.status = checked;
      }
    });
    // console.log(this.BudgetReportCollection)
  }


}
