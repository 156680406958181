export class eliminationRecordsModel {
    recordName: string
    recordNodeid: string
    isEliminated: boolean
    referenceForecasts: string
    valueArray: any[]
    columns: any
    monthDisplay: monthDisplay[]

    constructor() {
        this.recordName = null;
        this.recordNodeid = null;
        this.referenceForecasts = null;
        this.isEliminated = false;
        this.valueArray = [];
        this.columns = null;
        this.monthDisplay = [];
    }
}

export class eliminationsModel {
    blEliminationRecords: eliminationRecordsModel[]
    pnlEliminationRecords: eliminationRecordsModel[]
    constructor() {
        this.blEliminationRecords = null;
        this.pnlEliminationRecords = null;
    }

}

export class monthDisplay {
    name: string
    fontWight: string
}

export class elimnationRecords {
    nodeId: string
    isEliminated: boolean
}