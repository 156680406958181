import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'app-settingsmenu',
  templateUrl: './settingsmenu.component.html',
  styleUrls: ['./settingsmenu.component.scss']
})
export class SettingsmenuComponent implements OnInit {

  @Output() recordChanged = new EventEmitter();
  @Output() SettingMenuTitleCreated = new EventEmitter<{ title: string }>();

  activeId: any;
  forecastname  = '';
  NavitemSubmenu = [];
  navItems = [];
  public selected = [];
  public displaySubmenustyle = '';
  showDrawerClick: boolean;
  hideDrawerClick: boolean;
  constructor() { 
    this.activeId = localStorage.getItem('ForecastId');
  }


  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  } 
  public navigate(item) { 
    this.selected = [];
    this.selected.push(item);
    this.SettingMenuTitleCreated.emit({ title: item.name });
  }
  sidebarmenuContain() { 
    if(this.showDrawerClick = true){
      this.displaySubmenustyle = 'block';
    } else {
      this.displaySubmenustyle = '';
    }
    this.selected = [];
  }
  showDrawer(){
    this.displaySubmenustyle = 'block';
   }
   hideDrawer(){ 
     this.displaySubmenustyle = '';
   }
  RenderReportMenu(){ 
    let CountryVersion = {}
    let countryName = localStorage.getItem("CountryName");
    if(countryName == "GB"){
      CountryVersion = { name: 'VAT Rates', icon: 'corporate_fare', callback: { name: 'SettingCollection', perameter: 'VAT Rates', active: true } };
    } else {
      CountryVersion = { name: 'GST Rates', icon: 'corporate_fare', callback: { name: 'SettingCollection', perameter: 'GST Rates', active: true } }
    }
      this.NavitemSubmenu = [
        {name:'Home', icon:'home', type:'home', callback:{name:'Forecasthome',perameter:'',active:'true'}},
        {name:'Forecast Details', icon:'details', type:'forecastdetails', callback:{name:'ProfileSetting', perameter:'app-profile-setting',active:true}},
        {name:'Currencies', icon:'monetization_on', type:'Currency', callback:{name:'CurrencySetting', perameter:'' , active:true }},
        CountryVersion,
       /* {name:'GST Rates', icon:'corporate_fare', type:'gstrates', callback:{name:'ProfileSetting', perameter:'',active:true}},*/
        {name:'Profiles', icon:'settings_accessibility', type:'profiles', callback:{name:'ProfileSetting', perameter:'',active:true}},
        {name:'Payroll Tables & Options', icon:'receipt_long', type:'payrolltableoptions', callback:{name:'payrolltableoptionsSetting', perameter:'',active:true}},
        {name:'Contract Retentions', icon:'account_tree', type:'ContactRetension', callback:{name:'ContactRetensionSetting', perameter:'',active:true}},
        {name:'Forecast Other Options', icon:'settings_applications', type:'forecastotheroptions', callback:{name:'forecastotheroptionsSetting', perameter:'',active:true}},
        {name:'Preferences', icon:'room_preferences', type:'Preferences', callback:{name:'PreferencesSetting', perameter:'',active:true}},
        {name:'Parameter Defaults', icon:'dataset', type:'ParameterDefaults', callback:{name:'ParameterDefaultsSetting', perameter:'',active:true}},
        {name:'Extent/Amend', icon:'extension', type:'ForecastExtent', callback:{name:'ForecastExtent', perameter:'',active:true}},
      ]
  }

  ngOnInit() {
    this.NavitemSubmenu = [];
    this.RenderReportMenu();
  }

}
