import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ForecastOtherOptionService {

  private headers: Headers;
  constructor(private http: HttpClient) { }

  /**
   * Function For get ForecastOtherOption Collection
   * @param ForecastNodeId 
   * @returns 
   */
  getForecastotherOptionsCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/ForecastOtherOptions/ForecastOtherOptionsCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetDebtorInvoicesAllocationMethod(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetDebtorInvoicesAllocationMethod/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetCreditorInvoicesAllocationMethod(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetCreditorInvoicesAllocationMethod/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetForecastInterestMethod(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetForecastInterestMethod/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetCalculateTaxAndDividendsOnNegativeProfits(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetCalculateTaxAndDividendsOnNegativeProfits/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetExtraOrdinaryTaxCalculation(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetExtraOrdinaryTaxCalculation/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetIsFactoringDebtorsBalance(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetIsFactoringDebtorsBalance/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetAllowNegativeStockBalances(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetAllowNegativeStockBalances/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetHolidayInterestPaymentsForCompoundInterest(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetHolidayInterestPaymentsForCompoundInterest/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetDisableForecastRecordCalculation(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetDisableForecastRecordCalculation/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetDisableActualsProfileCalculation(ForecastNodeId, Value): Observable<any[]> {
    return this.http.put(environment.url + "/api/ForecastOtherOptions/SetDisableActualsProfileCalculation/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
}
