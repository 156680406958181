import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forEach } from 'jszip';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/core/services/notification.service';
import { supportRequestModel } from 'src/app/model/supportRequestModel';
import { AdminmoduleService } from 'src/app/service/adminmoduleServices/adminmodule.service';
@Component({
  selector: 'app-support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.scss']
})
export class SupportRequestComponent implements OnInit {
  criticality = [];
  CustomerName = new UntypedFormControl('', [Validators.required]);
  emailId = new UntypedFormControl(localStorage.getItem("userEmailID"), []);
  phone = new UntypedFormControl('', [Validators.required])
  help = new UntypedFormControl('', [Validators.required])
  howcritical = new UntypedFormControl('', [Validators.required])
  isReproducible = new UntypedFormControl(false, [])
  accessGranted = new UntypedFormControl(true, [])
  steps = new UntypedFormControl('', [])
  attachments = new UntypedFormControl('')
  supportRequestform: UntypedFormGroup;
  filejson = {
    file: null,
    filename: null
  };
  filestoattach = [];

  constructor(fb: UntypedFormBuilder, private adminService: AdminmoduleService, private SpinnerService: NgxSpinnerService, private injector: Injector) {
    this.supportRequestform = new UntypedFormGroup({
      CustomerName: this.CustomerName,
      emailId: this.emailId,
      phone: this.phone,
      help: this.help,
      howCritical: this.howcritical,
      isReproducible: this.isReproducible,
      accessGranted: this.accessGranted,
      steps: this.steps,
      attachments: this.attachments
    }, []);
    this.criticality = [
      { type: 'Query', description: 'Just a query' },
      { type: 'NextFewDays', description: 'In next few days' },
      { type: 'now', description: 'Need help now' },
    ];
  }

  ngOnInit(): void {
    console.log(this.emailId)
  }
  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    let supportRequest = new supportRequestModel();
    supportRequest.CustomerName = this.supportRequestform.value.CustomerName;
    supportRequest.emailId = this.supportRequestform.value.emailId;
    supportRequest.phone = this.supportRequestform.value.phone;
    supportRequest.help = this.supportRequestform.value.help;
    supportRequest.howCritical = this.supportRequestform.value.howCritical;
    supportRequest.isReproducible = this.supportRequestform.value.isReproducible;
    supportRequest.accessGranted = this.supportRequestform.value.accessGranted;
    supportRequest.steps = this.supportRequestform.value.steps;
    supportRequest.attachments = this.filestoattach;
    supportRequest.userId = localStorage.getItem("CurrentUserID").toString();
    console.log(supportRequest);
    const formData = new FormData();
    formData.append("CustomerName", this.supportRequestform.value.CustomerName);
    formData.append("emailId", this.supportRequestform.value.emailId);
    formData.append("phone", this.supportRequestform.value.phone);
    formData.append("help", this.supportRequestform.value.help);
    formData.append("howCritical", this.supportRequestform.value.howCritical);
    formData.append("isReproducible", this.supportRequestform.value.isReproducible);
    formData.append("accessGranted", this.supportRequestform.value.accessGranted);
    formData.append("steps", this.supportRequestform.value.steps);
    formData.append("userId", localStorage.getItem("CurrentUserID").toString());
    this.filestoattach.map((file, index) => {
      return formData.append('file' + index, file.file, file.filename);
    });
    this.adminService.RaiseSupportRequest(formData).subscribe(
      (response: any) => {

        this.SpinnerService.hide();
        notifier.showSuccess("Support request raised successfully.")
      })
  }

  handleFileInput = (files: FileList) => {
    for (let index = 0; index < files.length; index++) {
      let fileToUpload = files.item(index);
      this.filejson = {
        file: fileToUpload,
        filename: fileToUpload.name
      }
      this.filestoattach.push(this.filejson)

    }
  }
}
