<app-header [getSelectedNodeIdDetail]="SelectedNodeDetailBinding"></app-header>
<div class="forecast-tabs">
  <div class="row">
    <div class="col-lg-12">
      <div class="tab-content clearfix">
        <div class="tab-pane active" id="new-forecast1">
          <div class="row">
            <div class="col-sm-12" style="padding: 0px;">
              <span class="mdi mdi-alpha-j-circle-outline"></span>
              <div class="tree-menu">

                <div class="col-sm-3" style="background: rgb(168 168 183 / 27%);height: 92vh;padding: 0 0 0 4px;border-right: 3px solid gray;">
                  <div class="" style="height: 88vh;" >
                    <app-treemenu (eventClicked)="childEventClicked($event)"
                      [selectedNodedetails]="selectedNodedetail"></app-treemenu>
                  </div>
                </div>
              </div>
              <div class="BudgetRecordRightSide col-sm-9" style="padding: 0 0 0 10px;">
                <app-rightsidebar-menu></app-rightsidebar-menu>
                <app-detail [event]="clickedEvent" (recordTypeClicked)="recordtypeClickEvent($event)"></app-detail>
                <div class="dataviewDiv">
                  <app-valuelist [eventBudgetListView]="budgetListView"
                    [eventBudgetDerrivationView]="DisplayDerrivationData" [eventPeriodTabView]="PeriodTabView"
                    [eventMonthDisplay]="MonthDisplay" [clicknodeId]="treenodeId" [budgettotal]="budgetdatatotal"
                    [recordnodeType]="recordNodeType" [ActiveTreenodeId]="ActiveTreenodeId"
                    *ngIf="!sectionId || !treeNodeFolderId">
                  </app-valuelist>
                  <app-sections [sectionclicked]="sectionId" *ngIf="sectionId && !treeNodeFolderId"></app-sections>
                  <app-tree-nodefolder [treeNodeFolderClicked]="treeNodeFolderId" *ngIf="treeNodeFolderId">
                  </app-tree-nodefolder>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
  </ngx-spinner>
</div>
<app-footer></app-footer>
