<div class="col-sm-12">
<div class="col-sm-4" style="height: 500px;border: 1px solid black;" >
    <table class="table">
        <thead>
            <th>ForecastName</th>
        </thead>
        <tbody>
            <tr *ngFor="let ForecastName of listofForecast">
               <td value="{{ForecastName.code}}" (click)="ForecastClickEvent(ForecastName.code)" 
               [ngClass]="{'Active': ActiveForecast == ForecastName.code}" >{{ForecastName.name}}</td> 
            </tr>
        </tbody>
    </table>
</div>
<div class="col-sm-3">
    <div class="col">
        <div class="col" >
            <div style="padding: 3px 6px 0px 0;margin: 0px -33px 0 -28px;">
                <button type="button" class="UserManagementForecastAcess usermanagementbuttton btn btn-primary col" 
                    (click)="OepnAddUserDialog(UserManagementForecastAcess)">Update Forecast Access</button>
                <igx-dialog #UserManagementForecastAcess [closeOnOutsideSelect]="false">
                    <div class="UserManagementForecastAcess" style="width: 500px;">
                        <h2>Update Forecast Access</h2>
                        <div class="row">
                            <div class="col-sm-12" style="padding: 10px;">
                                <div class="col-sm-3">Owner</div>
                                <div class="col-sm-6">
                                    <select name="UpdateForecastOwner" class="form-control" (change)="SelectedOwner($event)">
                                        <option *ngFor="let ListOfUser of ListOfUsers" value="{{ListOfUser.code}}" >{{ListOfUser.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding: 10px;">
                                <div class="col-sm-3">Group</div>
                                <div class="col-sm-6">
                                    <select name="UpdateForecastGroup" class="form-control" (change)="SelectedGroup($event)">
                                        <option *ngFor="let Group of ListOfGroup" value="{{Group.code}}">{{Group.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="register-Submit col" style="height: 52px;padding: 40px;">

                        <button type="submit" class="OkSaveButtonColor register-submitbutton col-sm-5"
                            style="background-color: #1e90ff;" (click)="UpdateForecastAccess(UserManagementForecastAcess)">Save</button>
                        <button type="button" class="deleteCancelButtonColor register-submitbutton col-sm-5"
                        style="margin: 0 0 0 10px;" (click)="UserManagementForecastAcess.close()">Cancel</button>
                    </div>
                </igx-dialog>
            </div>
        </div>
        
    </div>
    
</div>
<div class="col-sm-3">

</div>

</div>
