<div class="container newforecast">
    <h2 class="HeaderColor"> {{ this.title}} </h2>
    <form [formGroup]="createForecastForm" (ngSubmit)="onSubmit()">
        <div class="consolidaiton">
            <div class="ConsolidationSelection">
                <div class="new-consolidation">
                    <div class="country form-group row">
                        <label class="col-sm-4 col-form-label">Country</label>
                        <select name="ForecastCountry" formControlName="ForecastCountry" class="form-control col-sm-6"
                            (change)='onOptionsSelected($event)' style="color: black;">
                            <option *ngFor="let CountryCollection of RegionalCollection; let CountryIndex = index"
                                [selected]="CountryIndex==0" value="{{CountryIndex}}">{{CountryCollection.name}}
                            </option>
                        </select>
                    </div>
                    <div class="company_name form-group row">
                        <label class="col-sm-4 col-form-label">Company Name</label>
                        <input type="text" formControlName="company_name" class="form-control col-sm-6"
                            style="color: black;" (blur)="changecompayname_event($event)" name="company_name" />
                    </div>
                    <div class="Subheading_name form-group row">
                        <label class="col-sm-4 col-form-label">Sub Heading</label>
                        <input type="text" class="form-control col-sm-6" name="Subheading_name"
                            formControlName="Subheading_name" style="color: black;" />
                    </div>
                    <div class="Description form-group row">
                        <label class="col-sm-4 col-form-label">Description</label>
                        <textarea type="text" class="form-control Description col-sm-6" name="Description"
                            formControlName="Description" style="color: black;"></textarea>
                    </div>
                    <div class="start-date form-group row">
                        <label class="col-sm-4 col-form-label">Number of Years</label>
                        <input type="number" value="1" class="form-control col-sm-4" name="numberofyears"
                            formControlName="numberofyears" style="color: black;" />
                    </div>
                    <div class="start-date form-group row">
                        <label class="col-sm-4 col-form-label">Start Date</label>
                        <div class="form-group">
                            <div class="input-group" style="width:80%;">
                                <input id="datepickerCon" class="datepicker" type="text" placeholder="Enter StartDate"
                                    name="startdate" formControlName="startdate" class="form-control"
                                    style="margin-left: -8px;" />
                            </div>
                        </div>
                    </div>
                    <div class="Firstdata_period form-group row">
                        <label class="col-sm-4 col-form-label">First data Period</label>
                        <select name="FirstdataPeriod" formControlName="FirstdataPeriod" class="form-control col-sm-4"
                            style="color: black;">
                            <option *ngFor="let data of months; let FirstdataPeriodindex=index"
                                [selected]="FirstdataPeriodindex==1" value="{{FirstdataPeriodindex}}">{{data | date:'MMM
                                yy'}}
                            </option>
                        </select>
                    </div>
                    <div class="LocalCurrency form-group row">
                        <label class="col-sm-4 col-form-label">Local Currency</label>
                        <select name="LocalCurrency" formControlName="LocalCurrency" class="form-control col-sm-4"
                            style="color: black;">
                            <option *ngFor="Currencylist of currency ; let LocalCurrencyIndex = index"
                                [selected]="LocalCurrencyIndex==0" value="{{LocalCurrencyIndex}}">
                                {{currency[LocalCurrencyIndex].name}}</option>
                        </select>
                    </div>
                    <div class="Firstdata_period form-group row">
                        <label class="col-sm-4 col-form-label">First daily cashflow period</label>
                        <select name="FirstdailyPeriodindex" formControlName="FirstdailyPeriodindex"
                            class="form-control col-sm-4" style="color: black;">
                            <option *ngFor="let data of months; let FirstdailyPeriodindex=index"
                                [selected]="FirstdailyPeriodindex==1" value="{{FirstdailyPeriodindex}}">{{data |
                                date:'MMM
                                yy'}}
                            </option>
                        </select>
                    </div>
                    <div class="Firstdata_period form-group row">
                        <label class="col-sm-4 col-form-label">Consolidation Type</label>
                        <select name="ConsolidationType" formControlName="ConsolidationType"
                            (change)='alertconsolidaitontype($event)' class="form-control col-sm-4"
                            style="color: black;">
                            <option *ngFor="let ctype of consolidationType">{{ctype}}
                            </option>
                        </select>
                    </div>


                </div>
                <div class="forecastSelection">
                    <div>Select Forecasts to Consolidate: </div>
                    <div style="overflow-x: auto;">
                        <div *ngFor="let forecast of forecastlist">
                            <igx-checkbox [igxTooltipTarget]="tooltipRef"
                                (change)="validatestartDate($event, forecast.nodeId)"
                                [checked]="forecast.consolSelected" (tooltipShow)="tooltipRef.context=forecast">
                                {{ forecast.name }}
                            </igx-checkbox>
                            <div>
                                <input type="text" placeholder="Associate Interest" [id]="forecast.nodeId"
                                    *ngIf="forecast.consolSelected" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class=" forecast_button">
                <input type="submit" name="submit" value="OK" class="newForecastSubmitButton OkSaveButtonColor" />
                <input type="button" (click)="closeForecast()" name="cancel" value="Cancel"
                    class="deleteCancelButtonColor" />
            </div>
        </div>
    </form>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
    </ngx-spinner>
    <div #outlet="overlay-outlet" igxOverlayOutlet>
        <div #tooltipRef="tooltip" igxTooltip>
            <ng-container *ngIf="tooltipRef.context">

                <div>Forecast Type: {{tooltipRef.context.type}}</div>
                <div>Start Date: {{tooltipRef.context.startdate| date:'MMM yy'}}
                </div>
                <div>Currency: {{tooltipRef.context.currency}}
                </div>
            </ng-container>
        </div>
    </div>
</div>