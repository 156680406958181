import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { Record } from '../../../../model/record';
import { Methodmodel } from './methodmodel.model';
// import { ok } from 'assert';
import { data } from 'jquery';
import { recordSaveModel } from 'src/app/model/recordSaveModel';
import { FormulaModel } from 'src/app/model/FormulaModel';
@Injectable({
  providedIn: 'root'
})
export class MethodserviceService {
  private headers: Headers;
  Methodmodels: Methodmodel[] = [];
  constructor(private http: HttpClient) { }
  /**
   * Function For get record-detais by nodeId
   */
  getAllEmumValue(): Observable<Methodmodel[]> {
    return this.http.get(environment.url + "/api/forecast/NodeType/all", { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  getDerrivation(NodeId, DerivationNodeId, valueNodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/GetDerivation/" + NodeId + "/" + DerivationNodeId + "/" + valueNodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  getmethodlocalize(NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/LocalizationDerivation/" + NodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  getValidationTypeLocalize(NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/LocalizationValidTypes/" + NodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  getDarrivationType(DarrivationId, ValueNodeId, NodeId): Observable<Methodmodel[]> {

    let url = environment.url + "/api/BudgetData/ChangeDerivation/" + NodeId + "/" + DarrivationId + "/" + ValueNodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  numberofperiodsofoptions(NodeId, Value): Observable<Methodmodel[]> {

    let url = environment.url + "/api/WhatIf/numberofperiodsofoptions/" + NodeId + "/" + Value;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  UpdateSubDerrivationOptions(nodeId, index, selectedNodeId, valuesNodeId): Observable<any> {
    let url = environment.url + "/api/BudgetData/SubDerrivatioOptions/" + nodeId + "/" + index + "/" + selectedNodeId + "/" + valuesNodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  UpdateSubDerrivationType(index, selectedNodeId, valuesNodeId): Observable<any> {
    let url = environment.url + "/api/BudgetData/SubDerrivationType/" + index + "/" + selectedNodeId + "/" + valuesNodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  // getLoan Taken Record Data 

  getLoanTakenCalcultedRecord(ForecastId, NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/Forecast/loantakenrecrod/" + ForecastId + "/" + NodeId + "/";
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  // getLoan Taken Record Data 

  getLoanMadeCalcultedRecord(ForecastId, NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/CalculatedLoanMadeRecord/LoanMadeRecrod/" + ForecastId + "/" + NodeId + "/";
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  // getLoan Taken Record Data 

  getFinnacedAssestsRecord(ForecastId, NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/FinnacedAssestsRecord/LoanMadeRecrod/" + ForecastId + "/" + NodeId + "/";
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  getBudgetData(NodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/DisplayBudgetData/" + NodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  saveRecord(saveRecordValuelist): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/SetValueFromDisplayDBD";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  saveModifiedRecord(recordModified: recordSaveModel): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/saveRecordModified";
    return this.http.post(url, recordModified, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          console.log("saveModifiedRecord", response);
          return response;
        }
      ));
  }
  /**
   * 
   * @param saveRecordValuelist 
   * @returns json Array
   */
  setAllRecordSaveValue(saveRecordValuelist): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/SetAllValueFromDisplayDBD";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  settotalRecordSaveValue(saveRecordValuelist): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/SetTotalFromDisplayDBD";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  setMonthRecordSaveValues(saveRecordValuelist): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/SetValueFromDisplayDBD";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  saveOpening(saveRecordValuelist): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/BudgetData/SetOpeningFromDisplayDBD";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  updateLoantakenRecord(SaveloanTakenRecord): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/Forecast/SetValueFromDisplayCalculatedDBD";
    return this.http.post(url, SaveloanTakenRecord, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  saveLoanCalculation(SaveloanTakenRecord): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/Forecast/CalculatedLoanTakenRecordCalculation";
    return this.http.post(url, SaveloanTakenRecord, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  updateFinnaceRecord(SaveloanTakenRecord): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/FinnacedAssestsRecord/SetValueFromLoanMadeRecord";
    return this.http.post(url, SaveloanTakenRecord, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  updateCalculatedLoanMadeRecord(SaveloanTakenRecord): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/CalculatedLoanMadeRecord/SetValueFromLoanMadeRecord";
    return this.http.post(url, SaveloanTakenRecord, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }
  /**
   * Get Profile data
   */
  getProfileColletions(ForecastId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/forecast/settings/Profile/" + ForecastId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  /**
   * Get EventSpread Profile data
   */
  getEventSpreadProfileColletions(NodeId): Observable<any> {
    let url = environment.url + "/api/BudgetData/getEvenSpreadProfileCollection/" + NodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  /**
   * Get EventSpread EMployeeGroup data
   */
  getEvenSpreadEMployeeGroupCollection(NodeId): Observable<any> {
    let url = environment.url + "/api/BudgetData/getEvenSpreadEMployeeGroupCollection/" + NodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  SavingEmployeeGroupCollection(valuesNodeId) {
    return this.http.get(environment.apiUrl + "/api/EmployeeGroupCollections/SavingEmployeeGroupCollection/" + valuesNodeId)
      .pipe(
        map((response => {
          return response;
        }))
      )
  }

  SavingFormulaCollection(valuesNodeId, formulaDetails: FormulaModel) {
    // return this.http.get(environment.apiUrl+"/api/Formula/saveFormulaCollection/"+valuesNodeId)
    // .pipe(
    //   map((response=>{
    //     return response;
    //   }))
    // )

    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/Formula/saveFormulaCollection/" + valuesNodeId;
    return this.http.post(url, formulaDetails, { headers: reqHeaders })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  /**
   * Change Profile Get data
   */

RefreshHotlink(RecordNodeId, DerrivationNodeId): Observable<any> {
  let url = environment.url + "/api/HotLink/RefreshHotLink/" + RecordNodeId + "/" + DerrivationNodeId;
  return this.http.get(url, { responseType: "json" })
    .pipe(map(
      (response: any) => {
        return response;
      }
    ));
}


  /**
   * Change Profile Get data
   */

  selectProfileOptions(Index, NodeId, ValueNodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/SelectProfile/" + NodeId + "/" + Index + "/" + ValueNodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  /**
   * selectedNdde API
   */

  selectedNodeService(seletedid, NodeId, ValuenodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/SelectNode/" + NodeId + "/" + seletedid + "/" + ValuenodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  unSelectedNodeService(Unseletedid, NodeId, ValuenodeId): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/UnSelectNode/" + NodeId + "/" + Unseletedid + "/" + ValuenodeId;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  CalculateAsBalance(RecordNodeId, SelectedOption): Observable<Methodmodel[]> {
    let url = environment.url + "/api/BudgetData/CalculateAsBalance/" + RecordNodeId + "/" + SelectedOption;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ))
  }

  savePriodTabViewIndex(ValueNodeId, Value): Observable<any> {
    let url = environment.url + "/api/BudgetData/PeriodTabViewIndexChanged/" + ValueNodeId + "/" + Value;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }

}
