<div class="insidecontainer" style="width: 1000px; height: 572px;">
    <igx-dialog-title class="dialogtitle" style="background: dodgerblue;color: white;padding: 5px;width: 1032px; margin: -14px 2px 6px -17px;font-size: 14px;font-weight: 400; border-radius: 0px;">
        <div class="title-container">
            <div class="dialog-title" style="font-size: 16px;">HotLink Forecast Select</div>
        </div>
    </igx-dialog-title>
    <igx-content>
        <div class="hotlinkForecastSelect">
            <select name="hotlinkForecastList" class="form-control" style="width: 30%;margin: 1px 1px 7px 0px;" (change)="ChangeForecast($event)">
                <option *ngFor="let ForecastResult of forecastList" 
                 [selected]="ForecastResult.name == selectedForecastValue" class="form-control" value="{{ForecastResult.code}}">
                    {{ForecastResult.name}}</option>
            </select>
        </div>
    </igx-content>
    <igx-content>
        <div style="width: 100%;height: 500px;overflow: overlay;" *ngIf="memorecordListView!=null">
            <table class="table table-striped table-bordered" width="100" style="height: 100px; overflow: scroll;">
                <thead style="background: dodgerblue;color: white;">
                    <th>*</th>
                    <th>Record Name</th>
                    <th *ngFor="let value of columnsArrayPL">{{value.header}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let displayData of memorecordListView.hotLinkMemoRecords">
                        <td><input type="checkbox" class="col-sm-10" (change)="SelectMemoRecord(displayData,$event)" [checked]="displayData.isCheckboxcheckedCode" ></td>
                        <td>{{displayData.forecastRecordName}}</td>
                        <td *ngFor="let data of displayData.displaybudgetData">{{data}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </igx-content>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
  </ngx-spinner>
