<div class="insidecontainer" style="width: 1250px; height: 657px;">
    <igx-dialog-title class="dialogtitle" style="    background: dodgerblue;color: white;margin: -12px -17px 0 -17px;">
        <div class="title-container">
            <div class="dialog-title" style="color: white;font-size: 16px;font-weight: 500;">Formula</div>
        </div>
    </igx-dialog-title>
    <div class="col-sm-12" style="background: #f3f4f6 var(--tw-gradient-to-position); padding: 0px;">
        <div class="col-sm-3" style="height: 610px; background: rgb(168 168 183 / 27%);" id="{{formulatree}}">

            <div class="text-center col-sm-12" *ngIf="loadingFormulaTreeView">
                <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                  </div>
                  <span class="visually-hidden">Please Wait Loading Tree...</span>
            </div>
            <app-formulatree-view [Formulatree]="formulatree" [formulavaluesNodeId]="formulavalueNodeId"
                (FormulaeventClicked)="FormulaTreeEventClicked($event)"
                *ngIf="!loadingFormulaTreeView &&DisplayMonthCollection.length>0"></app-formulatree-view>
        </div>

        <div class="col-sm-4" style="padding: 0 7px 0px 0px;">
            <div class="col-sm-6" style="padding: 0 9px 0 12px;">
                <div class="FormulaNumeric">
                    <label>Numeric</label>
                    <!-- (keyup)="ValidateValue($event)" -->
                    <input type="number" (keypress)="isNumber($event)" class="col-sm-10 form-control numericnumber"
                        (blur)="ChangeNumericNumber($event)">
                </div>
                <div class="col-sm-7" style="padding: 0px">
                    <div class="FormulaButton">
                        <button class="btn" *ngIf="SelectedFormulaPartVm.canAdd == true"
                            (click)="addFormulaOparand()">Add</button>
                        <button class="btn disabled" *ngIf="SelectedFormulaPartVm.canAdd == false">Add</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" (click)="replaceFormulaOparand()"
                            *ngIf="SelectedFormulaPartVm.canReplace == true">Replace</button>
                        <button class="btn disabled" *ngIf="SelectedFormulaPartVm.canReplace == false">Replace</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" (click)="DeleteFormulaOparand()"
                            *ngIf="SelectedFormulaPartVm.canDelete == true">Delete</button>
                        <button class="btn disabled" *ngIf="SelectedFormulaPartVm.canDelete == false">Delete</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" (click)="clearFormulaOparand()"
                            *ngIf="SelectedFormulaPartVm.canClear == true">Clear</button>
                        <button class="btn disabled" *ngIf="SelectedFormulaPartVm.canClear == false">Clear</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" (click)="AddLeftParenthesisFormulaOparand()"
                            *ngIf="SelectedFormulaPartVm.canAddLeftParenthesis == true">( </button>
                        <button class="btn disabled" *ngIf="SelectedFormulaPartVm.canAddLeftParenthesis == false">(
                        </button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn {{SelectedFormulaPartVm.canAddRightParenthesis}}"
                            (click)="AddRightParenthesisFormulaOparand()"
                            *ngIf="SelectedFormulaPartVm.canAddRightParenthesis == true">) </button>
                        <button class="btn disabled {{SelectedFormulaPartVm.canAddRightParenthesis}}"
                            *ngIf="SelectedFormulaPartVm.canAddRightParenthesis == false">) </button>
                    </div>

                </div>
                <div class="col-sm-5">
                    <div class="FormulaButton">
                        <button class="btn" [ngClass]="{'OprandActive': 210001== FormulaOparand }"
                            (click)="ChangedValidOperationType(210001,selectedIndex )"
                            (dblclick)="addFormulaOparand()">+ </button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" [ngClass]="{'OprandActive': 210002== FormulaOparand }"
                            (click)="ChangedValidOperationType(210002,selectedIndex)"
                            (dblclick)="addFormulaOparand()">-</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" [ngClass]="{'OprandActive': 210003== FormulaOparand }"
                            (click)="ChangedValidOperationType(210003,selectedIndex)"
                            (dblclick)="addFormulaOparand()">x</button>
                    </div>
                    <div class="FormulaButton">
                        <button class="btn" [ngClass]="{'OprandActive': 210004== FormulaOparand }"
                            (click)="ChangedValidOperationType(210004,selectedIndex)"
                            (dblclick)="addFormulaOparand()">÷</button>
                    </div>
                </div>
                <div class="IncludeType" *ngIf="FormulaPartVm!=null">
                    <div class="col-sm-12"
                        style="padding: 0px;margin-top: 7px;border: 1px solid #86b6d9;background:#1e90ff;color: white;font-size: 16px;">
                        <input type="checkbox" (change)="NagateValueChange($event)"
                            style="padding: 0px; margin: 3px 0px 3px 0px;width: 30px !important;height: 20px;"
                            [checked]="FormulaPartVm.negateValues" class="col-sm-2 form-control">
                        <label class="col-sm-9" style="padding: 0px;">Negate Values</label>
                    </div>
                    <div class="col-sm-12"
                        style="padding: 0px;margin-top: 7px;border: 1px solid #86b6d9;background:#1e90ff;color: white;font-size: 16px;">
                        <label class="col-sm-3" style="padding: 0px;">Include</label>
                        <select name="includeType" (change)="IncludeTypeChange($event)"
                            class="form-control {{FormulaPartVm.includeTypeResult}}" style="padding: 0px;">
                            <option value="0" [selected]="FormulaPartVm.includeTypeResult == 0">Always</option>
                            <option value="1" [selected]="FormulaPartVm.includeTypeResult == 1">Positive</option>
                            <option value="2" [selected]="FormulaPartVm.includeTypeResult == 2">Negative</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6"
                style="height: 610px; border: 2px solid #1e90ff; padding: 0px; color: white; font-size: 16px;">
                <div class="displayCalculation" *ngIf="FormualModelObj.FormulaDetails!=null">
                    <table class="table">
                        <tr *ngFor="let details of FormualModelObj.FormulaDetails; let i=index">
                            <td style="padding: 0px;" id="FormulaObjectDetails-{{i}}"
                                *ngIf="FormualModelObj.FormulaDetails.length > 0"
                                [ngClass]="{'table-active': i == selectedIndex}"
                                (click)="selectedFormulaChanged(i, details)">{{details.name}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-sm-5" style="border: 1px solid #1e90ff; height: 610px;">
            <div class="bs-example" *ngIf="PeriodTabViewCollection.length>0">
                <ul class="nav nav-tabs">
                    <li class="nav-item" style="margin: 0 8px -2px -1px;">
                        <select form="Payrool-payrate" class="form-control"
                            style="background: #1e90ff;color: white !important;font-size: 12px;font-weight: bold;">
                            <option value="Annual" ng-reflect-value="Annual">Annual</option>
                            <option value="All" ng-reflect-value="All">All</option>
                        </select>
                    </li>
                    <li class="nav-item" *ngFor="let result of PeriodTabViewCollection[0].results;  let i=index" 
                    style="height: 30px;"
                        (click)="ChangePeriodType(result, i)">
                        <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}" style="height: 30px; padding: 4px 7px 0 14px;"
                            data-toggle="tab">{{result.longLabel}}</a>
                    </li>
                </ul>
                <div class="currencyValueListArray col-sm-8" *ngIf="FormulaValueList!=''">
                    <!-- <div class="col-sm-12 ValueArray">
                        <label class="col-sm-5">{{FormulaValueList.name}}</label>
                    </div> -->
                    <div class="col-sm-12 display-border-after"></div>
                    <div *ngFor="let result of FormulaValueList | slice:PeriodStartIndex:PeriodEndIndex+1; let i=index">
                        <div class="col-sm-12 ValueArray">
                            <label class="col-sm-5">{{DisplayMonthCollection[i].name }}</label>
                            <input type="text" class="col-sm-5 form-control" disabled="true"
                                value="{{FormulaValueList[i] | customDecimal }}" style="text-align:end" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>