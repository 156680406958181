import { IntegrationModel } from "../model/IntegrationModel";

interface ICard {
    Id?: string;
    title?: string;
    subtitle?: string;
    content?: string;
    imageUrl?: string;
    NoofIntegration: string;
    buttonURL: string;
    Intgrations: IntegrationModel[];
    SelectedIntegrations: Integrations[];
    button: string[];
    planCode: string;
}


export class Card {
    public title: string;
    public subtitle: string;
    public content: string;
    public imageUrl: string;
    public id: string;
    public NoofIntegration: string;
    public button: string[];
    public buttonURL: string;
    public integrations: IntegrationModel[];
    public SelectedIntegrations: Integrations[];
    public planCode: string;

    constructor(obj?: ICard) {
        this.title = obj.title || 'Card Title';
        this.subtitle = obj.subtitle || 'Card Subtitle';
        this.content = obj.content ||
            'Some card content should be placed here. Description or other related information.';
        this.imageUrl = obj.imageUrl || 'https://www.infragistics.com/angular-demos-lob/assets/images/card/media/placeholder.jpg';
        this.NoofIntegration = obj.NoofIntegration || undefined;
        this.buttonURL = obj.buttonURL || '';
        this.integrations = obj.Intgrations || undefined;
        this.SelectedIntegrations = obj.SelectedIntegrations || undefined;
        this.id = obj.Id || null;
        this.button = obj.button || undefined;
        this.planCode = obj.planCode || '';
    }
}

interface IIntegration {
    title?: string;
    Id: string;
}
export class Integrations {
    public title: string;
    public Id: string;

    constructor(obj?: IIntegration) {
        this.title = obj.title || 'Integraiton Title';
        this.Id = obj.Id || '';
    }
}