import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { GSTRateService } from '../../../service/SettingServices/GSTRateService/gstrate.service';
import { CurrencyServiceService } from 'src/app/service/SettingServices/CurrencyService/currency-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gstrate-setting',
  templateUrl: './gstrate-setting.component.html',
  styleUrls: ['./gstrate-setting.component.scss']
})
export class GSTRateSettingComponent implements OnInit {

  collections: any = [];
  public ArrayValueList = [];
  GSTRatesCollection: any = [];
  GSTRatesValidColumn: any = [];
  GSTRatesValueList: any = [];
  GSTRatesSalesTaxRecordCollection: any = [];
  GSTRatesValidDerivationTypes: any = [];
  GSTRatesValidPeriods: any = [];
  GSTRatesDeferredPaymentMonths: any = null;
  ActiveRecord: any = 0;
  selectedsalesTaxRecordCollection: any = '';
  ForecastNodeId: any
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  displayBudgetList: boolean;
  DefaultSelectedIndex = 0;
  displayprefferedMonths: boolean = true;
  RateName = "";
  constructor(
    private GSTRateServices: GSTRateService,
    private SpinnerService: NgxSpinnerService,
    private CurrencyServiceServices: CurrencyServiceService,
    private router: Router) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');
  }

  async ngOnInit() {
    this.GSTRatesCollection = '';
    this.GSTRatesValueList = [];
    this.ArrayValueList = [];
    this.displayBudgetList = false;
    let ForecastNodeId = localStorage.getItem('ForecastId');
    let countryName = localStorage.getItem("CountryName");
    if (countryName == "GB") {
      this.RateName = "VAT Rates";
    } else {
      this.RateName = "GST Rates";
    }
    //  console.log(countryName);
    this.getPeriodCollection(ForecastNodeId);
    this.SpinnerService.show();
    await this.GSTRateServices.getGSTRatesCollections(ForecastNodeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.collections = response;
        console.log(this.collections);
        this.GSTRatesCollection = this.collections.gstRatesCollection;
        this.displayBudgetList = true;
        this.DefaultSelectedIndex = this.collections.defaultIndex;
        this.ActiveRecord = this.DefaultSelectedIndex;
        this.GSTRatesValueList = this.collections.collectionViewModels;
        this.changeValueList(this.ActiveRecord, this.GSTRatesCollection[this.ActiveRecord].nodeId)

        // console.log(this.GSTRatesValueList);
        this.DefaultSelectedIndex = this.collections.defaultIndex;
      })
    )
    // this.getPeriodCollection(ForecastNodeId);
  }

  /**
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
  }

  getPeriodCollection(ForecastNodeId) {
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      ((response) => {
        let PeriodViewResponse: any = response;
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        // console.log(this.PeriodTabViewCollection);
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }

  async ngOnChanges() {
    this.GSTRatesCollection = '';
    this.GSTRatesValueList = [];
    this.ArrayValueList = [];
    this.displayBudgetList = false;
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    let countryName = localStorage.getItem("CountryName");
    if (countryName == "GB") {
      this.RateName = "VAT Rates";
    } else {
      this.RateName = "GST Rates";
    }
    this.getPeriodCollection(ForecastNodeId);
    this.SpinnerService.show();
    await this.GSTRateServices.getGSTRatesCollections(ForecastNodeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.collections = response;
        console.log(this.collections);
        this.GSTRatesCollection = this.collections.gstRatesCollection;
        this.displayBudgetList = true;
        this.ActiveRecord = this.collections.selectedIndex.toString();
        this.GSTRatesValueList = this.collections.collectionViewModels;
        // console.log(this.GSTRatesValueList);

        this.DefaultSelectedIndex = this.collections.defaultIndex;
      })
    )
  }
  /**
   * 
   */
  async AddGSTRates() {
    this.SpinnerService.show();
    this.GSTRateServices.AddGSTRates(this.ForecastNodeId).subscribe(
      (async (response: any) => {
        this.SpinnerService.hide();
        this.GSTRatesCollection = '';
        this.GSTRatesValueList = [];
        this.ArrayValueList = [];
        this.displayBudgetList = false;
        this.collections = response;
        this.GSTRatesCollection = this.collections.gstRatesCollection;
        this.displayBudgetList = true;
        this.ActiveRecord = this.collections.selectedIndex.toString();
        this.GSTRatesValueList = this.collections.collectionViewModels;
      })
    )
  }
  gstRatesSave() {
    this.ngOnInit();
  }
  SettingClose() {
    this.router.navigate(['forecast']);
  }
  selectedsalesTaxRecordCollectionEvent(results) {
    this.GSTRateServices.SelectedSalesTaxSchemeIndex(this.ForecastNodeId, results.id).subscribe(
      (async response => {

      })
    )
  }
  selectedValidDerivationTypesEvent(results) {
    console.log(this.collections);
    if (results.value == 80037 || results.value == 80022) {
      this.displayprefferedMonths = false;
    }
    this.SpinnerService.show();
    this.GSTRateServices.DerivationType(this.ForecastNodeId, results.value).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.ngOnChanges();
      })
    )
  }
  selectedValidPeriodsEvent(results, $event, index) {
    let value = $event.target.value;
    console.log(value, index);
    if (index != null) {
      this.GSTRateServices.SelectedPeriodIndex(this.ForecastNodeId, index).subscribe(
        (async response => {
          //this.ngOnChanges();
        })
      )
    }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  deferredPaymentMonthsEvent($event) {
    this.GSTRateServices.SetDeferredPaymentMonths(this.ForecastNodeId, parseInt($event.target.value)).subscribe(
      (async response => {
        this.ngOnChanges();
      })
    )
  }
  /**
   * 
   * @param index 
   */
  changeValueList(index, valuesNodeId) {
    this.SpinnerService.show();
    this.ActiveRecord = index.toString();
    this.GSTRatesValueList = this.GSTRatesCollection[index];
    this.GSTRateServices.setGSTRatesindex(this.ForecastNodeId, valuesNodeId, parseInt(this.ActiveRecord)).subscribe(
      (response => {
        this.SpinnerService.hide();
        let index = response;
        this.GSTRatesValueList = response;
        //this.ActiveRecord = index.toString();
      })
    )
  }

  DeleteGSTRates() {
    this.SpinnerService.show();
    this.GSTRateServices.DeleteGSTRates(this.ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async (response: any) => {
        this.SpinnerService.hide();
        this.GSTRatesCollection = '';
        this.GSTRatesValueList = [];
        this.ArrayValueList = [];
        this.displayBudgetList = false;
        this.collections = response;
        this.GSTRatesCollection = this.collections.gstRatesCollection;
        this.displayBudgetList = true;
        this.ActiveRecord = this.collections.selectedIndex.toString();
        this.GSTRatesValueList = this.collections.collectionViewModels;
        //this.ActiveRecord = this.ActiveRecord-1;
      })
    )
  }

  DefaultGSTIndex() {
    this.GSTRateServices.DefaultGSTIndex(this.ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async (response: any) => {
        this.DefaultSelectedIndex = parseInt(response);
      })
    )
  }
  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
  /**
   * 
   * @param NodeId 
   * @param $event 
   */
  SetAllGSTRatesList(NodeId, $event) {
    if ($event.target.value == "") {
      return;
    }
    let setAllValue = $event.target.value;
    this.SpinnerService.show();
    this.GSTRateServices.SetAllGSTRates(NodeId, setAllValue).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.GSTRatesValueList = response;
      })
    )
  }

  MonthGSTRatesList(NodeId, Index, $event, budgetdata) {
    let callApi = 'false';
    let defualtValue = budgetdata.bonusCommisionValuesArray[Index];
    let updatedValue = $event.target.value;
    if (updatedValue != "") {
      let value = updatedValue;
      let convertInt = parseInt(value);
      if (this.check(value.toString()) == true) {
        let data = value.replace(/\(/g, '-').replace(/\)/g, '');
        convertInt = parseInt(data);
      } else {
      }
      if (defualtValue != convertInt) {
        callApi = 'true';
      }
    } else {
      return;
    }
    if (callApi == 'true') {
    } else {
      return;
    }
    let setAllValue = $event.target.value;
    this.SpinnerService.show();
    this.GSTRateServices.SaveValueListByMonth(NodeId, Index, setAllValue).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.GSTRatesValueList = response;
      })
    )
  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  GSTRatesSettingNameChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    console.log(Value);
    if (Value != null) {
      let replaceValue = Value.trim();
      let havepercentage = replaceValue.includes("%");
      let realvalue = replaceValue.replace("%", '')

      this.GSTRateServices.setGSTRatesName(ValuesList.nodeId, realvalue, havepercentage).subscribe(
        (response => {
          this.ngOnChanges();
        })
      )
    }

  }
}
