import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { EnterActuals } from '../model/enter-actuals';

@Injectable({
  providedIn: 'root'
})
export class EnterActualsService {

  EnterActual: EnterActuals[] = [];
  private headers: HttpHeaders;
  constructor(private http: HttpClient) { }

  /**
   * Function For get EnterActual result with values
   * @param ForecastId 
   * @param SelectedPeriodIndex 
   * @param EnterActualNodeType
   * @returns response
   */

  getEnterActulaByNodeType(ForecastId, SelectedPeriodIndex, EnterActualNodeType): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/EnterActualStart/" + ForecastId + "/" + SelectedPeriodIndex + "/" + EnterActualNodeType, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          let result: any = response[0];
          return result.row.table;
        }
      ));
  }
  ResetSelectedIndex(EnterActualNodeType,ForecastId): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/ResetSelectedIndex/" + EnterActualNodeType + "/"  + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }

  ImportFromIntegrationService(ForecastId, SelectedPeriodIndex, EnterActualNodeType, MonthIntegration, YearIntegration, rounding): Observable<any> {
    return this.http.get(environment.url + "/api/Actual/ActualImportIntegration/" + ForecastId + "/" + SelectedPeriodIndex + "/" + EnterActualNodeType + "/" + MonthIntegration + "/" + YearIntegration + "/" + rounding, { responseType: "json" })
      .pipe(map(
        (response: any) => {

        }
      ));
  }

  startActualWithCurrentActualIndex(CurrentActualsIndex): Observable<EnterActuals[]> {
    let ForecastNodeId = localStorage.getItem('ForecastId');
    return this.http.get(environment.url + "/api/Actual/StartAcualWithCurrentActualIndex/" + CurrentActualsIndex + "/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }

  startActual(ForecastNodeId): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/startActual/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }
  ResetActual(ForecastNodeId, keepActuals): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/ResetActuals/" + ForecastNodeId + "/" + keepActuals, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }
  canstartActual(ForecastNodeId): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/CanstartActual/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }

  canEnterActual(ForecastNodeId): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/CanEnterActual/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }

  ImportDataEnterActual(EnterActualbudgetData): Observable<EnterActuals[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    console.log(EnterActualbudgetData);
    return this.http.post(environment.url + "/api/Actual/SetValueFromImportExcel", EnterActualbudgetData, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }

  ResetNoToActuals(ForecastNodeId, Value): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/ResetActuals/" + ForecastNodeId+"/"+Value, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }

  CalculateActuals(NodeType, ForecastNodeId, CurrentActualIndex): Observable<EnterActuals[]> {
    return this.http.get(environment.url + "/api/Actual/CalculateActualRecord/" + NodeType + "/" + ForecastNodeId + "/" + CurrentActualIndex, { responseType: "json" })
      .pipe(map(
        (response: EnterActuals[]) => {
          return response;
        }
      ));
  }


}
