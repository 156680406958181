<div *ngIf="event!=''">
  <form class="detailsRecord">
    <div class="row" style="height: auto;">
      <div class="col-sm-4" style="margin-top: 1%;">
        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">
            Name
          </label>
          <div class="col-sm-8">
            <input  class="form-control" (blur)="clickgetdata(event.nodeId , $event )" type="text"
              value="{{event.name}}"/>
          </div>
        </div>
        <div class="form-group row" style="margin-top: 1%;">
          <label for="name" class="col-sm-2 col-form-label">
            Description
          </label>
          <div class="col-sm-8">
            <textarea id="Description" class="form-control" type="text"
              (blur)="DescriptionBulrEvent(event.nodeId , $event )" value=" {{ event.description}}"></textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-3" style="margin-top: 1%;padding: 0 0 0 0px; margin-left: -50px;">
        <div class="form-group row">
          <label for="name" class="col-sm-3 col-form-label">
            Type
          </label>
          <div class="col-sm-8">
            <div *ngIf="addEnumvalues!=''">
              <select name="recordtype" (change)="changeRecordtype($event.target.value)"
                [disabled]="!event.isEnabledNodeType" class="form-control" form="carform">
                <option *ngFor="let recordtypes of addEnumvalues; let i= index;"
                  [selected]="recordtypes.value === event.selectednodeType" value="{{recordtypes.value}}">
                  {{recordtypes.localizeName}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-3" style="margin-top: 1%;" *ngIf="event.isDepartmentVisible">
        <div class="form-group row">
          <label for="name" class="col-sm-3 col-form-label">
            Department
          </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" (blur)="UpdateDepartment(event.nodeId , $event )"
              value="{{event.departmentName}}"/>
          </div>
        </div>
      </div>
      <div class="col-sm-3 {{event.nodeType}}" style="margin-top: 1%;" *ngIf="event.nodeType!='30014'">
        <div class="form-group row">
          <label for="name" class="col-sm-6 col-form-label">
            What If Multiplier
          </label>
          <div class="col-sm-6">
            <input type="text" class="form-control" (blur)="UpdateWhatIfMultiplier(event.nodeId , $event )"
              value="{{event.whatIfMultiplier}}"/>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-sm-6 col-form-label">
            Total WI Multiplier
          </label>
          <div class="col-sm-6">
            <input type="text" disabled=disabled class="form-control" value="{{event.totalWhatIfMultiplier}}"/>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>