import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MethodserviceService } from "../../method/service/methodservice.service";
import { TreeviewService } from '../../../../service/treeview.service';
import { environment } from "../../../../../environments/environment";
import { NumericDirective } from '../../valuelist/numeric.directive';
import { element } from 'protractor';
import { MinussignsparenPipe } from '../../../../common/commonhtml/minussignsparen.pipe';
import { ValuelistComponent } from '../../valuelist/valuelist.component';
import { NgxSpinnerService } from "ngx-spinner";
//declare const $: any;
import * as $ from 'jquery';
// import { ok } from 'assert';
import { event } from 'jquery';

@Component({
  selector: 'app-consolidation-valuelist',
  templateUrl: './consolidation-valuelist.component.html',
  styleUrls: ['./consolidation-valuelist.component.scss']
})
export class ConsolidationValuelistComponent implements OnInit {

  @Input() valuelistdata;
  @Input() AllCommonDisplayBudgetData;
  @Input() valueListDerrivation;
  @Input() ValueListMonthDisplay;
  @Input() startIndex: any;

  darrivationnode: any;
  inputdarrivation: any;
  displayvaluelistdata = [];
  displaydarrivation = [];
  sectiontreeview: any;
  displaynodevisehtml: any;
  months = [];
  getProfileCollection = [];
  addeddata: boolean;

  selectedProfile: any;
  selectedNodeIds: any;
  percentagevaluearray: any;

  nodarrivationarray = [];
  darrivationarray = [];
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedloanTakenRecord: any = [];
  loantakenarray: any = {}
  additionvaluearray: any
  DerivationOptions: any;
  stockcomponent: any;
  SubDerrivationOptions: any;
  ProfileCollectionBool: boolean;
  ValidDerivationSubTypes = [];
  ValidDerivationSubTypesName = [];
  derivationSubType: any;
  PeriodTabViewStartIndex = 0;
  PeriodTabViewEndIndex = 11;

  DisplayCalculateAsBalance: boolean;

  //performance 
  CommonHtmlArrayResult: any;

  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  constructor(
    private methodservice: MethodserviceService,
    private TreeviewServices: TreeviewService,
    private ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService) {

    this.activeId = localStorage.getItem('ForecastId');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  @ViewChild('tree') tree;

  ngAfterViewInit() {
    $('.custom-OverdraftInterestRate').addClass('col-sm-5');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async onTreeLoad() {
    await this.delay(800);
    this.tree.treeModel.expandAll();
  }

  ngOnInit() {
  }

  async ngOnChanges() {
    if (this.startIndex == undefined) {
      return;
    }

    this.DisplayCalculateAsBalance = false;

    let listarray = [];
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.getProfileCollection = [];
    this.ProfileCollectionBool = false;

    this.selectedProfile = '';
    this.sectiontreeview = '';
    //this.getloopyears();
    this.inputdarrivation = '';
    this.addeddata = false;
    this.CalculatedloanTakenRecord = [];
    this.DerivationOptions = '';
    this.SubDerrivationOptions = [];
    this.ValidDerivationSubTypes = [];
    this.ValidDerivationSubTypesName = [];
    this.derivationSubType = null;
    this.displaynodevisehtml = this.valuelistdata;
    $('.OverdraftInterestRate').addClass('col-sm-5');
    if (this.valuelistdata != null && this.startIndex != undefined) {
      this.PeriodTabViewStartIndex = parseInt(this.startIndex) * 12;
      this.PeriodTabViewEndIndex = this.PeriodTabViewStartIndex + 12;
      this.PeriodTabViewStartIndex = this.PeriodTabViewStartIndex;
      this.PeriodTabViewEndIndex = this.PeriodTabViewEndIndex;
      let NodeId = this.displaynodevisehtml.recordNodeId;
      let DerivationNodeId = this.displaynodevisehtml.derivationType;
      let valueNodeId = this.displaynodevisehtml.values.valuesNodeId;
      let totaldisplayvalue = 0;
      this.nodarrivationarray = [];

      this.displaynodevisehtml.values.valuesArray.forEach((valuesarraylist, index) => {
        let stockOnhandArray = 0;
        if (this.displaynodevisehtml.hasOpening == true) {
          if (index == 0) {
            stockOnhandArray = valuesarraylist + this.displaynodevisehtml.opening;
          } else {
            stockOnhandArray = this.displaynodevisehtml.opening;
            for (let i = 0; i <= index; i++) {
              stockOnhandArray += this.displaynodevisehtml.values.valuesArray[i];
            }
          }
          valuesarraylist = stockOnhandArray;
        }
        if (this.displaynodevisehtml.nodetype == 61082) {
          if (this.displaynodevisehtml.opening >= 0) {
            this.displaynodevisehtml.opening = "(" + (this.displaynodevisehtml.opening * -1).toFixed(2) + ")";
          } else {
            this.displaynodevisehtml.opening = (this.displaynodevisehtml.opening);
          }

        } else {

        }
        this.displaynodevisehtml.opening = this.displaynodevisehtml.opening;
        valuesarraylist = valuesarraylist;
        if (Math.sign(valuesarraylist) == -1) {
          let absValue = Math.abs(valuesarraylist);
          let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          let realvalue = "(" + numberwithcomma + ")";
          this.nodarrivationarray.push(realvalue);
        } else {
          this.nodarrivationarray.push(valuesarraylist.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
        }
        //totaldisplayvalue += valuesarraylist;
      });

      let datastartIndex = this.startIndex * 12;
      let dataEndIndex = datastartIndex + 12;

      for (let t = datastartIndex; t < dataEndIndex; t++) {
        totaldisplayvalue += this.displaynodevisehtml.values.valuesArray[t];
      }


      this.displaynodevisehtml.values.valuesArray = [];
      this.displaynodevisehtml.values.valuesArray = this.nodarrivationarray;
      this.valuelistdata['total'] = totaldisplayvalue;
      if (this.valuelistdata.values.englishLabel == 'Sub Result') {
        valueNodeId = this.displaynodevisehtml.valuesNodeId;
      }
      if (this.valuelistdata.nodeTypeEnglishLabel === 'CapitalPaidNextYear' && this.valuelistdata.derivationEngType == "ManuallyEntered") {
        $('.valuelistmain' + this.valuelistdata.nodeTypeEnglishLabel).addClass('hidden');
      }
      if (this.valuelistdata.nodeTypeEnglishLabel != 'CapitalPaidNextYear') {
        this.DerivationOptions = '';
        // this.methodservice.getDerrivation(NodeId, DerivationNodeId , valueNodeId).subscribe( 
        //   async (response) => { 
        let getdarrivationallvalues: any = this.valueListDerrivation;
        if (getdarrivationallvalues.derivationOptions.length > 0) {
          let SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];
          if (SubDerrivationOptions.hasOwnProperty("items") == true) {
            this.SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];
          }
        }

        if (this.valuelistdata.values.validDerivationSubTypes != '') {
          this.ValidDerivationSubTypes = this.valuelistdata.values.validDerivationSubTypes;
          this.ValidDerivationSubTypesName = this.valuelistdata.values.validDerrivationSubTypeName;
          this.derivationSubType = this.valuelistdata.derivationSubType;
        }

        if (getdarrivationallvalues.inputs) {
          let inputvalue: any = getdarrivationallvalues.inputs;
          if (inputvalue.length > 0) {
            if (inputvalue[0].periods != undefined) {
              if (inputvalue[0].periods.length > 0) {
                inputvalue[0].periods.shift();
              }
            }
            inputvalue.forEach(element => {
              let totaldarrivationvalues = 0;
              this.percentagevaluearray = []
              this.darrivationarray = [];
              this.additionvaluearray = [];
              element.valuesArray.forEach(valuesarraylist => {
                //totaldarrivationvalues += valuesarraylist;
                if (element.englishLabel == "Percentages") {
                  this.percentagevaluearray.push((valuesarraylist));
                }
              });
              if (this.percentagevaluearray != '') {
                element.valuesArray = this.percentagevaluearray;
              }
              element.valuesArray.forEach(valuelistingarray => {
                let valuelistarrayResult = valuelistingarray;
                if (inputvalue[0].englishLabel == 'Payment Profile') {
                  if (inputvalue[0].periods.length > 0) {
                    valuelistarrayResult = (valuelistingarray * 100).toFixed(2);
                  }
                }
                if (element.display == 4) {
                  if (Math.sign(valuelistingarray) == -1) {
                    valuelistarrayResult = Math.abs(valuelistingarray);
                  }
                }
                if (Math.sign(valuelistarrayResult) == -1) {
                  let absValue = Math.abs(valuelistarrayResult);
                  let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                  let realvalue = "(" + numberwithcomma + ")";
                  this.darrivationarray.push(realvalue);
                } else {
                  this.darrivationarray.push(valuelistarrayResult.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
                }
              });
              element.valuesArray = this.darrivationarray;
              for (let t = datastartIndex; t < dataEndIndex; t++) {
                totaldarrivationvalues += element.valuesArray[t];
              }
              element['total'] = totaldarrivationvalues;

            });
          }
        }
        this.darrivationnode = this.valueListDerrivation;
        if (this.darrivationnode.derivationOptions.length > 0) {
          if (this.darrivationnode.derivationOptions[0].storedValue != null) {
            this.selectedProfile = this.darrivationnode.derivationOptions[0].storedValue.toFixed(0);
          }
          if (this.darrivationnode.derivationOptions[0].selectableEntities) {
            this.getProfileCollection = this.darrivationnode.derivationOptions[0].selectableEntities;
            this.ProfileCollectionBool = true;

          }
        }
        // //selected Ids
        // if (this.darrivationnode.selectedNodeIds != '') {
        //   this.selectedNodeIds = this.darrivationnode.selectedNodeIds;
        // }

        // treeviews
        if (this.darrivationnode.targetNodeID != '') {
          let s2 = this.darrivationnode.targetNodeID.substring(1);
          const usingSplit = s2.split(',');
          if (usingSplit != '') {
            this.TreeviewServices.getSelectedTreeMenu(s2, this.darrivationnode.derivationName, this.darrivationnode.derivationType).subscribe((response) => {
              this.addeddata = true
              //selected Ids
              if (this.darrivationnode.selectedNodeIds != '') {
                this.selectedNodeIds = this.darrivationnode.selectedNodeIds;
                // console.log(this.selectedNodeIds);
              }
              this.sectiontreeview = response;

            },
              err => {
                this.SpinnerService.hide();
                throw err;
              })
          }
        }
        //total calculations
        this.displaydarrivation.push(this.valueListDerrivation);
        this.displayvaluelistdata.push(this.valuelistdata);
        await this.delay(1000);
        if (this.displaydarrivation[0].inputs.length == 0) {
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
        }
        if (this.displaydarrivation[0].inputs.length == 0 && this.displaydarrivation[0].selection) {
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');

        }
        if (this.displaydarrivation[0].inputs.length > 0) {
          this.displaydarrivation[0].inputs.forEach(element => {
            element['customenglishname'] = element.englishLabel.replace(/\s/g, "");
          });
        }
        $('.custom-OverdraftInterestRate').addClass('col-sm-5');
        if (this.displaydarrivation[0].inputs.length >= 2) {
          if (this.displaydarrivation[0].targetNodeID != '') {
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
          } else {
            if (this.displaydarrivation[0].inputs.length >= 3) {
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col');
            } else {
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
            }

          }

        }
        if (this.displaydarrivation[0].inputs.length == 1 || this.displaydarrivation[0].inputs.length == 2 || this.displaydarrivation[0].inputs.length == 3) {
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-10');
          if (this.displaydarrivation[0].targetNodeID != '') {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
            if (((this.displaydarrivation[0].inputs.length + 3) * 2 + 1 - 1) == 8) {
              $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-7');
            } else {
              $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-' + ((this.displaydarrivation[0].inputs.length + 3) * 2 + 1 - 1));
            }

          } else {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-' + ((this.displaydarrivation[0].inputs.length + 1.5) * 2 + 1 - 1));
          }

        } else {
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
        }

        if (this.displaydarrivation[0].inputs.length == 0 && this.displaydarrivation[0].targetNodeID != '') {
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
          $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-6');
        }
        if (this.displayvaluelistdata[0].derivationEngType == 'FromStockOnHand') {
          $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4 , col-sm-3');
          $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-2');
        } else {

        }
        $('.OverdraftInterestRate').addClass('col-sm-5');
        //})
      }
      if (this.valuelistdata.nodeTypeEnglishLabel === 'CapitalPaidNextYear' && this.valuelistdata.derivationEngType != "ManuallyEntered") {

        this.displayvaluelistdata = this.valuelistdata;
        $('.valuelistmain' + this.valuelistdata.nodeTypeEnglishLabel).addClass('col-sm-4');
        $('.valuelistmain' + this.valuelistdata.nodeTypeEnglishLabel).removeClass('col-sm-3');
        this.methodservice.getLoanTakenCalcultedRecord(this.activeId, this.valuelistdata.recordNodeId).subscribe(
          (response: any) => {
            this.enumInterestTypes = this.interestTypesenum();
            this.enumpaymentTiming = this.paymenttimingenum();
            this.CalculatedloanTakenRecord = response;
            this.loantakenarray = {
              "nodeID": NodeId,
              "DisplayLoanDetailData": [
                {
                  "InterestTypes": this.CalculatedloanTakenRecord.interestTypes,
                  "PaymentFrequencies": this.CalculatedloanTakenRecord.interestTypes.numberFormatPrefix,
                  "PaymentTimings": this.CalculatedloanTakenRecord.paymentTimings,
                  "isNewLoan": this.CalculatedloanTakenRecord.isNewLoan,
                  "LoanDrawdownPeriodIndex": this.CalculatedloanTakenRecord.loanDrawdownPeriodIndex,
                  "Term": this.CalculatedloanTakenRecord.term,
                  "LoanBalance": this.CalculatedloanTakenRecord.loanBalance,
                  "InterestRate": this.CalculatedloanTakenRecord.interestRate,
                  "Payment": this.CalculatedloanTakenRecord.payment,
                  "HasDepositPayment": this.CalculatedloanTakenRecord.hasDepositPayment,
                  "CanHaveDepositPayment": this.CalculatedloanTakenRecord.canHaveDepositPayment,
                  "ShowDeposit": this.CalculatedloanTakenRecord.showDeposit,
                  "ShowDepositPayment": this.CalculatedloanTakenRecord.showDepositPayment,
                  "CanHaveBulletPayment": this.CalculatedloanTakenRecord.canHaveBulletPayment,
                  "ShowBullet": this.CalculatedloanTakenRecord.showBullet,
                  "ShowBulletPayment": this.CalculatedloanTakenRecord.showBulletPayment,
                  "CanHaveBalloonPayment": this.CalculatedloanTakenRecord.canHaveBalloonPayment,
                  "CanHaveHolidayPeriod": this.CalculatedloanTakenRecord.canHaveHolidayPeriod,
                  "HasBulletPayment": this.CalculatedloanTakenRecord.hasBulletPayment,
                  "HasBalloonPayment": this.CalculatedloanTakenRecord.hasBalloonPayment,
                  "HasHolidayPeriod": this.CalculatedloanTakenRecord.hasHolidayPeriod,
                  "DepositPayment": this.CalculatedloanTakenRecord.depositPayment,
                  "BulletPayment": this.CalculatedloanTakenRecord.bulletPayment,
                  "MonthsBulletDelay": this.CalculatedloanTakenRecord.monthsBulletDelay,
                  "BalloonPayment": this.CalculatedloanTakenRecord.balloonPayment,
                  "MonthsHoliday": this.CalculatedloanTakenRecord.monthsHoliday,
                  "LoanPrincipal": this.CalculatedloanTakenRecord.loanPrincipal,
                  "IsPeriodStartEnabled": this.CalculatedloanTakenRecord.isPeriodStartEnabled,
                  "SelectedIndex": this.CalculatedloanTakenRecord.selectedIndex
                }
              ]
            }
          },
          err => {
            this.SpinnerService.hide();
            throw err;
          })
      }

      if (this.valuelistdata.Stockcomponent == true) {
        this.stockcomponent = true;
      }
      this.CommonHtmlArrayResult = this.AllCommonDisplayBudgetData;

    }

  }


  /***
    * Change SubDerrivationOptions dropdwon event
    * 
    * change valuelist and calucaltion
    * 
    */
  changeSubderrivationOptions($event, Values, options) {
    const arr = $event.target.value.split('-');
    let valuesNodeId = Values[0].recordNodeId
    this.SpinnerService.show();
    this.methodservice.UpdateSubDerrivationOptions(parseInt(arr[0]), parseInt(arr[1]), options.selectNodeId, valuesNodeId).subscribe(
      (response) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
  /***
    * Change SubDerrivationOptions dropdwon event
    * 
    * change valuelist and calucaltion
    * 
    */
  changeSubderrivationType($event, Values) {
    let value = parseInt($event.target.value);
    let nodeId = Values[0].recordNodeId;
    let selectedNodeId = Values[0].valuesNodeId;
    this.SpinnerService.show();
    this.methodservice.UpdateSubDerrivationType(value, selectedNodeId, nodeId).subscribe(
      (response) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
  DisplayArray(data, Display) {
    let result = data;
    switch (Display) {
      case 2:
        if (result < 0)
          result = 0;
        break;
      case 3:
        if (result > 0)
          result = 0;
        break;
      case 4:
        result = -result;
        break;
      case 5:
        if (result > 0)
          result = 0;
        else
          result *= -1;
        break;
      case 6:
        if (result < 0)
          result = 0;
        else
          result *= -1;
        break;
      case 7:
        result = Math.abs(result);
        break;
      case 8:
        result = -Math.abs(result);
        break;
      case 9:
        result *= 100;
        break;
    }
    return result;
  }

  interestTypesenum() {
    let interesttypesenum = [{
      0: 'Undefined',
      1: 'CompoundInterest',
      2: 'SimpleInterest',
      3: 'InterestOnly',
    }]
    return interesttypesenum;
  }

  paymenttimingenum() {
    let paymenttimingenum = [{
      1: 'InAdvance',
      2: 'InArrears'
    }]
    return paymenttimingenum;
  }

  IsEnabled(Index, ValueList) {

    if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget)
      return ValueList.actualsMode == this.ActualsMode.Enterable;
    else
      return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
  }

  IsActual(Index, ValueList) {
    return Index <= ValueList.currentActualsIndex;
  }

  FontWight(Index, ValueList) {

    if (this.IsActual(Index, ValueList)) {
      if (ValueList.actualsMode >= this.ActualsMode.DerivedAsBudget)
        return "Bold";
      else if (ValueList.actualsMode == this.ActualsMode.Hidden)
        return "Thin";
    }
    return "Normal";
  }
  Testing(Index, ValueList) {
    if (ValueList.localizedLabel == "Exchange Movement") {
      if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget) {

        return ValueList.actualsMode == this.ActualsMode.Enterable;
      }
      else
        return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
    }
  }


  IsVisiable(Index, ValueList) {
    return Index >= ValueList.startIndex && Index <= ValueList.endIndex && (ValueList.actualsMode > this.ActualsMode.Hidden || !this.IsActual(Index, ValueList));
  }



  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Month Calculation Function
   * 
   * @ Return Budgetdata
   */

  monthCalculation($event, budgetdata, valuesNodeId, IsDerivationNumber) {
    let budgetdatavaluearray = [];

    budgetdata.valuesArray.forEach(element => {
      if (this.check(element.toString()) == true) {
        let data = element.replace(/\(/g, '-').replace(/\)/g, '');
        budgetdatavaluearray.push(parseInt(data));
      } else {
        let data = element;
        budgetdatavaluearray.push(parseInt(data));
      }
    });
    let nodetype: any;
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: Number,
      total: 0,
      startindex: Number,
      endindex: Number
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }

    if (budgetdata !== '') {
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuesNodeId[0].values.valuesNodeId;
      displaydata.values = budgetdatavaluearray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }
    this.methodservice.saveRecord(convertjson).subscribe(
      (response) => {
        response.forEach(element => {
          if (element.valuesNodeId == valuesNodeId[0].values.valuesNodeId) {
            this.valuelistdata = element;
            this.ngOnChanges();
            $('#' + valuesNodeId[0].recordNodeId).click();
          }
        });
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }

  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */

  NoderrivationMonthCalculation(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    let callApi = 'false';
    budgetdata.bonusCommisionValuesArray.forEach((element, index) => {
      if (budgetdata.valuesArray[index] != "") {
        let value = budgetdata.valuesArray[index];
        let convertInt = parseInt(value);
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '');
          convertInt = parseInt(data);
        } else {
        }
        if (element != convertInt) {
          callApi = 'true';
        }
      } else {
        return;
      }
    });

    if (callApi == 'true') {
    } else {
      return;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
      budgetdata.valuesArray[loopindexstart] = budgetdata.valuesArray[loopindexstart].replace(',', '');
      if (this.check(budgetdata.valuesArray[loopindexstart].toString()) == true) {
        let data = budgetdata.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '');
        totalgetelemtbyclassnamearray.value = parseInt(data);
        total += parseInt(data);
        resultarray.push(parseFloat(data).toFixed(2));
      } else {
        totalgetelemtbyclassnamearray.value = parseInt(budgetdata.valuesArray[loopindexstart]);
        total += parseInt(budgetdata.valuesArray[loopindexstart]);
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart]).toFixed(2))
      }
      // totalgetelemtbyclassnamearray.value = parseInt(budgetdata.valuesArray[loopindexstart]);
      // total += parseInt(budgetdata.valuesArray[loopindexstart]);
      // resultarray.push(parseInt(budgetdata.valuesArray[loopindexstart]))      
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = total;
    }
    this.SetMonthCalculation($event, budgetdata, resultarray, displaydata, Isdarrivation)
    //this.monthCalculationbysetallandtotal($event, budgetdata, resultarray, displaydata, Isdarrivation)
  }

  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */
  derrivationmonthCalculation(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    let callApi = 'false';
    budgetdata.bonusCommisionValuesArray.forEach((element, index) => {
      if (budgetdata.valuesArray[index] != "") {
        let value = budgetdata.valuesArray[index];
        let convertInt = parseInt(value);
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '');
          convertInt = parseInt(data);
        } else {
        }
        if (element != convertInt) {
          callApi = 'true';
        }
      } else {
        return;
      }
    });

    if (callApi == 'true') {
    } else {
      return;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
      // console.log(budgetdata.valuesArray[loopindexstart].toString().replace(',', ''));
      budgetdata.valuesArray[loopindexstart] = budgetdata.valuesArray[loopindexstart].toString().replace(',', '');

      if (budgetdata.customenglishname == "PaymentProfile") {
        totalgetelemtbyclassnamearray.value = parseInt(budgetdata.valuesArray[loopindexstart]);
        total += parseInt(budgetdata.valuesArray[loopindexstart]);
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart]).toFixed(2))
      } else {
        totalgetelemtbyclassnamearray.value = parseFloat(budgetdata.valuesArray[loopindexstart]).toFixed(2);
        total += parseInt(budgetdata.valuesArray[loopindexstart]);
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart]).toFixed(2))
      }

    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = total;
    }
    this.SetMonthCalculation($event, budgetdata, resultarray, displaydata, Isdarrivation)
    //this.monthCalculationbysetallandtotal($event, budgetdata, resultarray, displaydata, Isdarrivation)
  }
  /**
   * 
   * @param eventdata 
   * @param displaydata 
   */

  monthCalculationbysetallandtotal($event, budgetdata, resultarray, valuesNodeId, IsDerivationNumber) {
    let nodetype: any;
    let valuenodeId = valuesNodeId[0].values.valuesNodeId;
    let openingbalance: any = 0;
    let budgetdatavaluearray = [];
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: 0,
      total: 0,
      startindex: Number,
      endindex: Number,
      opening: Number,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }


    if (budgetdata !== '') {
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = valuesNodeId[0].valuesnodeId;
      }
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuenodeId;
      displaydata.opening = openingbalance;
      displaydata.values = resultarray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.setAllValue = 0;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }
    this.SpinnerService.show();
    this.methodservice.saveRecord(convertjson).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        //this.ValuelistComponent.DisplayAllCommonDataArray = response
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
  /***
   * Change even spread dropdwon event
   * 
   * change valuelist and calucaltion
   * 
   */
  changeEvenSpreaddata(eventdata, displaydata) {
    this.methodservice.selectProfileOptions(eventdata, displaydata[0].recordNodeId, displaydata[0].values.valuesNodeId).subscribe(
      (response) => {
        let nodeId = displaydata[0].recordNodeId
        this.methodservice.getBudgetData(nodeId).subscribe(
          (data) => {
            data.forEach(element => {
              if (element.valuesNodeId == displaydata[0].values.valuesNodeId) {
                this.valuelistdata = element;
                this.displaydarrivation = response;
                this.ngOnChanges();
              }
            });
          })
        //this.ngOnChanges();
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }

  countChangedHandler(count, BudgetResult) {
    if (count.option == true) {
      let arrayvalues = [];
      this.SpinnerService.show();
      // console.log(count.node, BudgetResult.recordNodeId, BudgetResult.valuesNodeId);
      this.methodservice.selectedNodeService(count.node, BudgetResult.recordNodeId, BudgetResult.valuesNodeId).subscribe(
        (response: any) => {
          let oldBudgetdata = this.valuelistdata;
          let oldDerrivationData = this.displaydarrivation;
          let NewBudgetData = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
          NewBudgetData.forEach((element, index) => {
            this.SpinnerService.hide();
            if (element.valuesNodeId == oldBudgetdata.valuesNodeId) {
              this.valuelistdata = [];
              this.displaydarrivation = [];
              this.valuelistdata = element;
              if (this.selectedNodeIds != null) {
                this.selectedNodeIds.push(count.node)
              } else {
                this.selectedNodeIds = [];
                this.selectedNodeIds.push(count.node)
              }

              this.displaydarrivation = response.getAllDisplayBudgetDataValue[0].getDataDerrivation[index];
              this.ngOnChanges();
            }
          });

        },
        err => {
          this.SpinnerService.hide();
          throw err;
        })
    }
    if (count.option == false) {
      this.SpinnerService.show();
      this.methodservice.unSelectedNodeService(count.node, BudgetResult.recordNodeId, BudgetResult.valuesNodeId).subscribe(
        (response: any) => {
          let oldBudgetdata = this.valuelistdata;
          let oldDerrivationData = this.displaydarrivation;
          let NewBudgetData = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
          NewBudgetData.forEach((element, index) => {
            this.SpinnerService.hide();
            if (element.valuesNodeId == oldBudgetdata.valuesNodeId) {
              this.valuelistdata = [];
              this.displaydarrivation = [];
              this.valuelistdata = element;
              for (var i = 0; i < this.selectedNodeIds.length; i++) {
                if (this.selectedNodeIds[i] == count.node) {
                  this.selectedNodeIds.splice(i, 1);
                }
              }
              this.displaydarrivation = response.getAllDisplayBudgetDataValue[0].getDataDerrivation[index];
              this.ngOnChanges();
            }
          });

        },
        err => {
          this.SpinnerService.hide();
          throw err;
        })
    }
  }

  //Calculate As Balance
  CalculateAsBalance(recordNodeId, event) {
    let selectedOption = 0;
    if (event.target.checked == true) {
      selectedOption = 1;
    }
    this.methodservice.CalculateAsBalance(recordNodeId.selectNodeId, selectedOption).subscribe(
      (response) => {
        return response;
      }
    )

  }

  // setall functionality
  darrivationsetall(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (displaydata.currentActualsIndex != -1) {
        if (displaydata.currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseInt((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseInt((totalgetelemtbyclassnamearray[loopindexstart].value)))
          }
        }
        else {
          if (parseInt(($event.target.value)) != undefined) {
            totalgetelemtbyclassnamearray.value = parseInt(($event.target.value));
            total += parseInt(($event.target.value));
            resultarray.push(parseInt(($event.target.value)))
            totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
          }
        }
      } else {
        if (parseInt(($event.target.value)) != undefined) {
          totalgetelemtbyclassnamearray.value = parseInt(($event.target.value));
          total += parseInt(($event.target.value));
          resultarray.push(parseInt(($event.target.value)))
          totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
        }
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = total;
    }
    this.SetAllCalculation($event, displaydata, resultarray, budgetdata, Isdarrivation)
    //this.monthCalculationbysetallandtotal($event, displaydata, resultarray, budgetdata, Isdarrivation)
  }

  noDarivationsetall(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let total = 0;
    let resultarray = [];

    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseInt((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseInt((totalgetelemtbyclassnamearray[loopindexstart].value)))
          }
        }
        else {
          if (parseInt(($event.target.value)) != undefined) {
            total += parseInt(($event.target.value));
            resultarray.push(parseInt(($event.target.value)));

            totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
          }
        }
      } else {
        if (parseInt(($event.target.value)) != undefined) {
          total += parseInt(($event.target.value));
          resultarray.push(parseInt(($event.target.value)))
          totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
        }
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    inputValue.value = total;
    this.SetAllCalculation($event, budgetdata, resultarray, displaydata, Isdarrivation);
    //this.monthCalculationbysetallandtotal($event, budgetdata, resultarray, displaydata, Isdarrivation)
  }

  noDarivationopening(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let total = 0;
    let resultarray = [];
    let openingDataValue = displaydata[0].openingResult;
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var n;
    for (let loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; loopindexstart++) {
      // let sumvalue = parseInt($event.target.value) + displaydata[0].values.valuesArray[loopindexstart];
      // let realvalue = ((sumvalue)-(openingDataValue));
      // let addOpeningValue:any = realvalue;
      // totalgetelemtbyclassnamearray[loopindexstart].value = addOpeningValue;
      // resultarray.push(parseInt(( totalgetelemtbyclassnamearray[loopindexstart].value)))

      if (this.check(displaydata[0].values.valuesArray[loopindexstart].toString()) == true) {
        let data = displaydata[0].values.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '');
        let sumvalue = parseInt(data.replace('-', ''));
        let value = (-(sumvalue) + (parseInt($event.target.value)));
        let absValue = Math.abs(value);
        let realvalue = null;
        if (value >= 0) {
          realvalue = absValue;
        } else {
          realvalue = "(" + absValue + ")";
        }
        totalgetelemtbyclassnamearray[loopindexstart].value = realvalue;
        resultarray.push((totalgetelemtbyclassnamearray[loopindexstart].value))
      } else {
        let sumvalue = displaydata[0].values.valuesArray[loopindexstart];
        let realvalue = ((sumvalue) + (parseInt($event.target.value)));
        let addOpeningValue: any = realvalue;
        totalgetelemtbyclassnamearray[loopindexstart].value = addOpeningValue;
        resultarray.push(parseInt((totalgetelemtbyclassnamearray[loopindexstart].value)))
      }
    }
    this.monthCalculationbyOpening($event, budgetdata, resultarray, displaydata, Isdarrivation, parseInt($event.target.value))
  }

  noDarrivationPercentage(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      total += parseInt(($event.target.value));
      resultarray.push(parseInt(($event.target.value)))
      totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    inputValue.value = total;
    // console.log($event, budgetdata, resultarray , displaydata , Isdarrivation);
    // this.monthCalculationbysetallandtotal($event, budgetdata, resultarray , displaydata , Isdarrivation)
  }


  /**
   * 
   * @param eventdata 
   * @param displaydata 
   */

  monthCalculationbyOpening($event, budgetdata, resultarray, valuesNodeId, IsDerivationNumber, openingDataValue) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let nodetype: any;
    let valuenodeId = valuesNodeId[0].values.valuesNodeId;
    let openingbalance: any = openingDataValue;
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: Number,
      total: 0,
      startindex: Number,
      endindex: Number,
      opening: Number,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,

      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }

    if (budgetdata !== '') {
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = valuesNodeId[0].valuesnodeId;
      }

      let ValuesArray = [];
      resultarray.forEach(element => {
        if (this.check(element.toString()) == true) {
          let value = parseInt(element.replace(/\(/g, '-').replace(/\)/g, ''));
          ValuesArray.push(value);
        } else {
          ValuesArray.push(parseInt(element));
        }
      });
      let eventValue: any = parseInt($event.target.value);
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuenodeId;
      displaydata.opening = eventValue;
      displaydata.values = ValuesArray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }
    this.SpinnerService.show();
    this.methodservice.saveOpening(convertjson).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;

      })
  }

  noDarivationtotal(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    let total = 0;
    let ActualTotal = 0;
    let resultarray = [];
    var loopindexstart;
    var n;
    var Actaulloopindexstart;
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    if (displaydata[0].currentActualsIndex != -1) {
      for (Actaulloopindexstart = 0; Actaulloopindexstart <= displaydata[0].currentActualsIndex; ++Actaulloopindexstart) {
        ActualTotal += parseInt(totalgetelemtbyclassnamearray[Actaulloopindexstart].value);
      }
      var actualModelenght = totalgetelemtbyclassnamearray.length - (displaydata[0].currentActualsIndex + 1);
      var ActualMonthCollection = ($event.target.value - ActualTotal);
      var ActualMonthCollectionValue = parseInt((ActualMonthCollection / actualModelenght).toFixed(0));
      var addvaluebasedon_total = actualModelenght.toFixed(0);
    } else {
      var addvaluebasedon_total = ($event.target.value / totalgetelemtbyclassnamearray.length).toFixed(0);
    }

    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {

      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          resultarray.push(parseInt((totalgetelemtbyclassnamearray[loopindexstart].value)));
        }
        else {
          resultarray.push((ActualMonthCollectionValue));
          totalgetelemtbyclassnamearray[loopindexstart].value = ActualMonthCollectionValue;
        }
      } else {
        resultarray.push(parseInt((addvaluebasedon_total)));
        totalgetelemtbyclassnamearray[loopindexstart].value = addvaluebasedon_total;
      }
    }
    this.monthCalculationbysetallandtotal($event, budgetdata, resultarray, displaydata, Isdarrivation)
  }

  //
  SetAllCalculation($event, budgetdata, resultarray, valuesNodeId, IsDerivationNumber) {

    let nodetype: any;
    let valuenodeId = valuesNodeId[0].values.valuesNodeId;
    let openingbalance: any = 0;
    let budgetdatavaluearray = [];
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: 0.00,
      total: 0,
      startindex: null,
      endindex: null,
      opening: Number,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }


    if (budgetdata !== '') {
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = valuesNodeId[0].valuesnodeId;
      }

      resultarray.forEach((element, index) => {
        let deimalvalue = parseFloat(resultarray[index]).toFixed(2);
        resultarray[index] = parseFloat(deimalvalue);
      });
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuenodeId;
      displaydata.opening = openingbalance;
      displaydata.values = resultarray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      //displaydata.startindex = budgetdata.startIndex;
      //displaydata.endindex = budgetdata.endIndex;
      displaydata.startindex = this.PeriodTabViewStartIndex;
      displaydata.endindex = this.PeriodTabViewEndIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
      let setallvalue = $event.target.value.replace(/,/g, '');
      let deimalvalue = parseFloat(setallvalue).toFixed(2);
      displaydata.setAllValue = parseFloat(deimalvalue);
    }

    this.SpinnerService.show();
    this.methodservice.setAllRecordSaveValue(convertjson).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
      })
  }

  SetMonthCalculation($event, budgetdata, resultarray, valuesNodeId, IsDerivationNumber) {
    // console.log($event.target.value.replace(/,/g, ''))
    let nodetype: any;
    let valuenodeId = valuesNodeId[0].values.valuesNodeId;
    let openingbalance: any = 0;
    let splitArrayIndex = ($event.target.id.toString()).split("-");
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      total: 0,
      startindex: this.PeriodTabViewStartIndex,
      endindex: this.PeriodTabViewEndIndex,
      opening: Number,
      value: 0.00,
      index: null,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      RecordNodeId: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }


    if (budgetdata !== '') {
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = valuesNodeId[0].valuesnodeId;
      }
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuenodeId;
      convertjson.RecordNodeId = this.valuelistdata.nodeId;
      displaydata.opening = openingbalance;
      displaydata.values = resultarray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      if (splitArrayIndex[1] == "months") {
        displaydata.index = parseInt(splitArrayIndex[2]);
      } else {
        displaydata.index = parseInt(splitArrayIndex[1]);
      }
      if (splitArrayIndex[0] == "Payment Profile") {
        displaydata.index = parseInt(splitArrayIndex[2]);
      }
      let deimalvalue = parseFloat($event.target.value.replace(/,/g, '').toString()).toFixed(2);

      displaydata.value = parseFloat(deimalvalue);
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }

    this.SpinnerService.show();
    this.methodservice.setMonthRecordSaveValues(convertjson).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
      })
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }
  changeInterestType($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['InterestTypes'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }

  changepaymentFrequcncy($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentFrequencies'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }
  changeTerm($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['Term'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }
  changeLoanBalance($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['LoanBalance'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }
  changeInterestRate($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['InterestRate'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }
  changePayment($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['Payment'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }

  changeBaloon($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['BalloonPayment'] = parseInt($event);
    this.methodservice.updateLoantakenRecord(this.loantakenarray).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + this.loantakenarray.nodeID).click();
      })
  }

  IsnewLoanchangeEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-periodStart').addClass('hidden');
    } else {
      $('.loantakenrecord-periodStart').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['IsNewLoan'] = $event.target.checked;
    this.updatearray(this.loantakenarray)
  }

  // onchange bullet event
  OnchangeBullet($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-BulletPayment').addClass('hidden');
      $('.loantakenrecord-PaymentMonth').addClass('hidden');
    } else {
      $('.loantakenrecord-BulletPayment').removeClass('hidden');
      $('.loantakenrecord-PaymentMonth').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['HasBulletPayment'] = $event.target.checked;
    this.updatearray(this.loantakenarray)
  }
  changeBulletPayment($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['BulletPayment'] = parseInt($event);
  }
  changeBulletPaymentMonth($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsBulletDelay'] = parseInt($event);
    this.updatearray(this.loantakenarray);
  }
  // update holiday(Month)
  changeholidaymonthText($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsHoliday'] = parseInt($event);
    this.updatearray(this.loantakenarray);
  }

  // change deposite code 
  changeDeposite($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['DepositPayment'] = parseInt($event);
    this.updatearray(this.loantakenarray);
  }
  // update record
  updatearray(updateArrayValue) {
    this.methodservice.updateLoantakenRecord(updateArrayValue).subscribe(
      async (response: any) => {
        await this.delay(200);
        $("#" + updateArrayValue.nodeID).click();
      })
  }

}
