<div *ngIf="displayHeader">
    <app-header></app-header>
</div>
<div class="subscribeContainer">
    <div class="cardAllignement">
        <div class="sample-column card-wrapper" *ngFor="let card of cardsarray">
            <igx-card [horizontal]="horizontal" elevated>
                <div class="igxcardheader">
                    <h3 igxCardHeaderTitle style="align-self: center;font-size: 25px !important;
    font-weight: bolder;">{{ card.title }}</h3>
                </div>
                <igx-card-media>
                    <img [src]="card.imageUrl" style="height: 95px; padding: 10px;">
                </igx-card-media>

                <div style="background-color:  rgb(30, 144, 255) !important;display: flex; justify-content: center;">
                    <h5 igxCardHeaderSubtitle
                        style="font-weight: bolder; font-size:20px; color:white; align-self: center;">{{
                        card.subtitle }}</h5>
                </div>
                <igx-card-content>
                    <div class="featureList">
                        <igx-list style="width: 100%;" [displayDensity]="density">
                            <igx-list-item [isHeader]="true" style="font-weight: bold;font-size:18px; margin:5px;">
                                Features
                            </igx-list-item>
                            <igx-list-item class="feature" igxRipple igxRippleTarget=".igx-list__item-content"
                                *ngFor="let features of card.content.split('|')">
                                <igx-icon [style.color]="'#1e90ff'">check</igx-icon>
                                <span style="margin: 5px;" igxListLineTitle>{{features}}</span>
                            </igx-list-item>
                        </igx-list>
                    </div>
                    <div class="integrations">
                        <div *ngIf="card.integrations">
                            <div style="font-weight: bold; margin-bottom: 10px;">Integrations (Please select
                                {{card.NoofIntegration}} integrations.)</div>
                            <div *ngFor="let inte of card.integrations" class="integrationCheck">
                                <input type="checkbox" style="margin-right:10px;"
                                    (click)="checkBoxValidation($event, inte, card)" />
                                <label style="margin: 0px;">{{inte.integration}}</label>
                            </div>
                        </div>
                    </div>

                </igx-card-content>

                <igx-card-actions style=" align-items: center;padding: 20px;justify-content: center !important;">
                    <button igxButton="button" [style.color]="'#ECAA53'" class="OkSaveButtonColor"
                        style="width: 100px;background-color: #1e90ff;z-index:999999999 "
                        *ngFor="let button of card.button" (click)="handleClick(card)" igxStart>
                        {{button}}
                    </button>
                    <!-- <button igxButton="icon" igxRipple [igxRippleCentered]="true" *ngFor="let icon of card.icons">
                        <igx-icon [style.color]="'#ECAA53'">{{icon}}</igx-icon>
                    </button> -->
                </igx-card-actions>

            </igx-card>
        </div>
    </div>
</div>
<div>
    <Label>Please remeber to provide the email ID registered to Forecast5 while purchasing the subscription.</Label>
</div>