import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { TreeviewService } from '../../service/treeview.service';
import { EnterActualsService } from '../../service/enter-actuals.service';
import { Treeview } from '../../service/treeview';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { Record } from '../../model/record';
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from '../../service/menu.service';

import * as XLSX from 'xlsx';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-forecasttabs',
  templateUrl: './forecasttabs.component.html',
  styleUrls: ['./forecasttabs.component.scss']
})
export class ForecasttabsComponent implements OnInit {
  Treeviews: Treeview[] = [];
  mainchildren = [];
  record: Record[] = [];
  details: any = [];
  months = []
  clickedtreearray = [];
  startdate = '';
  activeId: any;
  forecastname = '';
  isConsolidation = false;
  mini = true
  CallingBudgetData = true;
  //@ViewChild('tree') tree;

  @Input() event: Event;
  public clickedEvent = [];
  public budgetListView = [];
  public DefaultProfile: any;
  public treenodeId: any;
  public budgetdatatotal = [];
  public SelectedNodeDetailBinding: any;
  selectedNodedetail: any;
  openingdata: any;
  router: Router;
  sectionId: any;
  treeNodeFolderId: any;
  IsCanActualStartActive = '';
  IsCanActualEnterActive = '';
  IsActual: any;
  EnterOpenigCreditorNode: any = ''
  EnterOpeningDebtorsNode: any = '';
  DisplayDerrivationData: any = [];
  MonthDisplay: any = [];
  PeriodTabView: any = [];
  treemenuId: any;

  loandetails: any;
  recordNodeType: any;
  ActiveTreenodeId: any

  constructor(private treeview: TreeviewService, private _router: Router,
    private SpinnerService: NgxSpinnerService,
    public menuServices: MenuService
  ) {
    this.activeId = localStorage.getItem('ForecastId');
    if (this.activeId == '') {
      this._router.navigateByUrl('/forecast-list');
    }
    this.IsCanActualStartActive = localStorage.getItem('IsCanStartActual');
    this.IsCanActualEnterActive = localStorage.getItem('IsCanEnterActual');
    let mini = true;
    this.forecastname = localStorage.getItem('ForecastName');
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
    let firsttieload = localStorage.getItem("Firsttimeload");
    // if(firsttieload!=''){
    //   localStorage.setItem("Firsttimeload",'');
    //   window.location.reload();
    // }
  }
  openForecastList() {
    this._router.navigateByUrl('/forecast-list');
  }



  ngOnChange() {
    this.SpinnerService.show();
    this.treemenuId = this.activeId;
    this.IsCanActualStartActive = localStorage.getItem('IsCanStartActual');
    this.IsCanActualEnterActive = localStorage.getItem('IsCanEnterActual');
    this.delay(200);
    this.SpinnerService.hide();

  }
  ngAfterViewInit() {
    this.IsCanActualStartActive = localStorage.getItem('IsCanStartActual');
    this.IsCanActualEnterActive = localStorage.getItem('IsCanEnterActual');
  }

  ngOnInit() {
    this.SpinnerService.show();
    this.IsCanActualStartActive = localStorage.getItem('IsCanStartActual');
    this.IsCanActualEnterActive = localStorage.getItem('IsCanEnterActual');
    let firsttieload = localStorage.getItem("Firsttimeload");
    this.delay(200);
    this.SpinnerService.hide();
    if (firsttieload != '') {
      localStorage.setItem("Firsttimeload", '');
      window.location.reload();
    }

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getloopyears() {
    // this.treeview.getforecastDetails(this.activeId).subscribe
    //   ((response) => {
    //     this.details = response;
    //     if (this.details) {
    //       var date = new Date(this.details.startdate);
    //       var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //       for (var i = 0; i < 12; i++) {
    //         this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
    //         date.setMonth(date.getMonth() + 1);
    //       }
    //     }
    //   })
    let startDate = localStorage.getItem('Forecaststartdate');
    var date = new Date(startDate);
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (var i = 0; i < 12; i++) {
      this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
      date.setMonth(date.getMonth() + 1);
    }
  }

  setForecast(event) {
    // console.log("setForecast", event);
    this.activeId = event;
    localStorage.setItem('ForecastId', event);
    window.location.reload();
  }
  public month = [];
  changeNegativeToBraces(value) {
    var valueToReturn = value;
    if (valueToReturn.indexOf('-') == 0) {
      valueToReturn = valueToReturn.replace(/-/, '(') + ')';
    }
    return valueToReturn;
  }


  /**
   * RecordType Click Event 
   * @param event 
   */
  async recordtypeClickEvent(event) {
    this.months = [];
    this.getloopyears();
    let jasondata: any = this.clickedEvent;
    this.SpinnerService.show();
    this.treeview.changeRecordTypeData(jasondata.nodeId, event).subscribe(
      async (changeRecordResponse: any) => {
        //let response = changeRecordResponse.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.SpinnerService.hide();
        // this.loadBudgetData(changeRecordResponse);
        //console.log("changeRecordResponse", changeRecordResponse);
        this.childEventClicked(jasondata.nodeId, true, null);
      })

  }

  /**
   * Treenode click event function
   * @param event 
   */
  async childEventClicked(event: Event, isLoadingRequired: boolean = true, budgetData: any = null) {
    localStorage.removeItem('recordSelected');
    this.SpinnerService.show();
    let RecordNodeId: any = event;
    localStorage.setItem("recordNodeId", RecordNodeId);
    let FormulaTreeViewId = localStorage.getItem("FormulaTreeUpdateRecordNodeId");
    let checksectionId: any = event;
    if (FormulaTreeViewId != null && FormulaTreeViewId != undefined) {
      if (parseInt(FormulaTreeViewId) != parseInt(checksectionId)) {
        localStorage.setItem("FormulaRecordTree", null);
      }
    }
    this.treeNodeFolderId = null;
    this.SelectedNodeDetailBinding = event;
    localStorage.setItem("SelectedNodeDetailBinding", this.SelectedNodeDetailBinding);
    this.budgetListView = [];
    this.clickedEvent = [];
    this.months = [];
    this.treenodeId = event;
    this.budgetdatatotal = [];
    let parentElement = document.getElementById(checksectionId).parentNode.firstElementChild.firstElementChild.className;
    let Issection = parentElement.includes("section-icon-css");
    let IsFolder = parentElement.includes('folder-icon-css');
    let IsDepartment = parentElement.includes('department-icon-css');
    // setTimeout(() => {
    //   this.SpinnerService.show();
    // }, 1000);
    this.treeview.getRecordDetail(event).subscribe(
      (response: Record[]) => {
        this.record = response;
        this.SelectedNodeDetailBinding = response;
        let recorddata: any = response;
        this.clickedEvent = this.record;
        this.recordNodeType = recorddata.nodeType;
        localStorage.setItem('recordSelected', JSON.stringify(response));
        if (recorddata.canAddRecord == true) {
          $(".treetopbarRecordMenu").addClass("ActiveMenu");
          $(".treetopbarRecordMenu").removeClass("InActiveMenu");
        } else {
          $(".treetopbarRecordMenu").removeClass("ActiveMenu");
          $(".treetopbarRecordMenu").addClass("InActiveMenu");
        }
        if (recorddata.canAddFolder == true) {
          $(".treetopbarFolderMenu").addClass("ActiveMenu");
          $(".treetopbarFolderMenu").removeClass("InActiveMenu");
        } else {
          $(".treetopbarFolderMenu").addClass("InActiveMenu");
          $(".treetopbarFolderMenu").removeClass("ActiveMenu");
        }
        if (recorddata.canAddSection == true) {
          $(".treetopbarSectionMenu").removeClass("InActiveMenu");
          $(".treetopbarSectionMenu").addClass("ActiveMenu");
        } else {
          $(".treetopbarSectionMenu").removeClass("ActiveMenu");
          $(".treetopbarSectionMenu").addClass("InActiveMenu");
        }
        this.clickedtreearray = [this.record];
      }
    )
    if (Issection == true) {
      this.sectionId = checksectionId
      this.SpinnerService.hide();
      return
    }
    if (Issection == false) {
      this.sectionId = null;
    }
    if (IsFolder == true || IsDepartment == true) {
      this.treeNodeFolderId = checksectionId;
      return;
    } else {
      this.treeNodeFolderId = null;
      this.treeNodeFolderId = null;
    }
    let storgesessiondata = localStorage.getItem('budgetdata');
    if (storgesessiondata == '') {
    } else {
      localStorage.setItem('budgetdata', '');
    }

    this.getloopyears();
    this.SpinnerService.show();
    setTimeout(() => {
      this.SpinnerService.show();
    }, 900);
    if (isLoadingRequired) {
      setTimeout(() => {
        this.SpinnerService.show();
      }, 900);
      this.treeview.getAllDisplayBudgetData(event).subscribe(
        ((response: any) => {
          if (response != null) {
            this.loadBudgetData(response);
            this.CallingBudgetData = false;
            //this.SpinnerService.hide();
          } else {
            this.SpinnerService.show();
          }

        })
      )
    }
    else {
      this.SpinnerService.show();
      this.loadBudgetData(budgetData);
      this.CallingBudgetData = false;
      this.SpinnerService.hide();

    }
    setTimeout(() => {
      this.SpinnerService.hide();
    }, 7000);

    //this.SpinnerService.hide();
  }

  async loadBudgetData(response: any) {
    let reversarray = false;
    let stockcomponent = false;
    let stockMadecomponent = false;
    // await this.treeview.getNodeDetail(response.nodeId).subscribe((response: Record[]) => {
    //   let recorddata: any = response;

    // })
    if (this.recordNodeType == 50032) {
      stockcomponent = true;
    }
    if (this.recordNodeType == 50007) {
      stockMadecomponent = true;
    }

    if (response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData.length == 0) {
      //this.SpinnerService.show();
    } else {
      let DisplayBudgetData = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
      this.DisplayDerrivationData = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      this.MonthDisplay = response.getAllDisplayBudgetDataValue[0].getMonthDisplay;
      this.PeriodTabView = response.getPeriodTabView;
      let valuelist = [];
      let newResponseData = [];
      let totalvalue = 0;
      let valuenodeId = 'null';

      this.loandetails = response.loandetails
      this.recordNodeType = response.recordNodeType
      this.ActiveTreenodeId = response.nodeId;

      for (let interation = 0; interation < DisplayBudgetData.length; interation++) {
        if (DisplayBudgetData[interation].nodeTypeEnglishLabel == 'CapitalPaidNextYear') {
          reversarray = true;
        }
        if (DisplayBudgetData[interation].valuesNodeId) {
          valuenodeId = DisplayBudgetData[interation].valuesNodeId;
        }
        // console.log(DisplayBudgetData);
        this.openingdata = DisplayBudgetData[interation].opening;
        newResponseData.push({
          allowOpeningEntry: DisplayBudgetData[interation].allowOpeningEntry,
          allowSetAllEntry: DisplayBudgetData[interation].allowSetAllEntry,
          allowTotalEntry: DisplayBudgetData[interation].allowTotalEntry,
          allowValueEntry: DisplayBudgetData[interation].allowValueEntry,
          currentActualsIndex: DisplayBudgetData[interation].currentActualsIndex,
          decimalPlaces: DisplayBudgetData[interation].decimalPlaces,
          derivationEngType: DisplayBudgetData[interation].derivationEngType,
          display: DisplayBudgetData[interation].display,
          endIndex: DisplayBudgetData[interation].endIndex,
          englishLabel: DisplayBudgetData[interation].englishLabel,
          hasTotal: DisplayBudgetData[interation].hasTotal,
          isBalance: DisplayBudgetData[interation].isBalance,
          isConsolidation: DisplayBudgetData[interation].isConsolidation,
          isSingleUpdate: DisplayBudgetData[interation].isSingleUpdate,
          nodeTypeEnglishLabel: DisplayBudgetData[interation].nodeTypeEnglishLabel,
          recordNodeId: DisplayBudgetData[interation].recordNodeId,
          roundingGroup: DisplayBudgetData[interation].roundingGroup,
          startIndex: DisplayBudgetData[interation].startIndex,
          validDerivationTypes: DisplayBudgetData[interation].validDerivationTypes,
          derivationType: DisplayBudgetData[interation].derivationType,
          values: DisplayBudgetData[interation].values,
          valuelisttotal: totalvalue < 0 ? '(' + Math.abs(totalvalue) + ')' : totalvalue,
          validDerrivationTypeName: DisplayBudgetData[interation].validDerrivationTypeName,
          valuesNodeId: valuenodeId,
          nodeType: DisplayBudgetData[interation].nodeType,
          displaylenghtofinput: '3',
          Stockcomponent: stockcomponent,
          StockMadecomponent: stockMadecomponent,
          opening: this.openingdata,
          hasOpening: DisplayBudgetData[interation].hasOpening,
          openingResult: this.openingdata,
          derivationSubType: DisplayBudgetData[interation].derivationSubType,
          nodeId: DisplayBudgetData[interation].nodeId,
          valuesArray: DisplayBudgetData[interation].valuesArray,
        });
        valuelist = [];
        totalvalue = 0;
      }
      if (reversarray == true) {
        // newResponseData.unshift(newResponseData.pop());
      }
      this.budgetListView = newResponseData;
      if (this.budgetListView[0].currentActualsIndex != -1) {
        localStorage.setItem('IsCanEnterActual', 'true');
        localStorage.setItem('IsCanStartActual', 'false');
      } else {
        localStorage.setItem('IsCanEnterActual', 'false');
        localStorage.setItem('IsCanStartActual', 'true');
      }
      // console.log(this.budgetListView);
      this.months.push(this.budgetListView);
      this.budgetListView = this.months;
      this.budgetdatatotal = response[1];
      this.DefaultProfile = response.defaultProfile.valuesArray;
      localStorage.removeItem("DefaultProfile");
      // console.log("DefaultProfile called", this.DefaultProfile);
      if (this.DefaultProfile != undefined && this.DefaultProfile != null) {
        localStorage.setItem("DefaultProfile", JSON.stringify(this.DefaultProfile));
      }

    }

  }


  /**
   * add new record click event
   */
  addnewrecord() {
    let activedata = this.clickedEvent;
    for (let appViewState of this.clickedtreearray) {
      if (appViewState.canAddRecord === true) {
        this.SpinnerService.show();
        this.treeview.addNewSampleRecord(appViewState.nodeId).subscribe(
          (response) => {
            this.SpinnerService.hide();
            window.location.reload();
          }
        )
      } else {
        // alert('You are not able to add new record');
      }
    }
  }

  async AddSection() {
    let activedata = this.clickedEvent;
    for (let appViewState of this.clickedtreearray) {
      if (appViewState.canAddSection === true) {
        this.SpinnerService.show();
        await this.menuServices.addSection(appViewState.nodeId).subscribe
          ((response: any) => {
            this.SpinnerService.hide();
            window.location.reload();
          }
          )
      } else {
        // alert('You are not able to add new section');
      }
    }
  }

  async AddFolder() {
    let activedata = this.clickedEvent;
    for (let appViewState of this.clickedtreearray) {
      if (appViewState.canAddFolder === true) {
        this.SpinnerService.show();
        await this.menuServices.addFolder(appViewState.nodeId).subscribe
          ((response: any) => {
            this.SpinnerService.hide();
            window.location.reload();
          }
          )
      } else {
        //alert('You are not able to add new folder');
      }
    }
  }


}
