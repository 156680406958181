<app-header></app-header>
<div class="registrationPage">

    <div class="registration">

        <div class="register-container registration-header">

            <div class="registrationForm">
                <div class="centerAlignText">
                    <h2>Creat New Password</h2>
                </div>
                <form [formGroup]="userCreatPasswordForm" class="userregistrationForm" (ngSubmit)="onSubmit()">
                    <div>
                        <app-input [control]="userCreatPasswordForm.controls.Password" type="password"
                            placeholder="Enter New Password" text="New Password" icon="lock">
                        </app-input>
                        <app-input [control]="userCreatPasswordForm.controls.ConfirmPassword" type="password"
                            placeholder="Enter Confirm Password" text="Confirm Password" icon="lock">
                        </app-input>
                    </div>
                    <div class="register-Submit">
                        <input type="submit" class="OkSaveButtonColor register-submitbutton"
                            style="background-color: #1e90ff;" value="Submit">
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>
<div>
</div>