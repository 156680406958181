import { Component, OnInit } from '@angular/core';
import { ConsolidationService } from 'src/app/service/consolidation.service';
import { NgxSpinnerService } from "ngx-spinner";
import { consolidaitonsettingsModel, consolidationInterestModel } from 'src/app/model/consolidationsettings.model';
@Component({
  selector: 'app-consolidation-settings',
  templateUrl: './consolidation-settings.component.html',
  styleUrls: ['./consolidation-settings.component.scss']
})
export class ConsolidationSettingsComponent implements OnInit {
  consolidationSettings: consolidaitonsettingsModel;
  consolidaitonInterests: consolidationInterestModel[] = [];
  constructor(private ConsolidationService: ConsolidationService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getConsolidationsettings();
  }

  getConsolidationsettings() {
    this.SpinnerService.show();
    let forcastNodeid = localStorage.getItem('ForecastId');
    this.ConsolidationService.getConsolidationSettings(forcastNodeid).subscribe(
      (response: any) => {
        this.consolidationSettings = response;
        this.consolidaitonInterests = this.consolidationSettings.consolidationsettings;
        // console.log(this.consolidationSettings);

        this.SpinnerService.hide();
      }
    );
  }

  SaveInterests() {

    let consolSettings = {
      "consolidationsettings": this.consolidaitonInterests,
      "forecsatNodeID": localStorage.getItem('ForecastId')
    }
    this.ConsolidationService.saveConsolidationSettings(consolSettings).subscribe(
      ((response: any) => {
        this.consolidaitonInterests = [];
        window.location.reload();
      })
    )

  }
  Cancel() {
    this.consolidaitonInterests = [];
    window.location.reload();
  }
}
