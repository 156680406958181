import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MinussignsparenPipe } from '../../../common/commonhtml/minussignsparen.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpeningService } from 'src/app/service/opening.service';

@Component({
  selector: 'app-enter-actual-tree',
  /*template: `<div class="treeMenu enteractuals"><tree-root #tree [nodes]="nodes" [focused]="true"  (initialized)="onTreeLoad()" 
  [options]="options"><ng-template #treeNodeWrapperTemplate let-node let-index="index">
  <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
    <tree-node-expander [node]="node"></tree-node-expander>
    <div class="node-content-wrapper"
      [class.node-content-wrapper-active]="node.isActive"
      [class.node-content-wrapper-focused]="node.isFocused"
      (click)="node.mouseAction('click', $event)"
      (dblclick)="node.mouseAction('dblClick', $event)"
      (contextmenu)="node.mouseAction('contextMenu', $event)"
      (treeDrop)="node.onDrop($event)"
      [treeAllowDrop]="node.allowDrop"
      [treeDrag]="node"
      [treeDragEnabled]="node.allowDrag()">
      <div>
      <span class="displayname-{{index}} col-sm-6">{{node.data.name}}</span>
      <span class="displayvalue col-sm-6" *ngIf="node.data.localCurrency">{{node.data.localCurrency | minussignsparen}}</span>
      <span class="displayvalue col-sm-6" *ngIf="!node.data.localCurrency">0</span>
      </div>
    </div>
  </div>
</ng-template></tree-root></div>`,*/
  template: `<div class="treeMenu enteractuals"><tree-root #tree [nodes]="nodes"  (initialized)="onTreeLoad()" 
[options]="options"><ng-template #treeNodeWrapperTemplate let-node let-index="index">
<div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
  <tree-node-expander [node]="node"></tree-node-expander>
  <div class="node-content-wrapper"
    [class.node-content-wrapper-active]="node.isActive"
    [class.node-content-wrapper-focused]="node.isFocused"
    (click)="node.mouseAction('click', $event)"
    (dblclick)="node.mouseAction('dblClick', $event)"
    (contextmenu)="node.mouseAction('contextMenu', $event)"
    (treeDrop)="node.onDrop($event)"
    [treeAllowDrop]="node.allowDrop"
    [treeDrag]="node"
    [treeDragEnabled]="node.allowDrag()">
    <div>
    <span class="displayname" [ngClass]="{'NonEditable':node.data.localCurrency==null && node.data.nodeType!=51010 && node.data.nodeType!=51009}">{{node.data.name}}</span>
    <span class="displayvalue" style="margin: -3px 5px 0px 0px;float: right;">
    <input  value="{{node.data.localCurrency |minussignsparen }}"
    *ngIf="node.data.localCurrency!=null || node.data.nodeType == 51010 || node.data.nodeType == 51009"
    (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
    (blur)="updateopeningValue(node.data,$event)"
    allow-decimal="true" allow-negative="true" 
    (click)="valuelistregrex($event)"
    id="{{node.data.budgetDataNodeId}}"
    style="text-align: right; border: 0px; height: 30px;"
    class="form-control {{node.data.localCurrency}}" type="text"/>
     </span>
    </div>
  </div>
</div>
</ng-template>
</tree-root>
</div>`,
  styleUrls: ['./enter-actual-tree.component.scss']
})
export class EnterActualTreeComponent implements OnInit {

  nodes = '';
  options = {}
  constructor(private SpinnerService: NgxSpinnerService, private openingService: OpeningService) { }

  @Input() EnterActualTreeView;
  @Input() selectedNodeIds;
  @Input() SelectedActualIndex;

  @ViewChild('tree') tree;

  async ngOnInit() {


  }

  async ngOnChanges() {
    await this.delay(800);
    this.nodes = this.EnterActualTreeView;
    // console.log(this.nodes);
    this.options = {
    };
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async onTreeLoad() {
    await this.delay(900);
    this.tree.treeModel.expandAll();
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    console.log(inputValue);
    if (inputValue != null) {
      inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
    } else {
      $event.target.value = "0";
    }


  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    if (inputValue == null || inputValue == "") {
      $event.target.value = 0;
      return;
    }
    //var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    if ($event.target.value == null) {
      return;
    }
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if ($event.key == '-') {
        $event.target.value = "-";
      } else {
        $event.target.value = validatebynumberFormate;
      }

    }
  }

  updateopeningValue(OpeningValues, $event) {
    // console.log(OpeningValues);
    //console.log($event)
    let NewValue = "0.00";

    if ($event.target.value == '') {
      return;
    } else {
      let removecommavalue = $event.target.value.replaceAll(",", '')
      NewValue = parseFloat(removecommavalue).toFixed(2);
    }
    if (OpeningValues.localCurrency == null) {
      return;
    }

    let Id = OpeningValues.id;
    let LocalCurrency = "LocalCurrency";
    let BudgetDataNodeId = OpeningValues.budgetDataNodeId;
    let mul = OpeningValues.mul;
    let OldValue = parseFloat(OpeningValues.localCurrency).toFixed(2);
    if (OldValue == NewValue) {
      return;
    }
    let ForecastId = localStorage.getItem('ForecastId');
    if (BudgetDataNodeId != null && NewValue != 'NaN') {
      this.SpinnerService.show();
      this.openingService.EnterActualUpdateValue(ForecastId, Id, LocalCurrency, BudgetDataNodeId, mul, NewValue, this.SelectedActualIndex).subscribe(
        ((response: any) => {
          this.SpinnerService.hide();
        })
      )
    }

  }

}
