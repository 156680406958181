<div>
    <div class="">
        <div class="defualtdisplay financedAssetDispaly">
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Type</label>
                <div class="col-sm-7">
                    <select name="Intersttype" class="selectloantakencss form-control"
                        (change)="changeInterestType($event.target.value, CalculatedlFinnaceAssestsRecord)">
                        <option
                            *ngFor="let InterstResult of CalculatedlFinnaceAssestsRecord.interestTypeCollection ; let i = index"
                            [selected]="InterstResult.nodeType === CalculatedlFinnaceAssestsRecord.interestTypes"
                            value="{{InterstResult.nodeType}}">{{InterstResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Frequcncy</label>
                <div class="col-sm-7">
                    <select name="paymentFrequcncy" class="selectloantakencss form-control"
                        (change)="changepaymentFrequcncy($event.target.value, CalculatedlFinnaceAssestsRecord)">
                        <option
                            *ngFor="let paymentFrequencieResult of CalculatedlFinnaceAssestsRecord.paymentFrequencieCollection"
                            value="{{paymentFrequencieResult.numberFormatPrefix}}"
                            [selected]="paymentFrequencieResult.numberFormatPrefix === CalculatedlFinnaceAssestsRecord.paymentFrequencies.numberFormatPrefix">
                            {{paymentFrequencieResult.numberFormatPrefix}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Timing</label>
                <div class="col-sm-7">
                    <select name="paymentTiming" class="selectloantakencss form-control"
                        (change)="changepaymentTiming($event.target.value, CalculatedlFinnaceAssestsRecord)">
                        <option
                            *ngFor="let paymentTimingsResult of CalculatedlFinnaceAssestsRecord.paymentTimingsCollection"
                            [selected]="paymentTimingsResult.nodeType === CalculatedlFinnaceAssestsRecord.paymentTimings"
                            value="{{paymentTimingsResult.nodeType}}">{{paymentTimingsResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input [checked]="CalculatedlFinnaceAssestsRecord.isNewLoan"
                        (change)="IsnewLoanchangeEvent($event,CalculatedlFinnaceAssestsRecord)" type="checkbox"
                        name="IsNewLoan" class="col-sm-2 " style="height: 20px;margin: 0px">
                    <label class="col-sm-9" style="margin: 0px;">New loan</label>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-periodStart"
                [ngClass]="CalculatedlFinnaceAssestsRecord.isNewLoan == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Period Start</label>
                <div class="col-sm-7">
                    <select name="pariodstart" class="selectloantakencss form-control"
                        (change)="changeloanmadePeriod($event.target.value, CalculatedlFinnaceAssestsRecord)">
                        <option *ngFor="let result of CalculatedlFinnaceAssestsRecord.forecastperiod; let i = index"
                            [selected]="CalculatedlFinnaceAssestsRecord.selectedIndex === i" [value]="i">{{result.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Term (Months)</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control"
                        (blur)="changeTerm($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.term | minussignsparen }}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Asset Cost</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeLoanBalance($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.loanPrincipal | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Rate</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeInterestRate($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.interestRate | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changePayment($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.payment | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord"
                [ngClass]="CalculatedlFinnaceAssestsRecord.showDeposit == true ? '' : 'hidden' ">
                <div class="col-sm-6">

                    <input [checked]="CalculatedlFinnaceAssestsRecord.hasDepositPayment"
                        [disabled]="!CalculatedlFinnaceAssestsRecord.canHaveDepositPayment" type="checkbox"
                        style="height: 20px;margin: 0px"
                        (change)="OnchangeDeposit($event,CalculatedlFinnaceAssestsRecord)" class=" col-sm-2" />
                    <label class="col-sm-9 " style="margin: 0px;">Deposit</label>

                </div>
                <!-- <div class="col-sm-4"> 
                    <label class="col-sm-4 ">Deposit</label> 
                </div>   -->
                <div class="col-sm-6">
                    <input type="text" class=" col-sm-11 form-control enabledepositepayment"
                        (keypress)="isNumber($event)" *ngIf="CalculatedlFinnaceAssestsRecord.hasDepositPayment"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeDeposite($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.depositPayment | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input type="checkbox" [checked]="CalculatedlFinnaceAssestsRecord.hasBulletPayment"
                        [disabled]="!CalculatedlFinnaceAssestsRecord.canHaveBulletPayment"
                        (change)="OnchangeBullet($event,CalculatedlFinnaceAssestsRecord)" class=" col-sm-2"
                        style="height: 20px;margin: 0px" name="Isbullet" />
                    <label class="col-sm-9" style="margin: 0px;">Bullet</label>
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-BulletPayment"
                [ngClass]="CalculatedlFinnaceAssestsRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Bullet Payment</label>
                <div class="col-sm-6">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPayment($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.bulletPayment | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-PaymentMonth"
                [ngClass]="CalculatedlFinnaceAssestsRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Payment(Months)</label>
                <div class="col-sm-7">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPaymentMonth($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.monthsHoliday | minussignsparen}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input [checked]="CalculatedlFinnaceAssestsRecord.hasBalloonPayment"
                        [disabled]="!CalculatedlFinnaceAssestsRecord.canHaveBalloonPayment" type="checkbox"
                        (change)="HasBallooneEvent($event,CalculatedlFinnaceAssestsRecord)" class=" col-sm-2"
                        style="height: 20px;margin: 0px" />
                    <label class="col-sm-9">Balloon</label>
                </div>
                <div class="col-sm-6 loantakenrecordballon">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedlFinnaceAssestsRecord.hasBalloonPayment"
                        (blur)="changeBaloon($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.balloonPayment | minussignsparen}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input [checked]="CalculatedlFinnaceAssestsRecord.hasHolidayPeriod"
                        [disabled]="!CalculatedlFinnaceAssestsRecord.canHaveHolidayPeriod"
                        (change)="hasHolidayPeriodEvent($event,CalculatedlFinnaceAssestsRecord)" type="checkbox"
                        class=" col-sm-2" style="height: 20px;margin: 0px" />
                    <label class="col-sm-9">Holiday(Months)</label>
                </div>
                <div class="col-sm-6 loantakenrecordhollidayPeriodEvent">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedlFinnaceAssestsRecord.hasHolidayPeriod"
                        (blur)="changeholidaymonthText($event.target.value, CalculatedlFinnaceAssestsRecord)"
                        value="{{CalculatedlFinnaceAssestsRecord.monthsHoliday | minussignsparen}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col">
                    <label class="col-sm-6">Asset Sold</label>

                </div>
                <div class="col-sm-12" style="border: 1px solid;">
                    <div *ngFor="let result of CalculatedlFinnaceAssestsRecord.assetSoldTypeCollection; let i=index">
                        <div class="col">
                            <input [checked]="selectedOption === i" type="checkbox" value="{{result.nodeType}}"
                                (change)="changeAssetSold($event,CalculatedlFinnaceAssestsRecord , i)" class=" col-sm-2"
                                style="height: 20px;margin: 0px" />
                            <label class="col-sm-9">{{result.name}}</label>
                            <!-- <input 
                        type="checkbox" 
                         [checked]="CalculatedlFinnaceAssestsRecord.assetSoldType == i" 
                        [checked]="selectedOption === i" 
                        (change)="onCheckboxChange(i)"> 
                        <label class="col-sm-9">{{result.name}}</label>  -->
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf=" displayAssetSoldDetails===true">
                <div class="row valuelistdisplayrow loantakenrecord" *ngIf="selectedOption==2">
                    <label class="col-sm-5">Sold Amount:</label>
                    <div class="col-sm-7">
                        <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                            (keyup)="ValidateValue($event)" (blur)="changeAssetSoldAmount($event.target.value)"
                            value="{{CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldAmount | minussignsparen}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-5 ">Sold Period:</label>
                    <div class="col-sm-7">
                        <select name="pariodstart" class="assetSoldPeriodSelection form-control"
                            (change)="changeAssetSoldPeriod($event.target.value)">
                            <option *ngFor="let result of CalculatedlFinnaceAssestsRecord.forecastperiod; let i = index"
                                [selected]="CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldPeriodIndex === i"
                                [value]="i">
                                {{result.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-5 ">Days Credit:</label>
                    <div class="col-sm-7">
                        <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                            (keyup)="ValidateValue($event)" (blur)="changeAssetSoldDaysCredit($event.target.value)"
                            value="{{CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldDaysCredit | minussignsparen}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>