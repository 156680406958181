import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TreeviewService } from '../../service/treeview.service';
import { Treeview } from '../../service/treeview';
import { Record } from '../../model/record';
import { TreeModel, TreeNode } from '@ali-hm/angular-tree-component';
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from '../../service/menu.service';
declare const $: any;
import { Router } from '@angular/router';
import { ITreeState, ITreeOptions } from '@ali-hm/angular-tree-component';
import { v4 } from 'uuid';
import { ForecasttabsComponent } from '../forecasttabs/forecasttabs.component';

import {
  ConnectedPositioningStrategy,
  IgxDropDownComponent,
  IgxInputGroupComponent
} from '@infragistics/igniteui-angular';
import { recordNavigation } from 'src/app/service/recordNavigation.service';


@Component({
  selector: 'app-treemenu',
  templateUrl: './treemenu.component.html',
  styleUrls: ['./treemenu.component.scss'],
  providers: []
})
export class TreemenuComponent {
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  options: ITreeOptions = {
    getNodeClone: (node) => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`
    })
  };

  public items: any[] = [
    { field: 'Records', code: '', header: true, nodeId: 1 },
    { field: 'Add Record', code: '', nodeId: 1 },
    { field: 'Delete Record', code: '', nodeId: 1 },
    { field: 'Move Up Record', code: '', nodeId: 1 },
    { field: 'Move Down Record', code: '', nodeId: 1 },
    { field: 'Folders', code: '', header: true, nodeId: 1 },
    { field: 'Add Folder', code: '', nodeId: 1 },
    { field: 'Delete Folder', code: '', nodeId: 1 },
    { field: 'Sections', code: '', header: true, nodeId: 1 },
    { field: 'Add Section', code: '', nodeId: 1 },
    { field: 'Delete Section', code: '', nodeId: 1 },
    { field: 'Department', code: '', header: true, nodeId: 1 },
    { field: 'Add Department', code: '', nodeId: 1 },
    { field: 'Delete Department', code: '', nodeId: 1 },
  ];

  getSelectedNodeDetailsFromService: any;
  SelectedTreenodeEvent: boolean;
  allowdropnumber: number;
  allowdragnumber: number;
  @ViewChild(IgxDropDownComponent, { static: true }) public igxDropDown: IgxDropDownComponent;
  @ViewChild('inputGroup', { read: IgxInputGroupComponent, static: true }) public inputGroup: IgxInputGroupComponent;
  Treeviews: Treeview[] = [];
  record: Record[] = [];
  nodes = [];
  data = '';
  DepartmentErrorResponse = '';
  @Input() selectedNodedetails: any;
  @Output() eventClicked = new EventEmitter<Event>();
  @ViewChild('tree') clickroot: ElementRef<HTMLElement>;

  constructor(private treeview: TreeviewService,
    public menuServices: MenuService,
    private _router: Router,
    private forecasttabsComponent: ForecasttabsComponent,
    private SpinnerService: NgxSpinnerService,
    private recordNavigation: recordNavigation) {
    //console.log('... a new instance of LandComponent has been created');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  @ViewChild('tree') tree;

  Expandmenu() {
    this.tree.treeModel.expandAll();
  }

  public openDropDown() {
    if (this.igxDropDown.collapsed) {
      this.igxDropDown.open({
        target: this.inputGroup.element.nativeElement,
        modal: false,
        positionStrategy: new ConnectedPositioningStrategy()
      });
    }
  }
  public openFolderDropDown() {
    if (this.igxDropDown.collapsed) {
      this.igxDropDown.open({
        target: this.inputGroup.element.nativeElement,
        modal: false,
        positionStrategy: new ConnectedPositioningStrategy()
      });
    }
  }
  NavigationCalled(nodeID) {
    localStorage.setItem('TreeRecordNodeId', nodeID);
    this.onUpdateData();
  }
  ngOnInit() {
    this.getSelectedNodeDetailsFromService = this.selectedNodedetails;
    // if(this.getSelectedNodeDetailsFromService!=null){
    //   console.log(this.getSelectedNodeDetailsFromService);
    //   this.items = [
    //     { field: 'Records', code:'' , header: true, nodeId:1 },
    //     { field: 'Add Record', code: this.getSelectedNodeDetailsFromService.canAddRecord, nodeId:1 },
    //     { field: 'Delete Record', code: this.getSelectedNodeDetailsFromService.canDelete, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Move Up Record', code: this.getSelectedNodeDetailsFromService.canMoveUp, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Move Down Record', code: this.getSelectedNodeDetailsFromService.canMoveDown, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Folders', code: '', header: true, nodeId:2 },
    //     { field: 'Add Folder', code: this.getSelectedNodeDetailsFromService.canAddFolder && !this.folderLimitReached(this.getSelectedNodeDetailsFromService.nodeId), nodeId:this.getSelectedNodeDetailsFromService.nodeId},
    //     { field: 'Delete Folder', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeType==40000 , nodeId:this.getSelectedNodeDetailsFromService.nodeId},
    //     { field: 'Sections', code: '', header: true, nodeId:3 },
    //     { field: 'Add Section', code: this.getSelectedNodeDetailsFromService.canAddSection, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Delete Section', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeTypeCategory==30000 && this.getSelectedNodeDetailsFromService.departmentName==null, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Department', code: '', header: true },
    //     { field: 'Add Department', code: this.getSelectedNodeDetailsFromService.canAddDepartment, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //     { field: 'Delete Department', code: this.getSelectedNodeDetailsFromService.canDeleteDepartment && this.getSelectedNodeDetailsFromService.nodeType==20000, nodeId:this.getSelectedNodeDetailsFromService.nodeId },
    //  ]
    // }

    this.recordNavigation.recordNavigation.subscribe((event) => {
      this.NavigationCalled(event);
    })

    this.SelectedTreenodeEvent = false;
    this.SpinnerService.show();
    let storageData = localStorage.getItem("treeview");
    let ActiveForecastNodeId = localStorage.getItem("ForecastId");
    let storedForecast = localStorage.getItem("treeViewForecastID")
    this.SpinnerService.show();
    if (storageData != '' && storageData != null && ActiveForecastNodeId == storedForecast) {
      this.nodes = [JSON.parse(storageData)];
      this.SpinnerService.hide();
    } else {
      this.SpinnerService.show();
      this.treeview.getAllTreeMenu().subscribe(
        (response: Treeview[]) => {
          this.delay(1000);
          this.SpinnerService.hide();
          this.Treeviews = response;
          this.nodes = [this.Treeviews];
          localStorage.setItem("treeview", JSON.stringify(this.Treeviews));
          localStorage.setItem("treeViewForecastID", localStorage.getItem("ForecastId"))
          // this.options = {
          // }
          console.log("this.Treeviews", response);
        }
      );
    }

  }


  ngOnChanges() {

  }
  ngAfterViewInit() {
    this.tree.treeModel.expandAll();
  }

  onUpdateData() {
    const treeModel = this.tree.treeModel;
    treeModel.expandAll();
    let NodeId = localStorage.getItem('TreeRecordNodeId');
    if (NodeId != '' && NodeId != null) {
      let UpdatedTreeView: any = localStorage.getItem("UpdatedTreeView");
      let data = JSON.parse(UpdatedTreeView);
      if (UpdatedTreeView != null && UpdatedTreeView != '') {
        let Update = this.convertingTreeList(data.sectionTreeModels);
      }
      //this.SpinnerService.show();
      //this.SpinnerService.hide();
      // this.treeview.getAllTreeMenu().subscribe(
      //   async (response: Treeview[]) => {
      //     this.SpinnerService.hide();
      //     if (this.tree != undefined) {
      //       this.tree.treeModel.expandAll();
      //       localStorage.setItem('TreeRecordNodeId', '');
      //       if (document.getElementById(NodeId + '-treemenu') != undefined)
      //         document.getElementById(NodeId + '-treemenu').click();
      //     }
      //   }
      // );
    }

  }

  convertingTreeList(array) {
    var map: any = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.children = [];
      map[obj.nodeID] = obj;
      var parentNodeid = obj.parentNodeid || '-';
      if (!map[parentNodeid]) {
        map[parentNodeid] = {
          children: []
        };
      }
      if (map[parentNodeid].children != null) {
        map[parentNodeid].children.push(obj);
      }

    }

    return map[array[0].nodeID];

  }

  async onTreeLoad(event) {
    let data = [];
    let latestDragNodeId = [];
    let validparentArray = [];
    let validParent: any;
    this.options = {
      allowDrag: (node: any) => {
        if (node.isActive) {
          if (node.isFocused) {
            if (latestDragNodeId.length == 0) {
              latestDragNodeId.push(node.data.nodeId);
              if (validparentArray.length == 0) {
                this.treeview.getValidParents(node.data.nodeId).subscribe(
                  (response: Treeview[]) => {
                    validparentArray = response;
                  }
                );
              }
            }
            if (latestDragNodeId.length > 0 && latestDragNodeId[0] != node.data.nodeId) {
              latestDragNodeId = [];
              validparentArray = [];
              latestDragNodeId.push(node.data.nodeId);
              if (validparentArray.length == 0) {
                this.treeview.getValidParents(node.data.nodeId).subscribe(
                  (response: Treeview[]) => {
                    validparentArray = response;
                  }
                );
              }
            }
          }
          return true;
        } else {
          return false;
        }
      },
      allowDrop: (element: any, to: { parent, index: number }) => {
        // console.log("allowdrag");
        let data = false;
        if (latestDragNodeId != null) {
          if (latestDragNodeId[0] != null) {
            validparentArray.forEach(el => {
              if (el.nodeId == to.parent.data.nodeId) {
                data = true;
                this.allowdropnumber = to.index;
                to.parent.data.children.forEach((elements, index) => {
                  if (elements.nodeId == element.data.nodeId) {
                    this.allowdragnumber = index;
                  }
                });
              }
            });

          }
        } else {
          data = false;
        }
        return data;
      },
      actionMapping: {
        mouse: {
          drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
            if (from.data.parentNodeId == to.parent.data.nodeId) {
              this.treeview.MoveUpDownRecord(from.data.nodeId, this.allowdragnumber, this.allowdropnumber).subscribe(
                (response: Treeview[]) => {
                  this.SpinnerService.show();
                  //console.log("call tree MoveUpDownRecord ");
                  this.treeview.getAllTreeMenu().subscribe(
                    async (response: Treeview[]) => {
                      this.SpinnerService.hide();
                      if (this.tree != undefined) {
                        this.Treeviews = response;
                        this.nodes = [];
                        this.nodes = [this.Treeviews];
                        localStorage.setItem("treeview", JSON.stringify(this.Treeviews));
                        var returnnode = null;
                        // document.getElementById(latestDragNodeId[0] + '-treemenu').click();
                      }
                    }
                  );
                }
              )
            } else {
              this.SpinnerService.show();
              this.treeview.putMoveRecordTo(to.parent.data.nodeId, from.data.nodeId).subscribe(
                (response: Treeview[]) => {
                  //console.log("call tree putMoveRecordTo ");
                  this.treeview.getAllTreeMenu().subscribe(
                    async (response: Treeview[]) => {
                      if (this.tree != undefined) {
                        this.Treeviews = response;
                        this.nodes = [];
                        this.nodes = [this.Treeviews];
                        localStorage.setItem("treeview", JSON.stringify(this.Treeviews));
                        var returnnode = null;
                        this.SpinnerService.hide();
                        // document.getElementById(latestDragNodeId[0] + '-treemenu').click();
                      }
                    }
                  );
                }
              )
            }

          }
        }
      }
    };
  }

  ActiveEvent(event) {
    this.getSelectedNodeDetailsFromService = [];
    this.SelectedTreenodeEvent = false;
    //console.log(this.getSelectedNodeDetailsFromService);
    if (localStorage.getItem('recordSelected') != null) {
      let recordData = JSON.parse(localStorage.getItem('recordSelected'));
      if (recordData.nodeId == event.node.data.nodeId) {
        this.SelectedTreenodeEvent = true;
        this.getSelectedNodeDetailsFromService = recordData;
        if (this.getSelectedNodeDetailsFromService != null) {
          this.items = [
            { field: 'Records', code: '', header: true, nodeId: 1 },
            { field: 'Add Record', code: this.getSelectedNodeDetailsFromService.canAddRecord, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddRecord' },
            { field: 'Delete Record', code: this.getSelectedNodeDetailsFromService.canDelete, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode', },
            { field: 'Move Up Record', code: this.getSelectedNodeDetailsFromService.canMoveUp, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'MoveUpRecord', },
            { field: 'Move Down Record', code: this.getSelectedNodeDetailsFromService.canMoveDown, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'MoveDownRecord', },
            { field: 'Folders', code: '', header: true, nodeId: 2 },
            { field: 'Add Folder', code: this.getSelectedNodeDetailsFromService.canAddFolder && !this.folderLimitReached(this.getSelectedNodeDetailsFromService.nodeId), nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddFolder', },
            { field: 'Delete Folder', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeType == 40000, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode', },
            { field: 'Sections', code: '', header: true, nodeId: 3, function: 'DeleteSelectedNode' },
            { field: 'Add Section', code: this.getSelectedNodeDetailsFromService.canAddSection, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddSection', },
            { field: 'Delete Section', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeTypeCategory == 30000 && this.getSelectedNodeDetailsFromService.departmentName == null, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode' },
            { field: 'Department', code: '', header: true, nodeId: 3 },
            { field: 'Add Department', code: this.getSelectedNodeDetailsFromService.canAddDepartment, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddDepartmentDialog', },
            { field: 'Delete Department', code: this.getSelectedNodeDetailsFromService.canDeleteDepartment && this.getSelectedNodeDetailsFromService.nodeType == 20000, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteDepartment', },
          ]
        }
      }
    }
    if (!this.SelectedTreenodeEvent) {
      this.SpinnerService.show();
      this.treeview.getRecordDetail(event.node.data.nodeId).subscribe(
        (response: Record[]) => {
          this.SpinnerService.hide();
          this.SelectedTreenodeEvent = true;
          this.getSelectedNodeDetailsFromService = response;
          if (this.getSelectedNodeDetailsFromService != null) {
            this.items = [
              { field: 'Records', code: '', header: true, nodeId: 1 },
              { field: 'Add Record', code: this.getSelectedNodeDetailsFromService.canAddRecord, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddRecord' },
              { field: 'Delete Record', code: this.getSelectedNodeDetailsFromService.canDelete, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode', },
              { field: 'Move Up Record', code: this.getSelectedNodeDetailsFromService.canMoveUp, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'MoveUpRecord', },
              { field: 'Move Down Record', code: this.getSelectedNodeDetailsFromService.canMoveDown, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'MoveDownRecord', },
              { field: 'Folders', code: '', header: true, nodeId: 2 },
              { field: 'Add Folder', code: this.getSelectedNodeDetailsFromService.canAddFolder && !this.folderLimitReached(this.getSelectedNodeDetailsFromService.nodeId), nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddFolder', },
              { field: 'Delete Folder', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeType == 40000, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode', },
              { field: 'Sections', code: '', header: true, nodeId: 3, function: 'DeleteSelectedNode' },
              { field: 'Add Section', code: this.getSelectedNodeDetailsFromService.canAddSection, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddSection', },
              { field: 'Delete Section', code: this.getSelectedNodeDetailsFromService.canDelete && this.getSelectedNodeDetailsFromService.nodeTypeCategory == 30000 && this.getSelectedNodeDetailsFromService.departmentName == null, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteSelectedNode' },
              { field: 'Department', code: '', header: true, nodeId: 3 },
              { field: 'Add Department', code: this.getSelectedNodeDetailsFromService.canAddDepartment, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'AddDepartmentDialog', },
              { field: 'Delete Department', code: this.getSelectedNodeDetailsFromService.canDeleteDepartment && this.getSelectedNodeDetailsFromService.nodeType == 20000, nodeId: this.getSelectedNodeDetailsFromService.nodeId, function: 'DeleteDepartment', },
            ]
          }
        }
      )

    }

    this.eventClicked.emit(event.node.data.nodeId);
  }
  openReports() {
    this._router.navigateByUrl('/report');
  }
  onMoveNode($event) {
  }
  AddDepartmentDialog() {
    document.getElementById("AddDepartMentGroupEntry").click();
  }

  async AddDepartment(NodeId, AddDepartment) {
    var DepartmentName = ""
    var inputValue: any = (<HTMLInputElement>document.getElementById("DepartmentName"));
    var DepartmentCheckboxValue: any = (<HTMLInputElement>document.getElementById("showGrossProfitPercent"));
    if (inputValue != null) {
      DepartmentName = inputValue.value;
    }
    if (DepartmentName != '') {
      let DepartmentAddDetails = {
        "SelectedNodeID": NodeId,
        "NewDepartmentName": DepartmentName,
        "showGrossProfitPercent": DepartmentCheckboxValue.checked
      }
      AddDepartment.close();
      await this.menuServices.SaveAddDepartment(DepartmentAddDetails).subscribe
        ((response: any) => {
          this.updatedTree();
        })

    }

  }

  async DeleteDepartment(NodeId) {
    if (NodeId != null) {
      await this.menuServices.deleteDepartment(NodeId).subscribe
        ((response: any) => {
          this.updatedTree();
        })
    }
  }

  async AddFolder(NodeId) {
    if (NodeId != null) {
      this.SpinnerService.show();
      await this.menuServices.addFolder(NodeId).subscribe
        ((response: any) => {
          this.SpinnerService.hide();
          localStorage.setItem('TreeRecordNodeId', response);
          this.updatedTree();
        })
    }
  }

  async AddSection(NodeId) {
    if (NodeId != null) {
      this.SpinnerService.show();
      await this.menuServices.addSection(NodeId).subscribe
        ((response: any) => {
          this.SpinnerService.hide();
          localStorage.setItem('TreeRecordNodeId', response);
          this.updatedTree();
        })
    }
  }

  async AddRecord(NodeId) {
    if (NodeId != null) {
      this.SpinnerService.show();
      await this.menuServices.addRecord(NodeId).subscribe
        ((response: any) => {
          this.SpinnerService.hide();
          localStorage.setItem('TreeRecordNodeId', response);
          if (response == null) {
            return;
          }
          this.AddupdatedTree(response);
        })
    }
  }
  getTreeNode(treenodes, nodeid, returnnode) {

    if (treenodes.nodeId === nodeid) {
      return treenodes;
    }
    else {
      if ((treenodes.children != undefined && treenodes.children != null) && treenodes.children.length > 0) {
        treenodes.children.forEach(element => {
          returnnode = this.getTreeNode(element, nodeid, returnnode);
        });
      }
    }
    return returnnode;
  }
  ngAfterViewChecked() {
  }
  stoppropagation(event) {
    event.stoppropagation();
  }

  folderLimitReached(nodeid) {
    return this.CheckFolderLimitReached(this.getSelectedNodeDetailsFromService.parentNodeId);
  }
  CheckFolderLimitReached(parentNodeID) {
    let countFolder = 0;
    let maxFolder = 4;
    // console.log(this.nodes);

    let returnnode = null;
    let parentNode = this.getTreeNode(this.nodes[0], parentNodeID, returnnode);
    while (parentNode != null && countFolder <= maxFolder && parentNode.nodeType != null) {


      if (parentNode.nodeType === 40000) {
        countFolder++;
      }

      parentNode = this.getTreeNode(this.nodes[0], parentNode.parentNodeId, returnnode);
    }
    return countFolder >= maxFolder;
  }

  async AddupdatedTree(NodeId) {
    //console.log("AddupdatedTree");
    await this.treeview.getAllTreeMenu().subscribe(
      async (response: Treeview[]) => {
        // this.SpinnerService.hide();
        this.Treeviews = response;
        this.nodes = [this.Treeviews];
        localStorage.setItem("treeview", JSON.stringify(this.Treeviews));
        if (this.tree != undefined) {
          this.SpinnerService.hide();
          this.tree.treeModel.expandAll();
        }
      }
    );
  }

  updatedTree() {
    this.SpinnerService.show();
    // console.log("Updated tree");
    this.treeview.getAllTreeMenu().subscribe(
      (response: Treeview[]) => {
        this.SpinnerService.hide();
        this.Treeviews = response;
        this.nodes = [this.Treeviews];
        localStorage.setItem("treeview", JSON.stringify(this.Treeviews));
        this.tree.treeModel.update();
        if (this.tree != undefined) {
          this.tree.treeModel.expandAll();
        }
      }
    );
  }
  async DeleteSelectedNode(NodeId) {
    if (NodeId != null) {
      this.SpinnerService.show();
      await this.menuServices.DeleteSeletedNode(NodeId).subscribe
        ((response: any) => {
          this.SpinnerService.hide();
          if (response != '') {
            this.DepartmentErrorResponse = response;
            document.getElementById("DeleteDepartmentResponse").click();
          } else {
            this.updatedTree();
          }

        })
    }
  }

  async MoveUpRecord(NodeId) {
    if (NodeId != null) {
      await this.menuServices.MoveUpRecord(NodeId).subscribe
        ((response: any) => {
          this.updatedTree();
        })
    }
  }
  async MoveDownRecord(NodeId) {
    if (NodeId != null) {
      await this.menuServices.MoveDownRecord(NodeId).subscribe
        ((response: any) => {
          this.updatedTree();
        })
    }
  }

  filterFn(value: string, treeModel: TreeModel) {
    this.tree.treeModel.filterNodes((node: TreeNode) => this.searchTree(value, node.data.name));
  }

  searchTree(needle: string, haystack: string) {
    if (haystack != null) {
      const haystackLC = haystack.toLowerCase();
      const needleLC = needle.toLowerCase();
      const hlen = haystack.length;
      const nlen = needleLC.length;
      if (nlen > hlen) {
        return false;
      }
      if (nlen === hlen) {
        return needleLC === haystackLC;
      }
      outer: for (let i = 0, j = 0; i < nlen; i++) {
        const nch = needleLC.charCodeAt(i);
        while (j < hlen) {
          if (haystackLC.charCodeAt(j++) === nch) {
            continue outer;
          }
        }
        return false;
      }
      return true;
    }

  }

  ngOnDestroy() {

  }
}


