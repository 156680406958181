<div *ngIf="SelectedMenuName == 'Currencies'">
    <app-currency-setting></app-currency-setting>
</div>
<div *ngIf="SelectedMenuName == 'Profiles'">
    <app-profile-setting></app-profile-setting>
</div>
<div *ngIf="SelectedMenuName == 'Payroll Tables & Options'">
    <app-payroll-table-options></app-payroll-table-options>
</div>
<div *ngIf="SelectedMenuName == 'Contract Retentions'">
    <app-contract-retentions></app-contract-retentions>
</div>
<div *ngIf="SelectedMenuName == 'GST Rates'">
    <app-gstrate-setting></app-gstrate-setting>
</div>
<div *ngIf="SelectedMenuName == 'VAT Rates'">
    <app-gstrate-setting></app-gstrate-setting>
</div>
<div *ngIf="SelectedMenuName == 'Preferences'">
    <app-preferences-setting></app-preferences-setting>
</div>
<div *ngIf="SelectedMenuName == 'Forecast Other Options'">
    <app-forecastother-option></app-forecastother-option>
</div>
<div *ngIf="SelectedMenuName == 'Parameter Defaults'">
    <app-pameter-default></app-pameter-default>
</div>
<div *ngIf="SelectedMenuName == 'Forecast Details'">
    <app-forecast-setting-details></app-forecast-setting-details>

</div>
<div *ngIf="SelectedMenuName == 'Extent/Amend'">
    <app-forecast-extent></app-forecast-extent>
</div>

<!-- <div class="button-sample row col" style="float: right;" *ngIf="SelectedMenuName != 'Extent/Amend' && SelectedMenuName != 'Forecast Details' && SelectedMenuName != 'Profiles' && SelectedMenuName != 'Currencies' && SelectedMenuName != 'Payroll Tables & Options'">
    <button class="btn OkSaveButtonColor col-sm-1" (click)="SettingSave()" style="font-size: 16px;margin: 0px 7px 0 0;" >Save</button>
    <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingSave()" style="font-size: 16px;margin: 0px 7px 0 7px;" >Close</button>
   
</div>     -->