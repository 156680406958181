import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProfileServicesService {

  private headers: Headers;
  constructor(private http: HttpClient) { }

  /**
    * Function For get Profile information
    * @param ForecastNodeId 
    * @returns 
    */
  getProfileCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/ProfileCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          //  console.log(response);
          return response;
        }
      ));
  }
  saveProfileCollection(saveprofileValues) : Observable<any>{
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/ProfileSetting/SaveProfileModifer";
    return this.http.post(url, saveprofileValues, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  AddProfile(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/AddProfileCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  DeleteProfile(ForecastNodeId, selectedIndex): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/DeleteProfileCollection/" + ForecastNodeId + "/" + selectedIndex, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  DefaultProfile(ForecastNodeId, selectedIndex): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/DefaultProfileCollection/" + ForecastNodeId + "/" + selectedIndex, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SetAllProfile(NodeId, SetAllValue): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/SaveValueList/" + NodeId + "/" + SetAllValue, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  setProfileName(NodeId, Values): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/SetProfileName/" + NodeId + "/" + Values, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  setProfileindex(NodeId, Values): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/SetProfileIndex/" + NodeId + "/" + Values, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  SaveValueListByMonth(NodeId, index, Values): Observable<any[]> {
    return this.http.get(environment.url + "/api/ProfileSetting/SaveValueListByMonth/" + NodeId + "/" + index + "/" + Values, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
}
