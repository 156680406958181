<div class="">
    <div>
        <h3>Forecast Other Option Setting ( Calculation Options)</h3>
    </div>

    <div class="col-sm-12" style="margin-top:1%; height: 648px;">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-9">
            <div class="col-sm-12">
                <div class="col-sm-3" *ngIf="ForecastotherOptionCollection!=''">
                    <div class="box-content">
                        <h3>Debtor Invoices</h3>
                        <igx-select [(ngModel)]="ForecastotherOptionCollection.debtorInvoicesAllocationMethodVM">
                            <igx-select-item
                                *ngFor="let results of ForecastotherOptionCollection.validInvoicesAllocationMethodsVM"
                                [value]="results.value" (click)='validInvoicesAllocationMethodsVMEvent(results)'>
                                {{results.name}}
                            </igx-select-item>
                        </igx-select>
                    </div>
                </div>
                <div class="col-sm-3 box-content" *ngIf="ForecastotherOptionCollection!=''">
                    <h3>Creditor Invoices</h3>
                    <igx-select [(ngModel)]="ForecastotherOptionCollection.creditorInvoicesAllocationMethodVM">
                        <igx-select-item
                            *ngFor="let results of ForecastotherOptionCollection.validInvoicesAllocationMethodsVM"
                            [value]="results.value" (click)='InvoicesAllocationMethodsVMEvent(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
                <div class="col-sm-3 box-content" *ngIf="ForecastotherOptionCollection!=''">
                    <h3>Bank Interest Method</h3>
                    <igx-select [(ngModel)]="ForecastotherOptionCollection.interestMethodVM">
                        <igx-select-item *ngFor="let results of ForecastotherOptionCollection.validInterestMethodsVM"
                            [value]="results.value" (click)='validInterestMethodsVMEvent(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
            </div>
            <div class="col-sm-12">
                <!-- <div class="otheroptionsetting">
                    <input type="checkbox"
                        [checked]="ForecastotherOptionCollection.calculateTaxAndDividendsOnNegativeProfits"
                        (change)="calculateTaxAndDividendsOnNegativeProfitsEvent($event)">
                    Calculate Tax &amp; Dividends on Negative Profits (for Consolidation)
                </div> -->
                <div class="otheroptionsetting">
                    <input type="checkbox" [checked]="ForecastotherOptionCollection.allowNegativeStockBalances"
                        (change)="allowNegativeStockBalancesEvent($event)">
                    Allow Stock Balances to be Negative
                </div>
                <div class="otheroptionsetting">
                    <input type="checkbox"
                        [checked]="ForecastotherOptionCollection.holidayInterestPaymentsForCompoundInterest"
                        (change)="holidayInterestPaymentsForCompoundInterestEvent($event)">
                    Interest Payments During Holidays for Compound Interest Loans
                </div>
                <!-- <div class="otheroptionsetting textboxdisplay" style="border: 1px solid;width: 72%;">
                    <input type="checkbox" [checked]="ForecastotherOptionCollection.disableForecastRecordCalculation"
                        (change)="disableForecastRecordCalculationEvent($event)">
                    Disable System Record calculation while navigating to related records
                    <div *ngIf="recordCalculationDisplay" class="otheroptionsetting">
                        Please do system record recalculation to reflect proper values in the system
                    </div>
                </div> -->

                <div class="otheroptionsetting">
                    <input type="checkbox" [checked]="ForecastotherOptionCollection.disableActualsProfileCalculation"
                        (change)="disableActualsProfileCalculationEvent($event)">
                    Disable Re-Calculate Revenue/Cost Profile in actuals
                </div>
                <div class="otheroptionsetting">
                    <input type="checkbox" [checked]="ForecastotherOptionCollection.excludeExtraOrdinaryTaxCalculation"
                        (change)="SetExtraOrdinaryTaxCalculationEvent($event)">
                    Exclude Extra Ordinary Records from tax calculations
                </div>
                <div class="otheroptionsetting">
                    <input type="checkbox" [checked]="ForecastotherOptionCollection.isFactoringDebtorsBalance"
                        (change)="SetIsFactoringDebtorsBalanceEvent($event)">
                    Use debtors balance for Financing(else, will use sales invoices)
                </div>
            </div>
        </div>
    </div>
    <div class="button-sample row col" style="float: right;">
        <button class="btn OkSaveButtonColor col-sm-1" (click)="SettingSave()"
            style="font-size: 16px;margin: 0px 7px 0 0;">Save</button>
        <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingClose()"
            style="font-size: 16px;margin: 0px 7px 0 7px;">Close</button>

    </div>
</div>