import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class PayrolltableoptionsService {

  private headers: Headers;
  constructor(private http: HttpClient) { }

  /**
    * Function For get Payrates Collections
    * @param ForecastNodeId 
    * @returns 
    */
  getPayRatesCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getPayRate/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
  * Function For get Bonus Collections
  * @param ForecastNodeId 
  * @returns 
  */
  getBonusCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getPensionBonusCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
  * Function For get Bonus Collections
  * @param ForecastNodeId 
  * @returns 
  */
  getBonusesCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getPensionBonusCollections/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  SetAllAndTotalBonusesPayrollService(saveRecordValuelist): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/PayRollTableOptions/SetAllValueFromRecordBonuses";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  SetValuesSaveBonusesPayrollService(saveRecordValuelist): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/PayRollTableOptions/SetValuesFromRecordBonuses";
    return this.http.post(url, saveRecordValuelist, { headers: reqHeaders })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  CalculatePayeeRecord(forecastID): Observable<any> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/CalculatePayeRecord/" + forecastID, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
  * Function For get Pension Collections
  * @param ForecastNodeId 
  * @returns 
  */
  getPensionCollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getPensionCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
 * Function For get NI Collections
 * @param ForecastNodeId 
 * @returns 
 */
  getNICollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getNICollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
 * Function For Default Pension
 * @param ForecastNodeId 
 * @returns 
 */
  DefaultPension(ForecastNodeId, defaultIndex): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/DefaultPension/" + ForecastNodeId + "/" + defaultIndex, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
 * Function For Default NI
 * @param ForecastNodeId 
 * @returns 
 */
  DefaultNI(ForecastNodeId, defaultIndex): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/DefaultNI/" + ForecastNodeId + "/" + defaultIndex, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
  * Function For get PAYE Collections
  * @param ForecastNodeId 
  * @returns 
  */
  getPAYECollections(ForecastNodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/getPensionPAYEPaymentCollection/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
   * 
   * @param ForecastNodeId 
   * @param SelectedMethod 
   * @returns nodetype
   */
  editPAYEpaymentMethod(ForecastNodeId, SelectedMethod): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/EditPensionPAYEPaymentMethod/" + ForecastNodeId + "/" + SelectedMethod, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
   * 
   * @param ForecastNodeId 
   * @param Value 
   * @returns bool
   */
  editPAYEIncomeTextAllownce(ForecastNodeId, Value): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/EditIncludeAllowanceInTax/" + ForecastNodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  setAmount(NodeId, Value, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/SetAmount/" + NodeId + "/" + Value + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  setPercentage(NodeId, Value, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/SetPercentage/" + NodeId + "/" + Value + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  addPayRates(NodeId, Index): Observable<any[]> {
    return this.http.get(environment.url + "/api/PayRollTableOptions/addPayRates/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  deletePayRates(NodeId, Index): Observable<any[]> {
    return this.http.delete(environment.url + "/api/PayRollTableOptions/deletePayRates/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  AddPensionCollection(NodeId, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/AddPensionCollection/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  DeletePensionCollection(NodeId, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/DeletePensionCollection/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  // NI Section For UK Version

  AddNICollection(NodeId, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/AddNICollection/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  DeleteNICollection(NodeId, Index): Observable<any[]> {
    return this.http.put(environment.url + "/api/PayRollTableOptions/DeleteNICollection/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  Employeeaddbanding(NodeId, Index) {
    return this.http.get(environment.url + "/api/PayRollTableOptions/Employeeaddbanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  EmployeedeleteBanding(NodeId, Index) {
    return this.http.delete(environment.url + "/api/PayRollTableOptions/EmployeedeleteBanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  Employeraddbanding(NodeId, Index) {
    return this.http.get(environment.url + "/api/PayRollTableOptions/Employeraddbanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  EmployerdeleteBanding(NodeId, Index) {
    return this.http.delete(environment.url + "/api/PayRollTableOptions/EmployerdeleteBanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  EmployerUpdateAmountbanding(NodeId, Index) {
    return this.http.get(environment.url + "/api/PayRollTableOptions/EmployerAmountUpdatebanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  EmployerUpdatePercentagebanding(NodeId, Index) {
    return this.http.get(environment.url + "/api/PayRollTableOptions/EmployerUpdatePercentagebanding/" + NodeId + "/" + Index, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  PensionNameChange(Details) {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/PayRollTableOptions/PensionSchemaUpdate", Details, { headers: reqHeaders })
      .pipe(map(
        (response) => {
          return response;
        }
      ));

  }
  // Ni Name Changes

  NINameChange(Details) {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/PayRollTableOptions/NISchemaUpdate", Details, { headers: reqHeaders })
      .pipe(map(
        (response) => {
          return response;
        }
      ));

  }

}
