<div class="col-sm-12">
    <div class="col-sm-4" style="height: 500px;border: 1px solid black;" >
        <table class="table">
            <thead>
                <th>List of Users</th>
            </thead>
            <tbody>
                <tr *ngFor="let listofuser of listofusers">
                   <td value="{{listofuser.code}}" [ngClass]="{'Active': ActiveUsersNodeId==listofuser.code}"  (click)="ActiveUsers(listofuser.code)">{{listofuser.name}}</td> 
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-sm-3">
        <div class="col">
            <div class="col" >
                <div style="padding: 3px 6px 0px 0;margin: 0px -33px 0 -28px;">
                    <button type="button" class="UserManagementAddUser usermanagementbuttton btn btn-primary col" 
                        (click)="OepnAddUserDialog(UserManagementAddUser)">Add Users</button>
                    <igx-dialog #UserManagementAddUser [closeOnOutsideSelect]="false">
                        <div class="add UserManagement" style="width: 500px; height: 650px;">
                            <h2>Add User</h2>
                            <form [formGroup]="AdduserForm" (ngSubmit)="onuserSubmit()">
                                <div class="col" style="width: 75%;">
                                    <div class="adduseremail">
                                        <app-input [control]="AdduserForm.controls.useremailId" placeholder="Enter Email Id"
                                        text="Email ID" icon="mail">
                                    </app-input>
                                    </div>
                                    <div class="adduserpassword">
                                        <app-input [control]="AdduserForm.controls.Password" type="password"
                                        placeholder="Enter Password" text="Password" icon="lock">
                                    </app-input>
                                    </div>
                                    <div class="addconfirmpassword">
                                        <app-input [control]="AdduserForm.controls.ConfirmPassword" type="password"
                                        placeholder="Enter Name" text="Confirm Password" icon="lock">
                                    </app-input>
                                    </div>
                                    <div class="row">
                                        <div class="AvailableGroups col-sm-5"  style="border: 1px solid;" *ngIf="Displayadduser">
                                            <table class="table" style="height: 318px;overflow: scroll;">
                                                <tbody>
                                                    <tr *ngFor="let groups of AvailableGroups">
                                                       <td value="{{groups.code}}" 
                                                       (click)="changeUserEvent(groups.code)" ><label [ngClass]="{'Active': groups.code == activeAvailabelGroups}" >{{groups.name}}</label></td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="AvailableGroups col-sm-5" style="border: 1px solid;" *ngIf="Displayadduser">
                                            <table class="table table-hover" style="height: 318px; overflow: scroll;">
                                                <tbody>
                                                    <tr *ngFor="let groups of ValidGroups">
                                                       <td value="{{groups.code}}" 
                                                        (click)="changeValiduserEvent(groups.code)">
                                                        <label [ngClass]="{'Active': groups.code == activeSelectedGroups}">{{groups.name}}</label></td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row" style="margin: 5px 0px 11px 32px;">
                                            <button type="button" class="btn btn-primary col" style="margin: 0 0 0 53px;" (click)="addGroupsEvent()"> Add -> </button>
                                            <button type="button" class="btn btn-primary col" style="margin: 0 0 0 22px;" (click)="removeGroups()"> Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="register-Submit col">
                                    <input type="submit" class="OkSaveButtonColor register-submitbutton col-sm-5"
                                        style="background-color: #1e90ff;" value="submit">
                                    <input type="button" class="deleteCancelButtonColor register-submitbutton col-sm-5"
                                        value="cancel" style="margin: 0 0 0 10px;" (click)="UserManagementAddUser.close()">
                                </div>
                            </form>
                        </div>
                    </igx-dialog>
                </div>
                <div style="padding: 3px 6px 0px 0;margin: 0px -33px 0 -28px;">
                    <button type="button" class="UserManagementAddUser usermanagementbuttton btn btn-primary col" 
                        (click)="OepnUpdateUserDialog(UserManagementUpdateUser)">Update Users</button>
                    <igx-dialog #UserManagementUpdateUser [closeOnOutsideSelect]="false">
                        <div class="add UserManagement" style="width: 500px; height: 650px;">
                            <h2>Add User</h2>
                            <!-- <form [formGroup]="UpdateUserFrom" (ngSubmit)="onuserSubmit()"> -->
                                <div class="col" style="width: 75%;">
                                    <!-- <div class="adduseremail">
                                        <app-input [control]="UpdateUserFrom.controls.useremailId" placeholder="Enter Email Id"
                                        text="Email ID" icon="mail">
                                        </app-input>
                                    </div> -->
                                    <!-- <div class="adduserpassword">
                                        <app-input [control]="UpdateUserFrom.controls.Password" type="password"
                                        placeholder="Enter Password" text="Password" icon="lock">
                                    </app-input>
                                    </div>
                                    <div class="addconfirmpassword">
                                        <app-input [control]="UpdateUserFrom.controls.ConfirmPassword" type="password"
                                        placeholder="Enter Name" text="Confirm Password" icon="lock">
                                    </app-input>
                                    </div> -->
                                    <div class="adduseremail">
                                        <input type="text" name="Name" value="{{UpdatedName}}" class="form-control" disabled/>
                                    </div>
                                    <div class="row">
                                        <div class="AvailableGroups col-sm-5"  style="border: 1px solid;">
                                            <table class="table" style="height: 318px;overflow: scroll;">
                                                <tbody>
                                                    <tr *ngFor="let groups of UpdateAvailableGroups">
                                                       <td value="{{groups.code}}" 
                                                       (click)="changeUpdateUserEvent(groups.code)" ><label 
                                                       [ngClass]="{'Active': groups.code == activeudpateAvailabelGroups}" >{{groups.name}}</label></td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="AvailableGroups col-sm-5" style="border: 1px solid;">
                                            <table class="table table-hover" style="height: 318px; overflow: scroll;">
                                                <tbody>
                                                    <tr *ngFor="let groups of UpdateValidGroups">
                                                       <td value="{{groups.code}}" 
                                                        (click)="changeupdateValiduserEvent(groups.code)">
                                                        <label [ngClass]="{'Active': groups.code == activeupdateSelectedGroups}">{{groups.name}}</label></td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row" style="margin: 5px 0px 11px 32px;">
                                            <button type="button" class="btn btn-primary col" style="margin: 0 0 0 53px;" (click)="UpdateAddGroupsEvent()"> Add -> </button>
                                            <button type="button" class="btn btn-primary col" style="margin: 0 0 0 22px;" (click)="UpdateremoveGroups()"> Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="register-Submit col">
                                    <input type="submit" class="OkSaveButtonColor register-submitbutton col-sm-5"
                                        style="background-color: #1e90ff;" value="submit">
                                    <input type="button" class="deleteCancelButtonColor register-submitbutton col-sm-5"
                                        value="cancel" style="margin: 0 0 0 10px;" (click)="UserManagementUpdateUser.close()">
                                </div>
                            <!-- </form> -->
                        </div>
                    </igx-dialog>
                </div>
            </div>
            
        </div>
        <!-- <button type="button" class="usermanagementbuttton btn btn-primary col">Update Users</button> -->
        <button type="button" class="usermanagementbuttton btn btn-primary col" (click)="deleteUser(ActiveUsersNodeId)">Delete Users</button>
    </div>
    <div class="col-sm-3">
    
    </div>
    
    </div>
   