import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsermaintenanceService {

  private headers: HttpHeaders;
  constructor( public http: HttpClient) { }

  getForecastList(): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/GetForecastList", {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }
  getUserGroupList(): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/GetUserGroupCollection", {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }
  getUserList(): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/GetUserCollection", {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }
  getAvailableGroups(): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/GetAvailableGroups", {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }

  getAvailableUsers(): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/GetAvailableUsers", {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }

  addUserDeatils(AddUserDetails): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/UserMaintenance/AddUser/", AddUserDetails, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  deleteUser(UserID): Observable<any> {
    return this.http.delete(environment.url + "/api/UserMaintenance/DeleteUser/"+UserID, {responseType:'json'})
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  addUserGroupDeatils(AddUserGroupDetails): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/UserMaintenance/AddGroup/", AddUserGroupDetails, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  getForecastAccessuser(ForecastId):Observable<any>{
    return this.http.get(environment.url + "/api/UserMaintenance/ForecastAccessGetUser/"+ForecastId, {responseType:'json'})
    .pipe(map(
      (response: any) => {
        return response;
      }
    ))
  }
  getForecastAccessGetGroup(ForecastId, SelectedOwnerID):Observable<any>{
    return this.http.get(environment.url + "/api/UserMaintenance/ForecastAccessGetGroup/"+ForecastId+"/"+SelectedOwnerID, {responseType:'json'})
    .pipe(map(
      (response: any) => {
        return response;
      }
    ))
  }
  updateForecastAccess(ForecastNodeId,  SelectedOwner,SelectedGroup,):Observable<any>{
    return this.http.put(environment.url + "/api/UserMaintenance/ForecastAccess/" + ForecastNodeId+"/"+SelectedOwner+"/"+SelectedGroup, { responseType: "json" })
      .pipe(map(
        (response:any) => {
          return response;
        }
      ));
  }

  getUpdateCollectionList(NodeId): Observable<any>{
    return this.http.get(environment.url+"/api/UserMaintenance/Updateuser/"+NodeId, {responseType:'json'}).pipe(
      (map(response=>{
        return response;
      }))
    )
  }
}
