import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add JWT auth header if a user is logged in for API requests
    const accessToken = localStorage.getItem('jwt');
    const clientID = localStorage.getItem('clientID');
    const forecastNodeid = localStorage.getItem('ForecastId');
    const CurrentUserNodeId = localStorage.getItem('CurrentUserNodeId');
    const CurrentUserID = localStorage.getItem('CurrentUserID');
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (accessToken && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          clientID: `${clientID}`,
          forecastNodeId: `${forecastNodeid}`,
          CurrentUserNodeId: `${CurrentUserNodeId}`,
          CurrentUserID: `${CurrentUserID}`
        },

      });
    }
    //console.log("Interceptor", accessToken, isApiUrl);
    //const begin = performance.now();
    return next.handle(request).pipe(
      finalize(() => {
        //this.logRequestTime(begin, request.url, request.method);
      })
    );
  }
  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    //console.log(`HTTP ${method} ${url} - ${requestDuration} milliseconds`);
  }

}
