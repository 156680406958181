import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-tree-nodefolder',
  templateUrl: './tree-nodefolder.component.html',
  styleUrls: ['./tree-nodefolder.component.scss']
})
export class TreeNodefolderComponent implements OnInit {

  @Input() treeNodeFolderClicked;
  DisplayFolderContain:any

  constructor() { }

  ngOnInit() {
    if(this.treeNodeFolderClicked!=null){
      this.DisplayFolderContain = true
    } else {
      this.DisplayFolderContain = false;
    }
  }

}
