import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { ForecastOtherOptionService } from '../../../service/SettingServices/ForecastOtherOptionService/forecast-other-option.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-forecastother-option',
  templateUrl: './forecastother-option.component.html',
  styleUrls: ['./forecastother-option.component.scss']
})
export class ForecastotherOptionComponent implements OnInit {

  collections: any = [];
  ForecastotherOptionCollection: any = [];
  ForecastNodeId: any;
  recordCalculationDisplay: boolean = false;
  constructor(private ForecastOtherOptionService: ForecastOtherOptionService, private router: Router) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');
  }

  async ngOnInit() {
    this.ForecastotherOptionCollection = [];
    this.ForecastOtherOptionService.getForecastotherOptionsCollections(this.ForecastNodeId).subscribe(
      (async response => {
        this.ForecastotherOptionCollection = response;
        console.log(this.ForecastotherOptionCollection);
        this.recordCalculationDisplay = this.ForecastotherOptionCollection.disableForecastRecordCalculation;
      })
    )
  }


  async ngOnChanges() {
    this.ForecastotherOptionCollection = [];
    this.ForecastOtherOptionService.getForecastotherOptionsCollections(this.ForecastNodeId).subscribe(
      (async response => {
        this.ForecastotherOptionCollection = response;
        this.recordCalculationDisplay = this.ForecastotherOptionCollection.disableForecastRecordCalculation;
      })
    )
  }
  validInvoicesAllocationMethodsVMEvent(Values) {
    let value = parseInt(Values.value);
    this.ForecastOtherOptionService.SetDebtorInvoicesAllocationMethod(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  InvoicesAllocationMethodsVMEvent(Values) {
    let value = parseInt(Values.value);
    this.ForecastOtherOptionService.SetCreditorInvoicesAllocationMethod(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  validInterestMethodsVMEvent(Values) {
    let value = parseInt(Values.value);
    this.ForecastOtherOptionService.SetForecastInterestMethod(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  SettingSave() {
    this.ngOnInit();
  }
  SettingClose() {
    this.router.navigateByUrl('/forecast');
  }

  calculateTaxAndDividendsOnNegativeProfitsEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetCalculateTaxAndDividendsOnNegativeProfits(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  SetExtraOrdinaryTaxCalculationEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetExtraOrdinaryTaxCalculation(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  SetIsFactoringDebtorsBalanceEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetIsFactoringDebtorsBalance(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  allowNegativeStockBalancesEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetAllowNegativeStockBalances(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  holidayInterestPaymentsForCompoundInterestEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetHolidayInterestPaymentsForCompoundInterest(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  disableForecastRecordCalculationEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetDisableForecastRecordCalculation(this.ForecastNodeId, value).subscribe(
      (response => {
        this.recordCalculationDisplay = value;
        return response;

      })
    )
  }
  disableActualsProfileCalculationEvent($event) {
    let value = $event.target.checked;
    this.ForecastOtherOptionService.SetDisableActualsProfileCalculation(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }

}
