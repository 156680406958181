<div class="">
    <div class="">
        <h3>Currency Settings</h3>
    </div>
    <div class="col-sm-12" style="margin-top:1%; height:675px">
        <div class="col-sm-4 displayCurrencyList">
            <div class="col">
                <button class="btn OkSaveButtonColor col-sm-3"
                    style="font-size: 16px;margin-bottom: 4px;margin: 0 3px 6px 0px;"
                    (click)="AddCurrency()">New</button>
                <button class="btn deleteCancelButtonColor col-sm-3" style="font-size: 16px;"
                    (click)="DeleteCurrency()">Delete</button>
                <!-- <button class="btn SettingCancelButtonColor col-sm-3" 
                [disabled]="defaultIndex==ActiveRecord"
                style="font-size: 16px;margin: 0px 0px 0px 2px;" (click)="DafaultCurency()">Default</button> -->
            </div>
            <table class="table table-bordered col">
                <thead>
                    <th scope="col-sm-1"></th>
                    <th scope="col-sm-6">Name</th>
                    <th scope="col-sm-2">Symbol</th>
                    <th scope="col-sm-3">TLA</th>
                </thead>
                <tbody>
                    <tr *ngFor="let Currency of CurrencyCollection; let i=index;"
                        [ngClass]="{'selected':i==ActiveRecord}" (click)="changeValueList(i,Currency)">
                        <th scope="col-sm-1"><span *ngIf="i==defaultIndex">*</span></th>
                        <td [ngClass]="{'Active':i==ActiveRecord}" class="col-sm-6"><input type="text"
                                style="border:none" value="{{Currency.name}}"
                                (blur)="CurrencySettingNameChange(i, Currency, $event)" name="CurrencySettingName[i]"
                                class="col-sm-10" [ngClass]="{'Currencyname':i==ActiveRecord}" /></td>
                        <td [ngClass]="{'Active':i==ActiveRecord}" class="col-sm-2"><input type="text"
                                style="border:none" value="{{Currency.symbol}}"
                                (blur)="CurrencySettingSymbolChange(i, Currency, $event)"
                                name="CurrencySettingSymbol[i]" class="col-sm-10"
                                [ngClass]="{'Currencyname':i==ActiveRecord}" /></td>
                        <td [ngClass]="{'Active':i==ActiveRecord}" class="col-sm-3"><input type="text"
                                style="border:none" value="{{Currency.tla}}"
                                (blur)="CurrencySettingTlaChange(i, Currency,$event)" name="CurrencySettingtla[i]"
                                class="col-sm-10" [ngClass]="{'Currencyname':i==ActiveRecord}" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-4">
            <div class="bs-example" *ngIf="PeriodTabViewCollection.length>0">
                <ul class="nav nav-tabs">
                    <li class="nav-item" style="margin: 0 8px -2px -1px;">
                        <select form="Payrool-payrate" class="form-control"
                            style="background: #86b6d9; color: white; font-size: 12px; font-weight: bold;">
                            <option value="Annual" ng-reflect-value="Annual">Annual</option>
                            <option value="All" ng-reflect-value="All">All</option>
                        </select>
                    </li>
                    <li class="nav-item" *ngFor="let result of PeriodTabViewCollection[0].results;  let i=index"
                        (click)="ChangePeriodType(result, i)">
                        <a href="#{{result.nodeId}}" class="nav-link" [ngClass]="{'active':i==ActivetabView}"
                            data-toggle="tab">{{result.longLabel}}</a>
                    </li>
                </ul>

                <div class="currencyValueListArray col-sm-9" *ngIf="CurrencyValueList!=''">
                    <div class="col-sm-12 ValueArray" *ngIf="CurrencyValueList.allowOpeningEntry == true">
                        <label class="col-sm-5">Opening</label>
                        <input class="col-sm-5" style="text-align:end" *ngIf="CurrencyValueList.allowOpeningEntry"
                            value="{{CurrencyValueList.opening}}" />

                    </div>
                    <div class="col-sm-12 ValueArray" *ngIf="CurrencyValueList.allowSetAllEntry == true">
                        <label class="col-sm-5" *ngIf="CurrencyValueList.allowSetAllEntry"
                            id="hidecopy-{{CurrencyValueList.nodeId}}">SetAll</label>
                        <input class="col-sm-5 form-control" style="text-align:end" (keypress)="isNumber($event)"
                            (keyup)="ValidateValue($event)"
                            (blur)="SetAllCurrencyList(CurrencyValueList.nodeId, $event)"
                            *ngIf="CurrencyValueList.allowSetAllEntry"
                            (contextmenu)="open($event,CurrencyValueList.nodeId)" />
                        <div class="display overlayHotlinkCopypaste hide rightclick-{{CurrencyValueList.nodeId}}">
                            <section class="user-menu" style="border: 1px solid;">
                                <div style="color: white;font-size: 18px;height: 35px;border-bottom: 1px solid;"
                                    (click)="CopyToClipboard(CurrencyValueList.nodeId)">Copy</div>
                                <div (click)="convertoExcel(CurrencyValueList.nodeId, CurrencyValueList , $event)"
                                    style="color: white;font-size: 18px;"> Paste </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-sm-12 display-border-after"></div>
                    <div
                        *ngFor="let result of CurrencyValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1; let i=index">
                        <div class="col-sm-12 ValueArray">
                            <label class="col-sm-5">{{DisplayMonthCollection[i+PeriodStartIndex].name }}</label>
                            <input type="text" class="col-sm-5 form-control" (keypress)="isNumber($event)"
                                (keyup)="ValidateValue($event)" id="{{result.nodeId}}-{{i}}"
                                [(ngModel)]="CurrencyValueList.decimalValuesArray[i]" (click)="valuelistregrex($event)"
                                (blur)="MonthCurrencyList(CurrencyValueList.nodeId, (i+PeriodStartIndex), $event, CurrencyValueList)"
                                value="{{CurrencyValueList.decimalValuesArray[i+PeriodStartIndex]}}"
                                style="text-align:end" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 buttonSample">
        <button class="btn button SettingSaveButtonColor col-sm-1"
            (click)="CalculateSave(CurrencyValueList.nodeId,'save')"> Save</button>
        <button class="btn button SettingCancelButtonColor col-sm-1" style="margin: 0 0 0px 7px;"
            (click)="CalculateSave(CurrencyValueList.nodeId,'close')"> Close</button>
    </div>
</div>