<igx-content>
    
    <div *ngIf="displayvaluelistdata.length>0">
            <select name="dropdown-option" *ngIf="displaydarrivation.length>0"
            class="form-control" style="width: 170px;font-size: 15px;margin-top: 4px;"
            (change)="BonnusDerrivation($event,displayvaluelistdata[0])"
            >
            <option *ngFor="let result of displayvaluelistdata[0].validDerivationTypes; let i = index"
            value="{{result}}"
            [selected]="result == displayvaluelistdata[0].derivationType"
            > {{ displayvaluelistdata[0].validDerrivationTypeName[i]}}
            </option>
            </select>

            <div *ngIf="valueListDerrivation!=null  && valueListDerrivation.derivationType==80031" style="width: max-content;padding: 0px;">
                <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
                    <button class="EmployeeGroupEntry form-control" style="font-size: 15px;background: #cccccc;"
                        (click)="bonusformulaform.open()">Formula</button>
                    <igx-dialog #bonusformulaform [closeOnOutsideSelect]="false">
                        <!-- <app-formula [FormulaDetails]="displayvaluelistdata" (opening)="onDialogOpenHandler($event)"
                            (formulaModifiedEvent)="formulaModified($event)"></app-formula> -->
                            <app-formula [FormulaDetails]="displayvaluelistdata" 
                            (formulaModifiedEvent)="formulaModified($event)"></app-formula>
                        <div igxDialogActions>
                            <button igxButton (click)="bonusformulaform.close()"
                                class="deleteCancelButtonColor">CANCEL</button>
                            <button igxButton class="OkSaveButtonColor"
                                (click)="saveFormulaCollection(bonusformulaform,displayvaluelistdata)">Save</button>
                        </div>
                    </igx-dialog>
                </div>
            </div>

            <div *ngIf="displayvaluelistdata.nodeTypeEnglishLabel != 'CapitalPaidNextYear'">
                <form>
                    <section class="collection">
                        <div class="collection-item" id="{{displayvaluelistdata.nodeId}}">
                        </div>
                    </section>
                    <div *ngIf="displaydarrivation.length==0">
                        <div class="valuelistdisplayrow">
                            <div class="row">
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                    <div class="testingdata-{{i}} bonusesdata "  *ngFor="let result of displayvaluelistdata;let i = index" 
                                    [ngClass]="{'BonusesClassDerrivation': result.englishLabel=='Bonuses', 'CumulativeBonusesClassDerrivation': result.englishLabel=='Cumulative Bonuses', 'AmendedWagesClassDerrivation': result.englishLabel=='Amended Wages' }"
                                    >
                                        <div class="row">
                                            <div _ngcontent-jck-c424="" class="col-sm-4"><label  class="display-title" style="font-size: 14px; color: white;">Net Sales</label></div>
                                            <div _ngcontent-jck-c424="" class="col-sm-8"><label  class="display-title" style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv" *ngIf="result.allowSetAllEntry && result.englishLabel=='Bonuses'">
                                            <div class="row">
                                                <label class="col-sm-5 ">Set All</label>
                                                <div class="col-sm-6"><input
                                                    decimals="{{result.decimalPlaces}}"
                                                    style="text-align: right;" 
                                                    class=" col-sm-10 {{result.valuesNodeId}}-setall"
                                                    (blur)="SetAllValueFromBonuses(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                    [disabled] = 'result.allowSetAllEntry ==false'
                                                    allow-decimal="true" allow-negative="true" 
                                                    type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv" *ngIf="result.allowOpeningEntry">
                                            <div class="row">
                                                <label class="col-sm-5 ">Opening</label>
                                                <div class="col-sm-6"><input
                                                    id="{{result.opening}}"
                                                    decimals="{{result.decimalPlaces}}"
                                                    style="text-align: right;" 
                                                    class=" col-sm-10 {{result.valuesNodeId}}-setall  "
                                                    (blur)="noDarivationopening(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                    allow-decimal="true" allow-negative="true" 
                                                    value="{{result.opening}}"
                                                    type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-after-setall"></div> 
                                        <div class="valuelistdisplayrow" *ngFor="let valuearr of PeriodtabViewResults |slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index ; trackBy:trackByFn">
                                            <div class="row {{i}}">
                                            <label class="col-sm-5 " [style.font-weight]="ValueListMonthDisplay[i+BonusesCommisionStartIndex].fontWight">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                                <div class="col-sm-6" *ngIf=" result.currentActualsIndex==-1">
                                                    <input *ngIf="result.allowValueEntry"
                                                        type="text"
                                                        class=" col-sm-10 {{result.valuesNodeId}}"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right;" 
                                                        (click)="valuelistregrex($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="PeriodtabViewResults[i].value"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoSetValuesFromBonusesCommision(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                        />
                                                        <input *ngIf="!result.allowValueEntry"
                                                        type="text"
                                                        class=" col-sm-10 {{result.valuesNodeId}} withoutActualModeEnable"
                                                        decimals={{result.decimalPlaces}}
                                                        [disabled]="true"
                                                        style="text-align: right;" 
                                                        (click)="valuelistregrex($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="PeriodtabViewResults[i].value"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoSetValuesFromBonusesCommision(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                        /> 
                                                </div>
                                                <div class="col-sm-6" *ngIf="result.allowValueEntry && result.currentActualsIndex!=-1">
                                                    <input  *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == true"
                                                    type="text"
                                                    class=" col-sm-10 {{result.valuesNodeId}}"
                                                    decimals={{result.decimalPlaces}}
                                                    style="text-align: right;" 
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                    [disabled]="false"
                                                    (click)="valuelistregrex($event)"
                                                    id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                    [(ngModel)]="PeriodtabViewResults[i].value"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (blur)="NoSetValuesFromBonusesCommision(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                    />
                                                    <input  *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == false"
                                                    type="text"
                                                    class=" col-sm-10 {{result.valuesNodeId}} ActualModeEnable"
                                                    decimals={{result.decimalPlaces}}
                                                    style="text-align: right;" 
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                    [disabled]="true"
                                                    [(ngModel)]="PeriodtabViewResults[i].value"
                                                    [ngModelOptions]="{standalone: true}"
                                                    />
                                                </div>
                                                <div class="col-sm-6" *ngIf="result.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                    <input  *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == true"
                                                    type="text"
                                                    class=" col-sm-10 {{result.valuesNodeId}}"
                                                    decimals={{result.decimalPlaces}}
                                                    style="text-align: right;" 
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                    [disabled]="false"
                                                    (click)="valuelistregrex($event)"
                                                    id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                    [(ngModel)]="PeriodtabViewResults[i].value "
                                                    [ngModelOptions]="{standalone: true}"
                                                    (blur)="NoSetValuesFromBonusesCommision(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                    />
                                                    <input  *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == false"
                                                    type="text"
                                                    class=" col-sm-10 {{result.valuesNodeId}} ActualModeEnable"
                                                    decimals={{result.decimalPlaces}}
                                                    style="text-align: right;" 
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                    [disabled]="true"
                                                    [(ngModel)]="PeriodtabViewResults[i].value"
                                                    [ngModelOptions]="{standalone: true}"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div> 
                                        <div class="valuelistdisplayrow" style="height: 3vh;" >
                                            <div class="row" *ngIf="result.hasTotal">
                                                <label class="col-sm-5">Total</label>
                                                <div class="col-sm-6" *ngIf="result.allowTotalEntry">
                                                    <input 
                                                    type="text"
                                                    id="{{result.valuesNodeId}}-total"
                                                    class="col-sm-10" 
                                                    style="text-align: right;"
                                                    value="{{result.total | minussignsparen}}"
                                                    (click)="valuelistregrex($event)"
                                                    (blur)="noDarivationtotal(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                    decimals={{result.decimalPlaces}}
                                                    [disabled]="result.allowTotalEntry == false"/>
                                                </div>
                                                <label *ngIf="!result.allowTotalEntry"
                                                id="{{result.valuesNodeId}}-total"
                                                class=" col-sm-5" 
                                                style="text-align: right;"
                                                value="{{result | employeetotal:BonusesCommisionStartIndex:BonusesCommisionEndIndex}}"> {{result | employeetotal:BonusesCommisionStartIndex:BonusesCommisionEndIndex}}</label>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let display of displaydarrivation">
                        <div class="defualtdisplay" *ngIf="display.inputs.length == 0">
                            <div class="valuelistdisplayrow"
                                [ngClass]="{'valuelistdisplayrow': display.targetNodeID == '',  'row valuelistdisplayrow': display.targetNodeID !='' }">
                                <div class="row DisplayAdjusted" *ngIf="addeddata && !display.isConsolidation">
                                    <!-- <label class="display-title" style="font-size: 14px;">{{ display.englishLabel}}</label> -->
                                    <div class="display-border-after-setall"></div>
                                    <app-sectiontree [sectiontreeview]="sectiontreeview" [WhatIf]="false"
                                        [selectedNodeIds]="selectedNodeIds"
                                        [CalculateAsBalance]="DisplayCalculateAsBalance"
                                        (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                                </div>
                                <div class="displayWithSelection {{displayvaluelistdata[0].nodeTypeEnglishLabel}}" [ngClass]="{'row':displayvaluelistdata[0].nodeTypeEnglishLabel!='TotalAdditions'}">
                                    <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                        <div class="testingdata-{{i}}"
                                            *ngFor="let result of displayvaluelistdata;let i = index">
                                            <div *ngIf="DerivationOptions">
                                                <div class="valuelistdisplayrow derrivationdisplaydiv"
                                                    *ngIf="DerivationOptions.localizedLabel">
                                                    <div class="col-sm-12" *ngIf="DerivationOptions.optionType == 550029">
                                                        <div *ngIf="DerivationOptions.optionType == 550029">
                                                            <div class="col-sm-3">
                                                                <input type="checkbox" class=" col-sm-11"
                                                                    [checked]="DerivationOptions.isSelected"
                                                                    (change)="CalculateAsBalance(DerivationOptions, $event)" />
                                                            </div>
                                                            <label class=" col-sm-9"
                                                                style="font-size: 14px;">{{DerivationOptions.localizedLabel}}</label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            *ngIf="DerivationOptions.localizedLabel !='Calculate As Balance'">
                                                            <label
                                                                class="col-sm-5 ">{{DerivationOptions.localizedLabel}}</label>
                                                            <div class="col-sm-7"><input
                                                                    style="text-align: right; padding:1px;"
                                                                    class=" col-sm-11 {{result.values.valuesNodeId}}-percentage"
                                                                    (blur)="noDarrivationPercentage(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                                    value="{{DerivationOptions.storedValue*100}}"
                                                                    allow-decimal="true" allow-negative="true"
                                                                    type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="row" style="height: 40px;">
                                                <div _ngcontent-jck-c424="" class="col-sm-4"><label class="display-title"
                                                        style="font-size: 14px; color: white;">Net Sales</label></div>
                                                <div _ngcontent-jck-c424="" class="col-sm-8"><label class="display-title"
                                                        style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                            </div>
                                            <div class="valuelistdisplayrow setalldisplaydiv">
                                                <div class="row" style="height: 30px;"
                                                    *ngIf="result.values.allowOpeningEntry && !result.isConsolidation">
                                                    <label class="col-sm-5 ">Opening</label>
                                                    <div class="col-sm-7"><input id="{{result.opening}}"
                                                            decimals="{{result.decimalPlaces}}"
                                                            style="text-align: right; padding:1px;"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                            (blur)="noDarivationopening(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                            allow-decimal="true" allow-negative="true"
                                                            (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                            value="{{result.opening}}" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="valuelistdisplayrow setalldisplaydiv">
                                                <!-- <div class="display-border-after-setall"></div>  -->
                                                <div class="row" style="height: 30px;"
                                                    *ngIf="result.values.allowSetAllEntry && !result.isConsolidation && (result.allowSetAllEntry== true && result.values.allowValueEntry== true)">
                                                    <label class="col-sm-5" id="hidecopy-{{result.values.valuesNodeId}}"
                                                        *ngIf="result.allowSetAllEntry== true && result.values.allowValueEntry== true">Set All</label>
                                                    <div class="col-sm-7"
                                                        *ngIf="result.allowSetAllEntry== true && result.values.allowValueEntry== true">
                                                        <input decimals="{{result.decimalPlaces}}"
                                                            style="text-align: right; padding:1px;"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}}-setall"
                                                            (blur)="noDarivationsetall(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                            (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                            [disabled]='result.allowSetAllEntry ==false'
                                                            (contextmenu)="open($event,result.values.valuesNodeId)"
                                                            allow-decimal="true" allow-negative="true" type="text" />
                                                        <div
                                                            class="display overlayHotlinkCopypaste hide rightclick-{{result.values.valuesNodeId}}">
                                                            <section class="user-menu" style="border: 1px solid;">
                                                                <div style="color: white;font-size: 18px;height: 35px;border-bottom: 1px solid;" 
                                                                (click)="CopyToClipboard(result.values.valuesNodeId)">Copy</div>
                                                                <div (click)="convertoExcel(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                                    style="color: white;font-size: 18px;"> Paste  </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="display-border-after-setall"></div>
                                            <div class="valuelistdisplayrow"
                                                *ngFor="let valuearr of result.values.valuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index ; trackBy:trackByFn">
                                                <div class="row">
                                                    <label class="col-sm-5 ">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                                    <div class="col-sm-7" *ngIf=" result.currentActualsIndex==-1">
                                                        <input
                                                            *ngIf="result.values.allowValueEntry && !result.isConsolidation"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                        <input
                                                            *ngIf="!result.values.allowValueEntry && result.nodeType != 60103"
                                                            type="text"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}} withoutActualModeEnable"
                                                            decimals={{result.values.decimalPlaces}} [disabled]="true"
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0, i+BonusesCommisionStartIndex)" />
                                                        <input
                                                            *ngIf="!result.values.allowValueEntry && result.values.isConsolidation && result.nodeType == 60103"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}" disabled="true" />
                                                            <input
                                                            *ngIf="!result.values.allowValueEntry && !result.values.isConsolidation && result.nodeType == 60103"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}" disabled="true" />
                                                        <input
                                                            *ngIf="result.values.allowValueEntry && result.values.isConsolidation"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}" disabled="true" />
                                                        <!-- <input *ngIf="!result.allowValueEntry && result.nodeType == 60103" type="text"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}} withoutActualModeEnable"
                                                            decimals={{result.values.decimalPlaces}} [disabled]="true"
                                                            style="text-align: right; padding:1px;"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0, i+BonusesCommisionStartIndex)" /> -->
                                                    </div>
                                                    <div class="col-sm-7"
                                                        *ngIf="result.values.allowValueEntry && result.currentActualsIndex!=-1">
                                                        <input
                                                            *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == true"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                            [disabled]="false" (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                        <input
                                                            *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == false"
                                                            type="text"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                            [disabled]="true" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                    <div class="col-sm-7"
                                                        *ngIf="result.values.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                        <input
                                                            *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == true"
                                                            type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                            [disabled]="false" (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                            id="{{result.values.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                        <input
                                                            *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == false"
                                                            type="text"
                                                            class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                            decimals={{result.values.decimalPlaces}}
                                                            style="text-align: right; padding:1px;"
                                                            [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                            [disabled]="true" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="display-border-before-total"></div>
                                            <div class="valuelistdisplayrow Total-div" style="height: 3vh;" >
                                                <div class="row" *ngIf="result.values.hasTotal">
                                                    <label class="col-sm-5">Total</label>
                                                    <div class="col-sm-7" *ngIf="result.values.allowTotalEntry">
                                                        <input type="text" id="{{result.values.valuesNodeId}}-total"
                                                            class=" col-sm-11  {{result.total}}"
                                                            style="text-align: right; padding:1px;"
                                                            value="{{result.total | minussignsparen}}"
                                                            (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            (blur)="noDarivationtotal(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                            decimals={{result.decimalPlaces}}
                                                            [disabled]="result.values.allowTotalEntry == false || result.values.isConsolidation" />
                                                    </div>
                                                    <label *ngIf="!result.values.allowTotalEntry"
                                                        id="{{result.values.valuesNodeId}}-total" class=" col-sm-5"
                                                        style="text-align: right; padding:1px;"
                                                        value="{{result.total | minussignsparen}}"> {{result.total |
                                                        minussignsparen}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel == 'TotalAdditions'">
                                        <div class="col" *ngFor="let result of displayvaluelistdata;let i = index">
                                            <label class="display-title" style="font-size: 14px;">{{
                                                displayvaluelistdata[0].englishLabel}}</label>
                                            <div class="display-border-after-setall"></div>
                                            <div class="row valuelistdisplayrow"
                                                *ngFor="let defualtresult of result.values.valuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index">
                                                <div class="col">
                                                    <label class="col-sm-5 "
                                                        [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                                    <label class="col-sm-5 " style="text-align: right; padding:1px;"
                                                        [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{defualtresult
                                                        * (i+1) }}</label>
                                                </div>
                                            </div>
                                            <div class="display-border-before-total"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="displaywithdarrivation" *ngIf="display.inputs.length> 0">
                            <div class="row valuelistdisplayrow">
                                <div class="col-sm-4 {{addeddata}}" *ngIf="addeddata && !display.isConsolidation">
                                    <label class="display-title" style="font-size: 14px; height: 44px;"
                                        *ngIf="displayvaluelistdata[0].englishLabel">{{
                                        displayvaluelistdata[0].englishLabel}}</label>
                                    <div class="montharrayheader"></div>
                                    <div class="display-border-after-setall"></div>
                                    <app-sectiontree [sectiontreeview]="sectiontreeview" [selectedNodeIds]="selectedNodeIds"
                                        [WhatIf]="false"
                                        [CalculateAsBalance]="DisplayCalculateAsBalance"
                                        (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                                </div>
                                <div [ngClass]="
                                {'col': displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals',  
                                'col-sm-3' : displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals'
                                 && displayvaluelistdata[0].derivationEngType != 'CalculateOverdraftInterest'
                                 && displayvaluelistdata[0].derivationEngType != 'FromStockOnHand'  ,
                                 'col-sm-2':displayindex != 0 && displayvaluelistdata[0].derivationEngType == 'FromStockOnHand',
                                'col':displayvaluelistdata[0].derivationEngType == 'ProfileData' || displayvaluelistdata[0].derivationEngType == 'CalculateOverdraftInterest',
                                'col-sm-5':inputarrayloop.customenglishname == 'EvenSpread' }"
                                    class="custom-{{inputarrayloop.customenglishname}}"
                                    *ngFor="let inputarrayloop of display.inputs;let i = index; let displayindex = index">
    
                                    <div class="row customAS-{{inputarrayloop.customenglishname}}"
                                        [ngClass]="{titleheader:displayvaluelistdata[0].englishLabel == 'Bank Overdraft Interest'}"
                                        *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals' && !inputarrayloop.isConsolidation">
                                        <div class="col-sm-4 customAS-{{inputarrayloop.customenglishname}}">
                                            <label class="display-title"
                                                [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                                style="font-size: 14px; color: white; position: absolute; display: none;">
                                                {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                            </label>
                                        </div>
                                        <div class="col-sm-8 customAS-{{inputarrayloop.customenglishname}}" *ngIf=" !inputarrayloop.isConsolidation">
                                            <label class="display-title"
                                                [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                                style="font-size: 14px;text-align: left;">
                                                {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                            </label>
                                        </div>
                                    </div>
                                    <label class="display-title"
                                        *ngIf="displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals' && !inputarrayloop.isConsolidation"
                                        style="font-size: 14px;">{{inputarrayloop.englishLabel}}
                                        {{display.derivationEngType}}</label>
                                    <div class="montharrayheader">
                                        <div
                                            *ngIf="inputarrayloop.allowValueEntry == true && !inputarrayloop.isConsolidation">
                                            <div class="row valuelistdisplayrow setalldisplaydiv"
                                                *ngIf="inputarrayloop.allowSetAllEntry">
                                                <label class="col-sm-5"
                                                    *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals' " 
                                                    id="hidecopy-{{inputarrayloop.englishLabel}}">Set All</label>
                                                <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0}">
                                                    <input
                                                        (blur)="darrivationsetall(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                        decimals="{{inputarrayloop.decimalPlaces}}"
                                                        id="{{inputarrayloop.englishLabel}}-setall-{{i}}"
                                                        style="text-align: right; padding:1px;"
                                                        (keypress)="LimitOnly($event, inputarrayloop.limitNumber)"
                                                        (keyup)="ValidateValue($event)"
                                                         class=" col-sm-11"
                                                        [disabled]='inputarrayloop.allowSetAllEntry ==false'
                                                        (contextmenu)="open($event,inputarrayloop.englishLabel)" type="text" />
                                                        <div
                                                            class="display overlayHotlinkCopypaste hide rightclick-{{inputarrayloop.englishLabel}}">
                                                            <section class="user-menu" style="border: 1px solid;">
                                                                <div style="color: white;font-size: 18px;height: 35px;border-bottom: 1px solid;" 
                                                                (click)="CopyToClipboard(inputarrayloop.englishLabel)">Copy</div>
                                                                <div (click)="convertoExcel(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                                    style="color: white;font-size: 18px;"> Paste  </div>
                                                            </section>
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="row valuelistdisplayrow setalldisplaydiv"
                                                *ngIf="inputarrayloop.allowOpeningEntry">
                                                <label class="col-sm-5" *ngIf="displayindex == 0">Opening</label>
                                                <div class="col-sm-7">
                                                    <input
                                                        id="{{inputarrayloop.englishLabel}}-setall-{{i}}  {{inputarrayloop.opening}}"
                                                        decimals="{{inputarrayloop.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        (blur)="noDarivationopening(inputarrayloop.englishLabel, inputarrayloop, $event , displayvaluelistdata , 0)"
                                                        allow-decimal="true" allow-negative="true"
                                                        (keyup)="ValidateValue($event)"
                                                        value="{{inputarrayloop.opening | minussignsparen}}" type="text" />
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="display-border-after-setall"></div>
                                    <div class="display-border">
                                        <div class="row" *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">
                                            <label class="col-sm-5"
                                                *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">{{cyclePeriodIndex}}</label>
                                            <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex ==-1">
                                                <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                    class=" col-sm-11 {{inputarrayloop.englishLabel}}" type="text"
                                                    id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                    [(ngModel)]="inputarrayloop.valuesArray[0]"
                                                    (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                    (click)="valuelistregrex($event)"
                                                    style="text-align: right; padding:1px;"
                                                    [ngModelOptions]="{standalone: true}" />
                                            </div>
                                            <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex !=-1">
                                                <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                    class=" col-sm-11 {{inputarrayloop.englishLabel}} {{inputarrayloop.currentActualsIndex}}"
                                                    type="text" disabled=disabled
                                                    id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                    [(ngModel)]="inputarrayloop.valuesArray[0]"
                                                    (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                    (click)="valuelistregrex($event)"
                                                    style="text-align: right; padding:1px;"
                                                    [ngModelOptions]="{standalone: true}" />
                                            </div>
    
                                        </div>
                                        <div *ngIf="inputarrayloop.englishLabel != 'Cycle Totals'">
                                            <div class="MonthArrayClass">
                                                <div *ngIf="inputarrayloop.englishLabel =='Payment Profile'">
                                                    <div class="row valuelistdisplayrow"
                                                        *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:0:12; let i = index; trackBy:trackByFn">
                                                        <label class="col-sm-5"
                                                            *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                            [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                            inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                        <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                            *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                                [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                                type="text"
                                                                [style.font-weight]="FontWight(i, inputarrayloop)"
                                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                                [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                                (click)="valuelistregrex($event)"
                                                                (keypress)="isNumber($event)"
                                                                (keyup)="ValidateValue($event)"
                                                                style="text-align: right; padding:1px;"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                                [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                        </div>
                                                        <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                            *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} latestData"
                                                                type="text"
                                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                                [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                                (click)="valuelistregrex($event)"
                                                                (keypress)="isNumber($event)"
                                                                (keyup)="ValidateValue($event)"
                                                                style="text-align: right; padding:1px;"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <label
                                                                *ngIf="inputarrayloop.allowValueEntry==false">{{valuearrayresult}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="inputarrayloop.englishLabel !='Payment Profile'">
    
                                                    <div class="row valuelistdisplayrow"
                                                        *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index; trackBy:trackByFn">
                                                        <label class="col-sm-5"
                                                            *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel!='Payment Profile'"
                                                            [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                                        <label class="col-sm-5"
                                                            *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                            [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                            inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                        <label class="col-sm-5"
                                                            *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">20-21</label>
    
                                                        <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                            *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                                [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                                type="text"
                                                                [style.font-weight]="FontWight(i, inputarrayloop)"
                                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                                [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                                (click)="valuelistregrex($event)"
                                                                (keypress)="isNumber($event)"
                                                                (keyup)="ValidateValue($event)"
                                                                style="text-align: right; padding:1px;"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                                [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                        </div>
                                                        <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                            *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} latestData"
                                                                type="text"
                                                                id="{{inputarrayloop.englishLabel}}-months-{{i+BonusesCommisionStartIndex}}"
                                                                [(ngModel)]="inputarrayloop.valuesArray[i+BonusesCommisionStartIndex]"
                                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                                (click)="valuelistregrex($event)"
                                                                (keypress)="isNumber($event)"
                                                                (keyup)="ValidateValue($event)"
                                                                style="text-align: right; padding:1px;"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <label
                                                                *ngIf="inputarrayloop.allowValueEntry==false">{{valuearrayresult}}</label>
                                                        </div>
    
                                                    </div>
    
                                                </div>
    
                                            </div>
                                            <div class="display-border-before-total"></div>
                                            <div class="row valuelistdisplayrow setalldisplaydiv Total-div" style="height: 3vh;">
                                                <label class="col-sm-5"
                                                    *ngIf="displayindex == 0 && !inputarrayloop.isConsolidation">Total</label>
                                                <div *ngIf="inputarrayloop.englishLabel=='Even Spread'" class="col-sm-7">
                                                    <div class="col">
                                                        <label class=" col-sm-11">{{inputarrayloop.total}}</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="display.englishLabel !='Profile'" class="col-sm-7">
                                                    <div class="col"
                                                        *ngIf="inputarrayloop.hasTotal && inputarrayloop.allowValueEntry && !inputarrayloop.isConsolidation">
                                                        <label class=" col-sm-11"
                                                            *ngIf="inputarrayloop.allowValueEntry == false">{{inputarrayloop.total}}</label>
                                                    </div>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-sm-4 displayvaluedisbaled-{{displayvaluelistdata[0].nodeTypeEnglishLabel}}"
                                    *ngFor="let result of displayvaluelistdata;let i = index">
                                    <label class="display-title" style="font-size: 14px; height: 46px;">{{
                                        displayvaluelistdata[0].englishLabel}}</label>
                                    <div class="row valuelistdisplayrow setalldisplaydiv"></div>
                                    <div class="display-border-after-setall"></div>
                                    <div class="MonthArrayClass">
                                        <div class="row valuelistdisplayrow"
                                            *ngFor="let defualtresult of result.values.valuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index">
                                            <div *ngIf="result.values.currentActualsIndex==-1" class="col">
                                                <input type="text" disabled=disabled value="{{defualtresult}}"
                                                    class=" thirdsectionNoActual col-sm-11">
                                            </div>
                                            <div class="col" *ngIf="result.values.currentActualsIndex!=-1">
                                                <input *ngIf="IsEnabled(i+BonusesCommisionStartIndex,result.values) == true"
                                                    class=" col-sm-11 {{result.values.englishLabel}}" type="text"
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                    id="{{result.values.englishLabel}}-months-{{i+BonusesCommisionStartIndex}}"
                                                    [(ngModel)]="result.values.valuesArray[i+BonusesCommisionStartIndex]"
                                                    [ngClass]="{'ActualModeEnable': IsEnabled(i+BonusesCommisionStartIndex,result.values) == false && FontWight(i+BonusesCommisionStartIndex, result.values)=='Normal'}"
                                                    [disabled]="IsEnabled(i+BonusesCommisionStartIndex,result.values) == false && FontWight(i+BonusesCommisionStartIndex, result.values)=='Normal'"
                                                    (blur)="derrivationmonthCalculation(result.values.englishLabel, result.values , $event , displayvaluelistdata , 1,i+BonusesCommisionStartIndex)"
                                                    (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                    (keyup)="ValidateValue($event)" style="text-align: right; padding:1px;"
                                                    [ngModelOptions]="{standalone: true}" />
                                                <input type="text"
                                                    *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result.values) == false"
                                                    disabled=disabled class=" col-sm-11 thirdsection"
                                                    style="text-align: right; padding:1px;"
                                                    [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result.values)"
                                                    value="{{defualtresult}}" />
                                            </div>
                                            <div class="display-border-after-setall row"></div>
                                        </div>
                                    </div>
                                    <div class="display-border-before-total"></div>
                                    <div class="row valuelistdisplayrow">
                                        <div class="col">
                                            <input type="text" disabled=disabled class=" col-sm-11"
                                                id="{{result.values.valuesNodeId}}-total"
                                                value="{{displayvaluelistdata[0].total | minussignsparen}}"
                                                style="text-align: right; padding:1px;" />
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
    
    
                </form>
            </div>
    </div>
</igx-content>


                    