<div class="insidecontainer" style="width: 1122px;height: 519px;">
    <igx-dialog-title class="dialogtitle" style="background: dodgerblue; color: white; padding: 5px; width: 1300px; margin: -12px 0 0 -17px; font-size: 16px; font-weight: 400;">
        <div class="title-container">
            <div class="dialog-title">Hotlink</div>
        </div>
    </igx-dialog-title>
    <igx-content>
        <div class="col-sm-9">
            <table class="table table-bordered" style="margin: 7px 0 0 -17px;">
                <th>*</th>
                <th>FileName</th>
                <th>Hotlink Type</th>
                <th>FolderPath</th>
                <tbody>
                    <tr *ngFor="let hotlinkresult of hotlinksourcecollection; let i=index" 
                   [ngClass]="{'Active': ActiveIndexHotLink == hotlinkresult.hotLinkDetailsNodeId}" class="{{ActiveIndexHotLink}}" (click)="selectedHotlinkCollection(hotlinkresult, index)">
                        <td>*</td>
                        <td>{{hotlinkresult.fileName}}</td>
                        <td>{{hotlinkresult.hotLinkType}}</td>
                        <td>{{hotlinkresult.folderPath}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-2" style="height: 482px;margin: 7px 0 0 0;border: 2px solid #dee2e6;">
            <div class="col" style="margin: 36px 0 0 0;">
                    <button class="newForecastSubmitButton OkSaveButtonColor hotlinkbutton col" style="font-size: 16px;border: none;border-radius: 4px;margin: 0px 10px 0 0;"  
                    (click)="hotlinkOpen(hotlinkForecast,1)">Add</button>
                    <button class="hide" id="UpdateHotlink" style="font-size: 16px;border: none;border-radius: 4px;margin: 0px 10px 0 0;"  
                    (click)="hotlinkEdit(hotlinkForecast,1)">Edit</button>
                    <igx-dialog #hotlinkForecast [closeOnOutsideSelect]="false">
                        <div *ngIf="addhotlink">
                            <app-hotlink-data-source-collection [SelectedHotlinkData]="SelectedHotlink" ></app-hotlink-data-source-collection>
                            <div igxDialogActions>
                                <button igxButton (click)="hotlinkForecast.close()" class="deleteCancelButtonColor">CANCEL</button>
                                <button igxButton class="OkSaveButtonColor" (click)="hotlinkForecastSave(hotlinkForecast)">OK</button>
                            </div>
                        </div>
                    </igx-dialog>
            </div>
            <div class="col">    
                <button [ngClass]="{'disabled': SelectedHotlink==null}" style="margin: 9px 0px 0px 0px;" class="deleteCancelButtonColor hotlinkbutton col" (click)="DeleteHotlink(SelectedHotlink)">Delete</button>
            </div>
        </div>
    </igx-content>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
      </ngx-spinner>
 </div>   