import { Component, OnInit } from '@angular/core';
import { UsermaintenanceService } from '../services/usermaintenance.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-list-user-forecast',
  templateUrl: './list-user-forecast.component.html',
  styleUrls: ['./list-user-forecast.component.scss']
})
export class ListUserForecastComponent implements OnInit {

  listofForecast:any = [];
  ListOfUsers:any = [];
  ListOfGroup:any = [];
  ActiveForecast:any = null;
  ActiveOwner:any = null;
  ActiveGroup:any = null;
  constructor(
    public usermaintenanceService:UsermaintenanceService,
    public SpinnerService:NgxSpinnerService) { }

  ngOnInit() {
      this.usermaintenanceService.getForecastList().subscribe(
        (response=>{
          this.listofForecast = response;
        })
      )
  } 

  ngOnChanges(){
    this.usermaintenanceService.getForecastList().subscribe(
        (response=>{
          this.listofForecast = response;
        })
      )
  }
  OepnAddUserDialog(UserManagementForecastAcess){
    UserManagementForecastAcess.open();
    this.usermaintenanceService.getForecastAccessuser(this.ActiveForecast).subscribe(
      (( response:any )=>{
        this.ListOfUsers = response;
      })
    )
  }
  ForecastClickEvent(ForecastId){
    this.ActiveForecast = ForecastId;
  }
  SelectedOwner($event){
    let SelectedOwner = $event.target.value;
    if(SelectedOwner!=null && SelectedOwner!=''){
      this.ActiveOwner = SelectedOwner;
      this.usermaintenanceService.getForecastAccessGetGroup(this.ActiveForecast, SelectedOwner).subscribe(
        ((response:any)=>{
          this.ListOfGroup = response;
        })
      )
    }
  }

  SelectedGroup($event){
    let selectedGroupId = $event.target.value;
    if(selectedGroupId!=null && selectedGroupId!=''){
      this.ActiveGroup = selectedGroupId;
      
    }
  }
  UpdateForecastAccess(UserManagementForecastAcess){
    console.log(this.ActiveGroup);
    if(this.ActiveGroup!=null && this.ActiveGroup!='' && this.ActiveOwner!=null && this.ActiveOwner!=''){
      this.SpinnerService.show();
      this.usermaintenanceService.updateForecastAccess(this.ActiveForecast, parseInt(this.ActiveOwner), parseInt(this.ActiveGroup)).subscribe(
        ( (response)=>{
          this.SpinnerService.hide();
          UserManagementForecastAcess.close();
        })
      )
    }
  }
}
