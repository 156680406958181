<div class="">
    <div class="">
        <h3>{{RateName}} Settings</h3>
    </div>
    <div class="col-sm-12" style="margin-top:1%; height: 675px;">
        <div class="col-sm-3">
            <h4>{{RateName}}</h4>
            <div class="" *ngIf="collections!=''">
                <igx-select class="selectToResize" [(ngModel)]="collections.selectedsalesTaxRecordCollection">
                    <igx-select-item *ngFor="let results of collections.salesTaxRecordCollection" [value]="results.name"
                        (click)='selectedsalesTaxRecordCollectionEvent(results)'>
                        {{results.englishName}}
                    </igx-select-item>
                </igx-select>
            </div>
            <div class="borderafterCollection"></div>
            <div class="" *ngIf="collections!=''" style="margin-top: 20px;">
                <h4>Tax Period Ends</h4>
                <igx-select [(ngModel)]="collections.selectedValidDerivationTypes">
                    <igx-select-item *ngFor="let results of collections.validDerivationTypes" [value]="results.value"
                        (click)='selectedValidDerivationTypesEvent(results)'>
                        {{results.name}}
                    </igx-select-item>
                </igx-select>
            </div>
            <div class="" *ngIf="collections!='' && collections.validPeriods.length>0 && displayprefferedMonths"
                style="margin-top: 13px;">
                <igx-select [(ngModel)]="collections.selectedValidPeriodName">
                    <igx-select-item *ngFor="let results of collections.validPeriods; let i=index" [value]="results.id"
                        (click)='selectedValidPeriodsEvent(results , $event , i)'>
                        {{results.name}}
                    </igx-select-item>
                </igx-select>
            </div>
            <div class="borderafterCollection"></div>
            <div class="" *ngIf="collections!=''" style="margin-top: 20px;">
                <h4>Months in arrears</h4>
                <input type="text" style="font-size: 16px!important" (blur)="deferredPaymentMonthsEvent($event)"
                    (keypress)="numberOnly($event)" class="form-control col-sm-3"
                    value="{{collections.deferredPaymentMonths}}">
            </div>
        </div>

        <div class="col-sm-3 displayGSTRatesList">
            <div class="">
                <button class="btn SettingSaveButtonColor col-sm-3" style="margin-bottom: 4px;margin: 0 3px 6px 0px;"
                    (click)="AddGSTRates()">New</button>
                <button class="btn SettingCancelButtonColor col-sm-3" (click)="DeleteGSTRates()">Delete</button>
                <!-- <div>
                    <button class="btn btn-secondary col-sm-4" 
                    [disabled]="DefaultSelectedIndex==ActiveRecord"  style="margin: 0 0 0 3px;">Default</button>
                </div> -->
                <div>
                    <button class="btn SettingCancelButtonColor col-sm-4"
                        [disabled]="DefaultSelectedIndex==ActiveRecord" (click)="DefaultGSTIndex()"
                        style="font-size: 16px;font-size: 16px;margin: 0 0 0 3px;">Default</button>
                </div>

            </div>
            <table class="table table-bordered" *ngIf="displayBudgetList==true">
                <thead>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                </thead>
                <tbody>
                    <tr *ngFor="let GSTRates of GSTRatesCollection; let i=index;"
                        [ngClass]="{'selected':i==ActiveRecord}" (click)="changeValueList(i, GSTRates.nodeId)">
                        <td><span *ngIf="i==DefaultSelectedIndex">*</span></td>
                        <td [ngClass]="{'Active':i==ActiveRecord}"><input type="text" style="border:none"
                                value="{{GSTRates.name}}" (blur)="GSTRatesSettingNameChange(i, GSTRates, $event)"
                                name="GSTRatesSettingName[i]" class="col-sm-10"
                                [ngClass]="{'GSTRatesname':i==ActiveRecord}" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-6">
            <div class="bs-example">
                <ul class="nav nav-tabs" *ngIf="PeriodTabViewCollection.length>0">
                    <li class="nav-item" style="margin: 0 8px -2px -1px;">
                        <select form="Payrool-payrate" class="form-control"
                            style="background: #86b6d9; color: white; font-size: 12px; font-weight: bold;">
                            <option value="Annual" ng-reflect-value="Annual">Annual</option>
                            <option value="All" ng-reflect-value="All">All</option>
                        </select>
                    </li>
                    <li class="nav-item" *ngFor="let result of PeriodTabViewCollection[0].results;  let i=index"
                        (click)="ChangePeriodType(result, i)">
                        <a href="#{{result.nodeId}}" class="nav-link" [ngClass]="{'active':i==ActivetabView}"
                            data-toggle="tab">{{result.longLabel}}</a>
                    </li>
                </ul>

                <div class="GSTRatesValueListArray col-sm-6" *ngIf="displayBudgetList==true">
                    <div class="col-sm-12 ValueArray" *ngIf="GSTRatesValueList.allowOpeningEntry == true">
                        <label class="col-sm-5">Opening</label>
                        <input class="col-sm-5 form-control" style="text-align:end"
                            *ngIf="GSTRatesValueList.allowOpeningEntry"
                            value="{{collections.collectionViewModels.opening}}" />
                    </div>
                    <div class="col-sm-12 ValueArray" *ngIf="GSTRatesValueList.allowSetAllEntry == true">
                        <label class="col-sm-5" *ngIf="GSTRatesValueList.allowSetAllEntry">SetAll</label>
                        <input class="col-sm-5 form-control" style="text-align:end"
                            (blur)="SetAllGSTRatesList(GSTRatesValueList.nodeId, $event)"
                            *ngIf="GSTRatesValueList.allowSetAllEntry" />
                    </div>
                    <div
                        *ngFor="let result of GSTRatesValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1; let i=index">
                        <div class="col-sm-12 ValueArray">
                            <label class="col-sm-5">{{ DisplayMonthCollection[i+PeriodStartIndex].name }}</label>
                            <input type="text" class="col-sm-5 form-control"
                                (blur)="MonthGSTRatesList(GSTRatesValueList.nodeId, (i+PeriodStartIndex), $event, GSTRatesValueList)"
                                value="{{GSTRatesValueList.valuesArray[i+PeriodStartIndex]}}" style="text-align:end" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="button-sample row col" style="float: right;">
        <button class="btn OkSaveButtonColor col-sm-1" (click)="gstRatesSave()"
            style="font-size: 16px;margin: 0px 7px 0 0;">Save</button>
        <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingClose()"
            style="font-size: 16px;margin: 0px 7px 0 7px;">Close</button>

    </div>
</div>