import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminmoduleService {

  constructor(private http: HttpClient) { }

  GetAllPackageInformation(): Observable<any> {
    return this.http.get(environment.url + "/api/Admin/GetAllPackageInformation", { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }


  RaiseSupportRequest(supportRequest: FormData): Observable<boolean> {
    let url = environment.url + "/api/Admin/RaiseSupportRequest";
    return this.http.post(url, supportRequest, {
      reportProgress: true,
      observe: 'events'
    })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
}
