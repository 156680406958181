<div style="margin: 10px -14px 16px -15px;height: 22px;" class="col-sm-12">
<div class="col-sm-3" style="padding: 0px;">
  <button class="button newsettingMenu" igxButton="contained" [igxToggleAction]="dropdown"
    [igxDropDownItemNavigation]="dropdown" style="font-size: 15px !important;font-weight: 400 !important;">New</button>
  <igx-drop-down #dropdown [width]="'240px'">
      <div class="drop-down__scroll-container">
          <igx-drop-down-item *ngFor="let item of items" [disabled]="item.code==''" [isHeader]="item.header"
              [selected]="item.selected" [ngClass]="{'TreeTopMenu': item.nodeId>=5}" 
              (click)="this[item.function](item.nodeId)" [class.drop-down-header]="item.header">
              {{ item.field }}
              <igx-divider></igx-divider> 
          </igx-drop-down-item>
      </div>
  </igx-drop-down>
  </div>
  <div  class="col-sm-4" style="padding: 0px;">
    <div class="">
        <button class="btn SettingCancelButtonColor" style="margin: 0px 0 0 10px;background: #1891ff !important;padding: 6px;width: 100%;font-size: 14px !important;font-weight: 400 !important;" (click)="openReports()">Reports</button>
    </div>
</div>
  <div class="col-sm-3" *ngIf="SelectedTreenodeEvent">
    <div style="display:flex;margin-right: 10px;">
      <a style="cursor: pointer;">
        <button class="btn btn-primary" style="padding: 6px; font-size: 15px !important;font-weight: 400 !important;" *ngIf="getSelectedNodeDetailsFromService.canAddRecord"
        (click)="AddRecord(getSelectedNodeDetailsFromService.nodeId)">Add Record</button>
        </a>
    </div>
  </div>
</div>
<div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
  <button class="AddDepartMentGroupEntry form-control hide" id="AddDepartMentGroupEntry"
    style="font-size: 15px;background: #cccccc;" (click)="AddDepartmentForm.open()">Add Department</button>
  <igx-dialog #AddDepartmentForm [closeOnOutsideSelect]="false">
    <div class="insidecontainer" style="width: 465px;height: 100px;">
      <igx-dialog-title class="dialogtitle">
        <div class="title-container">
          <div class="dialog-title">Department</div>
        </div>
      </igx-dialog-title>
      <div class="Department row">
        <label class="col-sm-3">Department Name</label>
        <input type="text" class="col-sm-7 form-control" style="margin: -1px 0 -9px 15px;" id="DepartmentName" />
      </div>
      <div class="Department row">
        <div class="col-sm-2"></div>
        <input type="checkbox" class="col-sm-2 form-control" id="showGrossProfitPercent" />
        <label class="col-sm-7" style="margin: 3px 0 0 -27px;">Show Gross Profit Percent</label>
      </div>
    </div>
    <div igxDialogActions>
      <button igxButton (click)="AddDepartmentForm.close()" class="deleteCancelButtonColor">CANCEL</button>
      <button igxButton class="OkSaveButtonColor"
        (click)="AddDepartment(getSelectedNodeDetailsFromService.nodeId,AddDepartmentForm)">Save</button>
    </div>
  </igx-dialog>
</div>
<div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
  <button class="DeleteDepartmentResponse form-control hide" id="DeleteDepartmentResponse"
    style="font-size: 15px;background: #cccccc;" (click)="DeleteDepartmentResponse.open()">Department Response</button>
  <igx-dialog #DeleteDepartmentResponse [closeOnOutsideSelect]="false">
    <div class="insidecontainer" style="width: 465px;height: 100px;">
      <igx-dialog-title class="dialogtitle">
        <div class="title-container">
          <div class="dialog-title">Warning</div>
        </div>
      </igx-dialog-title>
      <div class="Department row">
        <label class="col">{{DepartmentErrorResponse}}</label>
      </div>
    </div>
    <div igxDialogActions>
      <button igxButton (click)="DeleteDepartmentResponse.close()" class="deleteCancelButtonColor">CANCEL</button>
      <button igxButton class="OkSaveButtonColor" (click)="DeleteDepartmentResponse.close()">Save</button>
    </div>
  </igx-dialog>
</div>
<input id="filter3" #filter3 (keyup)="filterFn(filter3.value, tree.treeModel)"
  style="width: 95%;padding: 14px;height: 38px;font-size: 16px;border-radius: 6px;" placeholder="Search Record" />
<button _ngcontent-foc-c126="" class="ForecastSearchicon" type="submit"><i _ngcontent-foc-c126=""
    class="fa fa-search"></i></button>
<div class="treeMenu">
  <tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="nodes"
    (initialized)="onTreeLoad($event)" (activate)="ActiveEvent($event)" (updateData)="onUpdateData()"
    (onMoveNode)="onMoveNode($event)">
    <ng-template #treeNodeTemplate let-node let-index="index">
      <span [attr.draggable]="true" [attr.nodeType]="node.data.nodeType" [attr.aria-grabbed]="true"
        [class.draggable]="true" [class]="node.data.name" class="w-full flex items-center " id="{{node.data.nodeId}}" [class.title]="true"
        *ngIf="node.data.name!=''" [ngClass]="{'LongNameDisplay': node.data.name.length>27 , 'ShorNameDisplay': node.data.name.length<=27}" style="padding-bottom: 4px;padding-top: 4px;" >

        <span class="folder-icon-css material-symbols-outlined text-blue-500 " *ngIf="node?.data?.nodeType==40000 
      || node?.data?.nodeType==10001
      || node?.data?.nodeType==10002
      || node?.data?.nodeType==10100
      ">
      <!-- <span *ngIf="node.data.name!=''" class="glyphicon glyphicon-record folder-open"></span>  -->
      <!-- <igx-avatar igxavatar="raised" #button initials="F" [igxToggleAction]="toggleRef"
      shape="circle" size="small" class="initials Folder-icon">
      </igx-avatar> -->
      <igx-icon style="font-size: 19px;color: blue !important;margin: 5px 0 0 0;" class="folder-icon-css material-symbols-outlined text-blue-500" aria-hidden="true">
        account_tree</igx-icon>
    </span>

        <span class="record-icon-css material-symbols-outlined text-purple-400" *ngIf="node?.data?.children?.length <= 0
      && node?.data?.canAddSection == false
      && node?.data?.levelIndex != 2
      && node?.data?.canAddDepartment==false 
      && node?.data?.nodeType !=40000
      ">
      <!-- <span *ngIf="node.data.name!=''" class="glyphicon glyphicon-record "></span>  -->
      <!-- <igx-avatar igxavatar="raised" #button initials="R" [igxToggleAction]="toggleRef"
                shape="circle" size="small" class="initials record-icon">
          </igx-avatar> -->
          <igx-icon style="font-size: 19px; color: green !important; margin: 5px 0 0 0;"  aria-hidden="true">
            receipt_long</igx-icon>
          
    </span>

        <span class="section-icon-css material-symbols-outlined text-yellow-500" *ngIf="node?.data?.nodeType >= 30000 && node?.data?.nodeType <= 30017">
          <!-- <span *ngIf="node.data.name!=''" class="glyphicon glyphicon-record th-list"></span> -->
          <!-- <igx-avatar igxavatar="raised" #button initials="S" [igxToggleAction]="toggleRef"
                shape="circle" size="small" class="initials Section-icon">
            </igx-avatar> -->
            <igx-icon style="font-size: 19px; color: orange !important;margin: 5px 0 0 0;"  aria-hidden="true">
              folder_open</igx-icon>
        </span>

        <span *ngIf="node?.data?.canDelete === true &&(node?.data?.nodeType==40000 
        || node?.data?.nodeType!==10001
        || node?.data?.nodeType==10002
        || node?.data?.nodeType==10100)
    && node.data?.nodeType!=30002 && node?.data?.nodeType < 30000" class="department-icon-css  material-symbols-outlined text-gray-400">
          <!-- <span *ngIf="node.data.name!=''" class="glyphicon glyphicon-record list-alt"
            style='font-size:14px;color: #f53027;'></span>  -->
            <!-- <igx-avatar igxavatar="raised" #button initials="D" [igxToggleAction]="toggleRef"
                shape="circle" size="small" class="initials department-icon">
            </igx-avatar> -->
            <igx-icon style="font-size: 19px; color: gray !important; margin: 5px 0 0 0;"  aria-hidden="true">
              corporate_fare</igx-icon>
            
          </span>

        <span id="{{node.data.nodeId}}-treemenu" style="line-height: 1.2 !important;padding: 0px 2px 1px 8px !important;" class="pl-2 pr-3 text-sm" *ngIf="node.data.name!=''">{{ node.data.name
          }}</span>
        </span>
    </ng-template>
  </tree-root>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>