import { Component, OnInit, Input, Output } from '@angular/core';
import { AuthService } from '../../core';
import { MenuService } from '../../service/menu.service';
import { Menu } from '../../model/menu'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() getSelectedNodeIdDetail: any;
  accessToken = '';
  refreshToken = '';
  islogin: boolean;
  currentuserName = '';
  SelectedTreenodeEvent = '';
  getSelectedNodeDetailsFromService: any;
  constructor(public authService: AuthService, public menuServices: MenuService, private SpinnerService: NgxSpinnerService) {
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refresh_token');

  }

  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refresh_token');
    let getCurrentuserName = localStorage.getItem('CurrentUserName');

    if (getCurrentuserName != null || getCurrentuserName != '') {
      this.islogin = true;
      this.currentuserName = getCurrentuserName;
    } else {
      this.islogin = false;
      this.currentuserName = '';
    }

  }

  async ngOnChanges() {
    this.getSelectedNodeDetailsFromService = '';
    this.SelectedTreenodeEvent = '';
    this.SelectedTreenodeEvent = this.getSelectedNodeIdDetail;
    if (this.SelectedTreenodeEvent != null || this.SelectedTreenodeEvent != '') {
      if (this.SelectedTreenodeEvent != undefined) {
        this.getSelectedNodeDetailsFromService = this.SelectedTreenodeEvent;
        //   this.menuServices.getSelectednodeDetails(this.SelectedTreenodeEvent).subscribe((response:Menu[])=>{
        //     let results = response;
        //     this.getSelectedNodeDetailsFromService = results;
        // })
      }
    }
  }

  logout() {
    this.authService.logout();
    this.islogin = false;
    let APIURL = localStorage.getItem('APIURL');
    localStorage.setItem('userdata', null);
    localStorage.setItem('CurrentUserName', '');
    localStorage.clear();
    localStorage.setItem('APIURL', APIURL);
  }

  BudgetRecordRecalcu() {
    let ForecastNodeId = localStorage.getItem("ForecastId");
    if (ForecastNodeId != null) {
      this.SpinnerService.show();
      this.menuServices.BudgetRecordRecalu(ForecastNodeId).subscribe
        ((response: Menu[]) => {
          this.SpinnerService.hide();
          window.location.reload();
        })
    }
  }

  SystemRecordRecalcu() {
    let ForecastNodeId = localStorage.getItem("ForecastId");
    if (ForecastNodeId != null) {
      this.SpinnerService.show();
      this.menuServices.SystemRecordRecalu(ForecastNodeId).subscribe
        ((response: Menu[]) => {
          this.SpinnerService.hide();
          window.location.reload();
        })
    }
  }

  async AddDepartment(NodeId) {
    let DepartmentAddDetails = {
      "SelectedNodeID": NodeId,
      "NewDepartmentName": "NewSalesDepartment",
      "showGrossProfitPercent": true
    }
    await this.menuServices.SaveAddDepartment(DepartmentAddDetails).subscribe
      ((response: Menu[]) => {
        window.location.reload();
      })
  }

  /**
   * 
   * @param NodeId 
   */
  async DeleteDepartment(NodeId) {
    // console.log(NodeId);
    if (NodeId != null) {
      await this.menuServices.deleteDepartment(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }



}
