declare let window: any;

export class DynamicEnvironment {

    public get url() {
        let url = localStorage.getItem('APIURL');
        return url;
    }
    public get apiUrl() {
        let url: any = localStorage.getItem('APIURL');
        return url;
    }
}