import { Component, OnInit } from '@angular/core';
import { Input } from 'hammerjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  title = ''; 
  SettingMenuHeader = '';

  constructor(private _router: Router) { 
    let settingMenuname = localStorage.getItem('SettingMenuActive');
    if(settingMenuname!=null){
      this.SettingMenuHeader = settingMenuname;
    }   
    
   }

  ngOnInit() {
    let settingMenuname = localStorage.getItem('SettingMenuActive');
    if(settingMenuname!=null){
      this.SettingMenuHeader = settingMenuname;
    }else {
      this._router.navigateByUrl('/forecast');
    }  
  }

  onSettingMenuAdded(eventData: { title: string }) {
    this.title = eventData.title;
    this.SettingMenuHeader = this.title;
  }

}
