import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gross-receipts-grouped',
  templateUrl: './gross-receipts-grouped.component.html',
  styleUrls: ['./gross-receipts-grouped.component.scss']
})
export class GrossReceiptsGroupedComponent implements OnInit {

  @Input() grossreceiptsgroupedchangemethod: Event;

  constructor() {
    // console.log(this.grossreceiptsgroupedchangemethod);
  }

  ngOnInit() {
    // console.log(this.grossreceiptsgroupedchangemethod);
  }

}
