import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, EMPTY } from 'rxjs';
import { map, tap, delay, finalize } from 'rxjs/operators';
import { ApplicationUser } from '../models/application-user';
import { environment } from 'src/environments/environment';

interface LoginResult {
  userName: string;
  userID: string;
  role: string;
  originalUserName: string;
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  private apiUrl: string;
  private timer: Subscription;
  private _user = new BehaviorSubject<ApplicationUser>(null);
  user$: Observable<ApplicationUser> = this._user.asObservable();
  userID: string

  private storageEventListener(event: StorageEvent) {
    this.apiUrl = `${environment.apiUrl}/api/account`;
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this.stopTokenTimer();
        this._user.next(null);
      }
      if (event.key === 'login-event') {
        this.stopTokenTimer();
        this.http.get<LoginResult>(`${this.apiUrl}/user`).subscribe((x) => {
          this._user.next({
            userName: x.userName,
            userID: x.userID,
            role: x.role,
            originalUserName: x.originalUserName,
          });
        });
      }
    }
  }

  constructor(private router: Router, private http: HttpClient) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  login(username: string, password: string) {
    this.apiUrl = `${environment.apiUrl}/api/account`;
    return this.http
      .post<LoginResult>(`${this.apiUrl}/login`, { username, password })
      .pipe(
        map((x) => {
          // console.log("x", x);
          localStorage.setItem('CurrentUserName', x.userName);
          this._user.next({
            userName: x.userName,
            userID: x.userID,
            role: x.role,
            originalUserName: x.originalUserName,
          });
          const token = x.accessToken;
          const RefreshToken = x.refreshToken;
          const userID = x.userID;
          localStorage.setItem("jwt", token);
          localStorage.setItem("refresh_token", RefreshToken);
          localStorage.setItem("CurrentUserID", userID);
          this.setLocalStorage(x);
          this.startTokenTimer();
          return x;
        })
      );
  }

  logout() {
    this.apiUrl = `${environment.apiUrl}/api/account`;
    this.http
      .post<unknown>(`${this.apiUrl}/logout`, {})
      .pipe(
        finalize(() => {
          this.clearLocalStorage();
          this._user.next(null);
          this.stopTokenTimer();
          this.router.navigate(['login']);
        })
      )
      .subscribe();

  }

  isTokenExpired(token) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  }

  refreshToken(credentials) {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    this.apiUrl = `${environment.apiUrl}/api/account`;
    if (credentials.accessToken == undefined || credentials.refreshToken == undefined) {
      this.clearLocalStorage();
      return of();
      //return new Observable<LoginResult>();
    }
    return this.http
      .post<LoginResult>(`${this.apiUrl}/refresh-token`, credentials, { headers: reqHeaders })
      .pipe(
        map((x) => {
          this._user.next({
            userName: x.userName,
            userID: x.userID,
            role: x.role,
            originalUserName: x.originalUserName,
          });
          const token = x.accessToken;
          const RefreshToken = x.refreshToken;
          localStorage.setItem("jwt", token);
          localStorage.setItem("refresh_token", RefreshToken);
          this.setLocalStorage(x);
          this.startTokenTimer();
          return x;
        })
      );
  }

  setLocalStorage(x: LoginResult) {
    localStorage.setItem('access_token', x.accessToken);
    localStorage.setItem('refresh_token', x.refreshToken);
    localStorage.setItem('CurrentUserName', x.userName);
    localStorage.setItem('CurrentUserID', x.userID);
    localStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('CurrentUserName');
    localStorage.removeItem('CurrentUserID');
    localStorage.removeItem('systemCalculation');
    localStorage.removeItem('consolidaitonCalculation');
    localStorage.removeItem("jwt");
    localStorage.setItem('logout-event', 'logout' + Math.random());

  }

  logoutUnAutherised() {
    this.clearLocalStorage();
    let APIURL = localStorage.getItem('APIURL');
    localStorage.setItem('userdata', null);
    localStorage.setItem("clientID", '');
    localStorage.setItem('CurrentUserName', '');
    localStorage.clear();
    localStorage.setItem('APIURL', APIURL);
    localStorage.setItem('clickedPoPupEvent', null);
    localStorage.setItem("ForecastClientCountryCollection", null);
    localStorage.removeItem("treeview");
  }

  private getTokenRemainingTime() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return 0;
    }
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires.getTime() - Date.now();
  }

  private startTokenTimer() {
    const timeout = this.getTokenRemainingTime();
    console.log("timeout " + new Date(Date.now() + timeout));
    this.timer = of(true)
      .pipe(
        delay(timeout),
        tap(() => {
          console.log("refresh called");
          const token = localStorage.getItem("jwt");
          const refreshToken: string = localStorage.getItem("refreshToken");
          const credentials = { accessToken: token, refreshToken: refreshToken };
          this.refreshToken(credentials).subscribe(res => { }, error => {
            let errorMsg = error;
          });
        })
      )
      .subscribe();
  }

  private stopTokenTimer() {
    this.timer?.unsubscribe();
  }
}
