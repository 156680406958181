import { ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
export class RegisterValidators {

    static match(controlName: string, matchingControlName: string): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const control = group.get(controlName)
            const matchingControl = group.get(matchingControlName)

            if (!control || !matchingControl) {
                console.log("Form Controls cannot be found in the form group.");
                return { controlNotFound: false }
            }

            const error = control.value === matchingControl.value ? null : { noMatch: true }

            matchingControl.setErrors(error)
            return error;
        }

    }
    static regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            return regex.test(control.value) ? null : error;
        };
    }
}

