export class Userregistrationmodel {
    FirstName: String
    LastName: String
    EmailID: String
    PhoneNumber: String
    CompanyName: String
    userName: String
    Password: String
    Reseller: String
    Country: String
    UserType:number
    ParentUser:number

    constructor() {
        this.FirstName = null;
        this.LastName = null;
        this.EmailID = null;
        this.PhoneNumber = null;
        this.CompanyName = null;
        this.userName = null;
        this.Password = null;
        this.Reseller = null;
        this.Country = null;
        this.UserType = null;
        this.ParentUser = null;
    }

}
