import { Component, OnInit, Input } from '@angular/core';
import { EmployeeGroupService } from 'src/app/service/EmployeeGroupService/employee-group.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MethodserviceService } from '../record/method/service/methodservice.service';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import { timeStamp } from 'console';
@Component({
  selector: 'app-employee-group',
  templateUrl: './employee-group.component.html',
  styleUrls: ['./employee-group.component.scss']
})
export class EmployeeGroupComponent implements OnInit {

  @Input() EmployeeDetails;
  EmployeeCollection: any = []
  EmployeeCollectionDetails: any = []
  Activetabmenu: any = 0;
  EmployeeCycleTotalindex: any = 0;
  GroupPayStartIndex: any = 0;
  GroupPayEndIndex: any = 11;
  ActiveEmployeeGroup: any = 0;
  employeeId: any = '';
  NIDisplay: boolean;
  recordmodifiedModel: recordSaveModel;
  constructor(private EmployeeGroupService: EmployeeGroupService,
    private methodserviceService:MethodserviceService,
    private SpinnerService: NgxSpinnerService) { }

  SavingEmployeeJsonArray = {
    NodeId: null,
    ValueNodeID: null,
    SelectableIndex: null,
    EmployeeDetailsArray: []
  }

  SavingAllModelValue = []

  ngOnInit() {
    if (this.EmployeeDetails[0].nodeId != '') {
      this.SavingEmployeeJsonArray = {
        NodeId: parseInt(this.EmployeeDetails[0].nodeId),
        ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
        SelectableIndex: null,
        EmployeeDetailsArray: []
      }
      this.EmployeeCollection = [];
      this.EmployeeCollectionDetails = [];
      this.SavingAllModelValue = [];
      let totaldisplayvalue = 0;
      this.SpinnerService.show();
      this.EmployeeGroupService.getEmployeeCollection(parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
        (response => {
          this.SpinnerService.hide();
          this.EmployeeCollection = response;
          if(this.EmployeeCollection!=null && this.EmployeeCollection.employees!=null && this.EmployeeCollection.employees.length>0){ 
            let collections = this.EmployeeCollection.employees[this.EmployeeCollection.employees[0].selectedIndex];
            this.ActiveEmployeeGroup = this.EmployeeCollection.employees[0].selectedIndex;
            let CountryName = localStorage.getItem("CountryName");
            // console.log(CountryName);
            if (CountryName == "GB") {
              this.NIDisplay = true;
            } else {
              this.NIDisplay = false;
            }
            collections.displayedValueLists.forEach(valuelistCollection => {
              valuelistCollection.results.forEach(valuesarraylist => { 
                if (Math.sign(valuesarraylist.value) == -1) {
                  let absValue = Math.abs(valuesarraylist.value);
                  let realvalue = "(" + absValue + ")";
                  valuesarraylist.value = realvalue;
                } else {
                  valuesarraylist.value = valuesarraylist.value;
                }
              });
            });
            this.EmployeeCollectionDetails = collections;
          }
          
        })
      )
    }
  }
  /**
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.EmployeeCycleTotalindex = parseInt(index);
    this.GroupPayStartIndex = index * 12;
    this.GroupPayEndIndex = this.GroupPayStartIndex + 11;
    let totaldisplayvalue = 0;
    this.Activetabmenu = parseInt(index);
    this.SpinnerService.show();
    this.EmployeeGroupService.getGrossWageRate(this.EmployeeCollectionDetails.nodeId, parseInt(this.Activetabmenu)).subscribe(
      (response => {
        try {
          let results = response;
          this.EmployeeCollectionDetails.grossWagesRate = parseFloat(results.toString()).toFixed(2);
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  /**
   * 
   * @param index 
   */
  changeEmployeeGroup(index) {
    this.ActiveEmployeeGroup = parseInt(index);
    this.SpinnerService.show();
    this.SavingEmployeeJsonArray = {
      NodeId: null,
      ValueNodeID: null,
      SelectableIndex: null,
      EmployeeDetailsArray: []
    }
    this.EmployeeCollectionDetails = this.EmployeeCollection.employees[this.ActiveEmployeeGroup];
    this.EmployeeGroupService.UpdateEmployeeCollection(this.EmployeeDetails[0].nodeId, parseInt(this.ActiveEmployeeGroup)).subscribe
    (response=>{ 
      try {
        this.SpinnerService.hide();
      } catch (error) {
        this.SpinnerService.hide();
      }
      return true;
    })
  }

  /**
   * 
   * @param selectedNodeId 
   */
  addEmployeeGroupCollection(selectedNodeId) {
    this.SpinnerService.show();
    this.EmployeeCollectionDetails = [];
    this.EmployeeCollection = [];
    this.EmployeeGroupService.addEmployeeCollection(parseInt(selectedNodeId), parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
      (result => {
        this.EmployeeGroupService.getEmployeeCollection(parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
          (response => { 
           
            this.EmployeeCollection = response;
            if(this.EmployeeCollection!=null && this.EmployeeCollection.employees!=null && this.EmployeeCollection.employees.length == 1 
              && result > 0){
              result = result -1;
            } 
            let index = parseInt(result);
            this.EmployeeCollectionDetails = this.EmployeeCollection.employees[index];
            this.ActiveEmployeeGroup = index;
            this.SpinnerService.hide();
           
          })
        )
      })
    )
  }

  deleteEmployeeGroupCollection(selectedNodeId) {
    this.SpinnerService.show();
    this.EmployeeGroupService.deleteEmployeeCollection(parseInt(selectedNodeId), parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
      (result => { 
         try {
          this.EmployeeGroupService.getEmployeeCollection(parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
            (response => { 
              try {
                this.SpinnerService.hide();
                console.log(response , result);
                this.EmployeeCollection = response;
                if (result > 0)
                  {
                      if(this.EmployeeCollection!=null && this.EmployeeCollection.length > 1)
                      {
                        result = 0;
                      }
                }
                if(this.EmployeeCollection!=null && this.EmployeeCollection.employees!=null && this.EmployeeCollection.employees.length>0 && result!=null) {
                  let index = parseInt(result);
                  this.ActiveEmployeeGroup = index; 
                  this.EmployeeCollectionDetails = this.EmployeeCollection.employees[this.ActiveEmployeeGroup];
                } else {
                  this.EmployeeCollectionDetails = [];
                }   
               
              } catch (error) {
                
              }
             
            })
          )
         } catch (error) {
          
         }
        

      })
    )
  }

  EmployeeNameChange($event, index) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          Name: $event.target.value,
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "Name"
        }
      ]
    }

    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }

  AllowanceChange($event, index) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          Allowance: $event.target.value,
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "Allowance"

        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }
  NumberOfEmployeesChange($event, index) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          NumberOfEmployees: $event.target.value,
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "NumberOfEmployees"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }
  HoursPerWeekChange($event, index) {
    let employeedata = this.EmployeeCollection.employees[parseInt(index)];
    // console.log(employeedata);
    if (employeedata.hoursPerWeek != parseInt($event.target.value)) {
      this.SavingEmployeeJsonArray = {
        NodeId: parseInt(this.EmployeeDetails[0].nodeId),
        ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
        SelectableIndex: parseInt(index),
        EmployeeDetailsArray: [
          {
            HoursPerWeek: $event.target.value,
            ToPeriodIndex: null,
            FromPeriodIndex: null,
            EditedValue: "HoursPerWeek"
          }
        ]
      }
      this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
    }

  }

  changefrequncie($event, index, Frequency) {
    let employeedata = this.EmployeeCollection.employees[parseInt(index)];
    // console.log(employeedata);
    if (employeedata.hoursPerWeek != parseInt($event.target.value)) {
      this.SavingEmployeeJsonArray = {
        NodeId: parseInt(this.EmployeeDetails[0].nodeId),
        ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
        SelectableIndex: parseInt(index),
        EmployeeDetailsArray: [
          {
            GrossWagesRateFrequency: Frequency.name,
            ToPeriodIndex: null,
            FromPeriodIndex: null,
            EditedValue: "GrossWagesRateFrequency"
          }
        ]
      }
      this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
    }

  }

  Bonusescomision(Bonusescomisionform, index) {
    Bonusescomisionform.open();
    this.employeeId = parseInt(index);
  }

  EmployeeFromPeriod($event, index, value) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          FromPeriodIndex: parseInt(value),
          ToPeriodIndex: null,
          EditedValue: "FromPeriodIndex"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }
  EmployeeToPeriod($event, index, value) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          ToPeriodIndex: parseInt(value),
          FromPeriodIndex: null,
          EditedValue: "ToPeriodIndex"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }
  EmployeePensionSchema($event, index, value) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          SelectedPenisonSchemaIndex: parseInt($event.target.value),
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "SelectedPenisonSchemaIndex"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);

  }
  EmployeeNIScheme($event, index, value) {
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          SelectedNISchemeIndex: parseInt($event.target.value),
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "SelectedNISchemeIndex"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
  }

  saveEmployeeValue(index){ 
    let ArrayEmployeeGroup = [];
    if(this.SavingAllModelValue.length>0){ 
      this.SavingAllModelValue.forEach(element => {
        ArrayEmployeeGroup.push(element);
      });
      let jsonArray = {
        NodeId: parseInt(this.EmployeeDetails[0].nodeId),
        ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
        SelectableIndex: parseInt(index),
        EmployeeDetailsArray: ArrayEmployeeGroup
      }
      this.SpinnerService.show();
      this.EmployeeGroupService.saveEmployeeGroupCollection(jsonArray).subscribe(
        ((responses: any) => {
          let totaldisplayvalue = 0;
          this.SavingAllModelValue = [];
          this.SpinnerService.show();
          this.EmployeeGroupService.getEmployeeCollection(parseInt(this.EmployeeDetails[0].nodeId)).subscribe(
            (response => { 
              this.SavingEmployeeJsonArray = {
                NodeId: null,
                ValueNodeID: null,
                SelectableIndex: null,
                EmployeeDetailsArray: []
              }
              this.EmployeeCollection = [];
              this.EmployeeCollectionDetails = [];
              this.SpinnerService.hide();
              this.EmployeeCollection = response;
              if(this.EmployeeCollection!=null && this.EmployeeCollection.employees!=null && this.EmployeeCollection.employees.length>0){
                let collections = this.EmployeeCollection.employees[this.EmployeeCollection.employees[0].selectedIndex];
                this.ActiveEmployeeGroup = this.EmployeeCollection.employees[0].selectedIndex;
                let CountryName = localStorage.getItem("CountryName");
                if (CountryName == "GB") {
                  this.NIDisplay = true;
                } else {
                  this.NIDisplay = false;
                }
                collections.displayedValueLists.forEach(valuelistCollection => {
                  valuelistCollection.results.forEach(valuesarraylist => {
                    if (Math.sign(valuesarraylist.value) == -1) {
                      let absValue = Math.abs(valuesarraylist.value);
                      let realvalue = "(" + absValue + ")";
                      valuesarraylist.value = realvalue;
                    } else {
                      valuesarraylist.value = valuesarraylist.value;
                    }
                  });
                });
                this.EmployeeCollectionDetails = collections;
              }
             
            })
          )
  
        })
      )
    }
    
  }
  saveEmployeeGroup(JsonArray, index) {
    let ActiveArray = false;
    if(this.SavingAllModelValue.length>0){ 
      this.SavingAllModelValue.forEach(element => {
        if(element.EditedValue == JsonArray.EmployeeDetailsArray[0].EditedValue){
          element = JsonArray.EmployeeDetailsArray[0];
          ActiveArray = true;
        } 
      });
      if(!ActiveArray){
        this.SavingAllModelValue.push(JsonArray.EmployeeDetailsArray[0]);
      }
    } else {
      this.SavingAllModelValue.push(JsonArray.EmployeeDetailsArray[0]);
    }
    localStorage.setItem("savingEMployeeGroup", JSON.stringify(JsonArray));
    console.log(JSON.parse(localStorage.getItem("savingEMployeeGroup")));
  }


  checkGrosswageRate($event, results, index) {
    let Value = $event.target.value;
    this.SavingEmployeeJsonArray = {
      NodeId: parseInt(this.EmployeeDetails[0].nodeId),
      ValueNodeID: parseInt(this.EmployeeDetails[0].nodeId),
      SelectableIndex: parseInt(index),
      EmployeeDetailsArray: [
        {
          GrossWagerate: parseFloat($event.target.value).toFixed(2),
          ToPeriodIndex: null,
          FromPeriodIndex: null,
          EditedValue: "GrossWagerate"
        }
      ]
    }
    this.saveEmployeeGroup(this.SavingEmployeeJsonArray, index);
    // this.SpinnerService.show();
    // this.EmployeeGroupService.saveGrossWageRate(results.nodeId, parseInt(this.Activetabmenu), Value).subscribe(
    //   (response => {
    //     try {
    //       this.ngOnInit();
    //       this.SpinnerService.hide();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
  }

  recordModified(record: recordSaveModel) {
    this.recordmodifiedModel = record;
    console.log(this.recordmodifiedModel);
  }

  RemoveDuplicationArray(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

  saveFromEmployeeGrop(){ 
  let ArrayEmployeeGroup = [];
  let data = localStorage.getItem("savingEMployeeGroup");
  if(data!=null && data!=undefined && data!=""){
    this.EmployeeGroupService.saveEmployeeGroupCollection(data).subscribe(
      ((responses: any) => {
        localStorage.setItem("savingEMployeeGroup", "");
      })
    )
   }
  }

  SaveData(Bonusescomisionform) { 
    console.log(Bonusescomisionform);
    
    let savemodel = null;
     let commonbonus = localStorage.getItem("setComomBonus");
     if(commonbonus!=undefined && commonbonus!=""){
       savemodel = JSON.parse(commonbonus);
     }

    if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
      let recordModified: any = this.recordmodifiedModel
      if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
        if(recordModified.budgetData.length>0){
          recordModified.budgetData.forEach(element => {
            element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
          });
        }
       
        this.recordmodifiedModel = recordModified;
      }
      this.SpinnerService.show();
      if(savemodel!="" && savemodel!=null && savemodel!=undefined ) {
        this.recordmodifiedModel.budgetData.push(savemodel.setCommonBonusmodel.budgetData);
      }
      if(this.recordmodifiedModel.recordNodeID == null){
        
        this.recordmodifiedModel = new recordSaveModel();
        this.SpinnerService.hide();
        localStorage.setItem("setComomBonus", "");
        this.EmployeeDetails[0].nodeId = this.EmployeeDetails[0].nodeId;
        Bonusescomisionform.close();
        this.ngOnInit();
        return;
      }
      if(this.recordmodifiedModel.budgetData.length>0){
        this.recordmodifiedModel.budgetData.forEach((element:any, index) => {
          if(element.length!=undefined && element.length>0) {
            this.recordmodifiedModel.budgetData[index] = null;
            this.recordmodifiedModel.budgetData[index] = element[0];
          }
        });
      }
      this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
        (response: any) => {
          this.recordmodifiedModel = new recordSaveModel();
          localStorage.setItem("setComomBonus", "");
          this.SpinnerService.hide();
          this.EmployeeDetails[0].nodeId = this.EmployeeDetails[0].nodeId;
          Bonusescomisionform.close();
          this.ngOnInit();
        })
    }
    if(this.recordmodifiedModel == undefined && this.recordmodifiedModel == null && savemodel!="" && savemodel!=null && savemodel!=undefined){
      this.recordmodifiedModel =  savemodel.setCommonBonusmodel;
      let recordModified: any = this.recordmodifiedModel
      if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
        recordModified.budgetData.forEach(element => {
          element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
        });
        this.recordmodifiedModel = recordModified;
      }
      this.SpinnerService.show();
      if(this.recordmodifiedModel.recordNodeID == null){
        this.recordmodifiedModel = new recordSaveModel();
        this.SpinnerService.hide();
        localStorage.setItem("setComomBonus", "");
        this.EmployeeDetails[0].nodeId = this.EmployeeDetails[0].nodeId;
        Bonusescomisionform.close();
        this.ngOnInit();
        return;
      }

      if(this.recordmodifiedModel.budgetData.length>0){
        this.recordmodifiedModel.budgetData.forEach((element:any, index) => {
          if(element.length!=undefined && element.length>0) {
            this.recordmodifiedModel.budgetData[index] = null;
            this.recordmodifiedModel.budgetData[index] = element[0];
          }
        });
      }
      this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
        (response: any) => {
          this.recordmodifiedModel = new recordSaveModel();
          localStorage.setItem("setComomBonus", "");
          this.SpinnerService.hide();
          this.EmployeeDetails[0].nodeId = this.EmployeeDetails[0].nodeId;
          Bonusescomisionform.close();
          this.ngOnInit();
        })
   }
   Bonusescomisionform.close();
   this.ngOnInit();
    // if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
    //   let recordModified: any = this.recordmodifiedModel
    //   if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
    //     recordModified.budgetData.forEach(element => {
    //       element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
    //     });
    //     this.recordmodifiedModel = recordModified;
    //   }

    //   this.SpinnerService.show();
    //   this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
    //     (response: any) => {
    //       this.SpinnerService.hide();
    //       localStorage.setItem("savingEMployeeGroup", "");
    //     })
    // }

  }


}
