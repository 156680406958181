<div class="col-sm-12" *ngIf="displaycollection" style="height: 25px;margin: 5px 0 2px 1px;">
    <div class="accumulatedDepreciationOpeningEntryModels col-sm-4">
        <label class="col-sm-8"
            style="padding: 0px; font-size: 12px;">Depreciation Opening:</label>
        <input type="text" class="form-control col-sm-4" style="text-align: right; height: 25px;" (keypress)="isNumber($event)"
            (keyup)="ValidateValue($event)"
            (blur)="accumulatedDepreciationOpeningEntrychange($event,recordCollectionList.accumulatedDepreciationOpeningEntryCollection.nodeId ,recordCollectionList.accumulatedDepreciationOpeningEntryCollection.value)"
            value="{{recordCollectionList.accumulatedDepreciationOpeningEntryCollection.value}}" />
    </div>
    <div class="fixedAssetOpeningEntryCollection col-sm-4">
        <label class="col-sm-7" style="padding: 0px; font-size: 12px;">Asset Opening Cost : </label>
        <input type="text" class="form-control col-sm-5" (keypress)="isNumber($event)" style="text-align: right; height: 25px;"
            (keyup)="ValidateValue($event)"
            (blur)="fixedAssetOpeningEntryCollectionchange($event, recordCollectionList.fixedAssetOpeningEntryCollection.nodeId,recordCollectionList.fixedAssetOpeningEntryCollection.value)"
            value="{{recordCollectionList.fixedAssetOpeningEntryCollection.value}}" />
    </div>
    <div class="depreciationdestination col-sm-4">
        <label class="col-sm-6" style="padding: 0px; font-size: 12px;">Depreciation Destination : </label>
        <select name="depreciationdestination" style="height: 25px;"
            (change)="depreciationDestinationChange(recordCollectionList.destinationDepreciationModels,$event)"
            class="col-sm-6 form-control">
            <option *ngFor="let result of recordCollectionList.destinationDepreciationModels; let i=index;"
                [selected]="result.selectedindex == result.nodeId" value="{{i}}">{{result.name}}</option>
        </select>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>