<div>
    <div class="forecastSelection">
        <div class="ForecastList">
            <div>Selected Forecasts in Consolidate: </div>
            <igx-checkbox #checkbox *ngFor="let forecast of forecastlist" [igxTooltipTarget]="tooltipRef"
                [checked]="forecast.ConsolSelected" (onTooltipShow)="tooltipRef.context=forecast">
                <label (click)="SelectedForecast(forecast.nodeId)">{{ forecast.name }}</label>
            </igx-checkbox>
        </div>

        <div class="col-sm-5 forecastDetails">
            <div class="new-forecast" *ngIf="details!=''">
                <div class="company_name form-group row">
                    <label class="col-sm-4 col-form-label">Company Name</label>
                    <input type="text" class="form-control col-sm-6" value="{{details.companyName}}"
                        style="color: black;" name="company_name" id="companyName" [disabled]="true" />
                </div>
                <div class="Subheading_name form-group row">
                    <label class="col-sm-4 col-form-label">SubHeading</label>
                    <input type="text" class="form-control col-sm-6" id="subHeading" value="{{details.subHeading}}"
                        name="Subheading_name" style="color: black;" [disabled]="true" />
                </div>
                <div class="Description form-group row">
                    <label class="col-sm-4 col-form-label">Description</label>
                    <textarea type="text" class="form-control Description col-sm-6" id="description"
                        value="{{details.description}}" name="Description" style="color: black;"
                        [disabled]="true"></textarea>
                </div>
                <div class="start-date form-group row">
                    <label class="col-sm-4 col-form-label">Number of Years</label>
                    <input type="number" value="1" class="form-control col-sm-4" id="numberOfYears"
                        value="{{details.numberOfYears}}" name="numberofyears" style="color: black;"
                        [disabled]="true" />
                </div>
                <div class="start-date form-group row">
                    <label class="col-sm-4 col-form-label">Start Date</label>
                    <div class="form-group">
                        <div class="input-group" style="width: 87%;">
                            <input type="text" placeholder="Enter StartDate" name="startdate"
                                value="{{details.startdate| date:'MMM yy'}}" [disabled]="true" class="form-control"
                                style="margin-left: 3px;" />
                        </div>
                    </div>
                </div>
                <div class="Firstdata_period form-group row">
                    <label class="col-sm-4 col-form-label">First data Period</label>
                    <div class="form-group">
                        <div class="input-group" style="width: 87%;">
                            <input type="text" value="{{months[0]}}" [disabled]="true" class="form-control"
                                style="margin-left: 3px;" />
                        </div>
                    </div>
                </div>
                <!-- <div class="LocalCurrency form-group row">
                                    <label class="col-sm-4 col-form-label">Local Currency</label>
                                    <select name="LocalCurrency" formControlName="LocalCurrency" class="form-control col-sm-4" style="color: black;">
                                        <option *ngFor="Currencylist of currency ; let LocalCurrencyIndex = index" [selected]="LocalCurrencyIndex==0" value="{{LocalCurrencyIndex}}"  >{{currency[LocalCurrencyIndex].name}}</option>
                                    </select>
                                </div> -->
                <!-- <div class="PeriodFrequency form-group row">
                    <label class="col-sm-4 col-form-label">First Daily CashFlow Period</label>
                    <select name="FirstDailyCashFlowPeriod" class="form-control col-sm-4" style="color: black;"
                        id="FirstDailyCashFlowPeriod">
                        <option *ngFor="let data of months;  let FirstDailyCashFlowPeriodIndex = index"
                            [selected]="FirstDailyCashFlowPeriodIndex==0" [disabled]="true"
                            value="{{FirstDailyCashFlowPeriodIndex}}">
                            {{data.name}}</option>
                    </select>
                </div> -->

            </div>
            <div #outlet="overlay-outlet" igxOverlayOutlet>
                <div #tooltipRef="tooltip" igxTooltip>
                    <ng-container *ngIf="tooltipRef.context">
                        <div>Forecast Type: {{tooltipRef.context.type}}</div>
                        <div>Start Date: {{tooltipRef.context.startdate| date:'MMM yy'}}
                        </div>
                        <div>Currency: {{tooltipRef.context.currency}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>