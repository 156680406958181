import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class OTRService {

  constructor(private http: HttpClient) { }
  getValidCyclesCollection(NodeId): Observable<any[]> {
    return this.http.get(environment.url + "/api/OTR/ValidCycles/" + NodeId)
      .pipe(map(
        (response: any[]) => {
          return response;
        }
      ));
  }
  CallExportExcel(saveOTRValuelist): Observable<any[]> {
    //let reqHeaders = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    let url = environment.url + "/api/OTR/ExportToExcelOTR";
    return this.http.post(url, saveOTRValuelist, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
    // return this.http.get(environment.url + "/api/OTR/ExportToExcelOTR/" + NodeId + "/"+CurrentUserID+"/"+ClientId)
    //   .pipe(map(
    //       (response: any[]) => {
    //           return response;
    //       }
    //   ));
  }
}
