<div *ngIf="event!=''">
    <form  class="detailsRecord">
        <div class="row" style="height: auto;">
          <div class="col-sm-3" style="margin-top: 1%; height: 150px;">
            <div class="form-group row" >
              <label for="name" class="col-sm-3 col-form-label">
                Name
              </label>
              <div class="col-sm-9">
                <input id="name" class="form-control" style="margin: 0 0 0 7px;" (blur)="clickgetdata(event.nodeId , $event )" type="text"
                  value="{{event.name}}" >
              </div>
            </div>
            <div class="form-group row" style="margin-top: 1%;">
              <label for="name" class="col-sm-4 col-form-label">
                Description
              </label>
              <div class="col-sm-9" style="margin: -27px 3px 3px 84px;">
                <textarea id="Description" class="form-control" type="text" (blur)="DescriptionBulrEvent(event.nodeId , $event )" value=" {{ event.description}}"
                 ></textarea>
              </div>
            </div>
          </div>
        <div class="col-sm-2" style="margin-top: 1%;" *ngIf="event.isEnabledWhatIfMultiplier">
        <div class="form-group row" *ngIf="event.isEnabledWhatIfMultiplier">
            <label for="name" class="col-sm-6 col-form-label">
            What If Multiplier
            </label>
            <div class="col-sm-6" *ngIf="event.isEnabledWhatIfMultiplier">
            <input  type="text" class="form-control" (blur)="UpdateWhatIfMultiplier(event.nodeId , $event )" value="{{event.whatIfMultiplier}}" >
            </div>
        </div>
        <div class="form-group row" *ngIf="event.isEnabledWhatIfMultiplier">
            <label for="name" class="col-sm-6 col-form-label">
            Total WI Multiplier
            </label>
            <div class="col-sm-6" *ngIf="event.isEnabledWhatIfMultiplier">
            <input  type="text" class="form-control" disabled="disabled" value="{{event.totalWhatIfMultiplier}}" >
            </div>
        </div>
        </div>
    </div>
    </form>
</div>