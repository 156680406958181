<div class="col-sm-12" *ngIf="displaycollection" style="height: 25px;margin: 5px 0 2px 1px;">
    <div class="depreciationdestination col-sm-4">
        <select name="depreciationdestination" style="height: 25px;"
            (change)="accrualPrepaymentOptionModelChange(recordCollectionList.accrualPrepaymentOptionModel,$event)"
            class="col-sm-9 form-control">
            <option *ngFor="let result of recordCollectionList.accrualPrepaymentOptionModel; let i=index;"
                [selected]="recordCollectionList.selectedIndex==i" value="{{i}}">{{result.name}}</option>
        </select>
    </div>
    <div class="accumulatedDepreciationOpeningEntryModels col-sm-5">
        <label class="col-sm-6"
            style="padding: 0px; font-size: 12px;">{{recordCollectionList.tradeDebitorsOpeningEntryModels.name}}</label>
        <input type="text" class="form-control col-sm-5" style="text-align: right; height: 25px;"
            (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
            (blur)="tradeDebitorsOpeningEntryModelschange($event,recordCollectionList.tradeDebitorsOpeningEntryModels.nodeId ,recordCollectionList.tradeDebitorsOpeningEntryModels.value)"
            value="{{recordCollectionList.tradeDebitorsOpeningEntryModels.value}}" />
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>