import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { ContractRetentionsService } from '../../../service/SettingServices/ContractRetentions/contract-retentions.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contract-retentions',
  templateUrl: './contract-retentions.component.html',
  styleUrls: ['./contract-retentions.component.scss']
})
export class ContractRetentionsComponent implements OnInit {


  ContractRetensionCollection: any = [];
  ContractRetensionActiveRecord = 0;
  ContractRetensionBandingActiveRecord = 0;
  ContractRetesnionBindingArray: any = [];
  ForecastNodeId: any = null;
  constructor(private ContractRetentionsServices: ContractRetentionsService, private router: Router) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');
  }

  ngOnInit() {
    this.ContractRetentionsServices.getContractRetentionCollections(this.ForecastNodeId).subscribe(
      (response => {
        this.ContractRetensionCollection = response;
        this.ContractRetesnionBindingArray = this.ContractRetensionCollection[this.ContractRetensionActiveRecord];
      })
    )
  }

  changeValueList(Index) {
    this.ContractRetensionActiveRecord = parseInt(Index);
    this.ContractRetensionBandingActiveRecord = 0;
    this.ContractRetesnionBindingArray = this.ContractRetensionCollection[this.ContractRetensionActiveRecord];
  }

  ChangeBandingList(Index) {
    this.ContractRetensionBandingActiveRecord = parseInt(Index);
  }
  AddContractRetension() {
    let addIndex = this.ContractRetensionActiveRecord;
    this.ContractRetentionsServices.addContractRetentionsService(this.ForecastNodeId, addIndex).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }
  DeleteContractRetension() {
    let addIndex = this.ContractRetensionActiveRecord;
    this.ContractRetentionsServices.deleteContractRetentionsService(this.ForecastNodeId, addIndex).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }
  SettingSave() {
    this.ngOnInit();
  }
  SettingClose() {
    this.router.navigateByUrl('/forecast');
  }

  ContractRetensionNameChange(Index, ValueList, $event) {
    this.ContractRetentionsServices.updateName(ValueList.nodeId, ($event.target.value)).subscribe(
      (response => {
        this.ContractRetensionActiveRecord = Index;
        this.ngOnInit();
      }
      ))
  }

  AddBanding(Index, selectedIndex) {
    let NodeId = this.ContractRetensionCollection[Index].valueNodeId;
    this.ContractRetentionsServices.addBandingService(NodeId, selectedIndex).subscribe(
      (response => {
        let selectedindex: any = response;
        this.ContractRetensionBandingActiveRecord = parseInt(selectedindex);
        this.ngOnInit();
      })
    )
  }
  DeleteBanding(Index, selectedIndex) {
    let NodeId = this.ContractRetensionCollection[Index].valueNodeId;
    this.ContractRetentionsServices.deleteBandingService(NodeId, selectedIndex).subscribe(
      (response => {
        let selectedindex: any = response;
        this.ContractRetensionBandingActiveRecord = parseInt(selectedindex);
        this.ngOnInit();
      })
    )
  }

  AddAmount(Value, $event) {
    let NodeId = Value.nodeId;
    let amount = parseFloat($event.target.value).toFixed(2);
    this.ContractRetentionsServices.addAmountService(NodeId, amount).subscribe(
      (response => {

        this.ngOnInit();
      })
    )
  }
  AddPercentage(Value, $event) {
    let NodeId = Value.nodeId;
    let percentage = parseFloat($event.target.value).toFixed(2);
    this.ContractRetentionsServices.addPercentageService(NodeId, percentage).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }

}
