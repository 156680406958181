import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockCalculationPipe'
})
export class StockCalculationPipePipe implements PipeTransform {

  transform(value: any, args?: any): any { 
    if(value==null){
      return 0;
    }
    if (Math.sign(value) == -1) {
      let data = Math.abs(value);
      let convertNumberFormat = data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      let calculateValue = parseFloat(convertNumberFormat.replace(",","")) * (args+1);
      return '(' + calculateValue + ')';
    } else {
      let convertNumberFormat = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      let calculateValue = parseFloat(convertNumberFormat.replace(",","")) * (args+1);
      return calculateValue;
    }
  }

}
