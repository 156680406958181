<app-header></app-header>
<div class="registrationPage">

    <div class="registration">

        <div class="register-container registration-header">

            <div class="registrationForm">
                <div class="centerAlignText">
                    <h2>Support Request</h2>
                </div>
                <div>
                    <form class="input-group-form" [formGroup]="supportRequestform" (ngSubmit)="onSubmit()">
                        <div>
                            <igx-input-group style="margin-bottom: 20px;">
                                <input igxInput name="CustomerName" type="text" formControlName="CustomerName" />
                                <label igxLabel for="CustomerName">Full Name</label>
                                <igx-suffix>
                                    <igx-icon>person</igx-icon>
                                </igx-suffix>
                            </igx-input-group>
                            <igx-input-group style="margin-bottom: 20px;">
                                <label igxLabel for="phone">Phone</label>
                                <input igxInput name="phone" type="tel" formControlName="phone" />
                                <igx-suffix>
                                    <igx-icon>phone</igx-icon>
                                </igx-suffix>
                                <igx-hint position="start">Ex.: +64 888 123 456</igx-hint>
                            </igx-input-group>
                            <igx-input-group style="margin-bottom: 20px;">
                                <label igxLabel for="emailId">Email address</label>
                                <input igxInput name="emailId" type="email" formControlName="emailId" readonly="true" />
                                <igx-suffix>
                                    <igx-icon>email</igx-icon>
                                </igx-suffix>
                            </igx-input-group>

                            <igx-input-group style="margin-bottom: 20px;">
                                <label igxLabel for="help">How can we help you today?</label>
                                <!-- <input igxInput name="help" type="text" isTextArea=true formControlName="help" /> -->
                                <textarea igxInput name="description" rows=”5” formControlName="help"
                                    style="height: 120px;"></textarea>
                                <igx-suffix>
                                    <igx-icon>support</igx-icon>
                                </igx-suffix>
                            </igx-input-group>
                            <igx-input-group style="margin-bottom: 20px;">
                                <label igxLabel for="steps">Steps to reproduce</label>
                                <!-- <input igxInput name="help" type="text" isTextArea=true formControlName="help" /> -->
                                <textarea igxInput name="steps" rows=”5” formControlName="steps"
                                    style="height: 120px;"></textarea>
                                <igx-suffix>
                                    <igx-icon>step</igx-icon>
                                </igx-suffix>
                            </igx-input-group>

                            <igx-simple-combo #combo1 class="input-container" [itemsMaxHeight]="130"
                                [data]="criticality" [valueKey]="'type'" [displayKey]="'description'" type="line"
                                formControlName="howCritical" placeholder="How critical is your request?"
                                style="margin-bottom: 20px;">
                            </igx-simple-combo>
                            <h3>Attachments</h3>
                            <igx-input-group style="margin-bottom: 20px;">
                                <label igxLabel for="attachments" style="text-decoration: none;">Upload supporting
                                    image(s)</label>
                                <input igxInput type="file" multiple="multiple" placeholder="Choose file(s)"
                                    accept='image/png, image/jpeg, image/jpg' formControlName="attachments"
                                    (change)="handleFileInput($event.target.files)" />
                            </igx-input-group>
                            <!-- <igx-input-group>
                                 <label igxLabel for="accessGranted">Provide Access to support user</label> 
                            <input igxInput name="accessGranted" type="checkbox" formControlName="accessGranted"
                                checked="accessGranted" />
                            </igx-input-group> -->
                            <div>
                                <igx-checkbox name="isReproducible" formControlName="isReproducible"
                                    [checked]="isReproducible">
                                    Is the issue reproducible?
                                </igx-checkbox>
                            </div>
                            <div>
                                <igx-checkbox name="accessGranted" formControlName="accessGranted"
                                    [checked]="accessGranted">
                                    Provide Access to support user
                                </igx-checkbox>
                            </div>
                            <button igxButton="contained" igxRipple type="submit"
                                style="align-self: flex-end;">Submit</button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>

</div>
<div>

</div>