import { Component, OnInit } from '@angular/core';
import { CurrencyServiceService } from '../../../../service/SettingServices/CurrencyService/currency-service.service';
import { ProfileServicesService } from '../../../../service/SettingServices/ProfileServices/profile-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MinussignsparenPipe } from 'src/app/common/commonhtml/minussignsparen.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {

  ProfileCollection: any = [];
  ProfileValidColumn = [];
  ProfileValueList: any = [];
  ActiveRecord = '0';
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  defaultIndex: any;
  ProfileValuesArray:any = [];
  ProfileCanDelete: boolean;
  constructor(private ProfileServices: ProfileServicesService,
    private CurrencyServiceServices: CurrencyServiceService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService) { }

  async ngOnInit() { 
    this.ProfileCollection = [];
    this.ProfileValidColumn = [];
    this.ProfileValueList = [];
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.getPeriodCollection(ForecastNodeId);
    this.ProfileServices.getProfileCollections(ForecastNodeId).subscribe(
      (async response => {
        this.ProfileCollection = response;
        this.defaultIndex = this.ProfileCollection[0].defaultIndex;
        this.ProfileValueList = this.ProfileCollection[this.defaultIndex];
        this.ProfileValuesArray = this.ProfileCollection[this.defaultIndex];
        this.ProfileCanDelete = this.ProfileValuesArray.canDelete;
        this.ActiveRecord = this.defaultIndex;
        this.ProfileValueList["total"] = 0;
        let total = 0;
        for (let index = this.PeriodStartIndex; index < this.PeriodEndIndex+1; index++) { 
          let values = this.converttoDisplayValue(this.ProfileValueList.values[index].toString());
          var data = this.converttoDisplayValue(values.toString());
          total+= parseFloat(data.toString().replaceAll(",", ''));
          this.ProfileValueList.values[index] = this.transform(this.ProfileValueList.values[index].toString());
        }
        this.ProfileValueList["total"] = this.transform(total.toString());
      })
    )
  }

  getPeriodCollection(ForecastNodeId) {
    this.PeriodTabViewCollection = [];
    this.DisplayMonthCollection = [];
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      (response => {
        let PeriodViewResponse: any = response;
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }
  /** 
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
  }

  async ngOnChanges() { 
    this.ProfileCollection = [];
    this.ProfileValueList = [];
    this.ProfileValidColumn = [];
    let ForecastNodeId = localStorage.getItem('ForecastId');
    if (this.PeriodTabViewCollection.length > 0) {
      this.getPeriodCollection(ForecastNodeId);
    }
    this.ProfileServices.getProfileCollections(ForecastNodeId).subscribe(
      (async response => {
        //this.ActiveRecord = '0';
        this.ProfileCollection = response;
        this.defaultIndex = this.ProfileCollection[this.ActiveRecord].defaultIndex.toString();
        this.ActiveRecord = this.ProfileCollection[this.ActiveRecord].selectedIndex.toString();
        this.ProfileValueList = this.ProfileCollection[parseInt(this.ActiveRecord)];
        this.ProfileValuesArray = this.ProfileCollection[parseInt(this.ActiveRecord)];
        this.ProfileCanDelete = this.ProfileValuesArray.canDelete;
        this.ProfileValueList["total"] = 0;
        let total = 0;
        for (let index = this.PeriodStartIndex; index <= this.PeriodEndIndex; index++) { 
          let values = this.converttoDisplayValue(this.ProfileValueList.values[index].toString());
          var data = this.converttoDisplayValue(values.toString());
          total+= parseFloat(data.toString().replaceAll(",", ''));
          this.ProfileValueList.values[index] = this.transform(this.ProfileValueList.values[index].toString());
        }
        this.ProfileValueList["total"] = this.transform(total.toString());
      })
    )
  }

  isNumber(evt) {
   console.log(evt.keyCode);
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode != 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
       
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      $event.target.value = validatebynumberFormate;
    }
  }
  async AddProfile() { 

    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.ProfileServices.AddProfile(ForecastNodeId).subscribe(
      (async response => {
        this.ngOnChanges();
      })
    )
  }
  changeValueList(index) {
    this.ActiveRecord = index.toString();
    this.defaultIndex = this.defaultIndex.toString();
    this.ProfileValueList = this.ProfileCollection[index];
    this.ProfileCanDelete = this.ProfileCollection[index].canDelete;
    this.ProfileValuesArray = this.ProfileCollection[parseInt(this.ActiveRecord)];
    this.ProfileValueList["total"] = 0;
    let total = 0;
    for (let index = this.PeriodStartIndex; index <= this.PeriodEndIndex; index++) { 
      let values = this.converttoDisplayValue(this.ProfileValueList.values[index].toString());
      var data = this.converttoDisplayValue(values.toString());
      total+= parseFloat(data.toString().replaceAll(",", ''));
      this.ProfileValueList.values[index] = this.transform(this.ProfileValueList.values[index].toString());
    }
    this.ProfileValueList["total"] = this.transform(total.toString());
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.ProfileServices.setProfileindex(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (response => {
        let index = response;
        this.ActiveRecord = index.toString();
      })
    )
  }

  DeleteProfile() {
    
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.ProfileServices.DeleteProfile(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async response => {
        let ActiveId = parseInt(this.ActiveRecord) - 1;
        this.ActiveRecord = ActiveId.toString();
        this.ProfileCollection = [];
        this.ProfileValueList = [];
        this.ProfileValidColumn = [];
        let ForecastNodeId = localStorage.getItem('ForecastId');
        if (this.PeriodTabViewCollection.length > 0) {
          this.getPeriodCollection(ForecastNodeId);
        }
        this.ProfileServices.getProfileCollections(ForecastNodeId).subscribe(
          (async response => {

            this.ProfileCollection = response;
            let activeId = this.ProfileCollection.length - 1;
            this.ActiveRecord = activeId.toString();
            this.defaultIndex = this.ProfileCollection[parseInt(this.ActiveRecord)].defaultIndex.toString();
            this.ProfileValueList = this.ProfileCollection[parseInt(this.ActiveRecord)];
          })
        )
        //this.ngOnChanges();
      })
    )
  }

  SetAllProfileList(ProfileValueList, NodeId, $event) { 
     if ($event.target.value == '') {
      $event.target.value = 0;
    }
    let total = 0;
    let resultarray = [];
    var loopindexstart;
    let value = $event.target.value;
    for (loopindexstart = this.PeriodStartIndex; loopindexstart <= this.PeriodEndIndex; ++loopindexstart) { 
      if (ProfileValueList.currentActualsIndex >= loopindexstart) { 
        if (this.check(ProfileValueList.value[loopindexstart].toString()) == true) { 
          let data = ProfileValueList.value[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          ProfileValueList.values[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = ProfileValueList.value[loopindexstart].toString().replaceAll(',', '');
          ProfileValueList.values[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      } else {
        if (this.check(value.toString()) == true) { 
          let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          ProfileValueList.values[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = value.replaceAll(',', '');
          ProfileValueList.values[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      }
      
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById("total-"+ProfileValueList.nodeId));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
      ProfileValueList["total"] = this.transform(inputValue.value);
    }
    this.ProfileValueList["Edited"] = true;
    $event.target.value = '';
  }

  converttoDisplayValue(decimalValue) {
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }

  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        
      }
    }
  }
  ProfileSettingNameChange(Index, ValuesList, $event) {

    if($event.target.value ==''){
      return;
    }
    ValuesList.name = $event.target.value;
    ValuesList["Edited"] = true;
    
  }
  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  DafaultProfile() {
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.ProfileServices.DefaultProfile(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async response => {
        this.ngOnChanges();
      })
    )
  }
  MonthProfileList(ProfileCollectionValues, $event, index ) { 
    if ($event.target.value == '') {
      return;
    } 
    let total = 0;
    var loopindexstart;
    let value = $event.target.value;
    if (this.check(value.toString()) == true) { 
      let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    } else {
      let data = value.replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    }
    for (loopindexstart = this.PeriodStartIndex; loopindexstart <= this.PeriodEndIndex; ++loopindexstart) { 
      if (this.check(this.ProfileValueList.values[loopindexstart].toString()) == true) { 
        let data = this.ProfileValueList.values[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
        total += parseFloat(data); 
      } else {
        let data = this.ProfileValueList.values[loopindexstart].toString().replaceAll(',', '');
        total += parseFloat(data);
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById("total-"+ProfileCollectionValues.nodeId));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
      ProfileCollectionValues["total"] = this.transform(inputValue.value);
    }
    this.ProfileValueList["Edited"] = true;
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  CalculateSave(NodeId, StringValue){ 
    let ForecastId = localStorage.getItem("ForecastId");
    
    let save = {
      ForecastNodeId:ForecastId,
      SaveProfileData: []
    }

    this.ProfileCollection.forEach(element => { 
      let SaveProfileCollection = {
      Name: String,
      Edited: Boolean,
      NodeId:Number,
      values:[],
    }
      if(element.Edited !=undefined && element.Edited == true) { 
        SaveProfileCollection.Name = element.name;
        SaveProfileCollection.Edited = element.Edited;
        SaveProfileCollection.NodeId = element.nodeId;
        for (let index = 0; index < element.values.length; index++) { 
          if (this.check(element.values[index].toString()) == true) { 
            let data = element.values[index].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
            element.values[index] = parseFloat(data.toString()).toFixed(0);
          } else {
            let data = element.values[index].toString().replaceAll(',', '');
            element.values[index] = parseFloat(data.toString()).toFixed(0);
          }
         
        }
        SaveProfileCollection.values = element.values;
        save.SaveProfileData.push(SaveProfileCollection);
      }
    });

    if(save.SaveProfileData.length>0){ 
      this.SpinnerService.show();
      this.ProfileServices.saveProfileCollection(save).subscribe(
        (response=>{
          this.ProfileCollection = [];
          this.ProfileValidColumn = [];
          this.ProfileValueList = [];
          let ForecastNodeId = localStorage.getItem('ForecastId');
          this.getPeriodCollection(ForecastNodeId);
          this.ProfileCollection = response;
          this.SpinnerService.hide();
          this.defaultIndex = this.ProfileCollection[0].defaultIndex;
          this.ProfileValueList = this.ProfileCollection[this.defaultIndex];
          this.ProfileValuesArray = this.ProfileCollection[this.defaultIndex];
          this.ActiveRecord = this.defaultIndex;
          this.ProfileValueList["total"] = 0;
          let total = 0;
          for (let index = this.PeriodStartIndex; index <= this.PeriodEndIndex; index++) { 
            let values = this.converttoDisplayValue(this.ProfileValueList.values[index].toString());
            var data = this.converttoDisplayValue(values.toString());
            this.ProfileValueList.values[index] = this.transform(this.ProfileValueList.values[index].toString());
            total+= parseFloat(data.toString().replaceAll(",", ''));
          }
          this.ProfileValueList["total"] = this.transform(total.toString());
        })
          
      )
    }
    if(StringValue == 'close'){
      this._router.navigateByUrl("/forecast");
    }
  }

  transform(value) { 
    if(value==null){
      return 0;
    }
    if (Math.sign(value) == -1) {
      let data = Math.abs(value);
      let convertNumberFormat = data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return '(' + convertNumberFormat + ')';
    } else {
      let convertNumberFormat = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return convertNumberFormat;
    }
  }

}
