import { Component, OnInit } from '@angular/core';
import { ConsolidationService } from 'src/app/service/consolidation.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';
import { eliminationRecordsModel, eliminationsModel, elimnationRecords } from 'src/app/model/EliminationRecords.model';
@Component({
  selector: 'app-eliminations',
  templateUrl: './eliminations.component.html',
  styleUrls: ['./eliminations.component.scss']
})
export class EliminationsComponent implements OnInit {
  eliminationRecords: eliminationsModel;
  DisplayTreeProfitAndLoss = '';
  DisplayTreeBalanceSheet = '';
  columnsArrayPL = [];
  columnsArrayBS = [];

  public pnlEliminationRecords: eliminationRecordsModel[];
  public BLElimnationRecords: eliminationRecordsModel[];

  constructor(private ConsolidationService: ConsolidationService, private SpinnerService: NgxSpinnerService) {
  }

  async reArrangeRecords() {
    this.SpinnerService.show();
    let forcastNodeid = localStorage.getItem('ForecastId');
    this.ConsolidationService.getElliminationRecords(forcastNodeid).subscribe(
      (response: eliminationsModel) => {
        this.eliminationRecords = response;
        this.DisplayTreeProfitAndLoss = 'Active';
        this.DisplayTreeBalanceSheet = '';
        // console.log("this.eliminationRecords", this.eliminationRecords);
        this.pnlEliminationRecords = [];
        this.BLElimnationRecords = [];
        if (this.pnlEliminationRecords.length == 0) {
          this.eliminationRecords.pnlEliminationRecords.forEach(element => {
            let eliminationRec = new eliminationRecordsModel();
            eliminationRec.recordName = element.recordName;
            eliminationRec.recordNodeid = element.recordNodeid;
            eliminationRec.referenceForecasts = element.referenceForecasts;
            eliminationRec.isEliminated = element.isEliminated;
            eliminationRec.valueArray = element.valueArray;
            if (this.columnsArrayPL.length == 0) {
              let count = 0;
              element.monthDisplay.forEach(month => {
                this.columnsArrayPL.push({ header: month.name, count: count, type: "number" })
                count = count + 1;
              });
            }
            eliminationRec.columns = this.columnsArrayPL;
            this.pnlEliminationRecords.push(eliminationRec);
          });
        }
        if (this.BLElimnationRecords.length == 0) {
          this.eliminationRecords.blEliminationRecords.forEach(element => {
            let eliminationRec = new eliminationRecordsModel();
            eliminationRec.recordName = element.recordName;
            eliminationRec.recordNodeid = element.recordNodeid;
            eliminationRec.referenceForecasts = element.referenceForecasts;
            eliminationRec.isEliminated = element.isEliminated;
            eliminationRec.valueArray = element.valueArray;
            if (this.columnsArrayBS.length == 0) {
              let count = 0;
              element.monthDisplay.forEach(month => {
                this.columnsArrayBS.push({ header: month.name, count: count, type: "number" })
                count = count + 1;
              });
            }
            eliminationRec.columns = this.columnsArrayBS;
            this.BLElimnationRecords.push(eliminationRec);
          });
        }
        this.SpinnerService.hide();

      }
    )
  }

  ngOnInit(): void {
    this.reArrangeRecords();
  }


  ProfitAndLoss() {
    this.DisplayTreeProfitAndLoss = 'Active';
    this.DisplayTreeBalanceSheet = '';
  }

  BalanceSheettabClick() {
    this.DisplayTreeProfitAndLoss = '';
    this.DisplayTreeBalanceSheet = 'Active';
  }
  EliminateRecords() {
    let elimnationRecord: elimnationRecords[] = [];
    this.pnlEliminationRecords.forEach(pnl => {
      let eliminRec = new elimnationRecords();
      eliminRec.nodeId = pnl.recordNodeid;
      eliminRec.isEliminated = pnl.isEliminated;
      elimnationRecord.push(eliminRec);
    });

    this.BLElimnationRecords.forEach(BS => {
      let eliminRec = new elimnationRecords();
      eliminRec.nodeId = BS.recordNodeid;
      eliminRec.isEliminated = BS.isEliminated;
      elimnationRecord.push(eliminRec);

    });
    let elimRecord = {
      "elimnationRecords": elimnationRecord
    }
    this.ConsolidationService.elimnateRecords(elimRecord).subscribe(
      ((response: any) => {
        this.pnlEliminationRecords = [];
        this.BLElimnationRecords = [];
        this.reArrangeRecords();
      })
    )

  }
  CancelElimination() {
    this.pnlEliminationRecords = [];
    this.BLElimnationRecords = [];
    window.location.reload();
  }
}
