import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Userregistrationmodel } from '../model/userregistrationmodel';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserregistrationService {
  UserResgitrationModel: Userregistrationmodel;
  constructor(private http: HttpClient) { }


  RegisterUser(urModel: Userregistrationmodel) {
    this.UserResgitrationModel = urModel;
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/Account/userregistration", this.UserResgitrationModel, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  checkUserExists(emailID: string) {
    return this.http.get(environment.url + "/api/Account/checkuserexists/" + emailID, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      )).toPromise();
  }

  validateEmail(userID: string) {
    return this.http.get(environment.url + "/api/Account/ValidateUserAccount/" + userID, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  updatePassword(userID: Number, password:string) {
    return this.http.get(environment.url + "/api/Account/UpdatePassword/" + userID+ "/" +password, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  forgotPassword(emailID:string) {
    return this.http.get(environment.url + "/api/Account/ForgotPassword/" + emailID, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

}
