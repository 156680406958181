import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotlinkService {

  constructor(private http:HttpClient) { }
 
  getHotLinkSourceCollection(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/HotLink/HotLinkSourceForecastCollection/" + ForecastNodeId)
        .pipe(map(
            (response: any) => {
                return response;
            }
        ));
    }

    getForecastListCollection(ForecastNodeId, RecordNodeId):Observable<any>{
      return this.http.get(environment.url + "/api/HotLink/HotlinkForecastList/" + ForecastNodeId +"/"+RecordNodeId)
      .pipe(map(
          (response: any) => {
              return response;
          }
      ));
    }
    getForecastMemoRecordCollection(NodeId, selectedHotlinkToForecastId):Observable<any>{
      return this.http.get(environment.url + "/api/HotLink/HotlinkForecastMemoRecordCollection/" + NodeId + "/"+selectedHotlinkToForecastId)
      .pipe(map(
          (response: any) => {
              return response;
          }
      ));
    }
    saveHotlinkData(saveHotlinkToForecast):Observable<any>{
      let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      let url = environment.url + "/api/HotLink/SaveHotlinkToForecast";
      return this.http.post(url, saveHotlinkToForecast, { headers: reqHeaders })
        .pipe(map(
          (response: any[]) => {
            return response;
          }
        ));
    }
    deletehotlink(RecordNodeId, hotlinkId):Observable<any>{
      return this.http.get(environment.url + "/api/HotLink/HotlinkDataSourceDelete/" + RecordNodeId +"/"+hotlinkId)
      .pipe(map(
          (response: any) => {
              return response;
          }
      ));
    }
}
