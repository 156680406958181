import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-forecast-extent-tabs',
  templateUrl: './forecast-extent-tabs.component.html',
  styleUrls: ['./forecast-extent-tabs.component.scss']
})
export class ForecastExtentTabsComponent implements OnInit {

  @Input() event;
  Forecastextenttab: any;
  setall: any;
  setalls: any;
  constructor() {
  }

  ngOnInit() {
    if (this.event != '') {
      this.Forecastextenttab = true;
    } else {
      this.Forecastextenttab = false;
    }
  }
  SetAll($event, displaydata) {
    let total = 0;
    // console.log(displaydata);
  }

  ngOnChange() {
    if (this.event != '') {
      this.Forecastextenttab = true;
    } else {
      this.Forecastextenttab = false;
    }
  }
}
