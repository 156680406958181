<div class="treeMenu enteropening"><tree-root #tree [nodes]="nodes"  (initialized)="onTreeLoad()" 
    [options]="options"><ng-template #treeNodeWrapperTemplate let-node let-index="index">
    <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
      <tree-node-expander [node]="node"></tree-node-expander>
      <div class="node-content-wrapper"
        [class.node-content-wrapper-active]="node.isActive"
        [class.node-content-wrapper-focused]="node.isFocused"
        (click)="node.mouseAction('click', $event)"
        (dblclick)="node.mouseAction('dblClick', $event)"
        (contextmenu)="node.mouseAction('contextMenu', $event)"
        (treeDrop)="node.onDrop($event)"
        [treeAllowDrop]="node.allowDrop"
        [treeDrag]="node"
        [treeDragEnabled]="node.allowDrag()">
        <div>
        <span class="displayname" [ngClass]="{'NonEditable':node.data.localCurrency==null && node.data.nodeType!=51010 && node.data.nodeType!=51009}">{{node.data.name}}</span>
        <span class="displayvalue" style="margin: 2px 17px -1px 0px;float: right;">
        <input  value="{{node.data.localCurrency |minussignsparen }}"
        *ngIf="node.data.localCurrency!=null || node.data.nodeType == 51010 || node.data.nodeType == 51009"
        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
        (blur)="updateopeningValue(node.data,$event)"
        allow-decimal="true" allow-negative="true" 
        (click)="valuelistregrex($event)"
        id="{{node.data.budgetDataNodeId}}"
        style="text-align: right;border: 0px;height: 25px;-webkit-box-shadow: none !important;box-shadow: none !important;-webkit-transition: none !important;"
        class="form-control {{node.data.localCurrency}}" type="text"/>
         </span>
        <!-- <span class="displayvalue" style="margin: 2px 5px -1px 0px;float: right;" *ngIf="!node.data.localCurrency && node.data.nodeType!='30000'"><input 
            allow-decimal="true" allow-negative="true"
            style="text-align: right; border: 0px;" 
            (click)="valuelistregrex($event)"
            id="{{node.data.nodeId}}"
            (keypress)="isNumber($event)"(keyup)="ValidateValue($event)"value="0" class=""  type="text" /></span> -->
        </div>
      </div>
    </div>
  </ng-template>
</tree-root>
</div>