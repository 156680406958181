import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IntegrationModel } from '../model/IntegrationModel';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class AdminService {
    constructor(private http: HttpClient) { }

    purchaseSubscription(SubscriptionID, emailID, UserID, Integrations) {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.url + "/api/LicenseSubscription/PurchaseSubscription", { SubscriptionID: SubscriptionID, emailID: emailID, UserID: UserID, Integrations: Integrations }, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ))
    }

    getIntegrations(): Observable<IntegrationModel[]> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/LicenseSubscription/getALLIntegrations/", { headers: reqHeaders })
            .pipe(map(
                (response: IntegrationModel[]) => {
                    return response;
                }
            ));
    }
}