import { Component, OnInit, Input } from '@angular/core';
import { MethodserviceService } from "../../component/record/method/service/methodservice.service";
import { TreeviewService } from '../../service/treeview.service';
import { ValuelistComponent } from '../../component/record/valuelist/valuelist.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';
import * as $ from 'jquery';
import { budgetdatamodified, recordSaveModel } from 'src/app/model/recordSaveModel';

@Component({
  selector: 'app-finnacedassets',
  templateUrl: './finnacedassets.component.html',
  styleUrls: ['./finnacedassets.component.scss']
})
export class FinnacedassetsComponent implements OnInit {


  @Input() startIndex: any;
  @Input() recordnodeType: any;
  @Input() ActiveTreenodeId: any;

  darrivationnode: any;
  inputdarrivation: any;
  displayvaluelistdata = [];
  displaydarrivation = [];
  sectiontreeview: any;
  displaynodevisehtml: any;
  months = [];
  getProfileCollection = [];
  addeddata: boolean;
  selectedProfile: any;
  selectedNodeIds: any;
  percentagevaluearray: any;
  nodarrivationarray = [];
  darrivationarray = [];
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedlFinnaceAssestsRecord: any = [];
  loantakenarray: any = {};
  PeriodTabViewStartIndex = 0;
  PeriodTabViewEndIndex = 11;
  //performance 
  CommonHtmlArrayResult: any;
  selectedOption: number | null = null;
  displayAssetSoldDetails = false;

  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  //
  constructor(
    private methodservice: MethodserviceService,
    private TreeviewServices: TreeviewService,
    private ValuelistComponent: ValuelistComponent,
    private forecasttabsComponent: ForecasttabsComponent,
    private SpinnerService: NgxSpinnerService) {
    this.activeId = localStorage.getItem('ForecastId');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnInit() {
  }

  async ngOnChanges() {
    if (this.startIndex == undefined) {
      return;
    }
    if (this.ActiveTreenodeId == '' && this.activeId == '') {
      return;
    }
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.getProfileCollection = [];
    this.selectedProfile = '';
    this.sectiontreeview = '';
    this.inputdarrivation = '';
    this.addeddata = false;
    this.CalculatedlFinnaceAssestsRecord = [];
    $('.OverdraftInterestRate').addClass('col-sm-5');
    if (this.startIndex != undefined) {
      this.PeriodTabViewStartIndex = parseInt(this.startIndex) * 12;
      this.PeriodTabViewEndIndex = this.PeriodTabViewStartIndex + 12;
      this.PeriodTabViewStartIndex = this.PeriodTabViewStartIndex;
      this.PeriodTabViewEndIndex = this.PeriodTabViewEndIndex;
      this.methodservice.getFinnacedAssestsRecord(this.activeId, this.ActiveTreenodeId).subscribe(
        (response: any) => {
          this.enumInterestTypes = this.interestTypesenum();
          this.enumpaymentTiming = this.paymenttimingenum();
          this.CalculatedlFinnaceAssestsRecord = response;
          console.log("this.CalculatedlFinnaceAssestsRecord ", this.CalculatedlFinnaceAssestsRecord);
          this.selectedOption = this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldType;
          if (this.selectedOption > 0) {
            this.displayAssetSoldDetails = true;
          }
          this.loantakenarray = {
            "nodeID": this.ActiveTreenodeId,
            "DisplayLoanDetailData": [
              {
                "InterestTypes": this.CalculatedlFinnaceAssestsRecord.interestTypes,
                "PaymentFrequencies": this.CalculatedlFinnaceAssestsRecord.paymentFrequencies.numberFormatPrefix,
                "PaymentTimings": this.CalculatedlFinnaceAssestsRecord.paymentTimings,
                "isNewLoan": this.CalculatedlFinnaceAssestsRecord.isNewLoan,
                "LoanDrawdownPeriodIndex": this.CalculatedlFinnaceAssestsRecord.loanDrawdownPeriodIndex,
                "Term": this.CalculatedlFinnaceAssestsRecord.term,
                "LoanBalance": this.CalculatedlFinnaceAssestsRecord.loanBalance,
                "InterestRate": this.CalculatedlFinnaceAssestsRecord.interestRate,
                "Payment": this.CalculatedlFinnaceAssestsRecord.payment.toFixed(2),
                "HasDepositPayment": this.CalculatedlFinnaceAssestsRecord.hasDepositPayment,
                "CanHaveDepositPayment": this.CalculatedlFinnaceAssestsRecord.canHaveDepositPayment,
                "ShowDeposit": this.CalculatedlFinnaceAssestsRecord.showDeposit,
                "ShowDepositPayment": this.CalculatedlFinnaceAssestsRecord.showDepositPayment,
                "CanHaveBulletPayment": this.CalculatedlFinnaceAssestsRecord.canHaveBulletPayment,
                "ShowBullet": this.CalculatedlFinnaceAssestsRecord.showBullet,
                "ShowBulletPayment": this.CalculatedlFinnaceAssestsRecord.showBulletPayment,
                "CanHaveBalloonPayment": this.CalculatedlFinnaceAssestsRecord.canHaveBalloonPayment,
                "CanHaveHolidayPeriod": this.CalculatedlFinnaceAssestsRecord.canHaveHolidayPeriod,
                "HasBulletPayment": this.CalculatedlFinnaceAssestsRecord.hasBulletPayment,
                "HasBalloonPayment": this.CalculatedlFinnaceAssestsRecord.hasBalloonPayment,
                "HasHolidayPeriod": this.CalculatedlFinnaceAssestsRecord.hasHolidayPeriod,
                "DepositPayment": this.CalculatedlFinnaceAssestsRecord.depositPayment,
                "BulletPayment": this.CalculatedlFinnaceAssestsRecord.bulletPayment,
                "MonthsBulletDelay": this.CalculatedlFinnaceAssestsRecord.monthsBulletDelay,
                "BalloonPayment": this.CalculatedlFinnaceAssestsRecord.balloonPayment,
                "MonthsHoliday": this.CalculatedlFinnaceAssestsRecord.monthsHoliday,
                "LoanPrincipal": this.CalculatedlFinnaceAssestsRecord.loanPrincipal,
                "IsPeriodStartEnabled": this.CalculatedlFinnaceAssestsRecord.isPeriodStartEnabled,
                "SelectedIndex": this.CalculatedlFinnaceAssestsRecord.selectedIndex,
                "AssetSoldAmount": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldAmount,
                "AssetSoldPeriodIndex": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldPeriodIndex,
                "AssetSoldDaysCredit": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldDaysCredit,
              }
            ]
          }
          this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'] = [];
        },
        err => {
          this.SpinnerService.hide();
          throw err;
        })
    }
  }
  interestTypesenum() {
    let interesttypesenum = [{
      0: 'Undefined',
      1: 'CompoundInterest',
      2: 'SimpleInterest',
      3: 'InterestOnly',
    }]
    return interesttypesenum;
  }

  paymenttimingenum() {
    let paymenttimingenum = [{
      1: 'InAdvance',
      2: 'InArrears'
    }]
    return paymenttimingenum;
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
  //Calculate As Balance
  CalculateAsBalance(recordNodeId, event) {
    let selectedOption = 0;
    if (event.target.checked == true) {
      selectedOption = 1;
    }
    this.methodservice.CalculateAsBalance(recordNodeId.selectNodeId, selectedOption).subscribe(
      (response) => {
        return response;
      }
    )
  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }

  UpdateRecordModified(loantakenarray) {
    let UpdateLoanTakenArray = new recordSaveModel();
    UpdateLoanTakenArray.recordNodeID = loantakenarray.nodeID;
    let budgetData = new budgetdatamodified();
    budgetData.valuenodeId = loantakenarray.nodeID;
    budgetData.valuelist = [];
    budgetData.selectedNodes = [];
    budgetData.unselectedNodes = [];
    budgetData.hasLoanOtherOptions = true;
    budgetData.isDerivation = false;
    budgetData.isNodeSelection = false;
    budgetData.loanCalculationModels = loantakenarray;
    UpdateLoanTakenArray.budgetData.push(budgetData);
    this.ValuelistComponent.recordModified(UpdateLoanTakenArray);
  }
  changeInterestType($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['Term'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Term");
    this.UpdateRecordModified(this.loantakenarray);
  }

  changepaymentFrequcncy($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentFrequencies'] = $event.toString();
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentFrequencies");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeTerm($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['Term'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Term");
    this.UpdateRecordModified(this.loantakenarray);
  }

  changeloanmadePeriod($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['SelectedIndex'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("SelectedIndex");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeLoanBalance($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    let values = $event.replaceAll(/,/g, '');
    this.loantakenarray.DisplayLoanDetailData[0]['LoanBalance'] = parseFloat(values).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("LoanBalance");
    this.UpdateRecordModified(this.loantakenarray);
  }
  HasBallooneEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordballon').addClass('hidden');
    } else {
      $('.loantakenrecordballon').removeClass('hidden');
    }

    this.loantakenarray.DisplayLoanDetailData[0]['HasBalloonPayment'] = $event.target.checked;
    this.CalculatedlFinnaceAssestsRecord.hasBalloonPayment = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBalloonPayment");
    this.UpdateRecordModified(this.loantakenarray);
  }
  hasHolidayPeriodEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordhollidayPeriodEvent').addClass('hidden');
    } else {
      $('.loantakenrecordhollidayPeriodEvent').removeClass('hidden');
    }

    this.loantakenarray.DisplayLoanDetailData[0]['HasHolidayPeriod'] = $event.target.checked;
    this.CalculatedlFinnaceAssestsRecord.hasHolidayPeriod = $event.target.checked;
    this.CalculatedlFinnaceAssestsRecord.canHaveBulletPayment = !$event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasHolidayPeriod");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changepaymentTiming($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentTimings'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentTimings");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeInterestRate($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['InterestRate'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("InterestRate");
    this.UpdateRecordModified(this.loantakenarray);

  }
  changePayment($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    let value = $event.replaceAll(/,/g, '');
    this.loantakenarray.DisplayLoanDetailData[0]['Payment'] = parseFloat(value).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Payment");
    this.UpdateRecordModified(this.loantakenarray);

  }

  changeBaloon($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BalloonPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BalloonPayment");
    this.UpdateRecordModified(this.loantakenarray);

  }

  IsnewLoanchangeEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-periodStart').addClass('hidden');
    } else {
      $('.loantakenrecord-periodStart').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['IsNewLoan'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("IsNewLoan");
    this.UpdateRecordModified(this.loantakenarray);
  }

  // onchange bullet event
  OnchangeBullet($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-BulletPayment').addClass('hidden');
      $('.loantakenrecord-PaymentMonth').addClass('hidden');
    } else {
      $('.loantakenrecord-BulletPayment').removeClass('hidden');
      $('.loantakenrecord-PaymentMonth').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['HasBulletPayment'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBulletPayment");
    this.CalculatedlFinnaceAssestsRecord.canHaveHolidayPeriod = !$event.target.checked;
    this.UpdateRecordModified(this.loantakenarray);
  }
  // onchange bullet event
  OnchangeDeposit($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.enabledepositepayment').addClass('hidden');
    } else {
      $('.enabledepositepayment').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['HasDepositPayment'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasDepositPayment");
    this.CalculatedlFinnaceAssestsRecord.hasDepositPayment = $event.target.checked;
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray)
  }
  changeBulletPayment($event, loantakenRecordValue) {

    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BulletPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BulletPayment");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeBulletPaymentMonth($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsBulletDelay'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsBulletDelay");
    this.UpdateRecordModified(this.loantakenarray);
  }
  // update holiday(Month)
  changeholidaymonthText($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsHoliday'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsHoliday");
    this.UpdateRecordModified(this.loantakenarray);
  }

  changeAssetSoldAmount($event) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['AssetSoldAmount'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("AssetSoldAmount");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeAssetSoldPeriod($event) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['AssetSoldPeriodIndex'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("AssetSoldPeriodIndex");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeAssetSoldDaysCredit($event) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['AssetSoldDaysCredit'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("AssetSoldDaysCredit");
    this.UpdateRecordModified(this.loantakenarray);
  }
  // change deposite code 
  changeDeposite($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }

    this.loantakenarray.DisplayLoanDetailData[0]['DepositPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    console.log(this.loantakenarray);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("DepositPayment");
    console.log(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeAssetSold($event, loantakenRecordValue, index: number) {
    if (this.selectedOption === index) {
      this.selectedOption = null;
    } else {
      this.selectedOption = index;
    }
    if (this.selectedOption != 0) {
      this.displayAssetSoldDetails = true;
    }

    if ($event == '' && $event.target.value == null) {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['assetSoldType'] = [];
    if (this.loantakenarray.DisplayLoanDetailData.length > 0 && this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].length > 0) {
      const index = this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].indexOf("assetSoldType");
      if (index > -1) {
        this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].splice(index, 1);
      }
    }
    this.loantakenarray.DisplayLoanDetailData[0]['assetSoldType'] = parseInt($event.target.value.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("assetSoldType");
    this.UpdateRecordModified(this.loantakenarray);

  }


  arrMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  updateLoanTakenrecord() {
    this.SpinnerService.show();
    this.methodservice.getFinnacedAssestsRecord(this.activeId, this.ActiveTreenodeId).subscribe(
      (response: any) => {
        this.enumInterestTypes = this.interestTypesenum();
        this.enumpaymentTiming = this.paymenttimingenum();
        this.CalculatedlFinnaceAssestsRecord = response;
        this.SpinnerService.hide();
        this.selectedOption = this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldType;
        console.log("CalculatedlFinnaceAssestsRecord", this.CalculatedlFinnaceAssestsRecord);
        this.loantakenarray = {
          "nodeID": this.ActiveTreenodeId,
          "DisplayLoanDetailData": [
            {
              "InterestTypes": this.CalculatedlFinnaceAssestsRecord.interestTypes,
              "PaymentFrequencies": this.CalculatedlFinnaceAssestsRecord.paymentFrequencies.numberFormatPrefix,
              "PaymentTimings": this.CalculatedlFinnaceAssestsRecord.paymentTimings,
              "isNewLoan": this.CalculatedlFinnaceAssestsRecord.isNewLoan,
              "LoanDrawdownPeriodIndex": this.CalculatedlFinnaceAssestsRecord.loanDrawdownPeriodIndex,
              "Term": this.CalculatedlFinnaceAssestsRecord.term,
              "LoanBalance": this.CalculatedlFinnaceAssestsRecord.loanBalance,
              "InterestRate": this.CalculatedlFinnaceAssestsRecord.interestRate,
              "Payment": this.CalculatedlFinnaceAssestsRecord.payment,
              "HasDepositPayment": this.CalculatedlFinnaceAssestsRecord.hasDepositPayment,
              "CanHaveDepositPayment": this.CalculatedlFinnaceAssestsRecord.canHaveDepositPayment,
              "ShowDeposit": this.CalculatedlFinnaceAssestsRecord.showDeposit,
              "ShowDepositPayment": this.CalculatedlFinnaceAssestsRecord.showDepositPayment,
              "CanHaveBulletPayment": this.CalculatedlFinnaceAssestsRecord.canHaveBulletPayment,
              "ShowBullet": this.CalculatedlFinnaceAssestsRecord.showBullet,
              "ShowBulletPayment": this.CalculatedlFinnaceAssestsRecord.showBulletPayment,
              "CanHaveBalloonPayment": this.CalculatedlFinnaceAssestsRecord.canHaveBalloonPayment,
              "CanHaveHolidayPeriod": this.CalculatedlFinnaceAssestsRecord.canHaveHolidayPeriod,
              "HasBulletPayment": this.CalculatedlFinnaceAssestsRecord.hasBulletPayment,
              "HasBalloonPayment": this.CalculatedlFinnaceAssestsRecord.hasBalloonPayment,
              "HasHolidayPeriod": this.CalculatedlFinnaceAssestsRecord.hasHolidayPeriod,
              "DepositPayment": this.CalculatedlFinnaceAssestsRecord.depositPayment,
              "BulletPayment": this.CalculatedlFinnaceAssestsRecord.bulletPayment,
              "MonthsBulletDelay": this.CalculatedlFinnaceAssestsRecord.monthsBulletDelay,
              "BalloonPayment": this.CalculatedlFinnaceAssestsRecord.balloonPayment,
              "MonthsHoliday": this.CalculatedlFinnaceAssestsRecord.monthsHoliday,
              "LoanPrincipal": this.CalculatedlFinnaceAssestsRecord.loanPrincipal,
              "IsPeriodStartEnabled": this.CalculatedlFinnaceAssestsRecord.isPeriodStartEnabled,
              "SelectedIndex": this.CalculatedlFinnaceAssestsRecord.selectedIndex,
              "AssetSoldAmount": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldAmount,
              "AssetSoldPeriodIndex": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldPeriodIndex,
              "AssetSoldDaysCredit": this.CalculatedlFinnaceAssestsRecord.assetSoldDetailsModel.assetSoldDaysCredit,

            }
          ]
        }
        this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'] = [];
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
}

