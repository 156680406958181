<app-header></app-header>
<div style="width: 90%;">
    <h2 class="HeaderColor Header"> {{ this.title}} </h2>
    <div style="    display: flex;">
        <div class="forecastSelection">
            <div class="subHeader">Select forecasts to print OTR: </div>
            <div style="overflow-x: auto;height: 90vh; font-size: 15px;">
                <div *ngFor="let forecast of forecastlist">
                    <igx-checkbox #checkbox [checked]="forecast.consolSelected" (change)="checked($event, forecast)">
                        {{ forecast.name }}
                    </igx-checkbox>
                </div>
            </div>
        </div>
        <div class="OTRReport" *ngIf="forecastIDList.length>=1">
            <app-otr [forecasts]="forecastIDList" isPrintOTR="true"></app-otr>
        </div>
    </div>
</div>