<div class="col-sm-12" style="background-color: #eaeeef !important; height: 50px;">
  <nav class="navbar navbar-expand-sm" >
    <div class="collapse navbar-collapse" id="mynav">
      <div class="col-sm-3">
        <div class="my-2 my-lg-0" *ngIf="currentuserName!=''">
          <a href="/forecast">
            <img
            src="../../../assets/FullBrandLogowebsite.png" style=" width: 50%;margin: -14px 0 0px -40px;"> 
          </a>
        </div>
        <div class="my-2 my-lg-0" *ngIf="currentuserName ==''">
          <img
            src="../../../assets/FullBrandLogowebsite.png" width="70%"> 
        </div>
      </div>
      <div class="col-sm-7">
        
      </div>
      <div class="col" style="margin-left: 80px;">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar-list-4">
          <ul class="navbar-nav" *ngIf="currentuserName" style="margin: -21px 0 0 0;">
              <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <img src="../../../assets/user_profile.png" width="40" height="40"
                 class="rounded-circle">
              </a>
              
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Dashboard</a>
                <a class="dropdown-item" href="#">Edit Profile</a>
                <a class="dropdown-item" (click)="logout()">Log Out</a>
              </div>
              <p  *ngIf="accessToken !== ''" style="margin-bottom: 0px;
              margin-left: 10px;
              font-size: 11px;
              margin-top: -19px;
          ">{{currentuserName}}</p>
            </li>   
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
