import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-net-sales',
  templateUrl: './net-sales.component.html',
  styleUrls: ['./net-sales.component.scss']
})
export class NetSalesComponent implements OnInit {

  @Input() netsaleschangemethod: Event;
  constructor() {
    // console.log(this.netsaleschangemethod);
  }

  ngOnInit() {
    // console.log(this.netsaleschangemethod);
  }

}
