import { Component, OnInit, ElementRef, ViewChild, Injector } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
 
  userEmailID =  "";
  packageTime = "";
  PackageProduct = "";

  constructor() {
    this.userEmailID = localStorage.getItem("userEmailID");
    this.packageTime =  localStorage.getItem("packageTime");
    this.PackageProduct = localStorage.getItem('PackageProduct');
  }

  ngOnInit(): void {
    this.userEmailID = localStorage.getItem("userEmailID");
    this.packageTime =  localStorage.getItem("packageTime");
    this.PackageProduct = localStorage.getItem('PackageProduct');
  }

}
