import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ClientModel } from '../model/clientModel';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";
import { elimnationRecords } from 'src/app/model/EliminationRecords.model';
@Injectable({
    providedIn: 'root'
})
export class ConsolidationService {

    constructor(private http: HttpClient) { }

    getElliminationRecords(forecastNodeid): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/Consolidation/GetEliminationRecords/" + forecastNodeid, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }

    getGoodwillParameters(forecastNodeId): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/Consolidation/GetGoodwillParameter/" + forecastNodeId, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }

    generateGoodwill(parameterModel) {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.url + "/api/Consolidation/GenerateGoodwill", parameterModel, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }

    elimnateRecords(elimnationRecordsList): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        // console.log("elimnateRecords", elimnationRecordsList);
        return this.http.post(environment.url + "/api/Consolidation/EliminateRecords", elimnationRecordsList, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }
    getConsolidationSettings(forecastNodeid): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/Consolidation/GetConsolidationSettings/" + forecastNodeid, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }
    saveConsolidationSettings(consolidationsettings): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.url + "/api/Consolidation/SaveConsolidationSettings", consolidationsettings, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }
    GetForecastsSelected(forecastNodeid): Observable<any> {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/Consolidation/GetForecastsSelected/" + forecastNodeid, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }
}