import { Injectable } from '@angular/core';
import { Userpermission } from 'src/app/model/userpermission';
import { ClientsService } from 'src/app/service/clients.service';
import { ReportService } from 'src/app/service/report.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private ClientsService: ClientsService) {

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private userPermisison: Userpermission

  checkPermission(featurekey: string, currentValue: string): boolean {
    this.userPermisison = JSON.parse(localStorage.getItem('UserPermission'));
    //console.log("checkPermission", this.userPermisison, featurekey)
    if (!this.userPermisison) {
      let CurrentUserID = localStorage.getItem('CurrentUserID');
      this.ClientsService.getUserPermission(CurrentUserID).subscribe(
        (response: Userpermission) => {
          this.userPermisison = response;

        }
      )
    }

    if (!!this.userPermisison) {
      if (featurekey == "expiryDate") {
        const licenseDate = new Date(this.userPermisison.expiryDate)
        if (licenseDate >= new Date()) {
          return true;
        }
      }
      if (this.userPermisison.productID == 'F5WebDemo') {
        // console.log("demo")
        return true;
      }
      else {
        if (this.userPermisison.featureList != null) {
          const perm = this.userPermisison.featureList.find(f => f.featureKey === featurekey);
          if (!!perm) {
            if (featurekey == "NumberOfForecast" || featurekey == "NumberOfConsolidations" || featurekey == "NETWORK" || featurekey == "NoOfUsers" || featurekey == "NumberOfClients") {
              if (Number(perm.featureValue) > Number(currentValue)) {
                return true;
              }
            } else {
              return (perm.featureValue.toLocaleLowerCase() === "true");
            }
          }
        }

      }

    }
    return false;
  }
}
