<!-- <app-header></app-header> -->
<div class="sample-column" *ngIf="displayXeroTree">
    <div style="width: 100%; display: flex;">
    </div>
    <div class="card-wrapper">
        <igx-content>
            <div class="col-sm-12" style="padding: 0px;margin: 0px;height: 800px;background: rgb(174 172 172 / 15%);">
                <div class="tab-content clearfix">
                    <div class="tab-pane active">
                        <div class="col-sm-12 settings">
                            <div class="col-sm-12">
                                <div class="col-sm-12">
                                    <h2 class="headingtitle">Import Integration Data</h2>
                                </div>
                                <div class="col-sm-12" style="margin: 0 0 24px 0;">
                                    <div class="bs-example col-sm-12" style="height: 709px;margin: 6px 0 0 -27px;border: 2px solid;">
                                        <ul class="nav nav-tabs" style="margin: 1px 3px 16px 11px">
                                            <li class="nav-item" 
                                                *ngFor="let result of IntegrationDetails;  let i=index" (click)="ChangePeriodType(result, i)" >
                                                    <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}" data-toggle="tab">{{result.longLable}}</a>
                                                </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="col-sm-12">
                                                <div class="XerotreeMenu" *ngIf="displayXeroTree" >
                                                    <div class="treeMenu"><tree-root #tree [state]="state"  [options]="options"  [focused]="true" [nodes]="nodes" (initialized)="onTreeLoad()" 
                                                        (updateData)="onUpdateData()">
                                                        <ng-template #treeNodeTemplate let-node let-index="index">
                                                            <span class="col-sm-12"
                                                            [ngClass]="{'section': node.data.nodeType >= 30000 && node.data.nodeType <= 30017}"
                                                            [attr.draggable]="true"
                                                        [attr.nodeType]="node.data.nodeType"
                                                        [attr.aria-grabbed]="true"
                                                        [class.draggable]="true" 
                                                        [class]="node.data.label" id="{{node.data.id}}" [class.title]="true">
                                                                <span class="col-sm-3">
                                                                    <span id="{{node.data.id}}-treemenu" style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.label }}</span>
                                                                </span>
                                                                <span class="col-sm-3" 
                                                                    [ngClass]="{'DeActive disabled': node.data.validSectionFolderTypeVM.length>0}">
                                                                    <select class="from-control" (change)="ChangeValidSectionFolderType(node.data, $event)">
                                                                        <option *ngFor="let result of node.data.validSectionFolderTypeVM; let i=index" value="{{result.nodeType}}">{{result.englishLabel}}</option>
                                                                    </select>
                                                                </span>
                                                                <span class="col-sm-3" [ngClass]="{'section': node.data.nodeType >= 30000 && node.data.nodeType <= 30017 , 
                                                                    'DeActive disabled': node.data.validDepreciationRecordVM.length>0 }">
                                                                    <!-- <select class="from-control {{node.data.validDepreciationRecordVM.length}}" id="{{node.data.label}}" (click)="showXeroRecord(node.data.label)" 
                                                                    [disabled]="node.data.validDepreciationRecordVM.length == 0"> -->
                                                                    <!-- <select class="from-control {{node.data.validDepreciationRecordVM.length}}" id="{{node.data.label}}" 
                                                                    [disabled]="node.data.validDepreciationRecordVM.length == 0">
                                                                        <option class="anchor"></option>
                                                                        <option *ngFor="let result of node.data.validDepreciationRecordVM; let i=index" value="{{result.nodeType}}">
                                                                            <label><input type="checkbox" class="col-sm-2" (change)="ChangeValidDepreciationRecordType(node.data, result, $event)" /> {{result.name}} </label>
                                                                            </option>
                                                                    </select> -->
                                                                    <igx-combo class="combo" [itemsMaxHeight]="250"
                                                                        [data]="node.data.validDepreciationRecordVM" [displayKey]="'name'" [valueKey]="'name'"
                                                                        (selectionChanging)="handleChange($event, node.data)"
                                                                        placeholder="" searchPlaceholder="Search...">
                                                                    </igx-combo>
                                                                    <!-- <div class="hide" id="xerorecord-{{node.data.label}}" style="z-index: 99999;height: 114px !important;overflow: scroll; ">
                                                                        <div *ngFor="let result of node.data.validDepreciationRecordVM; let i=index" value="{{result.nodeType}}">
                                                                            <input type="checkbox" class="col-sm-2" (change)="ChangeValidDepreciationRecordType(node.data, result, $event)" /> {{result.name}}</div>
                                                                    </div> -->
                                                                </span>
                                                            </span>
                                                      </ng-template>
                                                        </tree-root></div>   
                                                </div>
                                                <div class="importButton">
                                                    <button class="btn OkSaveButtonColor col-sm-1" style="padding: 10px; font-size: 16px;" (click)="SaveImportData()">Import Data</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </igx-content>
    </div>
</div>

<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true">
<p style="color: white" > Import Data loading </p>
</ngx-spinner> -->

<!-- <div class="col-sm-12 importIntegrationData">
    <div class="col-sm-3">
        <input type="checkbox" class="col-sm-1"/>Include Tracking Codes  
    </div>
    <div class="col-sm-3">
        <input type="checkbox" class="col-sm-1"/>Include Archived Accounts
    </div>
    <div class="col-sm-3">
        <input type="checkbox" class="col-sm-1"/>Include All Accounts
    </div>
    <div class="col-sm-3">
        <button class="btn btn-primary" (click)="importtreedata()">Import</button>
    </div>
</div>


<div class="bs-example col-sm-12" style="height: 490px; margin: 6px 0 0 0;">
    <ul class="nav nav-tabs" style="margin: 1px 3px 16px 11px">
        <li class="nav-item" 
            *ngFor="let result of IntegrationDetails;  let i=index" (click)="ChangePeriodType(result, i)" >
                <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}" data-toggle="tab">{{result.name}}</a>
            </li>
    </ul>
    <div class="tab-content">
        <div class="col-sm-12">
            <div class="XerotreeMenu" *ngIf="displayXeroTree" >
                <div class="treeMenu"><tree-root #tree [state]="state"  [options]="options"  [focused]="true" [nodes]="nodes" (initialized)="onTreeLoad()" 
                    (updateData)="onUpdateData()">
                    <ng-template #treeNodeTemplate let-node let-index="index">
                        <span class="col-sm-12"
                        [attr.draggable]="true"
                    [attr.nodeType]="node.data.nodeType"
                    [attr.aria-grabbed]="true"
                    [class.draggable]="true" 
                    [class]="node.data.label" id="{{node.data.id}}" [class.title]="true">
                            <span class="col-sm-3">
                                <span id="{{node.data.id}}-treemenu" style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.label }}</span>
                            </span>
                            <span class="col-sm-3">
                                <select class="from-control" (change)="ChangeValidSectionFolderType(node.data, $event)">
                                    <option *ngFor="let result of node.data.validSectionFolderTypeVM; let i=index" value="{{result.nodeType}}">{{result.name}}</option>
                                </select>
                            </span>
                            <span class="col-sm-3">
                                <select class="from-control {{node.data.validDepreciationRecordVM.length}}" id="{{node.data.label}}" (click)="showXeroRecord(node.data.label)" 
                                [disabled]="node.data.validDepreciationRecordVM.length == 0">
                                </select>
                                <div class="hide" id="xerorecord-{{node.data.label}}" style="z-index: 99999;height: 114px !important;overflow: scroll; ">
                                    <div *ngFor="let result of node.data.validDepreciationRecordVM; let i=index" value="{{result.nodeType}}">
                                        <input type="checkbox" class="col-sm-2"/> {{result.name}}</div>
                                </div>
                            </span>
                        </span>
                  </ng-template>
                    </tree-root></div>   
            </div>
            <div class="importButton">
                <button class="btn btn-primary col-sm-4" (click)="SaveImportData()">Import Data</button>
            </div>
        </div>
    </div>
</div> -->
