export class sectionTreeModel {
    name: String
    nodeType: string
    nodeID: string;
    isFolderAllowed: String
    parentNodeid: string
    IsModified: boolean;
    IsNewlyAdded: boolean
    isSection: string;
    CurrentNodeid: string;
    constructor() {
        this.name = null;
        this.nodeType = null;
        this.nodeID = null;
        this.isFolderAllowed = null;
        this.IsModified = false;
        this.IsNewlyAdded = false;
        this.isSection = null;
        this.parentNodeid = null;
        this.CurrentNodeid = null;
    }
}

export class sectionModel {
    sectionTreeModels: sectionTreeModel[];
    deletedRecords: sectionTreeModel[];

    constructor() {
        this.sectionTreeModels = [];
        this.deletedRecords = [];
    }
}