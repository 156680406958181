<igx-content>
  <div class="">
    <h3>Forecast Extent / Amend Data</h3>
  </div>
  <div class="bs-example" style="height: 675px;margin-top: 2%;"
    *ngIf="NavitemSubmenu.length>0 && ForecastExtentCollection!='' ">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let result of NavitemSubmenu;  let i=index">
        <a href="#{{result.type}}" class="nav-link"
          [ngClass]="{'active':i==0, 'disabled':i==1 && !ForecastExtentCollection.canAmend}"
          data-toggle="tab">{{result.name}}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade testingData" *ngFor="let Resultsbody of NavitemSubmenu; let data=index;"
        id="{{Resultsbody.type}}" [ngClass]="{'active':data == '0'}">
        <div *ngIf="Resultsbody.type=='Extent'">
          <div *ngIf="ForecastExtentCollection!=''" class="col-sm-5">
            <div class="row">
              <div class="col-sm-5">
                <label>Number of Years</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="number" max="15" min="1" maxlength="10" class="col"
                  value="{{ForecastExtentCollection.numberOfYears}}" id="numberofyears" (blur)="SaveNumber($event)">
              </div>
              <div class="col-sm-5">

                <!-- <div  class="button-sample row col" style="float: right;" *ngIf="ForecastExtentCollection.canDeleteFirstYear">
                        <button class="deleteCancelButtonColor col-sm-10" (click)="DeleteFirstYear()" style="font-size: 16px; padding: 0px;margin: -10px 0 0 0;">Delete First Year</button>
                      </div> -->
                <!-- <div  class="button-sample row col" style="float: right;" *ngIf="!ForecastExtentCollection.canDeleteFirstYear">
                        <button class="CancelButtonColor col-sm-10" style="font-size: 16px; padding: 0px;margin: -10px 0 0 0;">Delete First Year</button>
                      </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Start Month</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" style="font-size: 13px;" class="col form-control" disabled="true"
                  value="{{ForecastExtentCollection.validPeriodsVM[0].label}}">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Amend With Data From</label>
              </div>
              <div class="col-sm-4" style="margin: 0px;padding: 0px;">
                <select class="form-control" (change)="SaveValidAmendMonth($event)" style="width: 68%;font-size: 13px;">
                  <option value="{{result.value}}"
                    [selected]="ForecastExtentCollection.amendDataFromVM == result.value "
                    *ngFor="let result of ForecastExtentCollection.validAmendDataFromVMs">{{result.name}}</option>
                </select>
              </div>
              <div class="col-sm-5">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5"><label>Amend (100% no change)</label></div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Sales Price to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" (blur)="ChangeSalesPriceTo($event)" id="ExtentSalesPriceTo"
                  value="{{ForecastExtentCollection.amendSalesPriceTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Sales Value to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" (blur)="ChangeSalesVolumeTo($event)" id="ExtentSalesValueTo"
                  value="{{ForecastExtentCollection.amendSalesVolumeTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Costs to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="ExtentCostTo" (blur)="ChangeCostsTo($event)"
                  value="{{ForecastExtentCollection.amendCostsTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Overheads to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="ExtentOverheadsTo" (blur)="ChangeOverheadsTo($event)"
                  value="{{ForecastExtentCollection.amendOverheadsTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Other Income to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="ExtentOtherIncomeTo" (blur)="ChangeOtherIncomeTo($event)"
                  value="{{ForecastExtentCollection.amendOtherIncomeTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>List Wages to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="ExtentListWagesTo" (blur)="ChangeListWagesTo($event)"
                  value="{{ForecastExtentCollection.amendListWagesTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="button-sample row col" style="float: right;" *ngIf="SelectedMenuName != 'Extent/Amend'">
                <button class="btn OkSaveButtonColor col-sm-3" style="font-size: 16px;margin: 0px 7px 0 0;"
                  (click)="extentSave()">Save</button>
                <button class=" btn deleteCancelButtonColor col-sm-3" style="font-size: 16px;"
                  (click)="OnClickCancel()">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="Resultsbody.type=='Amend'">

          <div *ngIf="ForecastExtentCollection!=''" class="col-sm-5"
            style="border: 2px solid #c1c1c1; border-top: 0px;">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8" style="margin: 0px; padding: 7px;border: 1px solid;">
                <input type="radio" checked>{{ForecastExtentCollection.validObjects}}
              </div>

            </div>
            <div class="row">
              <div class="col-sm-5">
                <label class="col-sm-3" style="padding: 0px;">From</label>
                <div class="col-sm-8">
                  <select class="form-control" id="AmendFromPeriod" style="font-size: 13px;">
                    <option value="{{Result.nodeId}}"
                      *ngFor="let Result of ForecastExtentCollection.validPeriodsVM; let I=index"
                      [selected]="I==ForecastExtentCollection.amendFromPeriod">{{Result.label}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-5">
                <label class="col-sm-3" style="padding: 0px;">To</label>
                <div class="col-sm-8">
                  <select class="form-control" id="AmendToPeriod" style="font-size: 13px;">
                    <option value="{{Result.nodeId}}"
                      *ngFor="let Result of ForecastExtentCollection.validPeriodsVM; let I=index"
                      [selected]="I==ForecastExtentCollection.amendToPeriod">{{Result.label}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Amend With Data From</label>
              </div>
              <div class="col-sm-3" style="margin: 0px;padding: 0px;">
                <select class="form-control" (change)="SaveValidAmendMonth($event)" style="font-size: 13px;">
                  <option value="{{result.value}}" [selected]="ForecastExtentCollection.amendDataFromVM == result.value"
                    *ngFor="let result of ForecastExtentCollection.validAmendDataFromVMs">{{result.name}}</option>
                </select>
              </div>
              <div class="col-sm-5">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5"><label>Amend (100% no change)</label></div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Sales Price to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="AmendSalesPriceTo" (blur)="ChangeSalesPriceTo($event)"
                  value="{{ForecastExtentCollection.amendSalesPriceTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Sales Value to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="AmendSalesValuesTo" (blur)="ChangeSalesVolumeTo($event)"
                  value="{{ForecastExtentCollection.amendSalesVolumeTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Costs to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="AmendCostTo" (blur)="ChangeCostsTo($event)"
                  value="{{ForecastExtentCollection.amendCostsTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Overheads to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="AmendOverheadsTo" (blur)="ChangeOverheadsTo($event)"
                  value="{{ForecastExtentCollection.amendOverheadsTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>Other Income to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                  (keyup)="ValidateValue($event)" id="AmendOtherIncomeTo" (blur)="ChangeOtherIncomeTo($event)"
                  value="{{ForecastExtentCollection.amendOtherIncomeTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <label>List Wages to</label>
              </div>
              <div class="col-sm-2" style="margin: 0px;padding: 0px;">
                <input type="text" class="col" numeric decimals="3" (blur)="ChangeListWagesTo($event)"
                  value="{{ForecastExtentCollection.amendListWagesTo}}">
              </div>
              <div class="col-sm-5">
                %
              </div>
            </div>
            <div class="row">
              <div class="button-sample row col" style="float: right;" *ngIf="SelectedMenuName != 'Extent/Amend'">
                <button class="btn OkSaveButtonColor col-sm-3" style="font-size: 16px;margin: 0px 7px 0 0;"
                  (click)="AmendSave()">Save</button>
                <button class="btn deleteCancelButtonColor col-sm-3" style="font-size: 16px;"
                  (click)="OnClickCancel()">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</igx-content>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{loaderMessage}} </p>
</ngx-spinner>