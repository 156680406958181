import { Component, OnInit } from '@angular/core';
import { ParameterService } from '../../../service/SettingServices/ParameterSetting/parameter.service';
import { timeStamp } from 'console';
import { TreeviewService } from '../../../service/treeview.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CurrencyServiceService } from 'src/app/service/SettingServices/CurrencyService/currency-service.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pameter-default',
  templateUrl: './pameter-default.component.html',
  styleUrls: ['./pameter-default.component.scss']
})
export class PameterDefaultComponent implements OnInit {

  ForecastNodeId: any;
  ParameterRecieptsCollections: any = [];
  ParameterRecieptsValueList: any = [];
  ParameterPaymentCollections: any = [];
  ParameterPaymentValueList: any = [];
  ParameterAddToStockCollections: any = [];
  ParameterAddToStockValueList: any = [];
  ParameterDepreciationCollections: any = [];
  ForecastStartDate: any;
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  constructor(
    private ParameterService: ParameterService,
    private TreeviewServices: TreeviewService,
    private SpinnerService: NgxSpinnerService,
    private CurrencyServiceServices: CurrencyServiceService, private router: Router) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');
  }

  getRecipetsCollection() {
    this.ParameterRecieptsCollections = [];
    this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    if (ForecastNodeId == null) {
      return;
    }
    this.ParameterService.getParameterReciepts(ForecastNodeId).subscribe(
      (ParameterReciepts => {
        this.SpinnerService.hide()
        this.ParameterRecieptsCollections = ParameterReciepts;
        this.ParameterRecieptsValueList = this.ParameterRecieptsCollections.parameterDefaultsReceiptsVM;
        this.ForecastStartDate = this.ParameterRecieptsCollections.forecastStartDate;
      })
    )
  }

  getPeriodCollection(ForecastNodeId) {
    this.PeriodTabViewCollection = [];
    this.DisplayMonthCollection = [];
    this.SpinnerService.show();
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      (response => {
        this.SpinnerService.hide();
        let PeriodViewResponse: any = response;
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }

  /** 
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
  }

  getgetParameterPaymentCollection() {
    this.ParameterPaymentCollections = [];
    this.SpinnerService.show()
    this.ParameterService.getParameterPayment(this.ForecastNodeId).subscribe(
      (ParameterPayment => {
        this.SpinnerService.hide()
        this.ParameterPaymentCollections = ParameterPayment;
        this.ParameterPaymentValueList = this.ParameterPaymentCollections.parameterDefaultsPaymentsVM;

      })
    )
  }
  getParameterAddToStockCollection() {
    this.ParameterAddToStockCollections = [];
    this.SpinnerService.show();
    this.ParameterService.getParameterAddToStock(this.ForecastNodeId).subscribe(
      (ParameterAddToStock => {
        this.SpinnerService.hide();
        this.ParameterAddToStockCollections = ParameterAddToStock;
        this.ParameterAddToStockValueList = this.ParameterAddToStockCollections.parameterDefaultsAddToStockVM;

      })
    )
  }

  getParameterDepreciationCollection() {
    this.SpinnerService.show();
    this.ParameterService.getParameterDepreciation(this.ForecastNodeId).subscribe(
      (ParameterAddToStock => {
        this.SpinnerService.hide();
        this.ParameterDepreciationCollections = ParameterAddToStock;
      })
    )
  }

  ngOnInit() {
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.getPeriodCollection(ForecastNodeId);
    this.getRecipetsCollection();
    this.getgetParameterPaymentCollection();
    this.getParameterAddToStockCollection();
    this.getParameterDepreciationCollection();
  }

  receipts_ValidDerivationTypesVM(Results) {
    let ForecastId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.ParameterService.ChangesRecipetsDerrivation(ForecastId, Results.value).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.getRecipetsCollection();
      })
    )
  }

  Payment_ValidDerivationTypesVM(Results) {
    let ForecastId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.ParameterService.ChangesPaymentDerrivation(ForecastId, Results.value).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.getgetParameterPaymentCollection();
      })
    )
  }

  ParameterDepreciationDefualtPercentage($event) {
    let Value = $event.target.value;
    if (Value == "") {
      return;
    }
    let ForecastId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.ParameterService.ParameterDepreciationDefualtPerameterPercentage(ForecastId, Value).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  addtostock_ValidDerivationTypesVM(Results) {
    let ForecastId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.ParameterService.ChangesAddToStockDerrivation(ForecastId, Results.value).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.getParameterAddToStockCollection();
      })
    )
  }
  depreciation_ValidDerivationTypesVM(Results) {
    let ForecastId = localStorage.getItem('ForecastId');
    this.ParameterService.ChangesdepreciationDerrivation(ForecastId, Results.value).subscribe(
      (response => {

      })
    )
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
  /**
 * 
 * @param NodeId 
 * @param $event 
 */
  SetAllList(NodeId, $event, parameter, type) {
    let setAllValue = $event.target.value;
    if (setAllValue == "") {
      return;
    }
    this.ParameterService.SetAll(NodeId, setAllValue).subscribe(
      (response => {
        if (parameter == 'Payment') {
          this.getgetParameterPaymentCollection();
        }
        if (parameter == 'Reciepts') {
          this.getRecipetsCollection();
        }
        if (parameter == 'AddToStock') {
          this.getParameterAddToStockCollection();
        }
      })
    )
  }
  SettingSave() {
    this.ngOnInit();
  }
  SettingClose() {
    this.router.navigateByUrl('/forecast');
  }
  MonthList(NodeId, Index, $event, parameter, type, budgetdata) {
    // console.log(budgetdata);
    let callApi = 'false';
    let defualtValue = budgetdata.bonusCommisionValuesArray[Index];
    let updatedValue = $event.target.value;
    if (updatedValue != "") {
      let value = updatedValue;
      let convertInt = parseInt(value);
      if (this.check(value.toString()) == true) {
        let data = value.replace(/\(/g, '-').replace(/\)/g, '');
        convertInt = parseInt(data);
      } else {
      }
      if (defualtValue != convertInt) {
        callApi = 'true';
      }
    } else {
      return;
    }
    if (callApi == 'true') {
    } else {
      return;
    }
    var setAllValue = parseFloat(parseInt($event.target.value).toFixed(2));
    if (type == 80039) {
      let value = $event.target.value / 100;
      setAllValue = parseFloat(value.toFixed(2));
    }
    this.SpinnerService.show();
    this.ParameterService.SaveValueListByMonth(NodeId, Index, setAllValue).subscribe(
      (response => {
        this.SpinnerService.hide();
        if (parameter == 'Payment') {
          this.getgetParameterPaymentCollection();
        }
        if (parameter == 'Reciepts') {
          this.getRecipetsCollection();
        }
        if (parameter == 'AddToStock') {
          this.getParameterAddToStockCollection();
        }
      })
    )
  }
  DepreciationDestination_ValidDerivationTypesVM(result) {
    this.ParameterService.ChangesdepreciationDestinationDerrivation(this.ForecastNodeId, result.nodeId).subscribe(
      (response => {

      })
    )
  }


} 
