import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ForecastExtentService {

  constructor(private http:HttpClient) { }

  getForecastExtentCollection(ForecastNodeId):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/getForecastExtentCollection/"+ForecastNodeId).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }

  UpdateAmendDetails(record) : Observable<any[]>{
    let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
    let url = environment.url+"/api/ForecastExtent/SaveAmendData";
    return this.http.post(url, record, {headers:reqHeaders})
       .pipe(map(
         (response: any) =>
         {
           return response;
         }
       ));
  }

  SaveNumber(ForecastNodeId, Number):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/SaveNumber/"+ForecastNodeId+"/"+Number).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }

  SaveValidAmendMonth(ForecastNodeId, Value):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/SaveValidAmendMonth/"+ForecastNodeId+"/"+Value).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }
  DeleteFirstYear(ForecastNodeId):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/DeleteFirstYear/"+ForecastNodeId).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }
  AmendSaveData(ForecastNodeId,AmendFromPeriod,AmendToPeriod):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/AmendSaveData/"+ForecastNodeId+"/"+AmendFromPeriod+"/"+AmendToPeriod).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }
  ExtentSaveData(ForecastNodeId,_numberOfYears):Observable<any[]>{
    return this.http.get(environment.apiUrl+"/api/ForecastExtent/ExtentSaveData/"+ForecastNodeId+"/"+_numberOfYears).pipe(
      map(
        ((response:any) =>{
          return response;
        })
    ))
  }
}
