<div *ngIf="displayMemoRecord==true && !isConsolidation">
    <div *ngIf="RecordOptionlist" class="col-sm-12">
        <div class="col-sm-3" *ngFor="let result of RecordOptionlist">
            <input type="checkbox" [checked]="result.isSelected" (change)="RecordOptionChanged(result.nodeId, $event , result)"
                class="recordOptionCheckbox" />{{result.localizelable}}
        </div>
    </div>
</div>
<div *ngIf="displayFixedAsset==true && !isConsolidation">
    <app-fixedassetrecord [RecordOptionlistCollection]="RecordOptionlist"></app-fixedassetrecord>
</div>
<div *ngIf="displayOtherfixedAssestOption==true && !isConsolidation">
    <app-otherfixedassestoption [RecordOptionlistCollection]="RecordOptionlist"></app-otherfixedassestoption>
</div>
<div *ngIf="displayAccruedPrepaidCost==true && !isConsolidation">
    <app-accrued-prepaid-cost-record-options [RecordOptionlistCollection]="RecordOptionlist">
    </app-accrued-prepaid-cost-record-options>
</div>

<div id="finaacingRecordDisplay" class="hide" *ngIf="finacingRecord">
    <app-finacingrecord-option [RecordOptionlistCollection]="RecordOptionlist">
    </app-finacingrecord-option>
</div>
