import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  private headers: Headers;
  constructor( private http: HttpClient) { }

   /**
    * Function For get Preference Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getPreferenceCollections(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/Preferences/PreferencesCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      SetmessageSourceVM(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetmessageSourceVM/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      Setmessage(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/Setmessage/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SetHideZeroRows(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetHideZeroRows/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SetExportDescriptionWithPnl(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetExportDescriptionWithPnl/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SetAllocateGainsLossesToDepreciationDest(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetAllocateGainsLossesToDepreciationDest/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SelectedShowLoanBalancesVM(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SelectedShowLoanBalancesVM/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SetSelectedStockOnHandCostPerUnitMonthTimingVM(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetSelectedStockOnHandCostPerUnitMonthTimingVM/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SelectedWeekendPaymentDayVM(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SelectedWeekendPaymentDayVM/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
      SetSeparateCashReceiptsBySections(ForecastNodeId,Value) : Observable<any[]>{
        return this.http.put(environment.url+"/api/Preferences/SetSeparateCashReceiptsBySections/"+ForecastNodeId+"/"+Value, { responseType: "json" })
           .pipe(map(
             (response : any) =>
             {
               return response;
             }
           ));
      }
}
