<app-header></app-header>
<div class="GoodwillHeader">
    <div style="width:90%; margin-top: 20px;">
        <h2 class="HeaderColor">Goodwill</h2>
    </div>
    <div id="GoodwillRecords" class="tab-pane fade in show">
        <div class="row" style="height: 624px;overflow: auto; min-height: 620px;">
            <div class="left-sidebar col-sm-12">
                <table id="table" class="table" style="width: 100%;">
                    <!-- <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.profitAndLoss'>
                            </app-enter-actual-tree> -->
                    <div style="width: 100%; height: 200px;">
                        <igx-grid #grid1 [igxPreventDocumentScroll]="true" [data]="goodwillRecords" height="600px"
                            displayDensity="comfortable" [filterMode]="'excelStyleFilter'"
                            [allowAdvancedFiltering]="true">
                            <igx-paginator [perPage]="10"></igx-paginator>
                            <igx-column field="parentForecast" header="Parent Forecast" width="15%"
                                [editable]="false"></igx-column>
                            <igx-column field="recordName" header="Record Name" width="15%"
                                [editable]="false"></igx-column>
                            <igx-column field="parentAssetValue" header="Asset Value" width="10%"
                                [editable]="false"></igx-column>

                            <igx-column dataType="boolean" field="isIncluded" header="Is Included" width="10%"
                                [editable]="true">
                                <ng-template igxCell let-cell="cell">
                                    <input type="checkbox" [ngModel]="cell.value" />
                                </ng-template>
                            </igx-column>

                            <igx-column field="recordNodeId" header="RecordNodeid" [hidden]="true"></igx-column>
                            <igx-column field="parentForecastNodeID" header="Source Forecasts"
                                [hidden]="true"></igx-column>
                            <igx-column field="selectedForecast.forecastName" selectable="isIncluded"
                                header="Subsidary Forecast" width="15%" [editable]="true">
                                <ng-template igxCellEditor let-cell="cell" let-value>
                                    <igx-select class="cell-select" [(ngModel)]="val" [igxFocus]="true">
                                        <igx-select-item *ngFor="let forecast of forecastList"
                                            (click)="selectforecast(forecast, cell.row.rowData)"
                                            [value]=" forecast.forecastName">
                                            {{ forecast.forecastName }}
                                        </igx-select-item>
                                    </igx-select>
                                </ng-template>
                            </igx-column>
                            <igx-column field="subAssteValue" header="Asset Value" width="10%"
                                [editable]="false"></igx-column>
                            <igx-column field="goodwillValue" header="Goodwill Value" width="10%"
                                [editable]="false"></igx-column>
                            <!-- <igx-column header="Values" headerClasses="myClass" width="100px" field="valueArray"
                                            sortable="true" [filterable]="false">
                                            <ng-template igxCell let-cell="cell">
                                                <div class="cell__inner">
                                                    <div *ngFor="let a of cell.value">{{ a }}</div>
                                                </div>
                                            </ng-template>
                                        </igx-column> -->

                        </igx-grid>
                    </div>
                </table>
            </div>
        </div>
    </div>
    <div class="GoodwillButtondiv">
        <button class="GoodwillButton OkSaveButtonColor" (click)="saveGoodwill()">Goodwill</button>
        <button class="CancelButton deleteCancelButtonColor" (click)="CancelGoodwill()">Cancel</button>

    </div>
</div>