import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-settingbase',
  templateUrl: './settingbase.component.html',
  styleUrls: ['./settingbase.component.scss']
})
export class SettingbaseComponent {

  @Input() SettingMenuHeaderEvent: any;
  SelectedMenuName = '';
  constructor(private router:Router) { }

  ngOnInit() {
    let integrationType = localStorage.getItem("Integration");
  }

  ngOnChanges() {
    this.SelectedMenuName = this.SettingMenuHeaderEvent;
    if(this.SelectedMenuName == 'Home'){
      this.router.navigate(['forecast']);
    }
  }

  SettingSave(){
    this.router.navigate(['forecast']);
  }
}
