import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MenuService } from '../../service/menu.service';
import { Menu } from '../../model/menu';
import * as $ from 'jquery';
import { TreemenuComponent } from 'src/app/common/treemenu/treemenu.component';
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';
import {
  ConnectedPositioningStrategy,
  IgxDropDownComponent,
  IgxInputGroupComponent
} from '@infragistics/igniteui-angular';


@Component({
  selector: 'app-left-sidebar-menu',
  templateUrl: './left-sidebar-menu.component.html',
  styleUrls: ['./left-sidebar-menu.component.scss']
})
export class LeftSidebarMenuComponent{

  @Input() getSelectedNodeIdDetail: any;
  SelectedTreenodeEvent = '';
  getSelectedNodeDetailsFromService: any;
  DeteleSelectedNode: any
  constructor(public menuServices: MenuService,
              private forecasttabsComponent:ForecasttabsComponent,
              public treemenuComponent:TreemenuComponent) { }

  @ViewChild(IgxDropDownComponent, { static: true }) public igxDropDown: IgxDropDownComponent;
  @ViewChild('inputGroup', { read: IgxInputGroupComponent, static: true }) public inputGroup: IgxInputGroupComponent;

  public Department: { field: string }[] = [
    { field: 'Add Department' },
    { field: 'Delete Department' },
  ];

  public openDropDown() {
    if (this.igxDropDown.collapsed) {
      this.igxDropDown.open({
        target: this.inputGroup.element.nativeElement,
        modal: false,
        positionStrategy: new ConnectedPositioningStrategy()
      });
    }
  }
  public openFolderDropDown() {
    if (this.igxDropDown.collapsed) {
      this.igxDropDown.open({
        target: this.inputGroup.element.nativeElement,
        modal: false,
        positionStrategy: new ConnectedPositioningStrategy()
      });
    }
  }


  public records: { field: string }[] = [
    { field: 'Add Record' },
    { field: 'Delete Record' },
  ];

  ngOnInit() {
  }

  async ngOnChanges() {
    this.getSelectedNodeDetailsFromService = '';
    this.SelectedTreenodeEvent = '';
    this.SelectedTreenodeEvent = this.getSelectedNodeIdDetail;
    if (this.SelectedTreenodeEvent != null || this.SelectedTreenodeEvent != '') {
      if (this.SelectedTreenodeEvent != undefined) {
        this.getSelectedNodeDetailsFromService = this.SelectedTreenodeEvent;
        //   this.menuServices.getSelectednodeDetails(this.SelectedTreenodeEvent).subscribe((response:Menu[])=>{
        //     let results = response;
        //     console.log(results);
        //     this.getSelectedNodeDetailsFromService = results;
        //     if(this.getSelectedNodeDetailsFromService.canAddDepartment) {
        //     }
        // })
      }
    }
  }

  async AddDepartment(NodeId) {
    let DepartmentAddDetails = {
      "SelectedNodeID": NodeId,
      "NewDepartmentName": "NewSalesDepartment",
      "showGrossProfitPercent": true
    }
    await this.menuServices.SaveAddDepartment(DepartmentAddDetails).subscribe
      ((response: Menu[]) => {
        window.location.reload();
      })
  }

  async DeleteDepartment(NodeId) {
    // console.log(NodeId);
    if (NodeId != null) {
      await this.menuServices.deleteDepartment(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }

  async AddFolder(NodeId) {
    if (NodeId != null) {
      await this.menuServices.addFolder(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }

  async AddSection(NodeId) {
    if (NodeId != null) {
      await this.menuServices.addSection(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }

  async AddRecord(NodeId) {
    if (NodeId != null) {
      await this.menuServices.addRecord(NodeId).subscribe
        ((response: any) => { 
          window.location.reload();
          // this.forecasttabsComponent.ngOnChange();
          // this.forecasttabsComponent.childEventClicked(response);
        })
    }
  }
  async DeleteSelectedNode(NodeId) {
    if (NodeId != null) {
      await this.menuServices.DeleteSeletedNode(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }

  async MoveUpRecord(NodeId) {
    if (NodeId != null) {
      await this.menuServices.MoveUpRecord(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }
  async MoveDownRecord(NodeId) {
    if (NodeId != null) {
      await this.menuServices.MoveDownRecord(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }

}
