import { Component, OnInit, Input } from '@angular/core';
import { HotlinkService } from '../services/hotlink.service';
import { eliminationRecordsModel } from 'src/app/model/EliminationRecords.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-hotlink-data-source-collection',
  templateUrl: './hotlink-data-source-collection.component.html',
  styleUrls: ['./hotlink-data-source-collection.component.scss']
})
export class HotlinkDataSourceCollectionComponent implements OnInit {

  forecastList:any = [];
  memorecordListView:any = [];
  memorecordListResult:any = [];
  columnsArrayPL:any = [];
  @Input() SelectedHotlinkData;
  selectedForecastValue = "";
  constructor(private hotlinkService:HotlinkService, private SpinnerService:NgxSpinnerService) { }

  ngOnInit() {
    let ForecastId = localStorage.getItem("ForecastId");
    let recordNodeID = localStorage.getItem("recordNodeId");
    if(ForecastId!=null && recordNodeID!=null){ 
      if(this.SelectedHotlinkData!=null){ 
        this.SpinnerService.show();
        this.hotlinkService.getForecastListCollection(ForecastId,recordNodeID).subscribe(
          ((response)=>{ 
            this.SpinnerService.hide();
            this.forecastList = response;
            this.forecastList.forEach(element => {
              if(element.name == this.SelectedHotlinkData.fileName){
                localStorage.setItem("referanceForecast", element.code);
                this.selectedForecastValue = element.name;
                if(this.SelectedHotlinkData.fileName!=null){ 
                  let selectedHotlinkToForecast = this.SelectedHotlinkData.hotLinkDetailsNodeId;
                  this.SpinnerService.show();
                  this.hotlinkService.getForecastMemoRecordCollection(parseInt(element.code), selectedHotlinkToForecast).subscribe(
                    ((response:any)=>{
                      this.SpinnerService.hide();
                      this.memorecordListView = [];
                      this.memorecordListView = response;  
                      this.memorecordListView.hotLinkMemoRecords.forEach(memoelement => {
                        if(memoelement.forecastRecordName == this.SelectedHotlinkData.folderPath){
                          memoelement.isCheckboxcheckedCode = true;
                        }
                      });
                      this.memorecordListView.hotLinkMemoRecords.forEach(element => { 
                        if (this.columnsArrayPL.length == 0) {
                          let count = 0;
                          this.memorecordListView.monthDisplay.forEach(month => {
                            this.columnsArrayPL.push({ header: month.lable, count: count, type: "number" })
                            count = count + 1;
                          });
                        }
                      });
                    })
                  )
                }
              }
            });
          })
        )
      } else { 
        this.SpinnerService.show();
        this.hotlinkService.getForecastListCollection(ForecastId,recordNodeID).subscribe(
          ((response)=>{
            this.SpinnerService.show();
            this.forecastList = response;
            this.forecastList.forEach((element, index) => {
              if(index==0){
                localStorage.setItem("referanceForecast", element.code);
                this.selectedForecastValue = element.name;
                  let selectedHotlinkToForecast = 0;
                  this.SpinnerService.show();
                  this.hotlinkService.getForecastMemoRecordCollection(parseInt(element.code), selectedHotlinkToForecast).subscribe(
                    ((response:any)=>{
                      this.SpinnerService.hide();
                      this.memorecordListView = [];
                      this.memorecordListView = response;  
                      this.memorecordListView.hotLinkMemoRecords.forEach(memoelement => {
                        // if(memoelement.forecastRecordName == this.SelectedHotlinkData.folderPath){
                        //   memoelement.isCheckboxcheckedCode = true;
                        // }
                      });
                      this.memorecordListView.hotLinkMemoRecords.forEach(element => { 
                        if (this.columnsArrayPL.length == 0) {
                          let count = 0;
                          this.memorecordListView.monthDisplay.forEach(month => {
                            this.columnsArrayPL.push({ header: month.lable, count: count, type: "number" })
                            count = count + 1;
                          });
                        }
                      });
                    })
                  )
              }
            });
          })
        )
      }
      
    }
  }

  ngOnChanges(){
    this.ngOnInit();
  }
  
  ChangeForecast($event){
    let codeId = $event.target.value;
    localStorage.setItem("referanceForecast", codeId);
    if(codeId!=null){ 
      let selectedHotlinkToForecast = 0;
      this.hotlinkService.getForecastMemoRecordCollection(parseInt(codeId), selectedHotlinkToForecast).subscribe(
        ((response:any)=>{
          this.memorecordListView = [];
          this.memorecordListView = response;
          this.memorecordListView.hotLinkMemoRecords.forEach(element => { 
            if (this.columnsArrayPL.length == 0) {
              let count = 0;
              this.memorecordListView.monthDisplay.forEach(month => {
                this.columnsArrayPL.push({ header: month.lable, count: count, type: "number" })
                count = count + 1;
              });
            }
          });
        })
      )
    }
  }
  SelectMemoRecord(ChangedValue,$event){
    let checked = $event.target.checked;
    ChangedValue.isCheckboxcheckedCode = checked;
    localStorage.setItem("hotlinkForecastResult", JSON.stringify(this.memorecordListView));
  }

}
