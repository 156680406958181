import { Interface } from "readline"

export class Countries {
    code: string
    name: string

    constructor(obj?: ICountry) {
        this.code = obj.code || "";
        this.name = obj.name || "";
    }
}

interface ICountry {
    code: string;
    name: string;
}