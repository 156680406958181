<div *ngIf="event!=''">
  <form class="detailsRecord">
    <div class="row" style="height: auto;">
      <div class="col-sm-4" style="margin-top: 1%; height: 150px;">
        <div class="form-group row">
          <label for="name" class="col-sm-3 col-form-label">
            Name
          </label>
          <div class="col-sm-9">
            <input id="name" class="form-control" (blur)="clickgetdata(event.nodeId , $event )" type="text"
              value="{{event.name}}">
          </div>
        </div>
        <div class="form-group row" style="margin-top: 1%;">
          <label for="name" class="col-sm-3 col-form-label">
            Description
          </label>
          <div class="col-sm-9">
            <textarea id="Description" class="form-control" type="text"
              (blur)="DescriptionBulrEvent(event.nodeId , $event )" value=" {{ event.description}}"></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>