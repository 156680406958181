<app-header></app-header>

<div class="">
  <div class="col-sm-2" style="background: rgb(168 168 183 / 27%);height: 92vh;padding: 0 0 0 4px;border-right: 3px solid gray;">
    <div class="">
      <div class="content-wrap" style="background: #e7eaed !important;margin: 0 -3px 0 -12px;border-right: 3px solid gray;">
        <div id="navigation" class="customthem">
            <div class="drawerSetup" style="max-height: 90%;overflow-y: auto; overflow-x:clip;">
              <!-- <span class="reportsMenu">Reports Menu</span> -->
              <div class="row">
                <div class="col-sm-12">
                  <igx-card style="background: white !important;margin: 0 6px 6px 4px;border-radius: 12px; height: 39px;">
                    <div class="col-sm-12">
                      <div class="col-sm-2" style="margin-top: 10px;">Type</div>
                      <div class="col-sm-10" style="margin-top: 3px;">
                        <select name="ForecastBudgetReport"
                          class="form-control {{ForecastReportBudgteNodeId}}"
                          (change)="ForecastbudgteReporChange($event)">
                          <option *ngFor="let result of ForecastbudgetReport"
                            [selected]="ForecastReportBudgteNodeId == result.nodeId" value="{{result.nodeId}}">
                            {{result.englishLabel}}</option>
                        </select>
                      </div>
                    </div>
                    
                  </igx-card>
                 
                </div>
              </div>
              <div *ngFor="let item of NavitemSubmenu">
                <span *appPermission="item.featureKey"
                  [active]="item.name === selected">
                  <igx-card style="background: white !important;margin: 0 6px 6px 4px;border-radius: 12px;" >
                    <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;">{{item.name}}</h4>
                    <div *ngIf="item.type == 'Variance'" class="row" style="margin: 0 0 0 12px;">
                      <div style="margin-top: 3px;font-size: 15px;font-weight: 500;" >Month</div>
                      <div class="" style="margin: 0 0 0 6px;">
                        <select name="variationMonthSelection" class="form-control" style="width: 130px;"
                          (change)="changevarianceMonth($event.target.selectedOptions[0].id)">
                          <option></option>
                          <option *ngFor="let month of VarianceMonthColleciton"
                            [selected]="month.index== VarianceMonthSelectionIndex" [id]="month.index"> {{month.monthDisplay}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class=''>  
                      <div class="row"> 
                        <div *ngFor="let result of item.displaysubmenu" class="col-sm-12 {{result.callback.active}}" >
                          <div *ngIf="result.callback.active=='true'">
                            <div class="col-sm-12" style="padding: 5px 0 5px 0;">
                              <igx-icon style="font-size:20px;" class="col-sm-2">{{result.icon}}</igx-icon>
                                <span *appPermission=" result.featureKey,CurrentValue:0" class="col-sm-10"
                                  (click)="this[RightsidebarmenClickEvent](result.callback.perameter, result)">{{
                                  result.name }} 
                                </span>
                              </div>
                          </div>
                          <div *ngIf="result.callback.active==false">
                            <div class="col-sm-12" style="padding: 5px 0 5px 0;">
                              <igx-icon style="font-size:20px;" class="col-sm-2">{{result.icon}}</igx-icon>
                                <span *appPermission=" result.featureKey,CurrentValue:0" style="color: #a6b3c0;" class="col-sm-10">{{
                                  result.name }} 
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </igx-card>
                </span>
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-10">
    <app-rightsidebar-menu></app-rightsidebar-menu>
    <div class="forecast-tabs">
      <div class="row" style="padding: 0px;margin: -16px 0 0 0px;">
        <div class="col-sm-12" style="padding: 0px;">
          <div class="tab-content clearfix">
            <div class="tab-pane active" id="new-forecast1">
              <div class="report-method-data">
                <div class=" col-sm-12" style="padding: 0px;">
                 
                  <div class="main-contain-report-data-section">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      
                      <li class="nav-item reportNavCollection nav-collection-{{ReportTabActiveindex}} waves-effect waves-light"
                      (click)="navliClick(reportIds.Name, allTabsnameArrayindex)"
                      [ngClass]="{'active': prviousLinkClicked == null && allTabsnameArray.length === allTabsnameArrayindex+1 }"
                        *ngFor="let reportIds of allTabsnameArray; let allTabsnameArrayindex = index; trackBy:trackByFn" >
                        <a class="nav-link reportaNavItemCollection reportaNavItemCollection-{{ReportTabActiveindex}} test-{{allTabsnameArrayindex+1}}"
                          id="{{reportIds.Name}}-tab" data-toggle="tab" style="display: inline-block;"
                          [ngClass]="{'active': prviousLinkClicked == null && allTabsnameArray.length === allTabsnameArrayindex+1 }"
                          href="#{{reportIds.Name}}-{{allTabsnameArrayindex}}" role="tab"
                          aria-controls="{{reportIds.Name}}"> {{reportIds.Label}}
                          <i _ngcontent-foc-c126=""
                            style="color:#ea7704; cursor: pointer;" class="fa fa-remove"
                            (click)="reportCollectionIndexRemove(allTabsnameArrayindex)"></i>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tabscontentclass tab-pane {{mainIndex}}"
                      [ngClass]="{'active': prviousLinkClicked == null && allTabsnameArray.length === mainIndex+1 }"
                        role="tabpanel"
                        id="{{allTabsnameArray[mainIndex].Name}}-{{mainIndex}}"
                        aria-labelledby="{{allTabsnameArray[mainIndex].Name}}-tab"
                        *ngFor="let allTabsdataArraysResult of ReportCollectionArrayResult;  let mainIndex = index ; trackBy:trackByFn">
                        <ul class="nav nav-tabs {{allTabsdataArraysResult.CollectionColumn.length}}"
                          *ngIf="allTabsdataArraysResult.CollectionColumn && allTabsdataArraysResult.CollectionColumn.length>3">
                          <li class="nav-item" *ngIf="allTabsdataArraysResult.type!='Variance'"  style="width: 150px;">
                            <select name="Frequncy"
                              (change)="changeFrequency($event.target.value , allTabsdataArraysResult)"
                              style="width: 100%;background: #ea7704;color: white !important;font-size: 14px;font-weight: bold;margin-right: 10px;width: 90%;height: 33px;"
                              class="tabsindexFrequency{{mainIndex}}" class="form-control" form="carform">
                              <option value="Monthly" class="{{ReportCollectionArrayResult[mainIndex].Frequency}}"    [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Monthly'" >Monthly</option>
                              <option value="Quarterly" class="{{ReportCollectionArrayResult[mainIndex].Frequency}}"  [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Quarterly'" >Quarterly</option>
                              <option value="Annually" class="{{ReportCollectionArrayResult[mainIndex].Frequency}}"   [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Annually'" >Annually</option>
                            </select>
                          </li>
                          <li class="nav-item" *ngFor="let result of PeriodTabView[0].results;  let i=index" 
                          style="width: 100px;text-align: center;"
                            (click)="changePeriodYears(i, allTabsdataArraysResult)">
                            <a class="nav-link" [ngClass]="{'active':i==ActivePeriodTab}"
                              data-toggle="tab">{{result.longLabel}}</a>
                          </li>
                        </ul>
                        <ul class="nav nav-tabs {{allTabsdataArraysResult.CollectionColumn.length}}"
                          *ngIf="allTabsdataArraysResult.CollectionColumn !=null && allTabsdataArraysResult.CollectionColumn.length<=3">
                          <li class="nav-item" *ngIf="allTabsdataArraysResult.type!='Variance'"  style="width: 150px;">
                            <select name="Frequncy"
                              (change)="changeFrequency($event.target.value , allTabsdataArraysResult)"
                              style="width: 100%;background: #ea7704;color: white !important;font-size: 14px;font-weight: bold;margin-right: 10px;width: 90%;height: 33px;"
                              class="tabsindexFrequency{{mainIndex}}" class="form-control" form="carform">
                              <option value="Monthly"   class="{{ReportCollectionArrayResult[mainIndex].Frequency}}" [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Monthly'">Monthly</option>
                              <option value="Quarterly" class="{{ReportCollectionArrayResult[mainIndex].Frequency}}"  [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Quarterly'">Quarterly</option>
                              <option value="Annually"  class="{{ReportCollectionArrayResult[mainIndex].Frequency}}" [selected]="ReportCollectionArrayResult[mainIndex].Frequency == 'Annually'">Annually</option>
                            </select>
                          </li>
                          <li class="nav-item" style="width: 100px;text-align: center;">
                            <a class="nav-link" [ngClass]="{'active':i==ActivePeriodTab}" data-toggle="tab">ALL</a>
                          </li>
                        </ul>

                        <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '6%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" *ngIf="ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '6%'"
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'100px'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div>
                        <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '40%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" 
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'300px'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div>
                        <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '25%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" 
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'300px'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div>
                        <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '15%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" 
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'200px'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div>
                        <!-- <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '40%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" 
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'40%'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div> -->
                        <div class="reportDisplayScreen" *ngIf="ReportCollectionArrayResult[mainIndex].BudgetResult!=null 
                        && ReportCollectionArrayResult[mainIndex].BudgetResult.length>0 && ReportCollectionArrayResult[mainIndex].BudgetResult && ReportCollectionArrayResult[mainIndex].CustomWidth[0].width == '50%'">
                          <igx-tree-grid #sectionGrid id="reportGrid" 
                          [data]="ReportCollectionArrayResult[mainIndex].BudgetResult[0]" primaryKey="rowNum"
                          foreignKey="parentRowNum" [hideRowSelectors]="true" [autoGenerate]="false"
                          style="font-family: Segoe UI;" [rowStyles]="rowStyles" [clipboardOptions]="options">
                          <igx-grid-toolbar>
                            <igx-grid-toolbar-actions>
                              <igx-grid-toolbar-exporter [exportExcel]="true" class="exporttoexcelbutton"
                                [exportCSV]="false">
                              </igx-grid-toolbar-exporter>
                            </igx-grid-toolbar-actions>
                          </igx-grid-toolbar>
                          <igx-column-group header="{{allTabsnameArray[mainIndex].Label}} Report "
                            [collapsible]="true" [expanded]="true" [height]="'20px'" style="line-height: normal; height: 30px;">
                            <igx-column field="name" [sortable]="false" [resizable]="true" width="22.8%"
                              header="Record" [cellClasses]="beatsPerMinuteClasses" [height]="'15px'"
                              class="setColumHeight">
                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                <a (click)="handleRowSelection(cell.row,$event)" style="color: black;"
                                  class="TittleName">{{cell.value}}</a>
                              </ng-template>
                            </igx-column>
                            <igx-column
                              *ngFor="let insidemonth of ReportCollectionArrayResult[mainIndex].CollectionColumn; let IndexValue = index"
                              [width]="'700px'"
                              field="{{insidemonth}}" [height]="'10px'" [resizable]="true" class="setColumHeight"
                              header="{{insidemonth}}" [editable]="true" [cellClasses]="zeroValueAndBoldClasses">

                              <ng-template igxCell let-cell="cell" [height]="'10px'">
                                {{ cell.value==0 ? '-' : cell.value| reportDecimalpipe }}
                              </ng-template>

                            </igx-column>
                          </igx-column-group>

                        </igx-tree-grid>
                        </div>
                        <div class=" export-print-option-style" style="display: inline;width: 45%;float: right;">
                          
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
              <p style="color: white">Please Wait. </p>
            </ngx-spinner>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<igx-dialog #CashFlowBankAccount [closeOnOutsideSelect]="false">

  <button class="CashFlowBankAccount form-control hide" id="CashFlowBankAccount"
    style="font-size: 15px;background: #cccccc;" (click)="CashFlowBankAccount.open()"></button>
  <div class="cashflowDialog" style="font-size: 20px !important;">
    <igx-dialog-title class="dialogtitle">
      <div class="title-container">
        <div class="dialog-title">Select Bank Accounts</div>
      </div>
    </igx-dialog-title>
    <div class="content-savewhatIf">
      <select name="BankAcconts" class="form-control" style="width: 60%;" (change)="SelectBankAccountIdEvent($event)">
        <option value="0">All</option>
        <option *ngFor="let bankaccount of ForecastMainBankAccounts" value="{{bankaccount.nodeId}}">{{bankaccount.name}}
        </option>
      </select>
    </div>
    <div igxDialogActions>
      <button igxButton (click)="CashFlowBankAccount.close()" class="deleteCancelButtonColor">CANCEL</button>
      <button igxButton (click)="CashFlowBankAccountForecastEvent(CashFlowBankAccount)"
        class="OkSaveButtonColor">OK</button>
    </div>
  </div>

</igx-dialog>
<app-footer></app-footer>