<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-1">
    </div> 
    <h1>List of Licences</h1>
    <div class="col-sm-1">
    </div> 
  </div>
    <div class="row">
      <div class="col-sm-1">
      </div>  
      <div class="col-sm  admin-settingmap">
        Trial Packages
        <button class="btn SettingSaveButtonColor" type="button">Purchase</button>
      </div>
      <div class="col-sm admin-settingmap">
        Base Package
        <button class="btn SettingSaveButtonColor" type="button">Purchase</button>
      </div>
      <div class="col-sm admin-settingmap">
        Advance Package
        <button class="btn SettingSaveButtonColor" type="button">Purchase</button>
      </div>
      <div class="col-sm admin-settingmap">
        All In One Package
        <button class="btn SettingSaveButtonColor" type="button">Purchase</button>
      </div>
      <div class="col-sm admin-settingmap">
        Ultimate Package
        <button class="btn SettingSaveButtonColor" type="button">Purchase</button>
      </div>
      <div class="col-sm-1">
      </div>
    </div>
  </div>
