import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Menu } from '../model/menu';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  MenuDetail: Menu[] = [];
  private headers: Headers;
  constructor(private http: HttpClient) { }

  /**
  * Function For get Selected node Details
  * @returns response
  */
  getSelectednodeDetails(NodeId): Observable<Menu[]> {
    if (NodeId != null) {
      if (typeof NodeId === 'object' && NodeId !== null) {
        NodeId = NodeId.nodeId;
      }
      return this.http.get(environment.url + "/api/Department/selectednode/" + NodeId, { responseType: "json" })
        .pipe(map(
          (response: Menu[]) => {
            return response;
          }
        ));
    }

  }

  hidedrawer() {
    //this.rightsidebarMenuComponent.hideDrawer();
  }

  SaveAddDepartment(DepartmentAddDetails): Observable<Menu[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/Department/SaveNewAddDepartment/", DepartmentAddDetails, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }

  deleteDepartment(NodeId): Observable<Menu[]> {
    return this.http.put(environment.url + "/api/Department/DeleteDepartment/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ));
  }
  addFolder(NodeId): Observable<Menu[]> {
    return this.http.put(environment.url + "/api/ContentMenu/add-folder/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ));

  }

  addSection(NodeId): Observable<Menu[]> {
    return this.http.put(environment.url + "/api/ContentMenu/add-section/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ));
  }

  addRecord(NodeId): Observable<Menu[]> {
    return this.http.put(environment.url + "/api/ContentMenu/add-record/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ));
  }

  DeleteSeletedNode(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/ContentMenu/DeleteSelectedNode/" + NodeId, { responseType: "text" })
      .pipe(map(
        (resonse: any) => { 
          return resonse;
        }
      ))
  }

  MoveUpRecord(NodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/ContentMenu/MoveUp/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ))
  }
  MoveDownRecord(NodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/ContentMenu/MoveDown/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          return response;
        }
      ))
  }

  BudgetRecordRecalu(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/RecordCalculation/BudgetReCalculate/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          // console.log(response);
          return response;
        }
      ))
  }
  SystemRecordRecalu(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/RecordCalculation/SystemRecordReCalculate/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          // console.log(response);
          return response;
        }
      ))
  }
  NormalSystemRecordRecalu(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/RecordCalculation/SystemRecordCalculate/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: Menu[]) => {
          // console.log(response);
          return response;
        }
      ))
  }

  ExportRecordList(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/RecordImporter/RecordListExport/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  ExportRecordDetails(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/RecordImporter/RecordDetailExport/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }

  ImportRecordList(ForecastNodeId, ImportRecordString): Observable<Menu[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(environment.url + "/api/RecordImporter/RecordListImport/" + ForecastNodeId, ImportRecordString, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  ImportRecordDetails(ForecastNodeId, ImportRecordString): Observable<Menu[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(environment.url + "/api/RecordImporter/RecordDetailsImport/" + ForecastNodeId, ImportRecordString, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  GetIntegrationStatus(ForecastNodeId): Observable<Menu[]> {
    return this.http.get(environment.url + "/api/ForecastSetting/GetIntegrationStatus/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }

  setWhatIfValue(ForecastNodeId, value): Observable<any> {
    return this.http.get(environment.url + "/api/WhatIf/SetWhatIf/" + ForecastNodeId + "/" + value, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  SaveWhatIfAsAsBudget(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/WhatIf/SaveWhatIfAsAsBudget/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  showWhatIfValue(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/WhatIf/ShowWhatIf/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  SetWhatIfAsNewForecast(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/WhatIf/SetWhatIfAsNewForecast/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  ResetWhatIf(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/WhatIf/ResetWhatIf/" + ForecastNodeId, { responseType: "text" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ))
  }
  recalcConsolidation(forecastNodeid): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.url + "/api/Consolidation/RecalcConsolidation/" + forecastNodeid, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
}
