import { Component, Injector, OnInit } from '@angular/core';
import { ServiceService } from '../list-forecast/services/service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Forecastmodel } from '../list-forecast/services/forecastmodel';

@Component({
  selector: 'app-print-otr',
  templateUrl: './print-otr.component.html',
  styleUrls: ['./print-otr.component.scss']
})
export class PrintOTRComponent implements OnInit {
  UserId = localStorage.getItem('CurrentUserNodeId');
  forecastlist = [];
  forecastIDList = [];
  title = 'One Touch Reporting';
  constructor(private NewForecastService: ServiceService, private router: Router,
    private SpinnerService: NgxSpinnerService, private injector: Injector) {

    this.fillForecasts();
  }

  ngOnInit(): void {
  }

  fillForecasts() {
    if (this.UserId != null) {
      this.NewForecastService.getForecastList(this.UserId).subscribe(
        (response: Forecastmodel[]) => {
          this.SpinnerService.hide();
          this.forecastlist = response;
          //console.log("console Forecast", this.forecastlist);
        })
    }

  }
  checked(event, forecast) {


    var index = this.forecastIDList.findIndex(x => x == forecast.nodeId);
    console.log("forecast check", forecast, index, event);
    if (event.checked == true) {
      if (index == undefined || index == -1) {
        this.forecastIDList.push(forecast.nodeId.toString());
        if (forecast.actualStarted == true) {
          localStorage.setItem("IsActualStarted", "true");
        }
      }
    }
    else {
      this.forecastIDList.splice(index, 1);
    }

    console.log("forecast check this.forecastlist", this.forecastIDList);
  }
}
