<!-- <app-header></app-header>
<div class="forecast-tabs">
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10" style="border: 1px solid #eaeeef;">
            <div class="tab-content clearfix">
                <div class="tab-pane active" id="new-forecast1">
                    <app-settingbase [SettingMenuHeaderEvent]="SettingMenuHeader"></app-settingbase>
                </div>
            </div>
        </div>
        <div class="col-sm-1" style="margin: 0px 0px 0px -56px;">
            <app-settingsmenu (SettingMenuTitleCreated)="onSettingMenuAdded($event)"></app-settingsmenu>
        </div>
    </div>
</div> -->

<app-header></app-header>
<div class="sample-column">
    <div style="width: 100%; display: flex;">
    </div>
    <div class="card-wrapper">
        <igx-content>
            <div class="col-sm-2" style="margin: 0px 0px 0px -15px; padding: 0px; background: rgb(168 168 183 / 27%);height: 92vh;padding: 0 0 0 4px;border-right: 3px solid gray;">
                <app-settingsmenu (SettingMenuTitleCreated)="onSettingMenuAdded($event)"></app-settingsmenu>
            </div>
            <div class="col-sm-10 settingMenus" style="padding: 0 0px 0px 13px;">
                <app-rightsidebar-menu></app-rightsidebar-menu>
                <div class="tab-content clearfix">
                    <div class="tab-pane active" id="new-forecast1">
                        <app-settingbase [SettingMenuHeaderEvent]="SettingMenuHeader"></app-settingbase>
                    </div>
                </div>
            </div>
            
        </igx-content>
    </div>
</div>
<app-footer></app-footer>