<app-header></app-header>
<div class="col-sm-12" style="padding: 0px;">
  <div class="col-sm-2" style="background: rgb(168 168 183 / 27%);height: 92vh;padding: 0 0 0;border-right: 3px solid gray;margin: 0 0 0 -13px;">
    <div class="col-sm-12" style="margin-top: 30px;">

      <igx-card style="background: white !important;margin: 0 6px 25px 4px;border-radius: 12px; height: 240px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;">Actuals Options</h4>
      
        <div class="" style="margin-top: 3%;">
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">print</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="printEnterActual()">Print</button>
            </span>
          </div>
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">publish</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="exportCSV()">Export</button>
            </span>
          </div>
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">file_download</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="openImportFile()">Import</button>
            </span>
          </div>
          <input type="file" style="display:none" id="EnterOpeningImport" (change)="handle($event)">
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">restart_alt</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="ResetNoToActuals()">Reset</button>
            </span>
          </div>
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">add_chart</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="CalculateActual(CurrentActualsIndex, activeId)"> Calculate
              Total</button>
            </span>
          </div>
        </div>
      </igx-card>
      <igx-card style="background: white !important;margin: 0 6px 25px 4px;border-radius: 12px; height: 200px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;">Integration</h4>
        <div style="margin: 3px 0 0 0; width:100%;">
          <div style="margin-left: 10%;">
            <div class="input-group EnterActualMonth">
              <input id="datepickerActual" class="datepickerActual " type="" placeholder="Enter StartDate"
                name="startdate" class="form-control" />
            </div>
            <div>
              <select class="form-controll EnterActualMonth" (change)="selectRounding($event)">
                <option *ngFor="let result of rounding let dataindex = index" value="{{result}}">{{result}}</option>
              </select>
            </div>
            <div>
              <button class="btn btn-primary EnterActualMonth col" style="margin-bottom: 20px;"
                (click)="ImportFromIntegration()">Import</button>
            </div>
          </div>
        </div>
      </igx-card>

      <igx-card style="background: white !important;margin: 0 6px 25px 4px;border-radius: 12px; height: 120px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;"></h4>
        <div class="enteropeningbalance ">
          <button type="button btn btn-primary col" (click)="Save(CurrentActualsIndex, activeId)"  class="SettingSaveButtonColor col"
          style="border: none !important;background: dodgerblue;color: white;width: 73%;height: 30px;margin: 4px 0px 0 45px;font-size: 15px;font-weight: 600;"> Save</button>
        </div>
        <div class="enteropeningbalance">
          <button type="button btn btn-primary col" (click)="Cancel()" class="SettingCancelButtonColor col" 
          style="border: none !important; background: #ea7704; color: white; width: 73%; height: 30px; margin: 4px 0px 0 45px; font-size: 15px; font-weight: 600;">
            Close</button>
        </div>
      </igx-card>

      <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
        <button class="AutoBalanceResponse form-control hide" id="AutoBalanceResponse"
          style="font-size: 15px;background: #cccccc;" (click)="AutoBalanceResponse.open()">Auto Balance</button>
        <igx-dialog #AutoBalanceResponse [closeOnOutsideSelect]="false">
          <div class="insidecontainer" style="width: 465px;height: 100px;">
            <igx-dialog-title class="dialogtitle">
              <div class="title-container">
                <div class="dialog-title">Auto Balancing</div>
              </div>
            </igx-dialog-title>
            <div class="Department row">
              <label class="col">{{this.message}}</label>
            </div>
          </div>
          <div igxDialogActions>
            <button igxButton (click)="this.AutoBalanceClosing()" class="deleteCancelButtonColor">No</button>
            <button igxButton class="OkSaveButtonColor" (click)="this.AutoBalancing()">Yes</button>
          </div>
        </igx-dialog>
      </div>
      
      </div>
  </div>
  <div class="col-sm-10">
    <app-rightsidebar-menu></app-rightsidebar-menu>
    <igx-content>
      <div class="col-sm-9" style="margin-top:10px;">
        <div >
          <div class="rightsidebar col-sm-12"  style="padding: 0px;background: #1891ff;width: 1036px;margin: 0px 9px 0px 14px;">
            <div class="col-sm-2">
              <h4 style="margin-top: 8px;color: white;font-size: 18px;">Enter Actuals</h4>
            </div>
            <div class="col-sm-2">
              <select *ngIf="months" class="form-controll" class="EnterActualMonth" style="margin-top: 2px !important;"
                (change)="ChangeCurerentIndex($event)">
                <option *ngFor="let month of months let dataindex = index" value="{{dataindex}}"
                  [selected]="dataindex == CurrentActualsIndex">{{month}}</option>
              </select>
            </div>
          </div>
          <div style="display: flex;" class="col">
            <div class="left-sidebar " style="height: 770px;width: 100%;border: 3px solid #d8d8d9;margin: 0px 0px 0px 0px;">
              <div class="">
                <ul class="nav nav-tabs">
                  <li class="nav-item "><a class="nav-link active" data-toggle="tab" (click)="ProfitAndLoss()"
                      href="#profitAndLoss">Profit
                      And Loss</a></li>
                  <li class="nav-item"><a class="nav-link " data-toggle="tab" (click)="BalanceSheettabClick()"
                      href="#BalanceSheet">BalanceSheet</a></li>
                </ul>
                <div class="tab-content">
                  <div id="profitAndLoss" class="tab-pane fade in show" *ngIf="DisplayTreeProfitAndLoss=='Active'">
                    <div class="row" style="height: 700px;overflow: auto;margin: 0 4px 0 -2px;">
                      <div class="left-sidebar col-sm-12">
                        <table id="table" class="table" style="width: 100%;"
                          *ngIf="DefualtEnterActualEntryData.profitAndLoss!=''">
                          <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.profitAndLoss'
                            [SelectedActualIndex]="this.CurrentActualsIndex">
                          </app-enter-actual-tree>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div id="BalanceSheet" class="tab-pane fade in show" *ngIf="DisplayTreeBalanceSheet=='Active'">
                    <div class="row" style="height: 700px;overflow: auto;margin: 0 4px 0 -2px;">
                      <div class="left-sidebar col-sm-12">
                        <table id="table" class="table" style="width: 100%;"
                          *ngIf="DefualtEnterActualEntryData.BalanceSheet!=''">
                          <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.BalanceSheet'
                            [SelectedActualIndex]="this.CurrentActualsIndex">
                          </app-enter-actual-tree>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
        
      </div>
    </igx-content>
  </div>
</div>
<app-footer></app-footer>

<div class="hide">
  <igx-content>
    <div class="col-sm-9" style="margin-top:30px;">
      <div style="margin: 0 0 0 10%;">
        <div class="rightsidebar" style="height: 69px;background: #c1c1c1;">
          <div class="col-sm-2">
            <h4 style="margin-top: 20px;color: black;font-size: 22px;">Enter Actuals</h4>
          </div>
          <div class="col-sm-2">
            <select *ngIf="months" class="form-controll" class="EnterActualMonth"
              (change)="ChangeCurerentIndex($event)">
              <option *ngFor="let month of months let dataindex = index" value="{{dataindex}}"
                [selected]="dataindex == CurrentActualsIndex">{{month}}</option>
            </select>
          </div>
        </div>
        <div style="display: flex;">
          <div class="left-sidebar " style="height: 720px;width: 80%;border: 1px solid;margin: 0px 0px 0px 0px; ">
            <div class="">
              <ul class="nav nav-tabs">
                <li class="nav-item "><a class="nav-link active" data-toggle="tab" (click)="ProfitAndLoss()"
                    href="#profitAndLoss">Profit
                    And Loss</a></li>
                <li class="nav-item"><a class="nav-link " data-toggle="tab" (click)="BalanceSheettabClick()"
                    href="#BalanceSheet">BalanceSheet</a></li>
              </ul>
              <div class="tab-content">
                <div id="profitAndLoss" class="tab-pane fade in show" *ngIf="DisplayTreeProfitAndLoss=='Active'">
                  <div class="row" style="height: 624px;overflow: auto;margin: 0 4px 0 -2px;">
                    <div class="left-sidebar col-sm-12">
                      <table id="table" class="table" style="width: 100%;"
                        *ngIf="DefualtEnterActualEntryData.profitAndLoss!=''">
                        <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.profitAndLoss'
                          [SelectedActualIndex]="this.CurrentActualsIndex">
                        </app-enter-actual-tree>
                      </table>
                    </div>
                  </div>
                </div>
                <div id="BalanceSheet" class="tab-pane fade in show" *ngIf="DisplayTreeBalanceSheet=='Active'">
                  <div class="row" style="height: 616px;overflow: auto;margin: 0 4px 0 -2px;">
                    <div class="left-sidebar col-sm-12">
                      <table id="table" class="table" style="width: 100%;"
                        *ngIf="DefualtEnterActualEntryData.BalanceSheet!=''">
                        <app-enter-actual-tree [EnterActualTreeView]='DefualtEnterActualEntryData.BalanceSheet'
                          [SelectedActualIndex]="this.CurrentActualsIndex">
                        </app-enter-actual-tree>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="" style="height: 720px;border: 1px solid;width: 20%;margin-left: 0px;">
            <div class="enteropeningbalance col">
              <button type="button" (click)="printEnterActual()"
                style="border: none !important; background: dodgerblue;color: white;width: inherit;">Print</button>
            </div>
            <div class="enteropeningbalance col">
              <button type="button" (click)="exportCSV()"
                style="border: none !important; background: dodgerblue;color: white;width: inherit;">Export</button>
            </div>
            <div class="enteropeningbalance col">
              <button type="button" (click)="openImportFile()"
                style="border: none !important; background: dodgerblue;color: white;width: inherit;">Import</button>
            </div>
            <input type="file" style="display:none" id="EnterOpeningImport" (change)="handle($event)">
            <div class="enteropeningbalance col">
              <button type="button" (click)="ResetNoToActuals()"
                style="border: none !important; background: dodgerblue;color: white; width: inherit;">Reset</button>
            </div>
            <div class="enteropeningbalance col">
              <button type="button" (click)="CalculateActual(CurrentActualsIndex, activeId)"
                style="border: none !important; background: dodgerblue;color: white;width: inherit;"> Calculate
                Total</button>
            </div>
            <div style="border: 1px solid;margin: 3px 0 0 0; width:100%;">
              <label>
                <h4 style="margin-top: 20px;color: black;font-size: 22px;padding: 5px;">Integration</h4>
              </label>
              <div style="margin-left: 10%;">
                <div class="input-group EnterActualMonth">
                  <input id="datepickerActual" class="datepickerActual " type="" placeholder="Enter StartDate"
                    name="startdate" class="form-control" />
                </div>
                <div>
                  <select class="form-controll EnterActualMonth" (change)="selectRounding($event)">
                    <option *ngFor="let result of rounding let dataindex = index" value="{{result}}">{{result}}</option>
                  </select>
                </div>
                <div>
                  <button class="btn btn-primary EnterActualMonth col" style="margin-bottom: 20px;"
                    (click)="ImportFromIntegration()">Import</button>
                </div>
              </div>
            </div>
            <div class="enteropeningbalance col">
              <button type="button" (click)="Save(CurrentActualsIndex, activeId)" class="SettingSaveButtonColor col"
                style="font-size: 22px;"> Save</button>
            </div>
            <div class="enteropeningbalance col">
              <button type="button" (click)="Cancel()" class="SettingCancelButtonColor col" style="font-size: 22px;">
                Close</button>
            </div>
          </div>

        </div>

      </div>
      
    </div>
  </igx-content>

  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. EnterActual Results will be Display Soon! </p>
  </ngx-spinner>

  

  