import { Component, OnInit, Injector,Input } from '@angular/core';
import { Card, Integrations } from '../card.blueprint';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';
import { AdminService } from 'src/app/service/Admin.service';
import { IntegrationModel } from 'src/app/model/IntegrationModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-license-purchase',
  templateUrl: './license-purchase.component.html',
  styleUrls: ['./license-purchase.component.scss']
})
export class LicensePurchaseComponent implements OnInit {
  cardsarray = [];
  Integrations: IntegrationModel[] = [];
  displayHeader:boolean;
  @Input() UpgradeDialog;

  constructor(private httpService: HttpClient, 
    private injector: Injector, 
    private AdminService: AdminService, 
    private SpinnerService: NgxSpinnerService,
    private _router:Router) {

  }

  ngOnInit() { 
    if(this._router.url == '/login'){
    } else {
      console.log(this._router.url);
      this.AdminService.getIntegrations().subscribe(
        (response: IntegrationModel[]) => {
          this.Integrations = response;
          this.loadLicensePlan();
        },
        error => {
          throw error;
        }
      )
    }
    


    if(this.UpgradeDialog == 'true'){
      this.displayHeader = true;
    } else {
      this.displayHeader = false;
    }
  }

  loadLicensePlan() {
    this.httpService.get('../../../assets/LicensePlan.json').subscribe(
      data => {
        let dataArray = data as string[];
        // console.log(this.Integrations);
        dataArray["Plans"]["Plan"].forEach(plan => {
          let SelectedIntegrations = [];
          this.cardsarray.push(new Card({
            content: plan["Features"],
            NoofIntegration: plan["NoofIntegration"],
            imageUrl: '../../../assets/FullBrandLogowebsite.png',
            subtitle: plan['recurring_price_formatted'],
            title: plan["plantitle"],
            planCode: plan["plancode"],
            button: ['Purchase'],
            buttonURL: plan['url'],
            Intgrations: (plan["NoofIntegration"] !== undefined && parseInt(plan["NoofIntegration"]) > 0) ? this.Integrations : null,
            SelectedIntegrations: SelectedIntegrations,
          }))
        });

      },
      (err: HttpErrorResponse) => {
        // console.log(err.message);
      }
    );
  }
  checkBoxValidation(checkbox: any, selectedInte: Integrations, card: Card) {
    const notifier = this.injector.get(NotificationService);
    // console.log("checkbox clicked", card);
    if (checkbox.target.checked && (card.SelectedIntegrations.length >= parseInt(card.NoofIntegration))) {
      checkbox.preventDefault();
      notifier.showError("Allowed integrations already selected.");
    }
    else if (!checkbox.target.checked) {
      var count = card.SelectedIntegrations.indexOf(selectedInte);
      // console.log("count", count);
    }
    card.SelectedIntegrations.push(selectedInte);

  }

  handleClick(card: Card) {
    let currentUserID = localStorage.getItem('CurrentUserID');
    // console.log("currentUserID", currentUserID);
    let useremailID = localStorage.getItem('userEmailID');
    let selectedInte = '';
    // if (card.SelectedIntegrations) {
    //   card.SelectedIntegrations.forEach(element => {

    //     console.log(element);
    //     if (selectedInte === '') {
    //       selectedInte = element.Id + ",";
    //       console.log(selectedInte);
    //     }
    //     else {
    //       selectedInte = selectedInte + "," + element.Id;
    //     }
    //   });
    // }
    console.log(card.title, useremailID, currentUserID, card.SelectedIntegrations);
   
    this.AdminService.purchaseSubscription(card.title, useremailID, currentUserID, card.SelectedIntegrations).subscribe(
      (response: any) => {
        if (response) {
          const url = card.buttonURL;
          window.open(url, '_blank');
        }
      });


  }
}
