import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from "rxjs/operators";
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class XeroService {

  constructor(private http: HttpClient, private SpinnerService:NgxSpinnerService) { }

    XeroAuthentication(ForecastId): Observable<any> {
      return this.http.get(environment.url + "/api/XeroIntegrationAuthorization/"+ForecastId,{ responseType: "text" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
    }
    XeroTenants(ForecastId): Observable<any> {
      return this.http.get(environment.url + "/api/XeroIntegrationAuthorization/XeroTenant/"+ForecastId,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
    }
    Disconnection(ForecastId): Observable<any> {
        return this.http.delete(environment.url + "/api/XeroIntegrationAuthorization/Disconnection/"+ForecastId,{ responseType: "json" })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
      }
    SaveXerotenant(saveXeroTenantModel):Observable<any>{
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      return this.http.post(environment.url + "/api/XeroIntegrationAuthorization/SaveXeroTenant", saveXeroTenantModel, {headers:reqHeaders})
          .pipe(map(
              (response: any) => {
                  return response;
              }
          )); 
    }

    getSelectedTenant(ForecastId): Observable<any> {
        return this.http.get(environment.url + "/api/XeroIntegrationAuthorization/GetSelectedTenant/"+ForecastId,{ responseType: "json" })
        .pipe(map(
            (response: any) => {
                return response;
            }
        ));
      }

    ImportData(ForecastId): Observable<any> { 
        this.SpinnerService.show();
      return this.http.get(environment.url + "/api/ImportIntegration/GetImportData/"+ForecastId,{ responseType: "json" })
          .pipe(map(
              (response: any) => { 
                this.SpinnerService.hide();
                console.log(response);
                  return response;
              }
          ));
    }
    SaveImportData(SaveImportData):Observable<any>{
        let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(environment.url + "/api/ImportIntegration/SaveImportData", SaveImportData, {headers:reqHeaders})
            .pipe(map(
                (response: any) => {
                    return response;
                }
            )); 
      }

      XeroMapingDataCollection(ForecastId): Observable<any>{
        return this.http.get(environment.url + "/api/XeroMaping/GetMappingXeroCollections/"+ForecastId,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
      }
      SaveMaping(SaveMaping):Observable<any>{
        let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(environment.url + "/api/XeroMaping/SaveMaping", SaveMaping, {headers:reqHeaders})
            .pipe(map(
                (response: any) => {
                    return response;
                }
            )); 
      }

      ForecastIntegrationTrackingCode(ForecastId,value): Observable<any>{
        return this.http.get(environment.url + "/api/ForecastSetting/ForecastIntegrationTrackingCode/"+ForecastId+"/"+value,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
      }
      ForecastIntegrationAllAcountSetting(ForecastId,value): Observable<any>{
        return this.http.get(environment.url + "/api/ForecastSetting/ForecastIntegrationAllAcountSetting/"+ForecastId+"/"+value,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
      }
      ForecastIntegrationGlSetting(ForecastId,value): Observable<any>{
        return this.http.get(environment.url + "/api/ForecastSetting/ForecastIntegrationGlSetting/"+ForecastId+"/"+value,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
      }
      getIntegrationSettingDetails(ForecastId): Observable<any>{
        return this.http.get(environment.url + "/api/ForecastSetting/getIntegrationSettingDetails/"+ForecastId,{ responseType: "json" })
          .pipe(map(
              (response: any) => {
                  return response;
              }
          ));
      }

}
