<app-header></app-header>
<div class="registrationPage">

    <div class="registration">

        <div class="register-container registration-header">

            <div class="registrationForm">
                <div class="centerAlignText">
                    <h2>User Registration Trial</h2>
                </div>
                <form [formGroup]="userregistrationForm" class="userregistrationForm" (ngSubmit)="onSubmit()">
                    <div>
                        <app-input [control]="userregistrationForm.controls.firstName" placeholder="Enter Name"
                            text="First Name" icon="person">
                        </app-input>
                        <app-input [control]="userregistrationForm.controls.lastName" placeholder="Enter Name"
                            text="Last Name" icon="person">
                        </app-input>

                        <app-input [control]="userregistrationForm.controls.phone" type="tel" placeholder="Enter Name"
                            text="Phone" icon="phone">
                        </app-input>
                        <app-input [control]="userregistrationForm.controls.company" placeholder="Enter Name"
                            text="Company Name" icon="building">
                        </app-input>
                        <!-- <app-input [control]="userregistrationForm.controls.userName" placeholder="Enter Name"
                            text="User Name" icon="person">
                        </app-input> -->
                        <div class="country" style="width:500px;">
                            <igx-select style="width: 400px !important; " name="country" class="countrySelect"
                                [formControl]="userregistrationForm.controls.Country" id="country">
                                <label igxLabel>Select Country </label>
                                <igx-suffix>
                                    <igx-icon>home</igx-icon>
                                </igx-suffix>
                                <igx-select-item *ngFor="let country of Countries" [value]="country.name">
                                    {{country.name}}</igx-select-item>
                            </igx-select>
                        </div>
                        <app-input [control]="userregistrationForm.controls.emailId" placeholder="Enter Name"
                            text="Email ID" icon="mail">
                        </app-input>
                        <app-input [control]="userregistrationForm.controls.Password" type="password"
                            placeholder="Enter Password" text="Password" icon="lock">
                        </app-input>
                        <app-input [control]="userregistrationForm.controls.ConfirmPassword" type="password"
                            placeholder="Enter Name" text="Confirm Password" icon="lock">
                        </app-input>


                    </div>
                    <div class="register-Submit">
                        <input type="submit" class="OkSaveButtonColor register-submitbutton"
                            style="background-color: #1e90ff;" value="Register">
                    </div>

                </form>
            </div>

        </div>
    </div>

</div>
<div>
    <!-- <div style="width: 430px; display: flex;  flex-direction: column-reverse !important;">

        <igx-select style="width: 392px !important;  flex-direction: column-reverse !important;padding-bottom: 10px;"
            name="country" class="country" [formControl]="userregistrationForm.controls.Country" id="country">
            <label igxLabel>Select Country </label>
            <igx-select-item *ngFor="let country of Countries" [value]="country.name">
                {{country.name}}</igx-select-item>
        </igx-select>
    </div> -->
</div>