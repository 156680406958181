<app-header></app-header>
<div class="sample-column content-wrap">
    <div style="width: 100%; display: flex;">
        <div style="width:90%; margin-top: 20px;">
            <h2 class="HeaderColor">Clients</h2>
        </div>
        <div style="width:10%;margin: 10px;" class="Test-{{numberofClients}}">
            <button *appPermission="'NumberOfClients',CurrentValue:numberofClients" class="newCLient"
                igxToggleAction="navigation" (click)="NewClientSave()">Add
                Client</button>
        </div>
    </div>
    <div class="card-wrapper" *ngFor="let listing of clients">
        <igx-card style="padding: 6px;">
            <igx-card-header class="CardHeader" style="border-bottom: 1px solid gray;padding: 8px 6px 7px 9px;">
                <h3 igxCardHeaderTitle (click)="openforecastlist(listing.clientID,listing.clientName)">{{
                    listing.clientName }}
                </h3>
            </igx-card-header>
            <igx-card-content style="margin-top: 20px;">
                <p class="clientActiveForecast" style="font-size: 13px;height: 18px;font-weight: 500;">ActiveForecast:
                    {{ listing.activeForecast }}</p>
                <p class="clientdescription" style="font-size: 13px;height: 18px;font-weight: 500;">Description: {{
                    listing.description }}</p>
            </igx-card-content>
            <igx-card-actions>
                <button igxIconButton="flat" style="width: 30px; height: 30px;font-size: 30px;" igxRipple
                    igxRippleCentered="true" igxEnd>
                    <igx-icon class="deleteIconColor" style="width: 20px;height: 20px;font-size: 21px;"
                        (click)="editClient(listing)" igxToggleAction="navigation">
                        edit</igx-icon>
                </button>
                <button igxIconButton="flat" style="width: 30px; height: 30px;font-size: 30px;" igxRipple
                    igxRippleCentered="true" igxEnd>

                    <igx-icon class="deleteIconColor" style="width: 20px;height: 20px;font-size: 21px;"
                        (click)="DeleteClientForm.open()">
                        delete</igx-icon>
                </button>
                <igx-dialog #DeleteClientForm [closeOnOutsideSelect]="false">
                    <div class="insidecontainer" style="width: 465px;height: 100px;">
                        <igx-dialog-title class="dialogtitle">
                            <div class="title-container">
                                <div class="dialog-title" style="font-size: 16px;">Delete Client</div>
                            </div>
                        </igx-dialog-title>
                        <div class="Department row">
                            <p style="font-size: 24px;margin: 2px 3px 2px 45px;">Are you sure you want to delete Client?
                            </p>
                        </div>
                    </div>
                    <div igxDialogActions>
                        <button igxButton (click)="DeleteClientForm.close()"
                            class="deleteCancelButtonColor">Cancel</button>
                        <button igxButton class="OkSaveButtonColor"
                            (click)="deleteClient(listing.clientID, DeleteClientForm)">Ok</button>
                    </div>
                </igx-dialog>
            </igx-card-actions>
        </igx-card>
    </div>
</div>

<div class="content-wrap newForecastPage">
    <igx-nav-drawer id="navigation" #drawer [width]="'400px'" [pin]="true" [pinThreshold]="0"
        style="border: 1px solid gray;">
        <ng-template igxDrawer>
            <nav>
                <span>
                    <article class="Newclient">
                        <h2 class="HeaderColor"> New Client </h2>
                        <label igxLabel style="height: 30px;margin-left: 10px;"></label>
                        <form class="NewClientForm" [formGroup]="NewClientForm" (ngSubmit)="onSubmit()">
                            <label igxLabel *ngIf="clinetNameInValid" style="height: 30px;color: red;top: 10px;">Client
                                Name is required</label>
                            <igx-input-group class="inputGroup">
                                <input igxInput name="clientName" formControlName="clientName" type="text"
                                    style="height: 40px;font-size: medium;" required="required" />
                                <label igxLabel for="clientName" style="height: 30px;">Client Name</label>
                            </igx-input-group>
                            <igx-input-group class="inputGroup">
                                <input igxInput name="description" formControlName="description" type="text" multiple
                                    style="height: 40px;font-size: medium;" />
                                <label igxLabel for="description" style="height: 30px;">Description</label>
                            </igx-input-group>
                            <button igxButton="contained" class="submitClient" type="submit">{{buttonText}}</button>

                        </form>

                    </article>
                </span>
            </nav>
        </ng-template>
    </igx-nav-drawer>
</div>
<app-footer></app-footer>