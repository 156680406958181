import { Component, OnInit, ViewChild, Input,Injector } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { ServiceService } from '../services/service.service'
import * as moment from 'moment';
import { Forecastmodel } from '../../list-forecast/services/forecastmodel';
declare const $: any;
import { ListForecastComponent } from '../list-forecast.component';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-newforecast',
  templateUrl: './newforecast.component.html',
  styleUrls: ['./newforecast.component.scss']
})
export class NewforecastComponent implements OnInit {
  createForecastForm: UntypedFormGroup;
  details: any = [];
  months = []
  title = 'New Forecast';
  currency = []
  ForecastStartdatevalue: any;
  RegionalCollection: any;
  startdate: any;
  addupdatecurrency: any;
  @Input() forecastSelection: any;
  public date = new Date();
  UserId = localStorage.getItem('CurrentUserNodeId');
  RegionalCountryCollectionIndex = 0;
  ForecastClientCountryCollection = [];
  iSCountryLoaded = false;
  isDefaultCountry = "";
  selectedCountryIndex = 0;
  selectedCountryIndexValue = "0";
  currencyerror:boolean;
  constructor(
    private calendar: NgbCalendar,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private NewForecastService: ServiceService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private listForecastComponent: ListForecastComponent,
    private injector: Injector
  ) {
    var DateStart = new Date();
    this.startdate = moment(DateStart, "MMM YYYY").format("MMM YYYY");
    this.isDefaultCountry = localStorage.getItem("DefaultCounty");
    console.log(this.isDefaultCountry);
    if(this.isDefaultCountry == "UK"){
      this.selectedCountryIndex = 1;
      this.selectedCountryIndexValue = "1";
    }
    this.createForecastForm = this.formBuilder.group({
      company_name: [''],
      Subheading_name: [''],
      Description: [''],
      numberofyears: ['1'],
      ForecastCountry: [this.selectedCountryIndexValue],
      startdate: [this.startdate],
      LocalCurrency: ['0'],
      FirstdataPeriod: ['0'],
      FirstDailyCashFlowPeriod: ['0'],
      integration: ['0']
    });
    this.currencyerror = false;
   
  }

  async ngOnInit() {
    if (this.forecastSelection == true) {

    } else {
      return;
    }
    this.currencyerror = false;
    this.ForecastClientCountryCollection = [];
    this.addupdatecurrency = false;
    this.getloopyears();
    let newDate = '';
    $("#datepicker").on("change", function () {
      this.setAttribute(
        "value",
        this.value
      )
    }).trigger("change")
    $("#datepicker").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months"
    });
    let JsonCollectionArray = localStorage.getItem("ForecastClientCountryCollection");
    this.isDefaultCountry = localStorage.getItem("DefaultCounty");
    if(this.isDefaultCountry == "UK"){
      this.selectedCountryIndex = 1;
    }
    //console.log("JsonCollectionArray", JsonCollectionArray);
    if (JsonCollectionArray != null && JsonCollectionArray != undefined && JsonCollectionArray != "null") {
      this.ForecastClientCountryCollection = JSON.parse(JsonCollectionArray);
      this.RegionalCollection = this.ForecastClientCountryCollection;
      this.currency = [];
      this.RegionalCollection.forEach((element, index) => {
        if (index ==  this.selectedCountryIndex)
          element.regionalCurrencyCollection.forEach(children => {
            this.currency.push(children);
          });
        localStorage.setItem("Currency", JSON.stringify(this.currency));
        var storedNames = JSON.parse(localStorage.getItem("Currency"));
        localStorage.setItem("ForecastClientCountryCollection", JSON.stringify(this.ForecastClientCountryCollection))
        // console.log(storedNames);
      });
    } else {
      await this.getRegioanlcollectionAndCurrency(this.UserId);
    }


    // await this.delay(300);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getRegioanlcollectionAndCurrency(UserId) {
    if (UserId != null) {
      this.SpinnerService.show();
      let jsonCountryCollection = null;
      jsonCountryCollection = localStorage.getItem("ForecastClientCountryCollection");
      console.log("jsonCountryCollection", jsonCountryCollection);
      if (localStorage.getItem("ForecastClientCountryCollection") != "null" && localStorage.getItem("ForecastClientCountryCollection") != null) {
        this.RegionalCollection = JSON.parse(jsonCountryCollection);
        console.log("jsonCountryCollection else of response", this.RegionalCollection);
        if (this.RegionalCollection != null) {
          this.currency = [];
          this.RegionalCollection.forEach((element, index) => {
            if (index ==  this.selectedCountryIndex)
              element.regionalCurrencyCollection.forEach(children => {
                this.currency.push(children);
              });
            localStorage.setItem("Currency", JSON.stringify(this.currency));
            var storedNames = JSON.parse(localStorage.getItem("Currency"));
          });
        }
        this.SpinnerService.hide();
      } else {
        this.NewForecastService.getRegionalCollection(UserId).subscribe(
          (response: any) => {
            this.SpinnerService.hide();
            if(response.length == 0){
              this.currencyerror = true;
            }
            this.ForecastClientCountryCollection = response;

            this.RegionalCollection = response;
            console.log("jsonCountryCollection inside response", this.RegionalCollection);
            if (this.RegionalCollection != null) {
              this.currency = [];
              this.RegionalCollection.forEach((element, index) => {
                if (index ==  this.selectedCountryIndex)
                  element.regionalCurrencyCollection.forEach(children => {
                    this.currency.push(children);
                  });
                localStorage.setItem("Currency", JSON.stringify(this.currency));
                var storedNames = JSON.parse(localStorage.getItem("Currency"));
                localStorage.setItem("ForecastClientCountryCollection", JSON.stringify(this.ForecastClientCountryCollection))
                // console.log(storedNames);
              });
            }
          }
        )
      }
    }
  }

  CurrencyUpdateforms(CurrencyUpdateform) {
    this.addupdatecurrency = true;
    CurrencyUpdateform.open();
    localStorage.setItem("changeCurrency", 'true');
  }

  ngAfterContentChecked() {
    var inputValue: any = (<HTMLInputElement>document.getElementById('datepicker'));
    if (inputValue.value != '' && inputValue.value != undefined) {
      this.months = [];
      let Newdate = moment(inputValue.value, "MMM YYYY").format("MMM YYYY");
      var date = new Date(Newdate);
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      for (var i = 0; i < 12; i++) {
        this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() + 1);
      }
    }
  }

  onSubmit() {
    var inputValue: any = (<HTMLInputElement>document.getElementById('datepicker'));
    let CompanyName = this.createForecastForm.value.company_name;
    let SubHeading = this.createForecastForm.value.Subheading_name;
    let NumberOfYears = parseInt(this.createForecastForm.value.numberofyears);
    let SelectedCountryIndex = parseInt(this.createForecastForm.value.ForecastCountry);
    let ForecastStartDate: any = inputValue.value
    let SelectedCurrencyIndex = parseInt(this.createForecastForm.value.LocalCurrency);
    let FirstDataPeriodIndex = parseInt(this.createForecastForm.value.FirstdataPeriod);
    let clientID = localStorage.getItem("clientID");
    if (SelectedCountryIndex == null) {
      this.router.navigateByUrl("/client");
    }
    var getFullYears = inputValue.value.split(" ");
    let numberCount = 0;
    var getMonth = getFullYears[0];
    var getYears = "";
    if (getFullYears[1].length > 3) {
      getYears = getFullYears[1];
    } else {
      getYears = "20" + getFullYears[1];
    }
    var getDay = "2";
    var date = new Date(getDay + "-" + getMonth + "-" + getYears);

    ForecastStartDate = new Date(getDay + "/" + getMonth + "/" + getYears);

    if (FirstDataPeriodIndex == null) {
      FirstDataPeriodIndex = 0;
    }
    let FirstDCPeriodIndex = parseInt(this.createForecastForm.value.FirstDailyCashFlowPeriod);
    if (FirstDCPeriodIndex == null) {
      FirstDCPeriodIndex = 0;
    }
    let SoftwareIntegrationType = parseInt(this.createForecastForm.value.integration);
    let UserId = this.UserId;
    let data = this.currency;
    let currencyArray = [];
    this.currency.forEach(element => {
      let displaydata = {
        'name': '',
        'nodeID': null,
        'symbol': '',
        'tla': '',
      }
      if (element['nodeId'] == undefined) {
        element['nodeId'] = null;
      }
      if (element['symbol'] == undefined) {
        element['symbol'] = '';
      }
      if (element['tla'] == undefined) {
        element['tla'] = '';
      }
      displaydata.name = element['name'];
      displaydata.nodeID = element['nodeId'];
      displaydata.symbol = element['symbol'];
      displaydata.tla = element['tla'];
      currencyArray.push(displaydata);
    });

    let jsonArray = {
      UserId,
      CompanyName,
      SubHeading,
      NumberOfYears,
      SelectedCountryIndex,
      ForecastStartDate,
      SelectedCurrencyIndex,
      FirstDataPeriodIndex,
      FirstDCPeriodIndex,
      SoftwareIntegrationType,
      clientID,
      UpdateCurrencyCollection: currencyArray
    }

    this.SpinnerService.show();
    this.NewForecastService.CreateForecast(jsonArray).subscribe(
      (response) => {
        this.SpinnerService.hide();
        window.location.reload();
      },
      error => {
        this.SpinnerService.hide();
        throw error;
      }
    )
  }

  ForecastCountyClickEvent($event){
    const notifier = this.injector.get(NotificationService);
    if(this.RegionalCollection==null && this.RegionalCollection.length == 0){
      notifier.showError("Countries not loading, please refresh the page");
    }
  }

  ngOnChange() {
    $("#datepicker").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months",
    });

    this.isDefaultCountry = localStorage.getItem("DefaultCounty");
    if(this.isDefaultCountry == "UK"){
      this.selectedCountryIndex = 1;
    }

    if(this.RegionalCollection==null && this.RegionalCollection.length == 0){
      this.ngOnInit();
    }

  }
  getUpdatedCurrency() {
    let changeCurrency = localStorage.getItem("changeCurrency");
    if (changeCurrency == 'true') {
      var storedNames = JSON.parse(localStorage.getItem("Currency"));
      this.currency = storedNames;
      localStorage.setItem("changeCurrency", 'false');
    }
  }

  onOptionsSelected($event) {
    const notifier = this.injector.get(NotificationService);
    this.currency = [];
    this.RegionalCountryCollectionIndex = parseInt($event.target.value);
    if(this.RegionalCollection!=null && this.RegionalCollection.length>0){
      this.RegionalCollection.forEach((element, index) => {
        if (index == parseInt($event.target.value)) {
          element.regionalCurrencyCollection.forEach(children => {
            this.currency.push(children);
          });
          //console.log(this.currency)
          localStorage.setItem("Currency", JSON.stringify(this.currency));
          var storedNames = JSON.parse(localStorage.getItem("Currency"));
        }
      });
    } else {
      notifier.showError("Your Session has been expired please login");
    }
    
  }

  DefaultCurrencySelection(indexEvent) {
    this.currency = [];
    this.RegionalCountryCollectionIndex = indexEvent;
    this.RegionalCollection.forEach((element, index) => {
      if (index == indexEvent) {
        element.regionalCurrencyCollection.forEach(children => {
          this.currency.push(children);
        });
        //console.log(this.currency)
        localStorage.setItem("Currency", JSON.stringify(this.currency));
        var storedNames = JSON.parse(localStorage.getItem("Currency"));
      }
    });
  }

  changecompayname_event($event) {
    this.title = $event.target.value;
  }

  getloopyears() {
    var date = new Date();
    // console.log(date)
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (var i = 0; i < 12; i++) {
      this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
      date.setMonth(date.getMonth() + 1);
    }
  }
  closeForecast() {
    this.listForecastComponent.clossnewforecasttab();
    this.listForecastComponent.ngOnInit();
  }
}
