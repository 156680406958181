import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {
  constructor() {
  }
  transform(value: any, args?: any): any {
    if (value != null) {
      let data = value.toString();
      if (data.charAt(0) == '-') {
        return data.charAt(0) == '-' ?
          '(' + data.substring(1, data.length) + ')' :
          data;
      } else {
        let result = parseFloat(data).toFixed(2);
        return result;
      }
    } else {
      return value;
    }
  }

}
