import { Component, OnInit, Input } from '@angular/core';
import { MethodserviceService } from '../method/service/methodservice.service'
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from '../../../service/menu.service';
import { RightsidebarMenuComponent } from '../../rightsidebar-menu/rightsidebar-menu.component';
import { recordSaveModel } from 'src/app/model/recordSaveModel';
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';
import { SplitterType } from 'igniteui-angular';

declare const $: any;
//import * as $ from 'jquery';
@Component({
  selector: 'app-valuelist',
  templateUrl: './valuelist.component.html',
  styleUrls: ['./valuelist.component.scss']
})
export class ValuelistComponent implements OnInit {

  @Input() eventBudgetListView: any;
  @Input() eventBudgetDerrivationView: Event;
  @Input() eventMonthDisplay: any;
  @Input() eventPeriodTabView: any;
  @Input() clicknodeId: Event;
  @Input() budgettotal: Event;
  @Input() cssStringVar: any;

  @Input() recordnodeType: any;
  @Input() ActiveTreenodeId: any;

  public type = SplitterType.Horizontal;

  Total: any;
  eventMethodName = "";
  displaydata = []
  displayStock: any = []
  val: any;
  displaynodevisehtml = [];
  DisplayAllCommonDataArray: any;
  MemoRecordArray: any;
  IsActual: any;
  haveStockComponent: false;
  haveMadeStockComponent: false;
  stockinputId: any
  BudgetDerrivationView: any = [];
  MonthDisplayView: any = [];
  PeriodTabView: any = [];
  ActivePeriodTabMen: any;
  PeriodTabActive: boolean;
  showWhatIfActive: boolean;
  isConsolidation: boolean;
  finnaceAssetRecord: any;
  calculatedLoanMadeRecord: any;
  hasRecordOption: boolean = false;
  recordmodifiedModel: recordSaveModel;
  isRecord: boolean = false;
  DisplayBudgetData: boolean = true;

  constructor(private SpinnerService: NgxSpinnerService,
    private methodserviceService: MethodserviceService,
    public rightsidebarMenuComponent: RightsidebarMenuComponent,
    public forecasttabsComponent: ForecasttabsComponent,
    private menuService: MenuService) {
    localStorage.setItem('budgetdata', '');
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');

  }
  trackByFn(index: any, item: any) {
    return index;
  }


  consolelog(display) {
    // console.log("consolelog", display);
  }
  ngOnChanges() {
    this.showWhatIfActive = false;
    if (this.ActiveTreenodeId != undefined) {
    }
    // console.log(this.recordnodeType, this.ActiveTreenodeId);
    if (this.recordnodeType == '50017') {
      this.calculatedLoanMadeRecord = this.recordnodeType;
    }
    if (this.recordnodeType == '50011') {
      this.finnaceAssetRecord = this.recordnodeType;
    }
    if (this.recordnodeType == '50033') {
      this.DisplayBudgetData = false;
    }
    if (this.recordnodeType != '50033') {
      this.DisplayBudgetData = true;
    }


    this.PeriodTabView = [];
    let data: any = this.eventBudgetListView;
    this.DisplayAllCommonDataArray = this.eventBudgetListView[12];
    this.BudgetDerrivationView = this.eventBudgetDerrivationView;
    this.MonthDisplayView = this.eventMonthDisplay;
    this.PeriodTabView = this.eventPeriodTabView;

    if (this.eventPeriodTabView.length > 0) {
      this.PeriodTabActive = true;
      this.ActivePeriodTabMen = this.PeriodTabView[0].selectedIndex;
      localStorage.setItem("ActivePeriodTab", this.PeriodTabView[0].results[0].longLabel);
    } else {
      this.PeriodTabActive = false;
      this.ActivePeriodTabMen = 0;
      if (this.PeriodTabView.length > 0) {
        localStorage.setItem("ActivePeriodTab", this.PeriodTabView[0].results[0].longLabel);
      }
    }


    if (data != '') {
      if (data.length > 12) {
        if (this.eventBudgetListView[12].length > 0) {
          this.displayStock = data;
          if (this.eventBudgetListView[12].length > 0) {
            this.haveStockComponent = this.eventBudgetListView[12][0].Stockcomponent;
            this.haveMadeStockComponent = this.eventBudgetListView[12][0].StockMadecomponent;
            this.stockinputId = this.eventBudgetListView[12][0].recordNodeId;
          }
        }
      }

    }
    if (localStorage.getItem('recordSelected') != null) {
      let recordData = JSON.parse(localStorage.getItem('recordSelected'));
      this.hasRecordOption = (recordData.hasRecordOption === true);
    }
    if (this.DisplayAllCommonDataArray != null) {
      this.MemoRecordArray = this.DisplayAllCommonDataArray[0].recordNodeId;
      console.log("MemoRecordArray", this.MemoRecordArray);
      this.isRecord = true;
    }
    else {
      this.isRecord = false;
    }
    let ForecastId = localStorage.getItem("ForecastId");
    if (localStorage.getItem('showWhatIfValue') != '') {
      this.showWhatIfActive = (localStorage.getItem('showWhatIfValue') === 'true');
    }
    else {
      this.menuService.showWhatIfValue(ForecastId).subscribe(
        ((response: any) => {
          localStorage.setItem('showWhatIfValue', response);
          this.showWhatIfActive = response;
        })
      )
    }
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
  }
  ngOnInit() {
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  hideandshowDrawer() {
    $('igx-icon.materialsidebarfontmenu.material-icons.igx-icon').click();
  }

  ChangePeriodType(budgetData, index) {
    this.ActivePeriodTabMen = parseInt(index);
    this.SpinnerService.show();
    if (this.DisplayAllCommonDataArray != undefined) {
      this.methodserviceService.savePriodTabViewIndex(this.DisplayAllCommonDataArray[0].recordNodeId, index).subscribe(
        (response => {
          localStorage.setItem("ActivePeriodTab", this.PeriodTabView[0].results[index].longLabel);
          this.SpinnerService.hide();
        })
      )
    } else {
      this.SpinnerService.hide();
    }
  }

  RemoveDuplicationArray(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

  recordModified(record: recordSaveModel) {
    this.recordmodifiedModel = record;
    // console.log(this.recordmodifiedModel);
  }

  SaveData() {
    if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
      let recordModified: any = this.recordmodifiedModel
      if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
        recordModified.budgetData.forEach(element => {
          element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
        });
        this.recordmodifiedModel = recordModified;
      }

      this.SpinnerService.show();
      this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          if (this.recordnodeType == 50004 || this.recordnodeType == 50011 || this.recordnodeType == 50017) {
            this.forecasttabsComponent.childEventClicked(response.nodeId);
          } else {
            // console.log(response);
            this.DisplayAllCommonDataArray = [];
            this.BudgetDerrivationView = [];
            this.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
            this.isRecord = true;
            this.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
            this.PeriodTabView = response.getPeriodTabView;
            this.ActivePeriodTabMen = this.PeriodTabView[0].selectedIndex;
          }

        })
    }

  }
}
