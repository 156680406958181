import { Component, OnInit, Input } from '@angular/core';
import { CurrencyServiceService } from 'src/app/service/SettingServices/CurrencyService/currency-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../services/service.service'

@Component({
  selector: 'app-updatecurrency',
  templateUrl: './updatecurrency.component.html',
  styleUrls: ['./updatecurrency.component.scss']
})
export class UpdatecurrencyComponent implements OnInit {

  CurrencyCollection: any = [];
  CurrencyValidColumn: any = [];
  CurrencyValueList: any = [];
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  ActiveRecord = '0';
  defaultIndex: any;
  @Input() updatecurrency: any;
  @Input() countrycollectionindex: any;
  ForecastStartdatevalue: any;
  RegionalCollection: any;
  UserId = localStorage.getItem('CurrentUserNodeId');
  constructor(private CurrencyServiceServices: CurrencyServiceService,
    private NewForecastService: ServiceService,
    private SpinnerService: NgxSpinnerService) { }

  async ngOnInit() {
    if (this.updatecurrency == true) {
      // console.log(this.updatecurrency);

      // this.CurrencyCollection = [];
      // this.CurrencyValidColumn = [];
      // this.CurrencyValueList = [];
      // let ForecastNodeId = localStorage.getItem('ForecastId');
      // await this.getRegioanlcollectionAndCurrency(this.UserId);

      //this.getPeriodCollection(ForecastNodeId);
      //this.SpinnerService.show();

      // await this.delay(300);
      // if (this.RegionalCollection != null) {
      //   this.CurrencyCollection = [];
      //   this.RegionalCollection.forEach((element, index) => {
      //     if (index == 0)
      //       element.regionalCurrencyCollection.forEach(children => {
      //         this.CurrencyCollection.push(children);
      //       });
      //   });
      // }

      // this.CurrencyServiceServices.getCurrencyCollections(ForecastNodeId).subscribe(
      //   (async (response:any)=>{
      //     this.SpinnerService.hide();
      //     this.CurrencyCollection = response.getCurrencyTableCollection;
      //     console.log(response);
      //     this.defaultIndex = this.CurrencyCollection[0].defaultIndex;
      //     this.ActiveRecord = this.CurrencyCollection[this.defaultIndex].selectedIndex.toString();
      //     this.CurrencyValueList = response.getBudgetData;
      //   })
      // )
    }
  }

 async getRegioanlcollectionAndCurrency(UserId) {
    if (UserId != null) {
    this.SpinnerService.show();
    await  this.NewForecastService.getRegionalCollection(UserId).subscribe(
        (response: any) => {
          this.RegionalCollection = response;
          console.log(this.RegionalCollection);
          this.SpinnerService.hide();
        }
      )
    }


  }

  getPeriodCollection(ForecastNodeId) {
    this.PeriodTabViewCollection = [];
    this.DisplayMonthCollection = [];
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      (response => {
        let PeriodViewResponse: any = response;
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }

  /** 
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async ngOnChanges() {
    if (this.updatecurrency == true) {
      // console.log(this.updatecurrency);
      this.CurrencyCollection = [];
      this.CurrencyValueList = [];
      this.CurrencyValidColumn = [];
      let ForecastNodeId = localStorage.getItem('ForecastId');
      this.getRegioanlcollectionAndCurrency(this.UserId);
      // console.log(this.countrycollectionindex);
      await this.delay(300);
      if (this.RegionalCollection != null) {
        this.CurrencyCollection = [];
        this.RegionalCollection.forEach((element, index) => {
          if (index == this.countrycollectionindex)
            element.regionalCurrencyCollection.forEach(children => {
              children["symbol"] = children.symbol;
              children["tla"] = children.tla;
              this.CurrencyCollection.push(children);
            });
          localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
          var storedNames = JSON.parse(localStorage.getItem("Currency"));
        });
       
      }
    }

  }

  /**
   * change currency name
   */
  changeCurrencyName(Currency, i, $event) {
    let newName = $event.target.value;
    this.CurrencyCollection.forEach((element, index) => {
      if (index == i) {
        element['name'] = newName;
      }
    });
    localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
    var storedNames = JSON.parse(localStorage.getItem("Currency"));
    // console.log(storedNames);
  }

  /**
   * 
   */
  async AddCurrency() {
    //this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    let newCurrency = {
      name: 'New Currency',
      symbol: '',
      tla: ''
    }
    this.CurrencyCollection.push(newCurrency);
    localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
  }
  /**
   * 
   * @param index 
   */
  changeValueList(index, CurrencyCollection) {
    this.ActiveRecord = index.toString();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.CurrencyServiceServices.setCurrencyindex(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.CurrencyServiceServices.CurrencyCollectionBudget(CurrencyCollection.nodeId).subscribe(
          ((results: any) => {
            this.CurrencyValueList = results;
          })
        )
        let index = response;
        this.ActiveRecord = index.toString();
      })
    )
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }

  DeleteCurrency(indexElement) {
    // this.SpinnerService.show();
    // let ForecastNodeId = localStorage.getItem('ForecastId');
    // this.CurrencyServiceServices.DeleteCurrency(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
    //   (async response => {
    //     this.SpinnerService.hide();
    //     this.ngOnChanges();

    //   })
    // )
    if(this.CurrencyCollection.length>1){
      this.CurrencyCollection.splice(indexElement, 1);
      if(this.CurrencyCollection.length>1){
        let ActiveIndex:any = indexElement -1;
        if(ActiveIndex == 0){
          ActiveIndex = '0';
          this.ActiveRecord = ActiveIndex;
        } else {
          this.ActiveRecord = ActiveIndex;
        }
      }
      localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
    }
  }

  ChangeActiveRecord(index){
    let Index = index;
    this.ActiveRecord = Index;
  }
  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  DafaultCurency() {
    this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.CurrencyServiceServices.DefaultCurrency(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.ngOnChanges();

      })
    )
  }
  /**
   * 
   * @param NodeId 
   * @param $event 
   */
  SetAllCurrencyList(NodeId, $event) {
    if ($event.target.value == null || $event.target.value == "") {
      return;
    }
    this.SpinnerService.show();
    let setAllValue = parseFloat($event.target.value).toFixed(2);
    this.CurrencyServiceServices.SetAllCurrency(NodeId, setAllValue, this.PeriodStartIndex, this.PeriodEndIndex).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        $event.target.value = '';
        this.CurrencyValueList = response;
      })
    )
  }

  MonthCurrencyList(NodeId, Index, $event, budgetdata) {
    let callApi = 'false';
    let defualtValue = budgetdata.bonusCommisionValuesArray[Index];
    let updatedValue = $event.target.value;
    if (updatedValue != "") {
      let value = updatedValue;
      let convertInt = parseFloat(value).toFixed(2);
      if (this.check(value.toString()) == true) {
        let data = value.replace(/\(/g, '-').replace(/\)/g, '');
        convertInt = parseFloat(data).toFixed(2);
      } else {
      }
      if (parseFloat(defualtValue).toFixed(2) != convertInt) {
        callApi = 'true';
      }
    } else {
      return;
    }
    if (callApi == 'true') {
    } else {
      return;
    }

    this.SpinnerService.show();
    let setAllValue = $event.target.value;

    this.CurrencyServiceServices.SaveValueListByMonth(NodeId, Index, setAllValue).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.CurrencyValueList = response;
      })
    )
  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingNameChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    this.SpinnerService.show();
    this.CurrencyServiceServices.setCurrencyName(ValuesList.nodeId, Value).subscribe(
      (response => {
        this.SpinnerService.hide();
      })
    )

  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingSymbolChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    if (Value != '') {
      this.CurrencyCollection.forEach((element, index) => {
        if (index == Index) {
          element['symbol'] = Value;
        }
      });
      localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
      var storedNames = JSON.parse(localStorage.getItem("Currency"));
      // this.SpinnerService.show();
      // this.CurrencyServiceServices.setCurrencySymbol(ValuesList.nodeId, Value).subscribe(
      //   (response=>{
      //     this.SpinnerService.hide();
      //   })
      // )
    }

  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingTlaChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    if (Value != '') {
      this.CurrencyCollection.forEach((element, index) => {
        if (index == Index) {
          element['tla'] = Value;
        }
      });
      localStorage.setItem("Currency", JSON.stringify(this.CurrencyCollection));
      var storedNames = JSON.parse(localStorage.getItem("Currency"));
      //   this.SpinnerService.show();
      // this.CurrencyServiceServices.setCurrencyTla(ValuesList.nodeId, Value).subscribe(
      //   (response=>{
      //     this.SpinnerService.hide();
      //   })
      // )
    }

  }

}
