<igx-content>
    
    <div *ngIf="displayvaluelistdata.length>0">
        <div>
            <form>
                <div class="defualtdisplay">
                    <div class="valuelistdisplayrow">
                            <div class="row DisplayAdjusted">
                            </div>
                            <div class="displayWithSelection {{displayvaluelistdata[0].nodeTypeEnglishLabel}}" [ngClass]="{'row':displayvaluelistdata[0].nodeTypeEnglishLabel!='TotalAdditions'}">
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                    <div class="testingdata-{{i}}"
                                        *ngFor="let result of displayvaluelistdata;let i = index">
                                        
                                        <div class="row">
                                            <div _ngcontent-jck-c424="" class="col-sm-4"><label class="display-title"
                                                    style="font-size: 14px; color: white;">Net Sales</label></div>
                                            <div _ngcontent-jck-c424="" class="col-sm-8"><label class="display-title"
                                                    style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv">
                                            <!-- <div class="display-border-after-setall"></div>  -->
                                            <div class="row" style="height: 30px;"
                                                *ngIf="result.allowSetAllEntry && !result.isConsolidation">
                                                <label class="col-sm-5" id="hidecopy-{{result.valuesNodeId}}"
                                                    *ngIf="result.allowSetAllEntry== true && result.allowValueEntry== true">Set All</label>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowSetAllEntry== true && result.allowValueEntry== true">
                                                    <input decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.valuesNodeId}}-setall"
                                                        (blur)="noDarivationsetall(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        [disabled]='result.allowSetAllEntry ==false'
                                                        allow-decimal="true" allow-negative="true" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-after-setall"></div>
                                        <div class="valuelistdisplayrow"
                                            *ngFor="let valuearr of result.bonusCommisionValuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index ; trackBy:trackByFn">
                                            <div class="row">
                                                <label class="col-sm-5 ">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                                <div class="col-sm-7" *ngIf=" result.currentActualsIndex==-1">
                                                    <input
                                                        *ngIf="result.allowValueEntry"
                                                        type="text" class=" col-sm-11 {{result.valuesNodeId}}"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.valuesNodeId, result, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                    <input
                                                        *ngIf="!result.allowValueEntry && result.nodeType != 60103"
                                                        type="text"
                                                        class=" col-sm-11 {{result.valuesNodeId}} withoutActualModeEnable"
                                                        decimals={{result.decimalPlaces}} [disabled]="true"
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.valuesNodeId, result, $event , displayvaluelistdata , 0, i+BonusesCommisionStartIndex)" />
                                                    <input
                                                        *ngIf="result.allowValueEntry && result.isConsolidation"
                                                        type="text" class=" col-sm-11 {{result.valuesNodeId}}"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled="true" />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowValueEntry && result.currentActualsIndex!=-1">
                                                    <input
                                                        *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == true"
                                                        type="text" class=" col-sm-11 {{result.valuesNodeId}}"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.valuesNodeId, result, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                    <input
                                                        *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == false"
                                                        type="text"
                                                        class=" col-sm-11 {{result.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                    <input
                                                        *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == true"
                                                        type="text" class=" col-sm-11 {{result.valuesNodeId}}"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        id="{{result.valuesNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.valuesNodeId, result, $event , displayvaluelistdata , 0,i+BonusesCommisionStartIndex)" />
                                                    <input
                                                        *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == false"
                                                        type="text"
                                                        class=" col-sm-11 {{result.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.valuesArray[i+BonusesCommisionStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div>
                                        <div class="valuelistdisplayrow Total-div" >
                                            <div class="row" *ngIf="result.hasTotal">
                                                <label class="col-sm-5">Total</label>
                                                <div class="col-sm-7" *ngIf="result.allowTotalEntry">
                                                    <input type="text" id="{{result.valuesNodeId}}-total"
                                                        class=" col-sm-11  {{result.total}}"
                                                        style="text-align: right; padding:1px;"
                                                        value="{{result.total | minussignsparen}}"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        (blur)="noDarivationtotal(result.valuesNodeId, result, $event , displayvaluelistdata , 0)"
                                                        decimals={{result.decimalPlaces}}
                                                        [disabled]="result.allowTotalEntry == false || result.isConsolidation" />
                                                </div>
                                                <label *ngIf="!result.allowTotalEntry"
                                                    id="{{result.valuesNodeId}}-total" class=" col-sm-5"
                                                    style="text-align: right; padding:1px;"
                                                    value="{{result.total | minussignsparen}}"> {{result.total |
                                                    minussignsparen}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- <div class="valuelistdisplayrow">
                        <div class="row">
                            <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                <div class="testingdata-{{i}} bonusesdata" *ngFor="let result of displayvaluelistdata;let i = index">
                                    <div class="row">
                                        <div _ngcontent-jck-c424="" class="col-sm-4"><label  class="display-title" style="font-size: 14px; color: white;">Net Sales</label></div>
                                        <div _ngcontent-jck-c424="" class="col-sm-8"><label  class="display-title" style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                    </div>
                                    <div class="valuelistdisplayrow setalldisplaydiv" *ngIf="result.allowSetAllEntry">
                                        <div class="row" *ngIf="result.englishLabel=='Bonuses'">
                                            <label class="col-sm-5 ">Set All</label>
                                            <div class="col-sm-6"><input
                                                decimals="{{result.decimalPlaces}}"
                                                style="text-align: right;" 
                                                class=" col-sm-10 {{resultNodeId}}-setall"
                                                (blur)="noDarivationsetall(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                [disabled] = 'result.allowSetAllEntry ==false'
                                                allow-decimal="true" allow-negative="true" 
                                                type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="valuelistdisplayrow setalldisplaydiv" *ngIf="result.allowOpeningEntry">
                                        <div class="row">
                                            <label class="col-sm-5 ">Opening</label>
                                            <div class="col-sm-6"><input
                                                id="{{result.opening}}"
                                                decimals="{{result.decimalPlaces}}"
                                                style="text-align: right;" 
                                                class=" col-sm-10 {{resultNodeId}}-setall  "
                                                (blur)="noDarivationopening(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                allow-decimal="true" allow-negative="true" 
                                                value="{{result.opening}}"
                                                type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="display-border-after-setall"></div> 
                                    <div class="valuelistdisplayrow {{i+BonusesCommisionStartIndex}}" *ngFor="let valuearr of result.bonusCommisionValuesArray | slice:BonusesCommisionStartIndex:BonusesCommisionEndIndex; let i = index ; trackBy:trackByFn">
                                        <div class="row">
                                        <label class="col-sm-5 " [style.font-weight]="ValueListMonthDisplay[i+BonusesCommisionStartIndex].fontWight">{{ValueListMonthDisplay[i+BonusesCommisionStartIndex].name}}</label>
                                            <div class="col-sm-6" *ngIf=" result.currentActualsIndex==-1">
                                                <input *ngIf="result.allowValueEntry"
                                                    type="text"
                                                    class=" col-sm-10 {{resultNodeId}}"
                                                    decimals={{result.decimalPlaces}}
                                                    style="text-align: right;" 
                                                    (click)="valuelistregrex($event)"
                                                    id="{{resultNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                    [(ngModel)]="result.bonusCommisionValuesArray[i+BonusesCommisionStartIndex]"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (blur)="NoderrivationMonthCalculation(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                    />
                                                    <input *ngIf="!result.allowValueEntry"
                                                    type="text"
                                                    class=" col-sm-10 {{resultNodeId}} withoutActualModeEnable"
                                                    decimals={{result.decimalPlaces}}
                                                    [disabled]="true"
                                                    style="text-align: right;" 
                                                    (click)="valuelistregrex($event)"
                                                    id="{{resultNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                    [(ngModel)]="result.bonusCommisionValuesArray[i+BonusesCommisionStartIndex]"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (blur)="NoderrivationMonthCalculation(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                    /> 
                                            </div>
                                            <div class="col-sm-6" *ngIf="result.allowValueEntry && result.currentActualsIndex!=-1">
                                                <input  *ngIf="IsEnabled(i, result) == true"
                                                type="text"
                                                class=" col-sm-10 {{resultNodeId}}"
                                                decimals={{result.decimalPlaces}}
                                                style="text-align: right;" 
                                                [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                [disabled]="false"
                                                (click)="valuelistregrex($event)"
                                                id="{{resultNodeId}}-{{i+BonusesCommisionStartIndex}}"
                                                [(ngModel)]="result.bonusCommisionValuesArray[i+BonusesCommisionStartIndex]"
                                                [ngModelOptions]="{standalone: true}"
                                                (blur)="NoderrivationMonthCalculation(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                />
                                                <input  *ngIf="IsEnabled(i+BonusesCommisionStartIndex, result) == false"
                                                type="text"
                                                class=" col-sm-10 {{resultNodeId}} ActualModeEnable"
                                                decimals={{result.decimalPlaces}}
                                                style="text-align: right;" 
                                                [style.font-weight]="FontWight(i+BonusesCommisionStartIndex, result)"
                                                [disabled]="true"
                                                [(ngModel)]="result.bonusCommisionValuesArray[i+BonusesCommisionStartIndex]"
                                                [ngModelOptions]="{standalone: true}"
                                                />
                                            </div>
                                            <div class="col-sm-6" *ngIf="result.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                <input  *ngIf="IsEnabled(i, result) == true"
                                                type="text"
                                                class=" col-sm-10 {{resultNodeId}}"
                                                decimals={{result.decimalPlaces}}
                                                style="text-align: right;" 
                                                [style.font-weight]="FontWight(i, result)"
                                                [disabled]="false"
                                                (click)="valuelistregrex($event)"
                                                id="{{resultNodeId}}-{{i}}"
                                                [(ngModel)]="result.bonusCommisionValuesArray[i]"
                                                [ngModelOptions]="{standalone: true}"
                                                (blur)="NoderrivationMonthCalculation(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                />
                                                <input  *ngIf="IsEnabled(i, result) == false"
                                                type="text"
                                                class=" col-sm-10 {{resultNodeId}} ActualModeEnable"
                                                decimals={{result.decimalPlaces}}
                                                style="text-align: right;" 
                                                [style.font-weight]="FontWight(i, result)"
                                                [disabled]="true"
                                                [(ngModel)]="result.bonusCommisionValuesArray[i]"
                                                [ngModelOptions]="{standalone: true}"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="display-border-before-total"></div> 
                                    <div class="valuelistdisplayrow" *ngIf="result.hasTotal">
                                        <div class="row">
                                            <label class="col-sm-5">Total</label>
                                            <div class="col-sm-6" *ngIf="result.allowTotalEntry">
                                                <input 
                                                type="text"
                                                id="{{resultNodeId}}-total"
                                                class=" col-sm-10 " 
                                                style="text-align: right;"
                                                value="{{result | employeetotal:BonusesCommisionStartIndex:BonusesCommisionEndIndex}}"
                                                (click)="valuelistregrex($event)"
                                                (blur)="noDarivationtotal(resultNodeId, result, $event , displayvaluelistdata , 0)"
                                                decimals={{result.decimalPlaces}}
                                                [disabled]="result.allowTotalEntry == false"/>
                                            </div>
                                            <label *ngIf="!result.allowTotalEntry"
                                            id="{{resultNodeId}}-total"
                                            class=" col-sm-5" 
                                            style="text-align: right;"
                                            value="{{result | employeetotal:BonusesCommisionStartIndex:BonusesCommisionEndIndex}}"> {{result | employeetotal:BonusesCommisionStartIndex:BonusesCommisionEndIndex}}</label>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            
                        </div>
                    </div> -->
                </div>

            </form>   
        </div>
    </div>
</igx-content>


                    