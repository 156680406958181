<div class="insidecontainer"  style="width: 1209px; height: 711px;">
    <igx-dialog-title class="dialogtitle">
        <div class="title-container">
            <div class="dialog-title">Employee Entry</div>
        </div>
    </igx-dialog-title>
    <div class="col-sm-12">
        <div class="col-sm-3">
            <div class="employeeGroup">
                <igx-card elevated>
                    <!-- <div class="ArrayValuetitle">Employee Group</div> -->
                    <igx-card-header>Employee Group</igx-card-header>
                    <div class="row" style="margin: 3px 1px 8px 9px;">
                        <button class="newForecastSubmitButton btn OkSaveButtonColor col-sm-3"  (click)="addEmployeeGroupCollection(ActiveEmployeeGroup)" >Add</button>
                        <button class="deleteCancelButtonColor btn col-sm-4"  (click)="deleteEmployeeGroupCollection(ActiveEmployeeGroup)" >Delete</button>
                    </div>
                        <igx-content>
                            <div class="listingEmployeeGropu" style="height: 572px;" *ngIf="EmployeeCollection!=null && EmployeeCollection!='' && EmployeeCollection.employees!=null">
                                <table class="table table-bordered" *ngIf=" EmployeeCollection.employees.length>0">
                                    <tr [ngClass]="{'table-active': i==ActiveEmployeeGroup}"  *ngFor="let result of EmployeeCollection.employees; let i=index" 
                                        (click)="changeEmployeeGroup(i)">
                                        <td>{{result.name}}</td>
                                    </tr>
                                </table>
                            </div>
                            
                        </igx-content>
                        
                </igx-card>
            </div>
           
        </div>
        <div class="col-sm-4" *ngIf="EmployeeCollection!=null && EmployeeCollection.employees!=null">
            <div class="EmployeeDetails" *ngIf="EmployeeCollection.employees.length>0">
                <igx-card elevated>
                <div style="height: 635px; margin: 6px 0 0 0;">
                    <div class="row">
                        <div class="col-sm-7">
                            <button igxButton (click)="saveEmployeeValue(ActiveEmployeeGroup)"
                            class="OkSaveButtonColor btn col-sm-12">Calculate & Save</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <input type="text" value="{{EmployeeCollectionDetails.name}}" class="form-control col-sm-11"
                            (blur)="EmployeeNameChange($event,ActiveEmployeeGroup)"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Number of Employee</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" min="0" value="{{EmployeeCollectionDetails.numberOfEmployees}}" class="form-control col-sm-10"
                            (blur)="NumberOfEmployeesChange($event,ActiveEmployeeGroup)">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Hours of Week</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" min="0" value="{{EmployeeCollectionDetails.hoursPerWeek}}" class="form-control col-sm-10"
                             (blur)="HoursPerWeekChange($event,ActiveEmployeeGroup)">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div>
                                <label class="col">Rate</label>
                                <input type="number" value="{{EmployeeCollectionDetails.grossWagesRate}}" (blur)="checkGrosswageRate($event,EmployeeCollectionDetails,ActiveEmployeeGroup)" class="form-control col-sm-10">
                            </div>
                        </div>
                        <div class="col-sm-6" style="border: 1px solid #a9acac;margin: 0 -5px 0px -12px;">
                            <div *ngFor="let frequncie of EmployeeCollectionDetails.validFrequenciesVM">
                                <input type="radio" 
                                (change)="changefrequncie($event,ActiveEmployeeGroup,frequncie)"
                                [checked]="EmployeeCollectionDetails.frequencyVM.id == frequncie.value.id" name="validFrequenciesVM[]" value="{{frequncie.value.id}}">{{frequncie.name}} 
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <button class="newForecastSubmitButton btn deleteCancelButtonColor col-sm-9"   (click)="Bonusescomision(Bonusescomisionform,EmployeeCollectionDetails.nodeId)">Bonus & Commision</button>
                            <igx-dialog #Bonusescomisionform [closeOnOutsideSelect]="false" [color]="red" >
                                <app-bonuses-comision [employeeBonusCommision]="employeeId"></app-bonuses-comision>
                                <div igxDialogActions>
                                    <button igxButton (click)="Bonusescomisionform.close()" class="deleteCancelButtonColor btn">CANCEL</button>
                                    <button igxButton   (click)="SaveData(Bonusescomisionform)" class="OkSaveButtonColor btn">OK</button>
                                </div>
                            </igx-dialog>
                        </div>
                        <div class="col-sm-6">
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>From Period</label>
                        </div>
                        <div class="col-sm-6">
                            <select name="FromPeriod" class="col-sm-10 form-control" (change)="EmployeeFromPeriod($event,ActiveEmployeeGroup,$event.target.value)">
                                <option [selected]="i==EmployeeCollectionDetails.fromPeriodIndex" 
                                value="{{i}}" *ngFor="let period of EmployeeCollectionDetails.validPeriodsVM; let i=index">{{period.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>To Period</label>
                        </div>
                        <div class="col-sm-6">
                            <select name="ToPeriod" class="col-sm-10 form-control" (change)="EmployeeToPeriod($event,ActiveEmployeeGroup,$event.target.value)">
                                <option [selected]="i==EmployeeCollectionDetails.toPeriodIndex" 
                                value="{{i}}" *ngFor="let period of EmployeeCollectionDetails.validPeriodsVM; let i=index;">{{period.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label >Pension Scheme</label>
                        </div>
                        <div class="col-sm-6" >
                            <select name="PensionScheme" class="col-sm-10 form-control" (change)="EmployeePensionSchema($event,ActiveEmployeeGroup,i)">
                                <option  *ngFor="let pension of EmployeeCollectionDetails.pensionsVM ; let i=index" value="{{pension.nodeId}}" 
                                [selected]="pension.nodeId == pension.selectedIndex" >{{pension.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" *ngIf="NIDisplay">
                        <div class="col-sm-6">
                            <label >NI Scheme</label>
                        </div>
                        <div class="col-sm-6">
                            <select name="NIScheme" class="col-sm-10 form-control" (change)="EmployeeNIScheme($event,ActiveEmployeeGroup,i)">
                                <option  *ngFor="let NIScheme of EmployeeCollectionDetails.nivm ; let i=index" value="{{NIScheme.nodeId}}" 
                                [selected]="NIScheme.nodeId == NIScheme.selectedIndex" >{{NIScheme.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label >Allowance</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" min="0" value="{{EmployeeCollectionDetails.allowance}}" class="form-control col-sm-10"
                            (blur)="AllowanceChange($event,ActiveEmployeeGroup)">
                        </div>
                    </div>
                    
                    
                    </div>
                </igx-card>    
            </div>
        </div>
        <div class="col-sm-5" *ngIf="EmployeeCollection!=null && EmployeeCollection!='' && EmployeeCollectionDetails!=null && EmployeeCollectionDetails.displayedValueLists!=null">
            <igx-card elevated>
                <div class="bs-example" style="height: 635px; margin: 6px 0 0 0;" *ngIf="EmployeeCollectionDetails.displayedValueLists.length>0">
                    <ul class="nav nav-tabs" style="margin: 1px 3px 16px 11px">
                        <li class="nav-item" style="margin: 0 8px -2px -1px;">
                            <select  form="carform" class="form-control" style="background: #1e90ff;color: white;font-size: 12px;font-weight: bold;height: 38px;">
                                <option  value="Annual" ng-reflect-value="Annual">Annual</option>
                                <option  value="All" ng-reflect-value="All">All</option>
                        </select></li>
                        <li class="nav-item" 
                        *ngFor="let result of EmployeeCollectionDetails.displayedValueLists[0].results;  let i=index" (click)="ChangePeriodType(result, i)" >
                            <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}" data-toggle="tab">{{result.longLabel}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade testingData"
                        *ngFor="let Resultsbody of EmployeeCollectionDetails.displayedValueLists[0].results; let data=index;" id="{{Resultsbody.longLabel}}" [ngClass]="{'active':data == Activetabmenu}">
                            <div class="col-sm-12" style="padding: 0px;">
                                <div class="col-sm-6" *ngFor="let valuelistCollection of EmployeeCollectionDetails.displayedValueLists">
                                    <igx-card elevated>
                                        <igx-card-header>{{valuelistCollection.valueList.englishLabel}}</igx-card-header>
                                        <igx-content>
                                            <div *ngIf="valuelistCollection.valueList.englishLabel!='Group PAYE'" style="margin-bottom: 3px;">
                                                <div class="ArrayValue col" *ngFor="let monthArray of valuelistCollection.results | slice:EmployeeCycleTotalindex:EmployeeCycleTotalindex+1">
                                                    <div class="col-sm-4"><label>{{monthArray.longLabel}}</label></div>
                                                    <div class="col-sm-8">
                                                        <input type="text" disabled="true" value="{{monthArray.value | employeeCalculation}}" class="form-control col-sm-12" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="valuelistCollection.valueList.englishLabel == 'Group PAYE'" style="height: 380px;">
                                                <div class="ArrayValue col" *ngFor="let monthArray of valuelistCollection.results | slice:GroupPayStartIndex:GroupPayEndIndex+1">
                                                    <div class="col-sm-4"><label>{{monthArray.longLabel}}</label></div>
                                                    <div class="col-sm-8">
                                                        <input type="text" disabled="true" value="{{monthArray.value | employeeCalculation}}" class="form-control col-sm-12" />
                                                    </div>
                                                </div>
                                                <div class="ArrayValue col">
                                                    <div class="col-sm-4"><label>Total</label></div>
                                                    <div class="col-sm-8">
                                                        <input type="text" disabled="true" value="{{valuelistCollection.valueList | employeetotal:GroupPayStartIndex: GroupPayEndIndex+1}}" class="form-control col-sm-12" />
                                                    </div>
                                                </div>
                                            </div>
                                        </igx-content>
                                       
                                    </igx-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </igx-card>
            
        </div>
    </div>
</div>