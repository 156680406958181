import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewContainerRef, TemplateRef } from '@angular/core';
import { MethodserviceService } from 'src/app/component/record/method/service/methodservice.service';
import { TreeviewService } from 'src/app/service/treeview.service';
import { ValuelistComponent } from 'src/app/component/record/valuelist/valuelist.component';
import { NgxSpinnerService } from "ngx-spinner";
import { EmployeeGroupService } from 'src/app/service/EmployeeGroupService/employee-group.service';
import { BonusesComisionComponent } from '../bonuses-comision.component';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import * as $ from 'jquery';
// import { ok } from 'assert';
import { FormulaModel } from 'src/app/model/FormulaModel';

@Component({
  selector: 'app-bonuscommision-comon-html',
  templateUrl: './bonuscommision-comon-html.component.html',
  styleUrls: ['./bonuscommision-comon-html.component.scss']
})
export class BonuscommisionComonHtmlComponent {


  @Input() valuelistdata;
  @Input() AllCommonDisplayBudgetData;
  @Input() valueListDerrivation;
  @Input() ValueListMonthDisplay;
  @Input() PeriodtabViewData;
  @Input() startIndex;
  @Output() recordModifiedEvent = new EventEmitter<recordSaveModel>();
  darrivationnode: any;
  inputdarrivation: any;
  displayvaluelistdata: any = [];
  displaydarrivation: any = [];
  sectiontreeview: any;
  displaynodevisehtml: any;
  months = [];
  getProfileCollection = [];
  addeddata: boolean;
  selectedProfile: any;
  selectedNodeIds: any;
  percentagevaluearray: any;
  nodarrivationarray = [];
  darrivationarray = [];
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedloanTakenRecord: any = [];
  loantakenarray: any = {}
  additionvaluearray: any
  DerivationOptions: any;
  stockcomponent: any;
  SubDerrivationOptions: any;
  ProfileCollectionBool: boolean;
  ValidDerivationSubTypes = [];
  ValidDerivationSubTypesName = [];
  derivationSubType: any;
  //performance 
  CommonHtmlArrayResult: any;
  PeriodtabViewResults: any = [];

  BonusesCommisionStartIndex = 12;
  BonusesCommisionEndIndex = 23;
  bonusrecordModifiedModel: recordSaveModel;
  DisplayCalculateAsBalance: boolean;
  formulamodifiedModel: FormulaModel;

  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  //
  constructor(
    private methodservice: MethodserviceService,
    private TreeviewServices: TreeviewService,
    private ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService,
    private employeeGroupService: EmployeeGroupService,
    private bonusesComisionComponent: BonusesComisionComponent) {

    this.activeId = localStorage.getItem('ForecastId');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  @ViewChild('tree') tree;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async onTreeLoad() {
    await this.delay(800);
    this.tree.treeModel.expandAll();
  }


  BonnusDerrivation($event, DisplayData) {
    let DerrivationId = $event.target.value;
    let ValueNodeId = DisplayData.nodeId;
    this.SpinnerService.show();
    this.employeeGroupService.changeBonusesCommisonDerrivation(DerrivationId, ValueNodeId).subscribe(
      (response => {
        this.SpinnerService.hide();
        let DisplayAllBonusCollectionValue = response
        this.bonusesComisionComponent.displaydata = DisplayAllBonusCollectionValue.getAllCommisionValue;
        this.bonusesComisionComponent.BudgetDerrivationView = DisplayAllBonusCollectionValue.getCommisionDerrivation;
        this.bonusesComisionComponent.MonthDisplayView = DisplayAllBonusCollectionValue.getMonthDisplay;
        this.bonusesComisionComponent.ngOnChanges();
      })
    )
  }
  DisplayArray(data, Display) {
    let result = data;
    switch (Display) {
      case 2:
        if (result < 0)
          result = 0;
        break;
      case 3:
        if (result > 0)
          result = 0;
        break;
      case 4:
        result = -result;
        break;
      case 5:
        if (result > 0)
          result = 0;
        else
          result *= -1;
        break;
      case 6:
        if (result < 0)
          result = 0;
        else
          result *= -1;
        break;
      case 7:
        result = Math.abs(result);
        break;
      case 8:
        result = -Math.abs(result);
        break;
      case 9:
        result *= 100;
        break;
    }
    return result;
  }
  async ngOnChanges() {
    this.DisplayCalculateAsBalance = false;
    let listarray = [];
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.selectedProfile = '';
    this.sectiontreeview = '';
    this.inputdarrivation = '';
    this.addeddata = false;
    this.DerivationOptions = '';
    this.SubDerrivationOptions = [];
    this.ValidDerivationSubTypes = [];
    this.ValidDerivationSubTypesName = [];
    this.derivationSubType = null;
    this.displaynodevisehtml = this.valuelistdata;
    if (this.valuelistdata != null && this.startIndex != undefined) {
      this.BonusesCommisionStartIndex = parseInt(this.startIndex) * 12;
      this.BonusesCommisionEndIndex = this.BonusesCommisionStartIndex + 12;
      this.BonusesCommisionStartIndex = this.BonusesCommisionStartIndex;
      this.BonusesCommisionEndIndex = this.BonusesCommisionEndIndex;
      let endIndexBonus = this.BonusesCommisionStartIndex + 11;
      let NodeId = this.displaynodevisehtml.recordNodeId;
      let DerivationNodeId = this.displaynodevisehtml.derivationType;
      if (this.valueListDerrivation == undefined) {
        if (this.PeriodtabViewData.length > 0) {
          let totaldisplayvalue = 0;
          for (let index = this.BonusesCommisionStartIndex; index <= endIndexBonus; index++) {
            if (this.PeriodtabViewData[index].value != undefined) {
              if (typeof this.PeriodtabViewData[index].value == 'number') {
                let value = this.PeriodtabViewData[index].value;
                totaldisplayvalue += value;
              } else {
                let elementvalue = this.PeriodtabViewData[index].value.replace(/\(/g, '-').replace(/\)/g, '');
                totaldisplayvalue += elementvalue;
              }
            }

          }
          this.PeriodtabViewData.forEach((valuesarraylist) => {
            this.displaynodevisehtml.opening = this.displaynodevisehtml.opening;
            if (Math.sign(valuesarraylist.value) == -1) {
              let absValue = Math.abs(valuesarraylist.value);
              let realvalue = "(" + absValue + ")";
              valuesarraylist.value = realvalue;
            } else {
            }
          });

          let totalvalue: any = totaldisplayvalue;
          this.valuelistdata['total'] = parseFloat(totalvalue).toFixed();
          this.displayvaluelistdata.push(this.valuelistdata);
          this.PeriodtabViewResults = this.PeriodtabViewData;
        }

      } else {
        let valueNodeId = this.displaynodevisehtml.values.valuesNodeId;
        let totaldisplayvalue = 0;
        this.nodarrivationarray = [];
        this.displaynodevisehtml.values.valuesArray.forEach((valuesarraylist, index) => {
          let stockOnhandArray = 0;
          if (this.displaynodevisehtml.hasOpening == true) {
            if (index == 0) {
              stockOnhandArray = valuesarraylist + this.displaynodevisehtml.opening;
            } else {
              stockOnhandArray = this.displaynodevisehtml.opening;
              for (let i = 0; i <= index; i++) {
                stockOnhandArray += this.displaynodevisehtml.values.valuesArray[i];
              }
            }
            valuesarraylist = stockOnhandArray;
          }
          if (this.displaynodevisehtml.nodetype == 61082) {
            if (this.displaynodevisehtml.opening >= 0) {
              this.displaynodevisehtml.opening = "(" + (this.displaynodevisehtml.opening * -1).toFixed(2) + ")";
            } else {
              this.displaynodevisehtml.opening = (this.displaynodevisehtml.opening);
            }
          } else {
          }
          this.displaynodevisehtml.opening = this.displaynodevisehtml.opening;
          valuesarraylist = valuesarraylist;
          if (Math.sign(valuesarraylist) == -1) {
            let absValue = Math.abs(valuesarraylist);
            let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            let realvalue = "(" + numberwithcomma + ")";
            this.nodarrivationarray.push(realvalue);
          } else {
            this.nodarrivationarray.push(valuesarraylist.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
          }

        });

        let datastartIndex = this.startIndex * 12;
        let dataEndIndex = datastartIndex + 12;

        for (let t = datastartIndex; t < dataEndIndex; t++) {
          totaldisplayvalue = this.displaynodevisehtml.values.valuesArray[t] + totaldisplayvalue;
        }

        this.displaynodevisehtml.values.valuesArray = [];
        this.displaynodevisehtml.values.valuesArray = this.nodarrivationarray;
        // console.log(this.displaynodevisehtml.values.valuesArray);
        this.valuelistdata['total'] = parseFloat(totaldisplayvalue.toString()).toFixed(0);
        if (this.valuelistdata.values.englishLabel == 'Sub Result') {
          valueNodeId = this.displaynodevisehtml.valuesNodeId;
        }
        if (this.valuelistdata.nodeTypeEnglishLabel === 'CapitalPaidNextYear' && this.valuelistdata.derivationEngType == "ManuallyEntered") {
          $('.valuelistmain' + this.valuelistdata.nodeTypeEnglishLabel).addClass('hidden');
        }
        if (this.valuelistdata.nodeTypeEnglishLabel != 'CapitalPaidNextYear') {
          this.DerivationOptions = '';
          // this.methodservice.getDerrivation(NodeId, DerivationNodeId , valueNodeId).subscribe( 
          //   async (response) => { 
          let getdarrivationallvalues: any = this.valueListDerrivation;
          if (getdarrivationallvalues.derivationOptions.length > 0) {
            let SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];
            if (SubDerrivationOptions.hasOwnProperty("items") == true) {
              this.SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];
            }
          }
          if (this.valuelistdata.values.validDerivationSubTypes != '') {
            this.ValidDerivationSubTypes = this.valuelistdata.values.validDerivationSubTypes;
            this.ValidDerivationSubTypesName = this.valuelistdata.values.validDerrivationSubTypeName;
            this.derivationSubType = this.valuelistdata.derivationSubType;
          }
          if (getdarrivationallvalues.inputs) {
            let inputvalue: any = getdarrivationallvalues.inputs;
            if (inputvalue.length > 0) {
              if (inputvalue[0].periods != undefined) {
                if (inputvalue[0].periods.length > 0) {
                  inputvalue[0].periods.shift();
                }
              }
              inputvalue.forEach(element => {
                let totaldarrivationvalues = 0;
                this.percentagevaluearray = []
                this.darrivationarray = [];
                this.additionvaluearray = [];
                element.valuesArray.forEach(valuesarraylist => {
                  //totaldarrivationvalues += valuesarraylist;
                  if (element.englishLabel == "Percentages") {
                    this.percentagevaluearray.push((valuesarraylist));
                  }
                });
                if (this.percentagevaluearray != '') {
                  element.valuesArray = this.percentagevaluearray;
                }
                element.valuesArray.forEach(valuelistingarray => {
                  let valuelistarrayResult = valuelistingarray;
                  if (inputvalue[0].englishLabel == 'Payment Profile') {
                    if (inputvalue[0].periods.length > 0) {
                      valuelistarrayResult = (valuelistingarray * 100).toFixed(2);
                    }
                  }
                  if (element.display == 4) {
                    if (Math.sign(valuelistingarray) == -1) {
                      valuelistarrayResult = Math.abs(valuelistingarray);
                    }
                  }
                  if (Math.sign(valuelistarrayResult) == -1) {
                    let absValue = Math.abs(valuelistarrayResult);
                    let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    let realvalue = "(" + numberwithcomma + ")";
                    this.darrivationarray.push(realvalue);
                  } else {
                    this.darrivationarray.push(valuelistarrayResult.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
                  }
                });
                element.valuesArray = this.darrivationarray;
                for (let t = datastartIndex; t < dataEndIndex; t++) {
                  totaldarrivationvalues += parseInt(element.valuesArray[t]);
                }
                //element['total'] = "";
                element['total'] = parseFloat(totaldarrivationvalues.toString()).toFixed(0);;
              });
            }
          }
          if (this.valuelistdata.nodeType == 60103) {
            this.valuelistdata.valuesArray.forEach((element, index) => {
              if (element == 0) {
                // this.valuelistdata.valuesArray[index] = 0;
              } else {
                this.valuelistdata.valuesArray[index] = this.DisplayArray(element, this.valuelistdata.display);
                if (Math.sign(this.valuelistdata.valuesArray[index]) == -1) {
                  let absValue = Math.abs(this.valuelistdata.valuesArray[index]);
                  let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                  let realvalue = "(" + numberwithcomma + ")";
                  this.valuelistdata.valuesArray[index] = 0;
                } else {
                  this.valuelistdata.valuesArray[index] = 0;
                }
              }

            });
          }

          if (this.valuelistdata.isBalance) {
            this.valuelistdata.valuesArray.forEach((element, index) => {
              let data: any;
              if (this.check(element.toString()) == true) {
                data = element.replace(/\(/g, '-').replace(/\)/g, '');
                data = data.replaceAll(",", '');
              } else {
                data = element.toString();
                data = data.replaceAll(",", '');
              }

              if (Math.sign(data) == -1) {
                let absValue = Math.abs(data);
                let numberwithcomma = absValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                let realvalue = "(" + numberwithcomma + ")";
                this.valuelistdata.valuesArray[index] = realvalue;
                this.valuelistdata.values.valuesArray[index] = realvalue;
                element = realvalue;
              } else {
                this.valuelistdata.valuesArray[index] = data;
                this.valuelistdata.values.valuesArray[index] = data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              }
            });
            this.valuelistdata.values.valueArray = this.valuelistdata.valuesArray;
          }
          this.darrivationnode = this.valueListDerrivation;
          if (this.darrivationnode.derivationOptions.length > 0) {
            if (this.darrivationnode.derivationOptions[0].storedValue != null) {
              this.selectedProfile = this.darrivationnode.derivationOptions[0].storedValue.toFixed(0);
            }
            if (this.darrivationnode.derivationOptions[0].selectableEntities) {
              this.getProfileCollection = this.darrivationnode.derivationOptions[0].selectableEntities;
              this.ProfileCollectionBool = true;

            }
          }
          // treeviews
          //console.log(this.darrivationnode);
          if (this.darrivationnode.targetNodeID != '') {
            let s2 = this.darrivationnode.targetNodeID.substring(1);
            const usingSplit = s2.split(',');
            if (usingSplit != '') {
              this.SpinnerService.show();
              this.TreeviewServices.getSelectedTreeMenu(s2, this.darrivationnode.derivationName, this.darrivationnode.derivationType).subscribe((response) => {
                this.addeddata = true;
                //selected Ids
                this.SpinnerService.hide();
                if (this.darrivationnode.selectedNodeIds != '') {
                  this.selectedNodeIds = this.darrivationnode.selectedNodeIds;
                }
                this.sectiontreeview = response;
              },
                err => {
                  this.SpinnerService.hide();
                  throw err;
                })
            }
          }
          //total calculations
          this.valuelistdata.opening = this.DisplayArray(this.valuelistdata.opening, this.valuelistdata.display);
          if (this.valueListDerrivation.hasOwnProperty("inputs") == true) {
            let profiletotal = 0;
            if (this.valueListDerrivation.inputs.length > 0) {
              if (this.valueListDerrivation.inputs[0].englishLabel == 'Even Spread') {
                for (let t = datastartIndex; t < dataEndIndex; t++) {
                  profiletotal += parseInt(this.valueListDerrivation.inputs[0].valuesArray[t]);
                }
                this.valueListDerrivation.inputs[0]["total"] = profiletotal;
              }
            }
          }
          this.displaydarrivation.push(this.valueListDerrivation);
          this.displayvaluelistdata.push(this.valuelistdata);

          console.log(this.valueListDerrivation);
          await this.delay(1000);
          if (this.displaydarrivation[0].inputs.length == 0) {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
          }
          if (this.displaydarrivation[0].inputs.length == 0 && this.displaydarrivation[0].selection) {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
          }
          if (this.displaydarrivation[0].inputs.length > 0) {
            this.displaydarrivation[0].inputs.forEach(element => {
              element['customenglishname'] = element.englishLabel.replace(/\s/g, "");
            });
          }
          $('.custom-OverdraftInterestRate').addClass('col-sm-5');
          if (this.displaydarrivation[0].inputs.length >= 2) {
            if (this.displaydarrivation[0].targetNodeID != '') {
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
            } else {
              if (this.displaydarrivation[0].inputs.length >= 3) {
                $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
                $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col');
              } else {
                $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');
                $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
              }
            }
          }
          if (this.displaydarrivation[0].inputs.length == 1 || this.displaydarrivation[0].inputs.length == 2 || this.displaydarrivation[0].inputs.length == 3) {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-10');
            console.log(this.displaydarrivation[0].targetNodeID);
            if (this.displaydarrivation[0].targetNodeID != '') {
              $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4');

              $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
              if (((this.displaydarrivation[0].inputs.length + 3) * 2 + 1 - 1) == 8) {
                $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-6');
                $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-8');
              } else {
                $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-' + ((this.displaydarrivation[0].inputs.length + 3) * 2 + 1 - 1));
              }
            } else {
              $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
              if (this.displaydarrivation[0].targetNodeID == "") {
                $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-4');
              } else {
                $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-' + ((this.displaydarrivation[0].inputs.length + 1.5) * 2 + 1 - 1));
              }

            }
          } else {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-3');
          }
          if (this.displaydarrivation[0].inputs.length == 0 && this.displaydarrivation[0].targetNodeID != '') {
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-3');
            $('.valuelistmain' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-6');
          }
          if (this.displaydarrivation[0].englishLabel == "From Employees") {
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4 , col-sm-3');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-2');
          }

          if (this.displaydarrivation[0].englishLabel == "Costs Units") {
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4 , col-sm-3');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-2');
          }
          if (this.displaydarrivation[0].englishLabel == "Sales Units") {
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4 , col-sm-3');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-2');
          }
          if (this.displayvaluelistdata[0].derivationEngType == 'FromStockOnHand') {
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).removeClass('col-sm-4 , col-sm-3');
            $('.displayvaluedisbaled-' + this.displayvaluelistdata[0].nodeTypeEnglishLabel).addClass('col-sm-2');
          } else {
          }
          if (this.displaydarrivation[0].nodeType == "80048") {
            $('.displayvaluedisbaled-NetSales').removeClass('col-sm-3');
            $('.displayvaluedisbaled-NetSales').addClass('col-sm-2');
          }
          $('.OverdraftInterestRate').addClass('col-sm-5');
          $('.custom-OverdraftFacilityAmount').removeClass('col-sm-3');
          $('.custom-OverdraftFacilityAmount').addClass('col-sm-2');
          //})
        }
        if (this.valuelistdata.Stockcomponent == true) {
          this.stockcomponent = true;
        }
      }

    }

  }

  saveFormulaCollection(formulaform, result) {
    let NodeId = result[0].nodeId;
    console.log(result);
    this.SpinnerService.show();
    this.methodservice.SavingFormulaCollection(NodeId, this.formulamodifiedModel).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.bonusesComisionComponent.ngOnChanges();
        formulaform.close();

        // this.ValuelistComponent.DisplayAllCommonDataArray = [];
        // this.ValuelistComponent.BudgetDerrivationView = [];
        // this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        // this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        // this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;

      })
    )

  }
  formulaModified(formula: FormulaModel) {
    this.formulamodifiedModel = formula;
    // console.log("FormualModified save function", this.formulamodifiedModel);
  }

  IsEnabled(Index, ValueList) {

    if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget)
      return ValueList.actualsMode == this.ActualsMode.Enterable;
    else
      return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
  }

  IsActual(Index, ValueList) {
    return Index <= ValueList.currentActualsIndex;
  }

  FontWight(Index, ValueList) {

    if (this.IsActual(Index, ValueList)) {
      if (ValueList.actualsMode >= this.ActualsMode.DerivedAsBudget)
        return "Bold";
      else if (ValueList.actualsMode == this.ActualsMode.Hidden)
        return "Thin";
    }
    return "Normal";
  }
  Testing(Index, ValueList) {
    if (ValueList.localizedLabel == "Exchange Movement") {
      if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget) {

        return ValueList.actualsMode == this.ActualsMode.Enterable;
      }
      else
        return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
    }
  }


  IsVisiable(Index, ValueList) {
    return Index >= ValueList.startIndex && Index <= ValueList.endIndex && (ValueList.actualsMode > this.ActualsMode.Hidden || !this.IsActual(Index, ValueList));
  }



  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Month Calculation Function
   * 
   * @ Return Budgetdata
   */

  monthCalculation($event, budgetdata, valuesNodeId, IsDerivationNumber) {

    let budgetdatavaluearray = [];

    budgetdata.valuesArray.forEach(element => {
      if (this.check(element.toString()) == true) {
        let data = element.replace(/\(/g, '-').replace(/\)/g, '');
        budgetdatavaluearray.push(parseInt(data));
      } else {
        let data = element;
        budgetdatavaluearray.push(parseInt(data));
      }
    });
    let nodetype: any;
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: Number,
      total: 0,
      startindex: Number,
      endindex: Number
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }

    if (budgetdata !== '') {
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuesNodeId[0].values.valuesNodeId;
      displaydata.values = budgetdatavaluearray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }
    this.methodservice.saveRecord(convertjson).subscribe(
      (response) => {
        response.forEach(element => {
          if (element.valuesNodeId == valuesNodeId[0].values.valuesNodeId) {
            this.valuelistdata = element;
            this.ngOnChanges();
            $('#' + valuesNodeId[0].recordNodeId).click();
          }
        });
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }



  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */

  NoderrivationMonthCalculation(eventname, budgetdata, $event, displaydata, Isdarrivation, valuelistindex) {
    //console.log("NoderrivationMonthCalculation");
    if ($event.target.value == '') {
      $event.target.value = 0;
    }
    this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, valuelistindex);

    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
      if (this.check(budgetdata.valuesArray[loopindexstart].toString()) == true) {
        let data = budgetdata.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
        totalgetelemtbyclassnamearray.value = parseFloat(data);
        total += parseFloat(data);
        resultarray.push(parseFloat(data).toFixed(2));
      } else {
        totalgetelemtbyclassnamearray.value = parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', ''));
        total += parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', ''));
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', '')).toFixed(2))
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    console.log(this.bonusrecordModifiedModel);
    this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
  }


  modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, valuelistindex) {
    if (localStorage.getItem("recordModified") != undefined || localStorage.getItem("recordModified") != null)
      this.bonusrecordModifiedModel = JSON.parse(localStorage.getItem("recordModified"));
    if (this.bonusrecordModifiedModel == undefined || this.bonusrecordModifiedModel == null) {
      this.bonusrecordModifiedModel = new recordSaveModel();
      this.bonusrecordModifiedModel.recordNodeID = displaydata[0].recordNodeId;
    }
    let budgetDataAvailable: boolean = false;
    this.bonusrecordModifiedModel.budgetData.forEach(x => {
      if (x.valuenodeId == budgetdata.nodeId)
        budgetDataAvailable = true;
    })
    if (budgetDataAvailable === false) {
      let budgetData = new budgetdatamodified();
      budgetData.valuenodeId = budgetdata.nodeId;
      budgetData.isDerivation = Isdarrivation;
      if (budgetdata.englishLabel == 'Sub Result') {
        budgetData.valuenodeId = displaydata[0].valuesnodeId;
      }
      if (budgetData.valuenodeId == undefined) {
        budgetData.valuenodeId = displaydata[0].valuesNodeId;
      }
      this.bonusrecordModifiedModel.budgetData.push(budgetData);
    }

    if (this.bonusrecordModifiedModel.recordNodeID == displaydata[0].recordNodeId) {
      let budgetData: budgetdatamodified;
      let valuenodeId = budgetdata.nodeId;
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = displaydata[0].valuesNodeId;
      }
      if (valuenodeId == undefined) {
        valuenodeId = displaydata[0].valuesNodeId;
      }
      this.bonusrecordModifiedModel.budgetData.forEach(function (element) {
        if (element.valuenodeId == valuenodeId) {
          budgetData = element;
        }
      })
      if (budgetData != null) {
        let value: string = budgetdata.valuesArray[valuelistindex].replaceAll(',', '');
        if (value == '') {
          value = '0.0';
        }
        let convertInt = parseFloat(value).toFixed(2);
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '');
          convertInt = parseFloat(data).toFixed(2)
        }
        if (convertInt != budgetdata.bonusCommisionValuesArray[valuelistindex]) {
          this.removeValueModified(budgetData, valuelistindex);
          let valuelistMod = new valuelistModified();
          valuelistMod.index = valuelistindex;
          valuelistMod.value = convertInt.toString();
          budgetData.valuelist.push(valuelistMod);
          this.bonusrecordModifiedModel.budgetData.forEach(x => {
            if (x.valuenodeId == valuenodeId) {
              x = budgetData;
            }
          })
        }
      }
      localStorage.removeItem("recordModified")
      localStorage.setItem("recordModified", JSON.stringify(this.bonusrecordModifiedModel));
    }
  }

  removeValueModified(budgetData, index) {
    var valueListItem = budgetData.valuelist.findIndex(x => x.index == index);
    if (valueListItem != -1) {
      budgetData.valuelist.splice(valueListItem, 1);
    }
  }
  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */
  derrivationmonthCalculation(eventname, budgetdata, $event, displaydata, Isdarrivation, valuelistindex) {
    if ($event.target.value == "") {
      $event.target.value = 0;
      return;
    }
    if (eventname === "Cycle Totals") {
      valuelistindex = valuelistindex - 1;
    }
    this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, valuelistindex);
    if (displaydata[0].derivationEngType.startsWith("AsPercentageOf")) {
      let currentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
      if (currentValuesArray == null) {
        currentValuesArray = [];
      }
      var totalvalue = 0.0;
      for (let i = 0; i < budgetdata.valuesArray.length; i++) {
        if (budgetdata.valuesArray[i] > 0) {
          if (currentValuesArray.length > 0) {
            var calculatedValue = (currentValuesArray[i] * (budgetdata.valuesArray[i].replaceAll(',', '') / 100)).toFixed(0);
            totalvalue = totalvalue + parseInt(calculatedValue);
            this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
          } else {
            var calculatedValue = ((budgetdata.valuesArray[i].replaceAll(',', '') / 100)).toFixed(0);
            totalvalue = totalvalue + parseInt(calculatedValue);
            this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
          }
        }
        else {
          this.valuelistdata.values.valuesArray[i] = 0;
        }
      }
      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        inputValue.value = this.converttoDisplayValue(totalvalue.toString());
      }
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    if (eventname === "Cycle Totals") {
      var cycleTotalProfile = 0.00;
      var actualsProfileTotal = 0.00;
      var accumulatedProfile = 0.00;
      var cycleTotalValue = $event.target.value.replaceAll(',', '');
      var totalID = displaydata[0].valuesNodeId + "-total";
      var totalNetSales: any = (<HTMLInputElement>document.getElementById(totalID));
      let defaultProfile: any;
      defaultProfile = this.valueListDerrivation.profile.valuesArray;
      if (displaydata[0].currentActualsIndex != -1 && displaydata[0].currentActualsIndex >= this.BonusesCommisionStartIndex) {
        for (let i = this.BonusesCommisionStartIndex; i <= displaydata[0].currentActualsIndex; i++) {
          cycleTotalValue = cycleTotalValue - displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + i];
        }
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
        for (let i = 0; i <= displaydata[0].currentActualsIndex; i++) {
          actualsProfileTotal = parseFloat(actualsProfileTotal.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      else {
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      if (cycleTotalProfile > 0) {
        var accumulatedTotal = 0.00;
        for (loopindexstart = 0; loopindexstart < displaydata[0].values.valuesArray.length; ++loopindexstart) {
          var profileValue = defaultProfile[loopindexstart];
          accumulatedProfile = accumulatedProfile + profileValue;
          var resultValue = Math.round(cycleTotalValue * (accumulatedProfile / (cycleTotalProfile - actualsProfileTotal))) - accumulatedTotal;
          accumulatedTotal = accumulatedTotal + resultValue;
          displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(resultValue.toString());
        }
        if (totalNetSales != null) {
          totalNetSales.value = this.converttoDisplayValue(accumulatedTotal.toString());
        }
      }
    }
    for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
      if (budgetdata.customenglishname == "PaymentProfile") {
        totalgetelemtbyclassnamearray.value = parseInt(budgetdata.valuesArray[loopindexstart].replaceAll(',', ''));
        total += parseInt(budgetdata.valuesArray[loopindexstart].replaceAll(',', ''));
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', '')).toFixed(2))
      } else {
        totalgetelemtbyclassnamearray.value = parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', '')).toFixed(2);
        total += parseInt(budgetdata.valuesArray[loopindexstart].replaceAll(',', ''));
        resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart].replaceAll(',', '')).toFixed(2))
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    if (displaydata[0].derivationType == "80014" || displaydata[0].derivationType == "80048" || displaydata[0].derivationType == "80050") {
      let total80014 = 0.0;
      for (let i = 0; i < this.valuelistdata.values.valuesArray.length; i++) {
        var valueInput1 = this.valueListDerrivation.inputs[0].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var valueInput2 = this.valueListDerrivation.inputs[1].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var result = (parseFloat(valueInput1) * parseFloat(valueInput2));
        total80014 = total80014 + result;
        this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(result.toString());
        var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
        if (inputValue != null) {
          inputValue.value = this.converttoDisplayValue(total80014.toString());
        }
      }

    }
    this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
  }
  /**
   * 
   * @param eventdata 
   * @param displaydata 
   */

  monthCalculationbysetallandtotal($event, budgetdata, resultarray, valuesNodeId, IsDerivationNumber) {
    let nodetype: any;
    let valuenodeId = valuesNodeId[0].values.valuesNodeId;
    let openingbalance: any = 0;
    let budgetdatavaluearray = [];
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      IsDerivation: IsDerivationNumber,
      setAllValue: 0,
      total: 0,
      startindex: Number,
      endindex: Number,
      opening: Number,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    if (IsDerivationNumber > 0) {
      nodetype = budgetdata.englishLabel;
    } else {
      nodetype = valuesNodeId[0].nodeTypeEnglishLabel;
    }
    if (budgetdata !== '') {
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = valuesNodeId[0].valuesnodeId;
      }
      convertjson.NodeID = valuesNodeId[0].recordNodeId;
      convertjson.ValueNodeID = valuenodeId;
      displaydata.opening = openingbalance;
      displaydata.values = resultarray;
      displaydata.ValueNodeID = budgetdata.valuesNodeId;
      displaydata.setAllValue = 0;
      displaydata.derivationType = valuesNodeId[0].derivationEngType;
      displaydata.NodeType = nodetype.replace(/\s/g, "");
      displaydata.startindex = budgetdata.startIndex;
      displaydata.endindex = budgetdata.endIndex;
      displaydata.IsDerivation = IsDerivationNumber;
      displaydata.total = 0;
    }
    this.SpinnerService.show();
    this.methodservice.saveRecord(convertjson).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
  /***
   * Change even spread dropdwon event
   * 
   * change valuelist and calucaltion
   * 
   */
  changeEvenSpreaddata(eventdata, displaydata) {
    this.methodservice.selectProfileOptions(eventdata, displaydata[0].recordNodeId, displaydata[0].values.valuesNodeId).subscribe(
      (response) => {
        let nodeId = displaydata[0].recordNodeId
        this.methodservice.getBudgetData(nodeId).subscribe(
          (data) => {
            data.forEach(element => {
              if (element.valuesNodeId == displaydata[0].values.valuesNodeId) {
                this.valuelistdata = element;
                this.displaydarrivation = response;
                this.ngOnChanges();
              }
            });
          })
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
  modifyNodeSelection(budgetdata, displaydata, Isdarrivation, selectedNodeId, unselectedNoedId) {
    if (localStorage.getItem("recordModified") != undefined || localStorage.getItem("recordModified") != null)
      this.bonusrecordModifiedModel = JSON.parse(localStorage.getItem("recordModified"));
    if (this.bonusrecordModifiedModel == undefined || this.bonusrecordModifiedModel == null) {
      this.bonusrecordModifiedModel = new recordSaveModel();
      this.bonusrecordModifiedModel.recordNodeID = displaydata.recordNodeId;
    }
    let budgetDataAvailable: boolean = false;
    this.bonusrecordModifiedModel.budgetData.forEach(x => {
      if (x.valuenodeId == budgetdata.nodeId)
        budgetDataAvailable = true;
    })
    if (budgetDataAvailable === false) {
      let budgetData = new budgetdatamodified();
      budgetData.valuenodeId = budgetdata.nodeId;
      budgetData.isDerivation = Isdarrivation;
      if (budgetdata.englishLabel == 'Sub Result') {
        budgetData.valuenodeId = displaydata.valuesnodeId;
      }
      this.bonusrecordModifiedModel.budgetData.push(budgetData);
    }
    if (this.bonusrecordModifiedModel.recordNodeID == displaydata.recordNodeId) {
      let budgetData: budgetdatamodified;
      let valuenodeId = budgetdata.nodeId;
      if (budgetdata.englishLabel == 'Sub Result') {
        valuenodeId = displaydata.valuesNodeId;
      }
      this.bonusrecordModifiedModel.budgetData.forEach(function (element) {
        if (element.valuenodeId == valuenodeId) {
          budgetData = element;
        }
      })
      if (budgetData != null) {
        console.log(budgetData);
        budgetData.isNodeSelection = true;
        if (selectedNodeId != "0") {
          budgetData.selectedNodes.forEach((element, index) => {
            if (element == selectedNodeId) {
              budgetData.selectedNodes.splice(parseInt(selectedNodeId), index)
              return;
            }
          });
          budgetData.selectedNodes.push(selectedNodeId);
        }
        if (unselectedNoedId != "0") {
          budgetData.unselectedNodes.forEach((element, index) => {
            if (element == unselectedNoedId) {
              budgetData.unselectedNodes.splice(parseInt(unselectedNoedId), index)
              return;
            }
          });
          budgetData.unselectedNodes.push(unselectedNoedId);
        }
      }
      this.squash(budgetData.selectedNodes);
      this.squash(budgetData.unselectedNodes);
      localStorage.removeItem("recordModified")
      localStorage.setItem("recordModified", JSON.stringify(this.bonusrecordModifiedModel));
    }
  }

  squash(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

  countChangedHandler(count, BudgetResult) {
    let currentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
    if (currentValuesArray == null) {
      currentValuesArray = [];
    }
    if (count.zeroOption != undefined && count.zeroOption == true) {

      for (let i = this.BonusesCommisionStartIndex; i < this.BonusesCommisionEndIndex; i++) {
        let budgetValue: number = 0;
        if (!Number.isNaN(count.budgetData[i])) {
          budgetValue = count.budgetData[i]
        }
        if (currentValuesArray[i] == undefined) {
          currentValuesArray[i] = 0;
        }
        else {
          currentValuesArray[i] = 0;
        }
        if (this.displaydarrivation[0].inputs.length > 0) {
          if (this.displaydarrivation[0].inputs[0].valuesArray[i] > 0) {
            var calculatedValue = "0";
            this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue);
          }
          else {
            this.valuelistdata.values.valuesArray[i] = 0;
          }
        }
        else {
          this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(currentValuesArray[i]);
        }

        if (this.displaydarrivation[0].inputs.length > 1) {
          if (this.displaydarrivation[0].inputs[1].englishLabel == "Sales Unit"
            || this.displaydarrivation[0].inputs[1].englishLabel == "Costs Unit" ||
            this.displaydarrivation[0].inputs[1].englishLabel == "Employees") {
            var storedValue = [];
            this.displaydarrivation[0].inputs[1].valuesArray[i] = 0;

          }
        }
      }

      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        total = 0;
        inputValue.value = this.converttoDisplayValue(total.toString());
      }
      this.modifyNodeSelection(this.valuelistdata, BudgetResult, 0, count.node, 0);
      this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
      localStorage.setItem("AspercentageCalculatedBudgetData", JSON.stringify(currentValuesArray));
      return;
    }
    if (count.option == true) {
      var total = 0.0;
      let oldBudgetdata = this.valuelistdata.values.valuesArray;
      for (let i = this.BonusesCommisionStartIndex; i < this.BonusesCommisionEndIndex; i++) {
        let budgetValue: number = 0;
        if (!Number.isNaN(count.budgetData[i])) {
          budgetValue = count.budgetData[i]
        }
        if (currentValuesArray[i] == undefined) {
          currentValuesArray[i] = budgetValue;
        }
        else {
          currentValuesArray[i] = currentValuesArray[i] + budgetValue;
        }

        if (this.displaydarrivation[0].inputs.length > 0) {
          var Values = this.displaydarrivation[0].inputs[0].valuesArray[i].replaceAll(",", "");
          if (Values > 0) {
            var calculatedValue = (currentValuesArray[i] * (parseFloat(Values) / 100)).toFixed(0)
            total = total + parseInt(calculatedValue);
            this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
          }
          else {
            this.valuelistdata.values.valuesArray[i] = 0;
          }
        }

        else {
          this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(currentValuesArray[i].toString());
        }

      }

      if (this.displaydarrivation[0].inputs.length > 1) {
        if (this.displaydarrivation[0].inputs[1].englishLabel == "Sales Unit"
          || this.displaydarrivation[0].inputs[1].englishLabel == "Costs Unit"
          || this.displaydarrivation[0].inputs[1].englishLabel == "Employees") {
          var storedValue = [];
          this.displaydarrivation[0].inputs[1].valuesArray.forEach((element, index) => {
            var replaceValue = this.displaydarrivation[0].inputs[1].valuesArray[index].toString().replaceAll(",", "");
            this.displaydarrivation[0].inputs[1].valuesArray[index] = parseFloat(replaceValue) + parseFloat(count.budgetData[index]);
          });

        }
      }

      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        inputValue.value = this.converttoDisplayValue(total.toString());
      }
      this.modifyNodeSelection(this.valuelistdata, BudgetResult, 0, count.node, 0);
      //console.log(this.bonusrecordModifiedModel);
      this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
      localStorage.setItem("AspercentageCalculatedBudgetData", JSON.stringify(currentValuesArray));
    }
    if (count.option == false) {
      var total = 0.0;
      for (let i = this.BonusesCommisionStartIndex; i < this.BonusesCommisionEndIndex; i++) {
        let budgetValue: number = 0;
        if (!Number.isNaN(count.budgetData[i])) {
          budgetValue = count.budgetData[i]
        }
        if (currentValuesArray[i] == undefined) {
          currentValuesArray[i] = budgetValue;
        }
        else {
          currentValuesArray[i] = currentValuesArray[i] - budgetValue;
        }
        if (this.displaydarrivation[0].inputs.length > 0) {
          if (this.displaydarrivation[0].inputs[0].valuesArray[i] > 0) {
            if (this.displaydarrivation[0].inputs[0].valuesArray[i] > 0) {
              var calculatedValue = (currentValuesArray[i] * (this.displaydarrivation[0].inputs[0].valuesArray[i])).toFixed(0)
              total = total + parseInt(calculatedValue);
              this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
            }
            else {
              this.valuelistdata.values.valuesArray[i] = 0;
            }
          }
          else {
            this.valuelistdata.values.valuesArray[i] = 0;
          }
        } else {
          this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(currentValuesArray[i].toString());
        }
      }
      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        inputValue.value = this.converttoDisplayValue(total.toString());
      }
      this.modifyNodeSelection(this.valuelistdata, BudgetResult, 0, 0, count.node);
      this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
      localStorage.setItem("AspercentageCalculatedBudgetData", JSON.stringify(currentValuesArray));

    }
  }
  //Calculate As Balance
  CalculateAsBalance(recordNodeId, event) {
    let selectedOption = 0;
    if (event.target.checked == true) {
      selectedOption = 1;
    }
    this.methodservice.CalculateAsBalance(recordNodeId.selectNodeId, selectedOption).subscribe(
      (response) => {
        return response;
      }
    )

  }
  darrivationsetall(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    var decimalvalue = $event.target.value.replaceAll(',', '');
    if (decimalvalue == null || decimalvalue == "") {
      decimalvalue = 0;
    }
    //console.log(eventname, budgetdata, $event, displaydata, Isdarrivation);
    var total = 0.00;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    if (displaydata[0].derivationEngType.startsWith("AsPercentageOf") && this.valuelistdata.derivationEngType != "AsPercentageOfAllStock"
      && this.valuelistdata.derivationEngType != "AsPercentageOfProfitAfterTax") {

      let currentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
      let getcurrentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
      if (currentValuesArray == null) {
        currentValuesArray = [];
      }

      var total = 0.0;
      for (let i = 0; i < this.valuelistdata.values.valuesArray.length; i++) {
        if (getcurrentValuesArray != null && getcurrentValuesArray.length == 0) {
          var calculatedValue = ((0 / 100)).toFixed(0);
          total = total + parseInt(calculatedValue);
          this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
        } else {
          if (decimalvalue > 0) {
            if (currentValuesArray[i] == null) {
              if (displaydata[0].nodeType == 61080) {
                var calculatedValue = ((0 / 100)).toFixed(0);
                total = total + parseInt(calculatedValue);
                this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
              } else {
                var calculatedValue = ((decimalvalue / 100)).toFixed(0);
                total = total + parseInt(calculatedValue);
                this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
              }

            } else {
              var calculatedValue = (currentValuesArray[i] * (decimalvalue / 100)).toFixed(0);
              total = total + parseInt(calculatedValue);
              this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
            }
          }
          else {
            this.valuelistdata.values.valuesArray[i] = 0;
          }
        }
      }

      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        inputValue.value = this.converttoDisplayValue(total.toString());
      }
    }
    if (eventname === "Cycle Totals") {
      var Actaulloopindexstart;
      var cycleTotalProfile = 0.00;
      var actualsProfileTotal = 0.00;
      var accumulatedProfile = 0.00;
      var cycleTotalValue = $event.target.value.replaceAll(',', '');
      var totalID = displaydata[0].valuesNodeId + "-total";
      var totalNetSales: any = (<HTMLInputElement>document.getElementById(totalID));
      let defaultProfile: any;
      defaultProfile = this.valueListDerrivation.profile.valuesArray;
      if (displaydata[0].currentActualsIndex != -1 && displaydata[0].currentActualsIndex >= this.BonusesCommisionStartIndex) {
        for (let i = this.BonusesCommisionStartIndex; i <= displaydata[0].currentActualsIndex; i++) {
          cycleTotalValue = cycleTotalValue - displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + i];
        }
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
        for (let i = 0; i <= displaydata[0].currentActualsIndex; i++) {
          actualsProfileTotal = parseFloat(actualsProfileTotal.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      else {
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      if (cycleTotalProfile > 0) {
        var accumulatedTotal = 0.00;
        for (loopindexstart = 0; loopindexstart < displaydata[0].values.valuesArray.length; ++loopindexstart) {
          var profileValue = defaultProfile[loopindexstart];
          accumulatedProfile = accumulatedProfile + profileValue;
          var resultValue = Math.round(cycleTotalValue * (accumulatedProfile / (cycleTotalProfile - actualsProfileTotal))) - accumulatedTotal;
          accumulatedTotal = accumulatedTotal + resultValue;
          displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(resultValue.toString());
        }
        if (totalNetSales != null) {
          totalNetSales.value = this.converttoDisplayValue(accumulatedTotal.toString());
        }
      }
    }
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      console.log(totalgetelemtbyclassnamearray);
      console.log(eventname);
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
          }
        }
        else {
          if (parseFloat((decimalvalue)) != undefined) {
            total += parseFloat((decimalvalue));
            resultarray.push(parseFloat((decimalvalue)))

            totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
            budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
            this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
            this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
          }
        }
      } else {
        if (parseFloat((decimalvalue)) != undefined) {
          total += parseFloat((decimalvalue));
          resultarray.push(parseFloat((decimalvalue)))
          budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
          totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
          this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
          this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
        }
      }
    }
    $event.target.value = "";
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    if (displaydata[0].derivationType == "80014" || displaydata[0].derivationType == "80048" || displaydata[0].derivationType == "80050") {
      let total80014 = 0.0;
      for (let i = 0; i < this.valuelistdata.values.valuesArray.length; i++) {
        var valueInput1 = this.valueListDerrivation.inputs[0].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var valueInput2 = this.valueListDerrivation.inputs[1].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var result = (parseFloat(valueInput1) * parseFloat(valueInput2));
        total80014 = total80014 + result;
        this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(result.toString());
        var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
        if (inputValue != null) {
          inputValue.value = this.converttoDisplayValue(total80014.toString());
        }
      }
    }
  }


  SetAllValueFromBonuses(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    //console.log("noDarivationsetall");
    var decimalvalue = $event.target.value.replaceAll(',', '');
    if (decimalvalue == null || decimalvalue == "") {
      decimalvalue = 0;
    }
    var total = 0.00;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
            budgetdata.valuesArray[loopindexstart] = parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
          }
        }
        else {
          if (parseFloat((decimalvalue)) != undefined) {
            total += parseFloat((decimalvalue));
            resultarray.push(parseFloat((decimalvalue)));
            budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
            totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
            //this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
            //this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
          }
        }
      } else {
        if (parseFloat((decimalvalue)) != undefined) {
          total += parseFloat((decimalvalue));
          resultarray.push(parseFloat((decimalvalue)))
          totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
          budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
          //this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
          //this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
        }
      }
    }
    $event.target.value = "";
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue == undefined) {

    } else {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    let singleBonus = new recordSaveModel();
    singleBonus.recordNodeID = budgetdata.recordNodeId;
    let ValuesArray = [];
    resultarray.forEach((element, index) => {
      let ArrayValue = {
        index: index,
        value: element.toString()
      }
      ValuesArray.push(ArrayValue);
    });
    let savingModelBudgetData = [{
      hasLoanOtherOptions: false,
      isDerivation: false,
      isNodeSelection: false,
      loanCalculationModels: null,
      selectedNodes: [],
      unselectedNodes: [],
      valuelist: ValuesArray,
      valuenodeId: budgetdata.valuesNodeId
    }]
    singleBonus.budgetData = savingModelBudgetData;
    let JsonArray = {
      "setCommonBonusmodel": singleBonus
    }
    localStorage.setItem("setComomBonus", JSON.stringify(JsonArray));
  }

  /**
   * 
   * @param eventname 
   * @param budgetdata 
   * @param $event 
   * @param displaydata 
   * @param Isdarrivation 
   */

  NoSetValuesFromBonusesCommision(eventname, budgetdata, $event, displaydata, Isdarrivation, valuelistindex) {
    //console.log("NoderrivationMonthCalculation");
    if ($event.target.value == '') {
      $event.target.value = 0;
      return;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    // for (loopindexstart = 0; loopindexstart < budgetdata.valuesArray.length; ++loopindexstart) {
    //   if (this.check(budgetdata.valuesArray[loopindexstart].toString()) == true) {
    //     let data = budgetdata.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
    //     totalgetelemtbyclassnamearray.value = parseFloat(data);
    //     total += parseFloat(data);
    //     resultarray.push(parseFloat(data).toFixed(2));
    //   } else {
    //     totalgetelemtbyclassnamearray.value = parseFloat(budgetdata.valuesArray[loopindexstart].toString().replaceAll(',', ''));
    //     total += parseFloat(budgetdata.valuesArray[loopindexstart].toString().replaceAll(',', ''));
    //     resultarray.push(parseFloat(budgetdata.valuesArray[loopindexstart].toString().replaceAll(',', '')).toFixed(2))
    //   }
    // }

    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (this.check(totalgetelemtbyclassnamearray[loopindexstart].value.toString()) == true) {
        let data = budgetdata.valuesArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
        totalgetelemtbyclassnamearray.value = parseFloat(data);
        total += parseFloat(data);
        resultarray.push(parseFloat(data).toFixed(2));
      } else {
        totalgetelemtbyclassnamearray.value = parseFloat(totalgetelemtbyclassnamearray[loopindexstart].value.toString().replaceAll(',', ''));
        total += parseFloat(totalgetelemtbyclassnamearray[loopindexstart].value.toString().replaceAll(',', ''));
        resultarray.push(parseFloat(totalgetelemtbyclassnamearray[loopindexstart].value.toString().replaceAll(',', '')).toFixed(2))
      }
    }

    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    let singleBonus = new recordSaveModel();
    singleBonus.recordNodeID = budgetdata.recordNodeId;
    singleBonus.budgetData = [];
    // let ArrayValue = {
    //   index: null,
    //   value: ""
    // }

    let ValuesArray = [];
    resultarray.forEach((element, indexs) => {
      let ArrayValue = {
        index: null,
        value: ""
      }
      ArrayValue.index = indexs;
      ArrayValue.value = element.toString();
      ValuesArray.push(ArrayValue);
    });
    let savingModelBudgetData = [{
      hasLoanOtherOptions: false,
      isDerivation: false,
      isNodeSelection: false,
      loanCalculationModels: null,
      selectedNodes: [],
      unselectedNodes: [],
      valuelist: ValuesArray,
      valuenodeId: budgetdata.valuesNodeId
    }]
    singleBonus.budgetData = savingModelBudgetData;

    let JsonArray = {
      "setCommonBonusmodel": singleBonus
    }
    localStorage.setItem("setComomBonus", JSON.stringify(JsonArray));
  }

  noDarivationsetall(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    //console.log("noDarivationsetall");
    var decimalvalue = $event.target.value.replaceAll(',', '');
    if (decimalvalue == null || decimalvalue == "") {
      decimalvalue = 0;
    }
    var total = 0.00;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
            budgetdata.valuesArray[loopindexstart] = parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
          }
        }
        else {
          if (parseFloat((decimalvalue)) != undefined) {
            total += parseFloat((decimalvalue));
            resultarray.push(parseFloat((decimalvalue)));
            budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
            totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
            this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
            this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
          }
        }
      } else {
        if (parseFloat((decimalvalue)) != undefined) {
          total += parseFloat((decimalvalue));
          resultarray.push(parseFloat((decimalvalue)))
          totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
          budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
          this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
          this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
        }
      }
    }
    $event.target.value = "";
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue == undefined) {

    } else {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }

  }

  noDarivationopening(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    //console.log("noDarivationopening");
    if ($event.target.value == null || $event.target.value == "") {
      $event.target.value = 0;
    }
    let total = 0;
    let resultarray = [];
    let openingDataValue = displaydata[0].openingResult;
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var n;
    var eventValue = $event.target.value.replaceAll(',', '');
    budgetdata.valuesArray[-1] = this.converttoDisplayValue(eventValue);
    this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, -1);
    this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
    if (displaydata[0].display == 4) {
      eventValue = (parseFloat(eventValue) * -1).toString();
    }
    for (let loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; loopindexstart++) {
      if (this.check(displaydata[0].values.valueArray[loopindexstart].toString()) == true) {
        let data = displaydata[0].values.valueArray[loopindexstart].replace(/\(/g, '-').replace(/\)/g, '');
        let sumvalue = parseInt(data.replace('-', '').replaceAll(',', ''));
        let value = (-(sumvalue) + (parseFloat(eventValue)));
        let absValue = Math.abs(value);
        let realvalue = null;
        if (value >= 0) {
          realvalue = absValue;
        } else {
          realvalue = "(" + absValue + ")";
        }
        totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(realvalue.toString());
        //resultarray.push((totalgetelemtbyclassnamearray[loopindexstart].value))
        budgetdata.valuesArray[loopindexstart] = this.converttoDisplayValue(realvalue.toString());
      } else {
        let sumvalue = displaydata[0].values.valueArray[loopindexstart].replaceAll(',', '');
        let realvalue = (parseFloat(sumvalue) + (parseFloat(eventValue)));
        let addOpeningValue: any = realvalue;
        totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(addOpeningValue.toString());
        //resultarray.push(parseInt((totalgetelemtbyclassnamearray[loopindexstart].value)))
        budgetdata.valuesArray[loopindexstart] = this.converttoDisplayValue(addOpeningValue.toString());
      }
    }
  }

  noDarrivationPercentage(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    // console.log("noDarrivationPercentage", budgetdata, displaydata, Isdarrivation, event);
    if ($event.target.value == null || $event.target.value == "") {
      $event.target.value = 0;
    }
    let total = 0;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      total += parseInt(($event.target.value));
      resultarray.push(parseInt(($event.target.value)))
      totalgetelemtbyclassnamearray[loopindexstart].value = $event.target.value;
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    inputValue.value = total;
  }
  noDarivationtotal(eventname, budgetdata, $event, displaydata, Isdarrivation) {
    let defaultProfile: any;
    defaultProfile = JSON.parse(localStorage.getItem("DefaultProfile"));
    if ($event.target.value == null || $event.target.value == "") {
      $event.target.value = 0;
    }
    let total = 0;
    let ActualTotal = 0;
    let resultarray = [];
    var loopindexstart;
    var n;
    var Actaulloopindexstart;
    var cycleTotalProfile = 0.00;
    var actualsProfileTotal = 0.00;
    var accumulatedProfile = 0.00;
    var cycleTotalValue = $event.target.value.replaceAll(',', '');
    // for (let i = 0; i < defaultProfile.length; i++) {
    //   cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
    // }

    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    let totalValue = 0.00;
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      let value = parseFloat(totalgetelemtbyclassnamearray[loopindexstart].value.replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, ''));
      totalValue = totalValue + value;
    }
    if (totalValue === parseFloat(cycleTotalValue)) {
      return;
    }
    if (displaydata[0].currentActualsIndex != -1 && displaydata[0].currentActualsIndex >= this.BonusesCommisionStartIndex) {
      for (let i = this.BonusesCommisionStartIndex; i <= displaydata[0].currentActualsIndex; i++) {
        cycleTotalValue = cycleTotalValue - budgetdata.valuesArray[this.BonusesCommisionStartIndex + i];
      }

      for (let i = 0; i < defaultProfile.length; i++) {
        cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
      }

      for (let i = 0; i <= displaydata[0].currentActualsIndex; i++) {
        actualsProfileTotal = parseFloat(actualsProfileTotal.toString()) + parseFloat(defaultProfile[i]);
      }

    }
    else {
      for (let i = 0; i < defaultProfile.length; i++) {
        cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
      }
    }
    if (cycleTotalProfile > 0) {
      var accumulatedTotal = 0.00;
      for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
        var profileValue = defaultProfile[loopindexstart];
        accumulatedProfile = accumulatedProfile + profileValue;
        var resultValue = Math.round(cycleTotalValue * (accumulatedProfile / (cycleTotalProfile - actualsProfileTotal))) - accumulatedTotal;
        // console.log("resultValue", resultValue);
        accumulatedTotal = accumulatedTotal + resultValue;
        totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(resultValue.toString());
        budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(resultValue.toString());
        this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
        this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
      }
    }
    else {
      for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
        var resultValue = 0.00;
        totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(resultValue.toString());
        budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(resultValue.toString());
        this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
        this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
      }
    }
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    let stringArray = inputValue.id.split("-");
    if (stringArray[0] == 'Percentages') {
      number = 99;
    }
    if (eventValue != '') {
      if (eventValue == '-') {
        return true;
      }
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        eventValue = eventValue.replaceAll("-", '');
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      let values: any = this.displayvaluelistdata;
      if (values[0].nodeType == 60031) {
        if (evt.keyCode == 46) {
          return true;
        }
      }

      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  decimalvaluebeforeDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.'));
        if (afterDot.length == 0) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  converttoDisplayValue(decimalValue) {
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }
  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // console.log("validatebynumberFormate", validatebynumberFormate);
      let values: any = this.displayvaluelistdata;
      if (values[0].nodeType == 60031) {
        if (validatebynumberFormate == '.') {
          $event.target.value = "0.";
        }
      } else {
        if (validatebynumberFormate == '.') {
          $event.target.value = "0.";
        } else {
          $event.target.value = validatebynumberFormate;
        }
      }

    }
  }
  // update record
  updatearray(updateArrayValue) {
    this.SpinnerService.show();
    this.methodservice.updateLoantakenRecord(updateArrayValue).subscribe(
      async (response: any) => {
        this.SpinnerService.hide();
        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
        //this.updateLoanTakenrecord();
      })
  }

  /**
   * code for copy to paste hotlink
   */

  open($event, id) {
    //console.log($event, id);
    var result = document.getElementById(id);
    var input = document.getElementsByClassName('.rightclick-' + id);
    document.getElementById('hidecopy-' + id).click();
    $('.rightclick-' + id).removeClass('hide');
    $('.rightclick-' + id).addClass('show');
  }

  async convertoExcel(id, budgetdata, $event, displaydata, Isdarrivation) {
    const text = await navigator.clipboard.readText();
    var result = null;
    if (text.indexOf(',') > -1) {
      result = text.split(",");
    } else {
      result = text.split(/\r?\n/);
    }

    $('.rightclick-' + id).addClass('hide');
    $('.rightclick-' + id).removeClass('show');
    if (result.length > 0) {
      result = result[0].split("\t");
    }

    if (Isdarrivation == 0) {
      var total = 0.00;
      let resultarray = [];
      var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(id);
      var loopindexstart;
      for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
        if (result[loopindexstart] != undefined) {
          let latestvalue: any = result[loopindexstart].toString();
          var decimalvalue = latestvalue.replaceAll(',', '');
        }
        if (decimalvalue != null && decimalvalue != '' && decimalvalue != undefined && !isNaN(decimalvalue)) {
        } else {
          decimalvalue = budgetdata.valuesArray[loopindexstart];
        }
        if (decimalvalue != null && decimalvalue != '') {
          if (displaydata[0].currentActualsIndex != -1) {
            if (displaydata[0].currentActualsIndex >= loopindexstart) {
              if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
                total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
                resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
                budgetdata.valuesArray[loopindexstart] = parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
              }
            }
            else {
              if (parseFloat((decimalvalue)) != undefined) {
                total += parseFloat((decimalvalue));
                resultarray.push(parseFloat((decimalvalue)));
                budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
                totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
                this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
                this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
              }
            }
          } else {

            if (parseFloat((decimalvalue)) != undefined && decimalvalue != '') {
              total += parseFloat((decimalvalue));
              resultarray.push(parseFloat((decimalvalue)))
              totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
              budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
              this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
              this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
            }
          }
        }

      }
      var inputValue: any = (<HTMLInputElement>document.getElementById(id + "-total"));
      inputValue.value = this.converttoDisplayValue(total.toString());
    } else {
      this.PasteDarrivationValues(id, budgetdata, $event, displaydata, Isdarrivation, result)
    }
  }

  PasteDarrivationValues(eventname, budgetdata, $event, displaydata, Isdarrivation, UpdatedValues) {

    var total = 0.00;
    let resultarray = [];
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName(eventname);
    var loopindexstart;
    var n;
    if (displaydata[0].derivationEngType.startsWith("AsPercentageOf")) {
      let currentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
      let getcurrentValuesArray = JSON.parse(localStorage.getItem('AspercentageCalculatedBudgetData'));
      if (currentValuesArray == null) {
        currentValuesArray = [];
      }
      var total = 0.0;
      for (let i = 0; i < this.valuelistdata.values.valuesArray.length; i++) {
        let latestvalue: any = UpdatedValues[i].toString();
        var decimalvalue = latestvalue.replaceAll(',', '');
        if (decimalvalue != null && decimalvalue != '' && decimalvalue != undefined) {

        } else {
          decimalvalue = this.valuelistdata.values.valuesArray[i];
        }
        if (getcurrentValuesArray.length == 0) {
          var calculatedValue = ((0 / 100)).toFixed(0);
          total = total + parseInt(calculatedValue);
          this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
        } else {
          if (decimalvalue > 0) {
            if (currentValuesArray[i] == null) {
              var calculatedValue = ((decimalvalue / 100)).toFixed(0);
              total = total + parseInt(calculatedValue);
              this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
            } else {
              var calculatedValue = (currentValuesArray[i] * (decimalvalue / 100)).toFixed(0);
              total = total + parseInt(calculatedValue);
              this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(calculatedValue.toString());
            }
          }
          else {
            this.valuelistdata.values.valuesArray[i] = 0;
          }
        }
      }

      var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
      if (inputValue != null) {
        inputValue.value = this.converttoDisplayValue(total.toString());
      }
    }
    if (eventname === "Cycle Totals") {
      var Actaulloopindexstart;
      var cycleTotalProfile = 0.00;
      var actualsProfileTotal = 0.00;
      var accumulatedProfile = 0.00;
      var cycleTotalValue = $event.target.value.replaceAll(',', '');
      if (cycleTotalValue == '') {
        cycleTotalValue = 0;
      }
      var totalID = displaydata[0].valuesNodeId + "-total";
      var totalNetSales: any = (<HTMLInputElement>document.getElementById(totalID));
      let defaultProfile: any;
      defaultProfile = this.valueListDerrivation.profile.valuesArray;
      if (displaydata[0].currentActualsIndex != -1 && displaydata[0].currentActualsIndex >= this.BonusesCommisionStartIndex) {
        for (let i = this.BonusesCommisionStartIndex; i <= displaydata[0].currentActualsIndex; i++) {
          cycleTotalValue = cycleTotalValue - displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + i];
        }
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
        for (let i = 0; i <= displaydata[0].currentActualsIndex; i++) {
          actualsProfileTotal = parseFloat(actualsProfileTotal.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      else {
        for (let i = 0; i < defaultProfile.length; i++) {
          cycleTotalProfile = parseFloat(cycleTotalProfile.toString()) + parseFloat(defaultProfile[i]);
        }
      }
      if (cycleTotalProfile > 0) {
        var accumulatedTotal = 0.00;
        for (loopindexstart = 0; loopindexstart < displaydata[0].values.valuesArray.length; ++loopindexstart) {
          var profileValue = defaultProfile[loopindexstart];
          accumulatedProfile = accumulatedProfile + profileValue;
          var resultValue = Math.round(cycleTotalValue * (accumulatedProfile / (cycleTotalProfile - actualsProfileTotal))) - accumulatedTotal;
          accumulatedTotal = accumulatedTotal + resultValue;
          displaydata[0].values.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(resultValue.toString());
        }
        if (totalNetSales != null) {
          totalNetSales.value = this.converttoDisplayValue(accumulatedTotal.toString());
        }
      }
    }
    for (loopindexstart = 0; loopindexstart < totalgetelemtbyclassnamearray.length; ++loopindexstart) {
      if (UpdatedValues[loopindexstart] != undefined) {
        let latestvalue: any = UpdatedValues[loopindexstart].toString();
        var decimalvalue = latestvalue.replaceAll(',', '');
      }

      if (decimalvalue != null && decimalvalue != '' && decimalvalue != undefined && !isNaN(decimalvalue)) {
      } else {
        decimalvalue = budgetdata.valuesArray[loopindexstart];
      }
      if (displaydata[0].currentActualsIndex != -1) {
        if (displaydata[0].currentActualsIndex >= loopindexstart) {
          if (((totalgetelemtbyclassnamearray[loopindexstart].value)) != undefined) {
            total += parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value));
            resultarray.push(parseFloat((totalgetelemtbyclassnamearray[loopindexstart].value)))
          }
        }
        else {
          if (parseFloat((decimalvalue)) != undefined) {
            total += parseFloat((decimalvalue));
            resultarray.push(parseFloat((decimalvalue)))
            totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
            budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
            this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
            this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
          }
        }
      } else {
        if (parseFloat((decimalvalue)) != undefined) {
          total += parseFloat((decimalvalue));
          resultarray.push(parseFloat((decimalvalue)))
          budgetdata.valuesArray[this.BonusesCommisionStartIndex + loopindexstart] = this.converttoDisplayValue(decimalvalue);
          totalgetelemtbyclassnamearray[loopindexstart].value = this.converttoDisplayValue(decimalvalue);
          this.modifyValuesinRecord(budgetdata, displaydata, Isdarrivation, this.BonusesCommisionStartIndex + loopindexstart);
          this.recordModifiedEvent.emit(this.bonusrecordModifiedModel);
        }
      }
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById(eventname + "-total"));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
    }
    if (displaydata[0].derivationType == "80014" || displaydata[0].derivationType == "80048" || displaydata[0].derivationType == "80050") {
      let total80014 = 0.0;
      for (let i = 0; i < this.valuelistdata.values.valuesArray.length; i++) {
        var valueInput1 = this.valueListDerrivation.inputs[0].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var valueInput2 = this.valueListDerrivation.inputs[1].valuesArray[i].toString().replaceAll(',', '').replace(/\(/g, '-').replace(/\)/g, '');
        var result = (parseFloat(valueInput1) * parseFloat(valueInput2));
        total80014 = total80014 + result;
        this.valuelistdata.values.valuesArray[i] = this.converttoDisplayValue(result.toString());
        var inputValue: any = (<HTMLInputElement>document.getElementById(this.valuelistdata.values.valuesNodeId + "-total"));
        if (inputValue != null) {
          inputValue.value = this.converttoDisplayValue(total80014.toString());
        }
      }
    }
  }

  FixedRatePercentage($event, values, result, valuesNodeId) {
    let Value = $event.target.value;
    if (Value == "") {
      return;
    }
    //console.log(valuesNodeId);
    let ForecastId = localStorage.getItem('ForecastId');
    // console.log(values);
    // this.ParameterService.ParameterDepreciationDefualtPercentage(values.nodeId, parseFloat(Value.toString()).toFixed(2)).subscribe(
    //   (response => {

    //   })
    // )
    this.TreeviewServices.changePecentageValue(values.nodeId, parseFloat(Value.toString()).toFixed(2)).subscribe(
      (response => {

      })
    )
  }

  CopyToClipboard(table_id) {
    //console.log(table_id);
    document.addEventListener('copy', (e: ClipboardEvent) => {
      let values = [];
      for (let index = this.BonusesCommisionStartIndex; index < this.BonusesCommisionEndIndex; index++) {
        var inputValue: any = (<HTMLInputElement>document.getElementById(table_id + "-" + index));
        values.push(inputValue.value.replaceAll(",", ""));
      }
      var stringsValue = values.toString();
      e.clipboardData.setData('text/plain', (stringsValue));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    $('.rightclick-' + table_id).addClass('hide');
    $('.rightclick-' + table_id).removeClass('show');
  }
  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }

}
