import { Component, OnInit, Injector } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceService } from 'src/app/component/list-forecast/services/service.service';
import { Forecastmodel } from 'src/app/component/list-forecast/services/forecastmodel';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TreeviewService } from 'src/app/service/treeview.service';
import { ConsolidationService } from 'src/app/service/consolidation.service';
import * as moment from 'moment';
import { trigger } from '@angular/animations';
@Component({
  selector: 'app-consolidaitondetails',
  templateUrl: './consolidaitondetails.component.html',
  styleUrls: ['./consolidaitondetails.component.scss']
})
export class ConsolidaitondetailsComponent implements OnInit {
  forecastlist = [];
  UserId = localStorage.getItem('CurrentUserNodeId');
  months = [];
  details: any;
  constructor(private NewForecastService: ServiceService,
    private SpinnerService: NgxSpinnerService, private injector: Injector, private treeviewservice: TreeviewService,
    private consolService: ConsolidationService) { }

  ngOnInit(): void {
    this.fillForecasts();
    this.details = '';
  }
  fillForecasts() {
    this.SpinnerService.show();
    let forecastNodeid = localStorage.getItem('ForecastId');
    if(this.UserId!=null){
      this.NewForecastService.getForecastList(this.UserId).subscribe(
        (response: Forecastmodel[]) => {
          this.consolService.GetForecastsSelected(forecastNodeid).subscribe((selectedF: any) => {
            response.forEach(element => {
              if (element.nodeId.toString() !== forecastNodeid) {
                selectedF.forEach(f => {
                  if (f === element.nodeId) {
                    element.ConsolSelected = true;
                    this.forecastlist.push(element);
                  }
                });
              }
            });
            this.SpinnerService.hide();
          });
  
        })
    }
    
  }

  SelectedForecast(nodeId: any) {

    this.months = [];
    this.treeviewservice.getforecastDetails(nodeId).subscribe
      ((response) => {
        this.details = response;
        if (this.details) {
          var date = new Date(this.details.startdate);
          var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          for (var i = 0; i < 12; i++) {
            this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear() % 100);
            date.setMonth(date.getMonth() + 1);
          }
        }
        // console.log("Forecast Details", this.details);
      })
  }
}
