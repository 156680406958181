<div class="col-sm-12">
  <div class="col-sm-2" style="margin: 12px 0 0 0;">
    <h2>Integration Mapping</h2>
  </div>
</div>


<div class="col-sm-12" *ngIf="displayTree" style="margin: 12px 0 0 -28px;background: #fbf7f7;">
  <div class="col-sm-12" style="height: 590px;overflow: auto;">
    <div class="col-sm-6" style="height: 590px;overflow: auto;">
      <div class="treeMenu">
        <tree-root #tree [state]="state"  [options]="options"  [focused]="true" [nodes]="nodesSecond" (initialized)="onTreeLoad()" 
         (updateData)="onUpdateData()">
        <ng-template #treeNodeTemplate let-node let-index="index" >
        <span  [attr.draggable]="true"
        [attr.nodeType]="node.data.glAccountCode"
        [attr.aria-grabbed]="true"
        [class.draggable]="true" 
        [class]="node.data.glAccount" id="{{node.data.glAccountCode}}" [class.title]="true"> 
    
        <span id="{{node.data.glAccountCode}}-treemenu" style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.glAccount }}</span></span>
      </ng-template>
        </tree-root></div>
    </div>
    <div class="col-sm-6" style="height: 590px;overflow: auto;">
      <div class="treeMenu">
        <tree-root #tree [state]="state"  [options]="options"  [focused]="true" [nodes]="nodesFirst" (initialized)="onTreeLoad()" 
         (updateData)="onUpdateData()">
        <ng-template #treeNodeTemplate let-node let-index="index">
        <span  [attr.draggable]="true" *ngIf="node.data.glAccount !=''"
        [attr.nodeType]="node.data.glAccountCode"
        [attr.aria-grabbed]="true"
        [class.draggable]="true" 
        [class]="node.data.glAccount" id="{{node.data.glAccountCode}}" [class.title]="true"> 
        <span id="{{node.data.glAccountCode}}-treemenu" style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.glAccount }}</span></span>
      </ng-template>
        </tree-root></div>
    </div>
  </div>
  <div class="col-sm-12" style="margin: 0 0 0 -15px;">
    <button class="btn btn-primary col-sm-1" style="font-size: 16px;padding: 4px;" (click)="saveFunction()">Save Mapping</button>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>


