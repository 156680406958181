import { Component, OnInit,Input } from '@angular/core';

import { TreeviewService } from 'src/app/service/treeview.service';
import { EmployeeGroupService } from 'src/app/service/EmployeeGroupService/employee-group.service';
import { BonuscommisionComonHtmlComponent } from './bonuscommision-comon-html/bonuscommision-comon-html.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import { EmployeeGroupComponent } from '../employee-group.component';
import { MethodserviceService } from '../../record/method/service/methodservice.service';
@Component({
  selector: 'app-bonuses-comision',
  templateUrl: './bonuses-comision.component.html',
  styleUrls: ['./bonuses-comision.component.scss']
})
export class BonusesComisionComponent implements OnInit {

  @Input() employeeBonusCommision;
  displaydata:any = [];
  BudgetDerrivationView = [];
  MonthDisplayView = [];
  BonusesDisplayBudget:any = [];
  AmendWagesDisplatBudget:any = [];
  AllCumulativeBonuses:any = [];
  PeriodTabView:any =[];
  BonusesDisplayBudgetResults:any = [];
  AmendDisplayBudgetResults:any = [];
  CumulativeDisplayBudgetResults:any = [];
  Activetabmenu = 0;
  recordmodifiedModel: recordSaveModel;
  constructor(private treeview: TreeviewService, 
    private employeeGroupService:EmployeeGroupService,
    private employeeGroupComponent : EmployeeGroupComponent,
    private methodserviceService:MethodserviceService,
    private SpinnerService:NgxSpinnerService) { }

  ngOnInit() {
  }
  async ngOnChanges(){
    if(this.employeeBonusCommision!=''){ 
      this.displaydata = [];
      this.BudgetDerrivationView = [];
      this.MonthDisplayView = [];
      this.AmendWagesDisplatBudget = [];
      this.BonusesDisplayBudgetResults = [];
      this.AmendDisplayBudgetResults = [];
      this.CumulativeDisplayBudgetResults = [];
      this.SpinnerService.show();
      await this.employeeGroupService.getBonusesCommisonCollection(parseInt(this.employeeBonusCommision)).subscribe(
        (async response => {
          this.SpinnerService.hide()
          let DisplayAllBonusCollectionValue:any = response;
          this.displaydata = DisplayAllBonusCollectionValue.getAllCommisionValue;
          this.BudgetDerrivationView = DisplayAllBonusCollectionValue.getCommisionDerrivation;
          this.MonthDisplayView = DisplayAllBonusCollectionValue.getMonthDisplay;
          this.BonusesDisplayBudget = DisplayAllBonusCollectionValue.getBonusesValues[0].getBonusesValues;
          this.BonusesDisplayBudgetResults = DisplayAllBonusCollectionValue.getBonusesValues[0].results;
          this.AmendWagesDisplatBudget = DisplayAllBonusCollectionValue.getAmendWagesValue[0].getAmendedWages;
          this.AmendDisplayBudgetResults = DisplayAllBonusCollectionValue.getAmendWagesValue[0].results;
          this.AllCumulativeBonuses = DisplayAllBonusCollectionValue.getCumulativeWagesValue[0].getCumulativeWages;
          this.CumulativeDisplayBudgetResults = DisplayAllBonusCollectionValue.getCumulativeWagesValue[0].results;
          this.PeriodTabView = DisplayAllBonusCollectionValue.getPeriodTabView;
          this.Activetabmenu = parseInt(this.PeriodTabView[0].selectedIndex);
        })
        )  
  }
}

/**
   * 
   * @param result 
   * @param index 
   */
 ChangePeriodType(result,index){
  this.Activetabmenu = parseInt(index);
}
recordModified(record: recordSaveModel) {
  this.recordmodifiedModel = record;
  this.employeeGroupComponent.recordModified(record);
}

RemoveDuplicationArray(arr) {
  var tmp = [];
  for (var i = 0; i < arr.length; i++) {
    if (tmp.indexOf(arr[i]) == -1) {
      tmp.push(arr[i]);
    }
  }
  return tmp;
  }
  SaveData(){
    let savemodel = null;
    console.log("bonus commision save");
     let commonbonus = localStorage.getItem("setComomBonus");
     if(commonbonus!=undefined && commonbonus!=""){
       savemodel = JSON.parse(commonbonus);
     }

    if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
      let recordModified: any = this.recordmodifiedModel
      if (recordModified.budgetData.length>0 && recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
        if(recordModified.budgetData.length>0){
          recordModified.budgetData.forEach(element => {
            element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
          });
        }
        this.recordmodifiedModel = recordModified;
      }
      this.SpinnerService.show();
      if(savemodel!="" && savemodel!=null && savemodel!=undefined ) { 
        if(savemodel.setCommonBonusmodel.budgetData.length>0){
          this.recordmodifiedModel.budgetData.push(savemodel.setCommonBonusmodel.budgetData[0]);
        }
        
      }
      if(this.recordmodifiedModel.recordNodeID == null){
        this.recordmodifiedModel = new recordSaveModel();
        localStorage.setItem("setComomBonus", "");
        this.ngOnChanges();
        return;
      }
      this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          this.recordmodifiedModel = new recordSaveModel();
          localStorage.setItem("setComomBonus", "");
          this.ngOnChanges();
        })
    }
    if(this.recordmodifiedModel == undefined && this.recordmodifiedModel == null && savemodel!="" && savemodel!=null && savemodel!=undefined){
      this.recordmodifiedModel =  savemodel.setCommonBonusmodel;
      let recordModified: any = this.recordmodifiedModel
      if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
        recordModified.budgetData.forEach(element => {
          element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
        });
        this.recordmodifiedModel = recordModified;
      }
      this.SpinnerService.show();
      this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          this.recordmodifiedModel = null;
          localStorage.setItem("setComomBonus", "");
          this.ngOnChanges();
        })
   }
  }
}