<div *ngIf="CalculatedloanTakenRecord">
    <div class="{{CalculatedloanTakenRecord.nodeTypeEnglishLabel}}">
        <div class="defualtdisplay">
            <!-- <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-9"></div>
               <button class="button OkSaveButtonColor col-sm-2" (click)="saveCalculation()">Save</button>
            </div> -->
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Type</label>
                <div class="col-sm-7">
                    <select name="Intersttype" class="selectloantakencss form-control"
                        (change)="changeInterestType($event.target.value, CalculatedloanTakenRecord)">
                        <option
                            *ngFor="let InterstResult of CalculatedloanTakenRecord.interestTypeCollection ; let i = index"
                            [selected]="InterstResult.nodeType === CalculatedloanTakenRecord.interestTypes"
                            value="{{InterstResult.nodeType}}">{{InterstResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Frequcncy</label>
                <div class="col-sm-7">
                    <select name="paymentFrequcncy" class="selectloantakencss form-control"
                        (change)="changepaymentFrequcncy($event.target.value, CalculatedloanTakenRecord)">
                        <option
                            *ngFor="let paymentFrequencieResult of CalculatedloanTakenRecord.paymentFrequencieCollection"
                            value="{{paymentFrequencieResult.numberFormatPrefix}}"
                            [selected]="paymentFrequencieResult.numberFormatPrefix === CalculatedloanTakenRecord.paymentFrequencies.numberFormatPrefix">
                            {{paymentFrequencieResult.numberFormatPrefix}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Timing</label>
                <div class="col-sm-7">
                    <select name="paymentTiming" class="selectloantakencss form-control"
                        (change)="changepaymentTiming($event.target.value, CalculatedloanTakenRecord)">
                        <option *ngFor="let paymentTimingsResult of CalculatedloanTakenRecord.paymentTimingsCollection"
                            [selected]="paymentTimingsResult.nodeType === CalculatedloanTakenRecord.paymentTimings"
                            value="{{paymentTimingsResult.nodeType}}">{{paymentTimingsResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input [checked]="CalculatedloanTakenRecord.isNewLoan"
                        (change)="IsnewLoanchangeEvent($event,CalculatedloanTakenRecord)" type="checkbox"
                        name="IsNewLoan" class="col-sm-2" style="height: 20px;margin: 0px" />
                    <label class="col-sm-9" style="margin: 0px;">New loan</label>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-periodStart"
                [ngClass]="CalculatedloanTakenRecord.isNewLoan == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Period Start</label>
                <div class="col-sm-7">
                    <select name="pariodstart" class="selectloantakencss form-control"
                        (change)="changeloanmadePeriod($event.target.value, CalculatedloanTakenRecord)">
                        <option *ngFor="let result of CalculatedloanTakenRecord.forecastperiod; let i = index"
                            [selected]="CalculatedloanTakenRecord.selectedIndex === i" [value]="i">{{result.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Term (Months)</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control"
                        (blur)="changeTerm($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.term | number }}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Loan Balance</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeLoanBalance($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.loanPrincipal | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Rate</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeInterestRate($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.interestRate}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changePayment($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.payment}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord"
                [ngClass]="CalculatedloanTakenRecord.showDeposit == true ? '' : 'hidden' ">
                <div class="col-sm-2">
                    <input [checked]="CalculatedloanTakenRecord.hasDepositPayment"
                        [disabled]="!CalculatedloanTakenRecord.canHaveDepositPayment" type="checkbox"
                        class=" col-sm-11" />
                </div>
                <div class="col-sm-4">
                    <label class="col-sm-4 ">Deposit</label>
                </div>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeDeposite($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.depositPayment | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input type="checkbox" [checked]="CalculatedloanTakenRecord.hasBulletPayment"
                        [disabled]="!CalculatedloanTakenRecord.canHaveBulletPayment"
                        (change)="OnchangeBullet($event,CalculatedloanTakenRecord)" class="col-sm-2"
                        style="height: 20px;margin: 0px" name="Isbullet" />
                    <label class="col-sm-9" style="margin: 0px;">Bullet</label>
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-BulletPayment"
                [ngClass]="CalculatedloanTakenRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Bullet Payment</label>
                <div class="col-sm-7">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPayment($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.bulletPayment | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-PaymentMonth"
                [ngClass]="CalculatedloanTakenRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Payment(Months)</label>
                <div class="col-sm-7">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPaymentMonth($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.monthsBulletDelay | number}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input [checked]="CalculatedloanTakenRecord.hasBalloonPayment"
                        [disabled]="!CalculatedloanTakenRecord.canHaveBalloonPayment" type="checkbox"
                        (change)="HasBallooneEvent($event,CalculatedloanTakenRecord)" class="col-sm-2"
                        style="height: 20px;margin: 0px" />
                    <label class="col-sm-5">Balloon</label>
                </div>
                <div class="col-sm-6 loantakenrecordballon">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedloanTakenRecord.hasBalloonPayment"
                        (blur)="changeBaloon($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.balloonPayment | number}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input [checked]="CalculatedloanTakenRecord.hasHolidayPeriod"
                        [disabled]="!CalculatedloanTakenRecord.canHaveHolidayPeriod"
                        (change)="hasHolidayPeriodEvent($event,CalculatedloanTakenRecord)" type="checkbox"
                        class="col-sm-2" style="height: 20px;margin: 0px" />
                    <label class="col-sm-6">Holiday(Months)</label>

                </div>
                <div class="col-sm-6 loantakenrecordhollidayPeriodEvent">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedloanTakenRecord.hasHolidayPeriod"
                        (blur)="changeholidaymonthText($event.target.value, CalculatedloanTakenRecord)"
                        value="{{CalculatedloanTakenRecord.monthsHoliday | number}}" />
                </div>
            </div>
        </div>
    </div>
</div>