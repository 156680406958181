<div class="registrationPage">
    <div class="registration">
        <div class="register-container registration-header">
            <div class="registrationForm">
                <form [formGroup]="userForgotpasswordForm" (ngSubmit)="onSubmit()">
                    <div>
                        <app-input [control]="userForgotpasswordForm.controls.forgotpasswordemailId" placeholder="Enter Name"
                            text="Email ID" icon="mail">
                        </app-input>
                    </div>
                    <div class="register-Submit">
                        <input type="submit" class="OkSaveButtonColor register-submitbutton"
                            style="background-color: #1e90ff;" value="submit">
                        <input type="button" class="deleteCancelButtonColor register-submitbutton"
                            value="cancel" style="margin: 0 0 0 10px;" (click)="closeevent()">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>