import { Component, OnInit } from '@angular/core';
import { ForecastExtentService } from '../../../service/SettingServices/ForecastExtent/forecast-extent.service';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forecast-extent',
  templateUrl: './forecast-extent.component.html',
  styleUrls: ['./forecast-extent.component.scss']
})
export class ForecastExtentComponent implements OnInit {

  ForecastExtentCollection: any = [];
  NavitemSubmenu = [];
  convertjson: any;
  loaderMessage = "Please Wait";
  constructor(private forecastExtentService: ForecastExtentService,
    private SpinnerService: NgxSpinnerService,
    private _router: Router) { }

  ngOnInit() {
    let ForecastId = localStorage.getItem("ForecastId");
    this.RenderReportMenu();
    this.SpinnerService.show();
    this.forecastExtentService.getForecastExtentCollection(ForecastId).subscribe(
      ((response: any) => {
        this.ForecastExtentCollection = response;
        this.ForecastExtentCollection.amendSalesPriceTo = this.ForecastExtentCollection.amendSalesPriceTo.toFixed(2);
        this.ForecastExtentCollection.amendSalesVolumeTo = this.ForecastExtentCollection.amendSalesVolumeTo.toFixed(2);
        this.ForecastExtentCollection.amendCostsTo = this.ForecastExtentCollection.amendCostsTo.toFixed(2);
        this.ForecastExtentCollection.amendOverheadsTo = this.ForecastExtentCollection.amendOverheadsTo.toFixed(2);
        this.ForecastExtentCollection.amendOtherIncomeTo = this.ForecastExtentCollection.amendOtherIncomeTo.toFixed(2);
        this.ForecastExtentCollection.amendListWagesTo = this.ForecastExtentCollection.amendListWagesTo.toFixed(2);
        this.SpinnerService.hide();
      })
    )
  }

  RenderReportMenu() {
    this.NavitemSubmenu = [];
    this.NavitemSubmenu = [
      { name: 'Extent', icon: 'home', type: 'Extent' },
      { name: 'Amend', icon: 'details', type: 'Amend' },
    ]
  }

  getAmendValues() {
    this.convertjson = {
      NodeId: localStorage.getItem('ForecastId'),
      AmendSalesPriceTo: this.ForecastExtentCollection.amendSalesPriceTo,
      AmendSalesVolumeTo: this.ForecastExtentCollection.amendSalesVolumeTo,
      AmendCostsTo: this.ForecastExtentCollection.amendCostsTo,
      AmendOverheadsTo: this.ForecastExtentCollection.amendOverheadsTo,
      AmendOtherIncomeTo: this.ForecastExtentCollection.amendOtherIncomeTo,
      AmendListWagesTo: this.ForecastExtentCollection.amendListWagesTo,
    }
  }
  ChangeSalesPriceTo($event) {
    this.getAmendValues();
    this.convertjson.AmendSalesPriceTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  ChangeSalesVolumeTo($event) {
    this.getAmendValues();
    this.convertjson.AmendSalesVolumeTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  ChangeCostsTo($event) {
    this.getAmendValues();
    this.convertjson.AmendCostsTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  ChangeOverheadsTo($event) {
    this.getAmendValues();
    this.convertjson.AmendOverheadsTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  ChangeOtherIncomeTo($event) {
    this.getAmendValues();
    this.convertjson.AmendOtherIncomeTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  ChangeListWagesTo($event) {
    this.getAmendValues();
    this.convertjson.AmendListWagesTo = $event.target.value;
    this.saveAmendData(this.convertjson);
  }
  saveAmendData(UpdatedData) {

    this.forecastExtentService.UpdateAmendDetails(UpdatedData).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }

  SaveNumber($event) {
    let value = parseInt($event.target.value);
    let ForecastId = localStorage.getItem("ForecastId");
    // this.forecastExtentService.SaveNumber(ForecastId,value).subscribe(
    //   (response=>{
    //     this.ngOnInit();
    //   })
    // )
  }
  SaveValidAmendMonth($event) {
    let value = parseInt($event.target.value);
    let ForecastId = localStorage.getItem("ForecastId");
    // console.log(value);
    this.forecastExtentService.SaveValidAmendMonth(ForecastId, value).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }
  DeleteFirstYear() {
    let ForecastId = localStorage.getItem("ForecastId");
    this.forecastExtentService.DeleteFirstYear(ForecastId).subscribe(
      (response => {
        this.ngOnInit();
      })
    )
  }
  extentSave() {
    let numberOfYear = (<HTMLInputElement>document.getElementById("numberofyears"));
    let ForecastId = localStorage.getItem("ForecastId");
    // console.log(numberOfYear);
    if (numberOfYear.value == undefined) {
      return;
    }
    this.loaderMessage = "Changing Forecast Length... "+ numberOfYear.value + " Please wait."
    this.SpinnerService.show();
    this.forecastExtentService.ExtentSaveData(ForecastId, parseInt(numberOfYear.value)).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.loaderMessage = "Please wait";
          this._router.navigateByUrl('/forecast');
          this.ngOnInit();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  decimalvaluebeforeDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.'));
        if (afterDot.length == 0) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  converttoDisplayValue(decimalValue) {
    // console.log("converttoDisplayValue", decimalValue);
    //var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      $event.target.value = validatebynumberFormate;
    }
  }
  AmendSave() {
    let AmendFromPeriod = (<HTMLInputElement>document.getElementById("AmendFromPeriod"));
    let AmendToPeriod = (<HTMLInputElement>document.getElementById("AmendToPeriod"));
    let ForecastId = localStorage.getItem("ForecastId");
    this.forecastExtentService.AmendSaveData(ForecastId, parseInt(AmendFromPeriod.value), parseInt(AmendToPeriod.value)).subscribe(
      (response => {
        this._router.navigateByUrl('/forecast');
        this.ngOnInit();

      })
    )
  }
  OnClickCancel() {
    this._router.navigateByUrl('/forecast');
  }

}
