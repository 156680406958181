import { Component, OnInit, Input } from '@angular/core';
import { MethodserviceService } from '../../../../component/record/method/service/methodservice.service';
import { DetailservicesService } from '../../details-services/detailservices.service';

@Component({
  selector: 'app-department-view-details',
  templateUrl: './department-view-details.component.html',
  styleUrls: ['./department-view-details.component.scss']
})
export class DepartmentViewDetailsComponent implements OnInit {

  @Input() event;
  constructor(private methodservice: MethodserviceService, private DetailservicesServices: DetailservicesService) { }

  ngOnInit(): void {
  }

  clickgetdata(Id, $event) { 
    if($event.target.value =='' && Id==undefined && Id==''){
      return;
    }
    let UpdateDetails = {
      Name: $event.target.value
    }
    let TreeViews = localStorage.getItem("treeview");
    if(TreeViews!=null){
      let parseTree = JSON.parse(TreeViews);
      let UpdatedTreeView = this.UpdateLocalStorage(parseTree, UpdateDetails.Name, Id);
      localStorage.setItem("treeview", JSON.stringify(UpdatedTreeView));
    }
    document.getElementById(Id + "-treemenu").innerText = $event.target.value;
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateLocalStorage(node, Name, ID) {
    if (node.nodeId == ID) {
        node.name = Name;
    }
    if (node.children != null && node.children.length > 0) {
      if (node.children != null && node.children.length > 0) {
        node.children.forEach(element => {
          this.UpdateLocalStorage(element,Name, ID)
        });
      }
    }
    return node;
  }
  DescriptionBulrEvent(Id, $event) {
    let UpdateDetails = {
      Description: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateAPI(Id, UpdateDetails) { 
    
    this.DetailservicesServices.UpdateForecastRecordDetails(Id, UpdateDetails).subscribe(
      (response: any) => {
        // console.log(response);
      }
    )
  }
}
