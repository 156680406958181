import { Component, OnInit, Injector } from '@angular/core';
import { TreeviewService } from '../../service/treeview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { OpeningService } from "../../service/opening.service";
import { Report } from '../../model/report/report';
import * as XLSX from 'xlsx';
import { forEach } from 'jszip';
import { NotificationService } from 'src/app/core/services/notification.service';
import { EnterActualsService } from "../../service/enter-actuals.service";
declare const $: any;

@Component({
  selector: 'app-enter-opening',
  templateUrl: './enter-opening.component.html',
  styleUrls: ['./enter-opening.component.scss']
})
export class EnterOpeningComponent implements OnInit {

  activeId: any;
  DefualtBalanceopeningEntry: any
  Openingtreeview: any;
  sectiontreeview: any;
  message = "";
  val: any;
  displayedColumns: string[];
  dataSource: any[] = [];
  displaySampleColum: any[];
  latestarrayImport: any = [];
  difference = 0.00;
  jsonArray = {
    "parentRowNum": null,
    "rowNum": null,
    "name": null,
    "nodeType": null,
    "id": null,
    "nodePath": null,
    "mul": null,
    "pos": false,
    "recordNodeId": null,
    "recordCode01": null,
    "recordCode02": null,
    "recordCode03": null,
    "budgetDataType": null,
    "budgetDataNodeId": null,
    "resultNodeId": null,
    "recordCurrencyNodeId": null,
    "recordCurrencyTLA": null,
    "recordCurrency": null,
    "rate": null,
    "localCurrencyTLA": null,
    "localCurrency": null,
    "children": []
  }
  rounding = [1, 10, 100, 200]
  selectedRounding: any;
  IntegrationActive = "";
  IntegrationWithName = "";

  constructor(private OpeningService: OpeningService,
    private treeview: TreeviewService,
    private _router: Router,
    private injector: Injector,
    private EnterActualsService:EnterActualsService,
    private SpinnerService: NgxSpinnerService) {
    this.activeId = localStorage.getItem('ForecastId');
    this.IntegrationActive   =  localStorage.getItem('IntegrationActive');
    this.IntegrationWithName =  localStorage.getItem("IntegrationWithName");
    let mini = true;
  }

  ngOnInit() { 
    this.selectedRounding = 1;
    let newDate = '';
    $("#datepickerActual").on("change", function () {
      this.setAttribute(
        "value",
        this.value
      )
    }).trigger("change")
    $("#datepickerActual").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months"
    });
    this.DefualtBalanceopeningEntry = '';
    this.Openingtreeview = '';
    this.sectiontreeview = '';
    this.SpinnerService.show();
    this.OpeningService.getEnterOpeningDefaultResult(this.activeId).subscribe(
      (response: Report[]) => { 
        this.SpinnerService.hide();
        this.DefualtBalanceopeningEntry = response;
        let getRowValues: any = response;
        getRowValues.forEach(element => {
          if (element.nodeType == 51010) {
            if (element.budgetDataNodeId == null) {
              this.OpeningService.CalculateAllSystemRecords(this.activeId).subscribe(
                (response => {
                  this._router.navigateByUrl('/EnterOpening')
                })
              )
            }
          }

        });
        var data = this.convert(getRowValues)
        //this.SpinnerService.hide();
        // console.log('array', data);
        this.Openingtreeview = data;
        this.sectiontreeview = data[0];
      })
  }

  selectRounding($event) {
    this.selectedRounding = parseInt($event.target.value);
  }

  ImportFromIntegration() {
    const notifier = this.injector.get(NotificationService);
    console.log(this.IntegrationActive, this.IntegrationWithName );
    if(this.IntegrationActive){
      if(this.IntegrationWithName == "Not Connected"){
        notifier.showError("Please Authenticate with xero");
        return;
      }
    }
    let MonthIntegration = "";
    let YearIntegration = "";
    let validNodeType = 0;
    var inputValue: any = (<HTMLInputElement>document.getElementById("datepickerActual"));
    if (inputValue != null) {
      var MonthYear = inputValue.value;
      var ArrayMonthYear = MonthYear.split(" ");
      MonthIntegration = ArrayMonthYear[0];
      YearIntegration = ArrayMonthYear[1];
    }
   
    this.SpinnerService.show();
    this.EnterActualsService.ImportFromIntegrationService(this.activeId, -1, 990003, MonthIntegration, YearIntegration, this.selectedRounding).subscribe(
      (response: any) => { 
        if(response==1){
          notifier.showError("Please Authenticate with xero");
          this.SpinnerService.hide();
          return;
        }
        this.SpinnerService.hide();
        this.ngOnInit();
      })
  }

  convert(array) {
    var map = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.children = [];

      map[obj.rowNum] = obj;

      var parentRowNum = obj.parentRowNum || '-';
      if (!map[parentRowNum]) {
        map[parentRowNum] = {
          children: []
        };
      }
      map[parentRowNum].children.push(obj);
    }

    return map['-'].children;

  }
  printOpeningBalance() {
    var tab = document.getElementById('table');

    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";

    var win = window.open('', '', 'height=700,width=700');
    win.document.write(style);
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  }
  openImportFile() {
    document.getElementById('EnterOpeningImport').click();
  }

  handle($event: any) {  
    var dataFile = $event.target.files[0];
    const target: DataTransfer = <DataTransfer>($event.target);
    if (target.files.length !== 1) {
      return;
    }
    if (dataFile.type != 'text/csv') {
      alert("Upload only .CSV file");
      return;
    }
    const reader: FileReader = new FileReader();
    reader.readAsText(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      let row_data = binarystr.split('\n');
      this.displaySampleColum = ['Name', 'Values']
      let data = [];
      row_data.forEach(row_data => {
        let row = {};
        this.displaySampleColum.forEach((a, index) => {
          if (row_data.split('\t')[index] != '' && row_data.split('\t')[index] != undefined) {
            row[a] = row_data.split('\t')[index];
          }
        });
        data.push(row);
      })
      let mainArray = [];
      data.forEach(element => {
        if (element['Name'] != null) {
          let rowdataObject = {};
          const convertArray = element['Name'].split(',');
          this.displaySampleColum.forEach((a, index) => {
            rowdataObject['Name'] = convertArray[0];
            rowdataObject['Values'] = convertArray[1];
          });
          mainArray.push(rowdataObject);
        }
      });
      this.ImportEnterOpeningFromExcel(mainArray);
    }

  }

  calculateTotal() {
    this.DefualtBalanceopeningEntry = '';
    this.Openingtreeview = '';
    this.sectiontreeview = '';
    this.SpinnerService.show();
    this.OpeningService.getEnterOpeningDefaultResult(this.activeId).subscribe(
      (response: Report[]) => {
        this.DefualtBalanceopeningEntry = response;
        var data = this.convert(this.DefualtBalanceopeningEntry)
        this.SpinnerService.hide();
        this.Openingtreeview = data;
        this.sectiontreeview = data[0];
      })
  }

  /**
    * Function For Import Enter Opening
    * @param event 
    */

  async ImportEnterOpeningFromExcel(ImportFromExcel) {
   
    this.latestarrayImport = [];
    let insideimportdata = {
      Name: String,
      Value: Number,
    }
    let arrayofJsonImportData = {
      nodeId: Number,
      Nodetype: null,
      currentActualIndex: null,
      DisplayBudgetData: [],
    }

    this.dataSource = ImportFromExcel;
    console.log(this.dataSource);
    if (this.dataSource.length > 0) {
      this.dataSource.forEach(element => {
        if (element["Name"] != undefined) {
          let ValuesElement = '';
          let NameElement = '';
          var valueToPush = {};
          NameElement = element["Name"]
          valueToPush["Name"] = NameElement;
          if (element["Values"].includes('\r')) {
            element["Values"].replace(/\r?\n|\r/g, " ")
            ValuesElement = element["Values"].replace(/\r?\n|\r/g, " ");
            valueToPush["Value"] = parseInt(ValuesElement.replace(/\r?\n|\r/g, " "));
            if (Number.isNaN(valueToPush["Value"])) {
              valueToPush["Value"] = '0.00';
            }

          } else {
            element["Values"].replace(/\r?\n|\r/g, " ")
            ValuesElement = element["Values"].replace(/\r?\n|\r/g, " ");

            valueToPush["Value"] = parseFloat(ValuesElement.replace(/\r?\n|\r/g, " ")).toFixed(2);
            if (Number.isNaN(valueToPush["Value"])) {
              valueToPush["Value"] = '0.00';
            }
          }
          element.Name = NameElement;
          if (Number.isNaN(element.Values)) {
            let value = '0.00';
            element.Values = parseFloat(value).toFixed(2);
          } else {
            element.Values = parseFloat(ValuesElement).toFixed(2);
          }

          this.latestarrayImport.push(valueToPush);
        }
      });
      await this.delay(300);
      arrayofJsonImportData.nodeId = this.activeId;
      arrayofJsonImportData.Nodetype = 990003;
      arrayofJsonImportData.currentActualIndex = -1;
      arrayofJsonImportData.DisplayBudgetData = this.latestarrayImport;
      // console.log(arrayofJsonImportData.DisplayEnterOpeningImportData);
      var inputValue: any = (<HTMLInputElement>document.getElementById('EnterOpeningImport'));
      inputValue.value = "";
      this.SpinnerService.show();
      this.OpeningService.ImportDataEnterOpening(arrayofJsonImportData).subscribe(
        (response: Report[]) => {
          this.SpinnerService.hide();
          this.ngOnInit();
        })
    }


  }

  saveEnterOpening() {
    this.SpinnerService.show();
    this.OpeningService.getEnterOpeningDefaultResult(this.activeId).subscribe(
      (response: Report[]) => {
        this.DefualtBalanceopeningEntry = response;
        var data = this.convert(this.DefualtBalanceopeningEntry)
        this.SpinnerService.hide();
        this.Openingtreeview = data;
        this.sectiontreeview = data[0];

        //console.log("this.Openingtreeview", this.Openingtreeview);
        let netAssetIndex = this.Openingtreeview.findIndex(
          (r) => r.name === "TOTAL NET ASSETS"
        );

        let CapitalIndex = this.Openingtreeview.findIndex(
          (r) => r.name === "TOTAL CAPITAL AND RESERVES"
        );
        console.log("index", netAssetIndex, CapitalIndex);
        let totalnetAsset = parseFloat(this.Openingtreeview[netAssetIndex].localCurrency);
        let totalCapitalRes = parseFloat(this.Openingtreeview[CapitalIndex].localCurrency);
        this.difference = totalnetAsset - totalCapitalRes;
        let msg = "";
        if (this.difference >= 0.5)
          msg = "The Net Assets exceed the Capital and Reserves by " + this.difference.toString();
        else if (this.difference <= -0.5)
          msg = "The Capital and Reserves exceed the Net Assets by " + (- this.difference).toString();
        else
          msg = null;

        if (msg != null) {
          msg += ".\n\n Do you want to Auto-Balance?";
          this.message = msg;
          document.getElementById("AutoBalanceResponse").click();
          console.log(this.message);
        }


        //

      })

  }

  AutoBalanceClosing() {
    this._router.navigateByUrl('/forecast');
  }


  AutoBalancing() {
    this.SpinnerService.show();
    this.OpeningService.AutoBalance(this.activeId, this.difference, -1).subscribe(
      (response => {
        this.SpinnerService.hide();
        this._router.navigateByUrl('/forecast');
      })
    )
  }


  OpeningReset() {
    this.SpinnerService.show();
    let ForecastId = localStorage.getItem("ForecastId")
    this.OpeningService.ResetOpening(ForecastId).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.ngOnInit();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }
  /**
   * Function For Export Enter Opening
   * @param separator 
   */
  exportCSV(separator = ',') {
    var rows = this.DefualtBalanceopeningEntry;
    var csv = [];
    var row = [];
    if (rows != null) {
      for (var i = 0; i < rows.length; i++) {
        var row = [];
        var data = rows[i].name.replace(/"/g, '""');
        var currncay = rows[i].localCurrency;
        row.push('"' + data + '"');
        row.push(currncay);
        csv.push(row.join(separator));
      }

    }
    var csv_string = csv.join('\n');
    var filename = 'export_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Function For Import Enter Opening
   * @param event 
   */

  async ImportEnterOpening(event: ClipboardEvent) {

    this.latestarrayImport = [];
    let insideimportdata = {
      Name: String,
      Value: Number,
    }
    let arrayofJsonImportData = {
      nodeId: Number,
      Nodetype: null,
      currentActualIndex: null,
      DisplayBudgetData: [],
    }
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData.getData('text');
    // console.log(pastedText);
    let row_data = pastedText.split('\n');
    this.displayedColumns = row_data[0].split('\t');
    this.displaySampleColum = ['Name', 'Values']
    let data = [];
    row_data.forEach(row_data => {
      let row = {};
      this.displaySampleColum.forEach((a, index) => {
        if (row_data.split('\t')[index] != '' && row_data.split('\t')[index] != undefined) {
          row[a] = row_data.split('\t')[index];
        }
      });
      data.push(row);
    })
    this.dataSource = data;
    await this.delay(300);
    if (this.dataSource.length > 0) {
      this.dataSource.forEach(element => {
        if (element["Name"] != undefined) {
          let ValuesElement = '';
          let NameElement = '';
          var valueToPush = {};
          NameElement = element["Name"]
          valueToPush["Name"] = NameElement;
          if (element["Values"].includes('\r')) {
            element["Values"].replace(/\r?\n|\r/g, " ")
            ValuesElement = element["Values"].replace(/\r?\n|\r/g, " ");
            valueToPush["Value"] = parseInt(ValuesElement.replace(/\r?\n|\r/g, " "));
          }
          element.Name = NameElement;
          element.Value = ValuesElement;
          this.latestarrayImport.push(valueToPush);
        }
      });
      arrayofJsonImportData.Nodetype = 990003;
      arrayofJsonImportData.nodeId = this.activeId;
      arrayofJsonImportData.currentActualIndex = -1;
      arrayofJsonImportData.DisplayBudgetData = this.latestarrayImport;
      // console.log(arrayofJsonImportData);
      this.OpeningService.ImportDataEnterOpening(arrayofJsonImportData).subscribe(
        (response: Report[]) => {
          // console.log(response);
          this.ngOnInit();
        })
    }


  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
