import { Component, OnInit, Injector, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Userregistrationmodel } from 'src/app/model/userregistrationmodel';
import { UserregistrationService } from 'src/app/service/userregistration.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegisterValidators } from 'src/app/core/validators/register-validators';
import { EmailTaken } from 'src/app/core/validators/email-taken';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Countries } from 'src/app/model/Countries.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Input() forgotpassworddialog;

  forgotpasswordemailId = new UntypedFormControl(
    '', 
    [Validators.required, RegisterValidators.regexValidator(new
    RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), { 'email': 'not valid' })],
    [this.emailInvalid.valid]
    )

    userForgotpasswordForm = new UntypedFormGroup({
      forgotpasswordemailId: this.forgotpasswordemailId
  })

  constructor( 
    private SpinnerService: NgxSpinnerService, 
    private userService: UserregistrationService,
    private injector: Injector, 
    public emailInvalid: EmailTaken) { }

  ngOnInit() {
  }

  onSubmit() {
    
    const notifier = this.injector.get(NotificationService);
    if (this.userForgotpasswordForm.invalid) {
      return;
    }
    this.SpinnerService.show();
   this.userService.forgotPassword(this.userForgotpasswordForm.value.forgotpasswordemailId).subscribe(
    (response=>{
      try {
        this.SpinnerService.hide();
        notifier.showSuccess("Please check emailID, and Click on ForgotPassWord Link");
        this.forgotpassworddialog.close();
      } catch (error) {
        this.SpinnerService.hide();
        notifier.showError("emailID is not valid");
        this.forgotpassworddialog.close();
      }
    })
   )
  
  }
  closeevent(){
    this.forgotpassworddialog.close();
  }

}
