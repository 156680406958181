<div class="registration">
    <igx-input-group type="line" class="registrationInput">
        <input igxInput [formControl]="control" [type]="type" required />
        <label igxLabel [for]="control" [textContent]="text"></label>
        <igx-suffix>
            <igx-icon [textContent]="icon"></igx-icon>
        </igx-suffix>
    </igx-input-group>
</div>
<!-- <input [formControl]="control" [type]="type" class="block w-full py-1.5 px-3 text-gray-200 border border-gray-400 transition
    duration-500 focus:outline-none rounded bg-transparent focus:border-indigo-400" [placeholder]="placeholder" /> -->
<ng-container *ngIf="control.touched && control.dirty">
    <p class="text-red-400" *ngIf="control.errors?.required">
        Field is required.
    </p>
    <p class="text-red-400" *ngIf="control.errors?.email">
        You must enter a valid email.
    </p>
    <!-- <p class="text-red-400" *ngIf="control.hasError('email') ">
        You must eneter a valid email.
    </p> -->
    <p class="text-red-400" *ngIf="control.hasError('password') ">
        Password must be
        - at least 8 characters
        - contain at least 1 uppercase letter, 1 lowercase letter, and 1 number
    </p>
    <p class="text-red-400" *ngIf="control.errors?.noMatch">
        Passwords do not match.
    </p>
    <p class="text-red-400" *ngIf="control.errors?.emailTaken">
        User Already Exists, please user another email ID
    </p>
    <p class="text-red-400" *ngIf="control.errors?.emailInValid">
        User Not Register, please enter register email Id
    </p>
</ng-container>