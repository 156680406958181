 <div class="Stock-component">
    <div class="add-delete-section-component col-sm-12">
        <div class="col-sm-2">
            <label class="col">Stock Component</label>
        </div>
        <div class="stock-component-add-button col-sm-2" style="margin: 0px;">
            <button name="Add Component" class="btn-primary col-sm-12" 
            style="padding: 10px;"
            (click)="AddStockComponent(activeNodeId)">Add Component</button>
        </div>
        <div class="stock-component-delete-button col-sm-4">
            <button name="Delete Component" class="btn-primary col-sm-6" 
            style="padding: 10px;" (click)="DeleteStockComponent(activeNodeId, 1)">Delete Component</button>
        </div>
    </div>
    <div *ngIf="TabscollectionResult.length>0" class="col-sm-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" *ngFor="let result of TabscollectionResult; let i=index">
                <a class="nav-link" 
                [ngClass]="i == 0 ? 'active' : ''"
                id="stockComponent{{result.nodeId}}-tab" 
                data-toggle="tab" href="#stockComponent{{result.nodeId}}" 
                role="tab"  ><input type="text" value="{{result.name}}" (change)="ChangeName(result, $event)"> <div class="glyphicon glyphicon-remove" (click)="DeleteStockComponent(activeNodeId , i)"></div></a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="stockComponent{{result.nodeId}}" role="tabpanel"
            *ngFor="let result of TabscollectionResult; let i=index;"
            [ngClass]="i == 0 ? 'active' : ''"
            >
                <div class="container-fluid" style="border: 1px solid #b4d1ec;overflow-x: auto;border-top: none;">
                    <div class="row flex-nowrap" style="margin-top:3%"> 
                        <div class="listingvaluelist resultInsideData{{displaydata.derivationType}}-{{displaydata.nodeTypeEnglishLabel}} 
                        valuelistmain{{displaydata.nodeTypeEnglishLabel}} col-sm-3 "
                            *ngFor="let displaydata of result.displayBudgeData.getAllDisplayBudgetDataValue[0].getDisplayBudgetData; let i = index" [attr.data-index]="i">
                                <igx-card [horizontal]="horizontal" elevated>
                                    <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                        <igx-card-header>
                                            <div class="valuelist-title">
                                                <label style="word-break: break-all;" > {{displaydata.englishLabel}}</label>
                                            </div>
                                        </igx-card-header>
                                        <igx-card-content> 
                                            <div class="methodandvaluelistcontain">
                                                <!-- <app-stock-component-html 
                                                [valuelistdata]='displaydata' 
                                                [valueListDerrivation]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getDataDerrivation[i]"
                                                [ValueListMonthDisplay]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getMonthDisplay"
                                                [startIndex]="ActivePeriodTabStockComponent"
                                                ></app-stock-component-html>  -->
                                                <app-stock-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="displaydata"
                                                            [valueListDerrivation]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getDataDerrivation[i]"
                                                            [ValueListMonthDisplay]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getMonthDisplay"></app-stock-method>
                                                <app-stock-component-html 
                                                    [valuelistdata]='displaydata'
                                                    [valueListDerrivation]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getDataDerrivation[i]"
                                                    [startIndex]="ActivePeriodTabStockComponent"
                                                    [ValueListMonthDisplay]="result.displayBudgeData.getAllDisplayBudgetDataValue[0].getMonthDisplay"
                                                    (recordModifiedEvent)="recordModified($event)">
                                                </app-stock-component-html>
                                            </div>  
                                        </igx-card-content>
                                    </div>
                                </igx-card>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="spiner">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="small" type = "square-jelly-box" [fullScreen] = "false">  
        <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
    </div>
</div>

