import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Sections } from '../model/sections';
import { sectionModel, sectionTreeModel } from '../model/sectionTreeModel';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  SectionData: Sections[] = [];
  constructor(private http: HttpClient) { }

  /**
   * Function For get all children from section_Id
   * @returns response
   */
  getSectionsChildrens(ForecastId, SectionID): Observable<Sections[]> {
    return this.http.get(environment.url + "/api/forecast/section/" + ForecastId + "/" + SectionID, { responseType: "json" })
      .pipe(map(
        (response: Sections[]) => {
          return response;
        }
      ));
  }

  saveRecordsFolder(sectionTree): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/forecast/sectionFolder";
    console.log(sectionTree, url);
    return this.http.post(url, sectionTree, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

}
