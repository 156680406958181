import { Component, OnInit,Injector } from '@angular/core';
import { TreeviewService } from '../../service/treeview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { EnterActualsService } from "../../service/enter-actuals.service";
import * as XLSX from 'xlsx';
import { OpeningService } from 'src/app/service/opening.service';
import { NotificationService } from 'src/app/core/services/notification.service';
declare const $: any;

@Component({
  selector: 'app-enter-actuals',
  templateUrl: './enter-actuals.component.html',
  styleUrls: ['./enter-actuals.component.scss']
})
export class EnterActualsComponent implements OnInit {

  activeId: any;
  DefualtEnterActualEntryData: any = {
    "profitAndLoss": [],
    "BalanceSheet": [],
  }
  ActualEntrtData: any = {
    "profitAndLoss": [],
    "BalanceSheet": [],
  }
  EnterActualTreeView: any;
  months = [];
  CurrentActualsIndex: any;
  EnterActualBalanceSheetView: any;

  DisplayTreeProfitAndLoss = '';
  DisplayTreeBalanceSheet = '';
  displayedColumns: string[];
  dataSource: any[] = [];
  displaySampleColum: any[];
  latestarrayImport: any = [];

  rounding = [1, 10, 100, 200]
  selectedRounding: any;
  activeSelectionID: string;
  message = "";
  difference = 0.00;
  IntegrationActive = "";
  IntegrationWithName = "";
  constructor(private EnterActualsService: EnterActualsService,
    private TreeviewServices: TreeviewService,
    private _router: Router,
    private OpeningService: OpeningService,
    private injector: Injector,
    private SpinnerService: NgxSpinnerService) {
    this.activeId = localStorage.getItem('ForecastId');
    this.IntegrationActive   =  localStorage.getItem('IntegrationActive');
    this.IntegrationWithName =  localStorage.getItem("IntegrationWithName");
  }

  ngOnInit() {
    this.selectedRounding = 1;
    let newDate = '';
    $("#datepickerActual").on("change", function () {
      this.setAttribute(
        "value",
        this.value
      )
    }).trigger("change")
    $("#datepickerActual").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months"
    });

    this.DefualtEnterActualEntryData = {
      "profitAndLoss": [],
      "BalanceSheet": [],
    };

    this.EnterActualTreeView = '';

    this.activeSelectionID = "profitAndLoss";
    if (this.CurrentActualsIndex == null) {
      this.getForecastDetailsonLoad();
    }

    if (this.CurrentActualsIndex != null) {
      this.SpinnerService.show();
      this.DisplayTreeProfitAndLoss = 'Active';
      // if (this.activeSelectionID == "profitAndLoss") {
      //   this.DisplayTreeProfitAndLoss = 'Active';
      //   this.DisplayTreeBalanceSheet = '';
      // } else {
      //   if (this.activeSelectionID  == "BalanceSheet") {
      //     this.DisplayTreeBalanceSheet = 'Active';
      //     this.DisplayTreeProfitAndLoss = '';
      //   } 
      // }
      this.ActualEntrtData.profitAndLoss = [];
      this.DefualtEnterActualEntryData.profitAndLoss = [];
      this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990001).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          var data = this.convertingTreeList(response);
          this.ActualEntrtData.profitAndLoss = response;
          this.DefualtEnterActualEntryData.profitAndLoss = data;
        })
    }


  }

  getForecastDetailsonLoad() {
    this.months = [];
    let ForecastId = localStorage.getItem('ForecastId');
    this.TreeviewServices.getforecastDetails(ForecastId).subscribe(
      (response: any) => {
        let ForecastStartDate = response.startdate;
        this.CurrentActualsIndex = response.currentActualsIndex;
        var date = new Date(ForecastStartDate);
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        for (var i = 0; i < 12; i++) {
          this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
          date.setMonth(date.getMonth() + 1);
        }
        if (this.CurrentActualsIndex != null) {
          this.SpinnerService.show();
          this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990001).subscribe(
            (response: any) => {
              var data = this.convertingTreeList(response);
              this.ActualEntrtData.profitAndLoss = response;
              this.DefualtEnterActualEntryData.profitAndLoss = data;
            })
          this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990003).subscribe(
            (response: any) => {
              this.SpinnerService.hide();
              var data = this.convertingTreeList(response);
              this.ActualEntrtData.BalanceSheet = response;
              this.DefualtEnterActualEntryData.BalanceSheet = data;
            })
          this.DisplayTreeProfitAndLoss = 'Active';

        }
      }
    )
  }
  selectRounding($event) {
    this.selectedRounding = parseInt($event.target.value);
  }
  ImportFromIntegration() {
    const notifier = this.injector.get(NotificationService);
    if(this.IntegrationActive){
      if(this.IntegrationWithName == "Not Connected"){
        notifier.showError("Please Authenticate with xero");
        return;
      }
    }
    let MonthIntegration = "";
    let YearIntegration = "";
    let validNodeType = 0;
    var inputValue: any = (<HTMLInputElement>document.getElementById("datepickerActual"));
    if (inputValue != null) {
      var MonthYear = inputValue.value;
      var ArrayMonthYear = MonthYear.split(" ");
      MonthIntegration = ArrayMonthYear[0];
      YearIntegration = ArrayMonthYear[1];
    }
    if (this.activeSelectionID == "profitAndLoss") {
      validNodeType = 990001;
    } else {
      if (this.activeSelectionID  == "BalanceSheet") {
        validNodeType = 990003;
      } 
    }
    this.SpinnerService.show();
    this.EnterActualsService.ImportFromIntegrationService(this.activeId, this.CurrentActualsIndex, validNodeType, MonthIntegration, YearIntegration, this.selectedRounding).subscribe(
      (response: any) => { 
        if(response==1){
          notifier.showError("Please Authenticate with xero");
          this.SpinnerService.hide();
          return;
        }
        this.SpinnerService.hide();
        this.ngOnInit();
      })
  }
  ChangeCurerentIndex(index) {
    if (parseInt(this.CurrentActualsIndex) >= parseInt(index.target.value)) {
      this.CurrentActualsIndex = index.target.value;
      this.ngOnInit();
    }
    else {
      this.CurrentActualsIndex = index.target.value;
      this.EnterActualsService.startActualWithCurrentActualIndex(index.target.value).subscribe(
        (response: any) => {
          this.ngOnInit();
        }
      )
    }
  }

  ProfitAndLoss() {
    this.DisplayTreeProfitAndLoss = 'Active';
    this.DisplayTreeBalanceSheet = '';
    this.activeSelectionID = "profitAndLoss";
    this.ActualEntrtData.profitAndLoss = [];
    this.DefualtEnterActualEntryData.profitAndLoss = [];
    this.SpinnerService.show();
    this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990001).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        var data = this.convertingTreeList(response);
        this.ActualEntrtData.profitAndLoss = response;
        this.DefualtEnterActualEntryData.profitAndLoss = data;
      })
  }

  BalanceSheettabClick() {
    this.DisplayTreeProfitAndLoss = '';
    this.DisplayTreeBalanceSheet = 'Active';
    this.activeSelectionID = "BalanceSheet";
    this.ActualEntrtData.BalanceSheet = [];
    this.DefualtEnterActualEntryData.BalanceSheet = [];
    this.SpinnerService.show();
    this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990003).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        var data = this.convertingTreeList(response);
        this.ActualEntrtData.BalanceSheet = response;
        this.DefualtEnterActualEntryData.BalanceSheet = data;
      })
  }
  /**
   * 
   * @param array 
   * @returns 
   */
  convertingTreeList(array) {
    var map = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.children = [];
      map[obj.rowNum] = obj;
      var parentRowNum = obj.parentRowNum || '-';
      if (!map[parentRowNum]) {
        map[parentRowNum] = {
          children: []
        };
      }
      map[parentRowNum].children.push(obj);
    }

    return map['-'].children;

  }

  printEnterActual() {
    var tab = document.getElementsByClassName('tab-pane fade in show ng-star-inserted active');
    // console.log(tab);
    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "span.displayvalue {float: right;}";
    style = style + "</style>";

    var win = window.open('', '', 'height=700,width=700');
    win.document.write(style);
    win.document.write(tab[0].outerHTML);
    win.document.close();
    win.print();
  }

  /**
 * Function For Export Enter Opening
 * @param separator 
 */
  exportCSV(separator = ',') {
    var tab = document.getElementsByClassName('tab-pane fade in show ng-star-inserted active');
    console.log(tab);
    let activeActualId;
    if (tab.length == 0) {
      activeActualId = "profitAndLoss";
      var rows = this.ActualEntrtData.profitAndLoss;
    } else {
      activeActualId = tab[0].id;
      if (activeActualId == "BalanceSheet") {
        var rows = this.ActualEntrtData.BalanceSheet;
      } else if (activeActualId == "profitAndLoss") {
        var rows = this.ActualEntrtData.profitAndLoss;
      }
    }

    // console.log(activeActualId);
    // console.log(rows);

    var csv = [];
    var row = [];
    if (rows != null) {
      for (var i = 0; i < rows.length; i++) {
        var row = [];
        if (rows[i].name != null) {
          var data = rows[i].name.replace(/"/g, '""');
          var currncay = rows[i].localCurrency;
          row.push('"' + data + '"');
          row.push(currncay);
          csv.push(row.join(separator));
        }

      }
    }
    var csv_string = csv.join('\n');
    var filename = 'export_' + activeActualId + 'Actual_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openImportFile() { 
    let IntegrationActive = localStorage.getItem("IntegrationActive");
    if(IntegrationActive!="" && IntegrationActive!="true"){
      this._router.navigateByUrl("integration-import");
    }
    document.getElementById('EnterOpeningImport').click();
  }
  /**
   * 
   * @param $event 
   * @returns 
   */
  handle($event: any) {
    var dataFile = $event.target.files[0];
    const target: DataTransfer = <DataTransfer>($event.target);
    if (target.files.length !== 1) {
      return;
    }
    const reader: FileReader = new FileReader();
    reader.readAsText(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      let row_data = binarystr.split('\n');
      this.displaySampleColum = ['Name', 'Value']
      let data = [];
      row_data.forEach(row_data => {
        let row = {};
        this.displaySampleColum.forEach((a, index) => {
          if (row_data.split('\t')[index] != '' && row_data.split('\t')[index] != undefined) {
            row[a] = row_data.split('\t')[index];
          }
        });
        data.push(row);
      })
      let mainArray = [];
      data.forEach(element => {
        if (element['Name'] != null) {
          let rowdataObject = {};
          const convertArray = element['Name'].split(',');
          this.displaySampleColum.forEach((a, index) => {
            rowdataObject['Name'] = convertArray[0];
            rowdataObject['Value'] = convertArray[1];
          });
          mainArray.push(rowdataObject);
        }
      });
      this.ImportEnterOpeningFromExcel(mainArray);
    }

  }

  async ResetNoToActuals() {
    let ForecastNodeId = localStorage.getItem("ForecastId");
    this.SpinnerService.show();
    let IntegrationActive = localStorage.getItem("IntegrationActive");
    console.log("test");
    if(this.months!=null && this.months.length>0 ){ 
      var validNodeType = 0;
      if (this.activeSelectionID == "profitAndLoss") {
        validNodeType = 990001;
      } else {
        if (this.activeSelectionID  == "BalanceSheet") {
          validNodeType = 990003;
        } 
      }
       this.EnterActualsService.ResetSelectedIndex(validNodeType, ForecastNodeId).subscribe(
        (response=>{
          this.SpinnerService.hide();
          this.ngOnInit();
        })
       )
    } else {
      this.EnterActualsService.ResetNoToActuals(ForecastNodeId, true).subscribe(
        (response: any) => {
          localStorage.setItem('IsCanEnterActual', 'false');
          localStorage.setItem('IsCanStartActual', 'true');
  
          this.SpinnerService.hide();
          this.ngOnInit();
        }
      )
    }
    
  }
  /**
   * 
   * @param CurrentActualsIndex 
   * @param activeId 
   */

  async CalculateActual(CurrentActualsIndex, activeId) {
    var isActiveTree = this.activeSelectionID;
    this.ngOnInit();
    if (isActiveTree == "BalanceSheet") {
      this.BalanceSheettabClick();
    }
  }



  /**
    * Function For Import Enter Opening
    * @param event 
    */

  async ImportEnterOpeningFromExcel(ImportFromExcel) {

    this.latestarrayImport = [];
    let insideimportdata = {
      Name: String,
      Value: Number,
    }
    let arrayofJsonImportData = {
      nodeId: Number,
      Nodetype: null,
      currentActualIndex: Number,
      DisplayBudgetData: [],
    }

    this.dataSource = ImportFromExcel;
    console.log(this.dataSource);
    if (this.dataSource.length > 0) {
      this.dataSource.forEach(element => {
        if (element["Name"] != undefined) {
          let ValuesElement = '';
          let NameElement = '';
          var valueToPush = {};
          NameElement = element["Name"]
          valueToPush["Name"] = NameElement;
          if (element["Value"] !=undefined && element["Value"].includes('\r')) {
            element["Value"].replace(/\r?\n|\r/g, " ")
            ValuesElement = element["Value"].replace(/\r?\n|\r/g, " ");
            valueToPush["Value"] = parseInt(ValuesElement.replace(/\r?\n|\r/g, " "));
          } else {
            if( element["Value"] !=undefined ) {
              element["Value"].replace(/\r?\n|\r/g, " ")
              ValuesElement = element["Value"];
              valueToPush["Value"] = parseInt(ValuesElement);
            } else {
             // element["Value"].replace(/\r?\n|\r/g, " ");
              ValuesElement = null;
              valueToPush["Value"] = parseInt(ValuesElement);
            }
            
          }
          element.Name = NameElement;
          element.Value = ValuesElement;
          this.latestarrayImport.push(valueToPush);
        }
      });

      await this.delay(300);
      arrayofJsonImportData.nodeId = this.activeId;
      var tab = document.getElementsByClassName('tab-pane fade in show ng-star-inserted active');
      let activeActualId;
      let validNodeType = null;
      if (tab.length == 0) {
        activeActualId = "profitAndLoss";
        var rows = this.ActualEntrtData.profitAndLoss;
        validNodeType = 990001;
      } else {
        activeActualId = tab[0].id;
        if (activeActualId == "BalanceSheet") {
          validNodeType = 990003;
        } else if (activeActualId == "profitAndLoss") {
          validNodeType = 990001;
        }
      }
      arrayofJsonImportData.Nodetype = validNodeType;
      arrayofJsonImportData.currentActualIndex = this.CurrentActualsIndex;
      arrayofJsonImportData.DisplayBudgetData = this.latestarrayImport;
      // console.log(arrayofJsonImportData);
      var inputValue: any = (<HTMLInputElement>document.getElementById('EnterOpeningImport'));
      inputValue.value = "";
      this.SpinnerService.show();
      this.EnterActualsService.ImportDataEnterActual(arrayofJsonImportData).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          this.ngOnInit();
        })
    }


  }
  saveEnterActuals() {

  }

  Cancel() {
    this._router.navigateByUrl("/forecast");
  }
  Save(CurrentActualsIndex, activeId) {
    let validNodeType = null;
    if (this.activeSelectionID == "BalanceSheet" || this.activeSelectionID != undefined) {
      validNodeType = 990003;
    } else if (this.activeSelectionID == "profitAndLoss") {
      validNodeType = 990001;
    }
    if (validNodeType == null) {
      return;
    }
    this.SpinnerService.show();

    if (validNodeType == 990003) {
      this.EnterActualsService.getEnterActulaByNodeType(this.activeId, this.CurrentActualsIndex, 990003).subscribe(
        (response: any) => {
          this.SpinnerService.hide();
          var data = this.convertingTreeList(response);
          this.ActualEntrtData.BalanceSheet = response;
          this.DefualtEnterActualEntryData.BalanceSheet = data;

          let netAssetIndex = this.DefualtEnterActualEntryData.BalanceSheet.findIndex(
            (r) => r.name === "TOTAL NET ASSETS"
          );

          let CapitalIndex = this.DefualtEnterActualEntryData.BalanceSheet.findIndex(
            (r) => r.name === "TOTAL CAPITAL AND RESERVES"
          );
          console.log("index", netAssetIndex, CapitalIndex);
          let totalnetAsset = parseFloat(this.DefualtEnterActualEntryData.BalanceSheet[netAssetIndex].localCurrency);
          let totalCapitalRes = parseFloat(this.DefualtEnterActualEntryData.BalanceSheet[CapitalIndex].localCurrency);
          this.difference = totalnetAsset - totalCapitalRes;
          let msg = "";
          if (this.difference >= 0.5)
            msg = "The Net Assets exceed the Capital and Reserves by " + this.difference.toString();
          else if (this.difference <= -0.5)
            msg = "The Capital and Reserves exceed the Net Assets by " + (- this.difference).toString();
          else
            msg = null;

          if (msg != null) {
            msg += ".\n\n Do you want to Auto-Balance?";
            this.message = msg;
            document.getElementById("AutoBalanceResponse").click();
            console.log(this.message);
          }
        })
    }
    this.EnterActualsService.CalculateActuals(validNodeType, activeId, CurrentActualsIndex).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this._router.navigateByUrl("/forecast");
      }
    )
  }
  /**
   * 
   * @param ms 
   * @returns 
   */
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  AutoBalanceClosing() {
    this._router.navigateByUrl('/forecast');
  }


  AutoBalancing() {
    this.SpinnerService.show();
    this.OpeningService.AutoBalance(this.activeId, this.difference, this.CurrentActualsIndex).subscribe(
      (response => {
        this.SpinnerService.hide();
        this._router.navigateByUrl('/forecast');
      })
    )
  }
}
