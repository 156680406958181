import { UserregistrationService } from "src/app/service/userregistration.service";
import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EmailTaken implements AsyncValidator {
    constructor(private userService: UserregistrationService) {

    }

    validate = (control: AbstractControl): Promise<ValidationErrors | null> => {
        return this.userService.checkUserExists(control.value).then(
            response => response ? { emailTaken: true } : null
        );

    }
    valid = (control: AbstractControl): Promise<ValidationErrors | null> => {
        return this.userService.checkUserExists(control.value).then(
            response => !response ? { emailInValid: true } : null
        );
    }
}
