import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({ 
  providedIn: 'root'
})
export class ForecastSettingService {

  private headers: Headers;
  constructor( private http: HttpClient) { }

   /**
    * Function For get ForecastOtherOption Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getForecastSettingCollections(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ForecastSetting/ForecastSettingDetails/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
}
