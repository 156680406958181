import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class GSTRateService {
  private headers: Headers;
  constructor( private http: HttpClient) { }

   /**
    * Function For get GSTRates information
    * @param ForecastNodeId 
    * @returns 
    */
    getGSTRatesCollections(ForecastNodeId) : Observable<any>{
      return this.http.get(environment.url+"/api/GSTRates/GetGSTRatesCollection/"+ForecastNodeId,{ responseType: "json" })
         .pipe(map(
           (response:any) =>
           {
             return response;
           }
         ));
      }
      AddGSTRates(ForecastNodeId):Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/AddGSTRatesCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      DeleteGSTRates(ForecastNodeId, selectedIndex):Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/DeleteGSTRatesCollection/"+ForecastNodeId+"/"+selectedIndex, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      DefaultGSTIndex(ForecastNodeId, selectedIndex):Observable<any[]>{
        return this.http.put(environment.url+"/api/GSTRates/DefaultIndex/"+ForecastNodeId+"/"+selectedIndex, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      SetAllGSTRates(NodeId, SetAllValue):Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/SaveValueList/"+NodeId+"/"+SetAllValue, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

      setGSTRatesName(NodeId, Values, havepercentage) :Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/SetGSTRatesName/"+NodeId+"/"+Values+"/"+havepercentage, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      setGSTRatesindex(NodeId,valuesNodeId, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/SetGSTRatesIndex/"+NodeId+"/"+Values+"/"+valuesNodeId, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      SaveValueListByMonth(NodeId, index, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/GSTRates/SaveValueListByMonth/"+NodeId+"/"+index+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      SelectedSalesTaxSchemeIndex(NodeId, Values) :Observable<any[]>{
        return this.http.put(environment.url+"/api/GSTRates/SelectedSalesTaxSchemeIndex/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      DerivationType(NodeId, Values) :Observable<any[]>{
        return this.http.put(environment.url+"/api/GSTRates/DerivationType/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      SelectedPeriodIndex(NodeId, Values) :Observable<any[]>{
        return this.http.put(environment.url+"/api/GSTRates/SelectedPeriodIndex/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      SetDeferredPaymentMonths(NodeId, Values) :Observable<any[]>{
        return this.http.put(environment.url+"/api/GSTRates/SetDeferredPaymentMonths/"+NodeId+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
}
