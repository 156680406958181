<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-1">
    </div> 
    <h1>List Packages</h1>
    <div class="col-sm-1">
    </div> 
  </div>
    <div class="row">
      <div class="col-sm-1">
      </div>  
      <div class="col-sm  admin-settingmap">
        Packages
        <tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="Pakages" (activate)="ActiveEvent($event)">
            <ng-template #treeNodeTemplate let-node let-index="index">
            <span [attr.draggable]="true" [attr.aria-grabbed]="true"
                [class.draggable]="true" [class]="node.data.licensePackName" id="{{node.data.packageId}}" [class.title]="true">
                <span id="{{node.data.packageId}}-treemenu"
                style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.licensePackName
                }}</span></span>
        </ng-template>
        </tree-root>
      </div>
      <div class="col-sm admin-settingmap">
        Feature Groups
        <div *ngIf="FeatureGroup!=null">
            <tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="FeatureGroup" (activate)="ActiveFeatureGroupEvent($event)">
                <ng-template #treeNodeTemplate let-node let-index="index">
                <span [attr.draggable]="true" [attr.aria-grabbed]="true"
                    [class.draggable]="true" [class]="node.data.featureGroupName" id="{{node.data.featureGroupID}}" [class.title]="true">
                    <span id="{{node.data.featureGroupID}}-treemenu"
                    style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.featureGroupName
                    }}</span></span>
                </ng-template>
            </tree-root>
        </div>
        
      </div>
      <div class="col-sm admin-settingmap">
        Features
        <div *ngIf="Features!=null">
            <tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="Features" (activate)="ActiveFeatureEvent($event)">
                <ng-template #treeNodeTemplate let-node let-index="index">
                <span [attr.draggable]="true" [attr.aria-grabbed]="true"
                    [class.draggable]="true" [class]="node.data.featureName" id="{{node.data.featureID}}" [class.title]="true">
                    <span id="{{node.data.featureID}}-treemenu"
                    style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.featureName
                    }}</span></span>
                </ng-template>
            </tree-root>
        </div>
      </div>
      <div class="col-sm admin-settingmap">
        Sub Features
        <div *ngIf="SubFeatures!=null">
            <tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="SubFeatures" (activate)="ActiveSubFeatureGroupEvent($event)">
                <ng-template #treeNodeTemplate let-node let-index="index">
                <span [attr.draggable]="true" [attr.aria-grabbed]="true"
                    [class.draggable]="true" [class]="node.data.subFeatureName" id="{{node.data.subFeatureID}}" [class.title]="true">
                    <span id="{{node.data.subFeatureID}}-treemenu"
                    style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.subFeatureName
                    }}</span></span>
                </ng-template>
            </tree-root>
        </div>
      </div>
      <div class="col-sm-1">
      </div>
    </div>

    <!--- Edited View-->
    <div class="row">
      <div class="col-sm-1">
      </div> 
      <h1>Selected Packages</h1>
      <div class="col-sm-1">
      </div> 
    </div>
    
    <div class="row">
      
      <div class="col-sm-1">
      </div>  
      <div class="col-sm  admin-settingmap">
        Packages
        <div class="" *ngIf="CollectionPackage!=null">
          <tree-root #packageCollection [state]="state" [options]="options" [focused]="true" [nodes]="CollectionPackage">
            <ng-template #treeNodeTemplate let-node let-index="index">
            <span [attr.draggable]="true" [attr.aria-grabbed]="true"
                [class.draggable]="true" [class]="node.data.licensePackName" id="{{node.data.packageId}}" [class.title]="true">
                <span id="{{node.data.packageId}}-treemenu"
                style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.licensePackName
                }}</span></span>
        </ng-template>
        </tree-root>
        </div>
        
      </div>
      <div class="col-sm admin-settingmap">
        Feature Groups
        <div class="" *ngIf="CollectionFeatureGroup!=null">
          <tree-root #FeatureGroupCollection [state]="state" [options]="options" [focused]="true" [nodes]="CollectionFeatureGroup">
            <ng-template #treeNodeTemplate let-node let-index="index">
            <span [attr.draggable]="true" [attr.aria-grabbed]="true" *ngIf="node.data.edited"
                [class.draggable]="true" [class]="node.data.featureGroupName" id="{{node.data.featureGroupID}}" [class.title]="true">
                <span id="{{node.data.featureGroupID}}-treemenu"
                style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.featureGroupName
                }}</span></span>  
        </ng-template>
        </tree-root>
        </div>
        
      </div>
      <div class="col-sm admin-settingmap">
        Features
        <div class="" *ngIf="CollectionFeature!=null">
          <tree-root #FeatureCollection [state]="state" [options]="options" [focused]="true" [nodes]="CollectionFeature">
            <ng-template #treeNodeTemplate let-node let-index="index">
            <span [attr.draggable]="true" [attr.aria-grabbed]="true" *ngIf="node.data.edited"
                [class.draggable]="true" [class]="node.data.featureName" id="{{node.data.featureID}}" [class.title]="true">
                <span id="{{node.data.featureID}}-treemenu"
                style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.featureName
                }}</span></span>  
        </ng-template>
        </tree-root>
        </div>
      </div>
      <div class="col-sm admin-settingmap">
        Sub Features
        <div class="" *ngIf="CollectionSubFeature!=null">
          <tree-root #SubFeatureCollection [state]="state" [options]="options" [focused]="true" [nodes]="CollectionSubFeature">
            <ng-template #treeNodeTemplate let-node let-index="index">
            <span [attr.draggable]="true" [attr.aria-grabbed]="true" *ngIf="node.data.edited"
                [class.draggable]="true" [class]="node.data.subFeatureName" id="{{node.data.subFeatureID}}" [class.title]="true">
                <span id="{{node.data.subFeatureID}}-treemenu"
                style="font-size: 16px !important;line-height: normal;padding: 0 0px 0 5px; ">{{ node.data.subFeatureName
                }}</span></span>  
        </ng-template>
        </tree-root>
        </div>
      </div>
      <div class="col-sm-1">
      </div>
    </div>
  </div>
