import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ClientModel } from '../model/clientModel';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ClientsService {
    ClientModel: ClientModel[] = [];

    constructor(private http: HttpClient) { }

    getClientCollection(UserId): Observable<ClientModel[]> {
        let reqHeaders = new HttpHeaders().set('clientID', 'applicationID').set('Content-Type', 'application/json');
        return this.http.get(environment.url + "/api/Client/ClientList/" + UserId, { headers: reqHeaders })
            .pipe(map(
                (response: ClientModel[]) => {
                    return response;
                }
            ));
    }

    getCurrentUserInformation(UserID){
        return this.http.get(environment.url + "/api/UserMaintenance/CurrentUserCheckParent/" + UserID, { responseType:'json' })
            .pipe(map(
                (response: ClientModel[]) => {
                    return response;
                }
            ));
    }

    createnewClient(clientName, description, userID) {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.url + "/api/Client/CreateClient", { ClientName: clientName, Description: description, userID: userID, clientID: 0 }, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ))
    }
    UpdateClient(clientName, description, userID, clientID) {
        let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.url + "/api/Client/UpdateClient", { ClientName: clientName, Description: description, userID: userID, clientID: clientID }, { headers: reqHeaders })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ))
    }
    deleteClient(clientID) {
        return this.http.get(environment.url + "/api/Client/DeleteClient/" + clientID, { responseType: "json" })
            .pipe(map(
                (response: boolean) => {
                    return response;
                }
            ));
    }
    navigateToClient(clientID, username) {
        return this.http.get(environment.url + "/api/Client/ChangeDatabase/" + clientID + "/" + username, { responseType: "json" })
            .pipe(map(
                (response: boolean) => {
                    return response;
                }
            ));
    }

    getUserPermission(userID: string) {
        return this.http.get(environment.url + "/api/account/userpermission/" + userID, { responseType: "json" })
            .pipe(map(
                (response: any) => {
                    return response;
                }
            ));
    }
}