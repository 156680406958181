import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { MemoRecordOption } from '../model/memo-record-option';


@Injectable({
  providedIn: 'root'
})
export class MemoRecordOptionService {

  MemoRecordOption: MemoRecordOption[] = [];
  private headers: Headers;
  constructor(private http: HttpClient) { }

  /**
  * Function For get MemoRecordoptins
  * @returns response
  */
  getMemoRecordOptionLists(NodeId): Observable<MemoRecordOption[]> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOption/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: MemoRecordOption[]) => {
          return response;
        }
      ));
  }



  getBankAccountRecordOptionLists(NodeId): Observable<MemoRecordOption[]> {
    return this.http.get(environment.url + "/api/RecordOption/GetBankAccountRecordOption/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: MemoRecordOption[]) => {
          return response;
        }
      ));
  }
  /**
   * Function For get MemoRecordoptins
   * @returns response
   */
  getFixedAssetRecordOptionLists(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOptionFixedasset/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
   * Function For get MemoRecordoptins
   * @returns response
   */
  getAccreudPrepaidCostRecordOptionLists(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOptionAccruedPrepaidCost/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  getFinacingRecordOptionLists(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetFinacingRecordOptionLists/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  /**
* Function For get MemoRecordoptins
* @returns response
*/
  getOtherfixedassestRecordOptionLists(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOptionOtherFixedasset/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
* Function For get Recordoptins
* @returns response
*/
  getRecordOptionNodeType(NodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOptionNodeType/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  changeDestinationDepreciation(NodeId, selectedNodeId, activetreeNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordFixedassetDepreciationDestination/" + NodeId + "/" + selectedNodeId + "/" + activetreeNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordFixedassetOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordFixedassetOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordFinacingOpeningEntry(NodeId, Value): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/FinacingRecordSaveValues/" + NodeId + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: MemoRecordOption[]) => {
          return response;
        }
      ))
  }
  GetRecordAccumulatedDepreciationOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordAccumulatedDepreciationOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }

  changeRecordOption(NodeId, SelectedValue): Observable<MemoRecordOption[]> {
    return this.http.get(environment.url + "/api/RecordOption/RecordOption/" + NodeId + "/" + SelectedValue, { responseType: "json" })
      .pipe(map(
        (response: MemoRecordOption[]) => {
          return response;
        }
      ))
  }

  // Accrued option
  changeAccruedoptios(NodeId, selectedNodeId, activetreeNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecorAccruedchange/" + NodeId + "/" + selectedNodeId + "/" + activetreeNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordAccruedOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordAccruedOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordTradedebitorsOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordTradedebitorsOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }


  /**
   * function for other fixed asset
   */
  changeOtherDestinationDepreciation(NodeId, selectedNodeId, activetreeNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordOption/GetRecordOtherFixedassetDepreciationDestination/" + NodeId + "/" + selectedNodeId + "/" + activetreeNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordOtherFixedassetOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordOtherFixedassetOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
  GetRecordOtherAccumulatedDepreciationOpeningEntry(saveRecord): Observable<any> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/RecordOption/GetRecordOtherAccumulatedDepreciationOpeningEntry";
    return this.http.post(url, saveRecord, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }
}
