export class FormulaPermission {
    canAdd: boolean
    canReplace: boolean
    canDelete: boolean
    canClear: boolean
    canAddLeftParenthesis: boolean
    canAddRightParenthesis: boolean

    constructor() {
        this.canAdd = false;
        this.canReplace = false;
        this.canAddLeftParenthesis = true;
        this.canAddRightParenthesis = true;
        this.canClear = true;
        this.canDelete = false;
    }
}