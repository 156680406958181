<div class="testingFinacingRecord">
    <div class="col-sm-12 displaycollection hide" style="height: 25px;margin: 5px 0 2px 1px;">
        <div class="depreciationdestination col-sm-5" *ngIf="recordCollectionList!=null && recordCollectionList.length>0">
            <label class="col-sm-5">{{recordCollectionList[0].name}}</label>
            <input type="text" class="col-sm-4 form-control" style="text-align: right; height: 25px;"
                (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                (blur)="finacingRecordValuesChanges($event,recordCollectionList)"
                value="{{recordCollectionList[0].value}}" />
        </div>
    </div>
</div>

