import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Treeview } from "./treeview";
import { environment } from "../../environments/environment";
import { Record } from '../model/record';
import { DetailModel } from '../common/detail/detailModel/detail-model';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ok } from 'assert';
import { data } from 'jquery';
import { ForecastBudgetModel } from '../model/forecast-budget-model';

@Injectable({
  providedIn: 'root'
})
export class TreeviewService {
  private headers: Headers;
  Treeviews: Treeview[] = [];
  id: number;
  @Output() aClickedEvent = new EventEmitter<string>();
  constructor(private http: HttpClient, private SpinnerService:NgxSpinnerService) {
  }

  AClicked(msg: string) {
    this.aClickedEvent.emit(msg);
  }
  getAllTreeMenu(): Observable<Treeview[]> {
    let id = '';
    console.log("calling forecast" + localStorage.getItem('ForecastId'));
    if (localStorage.getItem('ForecastId') != null) {
      let id = +localStorage.getItem('ForecastId');
      return this.http.get(environment.url + "/api/forecast/GetForecast/" + id, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            return response;
          }
        ));
    } else {
      return this.http.get(environment.url + "/api/forecast/GetForecast/" + id, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            return response;
          }
        ));
    }
  }
  getTreeView(sectionNodeID): Observable<Treeview[]> {

    return this.http.get(environment.url + "/api/forecast/GetSection/" + sectionNodeID, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));

  }
  getFormulaTreeMenu(valuesNodeId): Observable<Treeview[]> {
    let id = +localStorage.getItem('ForecastId');
    return this.http.get(environment.url + "/api/Formula/DisplayFormulaTree/" + id + "/" + valuesNodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }

  getValidParents(NodeId): Observable<Treeview[]> {
    return this.http.get(environment.url + "/api/GetValidParents/getTreeValidParent/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }

  putMoveRecordTo(parentNode, currentNode) {
    return this.http.put(environment.url + "/api/forecast/MoveRecordTo/" + parentNode + "/" + currentNode, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));

  }

  MoveUpDownRecord(parentNode, OldIndex, NewIndex) {
    return this.http.get(environment.url + "/api/ContentMenu/MoveUpDown/" + parentNode + "/" + OldIndex + "/" + NewIndex, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));

  }
  getSelectedTreeMenu(selectedNodeId, derivationName, derivationType): Observable<Treeview[]> {
    let id = '';
    if (localStorage.getItem('ForecastId') != null) { 
      let id = +localStorage.getItem('ForecastId');
      return this.http.get(environment.url + "/api/forecast/" + id + "/" + derivationName + "/" + derivationType + "/children?arr[]=" + selectedNodeId, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            this.SpinnerService.hide();
            return response;
           

          }
        ));
    } else {
      return this.http.get(environment.url + "/api/forecast/" + id + "/" + derivationName + "/" + derivationType + "/children?arr[]=" + selectedNodeId, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            this.SpinnerService.hide();
            return response;
          }
        ));
    }
  }

  async getSelectedObservalbeTreeMenu(selectedNodeId, derivationName, derivationType): Promise<Observable<Treeview[]>> {
    let id = '';
    if (localStorage.getItem('ForecastId') != null) { 
      let id = +localStorage.getItem('ForecastId');
      this.SpinnerService.show();
      return await this.http.get(environment.url + "/api/forecast/" + id + "/" + derivationName + "/" + derivationType + "/children?arr[]=" + selectedNodeId, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            this.SpinnerService.hide();
            return response;
          }
        ));
    } else {
      this.SpinnerService.show();
      return await this.http.get(environment.url + "/api/forecast/" + id + "/" + derivationName + "/" + derivationType + "/children?arr[]=" + selectedNodeId, { responseType: "json" })
        .pipe(map(
          (response: Treeview[]) => {
            this.SpinnerService.hide();
            return response;
          }
        ));
    }
  }

  /**
   * Function For get record-detais by nodeId
   */
  getRecordDetail(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/record/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }
  getNodeDetail(nodeId): Observable<Record[]> {
    return this.http.get(environment.url + "/api/forecast/nodedetails/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Treeview[]) => {
          return response;
        }
      ));
  }
  /**
   * Function for get Budgetdata by recordID
   */
  getBudgetValueList(nodeId): Observable<Record[]> {
    let displayvalue = [];
    let total = 0;
    return this.http.get(environment.url + "/api/BudgetData/DisplayBudgetData/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          for (let i = 0; i < response.length; i++) {

            for (let j = 0; j < response[i].values.length; j++) {
              total += response[i].values[0];
            }
            displayvalue.push(total);
          }

          return [response, displayvalue];
        }
      ));
  }


  getAllDisplayBudgetData(nodeId): Observable<any> { 
    return this.http.get(environment.url + "/api/BudgetData/DisplayAllBudgetData/" + nodeId, { responseType: "json" })
      .pipe(map(
        ( response: any) => { 
            return response;
        }
      ));
  }

  async getObservableAllDisplayBudgetData(nodeId): Promise<Observable<ForecastBudgetModel>> { 
    return await this.http.get(environment.url + "/api/BudgetData/DisplayAllBudgetData/" + nodeId, { responseType: "json" })
      .pipe(map(
        ( response: ForecastBudgetModel) => { 
            return response;
        }
      ));
  }

  /**
   * Function for get forecast details based on Forecast Id
   */
  getforecastDetails(forcastId): Observable<Record[]> {
    let dateset = '';
    return this.http.get(environment.url + "/api/forecast/forecast/details/" + forcastId, { responseType: "json" })
      .pipe(map(
        (data: any) => {
          return data;
        }
      ));
  }

  /**
   * Function for add new record based on active node
   * 
   */
  addNewSampleRecord(activedata): Observable<Record[]> {
    let data = activedata;
    return this.http.post(environment.url + "/api/forecast/add-record/" + activedata, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }

  /**
   * APi Function for add-update value list data
   * 
   */

  changeBudgetdata(Budgetdata): Observable<Record[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/BudgetData/SetValueFromDisplayDBD", Budgetdata, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          localStorage.setItem('budgetdata', '');
          return response;
        }
      ))
  }

  /**
   * Api Function for get data when change record type
   * @return response
   */

  changeRecordTypeData(valueNodeId, RecordTypeId): Observable<DetailModel[]> {
    let url = environment.url + "/api/BudgetData/ChangeRecordtype/" + valueNodeId + "/" + RecordTypeId;
    return this.http.get(environment.url + "/api/BudgetData/ChangeRecordtype/" + valueNodeId + "/" + RecordTypeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
   * APi Function for Get EnterOpening Debotr NodeId
   */
  GetEnterOpeingDebtorsNodeId(ForecastnodeId): Observable<DetailModel[]> {
    // let ForecastnodeId = localStorage.getItem("ForecastId");
    if (ForecastnodeId != null) {
      let url = environment.url + "/api/Forecast/EnterOpeningDebtorsNode/" + ForecastnodeId;
      return this.http.get(url, { responseType: "json" })
        .pipe(map(
          (response: any) => {
            return response;
          }
        ));
    }
  }

  /**
 * APi Function for Get EnterOpening Debotr NodeId
 */
  GetEnterOpeingCreditorsNodeId(ForecastnodeId): Observable<DetailModel[]> {
    //let ForecastnodeId = localStorage.getItem("ForecastId");
    if (ForecastnodeId != null) {
      let url = environment.url + "/api/Forecast/EnterOpeningCreditorNode/" + ForecastnodeId;
      return this.http.get(url, { responseType: "json" })
        .pipe(map(
          (response: any) => {
            return response;
          }
        ));
    }
  }

  changePecentageValue(nodeId, value): Observable<any> {
    //let ForecastnodeId = localStorage.getItem("ForecastId");
    let url = environment.url + "/api/BudgetData/Percentages/" + nodeId + "/" + value;
    return this.http.get(url, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }




}

