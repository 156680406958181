import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ContractRetentionsService {

  private headers: Headers;
  constructor( private http: HttpClient) { }

   /**
    * Function For get Contract Retentions collection
    * @param ForecastNodeId 
    * @returns 
    */
    getContractRetentionCollections(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ForecastSetting/ForecastContractRetension/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }


    /**
    * Function For update Name collection
    * @param ForecastNodeId 
    * @returns 
    */
    updateName(NodeId,Value) : Observable<any[]>{
      return this.http.get(environment.url+"/api/Banding/SetName/"+NodeId+"/"+Value, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
    /**
    * Function For Add contract retentions
    * @param NodeId 
    * @returns 
    */
    addContractRetentionsService(NodeId, Index) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ForecastSetting/AddContractRetention/"+NodeId+"/"+Index, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
    /**
    * Function For delete Banding
    * @param NodeId 
    * @returns 
    */
    deleteContractRetentionsService(NodeId, Index) : Observable<any[]>{
      return this.http.delete(environment.url+"/api/ForecastSetting/DeleteContractRetention/"+NodeId+"/"+Index, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

    /**
    * Function For Add Banding
    * @param NodeId 
    * @returns 
    */
    addBandingService(NodeId, Index) : Observable<any[]>{
      return this.http.get(environment.url+"/api/Banding/addBindning/"+NodeId+"/"+Index, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
       /**
    * Function For delete Banding
    * @param NodeId 
    * @returns 
    */
    deleteBandingService(NodeId, Index) : Observable<any[]>{
      return this.http.delete(environment.url+"/api/Banding/deleteBinding/"+NodeId+"/"+Index, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

    /**
    * Function For Add Amount
    * @param NodeId 
    * @returns int Index
    */
    addAmountService(NodeId, Value) : Observable<any[]>{
      return this.http.put(environment.url+"/api/Banding/SetAmount/"+NodeId+"/"+Value, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
       /**
    * Function For Add percentage
    * @param NodeId 
    * @returns int Index
    */
    addPercentageService(NodeId, Value) : Observable<any[]>{
      return this.http.put(environment.url+"/api/Banding/SetPercentage/"+NodeId+"/"+Value, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
}
