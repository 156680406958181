import { Component, OnInit, Input } from '@angular/core';
import { DetailservicesService } from '../../details-services/detailservices.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-forecast-view-details',
  templateUrl: './forecast-view-details.component.html',
  styleUrls: ['./forecast-view-details.component.scss']
})
export class ForecastViewDetailsComponent implements OnInit {

  @Input() event;
  ForecastStatus: any

  constructor(private DetailservicesServices: DetailservicesService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    // console.log(this.event);
    this.ForecastStatus = {
      Editable: 0,
      BudgetEntry: 1,
      Closed: 2,
    }
    // console.log(this.ForecastStatus)
  }

  UpdateAPI(Id, UpdateDetails) {
    this.SpinnerService.show();
    this.DetailservicesServices.UpdateForecastRecordDetails(Id, UpdateDetails).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        //console.log(response);
      }
    )
  }
  clickgetdata(Id, $event) {
    if($event.target.value =='' && Id==undefined && Id==''){
      return;
    }
    let UpdateDetails = {
      Name: $event.target.value
    }
    let TreeViews = localStorage.getItem("treeview");
    if(TreeViews!=null){
      let parseTree = JSON.parse(TreeViews);
      let UpdatedTreeView = this.UpdateLocalStorage(parseTree, UpdateDetails.Name, Id);
      localStorage.setItem("treeview", JSON.stringify(UpdatedTreeView));
    }
    document.getElementById(Id + "-treemenu").innerText = $event.target.value;
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateLocalStorage(node, Name, ID) {
    if (node.nodeId == ID) {
        node.name = Name;
    }
    if (node.children != null && node.children.length > 0) {
      if (node.children != null && node.children.length > 0) {
        node.children.forEach(element => {
          this.UpdateLocalStorage(element,Name, ID)
        });
      }
    }
    return node;
  }
  DescriptionBulrEvent(Id, $event) {
    let UpdateDetails = {
      Description: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateTag(Id, $event) {
    let UpdateDetails = {
      Tag: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }

  UpdateAdditionalTag(Id, $event) {
    let UpdateDetails = {
      AdditionalTag: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }

}
