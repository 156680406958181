import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms'
import { MethodserviceService } from '../../component/record/method/service/methodservice.service';
import { DetailservicesService } from '../detail/details-services/detailservices.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import { ForecasttabsComponent } from '../forecasttabs/forecasttabs.component';
import { IgxExpansionPanelComponent } from '@infragistics/igniteui-angular';
import { Console } from 'console';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  @Input() event;

  CountryText: any = "";
  @Output() recordTypeClicked = new EventEmitter<Event>();
  @ViewChild(IgxExpansionPanelComponent, { read: IgxExpansionPanelComponent, static: true })
  public panel: IgxExpansionPanelComponent;
  public imgSource = 'https://i.ibb.co/6ZdY7cn/Untitled-design-3.png';
  public readMore = 'https://en.wikipedia.org/wiki/Golden_Retriever';
  allEnumValues: any;
  AddEnumvalueInList = [];
  addEnumvalues: any;
  validnodeType: any;
  validnodeTypeLocalize: any;
  detailSection = new UntypedFormGroup({});
  nodeType: string;
  currency: string;
  ShowWhatIf: boolean;
  isConsolidation: boolean;
  DCFrequency: any[];
  UpdateDetails = {
    Name: "",
    Description: "",
    DepartmentTag: "",
    PLInterestGLCode: "",
    PLDepnGLCode: "",
    AccumDepGLCode: "",
    Currency: 0,
    SalesTaxRate: 0,
    RecordCode01: "",
    RecordCode02: "",
    RecordCode03: "",
    FullGLCode: "",
    DayIndex: 0,
    WhatIfMultiplier: "",
    Frequency: 0
  }
  ngOnInit() {
    let countryName = localStorage.getItem("CountryName");
    this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
    if (countryName == "GB") {
      this.CountryText = "VAT";
    } else {
      if (countryName == "NZ") {
        this.CountryText = "GST";
      }
    }
  }
  constructor(private methodservice: MethodserviceService,
    private DetailservicesServices: DetailservicesService,
    private SpinnerService: NgxSpinnerService,
    private ForecasttabsComponent: ForecasttabsComponent) {
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async ngOnChanges() {
    if (this.event != '') {
      console.log(this.event);
      this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
      let countryName = localStorage.getItem("CountryName");
      if (countryName == "GB") {
        this.CountryText = "VAT";
      } else {
        if (countryName == "NZ") {
          this.CountryText = "GST";
        }
      }
      if (this.event.validnodeTypes != undefined) {
        this.event.validnodeTypes.forEach(element => {
          if (element['nodetype'] == this.event.nodeType)
            this.nodeType = element['name'];
        });
      }

      if (this.event.currencyCollectionResult != '' && this.event.currencyCollectionResult != null) {
        this.event.currencyCollectionResult.forEach(ele => {
          if (ele['nodeId'] == this.event.currencyNodeId)
            this.currency = ele['name'];
        });
      }

      this.SpinnerService.show();
      this.DetailservicesServices.getDCFrequency().subscribe(
        (response => {
          this.SpinnerService.hide();
          this.DCFrequency = response;
          console.log("DCFrequency", this.DCFrequency);
        })
      )

      this.UpdateDetails.Name = this.event.name;
      this.UpdateDetails.Description = this.event.description;
      this.UpdateDetails.FullGLCode = this.event.fullGLCode;
      this.UpdateDetails.RecordCode01 = this.event.recordCode01;
      this.UpdateDetails.RecordCode02 = this.event.recordCode02;
      this.UpdateDetails.RecordCode03 = this.event.recordCode03;
      this.UpdateDetails.DepartmentTag = this.event.departmentTag;
      this.UpdateDetails.WhatIfMultiplier = this.event.whatIfMultiplier;
      this.UpdateDetails.DayIndex = this.event.dayIndex;
      this.UpdateDetails.PLDepnGLCode = this.event.plDepnGLCode;
      this.UpdateDetails.PLInterestGLCode = this.event.plInterestGLCode;
      this.UpdateDetails.AccumDepGLCode = this.event.accumDepnGLCode;
      this.UpdateDetails.Frequency = this.event.dcFrequency;

    }
  }

  changeBanktype($event) {
    let value = parseInt($event);
    if (value != null) {
      this.SpinnerService.show();
      this.DetailservicesServices.UpdateForecastBankDetails(value, this.event.nodeId).subscribe(
        (response => {
          this.SpinnerService.hide();
          this.ForecasttabsComponent.childEventClicked(this.event.nodeId);
        })
      )
    }

  }
  changeRecordtype($event) {
    this.recordTypeClicked.emit($event);
  }
  clickgetdata(Id, $event) {
    if ($event.target.value == '' && Id == undefined && Id == '') {
      return;
    }
    // let UpdateDetails = {
    //   Name: $event.target.value
    // }
    this.UpdateDetails.Name = $event.target.value;
    let TreeViews = localStorage.getItem("treeview");
    if (TreeViews != null) {
      let parseTree = JSON.parse(TreeViews);
      let UpdatedTreeView = this.UpdateLocalStorage(parseTree, this.UpdateDetails.Name, Id);
      localStorage.setItem("treeview", JSON.stringify(UpdatedTreeView));
    }
    document.getElementById(Id + "-treemenu").innerText = $event.target.value;
    this.UpdateAPI(Id, this.UpdateDetails);
  }

  UpdateLocalStorage(node, Name, ID) {
    if (node.nodeId == ID) {
      node.name = Name;
    }
    if (node.children != null && node.children.length > 0) {
      if (node.children != null && node.children.length > 0) {
        node.children.forEach(element => {
          this.UpdateLocalStorage(element, Name, ID)
        });
      }
    }
    return node;
  }
  changeClickgetdata() {

  }
  FrequencyUpdate(Id, $event) {
    this.UpdateDetails.Frequency = $event.target.value;
    console.log("FrequencyUpdate", this.UpdateDetails.Frequency);
    this.UpdateAPI(Id, this.UpdateDetails);
  }
  DescriptionBulrEvent(Id, $event) {
    this.UpdateDetails.Description = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }

  DepartmentTag(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.DepartmentTag = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }

  UpdatePLInterestGLCode(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.PLInterestGLCode = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdatePLDepnGLCode(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.PLDepnGLCode = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateAccumDepGLCode(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.AccumDepGLCode = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateCurrency(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.Currency = parseInt($event.target.value);

    this.UpdateAPI(Id, this.UpdateDetails);
  }

  UpdateGSt(Id, $event) {
    if ($event.target.value == '') {
      return;
    }
    this.UpdateDetails.SalesTaxRate = parseInt($event.target.value);

    this.UpdateAPI(Id, this.UpdateDetails);

  }
  UpdateRecord01Name(Id, $event) {

    this.UpdateDetails.RecordCode01 = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateRecord02Name(Id, $event) {
    this.UpdateDetails.RecordCode02 = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateRecord03Name(Id, $event) {
    this.UpdateDetails.RecordCode03 = $event.target.value;


    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateDepartmentTag(Id, $event) {
    this.UpdateDetails.DepartmentTag = $event.target.value;

    this.UpdateAPI(Id, this.UpdateDetails);
  }

  UpdateFullGLCode(Id, $event) {
    this.UpdateDetails.FullGLCode = $event.target.value;


    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateDayIndex(Id, $event) {
    this.UpdateDetails.DayIndex = parseInt($event.target.value);

    this.UpdateAPI(Id, this.UpdateDetails);
  }

  UpdateWhatIfMultiplier(Id, $event) {
    this.UpdateDetails.WhatIfMultiplier = parseFloat($event.target.value).toFixed(2);

    this.UpdateAPI(Id, this.UpdateDetails);
  }
  UpdateAPI(Id, UpdateDetails) {
    console.log(UpdateDetails, Id);
    this.SpinnerService.show();
    this.DetailservicesServices.UpdateForecastRecordDetails(Id, UpdateDetails).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
        if (this.ShowWhatIf) {
          this.ForecasttabsComponent.childEventClicked(Id);
        }
        if (UpdateDetails.SalesTaxRate != undefined) {
          this.ForecasttabsComponent.childEventClicked(Id);
        }

      }
    )
  }

}
