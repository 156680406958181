<div *ngIf="event" style="margin: 0 0 0 0;">
  <igx-content>
    <div *ngIf="event.nodeTypeCategory == 30000">
      <app-section-view-details [event]="event"></app-section-view-details>
    </div>
    <div *ngIf="event.nodeTypeCategory==40000">
      <app-folder-view-details [event]="event"></app-folder-view-details>
    </div>
    <div *ngIf="event.nodeTypeCategory==10000">
      <app-forecast-view-details [event]="event"></app-forecast-view-details>
    </div>
    <div *ngIf="event.nodeTypeCategory==20000">
      <app-department-view-details [event]="event"></app-department-view-details>
    </div>
    <div *ngIf="event.nodeTypeCategory == 50000">
      <form [formGroup]="detailSection" class="detailsRecord">
        <div class="row" style="height: auto;">
          <div class="col-sm-4">
            <div class="row">
              <label for="name" class="col-sm-2 col-form-label">
                Name
              </label>
              <div class="col-sm-8">
                <input id="name" class="form-control " (blur)="clickgetdata(event.nodeId , $event )" type="text"
                  [disabled]="isConsolidation" value="{{event.name}}">
              </div>
            </div>
            <div class="row" style="margin-top: 1%;">
              <label for="name" class="col-sm-2 col-form-label">
                Description
              </label>
              <div class="col-sm-8">
                <textarea id="Description" class="form-control" type="text"
                  (blur)="DescriptionBulrEvent(event.nodeId , $event )" [disabled]="isConsolidation"
                  value=" {{ event.description}}"></textarea>
              </div>
            </div>
          </div>
          <div class="col-sm-3" style="padding: 0 0 0 0px; margin-left: -50px;" *ngIf="event.nodeType!='50033'">
            <div class="row recordDetailsRow">
              <label for="name" class="col-sm-3 col-form-label " style="padding: 0px;">
                Record Type
              </label>
              <div class="col-sm-8">
                <div *ngIf="event.validnodeTypes!=''">
                  <select name="recordtype" (change)="changeRecordtype($event.target.value)"
                    [disabled]="!event.isEnabledNodeType && !isConsolidation" class="form-control width70"
                    form="carform">
                    <option *ngFor="let recordtypes of event.validnodeTypes let i= index;"
                      [selected]="recordtypes.nodetype === event.selectednodeType" value="{{recordtypes.nodetype}}">
                      {{recordtypes.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row recordDetailsRow"
              *ngIf="event.nodeType!='50022' || event.nodeType!='51300' || event.nodeType!='50003'">
              <label for="name" class="col-sm-3 col-form-label" style="padding: 0px;">
                Bank
              </label>
              <div class="col-sm-8">
                <select name="" class="form-control width70" [disabled]="!event.isEnabledBank"
                  (change)="changeBanktype($event.target.value)">
                  <option *ngFor="let bankresult of event.bankVm" [selected]="bankresult.nodeId == event.bankNodeId"
                    value="{{bankresult.nodeId}}">{{bankresult.name}}</option>
                </select>
              </div>
            </div>

            <div class="row recordDetailsRow" *ngIf="event.nodeType!='50022'">
              <label for="name" class="col-sm-3 col-form-label" style="padding: 0px;">
                Currency <span *ngIf="event.isEnabledGST!=''">/ {{CountryText}}</span>
              </label>
              <div class="col-sm-4" style="margin: 0 -10px 0 0px;">
                <select name="" class="form-control width70" [disabled]="!event.isEnabledCurrency"
                  (change)="UpdateCurrency(event.nodeId , $event )">
                  <option *ngFor="let currency of event.currencyCollectionResult; let i = index"
                    [selected]="currency.nodeId == event.currencyNodeId" value="{{currency.nodeId}}">{{currency.value}}
                  </option>
                </select>
              </div>
              <div class="col-sm-4" *ngIf="event.isEnabledGST" style="padding: 0px;"
                (change)="UpdateGSt(event.nodeId , $event )">
                <select name="SalesTaxRatesResults" class="form-control width97" id="GSTRates" form="GSTRateform">
                  <option *ngFor="let taxresult of event.salesTaxRatesResult; let i=index"
                    [selected]="taxresult.nodeId == event.salesTaxRateNodeId" value="{{taxresult.nodeId}}">
                    {{taxresult.name}}</option>
                </select>
              </div>
            </div>


            <div class="row recordDetailsRow" *ngIf="event.isEnabledRecordCodes">
              <label for="name" class="col-sm-3 col-form-label" style="padding: 0px;">
                Full GL Code
              </label>
              <div class="col-sm-8">
                <input type="text" class="form-control" (blur)="UpdateFullGLCode(event.nodeId , $event )"
                  value="{{event.fullGLCode}}">
              </div>
            </div>

          </div>
          <div class="col-sm-2" style="margin: 0% -6px 0 0px;"
            *ngIf="event.isEnabledRecordCodes && event.nodeType!='50033'">
            <div class="row recordDetailsRow">
              <label for="name" class="col-sm-6 col-form-label" style="margin: 0px;padding: 0px !important;">
                {{event.recordCode01Name}}
              </label>
              <div class="col-sm-6" *ngIf="event.recordCode01Name" style="padding: 0px;">
                <input type="text" (blur)="UpdateRecord01Name(event.nodeId , $event )" class="form-control"
                  value="{{event.recordCode01}}">
              </div>
            </div>
            <div class="row recordDetailsRow">
              <label for="name" class="col-sm-6 col-form-label" style="margin: 0px;padding: 0px !important;">
                {{event.recordCode02Name}}
              </label>
              <div class="col-sm-6" style="padding: 0px;" *ngIf="event.recordCode02Name">
                <input type="text" class="form-control width70" (blur)="UpdateRecord02Name(event.nodeId , $event )"
                  value="{{event.recordCode02}}">
              </div>
            </div>

            <div class="row recordDetailsRow" *ngIf="event.isEnabledRecordCodes">
              <label for="name" class="col-sm-6 col-form-label" style="margin: 0px;padding: 0px !important;">
                {{event.recordCode03Name}}
              </label>
              <div class="col-sm-6" *ngIf="event.recordCode03Name" style="padding: 0px;">
                <input type="text" class="form-control" (blur)="UpdateRecord03Name(event.nodeId , $event )"
                  value="{{event.recordCode03}}">
              </div>
            </div>
            <div class="row recordDetailsRow" style="padding: 0px;">
              <label for="name" class="col-sm-6 col-form-label" style="margin: 0px;padding: 0px !important;">
                Department Code
              </label>
              <div class="col-sm-6" style="padding: 0px;">
                <input type="text" class="form-control" (blur)="DepartmentTag(event.nodeId , $event )"
                  value="{{event.departmentTag}}">
              </div>
            </div>

          </div>
          <div class="col-sm-3" style="margin-top: 0%;"
            [ngClass]="{'WhatIfMultipliterActive': ShowWhatIf==true , 'WhatIfMultipliterInActive': ShowWhatIf!=true }"
            *ngIf="!isConsolidation">

            <div *ngIf="(event.isEnabledWhatIfMultiplier) && event.nodeType!='50033' && !isConsolidation">
              <div class="row recordDetailsRow" *ngIf="event.isEnabledWhatIfMultiplier">
                <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                  What If Multiplier
                </label>
                <div class="col-sm-6" *ngIf="event.isEnabledWhatIfMultiplier">
                  <input type="text" class="form-control" (blur)="UpdateWhatIfMultiplier(event.nodeId , $event )"
                    value="{{event.whatIfMultiplier}}">
                </div>
              </div>
              <div class="row recordDetailsRow" *ngIf="event.isEnabledWhatIfMultiplier">
                <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                  Total WI Multiplier
                </label>
                <div class="col-sm-6" *ngIf="event.isEnabledWhatIfMultiplier">
                  <input type="text" disabled=disabled class="form-control" value="{{event.totalWhatIfMultiplier}}">
                </div>
              </div>
            </div>
            <div class="" *ngIf="( event.canHaveDailyCashflows) && event.nodeType!='50033' && !isConsolidation">
              <div class="row recordDetailsRow">
                <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                  Day Index
                </label>
                <div class="col-sm-6" style="padding: 0px;margin: 0 0 0 18px;">
                  <input type="text" class="form-control" (blur)="UpdateDayIndex(event.nodeId , $event )"
                    value="{{event.dayIndex}}">
                </div>
              </div>

              <div class="row recordDetailsRow" *ngIf="event.canHaveDailyCashflows">
                <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                  Frequency
                </label>
                <div class="col-sm-7" *ngIf="event.canHaveDailyCashflows">
                  <select name="DailyCashflows" id="DailyCashflows" class="form-control" form="carform"
                    style="margin: 0 0 0 6px;" (blur)="FrequencyUpdate(event.nodeId , $event )">
                    <option *ngFor="let frequency of DCFrequency; let i=index"
                      [selected]="frequency.value == event.dcFrequency" value="{{frequency.value}}">
                      {{frequency.name}}</option>
                    <!-- <option value="Weekly">Weekly </option>
                    <option value="Fortnight">Fortnight</option>
                    <option value="Bi Monthly">Bi Monthly </option>
                    <option value="Monthly">Monthly</option> -->
                  </select>
                </div>
              </div>
            </div>

            <div class="row recordDetailsRow" *ngIf="event.visibilityPLDepnGLCode">
              <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                P&L Depn GL Code
              </label>
              <div class="col-sm-6" *ngIf="event.visibilityPLDepnGLCode" style="margin: 0px 0px 0px 4px;">
                <input type="text" (blur)="UpdatePLDepnGLCode(event.nodeId , $event )" class="form-control"
                  value="{{event.plDepnGLCode}}">
              </div>
            </div>
            <div class="row recordDetailsRow" *ngIf="event.visibilityPLInterestGLCode">
              <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                P&L Interest GL Code
              </label>
              <div class="col-sm-6" style="margin: 0px 0px 0px 4px;" *ngIf="event.visibilityPLInterestGLCode">
                <input type="text" class="form-control width70" (blur)="UpdatePLInterestGLCode(event.nodeId , $event )"
                  value="{{event.plInterestGLCode}}">
              </div>
            </div>
            <div class="row recordDetailsRow" *ngIf="event.visibilityPLDepnGLCode">
              <label for="name" class="col-sm-5 col-form-label" style="margin: 0px;padding: 0px !important;">
                Accum Depn GL Code
              </label>
              <div class="col-sm-6" style="margin: 0px 0px 0px 4px;" *ngIf="event.visibilityPLDepnGLCode">
                <input type="text" class="form-control width70" (blur)="UpdateAccumDepGLCode(event.nodeId , $event )"
                  value="{{event.accumDepnGLCode}}">
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  </igx-content>

</div>