
<section class="container">
    <div class="flex-col items-center justify-center">
      
      <div class="flex min-h-screen flex-col md:flex-row items-center justify-center p-5"  >
        <div class="p-4 w-full md:w-1/2 flex flex-col justify-right items-center" style="margin: -3% 0 0 0px;padding: 72px !important;">
          <img src="../assets/logo.png" alt="logo" class="min-w-200" />
          <h2 class="text-3xl md:text-3xl text-center mt-2 md:text-right">
            Trust in your numbers.
          </h2>
        </div>
        <div class="p-3 w-full md:w-1/2 flex justify-left items-center" style="width: 37% !important;">
         
          <form [formGroup]="loginForm" class="w-full loginForm" (ngSubmit)="onSubmit()">
            <div class="loginInputGroup">
              <igx-input-group theme="fluent" type="line" class="">
                <igx-prefix>
                    <igx-icon class="IconOkButtonColor">person</igx-icon>
                </igx-prefix>
                <input
                  igxInput
                  formControlName="email"
                  id="email"
                  type="text"
                  name="email"
                  required="required"
                  placeholder="Username"
                  class="w-full inline-block border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent rounded-sm p-2 mb-2"
                />
              </igx-input-group>
            </div>
            <div class="loginInputGroup">
              <igx-input-group theme="fluent" type="line">
                <igx-prefix>
                    <igx-icon class="IconOkButtonColor">lock</igx-icon>
                </igx-prefix>
                <input
                  igxInput
                  formControlName="password"
                  id="password"
                  type="password"
                  name="password"
                  required="required"
                  placeholder="Password"
                  class="border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent rounded-sm w-full p-2"
                />
              </igx-input-group>
            </div>
            <div class="mt-2">
              <input
                type="submit"
                class="bg-blue-500 text-white font-bold py-2 px-4 rounded w-full cursor-pointer"
                value="Log in"
              />
            </div>
            <div class="loginSubmit">
              <div *ngIf="loginError" style="color: red; padding: 10px;text-align: center;">Please Enter Valid
                UserName and Password</div>
              <div class="">
                <div class="">
                  <label
                    class="mt-2 gray-200 text-sm underline cursor-pointer"
                    (click)="ForgotPassword.open()"
                    >Forgot password</label
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="flex justify-center mt-4" style="margin: -15% 0 0 0 !important;">
        <button class="text-sm rounded mr-2">Website</button>
        <button class="text-sm rounded mr-2">Help Center</button>
        <button class="text-sm rounded">Contact Us</button>
      </div>
    </div>
  </section>
  
  <ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="default"
    type="ball-spin-clockwise"
  >
    <p class="text-white">Please Wait.</p>
  </ngx-spinner>
  
  <igx-dialog #ForgotPassword [closeOnOutsideSelect]="false" class="hidden">
    <div class="login-container forgotpassword-header">
      <label class="text-lg text-orange-500">Forgot Password</label>
      <div class="loginInputGroup">
        <app-forgot-password
          [forgotpassworddialog]="ForgotPassword"
        ></app-forgot-password>
      </div>
    </div>
  </igx-dialog>

   


  <!-- <div class="sample-column">
    <igx-navbar [isActionButtonVisible]="true">
        <div igxNavbarTitle>
            <a href="">
                <img src="../../../assets/FullBrandLogowebsite.png" width="120px" height="50px" alt
                    style="margin-top: 7px;">
            </a>
        </div>
    </igx-navbar>
</div>
<div style="align-items: center;display: flex;justify-content: center;width:100%">
    <div class="login">
        <div class="login-container login-header">
            <div class="">
                <div class="centerAlignText">
                    <h2>Welcome</h2>
                </div>
                <div *ngIf="loginError" style="color: red; padding: 10px;text-align: center;">Please Enter Valid
                    UserName and Password</div>
                <form [formGroup]="loginForm" class="loginForm" (ngSubmit)="onSubmit()">
                    <div class="loginInputGroup">
                        <igx-input-group theme="fluent" type="line">
                            <igx-prefix>
                                <igx-icon class="IconOkButtonColor">person</igx-icon>
                            </igx-prefix>
                            <label igxLabel class="igxlabellogin" for="email">User Name</label>
                            <input igxInput formControlName="email" id="email" type="text" name="email"
                                required="required" />
                        </igx-input-group>
                    </div>
                    <div class="loginInputGroup">
                        <igx-input-group theme="fluent" type="line">
                            <igx-prefix>
                                <igx-icon class="IconOkButtonColor">lock</igx-icon>
                            </igx-prefix>
                            <label igxLabel class="igxlabellogin" for="password">Password</label>
                            <input igxInput formControlName="password" id="password" type="password" name="password"
                                required="required" />
                        </igx-input-group>
                    </div>
                    <div class="loginSubmit">
                        <input type="submit" class="OkSaveButtonColor" style="background-color: #1e90ff;"
                            value="Log in">
                    </div>
                    <div class="loginSubmit">
                        <div class="">
                            <div class="">
                                <label class="ForgotPassword"
                                    style="font-size: 15px;margin: 4px 0 0 20px; cursor: pointer;"
                                    (click)="ForgotPassword.open()">Forgot Password</label>
                                <igx-dialog #ForgotPassword [closeOnOutsideSelect]="false" class="forgotpassword">
                                    <div class="login-container forgotpassword-header">
                                        <label class="forgotpassword-label">Forgot Password</label>
                                        <div class="loginInputGroup">
                                            <app-forgot-password
                                                [forgotpassworddialog]="ForgotPassword"></app-forgot-password>
                                        </div>
                                    </div>
                                </igx-dialog>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner> -->
