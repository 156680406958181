import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserregistrationService } from 'src/app/service/userregistration.service';
@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {

  userID = "";
  isValidated = false;
  constructor(private routeParams: ActivatedRoute, private userregistrationService: UserregistrationService, private SpinnerService: NgxSpinnerService) {
    this.userID = routeParams.snapshot.params['userid'];
  }

  ngOnInit(): void {
    this.SpinnerService.show();
    if (this.userID != "") {
      this.userregistrationService.validateEmail(this.userID).subscribe(
        (response: boolean) => {
          if (response) {
            this.SpinnerService.hide();
            this.isValidated = true;
          }
        });
    }

  }

}
