<div>
    <div>
        <h3>Parameter Settings</h3>
    </div>
    <div class="col-sm-12" style="margin-top:1%; height: 92%;">
        <div class="bs-example" *ngIf="PeriodTabViewCollection.length>0">
            <ul class="nav nav-tabs">
                <li class="nav-item" style="margin: 0 8px -2px -1px;">
                    <select form="Payrool-payrate" class="form-control"
                        style="background: #1e90ff;color: white;font-size: 12px;font-weight: bold;height: 40px;">
                        <option value="Annual" ng-reflect-value="Annual">Annual</option>
                        <option value="All" ng-reflect-value="All">All</option>
                    </select>
                </li>
                <li class="nav-item" *ngFor="let result of PeriodTabViewCollection[0].results;  let i=index"
                    (click)="ChangePeriodType(result, i)">
                    <a href="#{{result.nodeId}}" class="nav-link" [ngClass]="{'active':i==ActivetabView}"
                        data-toggle="tab">{{result.longLabel}}</a>
                </li>
            </ul>

            <div class="currencyValueListArray col-sm-12" *ngIf="CurrencyValueList!=''">
                <div class="col-sm-11">
                    <div class="col-sm-3 ActiveBorder" *ngIf="ParameterRecieptsCollections!=''">
                        <div class="" style="padding: 10px;margin: 0 3px 7% 13px;">
                            <h3>Receipts</h3>
                        </div>
                        <div class="col">
                            <div style="padding: 10px;margin-bottom: 15%;">
                                <h4>Default Input Method:</h4>
                                <igx-select [(ngModel)]="ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM">
                                    <igx-select-item
                                        *ngFor="let results of ParameterRecieptsCollections.receipts_ValidDerivationTypesVM"
                                        [value]="results.value" (click)='receipts_ValidDerivationTypesVM(results)'>
                                        {{results.name}}
                                    </igx-select-item>
                                </igx-select>
                            </div>
                        </div>
                        <div class="col-sm-12" style="border-bottom: 2px solid #eaeeef;"
                            *ngIf="ParameterRecieptsValueList!=null">
                            <div class="col-sm-12 ValueArray"
                                *ngIf="ParameterRecieptsValueList.allowSetAllEntry == true">
                                <label class="col-sm-5" *ngIf="ParameterRecieptsValueList.allowSetAllEntry">Set
                                    All</label>
                                <input class="col-sm-5" style="text-align:end"
                                    (blur)="SetAllList(ParameterRecieptsValueList.valuesNodeId, $event,'Reciepts',ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM)"
                                    *ngIf="ParameterRecieptsValueList.allowSetAllEntry" />
                            </div>
                            <div *ngIf="ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM == '80039'">
                                <div
                                    *ngFor="let result of ParameterRecieptsValueList.valuesArray | slice:0:12; ; let i=index">
                                    <div class="col-sm-12 ValueMonthArray">
                                        <label class="col-sm-5">{{ParameterRecieptsValueList.paymentProfilePeriod[i]
                                            }}</label>
                                        <input type="text" class="col-sm-5"
                                            (blur)="MonthList(ParameterRecieptsValueList.valuesNodeId, i, $event,'Reciepts',ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM,ParameterRecieptsValueList)"
                                            value="{{result}}" style="text-align:end" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM != '80039'">
                                <div
                                    *ngFor="let result of ParameterRecieptsValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1; ; let i=index">
                                    <div class="col-sm-12 ValueMonthArray">
                                        <label
                                            class="col-sm-5 ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM">{{DisplayMonthCollection[i+PeriodStartIndex].name
                                            }}</label>
                                        <input type="text" class="col-sm-5"
                                            (blur)="MonthList(ParameterRecieptsValueList.valuesNodeId, i, $event,'Reciepts',ParameterRecieptsCollections.receipts_DefaultDerivationTypeVM,ParameterRecieptsValueList)"
                                            value="{{result}}" style="text-align:end" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-3 ActiveBorder">
                        <div class="" style="padding: 10px;margin-bottom: 7%;">
                            <h3>Payment</h3>
                        </div>
                        <div class="col" *ngIf="ParameterPaymentCollections!=''"
                            style="padding: 10px;margin-bottom: 15%;">
                            <h4>Default Input Method:</h4>
                            <igx-select [(ngModel)]="ParameterPaymentCollections.payments_DefaultDerivationTypeVM">
                                <igx-select-item
                                    *ngFor="let results of ParameterPaymentCollections.payments_ValidDerivationTypesVM"
                                    [value]="results.value" (click)='Payment_ValidDerivationTypesVM(results)'>
                                    {{results.name}}
                                </igx-select-item>
                            </igx-select>
                        </div>
                        <div class="col-sm-12" style="border-bottom: 2px solid #eaeeef;"
                            *ngIf="ParameterPaymentValueList!=null">
                            <div class="col-sm-12 ValueArray"
                                *ngIf="ParameterPaymentValueList.allowSetAllEntry == true">
                                <label class="col-sm-5" *ngIf="ParameterPaymentValueList.allowSetAllEntry">Set
                                    All</label>
                                <input class="col-sm-5" style="text-align:end"
                                    (blur)="SetAllList(ParameterPaymentValueList.valuesNodeId, $event,'Payment',ParameterPaymentCollections.payments_DefaultDerivationTypeVM)"
                                    *ngIf="ParameterPaymentValueList.allowSetAllEntry" />
                            </div>

                            <div *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM == '80039'">
                                <div
                                    *ngFor="let result of ParameterPaymentValueList.valuesArray | slice:0:12; ; let i=index">
                                    <div class="col-sm-12 ValueMonthArray {{i}}">
                                        <label
                                            *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM == '80039'"
                                            class="col-sm-5">{{ParameterPaymentValueList.paymentProfilePeriod[i]
                                            }}</label>
                                        <input type="text"
                                            *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM == '80039'"
                                            class="col-sm-5"
                                            (blur)="MonthList(ParameterPaymentValueList.valuesNodeId, i, $event,'Payment',ParameterPaymentCollections.payments_DefaultDerivationTypeVM,ParameterPaymentValueList)"
                                            value="{{result}}" style="text-align:end" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM != '80039'">
                                <div
                                    *ngFor="let result of ParameterPaymentValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1; ; let i=index">
                                    <div class="col-sm-12 ValueMonthArray {{i}}">
                                        <label
                                            *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM!='80039'"
                                            class="col-sm-5">{{DisplayMonthCollection[i+PeriodStartIndex].name
                                            }}</label>
                                        <input type="text"
                                            *ngIf="ParameterPaymentCollections.payments_DefaultDerivationTypeVM!='80039'"
                                            class="col-sm-5"
                                            (blur)="MonthList(ParameterPaymentValueList.valuesNodeId, i, $event,'Payment',ParameterPaymentCollections.payments_DefaultDerivationTypeVM,ParameterPaymentValueList)"
                                            value="{{result}}" style="text-align:end" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-3 ActiveBorder">

                        <div class="" style="padding: 10px;margin-bottom: 7%;">
                            <h3>Add To Stock</h3>
                        </div>
                        <div class="col" *ngIf="ParameterAddToStockCollections!=''"
                            style="padding: 10px;margin-bottom: 15%;">
                            <h4>Default Input Method:</h4>
                            <igx-select [(ngModel)]="ParameterAddToStockCollections.addToStock_DefaultDerivationTypeVM">
                                <igx-select-item
                                    *ngFor="let results of ParameterAddToStockCollections.addToStock_ValidDerivationTypesVM"
                                    [value]="results.value" (click)='addtostock_ValidDerivationTypesVM(results)'>
                                    {{results.name}}
                                </igx-select-item>
                            </igx-select>
                        </div>
                        <div class="col-sm-12" style="border-bottom: 2px solid #eaeeef;"
                            *ngIf="ParameterAddToStockCollections.parameterDefaultsAddToStockVM!=null">
                            <div class="col-sm-12 ValueArray"
                                *ngIf="ParameterAddToStockValueList.allowSetAllEntry == true">
                                <label class="col-sm-5" *ngIf="ParameterAddToStockValueList.allowSetAllEntry">Set
                                    All</label>
                                <input class="col-sm-5" style="text-align:end"
                                    (blur)="SetAllList(ParameterAddToStockValueList.valuesNodeId, $event,'AddToStock',ParameterAddToStockCollections.addToStock_DefaultDerivationTypeVM)"
                                    *ngIf="ParameterAddToStockValueList.allowSetAllEntry" />
                            </div>
                            <div
                                *ngFor="let result of ParameterAddToStockValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1;; let i=index">
                                <div class="col-sm-12 ValueMonthArray">
                                    <label class="col-sm-5">{{DisplayMonthCollection[i+PeriodStartIndex].name }}</label>

                                    <input type="text" class="col-sm-5"
                                        (blur)="MonthList(ParameterAddToStockValueList.valuesNodeId, i, $event,'AddToStock',ParameterAddToStockCollections.addToStock_DefaultDerivationTypeVM,ParameterAddToStockValueList)"
                                        value="{{result}}" style="text-align:end" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 ActiveBorder">

                        <div class="" style="padding: 10px;margin-bottom: 7%;">
                            <h3>Depreciation</h3>
                        </div>
                        <div class="col" *ngIf="ParameterDepreciationCollections!=''"
                            style="padding: 10px;margin-bottom: 3%;">
                            <h4>Default Input Method:</h4>
                            <igx-select
                                [(ngModel)]="ParameterDepreciationCollections.depreciation_DefaultDerivationTypeVM">
                                <igx-select-item
                                    *ngFor="let results of ParameterDepreciationCollections.depreciation_ValidDerivationTypesVM"
                                    [value]="results.value" (click)='depreciation_ValidDerivationTypesVM(results)'>
                                    {{results.name}}
                                </igx-select-item>
                            </igx-select>
                        </div>
                        <div class="col" *ngIf="ParameterDepreciationCollections!=''"
                            style="padding: 10px;margin-bottom: 7%;">
                            <h4>Default %</h4>
                            <input type="text" style="width: 42%" class="col"
                                (blur)="ParameterDepreciationDefualtPercentage($event)"
                                value="{{ParameterDepreciationCollections.depreciationPercent}}">
                        </div>
                        <div class="col" *ngIf="ParameterDepreciationCollections!=''"
                            style="padding: 10px;margin-bottom: 7%;">
                            <h4>Depreciation in:</h4>
                            <igx-select
                                [(ngModel)]="ParameterDepreciationCollections.defaultDepreciationDestinationsVM">
                                <igx-select-item
                                    *ngFor="let results of ParameterDepreciationCollections.validDepreciationDestinationsVM"
                                    [value]="results.nodeId"
                                    (click)='DepreciationDestination_ValidDerivationTypesVM(results)'>
                                    {{results.name}}
                                </igx-select-item>
                            </igx-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="button-sample row col" style="float: right;">
        <button class="btn OkSaveButtonColor col-sm-1" (click)="SettingSave()"
            style="font-size: 16px;margin: 0px 7px 0 0;">Save</button>
        <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingClose()"
            style="font-size: 16px;margin: 0px 7px 0 7px;">Close</button>

    </div>
</div>