import { Component, Input, OnInit } from '@angular/core';
import { MethodserviceService } from '../../../../component/record/method/service/methodservice.service';
import { DetailservicesService } from '../../details-services/detailservices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';

@Component({
  selector: 'app-section-view-details',
  templateUrl: './section-view-details.component.html',
  styleUrls: ['./section-view-details.component.scss']
})
export class SectionViewDetailsComponent implements OnInit {

  @Input() event;
  allEnumValues: any;
  AddEnumvalueInList = [];
  addEnumvalues: any;
  validnodeType: any;
  validnodeTypeLocalize: any;
  ShowWhatIf:boolean;

  constructor(private methodservice: MethodserviceService, 
    private ForecasttabsComponent:ForecasttabsComponent,
    public SpinnerService:NgxSpinnerService, private DetailservicesServices: DetailservicesService) { }

  async ngOnInit() {
    this.ngOnChange();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clickgetdata(Id, $event) {
   
    if($event.target.value =='' && Id!=null){
      return;
    }
    let UpdateDetails = {
      Name: $event.target.value
    }

    document.getElementById(Id + "-treemenu").innerText = $event.target.value;
    this.UpdateAPI(Id, UpdateDetails);
  }
  DescriptionBulrEvent(Id, $event) {
    let UpdateDetails = {
      Description: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }

  UpdateDepartment(Id, $event) { 
    if($event.target.value =='' && Id==undefined && Id==''){
      return;
    }
    let UpdateDetails = {
      DepartmentName: $event.target.value
    }
    let TreeViews = localStorage.getItem("treeview");
    if(TreeViews!=null){
      let parseTree = JSON.parse(TreeViews);
      let UpdatedTreeView = this.UpdateLocalStorage(parseTree, UpdateDetails.DepartmentName, Id);
      localStorage.setItem("treeview", JSON.stringify(UpdatedTreeView));
    }
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateLocalStorage(node, Name, ID) {
    if (node.nodeId == ID) {
        node.name = Name;
    }
    if (node.children != null && node.children.length > 0) {
      if (node.children != null && node.children.length > 0) {
        node.children.forEach(element => {
          this.UpdateLocalStorage(element,Name, ID)
        });
      }
    }
    return node;
  }

  UpdateWhatIfMultiplier(Id, $event) {
    let UpdateDetails = {
      WhatIfMultiplier: parseFloat($event.target.value).toFixed(2)
    }
    this.UpdateAPI(Id, UpdateDetails);
  }

  UpdateAPI(Id, UpdateDetails) { 
    this.SpinnerService.show();
    this.DetailservicesServices.UpdateForecastRecordDetails(Id, UpdateDetails).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true'); 
        if(this.ShowWhatIf){
          this.ForecasttabsComponent.childEventClicked(Id);
        }
        // console.log(response);
      }
    )
  }
  async ngOnChange() {
    if (this.event != '') {
      let addEnumvalu = []
      this.validnodeTypeLocalize = '';
      if (this.event.validnodeTypes != '') {
        this.SpinnerService.show();
        this.methodservice.getValidationTypeLocalize(this.event.nodeId).subscribe(
          (response: any) => {
            this.validnodeTypeLocalize = response;
            this.methodservice.getAllEmumValue().subscribe(
              (response) => {
                this.SpinnerService.hide();
                this.allEnumValues = response;
                this.allEnumValues.forEach(enumval => {
                  if (this.event.validnodeTypes != null) {
                    this.event.validnodeTypes.forEach((element, index) => {
                      if (enumval.value == element.nodetype) {
                        enumval["localizeName"] = this.validnodeTypeLocalize.englishLables[index].name;
                        addEnumvalu.push(enumval);
                      }
                    });
                  }
    
                })
                this.addEnumvalues = addEnumvalu;
              })
          }
        )
        
      }
    }
  }

}
