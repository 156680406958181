<app-header></app-header>
<div>
    <div class="consolidaitonSettingsHeader">
        <div style="width:90%; margin-top: 20px;">
            <h2 class="HeaderColor">Consolidation Settings </h2>
        </div>
        <div>
            <igx-grid #grid1 [igxPreventDocumentScroll]="true" [data]="consolidaitonInterests" height="600px"
                displayDensity="comfortable" [filterMode]="'excelStyleFilter'" [allowAdvancedFiltering]="true">
                <igx-paginator [perPage]="10"></igx-paginator>
                <igx-column field="forecastName" header="Forecast" width="20%">
                </igx-column>
                <igx-column field="forecastNodeid" header="forecastNodeid" width="20%" [hidden]="true">
                </igx-column>
                <igx-column field="associateInterest" header="Associate Interest (%)" dataType="number" width="20%">
                    <ng-template>
                        <input value="{{cell.value}}" type="number" />
                    </ng-template>
                </igx-column>
                <igx-column field="minorityInterest" header="Minority Interest (%)" dataType="number" width="20%"
                    editable="true">
                    <ng-template>
                        <input value="{{cell.value}}" type="number" />
                    </ng-template>
                </igx-column>
            </igx-grid>
        </div>
    </div>
    <div class="ConsolidationButton">
        <button class="ConsolidateButton OkSaveButtonColor" (click)="SaveInterests()">Save</button>
        <button class="CancelButton deleteCancelButtonColor" (click)="Cancel()">Cancel</button>

    </div>
</div>