import { Component, OnInit } from '@angular/core';
import { ConsolidationService } from 'src/app/service/consolidation.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GoodwillForecastList, GoodwillModel } from 'src/app/model/GoodwillModel';

@Component({
  selector: 'app-goodwill',
  templateUrl: './goodwill.component.html',
  styleUrls: ['./goodwill.component.scss']
})
export class GoodwillComponent implements OnInit {
  goodwillRecords: GoodwillModel[];
  forecastList: GoodwillForecastList[];
  selectedForecast: GoodwillForecastList;
  constructor(private ConsolidationService: ConsolidationService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.reArrangeRecords();
  }
  async reArrangeRecords() {
    this.SpinnerService.show();
    let forcastNodeid = localStorage.getItem('ForecastId');
    this.ConsolidationService.getGoodwillParameters(forcastNodeid).subscribe(
      (response: any) => {
        this.SpinnerService.hide();

        this.goodwillRecords = response.gwParameters;

        this.forecastList = response.sourceForecastsGoodwill;
        this.goodwillRecords.forEach(x => {
          this.forecastList.forEach(element => {
            if (element.forecastNodeId == x.subsidaryForecastNodeid) {
              x.selectedForecast = element;
            }
          });
        })
        // console.log(" this.forecastList", this.forecastList);
      })

  }

  selectforecast(forecast, rowData) {
    // console.log("forecast", forecast);
    let recordID = rowData.recordNodeId;
    let tempValue = this.goodwillRecords;
    tempValue.forEach(x => {
      if (x.recordNodeId === recordID) {
        x.subAssteValue = forecast.childAssetValue;
        x.goodwillValue = (parseFloat(x.parentAssetValue.toString()) - parseFloat(forecast.childAssetValue.toString())).toString();
        x.selectedForecast = forecast;
        x.subsidaryForecastNodeid = forecast.forecastNodeId;
        x.isIncluded = true;
      }
    });
    this.goodwillRecords = tempValue;
  }

  saveGoodwill() {
    let parameterModel = {
      SourceForecastsGoodwill: [],
      GWParameters: [],
    }
    parameterModel.GWParameters = this.goodwillRecords;
    parameterModel.SourceForecastsGoodwill = this.forecastList;
    // console.log("parameterModel", parameterModel);
    this.ConsolidationService.generateGoodwill(parameterModel).subscribe(
      ((response: any) => {
        this.goodwillRecords = [];
        window.location.reload();
      })
    )
  }

  CancelGoodwill() {
    this.goodwillRecords = [];
    window.location.reload();
  }

}
