import { Component, OnInit, Input } from '@angular/core';
import { HotlinkService } from './services/hotlink.service';
import { HotlinkDataSourceCollectionComponent } from './hotlink-data-source-collection/hotlink-data-source-collection.component';
import { HotlinkSave } from './hotlinkModel/hotlink-save';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValuelistComponent } from '../record/valuelist/valuelist.component';

@Component({
  selector: 'app-hotlink',
  templateUrl: './hotlink.component.html',
  styleUrls: ['./hotlink.component.scss']
})
export class HotlinkComponent implements OnInit {
  hotlinksourcecollection = [];
  addhotlink: boolean;
  @Input() hotlinkvaluesNodeId;
  SelectedHotlink: any;
  selectedIndex = null;
  ActiveIndexHotLink: any = '';

  constructor(
    private hotlinkService: HotlinkService,
    private ngxSpinerServices: NgxSpinnerService,
    public valuelistComponent: ValuelistComponent,
    public hotlinkDataSourceCollectionComponent: HotlinkDataSourceCollectionComponent) { }

  ngOnInit() {
    if (this.hotlinkvaluesNodeId != null) {
      this.SelectedHotlink = null;
      let ForecastId = localStorage.getItem("ForecastId");
      this.addhotlink = false;
      this.ngxSpinerServices.show();
      this.hotlinkService.getHotLinkSourceCollection(ForecastId).subscribe(
        (response => {
          this.ngxSpinerServices.hide()
          this.hotlinksourcecollection = response.getHotlinkSourceForecastCollection;
        })
      )
    }

  }
  DeleteHotlink(HotlinkDetails) {
    let RecordNodeId = parseInt(localStorage.getItem("recordNodeId"));
    let HotlinkId = HotlinkDetails.hotLinkDetailsNodeId;
    if (HotlinkId != null && RecordNodeId != null) {
      this.SelectedHotlink = null;
      this.addhotlink = false;
      this.ngxSpinerServices.show();
      this.hotlinkService.deletehotlink(RecordNodeId, HotlinkId).subscribe(
        (response => {
          this.ngxSpinerServices.hide()
          this.ActiveIndexHotLink = null;
          this.hotlinksourcecollection = response.getHotlinkSourceForecastCollection;
        })
      )
    }
  }

  hotlinkOpen(hotlinkForecast, nodeId) {
    this.addhotlink = true;
    hotlinkForecast.open();
    this.SelectedHotlink = null;
  }
  hotlinkEdit(hotlinkForecast, nodeId) {
    this.addhotlink = true;
    hotlinkForecast.open();
  }

  selectedHotlinkCollection(selectedHotlinkDetails, Index) {
    if (selectedHotlinkDetails != null) {
      this.ActiveIndexHotLink = selectedHotlinkDetails.hotLinkDetailsNodeId;
      this.SelectedHotlink = selectedHotlinkDetails
      document.getElementById("UpdateHotlink").click();
    }

  }
  hotlinkForecastSave(hotlinkForecast) {
    let updatedForecastData = localStorage.getItem("hotlinkForecastResult");
    if (updatedForecastData != null && updatedForecastData != undefined) {
      let jsonUpdatedData = JSON.parse(updatedForecastData);
      jsonUpdatedData.hotLinkMemoRecords.forEach(element => {
        if (element.isCheckboxcheckedCode) {
          let edited = false;
          let HotlinkId = 0;
          let hotlinkUpdatedData = new HotlinkSave()
          if (this.SelectedHotlink != null) {
            edited = true;
            HotlinkId = this.SelectedHotlink.hotLinkDetailsNodeId;
          }
          hotlinkUpdatedData.ForecastNodeId = parseInt(localStorage.getItem("ForecastId"));
          hotlinkUpdatedData.ReferanceForecast = parseInt(localStorage.getItem("referanceForecast"));
          hotlinkUpdatedData.RecordNodeId = parseInt(localStorage.getItem("recordNodeId"));
          hotlinkUpdatedData.IsCheckboxcheckedCode = element.isCheckboxcheckedCode;
          hotlinkUpdatedData.ReferanceRecordNodeId = parseInt(this.hotlinkvaluesNodeId);
          hotlinkUpdatedData.NodeId = element.code;
          hotlinkUpdatedData.Edited = edited;
          hotlinkUpdatedData.HotlinkId = HotlinkId;
          this.ngxSpinerServices.show();
          this.hotlinkService.saveHotlinkData(hotlinkUpdatedData).subscribe(
            ((response) => {
              this.ngxSpinerServices.hide();
              hotlinkForecast.close();
              this.valuelistComponent.DisplayAllCommonDataArray = [];
              this.valuelistComponent.BudgetDerrivationView = [];
              this.valuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
              this.valuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
              this.valuelistComponent.PeriodTabView = response.getPeriodTabView;
            })
          )
        }


      });
    }
    hotlinkForecast.close();
  }


}
