import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
declare let window: any;

@Injectable()
export class AppInitService {

    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    public init() {
        return from(
            fetch('assets/app-config.json').then(function (response) {
                return response.json();
            })
        ).pipe(
            map((config) => {
                window.config = config.APIURL;
                window.DefaultCounty = config.CountryServer;
                localStorage.setItem('APIURL', window.config);
                localStorage.setItem("DefaultCounty", window.DefaultCounty);
                return config;
            })).toPromise();
    }
}