import { Component, OnInit, ViewChild } from '@angular/core';
import { ITreeState, ITreeOptions } from '@ali-hm/angular-tree-component';
// import { AdminmoduleService } from 'src/app/service/adminmoduleServices/adminmodule.service';
import { v4 } from 'uuid';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {

  activeUserId: any;
  activePackage: any;
  Pakages = [];
  AllPackages = [];
  FeatureGroup = [];
  Features = [];
  SubFeatures = [];
  SelectedPackage = [];
  CollectionPackage = [];
  CollectionFeatureGroup = [];
  CollectionFeature = [];
  CollectionSubFeature = [];
  SelectedPackageIndex: any;
  ActiveFeatureGroupId: any;
  ActiveFeatureId: any;
  constructor() { }

  ngOnInit() {
    this.activeUserId = null;
    // this.adminService.GetAllPackageInformation().subscribe(
    //   (response => {
    //     this.Pakages = response;
    //   })
    // )
  }

  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };
  options: [];

  @ViewChild('packageCollection') packageCollection;
  @ViewChild('FeatureGroupCollection') FeatureGroupCollection;
  @ViewChild('FeatureCollection') FeatureCollection;
  @ViewChild('SubFeatureCollection') SubFeatureCollection;

  ActiveEvent($event) {
    this.FeatureGroup = [];
    this.Features = [];
    this.SubFeatures = [];
    this.CollectionPackage = [];
    let addPackage = true;
    this.activePackage = $event.node.data.packageId;
    this.FeatureGroup = $event.node.data.featureGroups;
    if (this.SelectedPackage.length > 0) {
      this.SelectedPackage.forEach(element => {
        if (element.packageId == $event.node.data.packageId) {
          addPackage = false;
        }
      });
      if (addPackage) {
        this.SelectedPackage.push($event.node.data);
      }
    } else {
      if (addPackage) {
        this.SelectedPackage.push($event.node.data);
      }
    }
    this.CollectionPackage = this.SelectedPackage;
    this.packageCollection.treeModel.update();
  }
  ActiveFeatureGroupEvent($event) {
    this.Features = [];
    this.SubFeatures = [];
    let addFeatureGroup = true;
    this.Features = $event.node.data.features;
    let index = 0;
    this.CollectionFeatureGroup = [];
    this.SelectedPackage.forEach((element, Index) => {
      if (element.packageId = $event.node.data.packageId) {
        index = Index;
      }
    });
    if (this.SelectedPackage[index].featureGroups.length > 0) {
      this.SelectedPackage[index].featureGroups.forEach(element => {
        if (element.featureGroupID == $event.node.data.featureGroupID) {
          element['edited'] = true;
          this.ActiveFeatureGroupId = element.featureGroupID;
        }
      });
    }
    this.SelectedPackageIndex = index;
    this.CollectionFeatureGroup = this.SelectedPackage[index].featureGroups;

    this.FeatureGroupCollection.treeModel.update();
  }
  ActiveFeatureEvent($event) {
    this.SubFeatures = [];
    this.SubFeatures = $event.node.data.subFeatures;
    this.CollectionFeature = [];
    let index = 0;
    let featureGroupIndex = 0;
    let addFeature = false;
    this.SelectedPackage.forEach((element, Index) => {
      if (element.packageId = this.activePackage) {
        index = Index;
      }
    });
    this.SelectedPackage[index].featureGroups.forEach((element, Index) => {
      if (element.featureGroupID == $event.node.data.featureGroupID) {
        featureGroupIndex = Index;

      }
    });
    if (this.SelectedPackage[index].featureGroups[featureGroupIndex] != null) {
      this.SelectedPackage[index].featureGroups[featureGroupIndex].features.forEach(element => {
        if (element.featureID == $event.node.data.featureID) {
          element['edited'] = true;
          this.ActiveFeatureId = element.featureID
        }
      });
    }

    this.CollectionFeature = this.SelectedPackage[index].featureGroups[featureGroupIndex].features;
    this.FeatureCollection.treeModel.update();
  }
  ActiveSubFeatureGroupEvent($event) {
    let index = 0;
    let featureGroupIndex = 0;
    let SubfeatureGroupIndex = 0;
    this.SelectedPackage.forEach((element, Index) => {
      if (element.packageId = this.activePackage) {
        index = Index;
      }
    });
    this.SelectedPackage[index].featureGroups.forEach((element, Index) => {
      if (element.featureGroupID == this.ActiveFeatureGroupId) {
        featureGroupIndex = Index;
      }
    });
    if (this.SelectedPackage[index].featureGroups[featureGroupIndex] != null) {
      this.SelectedPackage[index].featureGroups[featureGroupIndex].features.forEach((element, Index) => {
        if (element.featureID == this.ActiveFeatureId) {
          SubfeatureGroupIndex = Index;
        }
      });
    }
    if (this.SelectedPackage[index].featureGroups[featureGroupIndex] != null) {
      this.SelectedPackage[index].featureGroups[featureGroupIndex].features[SubfeatureGroupIndex].subFeatures.forEach(element => {
        if (element.subFeatureID == $event.node.data.subFeatureID) {
          element['edited'] = true;
        }
      });
    }
    this.CollectionSubFeature = this.SelectedPackage[index].featureGroups[featureGroupIndex].features[SubfeatureGroupIndex].subFeatures;
    this.SubFeatureCollection.treeModel.update();
  }



}
