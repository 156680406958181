import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  private headers: Headers;
  constructor( private http: HttpClient) { }

   /**
    * Function For get ParameterReciepts Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getParameterReciepts(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ParameterDefault/ParameterReceiptsCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      /**
    * Function For get ParameterPayment Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getParameterPayment(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ParameterDefault/ParameterPaymentCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

    /**
    * Function For get ParameterAddToStock Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getParameterAddToStock(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ParameterDefault/ParameterAddToStockCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      /**
    * Function For get ParameterDepreciation Collection
    * @param ForecastNodeId 
    * @returns 
    */
    getParameterDepreciation(ForecastNodeId) : Observable<any[]>{
      return this.http.get(environment.url+"/api/ParameterDefault/ParameterDepreciationCollection/"+ForecastNodeId, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

      SetAll(NodeId, SetAllValue):Observable<any[]>{
        return this.http.get(environment.url+"/api/ParameterDefault/SaveValueList/"+NodeId+"/"+SetAllValue, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }
      ParameterDepreciationDefualtPercentage(NodeId, Value):Observable<any[]>{
        return this.http.get(environment.url+"/api/BudgetData/FixedRatePercent/"+NodeId+"/"+Value, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

      ParameterDepreciationDefualtPerameterPercentage(NodeId, Value):Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterDepreciationDefualtPercentage/"+NodeId+"/"+Value, { responseType: "json" })
         .pipe(map(
           (response : any) =>
           {
             return response;
           }
         ));
      }

      

      SaveValueListByMonth(NodeId, index, Values) :Observable<any[]>{
        return this.http.get(environment.url+"/api/ParameterDefault/SaveValueListByMonth/"+NodeId+"/"+index+"/"+Values, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      ChangesRecipetsDerrivation(NodeId, DerrivationType) :Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterRecieptsDerrivationType/"+NodeId+"/"+DerrivationType, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      ChangesPaymentDerrivation(NodeId, DerrivationType) :Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterPaymentDerrivationType/"+NodeId+"/"+DerrivationType, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      ChangesAddToStockDerrivation(NodeId, DerrivationType) :Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterAddToStockDerrivationType/"+NodeId+"/"+DerrivationType, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }

      ChangesdepreciationDerrivation(NodeId, DerrivationType) :Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterDepreciationDerrivationType/"+NodeId+"/"+DerrivationType, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      ChangesdepreciationDestinationDerrivation(NodeId, DerrivationType) :Observable<any[]>{
        return this.http.put(environment.url+"/api/ParameterDefault/ParameterDepreciationDestinationsDerrivationType/"+NodeId+"/"+DerrivationType, { responseType: "json" })
        .pipe(map(
          (response : any) =>
          {
            return response;
          }
        ));
      }
      
      
}
