export class FormulaModel {
    valuesNodeID: number
    recordNodeID: number
    FormulaDetails: FormulaDetailModel[]
    includeTypeResult: number
    negateValues: boolean
    iscleared: boolean
    ValueList:any = [];
    constructor() {
        this.valuesNodeID = 0;
        this.recordNodeID = 0;
        this.FormulaDetails = [];
        this.includeTypeResult = 0;
        this.negateValues = false;
        this.iscleared = false;
        this.ValueList = [];
    }
}

export enum FormulaOperandEnum {
    FormulaOperand = 210000,
    FormulaPlus = 210001,
    FormulaMinus = 210002,
    FormulaMultiply = 210003,
    FormulaDivide = 210004,
    FormulaLeftParenthesis = 210005,
    FormulaRightParenthesis = 210006,
    FormulaDataOperand = 210010,
    FormulaDecimalOperand = 210011
}

export class FormulaDetailModel {
    name: string
    valuesArray: string[]
    nodeId: number
    operatorType: FormulaOperandEnum
    index: number
    isDeleted: boolean
    isModified: boolean
    constructor() {
        this.name = "";
        this.nodeId = 0;
        this.valuesArray = [];
        this.operatorType = null;
        this.index = 0;
        this.isDeleted = false;
        this.isModified = false;
    }
}