
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MethodserviceService } from "../../component/record/method/service/methodservice.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import { ValuelistComponent } from '../record/valuelist/valuelist.component';
import { recordSaveModel } from 'src/app/model/recordSaveModel';
import { budgetdatamodified } from 'src/app/model/recordSaveModel';

@Component({
  selector: 'app-capitalpaid',
  templateUrl: './capitalpaid.component.html',
  styleUrls: ['./capitalpaid.component.scss']
})
export class CapitalpaidComponent implements OnInit {

  @Input() startIndex: any;
  @Input() recordnodeType: any;
  @Input() ActiveTreenodeId: any;
  @Output() recordModifiedEvent = new EventEmitter<recordSaveModel>();
  displayvaluelistdata = [];
  displaynodevisehtml: any;
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedloanTakenRecord: any = [];
  loantakenarray: any = {}
  CommonHtmlArrayResult: any;

  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  //
  constructor(
    private methodservice: MethodserviceService,
    public ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService) {
    this.activeId = localStorage.getItem('ForecastId');
  }

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    $('.OverdraftInterestRate').addClass('col-sm-5');
    if (this.ActiveTreenodeId == '' && this.activeId == '') {
      return;
    }
    this.methodservice.getLoanTakenCalcultedRecord(this.activeId, this.ActiveTreenodeId).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.enumInterestTypes = this.interestTypesenum();
        this.enumpaymentTiming = this.paymenttimingenum();
        this.CalculatedloanTakenRecord = response;
        this.loantakenarray = {
          "nodeID": this.ActiveTreenodeId,
          "DisplayLoanDetailData": [
            {
              "InterestTypes": this.CalculatedloanTakenRecord.interestTypes,
              "PaymentFrequencies": this.CalculatedloanTakenRecord.paymentFrequencies.numberFormatPrefix,
              "PaymentTimings": this.CalculatedloanTakenRecord.paymentTimings,
              "isNewLoan": this.CalculatedloanTakenRecord.isNewLoan,
              "LoanDrawdownPeriodIndex": this.CalculatedloanTakenRecord.loanDrawdownPeriodIndex,
              "Term": this.CalculatedloanTakenRecord.term,
              "LoanBalance": this.CalculatedloanTakenRecord.loanBalance,
              "InterestRate": this.CalculatedloanTakenRecord.interestRate,
              "Payment": this.CalculatedloanTakenRecord.payment.toFixed(2),
              "HasDepositPayment": this.CalculatedloanTakenRecord.hasDepositPayment,
              "CanHaveDepositPayment": this.CalculatedloanTakenRecord.canHaveDepositPayment,
              "ShowDeposit": this.CalculatedloanTakenRecord.showDeposit,
              "ShowDepositPayment": this.CalculatedloanTakenRecord.showDepositPayment,
              "CanHaveBulletPayment": this.CalculatedloanTakenRecord.canHaveBulletPayment,
              "ShowBullet": this.CalculatedloanTakenRecord.showBullet,
              "ShowBulletPayment": this.CalculatedloanTakenRecord.showBulletPayment,
              "CanHaveBalloonPayment": this.CalculatedloanTakenRecord.canHaveBalloonPayment,
              "CanHaveHolidayPeriod": this.CalculatedloanTakenRecord.canHaveHolidayPeriod,
              "HasBulletPayment": this.CalculatedloanTakenRecord.hasBulletPayment,
              "HasBalloonPayment": this.CalculatedloanTakenRecord.hasBalloonPayment,
              "HasHolidayPeriod": this.CalculatedloanTakenRecord.hasHolidayPeriod,
              "DepositPayment": this.CalculatedloanTakenRecord.depositPayment,
              "BulletPayment": this.CalculatedloanTakenRecord.bulletPayment,
              "MonthsBulletDelay": this.CalculatedloanTakenRecord.monthsBulletDelay,
              "BalloonPayment": this.CalculatedloanTakenRecord.balloonPayment,
              "MonthsHoliday": this.CalculatedloanTakenRecord.monthsHoliday,
              "LoanPrincipal": this.CalculatedloanTakenRecord.loanPrincipal,
              "IsPeriodStartEnabled": this.CalculatedloanTakenRecord.isPeriodStartEnabled,
              "SelectedIndex": this.CalculatedloanTakenRecord.selectedIndex
            }
          ]
        }
        this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'] = [];
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })

  }

  interestTypesenum() {
    let interesttypesenum = [{
      0: 'Undefined',
      1: 'CompoundInterest',
      2: 'SimpleInterest',
      3: 'InterestOnly',
    }]
    return interesttypesenum;
  }
  paymenttimingenum() {
    let paymenttimingenum = [{
      1: 'InAdvance',
      2: 'InArrears'
    }]
    return paymenttimingenum;
  }
  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
  //Calculate As Balance
  CalculateAsBalance(recordNodeId, event) {
    let selectedOption = 0;
    if (event.target.checked == true) {
      selectedOption = 1;
    }
    this.methodservice.CalculateAsBalance(recordNodeId.selectNodeId, selectedOption).subscribe(
      (response) => {
        return response;
      }
    )
  }
  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }
  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }
  changeInterestType($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['InterestTypes'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("InterestTypes");
    this.UpdateRecordModified(this.loantakenarray);
  }

  changepaymentFrequcncy($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentFrequencies'] = $event.toString();
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentFrequencies");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeTerm($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['Term'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Term");
    this.UpdateRecordModified(this.loantakenarray);
  }

  UpdateRecordModified(loantakenarray) {
    let UpdateLoanTakenArray = new recordSaveModel();
    UpdateLoanTakenArray.recordNodeID = loantakenarray.nodeID;
    let budgetData = new budgetdatamodified();
    budgetData.valuenodeId = loantakenarray.nodeID;
    budgetData.valuelist = [];
    budgetData.selectedNodes = [];
    budgetData.unselectedNodes = [];
    budgetData.hasLoanOtherOptions = true;
    budgetData.isDerivation = false;
    budgetData.isNodeSelection = false;
    budgetData.loanCalculationModels = loantakenarray;
    UpdateLoanTakenArray.budgetData.push(budgetData);
    this.ValuelistComponent.recordModified(UpdateLoanTakenArray);
  }

  changeloanmadePeriod($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['SelectedIndex'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("SelectedIndex");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeLoanBalance($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    let values = $event.replaceAll(/,/g, '');
    this.loantakenarray.DisplayLoanDetailData[0]['LoanBalance'] = parseFloat(values).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("LoanBalance");
    this.UpdateRecordModified(this.loantakenarray);
  }
  HasBallooneEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordballon').addClass('hidden');
    } else {
      $('.loantakenrecordballon').removeClass('hidden');
    }

    this.loantakenarray.DisplayLoanDetailData[0]['HasBalloonPayment'] = $event.target.checked;
    this.CalculatedloanTakenRecord.hasBalloonPayment = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBalloonPayment");
    this.UpdateRecordModified(this.loantakenarray);
  }
  hasHolidayPeriodEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordhollidayPeriodEvent').addClass('hidden');
    } else {
      $('.loantakenrecordhollidayPeriodEvent').removeClass('hidden');
    }

    this.loantakenarray.DisplayLoanDetailData[0]['HasHolidayPeriod'] = $event.target.checked;
    this.CalculatedloanTakenRecord.hasHolidayPeriod = $event.target.checked;
    this.CalculatedloanTakenRecord.canHaveBulletPayment = !$event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasHolidayPeriod");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  changepaymentTiming($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentTimings'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentTimings");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeInterestRate($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['InterestRate'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("InterestRate");
    this.UpdateRecordModified(this.loantakenarray);
    // this.updatearray(this.loantakenarray);

  }
  changePayment($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    let value = $event.replaceAll(/,/g, '');
    this.loantakenarray.DisplayLoanDetailData[0]['Payment'] = parseFloat(value).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Payment");
    this.UpdateRecordModified(this.loantakenarray);
    // this.updatearray(this.loantakenarray);

  }

  changeBaloon($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BalloonPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BalloonPayment");
    this.UpdateRecordModified(this.loantakenarray);

  }

  IsnewLoanchangeEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-periodStart').addClass('hidden');
    } else {
      $('.loantakenrecord-periodStart').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['IsNewLoan'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("IsNewLoan");
    this.UpdateRecordModified(this.loantakenarray);
  }
  OnchangeBullet($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-BulletPayment').addClass('hidden');
      $('.loantakenrecord-PaymentMonth').addClass('hidden');
    } else {
      $('.loantakenrecord-BulletPayment').removeClass('hidden');
      $('.loantakenrecord-PaymentMonth').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['HasBulletPayment'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBulletPayment");
    this.CalculatedloanTakenRecord.canHaveHolidayPeriod = !$event.target.checked;
    this.UpdateRecordModified(this.loantakenarray);
    // this.updatearray(this.loantakenarray)
  }
  changeBulletPayment($event, loantakenRecordValue) {

    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BulletPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BulletPayment");
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeBulletPaymentMonth($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsBulletDelay'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsBulletDelay");
    this.UpdateRecordModified(this.loantakenarray);
  }
  // update holiday(Month)
  changeholidaymonthText($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsHoliday'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsHoliday");
    this.UpdateRecordModified(this.loantakenarray);
  }

  // change deposite code 
  changeDeposite($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['DepositPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("DepositPayment");
    this.UpdateRecordModified(this.loantakenarray);
  }

  arrMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  updateLoanTakenrecord() {
    this.SpinnerService.show();
    this.methodservice.getLoanTakenCalcultedRecord(this.activeId, this.ActiveTreenodeId).subscribe(
      (response: any) => {
        this.enumInterestTypes = this.interestTypesenum();
        this.enumpaymentTiming = this.paymenttimingenum();
        this.CalculatedloanTakenRecord = response;
        let NodeId = this.displaynodevisehtml.recordNodeId;
        this.displayvaluelistdata = this.displayvaluelistdata;
        // console.log(this.displayvaluelistdata);
        this.SpinnerService.hide();
        this.loantakenarray = {
          "nodeID": NodeId,
          "DisplayLoanDetailData": [
            {
              "InterestTypes": this.CalculatedloanTakenRecord.interestTypes,
              "PaymentFrequencies": this.CalculatedloanTakenRecord.paymentFrequencies.numberFormatPrefix,
              "PaymentTimings": this.CalculatedloanTakenRecord.paymentTimings,
              "isNewLoan": this.CalculatedloanTakenRecord.isNewLoan,
              "LoanDrawdownPeriodIndex": this.CalculatedloanTakenRecord.loanDrawdownPeriodIndex,
              "Term": this.CalculatedloanTakenRecord.term,
              "LoanBalance": this.CalculatedloanTakenRecord.loanBalance,
              "InterestRate": this.CalculatedloanTakenRecord.interestRate,
              "Payment": this.CalculatedloanTakenRecord.payment,
              "HasDepositPayment": this.CalculatedloanTakenRecord.hasDepositPayment,
              "CanHaveDepositPayment": this.CalculatedloanTakenRecord.canHaveDepositPayment,
              "ShowDeposit": this.CalculatedloanTakenRecord.showDeposit,
              "ShowDepositPayment": this.CalculatedloanTakenRecord.showDepositPayment,
              "CanHaveBulletPayment": this.CalculatedloanTakenRecord.canHaveBulletPayment,
              "ShowBullet": this.CalculatedloanTakenRecord.showBullet,
              "ShowBulletPayment": this.CalculatedloanTakenRecord.showBulletPayment,
              "CanHaveBalloonPayment": this.CalculatedloanTakenRecord.canHaveBalloonPayment,
              "CanHaveHolidayPeriod": this.CalculatedloanTakenRecord.canHaveHolidayPeriod,
              "HasBulletPayment": this.CalculatedloanTakenRecord.hasBulletPayment,
              "HasBalloonPayment": this.CalculatedloanTakenRecord.hasBalloonPayment,
              "HasHolidayPeriod": this.CalculatedloanTakenRecord.hasHolidayPeriod,
              "DepositPayment": this.CalculatedloanTakenRecord.depositPayment,
              "BulletPayment": this.CalculatedloanTakenRecord.bulletPayment,
              "MonthsBulletDelay": this.CalculatedloanTakenRecord.monthsBulletDelay,
              "BalloonPayment": this.CalculatedloanTakenRecord.balloonPayment,
              "MonthsHoliday": this.CalculatedloanTakenRecord.monthsHoliday,
              "LoanPrincipal": this.CalculatedloanTakenRecord.loanPrincipal,
              "IsPeriodStartEnabled": this.CalculatedloanTakenRecord.isPeriodStartEnabled,
              "SelectedIndex": this.CalculatedloanTakenRecord.selectedIndex
            }
          ]
        }
        this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'] = [];
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
}
