export class HotlinkSave {
    ForecastNodeId: any
    IsCheckboxcheckedCode: boolean
    RecordNodeId: any
    ReferanceForecast: any
    ReferanceRecordNodeId:any
    NodeId:any
    Edited:boolean;
    HotlinkId :any;

    constructor() {
        this.ForecastNodeId = null;
        this.IsCheckboxcheckedCode = null;
        this.RecordNodeId = null;
        this.ReferanceForecast = null;
        this.ReferanceRecordNodeId = null;
        this.NodeId = null;
        this.Edited = null;
        this.HotlinkId = null;
    }
}
