import { Component, Input, ElementRef, EventEmitter, OnInit, Output, ViewChild, Injector } from '@angular/core';
import { ITreeState, ITreeOptions } from '@ali-hm/angular-tree-component';
import { XeroService } from '../Integration/Xero/service/xero.service';
import { Treeview } from '../../service/treeview';
import { Record } from '../../model/record';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';

declare const $: any;
import { v4 } from 'uuid';

@Component({
  selector: 'app-report-tree',
  templateUrl: './report-tree.component.html',
  styleUrls: ['./report-tree.component.scss']
})
export class ReportTreeComponent implements OnInit {

  nodesFirst = [];
  nodesSecond = [];
  displayTree: boolean;
  Treeviews: Treeview[] = [];
  record: Record[] = [];
  nodes = [];
  constructor(private xeroService: XeroService, private SpinnerService: NgxSpinnerService, private injector: Injector) {

  }
  @ViewChild('tree') tree;
  onUpdateData() {
    if(this.tree!=null){
      const treeModel = this.tree.treeModel;
      treeModel.expandAll()
    }
    
  }
  async onTreeLoad() {

  }
  ngOnInit() {
    this.displayTree = false;
    let ForecastId = localStorage.getItem("ForecastId");
    this.createMapingClick();
  }
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };


  options: ITreeOptions = {
    allowDrag: (node) => node,
    allowDrop: (node) => node,
    getNodeClone: (node) => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`
    })
  };

  saveFunction() {

    const notifier = this.injector.get(NotificationService);
    let UpdatedTree = {
      "name": "ForecastSection",
      "children": this.nodesSecond
    }
    let saveMaping = {
      "importDataValue": UpdatedTree,
      "nodeId": parseInt(localStorage.getItem("ForecastId"))
    }
    this.SpinnerService.show();
    this.xeroService.SaveMaping(saveMaping).subscribe(
      ((response: any) => {
        try {
          this.SpinnerService.hide();
          notifier.showSuccess("Mapping's saved successfully.")
        } catch (error) {
          this.SpinnerService.hide();
          notifier.showError("Mapping's not saved successfully")
        }
        
      })
    )
  }

  createMapingClick() {
    this.SpinnerService.show();
    let ForecastId = localStorage.getItem("ForecastId");
    this.xeroService.XeroMapingDataCollection(ForecastId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.displayTree = true;
        //this.onUpdateData();
        if(!response.isMapingshow) { 
          this.nodesSecond = response.forecastMap;
          this.nodesFirst = response.xeroGLAccounts;
          this.options = {
            allowDrag: true,
            allowDrop: true,
          }
          this.DefaultMapping( this.nodesSecond, this.nodesFirst );
          const notifier = this.injector.get(NotificationService);
            let UpdatedTree = {
              "name": "ForecastSection",
              "children": this.nodesSecond
            }
            let saveMaping = {
              "importDataValue": UpdatedTree,
              "nodeId": parseInt(localStorage.getItem("ForecastId"))
            }
            this.SpinnerService.show();
            this.xeroService.SaveMaping(saveMaping).subscribe(
              ((response: any) => {
                try { 
                  this.xeroService.XeroMapingDataCollection(ForecastId).subscribe(
                    (result=> { 
                      console.log(result.forecastMap);
                      this.SpinnerService.hide();
                      this.nodesSecond = [];
                      this.nodesFirst = [];
                      this.nodesSecond = result.forecastMap;
                      this.nodesFirst = result.xeroGLAccounts;
                      this.options = {
                        allowDrag: true,
                        allowDrop: true,
                      }
                      this.onUpdateData();
                    })
                  )
                   
                } catch (error) {
                  this.SpinnerService.hide();
                }
              })
            )
          //
        } else {
          this.nodesSecond = response.forecastMap;
          this.nodesFirst = response.xeroGLAccounts;
          this.options = {
            allowDrag: true,
            allowDrop: true,
          }
          this.onUpdateData();
        }
        

      }))
  }

  DefaultMapping(ForecastSection, XeroSections){
    let Data = ForecastSection;
     let  Sections = [
    {
      forecastsection: "Sales",
      ValidXeroSection : ["REVENUE", "SALES"]
     },
     {
      forecastsection: "Direct Costs",
      ValidXeroSection : ["DIRECTCOSTS"]
     },
     {
      forecastsection: "Overheads",
      ValidXeroSection : [ "EXPENSE", "OVERHEADS", "PREPAYMENT", "SUPERANNUATIONEXPENSE", "WAGESEXPENSE", "DEPRECIATN"]
     },
     {
      forecastsection: "Other Income",
      ValidXeroSection : ["OTHERINCOME"]
     },
     {
      forecastsection: "Bank Accounts",
      ValidXeroSection : ["BANK"]
     },
     {
      forecastsection: "Fixed Assets",
      ValidXeroSection : ["FIXED"]
     },
     {
      forecastsection: "Loans",
      ValidXeroSection : ["TERMLIAB"]
     },
     {
      forecastsection: "Other Assets/Liabilities",
      ValidXeroSection : [ "CURRENT", "CURRLIAB", "INVENTORY", "LIABILITY", "NONCURRENT", "PAYGLIABILITY", "SUPERANNUATIONLIABILITY"]
     },
     {
      forecastsection: "Capital Accounts",
      ValidXeroSection : ["EQUITY"]
     }
    ]

    
    Data.forEach(Forecastelement => {
      XeroSections.forEach((Xeroelement, index) => {
        Sections.forEach(element => {
          if(Forecastelement.glAccount == element.forecastsection && element.ValidXeroSection.includes(Xeroelement.glAccount) ){
            Forecastelement["children"].push(Xeroelement);
            //XeroSections.splice(index, 1);
          }
        });
      });
   });

   console.log(Data);

  }
}
