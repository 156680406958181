import { Component, Input, OnInit } from '@angular/core';
import { MethodserviceService } from '../../../../component/record/method/service/methodservice.service';
import { DetailservicesService } from '../../details-services/detailservices.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';

@Component({
  selector: 'app-folder-view-details',
  templateUrl: './folder-view-details.component.html',
  styleUrls: ['./folder-view-details.component.scss']
})
export class FolderViewDetailsComponent implements OnInit {

  @Input() event;
  ShowWhatIf:boolean;
  constructor(private DetailservicesServices: DetailservicesService,
    private ForecasttabsComponent:ForecasttabsComponent,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    // console.log(this.event);
  }

  ngOnChange() {
    // console.log(this.event);
  }

  clickgetdata(Id, $event) {
    let UpdateDetails = {
      Name: $event.target.value
    }
    document.getElementById(Id + "-treemenu").innerText = $event.target.value;
    this.UpdateAPI(Id, UpdateDetails);
  }
  DescriptionBulrEvent(Id, $event) {
    let UpdateDetails = {
      Description: $event.target.value
    }
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateWhatIfMultiplier(Id, $event) {
    let UpdateDetails = {
      WhatIfMultiplier: parseFloat($event.target.value).toFixed(2)
    }
    this.UpdateAPI(Id, UpdateDetails);
  }
  UpdateAPI(Id, UpdateDetails) {
    this.SpinnerService.show();
    console.log(UpdateDetails);
    this.DetailservicesServices.UpdateForecastRecordDetails(Id, UpdateDetails).subscribe(
      (response: any) => { 
        this.SpinnerService.hide();
        this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true'); 
        if(this.ShowWhatIf){
          this.ForecasttabsComponent.childEventClicked(Id);
        }
        // console.log(response);
      }
    )
  }

}
