<div class="valuelist-component inboxContainer col-sm-12" style="width: 100%; display: inline-block;">
    <div class="bs-example" *ngIf="PeriodTabActive">
        <ul class="nav nav-tabs" style="width: 40%;margin: 0 0 7px -12px;height: 20px;border-bottom: none !important; display: inline-block;">
            <li class="nav-item" style="height: 20px;">
                <select form="recordTabForm" class="form-control"
                    style="background: #1e90ff;color: white !important;font-size: 12px;font-weight: bold;height: 30px; font-weight: 400 !important;">
                    <option value="Annual" ng-reflect-value="Annual">Annual</option>
                    <option value="All" ng-reflect-value="All">All</option>
                </select>
            </li>
            <li class="nav-item" *ngFor="let result of PeriodTabView[0].results;  let i=index"
                (click)="ChangePeriodType(result, i)">
                <a class="nav-link" style="height: 30px;font-weight: 400 !important;padding: 4px 20px 0 20px !important;" [ngClass]="{'active':i==ActivePeriodTabMen}"
                    data-toggle="tab">{{result.longLabel}}</a>
            </li>
            <li class="nav-item" style="height: 30px;">
                <div class="" *ngIf="isRecord == true;">
                    <input type="button" class="OkSaveButtonColor" style="border: 1px solid !important; height: 29px; font-weight: 400 !important;"
                        value="Calculate & Save" (click)="SaveData()" />
                </div>
            </li>
        </ul>
        <div class="RecordDisplaydiv" *ngIf="ActivePeriodTabMen!=null && DisplayBudgetData==true" style="display: inline-block; display: inline-block;width: 59%;">
            <div *ngIf="hasRecordOption && MemoRecordArray">
                <app-record-option [RecordOption]="MemoRecordArray"></app-record-option>
            </div>
        </div>
        <div class="tab-content" *ngIf="ActivePeriodTabMen!=null && DisplayBudgetData==true">
            <div class="tab-pane fade testingData ValueListMainComponent active">
                <div class="scroll-bar-wrap">
                    <div class="scroll-box">
                        <form>
                            <div class="scrolling-wrapper row flex-row flex-nowrap"
                                *ngIf="eventBudgetListView.length>0">

                                <div class="listingvaluelist valuelistmain col-sm-4"
                                    *ngIf="recordnodeType=='50017' && isConsolidation==false">
                                    <igx-card [horizontal]="horizontal" elevated>
                                        <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                            <igx-card-header>
                                                <div class="valuelist-title">
                                                    <label style="word-break: break-all;"></label>
                                                </div>
                                            </igx-card-header>
                                            <igx-card-content>
                                                <div class="methodandvaluelistcontain">
                                                    <app-calculated-loan-made [startIndex]="ActivePeriodTabMen"
                                                        [recordnodeType]="recordnodeType"
                                                        [ActiveTreenodeId]="ActiveTreenodeId"></app-calculated-loan-made>
                                                </div>
                                            </igx-card-content>
                                        </div>
                                    </igx-card>
                                </div>
                                <div class="listingvaluelist valuelistmain col-sm-4"
                                    *ngIf="recordnodeType=='50011' && isConsolidation==false">
                                    <igx-card [horizontal]="horizontal" elevated>
                                        <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                            <igx-card-header>
                                                <div class="valuelist-title">
                                                    <label style="word-break: break-all;"></label>
                                                </div>
                                            </igx-card-header>
                                            <igx-card-content>
                                                <div class="methodandvaluelistcontain"
                                                    *ngIf="finnaceAssetRecord == '50011' ">
                                                    <app-finnacedassets [startIndex]="ActivePeriodTabMen"
                                                        [recordnodeType]="recordnodeType"
                                                        [ActiveTreenodeId]="ActiveTreenodeId"></app-finnacedassets>
                                                </div>
                                            </igx-card-content>
                                        </div>
                                    </igx-card>
                                </div>

                                <div class="listingvaluelist valuelistmain col-sm-4"
                                    *ngIf="recordnodeType=='50004'  && isConsolidation==false">
                                    <igx-card [horizontal]="horizontal" elevated>
                                        <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                            <igx-card-header>
                                                <div class="valuelist-title">
                                                    <label style="word-break: break-all;"></label>
                                                </div>
                                            </igx-card-header>
                                            <igx-card-content>
                                                <div class="methodandvaluelistcontain">
                                                    <div class="methodandvaluelistcontain" *ngIf="ActiveTreenodeId!=''">
                                                        <app-capitalpaid [startIndex]="ActivePeriodTabMen"
                                                            [recordnodeType]="recordnodeType"
                                                            [ActiveTreenodeId]="ActiveTreenodeId"></app-capitalpaid>
                                                    </div>
                                                </div>
                                            </igx-card-content>
                                        </div>
                                    </igx-card>

                                </div>

                                <div class="listingvaluelist valuelistmain{{displaydata.nodeTypeEnglishLabel}} col-sm-3"
                                    [ngClass]="{'hide': displaydata.nodeTypeEnglishLabel == 'CapitalPaidNextYear'}"
                                    *ngFor="let displaydata of DisplayAllCommonDataArray; let i = index"
                                    [attr.data-index]="i">
                                    <div *ngIf="displaydata.nodeType !='67065'">
                                        <igx-card [horizontal]="horizontal" elevated>
                                            <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                                <igx-card-header>
                                                    <div class="valuelist-title">
                                                        <label style="word-break: break-all;">
                                                            {{displaydata.englishLabel}}</label>
                                                    </div>
                                                </igx-card-header>
                                                <igx-card-content>
                                                    <div class="methodandvaluelistcontain"
                                                        *ngIf="showWhatIfActive==false && isConsolidation==false">
                                                        <app-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="eventBudgetListView[12]"
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-method>
                                                        <app-commonhtml [valuelistdata]='displaydata'
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [startIndex]="ActivePeriodTabMen"
                                                            [ValueListMonthDisplay]="MonthDisplayView"
                                                            (recordModifiedEvent)="recordModified($event)"></app-commonhtml>
                                                    </div>
                                                    <div class="methodandvaluelistcontain testingWhatif"
                                                        *ngIf="showWhatIfActive==true  && isConsolidation==false">
                                                        <!-- <app-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="eventBudgetListView[12]"
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-method>
                                                        <app-what-if-common [valuelistdata]='displaydata'
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [startIndex]="ActivePeriodTabMen"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-what-if-common> -->
                                                        <!-- <app-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="eventBudgetListView[12]"
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-method> -->
                                                        <app-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="eventBudgetListView[12]"
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-method>
                                                        <app-commonhtml [valuelistdata]='displaydata'
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [startIndex]="ActivePeriodTabMen"
                                                            [ValueListMonthDisplay]="MonthDisplayView"
                                                            (recordModifiedEvent)="recordModified($event)"></app-commonhtml>

                                                    </div>
                                                    <div class="methodandvaluelistcontain"
                                                        *ngIf="isConsolidation ==true ">
                                                        <app-method [eventMethodName]="displaydata"
                                                            [valuelistdata]='displaydata'
                                                            [displayallMaintainData]="eventBudgetListView[12]"
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [ValueListMonthDisplay]="MonthDisplayView"></app-method>
                                                        <app-commonhtml [valuelistdata]='displaydata'
                                                            [valueListDerrivation]="BudgetDerrivationView[i]"
                                                            [startIndex]="ActivePeriodTabMen"
                                                            [ValueListMonthDisplay]="MonthDisplayView"
                                                            (recordModifiedEvent)="recordModified($event)"></app-commonhtml>
                                                    </div>
                                                </igx-card-content>
                                            </div>
                                        </igx-card>
                                    </div>

                                </div>

                                <div *ngIf="haveStockComponent && isConsolidation==false"
                                    class="listingvaluelist col-sm-12">
                                    <div style="padding: 10px;border-radius: 1%;">
                                        <div class="methodandvaluelistcontain">
                                            <app-stock-component [stockComponentInput]='stockinputId'
                                                [startIndex]="ActivePeriodTabMen"
                                                (recordModifiedEvent)="recordModified($event)"></app-stock-component>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="haveMadeStockComponent" class="listingvaluelist col-sm-12">
                                    <div style="padding: 10px;border-radius: 1%;">

                                        <div class="methodandvaluelistcontain">
                                            <app-stockmadecomponet [stockComponentInput]='stockinputId'
                                                [startIndex]="ActivePeriodTabMen"
                                                (recordModifiedEvent)="recordModified($event)"></app-stockmadecomponet>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>