import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MethodserviceService } from '../../record/method/service/methodservice.service';
import { StockmadecomponetComponent } from '../stockmadecomponet.component';
import { TreeviewService } from 'src/app/service/treeview.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from 'src/app/service/menu.service';
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';
import { FormulaModel } from 'src/app/model/FormulaModel';
import { EmployeeGroupComponent } from '../../employee-group/employee-group.component';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import { StockComponentService } from 'src/app/service/stock-component.service';
@Component({
  selector: 'app-stock-made-method',
  templateUrl: './stock-made-method.component.html',
  styleUrls: ['./stock-made-method.component.scss']
})

export class StockMadeMethodComponent implements OnInit {

  @Input() eventMethodName: Event;
  @Input() valuelistdata;
  @Input() displayallMaintainData: any;
  @Input() valueListDerrivation;
  @Input() ValueListMonthDisplay;
  addsimpleevent: any;
  allEnumValues: any;
  AddEnumvalueInList: any = [];
  getbudgetdata: any;
  getProfileCollection = [];
  MethodLocalization: any
  SubDerrivationOptions: any;
  MemoDerrivationOption: any;
  formulamodifiedModel: FormulaModel;
  showActiveWhatIf: boolean;
  getEvenSpreadProfileCollection = [];
  ProfileCollectionBool: boolean;
  selectedProfile: any;
  displayvaluelistdata = [];
  displaydarrivation = [];
  DisplayEmployeForm: boolean;
  EmployeeRecordList: any;
  recordmodifiedModel: recordSaveModel;
  HotLinkValuesNodeId:any;

  constructor(private methodservice: MethodserviceService,
    private stockComponentComponent: StockmadecomponetComponent,
    private forecasttabsComponents: ForecasttabsComponent,
    private menuService: MenuService,
    private SpinnerService: NgxSpinnerService,
    private stockComponentService:StockComponentService,
    private treeview: TreeviewService) { }

  signIn(event) {
    event.dialog.close();
  }
  ngOnInit() {
    this.showActiveWhatIf = false;
    this.DisplayEmployeForm = false;
    this.addsimpleevent = this.eventMethodName;
    this.getbudgetdata = this.eventMethodName;
    this.MethodLocalization = '';
    this.AddEnumvalueInList = this.getbudgetdata;
    console.log("budgetData", this.valuelistdata);
    // console.log("this.AddEnumvalueInList", this.AddEnumvalueInList);
    this.HotLinkValuesNodeId = this.AddEnumvalueInList.valuesNodeId;
    if (this.getbudgetdata.derivationEngType == 'ProfileData') {
      this.methodservice.getProfileColletions(this.getbudgetdata.nodeId).subscribe(
        (response) => {
          this.getProfileCollection = response;
        }
      )
    }
    let forecastId = localStorage.getItem("ForecastId");
    if (localStorage.getItem('showWhatIfValue') != '') {
      this.showActiveWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
    }
    else {
      this.menuService.showWhatIfValue(forecastId).subscribe(
        ((response: any) => {
          localStorage.setItem('showWhatIfValue', response);
          this.showActiveWhatIf = response;
        })
      )
    }
  }
  ngOnChanges() {
    this.showActiveWhatIf = false;
    this.addsimpleevent = this.eventMethodName;
    this.getbudgetdata = this.eventMethodName;
    this.MethodLocalization = '';
    this.AddEnumvalueInList = this.getbudgetdata;
    this.HotLinkValuesNodeId = this.AddEnumvalueInList.valuesNodeId;
     console.log("budgetData", this.valuelistdata);
    // console.log(this.AddEnumvalueInList);
    this.getProfileCollection = [];
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.ProfileCollectionBool = false;
    this.selectedProfile = '';
    this.SubDerrivationOptions = [];
    this.MemoDerrivationOption = [];
    this.getEvenSpreadProfileCollection = [];
    let forecastId = localStorage.getItem("ForecastId");
    if (localStorage.getItem('showWhatIfValue') != '') {
      this.showActiveWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
    }
    else {
      this.menuService.showWhatIfValue(forecastId).subscribe(
        ((response: any) => {
          localStorage.setItem('showWhatIfValue', response);
          this.showActiveWhatIf = response;
        })
      )
    }

    this.displaydarrivation.push(this.valueListDerrivation);
    this.displayvaluelistdata.push(this.valuelistdata);
    if (this.valueListDerrivation.derivationOptions.length > 0) {
      if (this.valueListDerrivation.derivationOptions[0].storedValue != null) {
        this.selectedProfile = this.valueListDerrivation.derivationOptions[0].storedValue.toFixed(0);
      }
      if (this.valueListDerrivation.derivationOptions[0].selectableEntities) {
        this.getEvenSpreadProfileCollection = this.valueListDerrivation.derivationOptions[0].selectableEntities;

        this.ProfileCollectionBool = true;
      }
      if (this.getbudgetdata.derivationEngType == 'ProfileData') {
        this.methodservice.getEventSpreadProfileColletions(this.getbudgetdata.nodeId).subscribe(
          (response) => {
            this.getEvenSpreadProfileCollection = response;
            this.ProfileCollectionBool = true;
          }
        )
      }
      

    }
    let getdarrivationallvalues: any = this.valueListDerrivation;

    if (getdarrivationallvalues.derivationOptions.length > 0) {
      let SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];

      if (SubDerrivationOptions.hasOwnProperty("items") == true) {
        this.SubDerrivationOptions = getdarrivationallvalues.derivationOptions[0];
        if (getdarrivationallvalues.derivationOptions[0].nodeType == '550130') {
          let MemoRecord = [];
          getdarrivationallvalues.derivationOptions[0].items.forEach((element, index) => {
            let memoRecordOption = {
              '_label': '',
              'NodeId': 0,
            }
            if (element == 1) {
              memoRecordOption._label = "Calendar";
              memoRecordOption.NodeId = getdarrivationallvalues.nodeId;
              MemoRecord.push(memoRecordOption);
            }
            if (element == 3) {
              memoRecordOption._label = "Calendar Working";
              memoRecordOption.NodeId = getdarrivationallvalues.nodeId;
              MemoRecord.push(memoRecordOption);
            }
          });
          getdarrivationallvalues.derivationOptions[0].items = MemoRecord;
        }
      }
    }


  }

  openEmployeForm(valuelistdata) {
    var eventgenarate: any = (<HTMLInputElement>document.getElementById('getEmployeegroup'));
    this.DisplayEmployeForm = true;
    this.EmployeeRecordList = valuelistdata;
    eventgenarate.click();
    console.log(this.displayvaluelistdata);
  }


  
  recordModified(record: recordSaveModel) {
    this.recordmodifiedModel = record;
    console.log(this.recordmodifiedModel);
  }

  RemoveDuplicationArray(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }
  
  formulaModified(formula: FormulaModel) {
    this.formulamodifiedModel = formula;
    // console.log("FormualModified save function", this.formulamodifiedModel);
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  changeMethodDarrivations(DarrivationId, data) {
    let NodeId = data.recordNodeId;
    let ValueNodeId = data.values.valuesNodeId;
    this.SpinnerService.show();
    this.methodservice.getDarrivationType(DarrivationId, ValueNodeId, NodeId).subscribe(
      async (response: any) => {
        console.log(response);
        // await this.treeview.getAllDisplayBudgetData(NodeId).subscribe(
        //   (async response => {
        //     this.SpinnerService.hide();
        //     this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        //     this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        //   })
        // )

        this.SpinnerService.hide();
        this.stockComponentComponent.ngOnChanges();
       // this.ValuelistComponent.DisplayAllCommonDataArray = response.budgetData.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        //this.ValuelistComponent.BudgetDerrivationView = response.budgetData.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      })
  }

  /***
    * Change SubDerrivationOptions dropdwon event
    * 
    * change valuelist and calucaltion
    * 
    */
  changeSubderrivationOptions($event, Values, options) {
    const arr = $event.target.value.split('-');
    let valuesNodeId = Values[0].recordNodeId
    this.SpinnerService.show();
    this.methodservice.UpdateSubDerrivationOptions(parseInt(arr[0]), parseInt(arr[1]), options.selectNodeId, valuesNodeId).subscribe(
      (response) => {
        this.SpinnerService.hide();
        // this.ValuelistComponent.DisplayAllCommonDataArray = [];
        // this.ValuelistComponent.BudgetDerrivationView = [];
        // this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        // this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
      })
  }

  /***
   * Change even spread dropdwon event
   * 
   * change valuelist and calucaltion
   * 
   */
  // changeEvenSpreaddata(eventdata, displaydata) {
  //   this.SpinnerService.show();
  //   this.methodservice.selectProfileOptions(eventdata, displaydata[0].recordNodeId, displaydata[0].values.valuesNodeId).subscribe(
  //     async (responses) => {
  //       //console.log("changeEvenSpreaddata");
  //       // localStorage.setItem("Derivationprofile", JSON.stringify(responses.profile));
  //       let nodeId = displaydata[0].recordNodeId
  //       await this.treeview.getAllDisplayBudgetData(nodeId).subscribe(
  //         (async response => {
  //           this.SpinnerService.hide();
  //           this.ValuelistComponent.DisplayAllCommonDataArray = [];
  //           this.ValuelistComponent.BudgetDerrivationView = [];
  //           this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
  //           this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
  //         })
  //       )
  //     })
  // }

  

}
