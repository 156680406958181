<div *ngIf="event!=''">
    <form  class="detailsRecord">
        <div class="row" style="height: auto;">
          <div class="col-sm-4" style="margin-top: 1%; height: 150px;">
            <div class="form-group row" >
              <label for="name" class="col-sm-3 col-form-label">
                Name
              </label>
              <div class="col-sm-9">
                <input id="name" class="form-control" (blur)="clickgetdata(event.nodeId , $event )" type="text"
                  value="{{event.name}}" >
              </div>
            </div>
            <div class="form-group row" style="margin-top: 1%;">
              <label for="name" class="col-sm-4 col-form-label">
                Description
              </label>
              <div class="col-sm-8" style="padding: 0px;margin: 0 0 0 -16px;">
                <textarea id="Description" class="form-control" type="text" (blur)="DescriptionBulrEvent(event.nodeId , $event )" value=" {{ event.description}}"
                 ></textarea>
              </div>
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 1%;">
            <div class="form-group row" >
              <label for="name" class="col-sm-4 col-form-label">
              Type
              </label>
              <div class="col-sm-8" >
              <input  type="text" class="form-control" disabled value="{{event.label}}" >
              </div>
          </div>
            <div class="form-group row" *ngIf="event.forecastViewStatus">
              <label for="name" class="col-sm-4 col-form-label">
                Status
              </label>
              <div class="col-sm-8">
                <div *ngIf="addEnumvalues!=''">
                  <select name="recordtype"
                    class="form-control" form="carform">
                    <option  *ngFor="let recordtypes of event.listForecastStatus; let i= index;" 
                    [selected]="recordtypes.value === event.selectednodeType"
                    value="{{i}}">{{recordtypes.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        <div class="col-sm-3" style="margin-top: 1%;">
        <div class="form-group row" >
            <label for="name" class="col-sm-6 col-form-label">
            Tag
            </label>
            <div class="col-sm-6" >
            <input  type="text" class="form-control" (blur)="UpdateTag(event.nodeId , $event )" value="{{event.tag}}" >
            </div>
        </div>
        <div class="form-group row" >
            <label for="name" class="col-sm-6 col-form-label">
              Additional Tag
            </label>
            <div class="col-sm-6" >
            <input  type="text" class="form-control" (blur)="UpdateAdditionalTag(event.nodeId , $event )" value="{{event.additionalTag}}" >
            </div>
        </div>
        </div>
    </div>
    </form>
</div>