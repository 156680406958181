<!-- <div class="content-wrap displaydrawer" >
  <main igxFlex 
    [ngClass]="{mainSibebarcontextMenuActive: displaySubmenustyle!='', mainSibebarcontextMenuDiplayInActive: displaySubmenustyle==''}" >
    </main>
    <igx-nav-drawer id="navigation" [pin]="true" [pinThreshold]="0" class="customthem">
      <ng-template igxDrawerMini>
        <div class="drawerSetup">
        <span igxIconButton="flat" igxToggleAction="navigation" class="togglemenudrawer" (click)="showDrawer()" >
          <igx-icon family="material" class="materialsidebarfontmenu">menu</igx-icon>
        </span>
        <span *ngFor="let item of NavitemSubmenu" class="drawerpaddingspan"  igxDrawerItem [active]="item.name === selected" igxRipple (click)="navigate(item)">
            <igx-icon family="material" class="red-tooltip" data-toggle="tooltip" data-placement="top" title="{{item.name}}" class="materialsidebarfont"><span class="materialsidebarfont">{{ item.icon }}</span></igx-icon>
        </span>
        </div>
    </ng-template>  
      <ng-template igxDrawer>
        <div class="drawerSetup">
          <span igxIconButton="flat" igxToggleAction="navigation" class="togglemenudrawer" (click)="hideDrawer()" >
            <igx-icon family="material" class="materialsidebarfontmenu">close</igx-icon>
          </span>
            <span *ngFor="let item of NavitemSubmenu" class="drawerpaddingspan" igxDrawerItem [active]="item.name === selected" igxRipple (click)="navigate(item)">
                  <igx-icon family="material" class="materialsidebarfont">{{ item.icon }}</igx-icon>
                  <span style="font-size: 16px; margin: 16px 0 0 22px;"  >
                    {{ item.name }}
                  </span>
            </span>
          </div>
      </ng-template>
    </igx-nav-drawer>
  </div> -->
  <div class="content-wrap">
      <igx-card class="settingMenucardDesign" style="margin: 20px 8px 0 0px;background: white !important;border-radius: 12px; padding-bottom: 20px;">
        <h4 style="text-align: center;margin: 0px 0 5px 0;font-size: 17px;background: #1891ff;color: white;padding: 4px;">Settings</h4>
          <span *ngFor="let item of NavitemSubmenu" class="NewSettingMenuSpan" 
                [ngClass]="{'active': selected!=null && selected.length>0 && item.name == selected[0].name}"   
                (click)="navigate(item)" style="padding: 8px;">
                <igx-icon family="material" class="materialsidebarfont">{{ item.icon }}</igx-icon>
                <span style="font-size: 14px; margin: 0px 0 0 15px;"  >
                  {{ item.name }}
                </span>
          </span>
        </igx-card>
    </div>
  