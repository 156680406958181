<div class="container newforecast">
    <h2 class="HeaderColor"> {{ this.title}} </h2>
    <form [formGroup]="createForecastForm" (ngSubmit)="onSubmit()">
        <label style="color: red;" *ngIf="currencyerror" class="col-sm-12">Country not loading..please logout and login and create forecast. </label>
        <div class="new-forecast">
            <div class="country form-group row" *ngIf="RegionalCollection!=null">
                <label class="col-sm-4 col-form-label">Country</label>
                <select name="ForecastCountry" formControlName="ForecastCountry" class="form-control col-sm-6"
                    (change)='onOptionsSelected($event)' (click)="ForecastCountyClickEvent($event)" style="color: black;">
                    <option *ngFor="let CountryCollection of RegionalCollection; let CountryIndex = index"
                        [selected]="CountryIndex==selectedCountryIndex" value="{{CountryIndex}}">{{CountryCollection.name}}</option>
                </select>
                
            </div>
            <div class="company_name form-group row">
                <label class="col-sm-4 col-form-label">Company Name</label>
                <input type="text" formControlName="company_name" class="form-control col-sm-6" style="color: black;"
                    (blur)="changecompayname_event($event)" name="company_name" />
            </div>
            <div class="Subheading_name form-group row">
                <label class="col-sm-4 col-form-label">Sub Heading</label>
                <input type="text" class="form-control col-sm-6" name="Subheading_name"
                    formControlName="Subheading_name" style="color: black;" />
            </div>
            <div class="Description form-group row">
                <label class="col-sm-4 col-form-label">Description</label>
                <textarea type="text" class="form-control Description col-sm-6" name="Description"
                    formControlName="Description" style="color: black;"></textarea>
            </div>
            <div class="start-date form-group row">
                <label class="col-sm-4 col-form-label">Number of Years</label>
                <input type="number" value="1" class="form-control col-sm-4" name="numberofyears"
                    formControlName="numberofyears" style="color: black;" />
            </div>
            <div class="start-date form-group row">
                <label class="col-sm-4 col-form-label">Start Date</label>
                <div class="form-group">
                    <div class="input-group" style="width:80%;">
                        <input id="datepicker" class="datepicker" type="" placeholder="Enter StartDate" name="startdate"
                            formControlName="startdate" class="form-control" style="margin-left: -8px;" />
                    </div>
                </div>
            </div>
            <div class="Firstdata_period form-group row">
                <label class="col-sm-4 col-form-label">First data Period</label>
                <select name="FirstdataPeriod" formControlName="FirstdataPeriod" class="form-control col-sm-4"
                    style="color: black;">
                    <option *ngFor="let data of months; let FirstdataPeriodindex=index"
                        [selected]="FirstdataPeriodindex==1" value="{{FirstdataPeriodindex}}">{{data | date:'MMM yy'}}
                    </option>
                </select>
            </div>
            <div class="LocalCurrency form-group row">
                <label class="col-sm-4 col-form-label">Local Currency</label>
                <select name="LocalCurrency" formControlName="LocalCurrency" class="form-control col-sm-4"
                    style="color: black;" (click)="getUpdatedCurrency()">
                    <option *ngFor="Currencylist of currency ; let LocalCurrencyIndex = index"
                        [selected]="LocalCurrencyIndex==0" value="{{LocalCurrencyIndex}}">
                        {{currency[LocalCurrencyIndex].name}}</option>
                </select>
                <!-- <button class="EmployeeGroupEntry form-control col-sm-4" type="button" style="font-size: 15px;background: #cccccc;"
                            (click)="CurrencyUpdateforms(CurrencyUpdateform)">Update Currency</button>
                        <igx-dialog #CurrencyUpdateform [closeOnOutsideSelect]="false" >
                            <div  class="" *ngIf="addupdatecurrency">
                                <app-updatecurrency [updatecurrency]="addupdatecurrency" [countrycollectionindex]="RegionalCountryCollectionIndex">
                                </app-updatecurrency>
                            </div>
                            <div igxDialogActions>
                                <button igxButton (click)="CurrencyUpdateform.close()"
                                    class="deleteCancelButtonColor">CANCEL</button>
                                <button igxButton class="btn btn-primary" type="button" (click)="CurrencyUpdateform.close()">Save</button>
                            </div>
                        </igx-dialog> -->
            </div>
            <div class="3rdpartyintegration">
                <div><label>3rd Party Integration</label></div>
            </div>

            <div class="row integration" style="border: 1px solid dodgerblue;margin: 0 -18px 14px -1px;">
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        checked="checked" value="0">None</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="1">SAPB1</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="2">Sage50</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="3">Sage300</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="4">MYOBEXO</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="5">Xero</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="6">AccountRight</div>
                <div class="col-sm-4"><input type="radio" class="integration" formControlName="integration"
                        value="7">Sage 200 Acumatica</div>
            </div>

            <div class="forecast_button" style="margin-top: 23%;">
                <input type="submit" [disabled]="!(RegionalCollection!=null && RegionalCollection.length > 0)"
                    [ngClass]="{'disabled': !(RegionalCollection!=null && RegionalCollection.length > 0)}" name="submit"
                    value="OK" class="newForecastSubmitButton OkSaveButtonColor" />
                <input type="button" (click)="closeForecast()" name="cancel" value="Cancel"
                    class="deleteCancelButtonColor" />
            </div>
        </div>
    </form>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
    </ngx-spinner>
</div>