import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeetotal'
})
export class EmployeetotalPipe implements PipeTransform {

  transform(value: any, args1?: any, args2?: any,): any {
    let total = 0;
    if (value.valuesArray == undefined) {
      for (let index = args1; index < args2; index++) {
        total += value[index];
      }
    } else {
      for (let index = args1; index < args2; index++) {
        total += value.valuesArray[index];
      }
    }

    // console.log(total);
    if (total != null) {
      let data = total.toString();
      if (data.charAt(0) == '-') {
        let decimalconvert = parseFloat(data).toFixed();
        return '(' + decimalconvert.substring(1, decimalconvert.length) + ')';
      } else {
        let data = total.toString();
        let decimalconvert = parseFloat(data).toFixed();
        let returnvalue = parseFloat(decimalconvert);
        let convertdecimal = returnvalue.toFixed();
        return parseFloat(data).toFixed();
      }
    } else {
      return total;
    }
  }

}
