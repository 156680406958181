<div *ngIf="isValidated">
    <div class="registrationPage">

        <div class="registration">

            <div class="register-container registration-header">

                <div class="registrationForm">
                    <div class="centerAlignText" style="    width: 100%;    height: 50%;">
                        <img src="../../../assets/FullBrandLogowebsite.png" width="150px" height="60px" alt
                            style="margin-top: 7px;">
                        <h2>Thank You</h2>
                    </div>
                    <div style="    font-size: 18px; text-align: center;">
                        <p>Your email is Validted.</p>
                        <p>Please <a href="/login">click here</a> to Login.</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<div *ngIf="!isValidated">
    <div class="registrationPage">
        <div class="registration">
            <div class="register-container registration-header">
                <div class="registrationForm">
                    <div class="centerAlignText" style="    width: 100%;    height: 50%;">
                        <img src="../../../assets/FullBrandLogowebsite.png" width="150px" height="60px" alt
                            style="margin-top: 7px;">
                    </div>
                    <div style="text-transform: none; font-size: 18px; text-align: center;">
                        <p>Something went wrong.</p>
                        <p>Please contact Forecast5 sales team at <a
                                style="color: blue;text-decoration: underline;">sales&#64;forecast5.com</a> to
                            verify your
                            email.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>