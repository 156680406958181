<div class="">
    <div class=""><h3>Profile Settings</h3></div>
    <div class="col-sm-12" style="margin-top:1%; height: 675px;" >
        
        <div class="col-sm-4 displayCurrencyList">
            <div class="">
                <button class="btn SettingSaveButtonColor col-sm-3" style="font-size: 16px;margin-bottom: 4px;margin: 0 3px 6px 0px;" (click)="AddProfile()">New</button>
                <button class="btn SettingCancelButtonColor col-sm-3" style="font-size: 16px;" 
                [ngClass]="{'disabled':!ProfileCanDelete}" (click)="DeleteProfile()">Delete</button>
                <button class="btn SettingCancelButtonColor col-sm-3" 
                [disabled]="defaultIndex==ActiveRecord"
                style="font-size: 16px;margin: 0px 0px 0px 2px;" (click)="DafaultProfile()">Default</button>
            </div>
            <table class="table table-bordered">
                <thead> 
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                </thead>
                <tbody>
                    <tr *ngFor="let Profile of ProfileCollection; let i=index;" 
                    [ngClass]="{'selected':i==ActiveRecord}" (click)="changeValueList(i)">
                        <th scope="col"><span *ngIf="i==defaultIndex">*</span></th>
                        <td [ngClass]="{'Active':i==ActiveRecord}"><input type="text" style="border:none" value="{{Profile.name}}" 
                            (blur)="ProfileSettingNameChange(i, Profile, $event)" 
                            name="ProfileSettingName[i]" class="col-sm-10" [ngClass]="{'Profilename':i==ActiveRecord}" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-6">
            <div class="bs-example" *ngIf="PeriodTabViewCollection.length>0">
                <ul class="nav nav-tabs">
                    <li class="nav-item" style="margin: 0 8px -2px -1px;">
                        <select  form="Payrool-payrate" class="form-control" style="background: #86b6d9; color: white; font-size: 12px; font-weight: bold;">
                            <option value="Annual" ng-reflect-value="Annual">Annual</option>
                            <option value="All" ng-reflect-value="All">All</option>
                    </select></li>
                    <li class="nav-item" 
                    *ngFor="let result of PeriodTabViewCollection[0].results;  let i=index" (click)="ChangePeriodType(result, i)" >
                        <a href="#{{result.nodeId}}" class="nav-link" [ngClass]="{'active':i==ActivetabView}" data-toggle="tab">{{result.longLabel}}</a>
                    </li> 
                    
                </ul>
                
                <div class="currencyValueListArray col-sm-5" *ngIf="ProfileValueList!=''">
                    <div class="col-sm-12 ValueArray" *ngIf="ProfileValueList.allowOpeningEntry == true">
                        <label class="col-sm-5">Opening</label>
                        <input class="col-sm-5" style="text-align:end" *ngIf="ProfileValueList.allowOpeningEntry" value="{{CurrencyValueList.opening}}"/>

                    </div>
                    <div class="col-sm-12 ValueArray" *ngIf="ProfileValueList.allowSetAllEntry == true">
                        <label class="col-sm-5" *ngIf="ProfileValueList.allowSetAllEntry">SetAll</label>
                        <input class="col-sm-5 form-control" style="text-align:end" 
                        (blur)="SetAllProfileList(ProfileValueList, ProfileValueList.nodeId, $event)" 
                        (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        *ngIf="ProfileValueList.allowSetAllEntry"/>
                    </div>
                    <div class="col-sm-12 display-border-after" ></div>
                    <div *ngFor="let result of ProfileValueList.valuesArray | slice:PeriodStartIndex:PeriodEndIndex+1; let i=index">
                        <div class="col-sm-12 ValueArray">
                            <label class="col-sm-5" >{{DisplayMonthCollection[i].name }}</label>
                            <input type="text" class="col-sm-5 form-control" 
                            id="{{ProfileValueList.nodeId}}-{{i}}"
                            [(ngModel)]="ProfileValueList.values[i]"
                            (click)="valuelistregrex($event)" 
                            (keypress)="isNumber($event)"
                            (keyup)="ValidateValue($event)"
                            [ngModelOptions]="{standalone: true}"
                            (blur)="MonthProfileList(ProfileValueList,$event,i)"
                             style="text-align:end"/>
                        </div>
                    </div>
                    <div class="col-sm-12 ValueArray" *ngIf="ProfileValueList.allowSetAllEntry == true">
                        <label class="col-sm-5" *ngIf="ProfileValueList.allowSetAllEntry">Total</label>
                        <input class="col-sm-5 form-control" disabled  id="total-{{ProfileValueList.nodeId}}" style="text-align:end" value="{{ProfileValueList.total}}"/>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12">
        <button class="btn button SettingSaveButtonColor col-sm-1" (click)="CalculateSave(ProfileValueList.nodeId,'save')"> Save</button>
        <button class="btn button SettingCancelButtonColor col-sm-1" style="margin: 0 0 0px 7px;" (click)="CalculateSave(ProfileValueList.nodeId,'close')"> Close</button>
    </div>
</div>