<div class="col-sm-12" *ngIf="sectionclicked"
    style="width: 100%;display: inline-block;height: 588px;padding: 0px;overflow: scroll;">

    <igx-tabs>
        <igx-tab-item *ngIf="!hideSectionView">
            <igx-tab-header style="margin: 8px 0 0 4px;;">
                <span igxTabHeaderLabel>Records</span>
            </igx-tab-header>
            <igx-tab-content>
                <div *ngIf="sectionsDisplay" style="width: 95%;">
                    <div style="margin: 10px;">
                        <button igxButton (click)="SaveRecords($event)" style="
                            padding: 9px !important;
                            font-size: 16px;
                            font-weight: 400 !important;
                            margin: 0 0 0 -9px;
                        " class="OkSaveButtonColor btnsave">
                            Save
                        </button>
                    </div>
                    <igx-tree-grid #sectionGrid id="sectionGrid" [data]="sectionTree" height="550px" primaryKey="nodeID"
                        foreignKey="parentNodeid" [batchEditing]="true" [hideRowSelectors]="hideRowSelectors"
                        [cellSelection]="selectionMode" (cellEditEnter)="handleCellEditEnter($event)"
                        (cellEditDone)="editDone($event)">
                        <igx-column field="name" [sortable]="true" width="30%" header="Record Name" [editable]="true">
                            <ng-template igxCell let-cell="cell">
                                <a (click)="handleRowSelection(cell.row,$event)">{{cell.value}}</a>
                            </ng-template>
                        </igx-column>
                        <igx-column field="nodeType" [sortable]="true" width="30%" header="Record Type"
                            [filterable]="false" [dataType]="'string'" [editable]="true">
                            <ng-template igxCell let-cell="cell">
                                {{ parseArray(cell.value) }}
                            </ng-template>
                            <ng-template igxCellEditor let-cell="cell" let-value>
                                <igx-input-group (click)="toggleDropDown($event)">
                                    <input igxInput name="drop-down-target" type="text" />
                                </igx-input-group>
                                <igx-drop-down #dropdown (selectionChanging)="select($event, cell, cell.row)"
                                    *ngIf="cell.value!='40000'">
                                    <igx-drop-down-item [igxDropDownItemNavigation]="dropdown"
                                        *ngFor="let item of SectionrecordsType"
                                        (value)="item.nodeType">{{item.recordType}}</igx-drop-down-item>
                                </igx-drop-down>
                            </ng-template>

                        </igx-column>
                        <igx-column [filterable]="false" width="130px">
                            <ng-template igxCell let-cell="cell">
                                <div class="buttonsArea">
                                    <button igxIconButton="flat"
                                        (click)="addChildRow(cell.row, cell.row.key, cell.row.index)" class="emplBtn"
                                        [igxTooltipTarget]="tooltipRefRecord">
                                        <igx-icon>add</igx-icon>
                                    </button>
                                    <button igxIconButton="flat" (click)="AddFolder(cell.row, cell.row.key, cell.row.index)"
                                        class="emplBtn" [igxTooltipTarget]="tooltipReffolder"
                                        *ngIf="cell.row.data.isFolderAllowed==='1'">
                                        <igx-icon>folder</igx-icon>

                                    </button>
                                    <button igxIconButton="flat" (click)="rowEditEnter($event, cell.row)" class="emplBtn"
                                        [igxTooltipTarget]="tooltipRefedit">
                                        <igx-icon>edit</igx-icon>
                                    </button>
                                    <button igxIconButton="flat" (click)="deleteRow(cell.row.index, $event,cell.row)"
                                        class="emplBtn" [igxTooltipTarget]="tooltipRefDelete">
                                        <igx-icon>delete</igx-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </igx-column>

                        <!-- <igx-action-strip #actionstrip>
                            <igx-grid-editing-actions [addRow]="true"
                                [addChild]="actionstrip.context?.treeRow.level < 2">
                            </igx-grid-editing-actions>
                        </igx-action-strip> -->

                    </igx-tree-grid>

                </div>
            </igx-tab-content>
        </igx-tab-item>
        <igx-tab-item>
            <igx-tab-header>
                <span igxTabHeaderLabel>Summary</span>
            </igx-tab-header>
            <igx-tab-content>
                <div *ngIf="sectionsDisplay" style="width: 99%;">
                    <table datatable [dtOptions]="dtOptions" class="row-border hover" id="customers"
                        *ngIf="sectionsDisplay[0]">
                        <thead>
                            <tr>
                                <th> Record</th>
                                <th> Record Type</th>
                                <th> Budget Data</th>
                                <th *ngFor="let month of months; let i = index;">
                                    {{month}} <div><img (click)="openadditonsum($event , i)"
                                            src="../../../assets/amount.png" class="amount-icon"></div>
                                    <div class="hidden overlaydata" id="additonalinfoshow-{{i}}">
                            <tr>
                                <div>
                                    <div><input type="checkbox" id="selectioninfosum-{{i}}">Sum</div>
                                    <div><input type="checkbox" id="selectioninfoavarage-{{i}}">Avarage</div>
                                    <div><input type="checkbox" id="selectioninfomaximum-{{i}}">Minimum</div>
                                    <div><input type="checkbox" id="selectioninfominimum-{{i}}">Maximum</div>
                                    <div><input type="checkbox" id="selectioninfocount-{{i}}">Count</div>
                                    <div class="icon-setting" style="border-top: 1px solid rgb(90, 86, 86);">
                                        <span class="glyphicon glyphicon-ok" (click)="addsumvalue($event, i)"
                                            style="width: 50%;text-align: center;"></span>
                                        <span class="glyphicon glyphicon-remove" (click)="removesumvalue($event, i)"
                                            style="width: 50%;text-align: center;"></span>
                                    </div>
                                </div>
                            </tr>
                </div>
                </th>
                <th> Total </th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sectionResult of sectionsDisplay[0]; let i = index;">
                        <td>{{sectionResult["record"]}}</td>
                        <td>{{sectionResult["record Type"]}}</td>
                        <td>{{sectionResult["budget Data"]}}</td>
                        <td *ngFor="let month of months; let itteration = index;"> {{sectionResult[itteration]}}</td>
                        <td>{{sectionResult["total"]}}</td>
                    </tr>
                </tbody>
                </table>
                <table>
                    <tr class="hidden" style="border: none;" *ngIf="sectionsDisplay[0]" id="updatedvalue">
                        <td style="color: white; border: none">{{sectionsDisplay[0][0]["record"]}}</td>
                        <td style="color: white; border: none">{{sectionsDisplay[0][0]["record Type"]}}</td>
                        <td style="color: white;border: none">{{sectionsDisplay[0][0]["budget Data"]}}</td>
                        <td *ngFor="let result of additonal_info_sum; let i = index;" style="border: none;">
                    <tr class="hidden" style="border: none;" id="sum-{{i}}">Sum : <span>{{result.addition}}</span></tr>
                    <tr class="hidden" style="border: none;" id="Avarage-{{i}}">Avarage :
                        <span>{{result.avarage}}</span>
                    </tr>
                    <tr class="hidden" style="border: none;" id="Minimum-{{i}}">Minimum : <span>{{result.min}}</span>
                    </tr>
                    <tr class="hidden" style="border: none;" id="Maximum-{{i}}">Maximum: <span>{{result.max}}</span>
                    </tr>
                    <tr class="hidden" style="border: none;" id="Count-{{i}}">Count: <span>{{result.count}}</span></tr>
                    </td>
                    <td style="border: none;"></td>
                    </tr>
                </table>
</div>
</igx-tab-content>
</igx-tab-item>
</igx-tabs>
<div class="iconTooltip" #tooltipRefRecord="tooltip" igxTooltip>
    Add Record
</div>
<div class="iconTooltip" #tooltipRefedit="tooltip" igxTooltip>
    Edit
</div>
<div class="iconTooltip" #tooltipReffolder="tooltip" igxTooltip>
    Add Folder
</div>
<div class="iconTooltip" #tooltipRefDelete="tooltip" igxTooltip>
    Delete
</div>
<div *ngIf="sectionsDisplay" style="width: 99%; display: none;">
    <table datatable [dtOptions]="dtOptions" class="row-border hover" id="customers" *ngIf="sectionsDisplay[0]">
        <thead>
            <tr>
                <th> Record</th>
                <th> Record Type</th>
                <th> Budget Data</th>
                <th *ngFor="let month of months; let i = index;">
                    {{month}} <div><img (click)="openadditonsum($event , i)" src="../../../assets/amount.png"
                            class="amount-icon"></div>
                    <div class="hidden overlaydata" id="additonalinfoshow-{{i}}">
            <tr>
                <div>
                    <div><input type="checkbox" id="selectioninfosum-{{i}}">Sum</div>
                    <div><input type="checkbox" id="selectioninfoavarage-{{i}}">Avarage</div>
                    <div><input type="checkbox" id="selectioninfomaximum-{{i}}">Minimum</div>
                    <div><input type="checkbox" id="selectioninfominimum-{{i}}">Maximum</div>
                    <div><input type="checkbox" id="selectioninfocount-{{i}}">Count</div>
                    <div class="icon-setting" style="border-top: 1px solid rgb(90, 86, 86);">
                        <span class="glyphicon glyphicon-ok" (click)="addsumvalue($event, i)"
                            style="width: 50%;text-align: center;"></span>
                        <span class="glyphicon glyphicon-remove" (click)="removesumvalue($event, i)"
                            style="width: 50%;text-align: center;"></span>
                    </div>
                </div>
            </tr>
</div>
</th>
<th> Total </th>
</tr>
</thead>
<tbody>
    <tr *ngFor="let sectionResult of sectionsDisplay[0]; let i = index;">
        <td>{{sectionResult["record"]}}</td>
        <td>{{sectionResult["record Type"]}}</td>
        <td>{{sectionResult["budget Data"]}}</td>
        <td *ngFor="let month of months; let itteration = index;"> {{sectionResult[itteration]}}</td>
        <td>{{sectionResult["total"]}}</td>
    </tr>
</tbody>
</table>
<table>
    <tr class="hidden" style="border: none;" *ngIf="sectionsDisplay[0]" id="updatedvalue">
        <td style="color: white; border: none">{{sectionsDisplay[0][0]["record"]}}</td>
        <td style="color: white; border: none">{{sectionsDisplay[0][0]["record Type"]}}</td>
        <td style="color: white;border: none">{{sectionsDisplay[0][0]["budget Data"]}}</td>
        <td *ngFor="let result of additonal_info_sum; let i = index;" style="border: none;">
    <tr class="hidden" style="border: none;" id="sum-{{i}}">Sum : <span>{{result.addition}}</span></tr>
    <tr class="hidden" style="border: none;" id="Avarage-{{i}}">Avarage : <span>{{result.avarage}}</span></tr>
    <tr class="hidden" style="border: none;" id="Minimum-{{i}}">Minimum : <span>{{result.min}}</span></tr>
    <tr class="hidden" style="border: none;" id="Maximum-{{i}}">Maximum: <span>{{result.max}}</span></tr>
    <tr class="hidden" style="border: none;" id="Count-{{i}}">Count: <span>{{result.count}}</span></tr>
    </td>
    <td style="border: none;"></td>
    </tr>
</table>
</div>
<div *ngIf="sectionsDisplay" style="width: 125%; display: none;">
    <button igxButton (click)="beginAddRow($event)">
        Add record
    </button>
    <button igxButton (click)="SaveRecords($event)">
        Save
    </button>
    <igx-grid #sectionGrid id="sectionGrid" [data]="sectionsDisplay[0]" [primaryKey]="'rowNum'" height="600px"
        [cellSelection]="'none'" [rowSelection]="selectionMode" [selectedRows]="selectedRows"
        [hideRowSelectors]="hideRowSelectors" [rowEditable]="true" (rowEditEnter)="editStart($event)"
        (onRowAdded)="rowAdded($event)" (rowSelectionChanging)="handleRowSelection(row,$event)">
        <igx-paginator [perPage]="25"></igx-paginator>
        <igx-column field="record" [sortable]="true" header="Record Name"></igx-column>
        <igx-column field="record Type" [sortable]="true" header="Record Type" [filterable]="false"></igx-column>
        <igx-column field="budget Data" [sortable]="true" header="Budget Data"></igx-column>
        <igx-column field="0" [header]="months[0]" width="7%"></igx-column>
        <igx-column field="1" [header]="months[1]" width="7%"></igx-column>
        <igx-column field="2" [header]="months[2]" width="7%"></igx-column>
        <igx-column field="3" [header]="months[3]" width="7%"></igx-column>
        <igx-column field="4" [header]="months[4]" width="7%"></igx-column>
        <igx-column field="5" [header]="months[5]" width="7%"></igx-column>
        <igx-column field="6" [header]="months[6]" width="7%"></igx-column>
        <igx-column field="7" [header]="months[7]" width="7%"></igx-column>
        <igx-column field="8" [header]="months[8]" width="7%"></igx-column>
        <igx-column field="9" [header]="months[9]" width="7%"></igx-column>
        <igx-column field="10" [header]="months[10]" width="7%"></igx-column>
        <igx-column field="11" [header]="months[11]" width="7%"></igx-column>
    </igx-grid>

</div>
</div>
<!-- <div class="col-sm-2" *ngIf="sectionclicked" style="width: 6%;display: inline-block;z-index: 100;margin: 0 0 0 2%;">
    <app-rightsidebar-menu></app-rightsidebar-menu>
</div> -->