<igx-content>
    <div *ngIf="ValidDerivationSubTypes.length>0 && ValidDerivationSubTypesName.length>0">
        <select name="dropdown-option" class="form-control" style="width: auto;font-size: 15px;margin-top: 4px;"
            (change)="changeSubderrivationType($event, displayvaluelistdata ,ValidDerivationSubTypes)">
            <option *ngFor="let result of ValidDerivationSubTypes; let i = index" value="{{result}}"
                [selected]="result == derivationSubType"> {{ ValidDerivationSubTypesName[i]}}
            </option>
        </select>
    </div>
    <div *ngIf="displayvaluelistdata">
        <div *ngIf="displayvaluelistdata.nodeTypeEnglishLabel=='CapitalPaidNextYear'"
            class="{{displayvaluelistdata.nodeTypeEnglishLabel}}">
            <div class="defualtdisplay">
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Interest Type</label>
                    <div class="col-sm-7">
                        <select name="Intersttype" class="selectloantakencss"
                            (change)="changeInterestType($event.target.value, CalculatedloanTakenRecord)">
                            <option
                                *ngFor="let InterstResult of CalculatedloanTakenRecord.interestTypeCollection ; let i = index"
                                [selected]="InterstResult === CalculatedloanTakenRecord.interestTypes"
                                value="{{InterstResult}}">{{enumInterestTypes[0][i+1]}}</option>
                        </select>
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Payment Frequcncy</label>
                    <div class="col-sm-7">
                        <select name="paymentFrequcncy" class="selectloantakencss"
                            (change)="changepaymentFrequcncy($event.target.value, CalculatedloanTakenRecord)">
                            <option
                                *ngFor="let paymentFrequencieResult of CalculatedloanTakenRecord.paymentFrequencieCollection"
                                [selected]="paymentFrequencieResult.numberFormatPrefix === CalculatedloanTakenRecord.paymentFrequencies.numberFormatPrefix">
                                {{paymentFrequencieResult.numberFormatPrefix}}</option>
                        </select>
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Payment Timing</label>
                    <div class="col-sm-7">
                        <select name="paymentTiming" class="selectloantakencss"
                            (change)="changepaymentTiming($event.target.value, CalculatedloanTakenRecord)">
                            <option
                                *ngFor="let paymentTimingsResult of CalculatedloanTakenRecord.paymentTimingsCollection"
                                [selected]="paymentTimingsResult === CalculatedloanTakenRecord.paymentTimings"
                                value="{{paymentTimingsResult}}">{{enumpaymentTiming[0][paymentTimingsResult]}}</option>
                        </select>
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <div class="col-sm-12">
                        <label class="col-sm-7 ">New loan</label>
                        <input [checked]="CalculatedloanTakenRecord.isNewLoan"
                            (change)="IsnewLoanchangeEvent($event,CalculatedloanTakenRecord)" type="checkbox"
                            name="IsNewLoan" class="col-sm-2 ">
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-periodStart"
                    [ngClass]="CalculatedloanTakenRecord.isNewLoan == false ? 'hidden' : ''">
                    <label class="col-sm-7 ">Period Start</label>
                    <div class="col-sm-7">
                        <select name="pariodstart" class="selectloantakencss">
                            <option *ngFor="let month of months">{{month}}</option>
                        </select>
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Term (Months)</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeTerm($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.term | number }}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Loan Balance</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeLoanBalance($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.loanPrincipal | number}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Interest Rate</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeInterestRate($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.interestRate | number}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <label class="col-sm-7 ">Payment</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changePayment($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.payment | number}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord"
                    [ngClass]="CalculatedloanTakenRecord.showDeposit == true ? '' : 'hidden' ">
                    <div class="col-sm-2">
                        <input [checked]="CalculatedloanTakenRecord.hasDepositPayment"
                            [disabled]="!CalculatedloanTakenRecord.canHaveDepositPayment" type="checkbox"
                            class=" col-sm-11" />
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-4 ">Deposit</label>
                    </div>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeDeposite($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.depositPayment | number}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord">
                    <div class="col-sm-12">
                        <label class="col-sm-7 ">Bullet</label>
                        <input type="checkbox" [checked]="CalculatedloanTakenRecord.hasBulletPayment"
                            [disabled]="!CalculatedloanTakenRecord.canHaveBulletPayment"
                            (change)="OnchangeBullet($event,CalculatedloanTakenRecord)" class=" col-sm-2"
                            name="Isbullet" />
                    </div>
                </div>

                <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-BulletPayment"
                    [ngClass]="CalculatedloanTakenRecord.hasBulletPayment == false ? 'hidden' : ''">
                    <label class="col-sm-7 ">Bullet Payment</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeBulletPayment($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.bulletPayment | number}}" />
                    </div>
                </div>
                <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-PaymentMonth"
                    [ngClass]="CalculatedloanTakenRecord.hasBulletPayment == false ? 'hidden' : ''">
                    <label class="col-sm-7 ">Payment(Months)</label>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeBulletPaymentMonth($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.monthsHoliday | number}}" />
                    </div>
                </div>

                <div class="row valuelistdisplayrow loantakenrecord">
                    <div class="col-sm-7">
                        <input [checked]="CalculatedloanTakenRecord.hasBulletPayment"
                            [disabled]="!CalculatedloanTakenRecord.canHaveBalloonPayment" type="checkbox"
                            class=" col-sm-2" style="margin-top: 8px;" />
                        <label class="col-sm-8">Baloon</label>

                    </div>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeBaloon($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.balloonPayment | number}}" />
                    </div>
                </div>

                <div class="row valuelistdisplayrow loantakenrecord">
                    <div class="col-sm-7">
                        <input [checked]="CalculatedloanTakenRecord.hasHolidayPeriod"
                            [disabled]="!CalculatedloanTakenRecord.canHaveHolidayPeriod" type="checkbox"
                            class=" col-sm-2" style="margin-top: 8px;" />
                        <label class="col-sm-8">Holiday(Months)</label>
                    </div>
                    <div class="col-sm-7">
                        <input type="text" class=" col-sm-11"
                            (blur)="changeholidaymonthText($event.target.value, CalculatedloanTakenRecord)"
                            value="{{CalculatedloanTakenRecord.monthsHoliday | number}}" />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="displayvaluelistdata.nodeTypeEnglishLabel != 'CapitalPaidNextYear'">
            <form>
                <div *ngFor="let display of displaydarrivation">
                    <div class="defualtdisplay" *ngIf="display.inputs.length == 0">
                        <div class="valuelistdisplayrow"
                            [ngClass]="{'valuelistdisplayrow': display.targetNodeID == '',  'row valuelistdisplayrow': display.targetNodeID !='' }">
                            <div class="row" *ngIf="addeddata">
                                <label class="display-title" style="font-size: 14px;">{{ display.englishLabel}}</label>
                                <div class="display-border-after-setall"></div>
                                <app-sectiontree [sectiontreeview]="sectiontreeview" [WhatIf]="false"
                                    [selectedNodeIds]="selectedNodeIds" [CalculateAsBalance]="DisplayCalculateAsBalance"
                                    (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                            </div>
                            <div class="row">
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                    <div class="testingdata-{{i}}"
                                        *ngFor="let result of displayvaluelistdata;let i = index">
                                        <div *ngIf="DerivationOptions">
                                            <div class="valuelistdisplayrow derrivationdisplaydiv"
                                                *ngIf="DerivationOptions.localizedLabel">
                                                <div class="col-sm-12" *ngIf="DerivationOptions.optionType == 550029">
                                                    <div *ngIf="DerivationOptions.optionType == 550029">
                                                        <div class="col-sm-3">
                                                            <input type="checkbox" class=" col-sm-11"
                                                                [checked]="DerivationOptions.isSelected"
                                                                (change)="CalculateAsBalance(DerivationOptions, $event)" />
                                                        </div>
                                                        <label class=" col-sm-9"
                                                            style="font-size: 14px;">{{DerivationOptions.localizedLabel}}</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        *ngIf="DerivationOptions.localizedLabel !='Calculate As Balance'">
                                                        <label
                                                            class="col-sm-5 ">{{DerivationOptions.localizedLabel}}</label>
                                                        <div class="col-sm-7"><input
                                                                style="text-align: right; padding:1px;"
                                                                class=" col-sm-11 {{result.values.valuesNodeId}}-percentage"
                                                                (blur)="noDarrivationPercentage(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                                value="{{DerivationOptions.storedValue*100}}"
                                                                allow-decimal="true" allow-negative="true"
                                                                type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div _ngcontent-jck-c424="" class="col-sm-4"><label class="display-title"
                                                    style="font-size: 14px; color: white;">Net Sales</label></div>
                                            <div _ngcontent-jck-c424="" class="col-sm-8"><label class="display-title"
                                                    style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="result.values.allowSetAllEntry">
                                            <!-- <div class="display-border-after-setall"></div>  -->
                                            <div class="row" style="height: 30px;">
                                                <label class="col-sm-5 ">Set All</label>
                                                <div class="col-sm-7"><input decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall"
                                                        (blur)="noDarivationsetall(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        allow-decimal="true" allow-negative="true" type="text"
                                                        disabled=disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="result.values.allowOpeningEntry">
                                            <div class="row">
                                                <label class="col-sm-5 ">Opening</label>
                                                <div class="col-sm-7"><input id="{{result.opening}}"
                                                        decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        (blur)="noDarivationopening(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        allow-decimal="true" allow-negative="true"
                                                        value="{{result.opening}}" type="text" disabled=disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-after-setall"></div>
                                        <div class="valuelistdisplayrow"
                                            *ngFor="let valuearr of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index ; trackBy:trackByFn">
                                            <div class="row">
                                                <label class="col-sm-5 "
                                                    [style.font-weight]="ValueListMonthDisplay[i+PeriodTabViewStartIndex].fontWight">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                <div class="col-sm-7" *ngIf=" result.currentActualsIndex==-1">
                                                    <!-- <input *ngIf="result.allowValueEntry" type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)" /> -->
                                                    <input type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} withoutActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}} [disabled]="true"
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)" />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowValueEntry && result.currentActualsIndex!=-1">
                                                    <!-- <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == true"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)" /> -->
                                                    <input type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled=disabled />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                    <!-- <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == true"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)" /> -->
                                                    <input type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled=disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div>
                                        <div class="valuelistdisplayrow" *ngIf="result.values.hasTotal">
                                            <div class="row">
                                                <label class="col-sm-5">Total</label>
                                                <div class="col-sm-7" *ngIf="result.allowTotalEntry">
                                                    <input type="text" id="{{result.values.valuesNodeId}}-total"
                                                        class=" col-sm-11 abcd " style="text-align: right; padding:1px;"
                                                        value="{{result.total | minussignsparen}}"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" (disabled)="true"
                                                        (blur)="noDarivationtotal(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        decimals={{result.decimalPlaces}} disabled=disabled />
                                                </div>
                                                <!-- <label id="{{result.values.valuesNodeId}}-total" class=" col-sm-5"
                                                    style="text-align: right; padding:1px;"
                                                    value="{{result.total | minussignsparen}}"> {{result.total |
                                                    minussignsparen}}</label> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel == 'TotalAdditions'">
                                    <div class="col" *ngFor="let result of displayvaluelistdata;let i = index">
                                        <label class="display-title" style="font-size: 14px;">{{
                                            displayvaluelistdata[0].englishLabel}}</label>
                                        <div class="display-border-after-setall"></div>
                                        <div class="row valuelistdisplayrow" class="row valuelistdisplayrow"
                                            *ngFor="let defualtresult of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index">
                                            <div class="col">
                                                <label class="col-sm-5 "
                                                    [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                <label class="col-sm-5 " style="text-align: right; padding:1px;"
                                                    [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{defualtresult
                                                    * (i+1) }}</label>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="displaywithdarrivation" *ngIf="display.inputs.length> 0">
                        <div class="row valuelistdisplayrow">
                            <div class="col-sm-4 {{addeddata}}" *ngIf="addeddata">
                                <label class="display-title" style="font-size: 14px;"
                                    *ngIf="displayvaluelistdata[0].englishLabel">{{
                                    displayvaluelistdata[0].englishLabel}}</label>
                                <div class="montharrayheader"></div>
                                <div class="display-border-after-setall"></div>
                                <app-sectiontree [sectiontreeview]="sectiontreeview" [selectedNodeIds]="selectedNodeIds"
                                    [WhatIf]="false" [CalculateAsBalance]="DisplayCalculateAsBalance"
                                    (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                            </div>
                            <div [ngClass]="
                            {'col': displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals',  
                            'col-sm-3' : displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals'
                             && displayvaluelistdata[0].derivationEngType != 'CalculateOverdraftInterest'
                             && displayvaluelistdata[0].derivationEngType != 'FromStockOnHand' ,
                             'col-sm-2':displayindex != 0 && displayvaluelistdata[0].derivationEngType == 'FromStockOnHand',
                            'col':displayvaluelistdata[0].derivationEngType == 'ProfileData' || displayvaluelistdata[0].derivationEngType == 'CalculateOverdraftInterest',
                            'col-sm-5':inputarrayloop.customenglishname == 'EvenSpread' }"
                                class="custom-{{inputarrayloop.customenglishname}}"
                                *ngFor="let inputarrayloop of display.inputs;let i = index; let displayindex = index">
                                <div class="row"
                                    [ngClass]="{titleheader:displayvaluelistdata[0].englishLabel == 'Bank Overdraft Interest'}"
                                    *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals'">
                                    <div class="col-sm-4">
                                        <label class="display-title"
                                            [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                            style="font-size: 14px; color: white; position: absolute; display: none;">
                                            {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                        </label>
                                    </div>
                                    <div class="col-sm-8">
                                        <label class="display-title"
                                            [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                            style="font-size: 14px;text-align: left;">
                                            {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                        </label>
                                    </div>
                                </div>
                                <label class="display-title"
                                    *ngIf="displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals'"
                                    style="font-size: 14px;">{{inputarrayloop.englishLabel}}
                                    {{display.derivationEngType}}</label>
                                <div class="montharrayheader">
                                    <div *ngIf="inputarrayloop.allowValueEntry == true">
                                        <div class="row valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="inputarrayloop.allowSetAllEntry">
                                            <label class="col-sm-5"
                                                *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals' ">Set
                                                All</label>
                                            <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0}">
                                                <input
                                                    (blur)="darrivationsetall(inputarrayloop.englishLabel, displayvaluelistdata , $event , inputarrayloop , 1)"
                                                    decimals="{{inputarrayloop.decimalPlaces}}"
                                                    id="{{inputarrayloop.englishLabel}}-setall-{{i}}"
                                                    style="text-align: right; padding:1px;"
                                                    (keypress)="LimitOnly($event, inputarrayloop.limitNumber)"
                                                    (keyup)="ValidateValue($event)" class=" col-sm-11" disabled=disabled
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div class="row valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="inputarrayloop.allowOpeningEntry">
                                            <label class="col-sm-5" *ngIf="displayindex == 0">Opening</label>
                                            <div class="col-sm-7"><input decimals="{{inputarrayloop.decimalPlaces}}"
                                                    id="{{inputarrayloop.englishLabel}}-setall-{{i}}  {{inputarrayloop.opening}}"
                                                    style="text-align: right; padding:1px;" class=" col-sm-11"
                                                    value="{{inputarrayloop.opening}}" type="text" disabled=disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="display-border-after-setall"></div>
                                <div class="display-border">
                                    <div class="row" *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">
                                        <label class="col-sm-5"
                                            *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">20-21</label>
                                        <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex ==-1">
                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                class=" col-sm-11 {{inputarrayloop.englishLabel}}" type="text"
                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                [(ngModel)]="inputarrayloop.valuesArray[1]"
                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                (click)="valuelistregrex($event)"
                                                style="text-align: right; padding:1px;"
                                                [ngModelOptions]="{standalone: true}" disabled=disabled />
                                        </div>
                                        <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex !=-1">
                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} {{inputarrayloop.currentActualsIndex}}"
                                                type="text" disabled=disabled
                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                [(ngModel)]="inputarrayloop.valuesArray[1]"
                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                (click)="valuelistregrex($event)"
                                                style="text-align: right; padding:1px;"
                                                [ngModelOptions]="{standalone: true}" disabled=disabled />
                                        </div>

                                    </div>
                                    <div *ngIf="inputarrayloop.englishLabel != 'Cycle Totals'">
                                        <div class="MonthArrayClass">
                                            <div *ngIf="inputarrayloop.englishLabel =='Payment Profile'">
                                                <div class="row valuelistdisplayrow"
                                                    *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:0:12; let i = index; trackBy:trackByFn">
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                        inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                            [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                            type="text"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" disabled=disabled />
                                                        <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                    </div>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} latestData"
                                                            type="text"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" disabled=disabled />
                                                        <label
                                                            *ngIf="inputarrayloop.allowValueEntry==false">{{valuearrayresult}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="inputarrayloop.englishLabel !='Payment Profile'">
                                                <div class="row valuelistdisplayrow"
                                                    *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index; trackBy:trackByFn">
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel!='Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                        inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                    <label class="col-sm-5"
                                                        *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">20-21</label>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                            [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                            type="text"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" disabled=disabled />
                                                        <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                    </div>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} latestData"
                                                            type="text"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i+PeriodTabViewStartIndex}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i+PeriodTabViewStartIndex]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" disabled="disabled" />
                                                        <label
                                                            *ngIf="inputarrayloop.allowValueEntry==false">{{valuearrayresult}}</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="display-border-before-total"></div>
                                        <div class="row valuelistdisplayrow setalldisplaydiv">
                                            <label class="col-sm-5" *ngIf="displayindex == 0">Total</label>
                                            <div class="col-sm-7"
                                                *ngIf="inputarrayloop.hasTotal && inputarrayloop.allowValueEntry">
                                                <label class=" col-sm-11"
                                                    *ngIf="inputarrayloop.allowValueEntry == false">{{inputarrayloop.total}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 displayvaluedisbaled-{{displayvaluelistdata[0].nodeTypeEnglishLabel}}"
                                *ngFor="let result of displayvaluelistdata;let i = index">
                                <label class="display-title" style="font-size: 14px;">{{
                                    displayvaluelistdata[0].englishLabel}}</label>
                                <div class="row valuelistdisplayrow setalldisplaydiv"></div>
                                <div class="display-border-after-setall"></div>
                                <div class="MonthArrayClass">
                                    <div class="row valuelistdisplayrow"
                                        *ngFor="let defualtresult of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index">
                                        <div *ngIf="result.values.currentActualsIndex==-1" class="col">
                                            <input type="text" disabled=disabled value="{{defualtresult}}"
                                                class=" thirdsectionNoActual col-sm-11">
                                        </div>
                                        <div class="col" *ngIf="result.values.currentActualsIndex!=-1">
                                            <input *ngIf="IsEnabled(i+PeriodTabViewStartIndex,result.values) == true"
                                                class=" col-sm-11 {{result.values.englishLabel}}" type="text"
                                                [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                id="{{result.values.englishLabel}}-months-{{i+PeriodTabViewStartIndex}}"
                                                [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                [ngClass]="{'ActualModeEnable': IsEnabled(i+PeriodTabViewStartIndex,result.values) == false && FontWight(i+PeriodTabViewStartIndex, result.values)=='Normal'}"
                                                disabled=disabled
                                                (blur)="derrivationmonthCalculation(result.values.englishLabel, result.values , $event , displayvaluelistdata , 1)"
                                                (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                (keyup)="ValidateValue($event)" style="text-align: right; padding:1px;"
                                                [ngModelOptions]="{standalone: true}" />
                                            <input type="text"
                                                *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == false"
                                                disabled=disabled class=" col-sm-11 thirdsection"
                                                style="text-align: right; padding:1px;"
                                                [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                value="{{defualtresult}}" />
                                        </div>
                                        <div class="display-border-after-setall row"></div>
                                    </div>
                                </div>
                                <div class="display-border-before-total"></div>
                                <div class="row valuelistdisplayrow">
                                    <div class="col">
                                        <input type="text" disabled=disabled class=" col-sm-11"
                                            value="{{displayvaluelistdata[0].total | minussignsparen}}"
                                            style="text-align: right; padding:1px;" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>
</igx-content>