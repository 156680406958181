<app-header></app-header>
<div class="col-sm-12" style="padding: 0px;">
  <div class="col-sm-2" style="background: rgb(168 168 183 / 27%);height: 92vh;padding: 0 0 0;border-right: 3px solid gray;margin: 0 0 0 -13px;">
    <div class="col-sm-12" style="margin-top: 30px;">
      <igx-card style="background: white !important;margin: 0 6px 6px 4px;border-radius: 12px; height: 250px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;">Opening Options</h4>
        <div class="col-sm-12" style="padding: 0px; margin-top: 4%;">
          
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">print</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="printOpeningBalance()">Print</button>
            </span>
          </div>

          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">publish</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="exportCSV()">Export</button>
            </span>
          </div>

          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">file_download</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="openImportFile()">Import</button>
            </span>
          </div>
          <input type="file" style="display:none" id="EnterOpeningImport" (change)="handle($event)">

          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">restart_alt</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="OpeningReset()" >Reset</button>
            </span>
          </div>
          <div class="col-sm-12" style="padding: 8px;font-size: 14px;">
            <igx-icon style="font-size:20px;" class="col-sm-2">add_chart</igx-icon>
            <span  class="col-sm-10">
              <button type="button" (click)="calculateTotal()"> Calculate
              Total</button>
            </span>
          </div>
            <!-- <div class="enteropeningbalance col" (click)="printOpeningBalance()" hideDelay="100"
            [igxTooltipTarget]="tooltipPrint">
            <div #tooltipPrint="tooltip" igxTooltip >Print</div><span class="openingText"
              >Print</span>
          </div>
          <div class="enteropeningbalance col" (click)="exportCSV()" hideDelay="100"
            [igxTooltipTarget]="tooltipExport">
            <div #tooltipExport="tooltip" igxTooltip >Export</div><span class="openingText"
              >Export</span>
          </div> -->
          <!-- <div class="enteropeningbalance col" (click)="openImportFile()" hideDelay="100" 
            [igxTooltipTarget]="tooltipImport">
            <div #tooltipImport="tooltip" igxTooltip >Import</div><span class="openingText" 
              >Import</span>
          </div> -->
          <!-- <input type="file" style="display:none" id="EnterOpeningImport" (change)="handle($event)">
          <div class="enteropeningbalance col">
            <button type="button" (click)="OpeningReset()" 
            style="border: none !important;background: dodgerblue;color: white;width: 73%;height: 30px;margin: 4px 0px 0 28px;font-size: 15px;font-weight: 600;" class="openingText">Reset</button>
          </div> -->
          <!-- <div class="enteropeningbalance col">
            <button type="button" (click)="calculateTotal()" class="openingText" 
            style="border: none !important;background: dodgerblue;color: white;width: 73%;height: 30px;margin: 4px 0px 0 28px;font-size: 15px;font-weight: 600;"> Calculate Total</button>
          </div> -->
        </div>
      </igx-card>
      <igx-card style="background: white !important;margin: 10px 6px 6px 4px;border-radius: 12px; height: 200px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;">Opening Integration</h4>
        <div style="margin: 3px 0 0 0; width:100%;">
         
          <div style="margin-left: 10%;">
            <div class="input-group EnterActualMonth">
              <input id="datepickerActual" class="datepickerActual " type="" placeholder="Enter StartDate"
                name="startdate" class="form-control" />
            </div>
            <div>
              <select class="form-controll EnterActualMonth" (change)="selectRounding($event)">
                <option *ngFor="let result of rounding let dataindex = index" value="{{result}}">{{result}}</option>
              </select>
            </div>
            <div>
              <button class="btn btn-primary EnterActualMonth col" style="margin-bottom: 20px;"
                (click)="ImportFromIntegration()">Import</button>
            </div>
          </div>
        </div>
      </igx-card>

      <igx-card style="background: white !important;margin: 10px 6px 6px 4px;border-radius: 12px; height: 120px;">
        <h4 style="text-align: center;padding: 4px 0 3px 0;font-size: 16px;background: #1891ff;color: white;"></h4>
        <div class="enteropeningbalance" style="margin-bottom: 6px;">
          <button type="button btn btn-primary col" (click)="saveEnterOpening()" style="margin-bottom: 20px;"
            style="border: none !important;background: dodgerblue;color: white;width: 73%;height: 30px;margin: 4px 0px 0 28px;font-size: 15px;font-weight: 600;">Save</button>
        </div>
        <div class="enteropeningbalance " style="margin-bottom: 20px;">
          <button type="button btn btn-primary col" (click)="saveEnterOpening()" style="margin-bottom: 20px;"
            style="border: none !important; background: #ea7704; color: white; width: 73%; height: 30px; margin: 4px 0px 0 28px; font-size: 15px; font-weight: 600;">Cancel</button>
        </div>
      </igx-card>
       
      
    </div>
    
    <!-- <div class="" style="height: 720px;width: 96.5%;">
      <div class="enteropeningbalance col" (click)="printOpeningBalance()" hideDelay="100"
        [igxTooltipTarget]="tooltipPrint">
        <div #tooltipPrint="tooltip" igxTooltip style="margin:0px 8px 0px 10px">Print</div><span class=""
          style="color: white;">Print</span>
      </div>
      <div class="enteropeningbalance col" (click)="exportCSV()" hideDelay="100"
        [igxTooltipTarget]="tooltipExport">
        <div #tooltipExport="tooltip" igxTooltip style="margin:0px 8px 0px 10px">Export</div><span class=""
          style="color: white;">Export</span>
      </div>
      <div class="enteropeningbalance col" (click)="openImportFile()" hideDelay="100"
        [igxTooltipTarget]="tooltipImport">
        <div #tooltipImport="tooltip" igxTooltip style="margin:0px 8px 0px 10px">Import</div><span
          style="color: white;">Import</span>
      </div>
      <input type="file" style="display:none" id="EnterOpeningImport" (change)="handle($event)">
      <div class="enteropeningbalance col">
        <button type="button" (click)="OpeningReset()"
          style="border: none !important; background: dodgerblue;color: white;">Reset</button>
      </div>
      <div class="enteropeningbalance col">
        <button type="button" (click)="calculateTotal()"
          style="border: none !important; background: dodgerblue;color: white;"> Calculate Total</button>
      </div>
      
      <div style="border: 1px solid;margin: 3px 0 0 0; width:100%;">
        <label>
          <h4 style="margin-top: 20px;color: black;font-size: 22px;padding: 5px;">Integration</h4>
        </label>
        <div style="margin-left: 10%;">
          <div class="input-group EnterActualMonth">
            <input id="datepickerActual" class="datepickerActual " type="" placeholder="Enter StartDate"
              name="startdate" class="form-control" />
          </div>
          <div>
            <select class="form-controll EnterActualMonth" (change)="selectRounding($event)">
              <option *ngFor="let result of rounding let dataindex = index" value="{{result}}">{{result}}</option>
            </select>
          </div>
          <div>
            <button class="btn btn-primary EnterActualMonth col" style="margin-bottom: 20px;"
              (click)="ImportFromIntegration()">Import</button>
          </div>
        </div>
      </div>

      <div class="enteropeningbalance col">
        <button type="button" (click)="saveEnterOpening()"
          style="border: none !important; background: dodgerblue;color: white;">Save</button>
      </div>
      <div class="enteropeningbalance col">
        <button type="button" (click)="saveEnterOpening()"
          style="border: none !important; background: dodgerblue;color: white;">Cancel</button>
      </div>
    </div> -->
  </div>
  <div class="col-sm-10">
    <app-rightsidebar-menu></app-rightsidebar-menu>
    <div class="forecast-tabs">
      <igx-content>
        <div>
          <div class="row" style="margin-top:10px;">
            <div _ngcontent-bhk-c375="" class="col-sm-10" >
              <div class="left-sidebar col-sm-9" style="height: 814px;width: 96.5%;border: 1px solid #9ca3c1;; padding: 0px;">
                <igx-card>
                  <h3 style="text-align: center;padding: 4px 0 5px 0;font-size: 16px;background: #1891ff;color: white;border-radius: 0px;margin: -1px -2px -1px 0px;">Opening Balance Sheet</h3>
                </igx-card>
                <div class="" style="padding: 10px;">
                  <ul class="nav nav-tabs">
                    <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#BalanceSheet">Balance Sheet</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div id="BalanceSheet" class="tab-pane fade in show" style="margin: 0px 0 0 0;">
                      <div class="row" style="height: 720px;overflow: auto; width: 100%;">
                        <div class="left-sidebar col-sm-12">
                          <table id="table" *ngIf="DefualtBalanceopeningEntry" style="width: 100%;">
                            <app-treeopening [Openingtreeview]='Openingtreeview'></app-treeopening>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
              </div>
             
            </div>
    
          </div>
        </div>
        <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
          <button class="AutoBalanceResponse form-control hide" id="AutoBalanceResponse"
            style="font-size: 15px;background: #cccccc;" (click)="AutoBalanceResponse.open()">Auto Balance</button>
          <igx-dialog #AutoBalanceResponse [closeOnOutsideSelect]="false">
            <div class="insidecontainer" style="width: 465px;height: 100px;">
              <igx-dialog-title class="dialogtitle">
                <div class="title-container">
                  <div class="dialog-title">Auto Balancing</div>
                </div>
              </igx-dialog-title>
              <div class="Department row">
                <label class="col">{{this.message}}</label>
              </div>
            </div>
            <div igxDialogActions>
              <button igxButton (click)="this.AutoBalanceClosing()" class="deleteCancelButtonColor">No</button>
              <button igxButton class="OkSaveButtonColor" (click)="this.AutoBalancing()">Yes</button>
            </div>
          </igx-dialog>
        </div>
      </igx-content>
  </div>
</div>
<app-footer></app-footer>

