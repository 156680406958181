import { Component, OnInit, Input } from '@angular/core';
import { EmployeeGroupService } from 'src/app/service/EmployeeGroupService/employee-group.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { recordSaveModel, budgetdatamodified, valuelistModified } from 'src/app/model/recordSaveModel';
import { MethodserviceService } from '../../record/method/service/methodservice.service';

@Component({
  selector: 'app-bonuses',
  templateUrl: './bonuses.component.html',
  styleUrls: ['./bonuses.component.scss']
})
export class BonusesComponent {

  @Input() BonusesDetails;

  MonthDisplayView = [];
  PeriodTabView: any = [];
  getBonusesCollection: any = [];
  getCumulativeBonuses: any = [];
  BonusesDisplayBudgetResults = [];
  CumulativeBonusesDisplayBudgetResults = []
  Activetabmenu = 0;
  recordmodifiedModel: recordSaveModel;

  constructor(private employeeGroupService: EmployeeGroupService,
    private methodserviceService:MethodserviceService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnChanges() {
    let details = this.BonusesDetails;
    this.MonthDisplayView = [];
    this.getBonusesCollection = [];
    this.getCumulativeBonuses = [];
    this.BonusesDisplayBudgetResults = [];
    this.CumulativeBonusesDisplayBudgetResults = [];
    if (this.BonusesDetails[0].nodeId != '') {
      let nodeId = this.BonusesDetails[0].nodeId
      this.SpinnerService.show();
      this.employeeGroupService.getBonusesCollections(nodeId).subscribe(
        ((response: any) => {
          this.SpinnerService.hide()
          let DisplayAllBonusCollectionValue: any = response;
          this.getBonusesCollection = DisplayAllBonusCollectionValue.getBonuses;
          this.getCumulativeBonuses = DisplayAllBonusCollectionValue.getCumulativeBonuses;
          this.MonthDisplayView = DisplayAllBonusCollectionValue.getMonthDisplay;
          this.PeriodTabView = DisplayAllBonusCollectionValue.getPeriodTabView;
          this.CumulativeBonusesDisplayBudgetResults = DisplayAllBonusCollectionValue.getCumulativeBonuses[0].bonusCommisionValuesArray;
          this.BonusesDisplayBudgetResults = DisplayAllBonusCollectionValue.getBonuses[0].bonusCommisionValuesArray;
          this.Activetabmenu = parseInt(this.PeriodTabView[0].selectedIndex);
        })
      )
    }
  }

  /**
 * 
 * @param result 
 * @param index 
 */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    // console.log(this.Activetabmenu);
  }
  recordModified(record: recordSaveModel) {
    this.recordmodifiedModel = record;
    console.log(record);
  }

  SaveData(){
    // if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
    //   let recordModified: any = this.recordmodifiedModel
    //   if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
    //     recordModified.budgetData.forEach(element => {
    //       element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
    //     });
    //     this.recordmodifiedModel = recordModified;
    //   }

    //   this.SpinnerService.show();
    //   this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
    //     (response: any) => {
    //       this.SpinnerService.hide();
    //       this.recordmodifiedModel = new recordSaveModel();
    //       this.ngOnChanges();
    //     })
    // }
    let savemodel = null;
    let commonbonus = localStorage.getItem("setEmployeeCommonBonus");
    if(commonbonus!=undefined && commonbonus!=""){
      savemodel = JSON.parse(commonbonus);
    }

   if (this.recordmodifiedModel != undefined && this.recordmodifiedModel != null) {
     let recordModified: any = this.recordmodifiedModel
     if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
       recordModified.budgetData.forEach(element => {
         element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
       });
       this.recordmodifiedModel = recordModified;
     }
     this.SpinnerService.show();
     if(savemodel!="" && savemodel!=null && savemodel!=undefined ) {
       this.recordmodifiedModel.budgetData.push(savemodel.setCommonBonusmodel.budgetData);
     }
     this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
       (response: any) => {
         this.SpinnerService.hide();
         this.recordmodifiedModel = new recordSaveModel();
         localStorage.setItem("setEmployeeCommonBonus", "");
         this.ngOnChanges();
       })
   }
   if(this.recordmodifiedModel == undefined && this.recordmodifiedModel == null && savemodel!="" && savemodel!=null && savemodel!=undefined){
     this.recordmodifiedModel =  savemodel.setCommonBonusmodel;
     let recordModified: any = this.recordmodifiedModel
     if (recordModified.budgetData[0].selectedNodes != undefined && recordModified.budgetData[0].selectedNodes != null) {
       recordModified.budgetData.forEach(element => {
         element.selectedNodes = this.RemoveDuplicationArray(element.selectedNodes);
       });
       this.recordmodifiedModel = recordModified;
     }
     this.SpinnerService.show();
     this.methodserviceService.saveModifiedRecord(this.recordmodifiedModel).subscribe(
       (response: any) => {
         this.SpinnerService.hide();
         this.recordmodifiedModel = new recordSaveModel();
         localStorage.setItem("setEmployeeCommonBonus", "");
         this.ngOnChanges();
       })
  }
  }

  RemoveDuplicationArray(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

}
