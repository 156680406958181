<div *ngIf="grossreceiptsgroupedchangemethod">
    <div *ngIf="grossreceiptsgroupedchangemethod == 'ManuallyEntered'" >
        <div class="form-group row">
            <label class="col-sm-4 col-form-label">Set All</label>
            <div class="col-sm-4"><input type="text" /></div>
        </div>
        <div class="GrossRecipt form-group row">
            <div>
                <label class="col-sm-4 col-form-label"></label>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Jan 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Feb 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">March 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Apr 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">May 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Jun 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">July 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Aug 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Spet 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Oct 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Nov 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-4 col-form-label">Dec 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
        </div>

    </div>
    <div *ngIf="grossreceiptsgroupedchangemethod == 'DaysCredit'">
        <p>{{grossreceiptsgroupedchangemethod}}</p>
        <div class="Days row" >
            <div class="form-group row" >
                <label>Set All</label><input type="text" />
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Jan 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Feb 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">March 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Apr 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">May 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Jun 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">July 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Aug 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Spet 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Oct 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Nov 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Dec 20</label>
                <div class="col-sm-4"><input type="text" value="0"/></div>
            </div>
        </div>
        <div class="GrossRecipt">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label"></label>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Jan 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label"> Feb 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">March 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Apr 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">May 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Jun 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">July 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Aug 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Spet 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Oct 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Nov 20</label>
                <div class="col-sm-4">0</div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Dec 20</label>
                <div class="col-sm-4">0</div>
            </div>
        </div>

    </div>
</div>