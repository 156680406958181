import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tree-node-department',
  templateUrl: './tree-node-department.component.html',
  styleUrls: ['./tree-node-department.component.scss']
})
export class TreeNodeDepartmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
