import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, Renderer2 } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {

  @Input() appPermission = '';
  @Input() appPermissionCurrentValue = '';


  private onDestroy$ = new Subject<boolean>();

  constructor(private permissionService: PermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2) {
  }


  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngOnInit(): void {
    if (this.appPermission == "" || this.appPermission == undefined || this.permissionService.checkPermission(this.appPermission, this.appPermissionCurrentValue) || this.appPermission == "Home") {
      this.showComponent();
      // this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'not-allowed');
    } else {
      this.disableComponent();
    }
  }

  disableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    viewRootElement.setAttribute('style', 'pointer-events: none;');
    this.renderer.setProperty(viewRootElement, 'disabled', 'true');
    // this.renderer.setProperty(viewRootElement, 'ng-disabled', 'true');

    // this.renderer.addClass(viewRootElement, "disabledElement")
  }

  removeOnAttributes(element) {
    for (var attribute of element.attributes)
      if (/^on/i.test(attribute.name))
        element.removeAttribute(attribute.name);
  }

  enableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    this.renderer.setProperty(viewRootElement, 'disabled', false);
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
  }
}

