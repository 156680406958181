import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Userregistrationmodel } from 'src/app/model/userregistrationmodel';
import { UserregistrationService } from 'src/app/service/userregistration.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegisterValidators } from 'src/app/core/validators/register-validators';
import { EmailTaken } from 'src/app/core/validators/email-taken';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Countries } from 'src/app/model/Countries.model';
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {

  Countries: Countries[] = [];
  isSubmitted = false;
  busy = false;
  firstName = new UntypedFormControl('', [Validators.required])
  lastName = new UntypedFormControl('', [Validators.required])
  emailId = new UntypedFormControl('', [Validators.required, RegisterValidators.regexValidator(new
    RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), { 'email': 'not valid' })],
    [this.emailTaken.validate])
  phone = new UntypedFormControl('', [Validators.required])
  company = new UntypedFormControl('', [Validators.required])
  userName = new UntypedFormControl('', [Validators.required])
  Country = new UntypedFormControl('', [Validators.required])
  Password = new UntypedFormControl('', [Validators.required,
  RegisterValidators.regexValidator(new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), { 'password': 'not valid' })])
  ConfirmPassword = new UntypedFormControl('', [Validators.required])
  userModel: Userregistrationmodel;


  userregistrationForm = new UntypedFormGroup({
    firstName: this.firstName,
    lastName: this.lastName,
    emailId: this.emailId,
    phone: this.phone,
    company: this.company,
    userName: this.emailId,
    Password: this.Password,
    Country: this.Country,
    ConfirmPassword: this.ConfirmPassword,

  }, [RegisterValidators.match('Password', 'ConfirmPassword')]);

  constructor(private httpService: HttpClient, private userregistrationService: UserregistrationService, private SpinnerService: NgxSpinnerService, private injector: Injector, private emailTaken: EmailTaken) { }

  ngOnInit(): void {
    this.httpService.get('../../../assets/Countries.json').subscribe(data => {
      let dataArray = data as string[];
      dataArray.forEach(country => {
        this.Countries.push(
          new Countries({
            code: country["code"],
            name: country["name"]
          })
        )
      });
    });
  }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    if (this.userregistrationForm.invalid) {
      return;
    }
    if (this.userregistrationForm.value.Password != this.userregistrationForm.value.ConfirmPassword) {
      return;
    }
    this.userModel = new Userregistrationmodel();
    this.SpinnerService.show();
    this.userModel.FirstName = this.userregistrationForm.value.firstName;
    this.userModel.LastName = this.userregistrationForm.value.lastName;
    this.userModel.EmailID = this.userregistrationForm.value.emailId;
    this.userModel.PhoneNumber = this.userregistrationForm.value.phone;
    this.userModel.CompanyName = this.userregistrationForm.value.company;
    this.userModel.userName = this.userregistrationForm.value.emailId;
    this.userModel.Password = this.userregistrationForm.value.Password;
    //this.userModel.Country = this.userregistrationForm.value.Country;
    this.userModel.Reseller = 'F5 Web Trial';
    this.userModel.Country = "New Zealand";
    this.userModel.UserType = 3;
    this.userModel.ParentUser = 0;
    this.userregistrationService.RegisterUser(this.userModel).subscribe(
      (response: boolean) => {
        this.SpinnerService.hide();
        if (response) {
          notifier.showSuccess("Registered Successfully");
        }
      }),
      err => {
        this.SpinnerService.hide();
        throw err;
      }

  }
}
