import { Component, Input, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forEach } from 'jszip';

@Component({
  selector: 'app-sectiontree',
  template: `<div class="treeMenu" style="max-height: 42vh;
    margin-left: 9px;
    display: flex;
    flex-direction: column;
    overflow: overlay;"><tree-root #tree [nodes]="nodes"  (initialized)="onTreeLoad()" 
   [options]="options"><ng-template #treeNodeTemplate let-node="node" let-index="index" >
   <div *ngIf="selectedNodeIds" id="{{node.data.nodeId}}">  
    <div *ngIf="selectedNodeIds.includes(node.data.nodeId) == true">
        <input 
        (change)="check(node, !(selectedNodeIds.includes(node.data.nodeId) == true) , true)"
        type="checkbox"
        style="float: left;margin-right: 9px;"
        class="Test-{{WhatIfActive}}"
        [indeterminate]="node.data.indeterminate"
        [disabled]="ShowWhatIf"
        [checked]="true">
        {{ node.data.name }}
      </div>
      <div *ngIf="selectedNodeIds.includes(node.data.nodeId) == false">
      <input 
        (change)="check(node, !(selectedNodeIds.includes(node.data.nodeId) == true), false)"
        type="checkbox"
        class="test-{{WhatIfActive}}"
        [disabled]="ShowWhatIf"
        style="float: left;margin-right: 9px;"
        [indeterminate]="node.data.indeterminate"
        [checked]="node.data.checked">
        {{ node.data.name }}
      </div>
     </div> 
     <div *ngIf="!selectedNodeIds">  
       <input 
         (change)="check(node,  !(node.data.checked), false)"
         type="checkbox"
         class="Test-{{WhatIfActive}}"
         style="float: left;margin-right: 9px;"
         [disabled]="ShowWhatIf"
         [indeterminate]="node.data.indeterminate"
         [checked]="node.data.checked">
         {{ node.data.name }}
      </div>
 </ng-template></tree-root>
 </div>
 
`,
  styleUrls: ['./sectiontree.component.scss']
})
export class SectiontreeComponent implements OnInit {

  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  nodes = [];
  options = {}
  ResorcenodeArray = [];
  WhatIfActive: boolean;
  selectedUnselectedNode = [];
  BudgetValuesLength: any = 0;
  breakRecord: boolean;
  UpdatedNodes = [];
  DuplicateSelected = [];
  ShowWhatIf: boolean;
  derivationTypeParent = "";
  constructor(public SpinnerService:NgxSpinnerService) { }

  @Input() sectiontreeview;
  @Input() selectedNodeIds;
  @Input() WhatIf;
  @Input() CalculateAsBalance;

  @ViewChild('tree') tree;

  async ngOnInit() { 
    this.SpinnerService.show();
    this.WhatIfActive = this.WhatIf;
    await this.UpdateTreengOnInt();
    this.nodes = [];
    this.ResorcenodeArray = [this.sectiontreeview];
    localStorage.setItem("UpdatedTreeValues", JSON.stringify([this.sectiontreeview]))
    this.nodes = [this.sectiontreeview];
    this.UpdatedNodes = this.nodes;
    this.ShowWhatIf = (localStorage.getItem('showWhatIfValue') === 'true');
    this.options = {};
    await this.getparentValuelist();
    this.derivationTypeParent = localStorage.getItem("selectionTreeParentDerivation");
    this.SpinnerService.hide();
  }

  AllUpdatedSelectedId = [];

  UpdateTreengOnInt() {
    if (this.selectedNodeIds != null) {
      let isselected = false;
      isselected = this.CheckifChildNodesSelected(this.sectiontreeview, this.sectiontreeview.children);
      if (isselected) {
        this.selectedNodeIds.push(this.sectiontreeview.nodeId);
      }
    }
  }

  CheckifChildNodesSelected(parent, children) {
    let isselected = false;
    children.forEach(element => {
      if (this.selectedNodeIds != null && !this.selectedNodeIds.includes(element.nodeId)) {
        isselected = false;
      } else {
        isselected = true;
      }

      if (element.children != undefined && element.children.length > 0) {
        isselected = this.CheckifChildNodesSelected(element, element.children);
      }

      if (this.selectedNodeIds != null && isselected && !this.selectedNodeIds.includes(element.nodeId)) {
        this.selectedNodeIds.push(element.nodeId);
      }
    });
    return isselected;
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async onTreeLoad() {
    this.tree.treeModel.expandAll();
  }

  public check(node, checked, selectunselect) {
    let data: any = {
      node: '',
      option: '',
      zeroOption: '',
      budgetData: [],
    }
    this.breakRecord = false;

    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.parent);
    let NodesArray = this.nodes[0];

    var FirstNodeValue = this.CheckBudgetLength(NodesArray);

    let DefaultArray = [];
    if (FirstNodeValue != null && this.derivationTypeParent != "84078") {
      //this.selectedNodeIds = [];
      FirstNodeValue.budgetData = DefaultArray;
      FirstNodeValue["zeroOption"] = true;
      this.countChanged.emit(FirstNodeValue);
    }

    this.TreeViewFunction(this.nodes[0]);
    this.tree.treeModel.update();
    if (this.selectedNodeIds != null) {
      this.selectedNodeIds = this.RemoveDuplicateId(this.selectedNodeIds);
    }

    let UpdatedTreeValues = localStorage.getItem("UpdatedTreeValues");
    if (UpdatedTreeValues != undefined) {
      let realValuesTree = JSON.parse(UpdatedTreeValues);

      this.ConvertTreeStructure(realValuesTree[0]);
    }

    this.DuplicateSelected = [];
  }

  RemoveDuplicateId(ArrayValues) {
    let Uniq = [];
    ArrayValues.forEach(element => {
      if (!Uniq.includes(element)) {
        Uniq.push(element);
      }
    });
    return Uniq;
  }
  ConvertTreeStructure(node) {

    if (this.selectedNodeIds.includes(node.nodeId)) {
      if ((node.checked != undefined && node.checked == true) || this.selectedNodeIds.includes(node.nodeId)) {
        //if ((node.checked != undefined && node.checked == true)) { 
        if (node.budgetData != null) {
          let valuesArray = node.budgetData.valuesArray;
          if (this.CalculateAsBalance != null && this.CalculateAsBalance == true) {
            if (node.budgetData.hasOpening) {
              let CalculateAsBalance = []
              let hasValues = 0;
              for (var i = 0; i < 12; i++) {
                if (i == 0) {
                  CalculateAsBalance.push(node.budgetData.valuesArray[i] + node.budgetData.opening);
                }
                else {
                  hasValues = node.budgetData.opening;
                  for (var j = 0; j <= i; j++) {
                    hasValues += node.budgetData.valuesArray[j];
                  }
                  CalculateAsBalance.push(hasValues);
                }

              }
              valuesArray = CalculateAsBalance;
            }
          } else {
            valuesArray.forEach((element, index) => {
              valuesArray[index] = this.DisplayArray(element, node.budgetData.display);
            });
          }
          let BudgetValuedata: any = {
            node: node.nodeId,
            option: true,
            budgetData: valuesArray
          }

          this.countChanged.emit(BudgetValuedata);
        } else {
          let values = [];
          let BudgetValuedata: any = {
            node: node.nodeId,
            option: true,
            budgetData: values
          }

          this.countChanged.emit(BudgetValuedata);
        }
      }
    }
    if (!this.selectedNodeIds.includes(node.nodeId)) {
      if (node.checked != undefined && node.checked == false) {
        if (node.budgetData != null) {
        } else {
        }
      }
    }
    if (node.children != null && node.children.length > 0) {
      var treechildren = node.children.filter(child => child.nodeType < 60000);
      if (treechildren != null && treechildren.length > 0) {
        treechildren.forEach(element => {
          this.ConvertTreeStructure(element)
        });
      }
    }
    //return node;
  }

  DisplayArray(data, Display) {
    let result = data;
    switch (Display) {
      case 2:
        if (result < 0)
          result = 0;
        break;
      case 3:
        if (result > 0)
          result = 0;
        break;
      case 4:
        result = -result;
        break;
      case 5:
        if (result > 0)
          result = 0;
        else
          result *= -1;
        break;
      case 6:
        if (result < 0)
          result = 0;
        else
          result *= -1;
        break;
      case 7:
        result = Math.abs(result);
        break;
      case 8:
        result = -Math.abs(result);
        break;
      case 9:
        result *= 100;
        break;
    }
    return result;
  }

  squash(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

  TreeViewFunction(node) {
    if (node.checked == false) {
      if (this.selectedNodeIds != 'undefined') {
        if (this.selectedNodeIds != null) {
          this.selectedNodeIds.forEach((item, index) => {
            if (item === node.nodeId) {
              this.selectedNodeIds.splice(index, 1);
            }
          });
        }
      }
    }
    else {
      if (node.checked == true) {
        if (this.selectedNodeIds == null) {
          this.selectedNodeIds = [];
          if (node.nodeId != 'undefined') {
            this.selectedNodeIds.push(node.nodeId);
          }
        } else {
          if (!this.selectedNodeIds.includes(node.nodeId)) {
            this.selectedNodeIds.push(node.nodeId);
          }
        }
      }

    }


    if (node.children != null && node.children.length > 0) {
      var treechildren = node.children.filter(child => child.nodeType < 60000);
      if (treechildren != null && treechildren.length > 0) {
        treechildren.forEach(element => {
          this.TreeViewFunction(element)
        });
      }
    }
  }
  CheckBudgetLength(node) {
    this.BudgetValuesLength = 0;
    let BudgetValuedata: any = {
      node: node.nodeId,
      option: true,
      budgetData: []
    }
    let values = [];
    if (node.budgetData != null) {
      this.BudgetValuesLength = node.budgetData.valuesArray.length;
      BudgetValuedata = {
        node: node.nodeId,
        option: true,
        budgetData: values
      }
      this.breakRecord = true;
      return BudgetValuedata;

    }

    if (node.children != null && node.children.length > 0 && this.breakRecord == false) {
      var treechildren = node.children.filter(child => child.nodeType < 60000);
      if (treechildren != null && treechildren.length > 0) {
        if (this.breakRecord == false) {
          treechildren.forEach(element => {
            if (values.length > 0) {
              return BudgetValuedata;
            } else {
              this.CheckBudgetLength(element)
            }
          });
        }

      }
    }

    return BudgetValuedata;

  }

  getparentValuelist() {
    let valuesArry = [];
    for (let i = 0; i < this.sectiontreeview.children.length; i++) {
      let childValues = this.getchildrenValue(this.sectiontreeview.children[i]);
      if (childValues != null && childValues != undefined && childValues.length > 0) {
        if (valuesArry.length > 0) {
          for (let j = 0; j < childValues.length; j++) {
            valuesArry[j] = parseFloat(valuesArry[j]) + parseFloat(childValues[j]);
          }
        }
        else {
          for (let j = 0; j < childValues.length; j++) {
            valuesArry[j] = parseFloat(childValues[j]);
          }
        }
      }
    }
    localStorage.setItem("AspercentageCalculatedBudgetData", JSON.stringify(valuesArry));
  }
  getchildrenValue(childNode: any) {
    let valueArray = [];
    if (childNode.children != null) {
      for (let i = 0; i < childNode.children.length; i++) {
        if (this.selectedNodeIds != null && this.selectedNodeIds.includes(childNode.children[i].nodeId) && childNode.children[i].budgetData != null) {
          //valueArray = childNode.children[i].budgetData.valuesArray;
          //console.log("childNode.children[i]", childNode.children[i]);
          if (valueArray.length > 0) {
            for (let j = 0; j < childNode.children[i].budgetData.valuesArray.length; j++) {
              valueArray[j] = parseFloat(valueArray[j]) + parseFloat(childNode.children[i].budgetData.valuesArray[j]);
            }
          }
          else {
            // valueArray[j].push(childNode.children[i].budgetData.valuesArray[j])
            valueArray = childNode.children[i].budgetData.valuesArray;

          }
        }
        else {
          if (childNode.children[i].children != null) {
            let childvalueArray = this.getchildrenValue(childNode.children[i]);
            if (childvalueArray.length > 0) {
              if (valueArray.length > 0) {
                for (let j = 0; j < valueArray.length; j++) {
                  valueArray[j] = parseFloat(valueArray[j]) + parseFloat(childvalueArray[j]);
                }
              }
              else {
                // valueArray[j].push(childNode.children[i].budgetData.valuesArray[j])
                valueArray = childvalueArray;

              }
            }
          }
        }
      }
    }
    return valueArray;
  }

  public updateChildNodeCheckbox(node, checked) {
    if (node.data != null) {
      node.data.checked = checked;
      if (node.children != undefined) {
        node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
        this.tree.treeModel.update();
      }
    }

  }
  public updateParentNodeCheckbox(node) {
    if (!node) {
      return;
    }
    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (node.data != null) {
        if (!child.data.checked || child.data.indeterminate) {
          allChildrenChecked = false;
        }
        if (child.data.checked) {
          noChildChecked = false;
        }
      }

    }
    if (allChildrenChecked) {
      if (node.data != null) {
        node.data.checked = true;
        node.data.indeterminate = false;
      }

    } else if (noChildChecked) {
      if (node.data != null) {
        node.data.checked = false;
        node.data.indeterminate = false;
      }

    } else {
      if (node.data != null) {
        //node.data.checked = true;
        node.data.indeterminate = true;
      }
    }
    this.updateParentNodeCheckbox(node.parent);
  }

}
