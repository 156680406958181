import { Injectable, NgZone, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(public snackBar: MatSnackBar,
    private zone: NgZone
  ) { }

  // public snackBar: IgxSnackbarComponent,
  // private zone: NgZone

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, 'X', {
        duration: 2000, panelClass: ['success'], verticalPosition: 'bottom', horizontalPosition: 'end'

      });
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button. 
      // In the third, we send in the css class for the snack bar.
      this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'bottom', horizontalPosition: 'end' });
    });
  }
}
