import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { timeStamp } from 'console';
import { PayrolltableoptionsService } from '../../../service/SettingServices/payrollTableService/payrolltableoptions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/core/services/notification.service';
import { identity } from 'rxjs';

@Component({
  selector: 'app-payroll-table-options',
  templateUrl: './payroll-table-options.component.html',
  styleUrls: ['./payroll-table-options.component.scss']
})
export class PayrollTableOptionsComponent {

  NavitemSubmenu: any = [];
  //payrates
  PayRatesCollections: any = [];
  PayRateActiveRecord: any = 0;
  PAYEOptions: any = [];
  ForecastNodeId: any
  ForecastStartDate: any = '';
  //Bonus
  BonusCollections: any = [];
  BonusValidColumn: any = [];
  //Pension
  PensionCollectionsItems = [];
  PensionCollections: any = [];
  PensionActiveRecord: any = null;

  //NI Details
  NICollections = [];
  NICollectionsItems: any = [];
  DefaultNI: any = 0;
  NIActiveRecord: any = 0;

  PeriodTabView: any = [];
  MonthDisplay: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  bonusCommisionStartIndex: any = 0;
  bonusCommisionEndIndex: any = 11;
  activetab = "Payrates";

  SelectedEmployee: any = 0;
  SelectedEmployer: any = 0;
  DefaultPension: any = 0;
  CountryVersion = "";

  constructor(private PayrolltableoptionsServices: PayrolltableoptionsService,
    private _router: Router,
    private injector: Injector,
    private SpinnerService: NgxSpinnerService) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');
  }

  async ngOnInit() {
    this.RenderReportMenu();
    this.PayRateCollectionFun();
    this.CountryVersion = localStorage.getItem("CountryName");
  }

  SelectedEmployeeSelection(Index) {
    this.SelectedEmployee = Index;
  }
  SelectedEmployerSelection(Index) {
    this.SelectedEmployer = Index;
  }

  payratesOpen() {
    this.activetab = "Payrates";
  }

  PensionDetailsOpen() {
    this.activetab = "PensionDetails";
    this.PensionCollections = [];
    this.PensionCollectionsItems = [];
    this.SpinnerService.show();

    this.PayrolltableoptionsServices.getPensionCollections(this.ForecastNodeId).subscribe(
      (async response => {
        try {
          this.PensionCollectionsItems = response;
          this.DefaultPension = this.PensionCollectionsItems[0].defaultIndex;
          if (this.PensionActiveRecord == null) {
            this.PensionActiveRecord = this.PensionCollectionsItems[0].defaultIndex;
            this.PensionCollections = this.PensionCollectionsItems[parseInt(this.DefaultPension)];
          } else {
            // this.PensionActiveRecord = this.PensionCollectionsItems[0].defaultIndex;
            this.PensionCollections = this.PensionCollectionsItems[parseInt(this.PensionActiveRecord)];
          }

          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  NIDetailsOpen() {
    this.activetab = "NIDetails";
    this.NICollections = [];
    this.NICollectionsItems = [];
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.getNICollections(this.ForecastNodeId).subscribe(
      (async response => {
        try {
          this.NICollectionsItems = response;
          this.DefaultNI = this.NICollectionsItems[0].defaultIndex;
          this.NICollections = this.NICollectionsItems[parseInt(this.DefaultNI)];
          this.NIActiveRecord = this.DefaultNI;
          // console.log(this.NICollections);
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  SetDefaultPension() {
    this.PensionCollections = [];
    this.PensionCollectionsItems = [];
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.DefaultPension(this.ForecastNodeId, this.PensionActiveRecord).subscribe(
      (async response => {
        try {
          this.SpinnerService.hide();
          this.PensionCollectionsItems = response;
          this.DefaultPension = this.PensionCollectionsItems[0].defaultIndex;
          this.PensionCollections = this.PensionCollectionsItems[parseInt(this.DefaultPension)];
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }
  SetDefaultNI() {
    this.NICollections = [];
    this.NICollectionsItems = [];
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.DefaultNI(this.ForecastNodeId, this.NIActiveRecord).subscribe(
      (async response => {
        try {
          this.NICollectionsItems = response;
          this.DefaultNI = this.NICollectionsItems[0].defaultIndex;
          this.NICollections = this.NICollectionsItems[parseInt(this.DefaultNI)];
          this.NIActiveRecord = this.DefaultNI;
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  async PayRateCollectionFun() {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.getPayRatesCollections(this.ForecastNodeId).subscribe(
      (async response => {
        try {
          this.PayRatesCollections = response;
          this.PayRatesCollections.forEach(element => {
            element.amount = element.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            element.percentage = parseFloat((element.percentage * 100).toString()).toFixed(2);
          });
          await this.PayrolltableoptionsServices.getPAYECollections(this.ForecastNodeId).subscribe(
            (result => {
              this.PAYEOptions = result;
            })
          )
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
    this.BonusCollections = [];
    this.PeriodTabView = [];
    this.MonthDisplay = [];
    this.PayrolltableoptionsServices.getBonusesCollections(this.ForecastNodeId).subscribe(
      (async response => {
        try {
          let allBonusesCollection: any = response;
          this.BonusCollections = allBonusesCollection.getBonuses;
          this.PeriodTabView = allBonusesCollection.getPeriodTabView[0];
          this.MonthDisplay = allBonusesCollection.getMonthDisplay;
          var selectedIndex = this.PeriodTabView.selectedIndex;
          this.bonusCommisionStartIndex = parseInt(selectedIndex) * 12;
          this.bonusCommisionEndIndex = parseInt(this.bonusCommisionStartIndex + 11);
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )

  }
  ngOnChanges() {
    this.RenderReportMenu();
  }

  /**
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.bonusCommisionStartIndex = parseInt(index) * 12;
    this.bonusCommisionEndIndex = parseInt(this.bonusCommisionStartIndex + 11);
  }

  changeValueList(i) {
    this.PayRateActiveRecord = i;
  }
  /**
   * payrates sections start here
   */

  DeletePayRates() {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.deletePayRates(this.ForecastNodeId, this.PayRateActiveRecord).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.PayRatesCollections = response;
        this.PayRatesCollections.forEach(element => {
          element.amount = element.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          element.percentage = parseFloat((element.percentage * 100).toString()).toFixed(2);
        });
        this.PayRateActiveRecord = this.PayRateActiveRecord + 1;
      })
    )
  }
  AddPayRates() {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.addPayRates(this.ForecastNodeId, this.PayRateActiveRecord).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.PayRatesCollections = response;
        this.PayRatesCollections.forEach(element => {
          element.amount = element.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          element.percentage = parseFloat((element.percentage * 100).toString()).toFixed(2);
        });
        this.PayRateActiveRecord = this.PayRateActiveRecord + 1;
      })
    )
  }
  PayratesAmountChange(Index, value, event) {
    let setamountValue = parseFloat(event.target.value.replaceAll(',', '')).toFixed(2);
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.setAmount(value.valueNodeId, setamountValue, Index).subscribe(
      (response => {
        this.SpinnerService.hide();
        this.PayRateActiveRecord = Index;
      })
    )
  }
  LimitOnly($event, number): boolean {
    //  var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = $event.target.value;
    if (eventValue == "") {
      return;
    }
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  PayratesPercentagesChange(Index, value, event) {
    let setpercentageValue = parseFloat(event.target.value).toFixed(2);
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.setPercentage(value.valueNodeId, setpercentageValue, Index).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.PayRateActiveRecord = Index;
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }

  /**
   * PAYE Options 
   */
  PAYEOptionsChanges($event) {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.editPAYEpaymentMethod(this.ForecastNodeId, $event).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.PAYEOptions.payePaymentMethod = response;
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }

  /** 
  * PAYE Options 
  */
  PAYEIncomeTextChange($event) {
    this.PayrolltableoptionsServices.editPAYEIncomeTextAllownce(this.ForecastNodeId, $event.target.checked).subscribe(
      (response => {
        this.PAYEOptions.includeAllowanceInTax = response;
      })
    )
  }

  /**
   * Payrates Sections end here
   */

  /**
   * Change event TabView
   */
  async ChangePensionType(Result) {

    if (Result.type == 'PensionDetails') {
      this.PensionCollections = [];
      this.PensionCollectionsItems = [];
      this.SpinnerService.show();
      this.PayrolltableoptionsServices.getPensionCollections(this.ForecastNodeId).subscribe(
        (async response => {
          try {
            this.SpinnerService.hide();
            this.PensionCollectionsItems = response;
            this.PensionCollections = this.PensionCollectionsItems[0];
            this.PensionActiveRecord = this.PensionCollections.defaultIndex;
          } catch (error) {
            this.SpinnerService.hide();
          }
        })
      )
    }
  }

  changePensionActiveList(index) {
    this.PensionCollections = this.PensionCollectionsItems[index];
    this.PensionActiveRecord = index;
  }

  changeNIActiveList(index) {
    this.NICollections = this.NICollectionsItems[index];
    this.NIActiveRecord = index;
  }

  SetAllBonusList(Values, $event) {
    if ($event.target.value != '') {
      this.monthCalculationbysetallandtotal(Values, $event);
    }
  }
  MonthBonusList(Values, index, $event) {
    if ($event.target.value != '') {
      let callApi = 'false';
      let defualtValue = Values[0].bonusCommisionValuesArray[index];
      let updatedValue = $event.target.value;
      if (updatedValue != "") {
        let value = updatedValue;
        let convertInt = parseInt(value);
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '');
          convertInt = parseInt(data);
        } else {
        }
        if (defualtValue != convertInt) {
          callApi = 'true';
        }
      } else {
        return;
      }
      if (callApi == 'true') {
      } else {
        return;
      }
      this.monthCalculationbysetValue(Values, index, $event);
    }
  }

  monthCalculationbysetallandtotal(getBonusesValue, $event) {
    if ($event.target.value == "") {
      return;
    }
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      setAllValue: null,
      total: 0,
      startindex: 0,
      endindex: 11,
      opening: Number,
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    let startindex = 0;
    let endindex = 11;
    let ActiveyearsIndex = this.Activetabmenu;
    if (ActiveyearsIndex != null) {
      startindex = ActiveyearsIndex * 12;
      endindex = startindex + 11;
    }
    convertjson.NodeID = getBonusesValue[0].nodeId;
    convertjson.ValueNodeID = getBonusesValue[0].valuesNodeId;
    displaydata.ValueNodeID = getBonusesValue[0].valuesNodeId;
    displaydata.startindex = startindex;
    displaydata.endindex = endindex;
    displaydata.setAllValue = parseFloat($event.target.value).toFixed(2);
    // console.log(parseFloat($event.target.value).toFixed(2));
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.SetAllAndTotalBonusesPayrollService(convertjson).subscribe(
      (response: any) => {
        try {
          this.SpinnerService.hide();
          this.BonusCollections = [];
          this.PeriodTabView = [];
          this.MonthDisplay = [];
          let allBonusesCollection: any = response;
          this.BonusCollections = allBonusesCollection.getBonuses;
          this.PeriodTabView = allBonusesCollection.getPeriodTabView[0];
          this.MonthDisplay = allBonusesCollection.getMonthDisplay;
          var selectedIndex = this.Activetabmenu;
          this.bonusCommisionStartIndex = parseInt(selectedIndex) * 12;
          this.bonusCommisionEndIndex = parseInt(this.bonusCommisionStartIndex + 11);
          // console.log(allBonusesCollection);

        } catch (error) {
          this.SpinnerService.hide();
        }

      })
  }

  SetAllPayrollBonusList(BonusCollections, $event) {
    if ($event.target.value == '') {
      return;
      $event.target.value = 0;
    }
    let total = 0;
    let resultarray = [];
    var loopindexstart;
    let value = $event.target.value;
    for (loopindexstart = this.bonusCommisionStartIndex; loopindexstart <= this.bonusCommisionEndIndex; ++loopindexstart) {
      if (BonusCollections[0].currentActualsIndex >= loopindexstart) {
        if (this.check(BonusCollections.bonusCommisionValuesArray[loopindexstart].toString()) == true) {
          let data = BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString().replaceAll(',', '');
          BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      } else {
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = value.replaceAll(',', '');
          BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      }

    }

    BonusCollections[0]["Edited"] = true;
    localStorage.setItem("Editedbonuses", JSON.stringify(this.BonusCollections[0]));
    $event.target.value = '';
  }

  transform(value) {
    if (value == null) {
      return 0;
    }
    if (Math.sign(value) == -1) {
      let data = Math.abs(value);
      let convertNumberFormat = data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return '(' + convertNumberFormat + ')';
    } else {
      let convertNumberFormat = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return convertNumberFormat;
    }
  }
  MonthValuePayrollBonusList(BonusCollections, index, $event) {
    if ($event.target.value == '') {
      var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
      inputValue.value = 0;
      return;
    }

    let total = 0;
    var loopindexstart;
    let value = $event.target.value;
    if (this.check(value.toString()) == true) {
      let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    } else {
      let data = value.replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    }

    for (loopindexstart = this.bonusCommisionStartIndex; loopindexstart <= this.bonusCommisionEndIndex; ++loopindexstart) {
      if (loopindexstart == index) {
        if (BonusCollections[0].currentActualsIndex >= loopindexstart) {
          if (this.check(BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString()) == true) {
            let data = value.toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
            BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
            total += parseFloat(data);
          } else {
            let data = value.toString().replaceAll(',', '');
            BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
            total += parseFloat(data);
          }
        } else {
          if (this.check(value.toString()) == true) {
            let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
            BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
            total += parseFloat(data);
          } else {
            let data = value.replaceAll(',', '');
            BonusCollections[0].bonusCommisionValuesArray[loopindexstart] = this.converttoDisplayValue(data);
            total += parseFloat(data);
          }
        }
      }
    }

    // for (loopindexstart = this.bonusCommisionStartIndex; loopindexstart <= this.bonusCommisionEndIndex; ++loopindexstart) { 
    //   if (this.check(BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString()) == true) { 
    //     let data = BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
    //     total += parseFloat(data); 
    //   } else {
    //     let data = BonusCollections[0].bonusCommisionValuesArray[loopindexstart].toString().replaceAll(',', '');
    //     total += parseFloat(data);
    //   }
    // }
    // var inputValue: any = (<HTMLInputElement>document.getElementById("total-"+BonusCollections[0].nodeId));
    // if (inputValue != null) {
    //   inputValue.value = this.converttoDisplayValue(total.toString());
    //   BonusCollections[0]["total"] = this.transform(inputValue.value);
    // }
    BonusCollections[0]['Edited'] = true;
    localStorage.setItem("Editedbonuses", JSON.stringify(this.BonusCollections[0]));
    return;

  }

  trackByFn(index: any, item: any) {
    return index;
  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }


  CalculateSave(options) {
    console.log(options);
    if (options != null) {
      if (options == 'save') {
        let EditedBonusData = localStorage.getItem("Editedbonuses");
        if (EditedBonusData != null && EditedBonusData != undefined) {
          let JsonParseBonus = JSON.parse(EditedBonusData);
          console.log(JsonParseBonus);
          let displaydata = {
            values: [],
            derivationType: String,
            NodeType: String,
            ValueNodeID: Number,
            setAllValue: 0,
            total: 0,
            startindex: 0,
            endindex: 11,
            opening: Number,
            value: null,
            index: 0,
          }
          let convertjson = {
            NodeID: Number,
            ValueNodeID: Number,
            DisplayBudgetData: [displaydata]
          }
          let startindex = 0;
          let endindex = 11;
          let ActiveyearsIndex = this.Activetabmenu;
          if (ActiveyearsIndex != null) {
            startindex = ActiveyearsIndex * 12;
            endindex = startindex + 11;
          }
          let ArrayJson = [];
          JsonParseBonus.bonusCommisionValuesArray.forEach(element => {
            ArrayJson.push(parseFloat(element.toString().replaceAll(",", "")).toFixed(0));
          });
          const notifier = this.injector.get(NotificationService);
          convertjson.NodeID = JsonParseBonus.nodeId;
          convertjson.ValueNodeID = JsonParseBonus.valuesNodeId;
          displaydata.ValueNodeID = JsonParseBonus.valuesNodeId;
          displaydata.values = ArrayJson;
          displaydata.value = parseFloat(ArrayJson[0]).toFixed(2);
          displaydata.startindex = startindex;
          displaydata.endindex = endindex;
          this.SpinnerService.show();
          this.PayrolltableoptionsServices.SetValuesSaveBonusesPayrollService(convertjson).subscribe(
            (response: any) => {
              try {
                this.SpinnerService.hide();
                if (this.activetab == 'PensionDetails')
                  notifier.showSuccess("Pension Details Saved Succesfully");
                else if (this.activetab == 'NIDetails')
                  notifier.showSuccess("NI Details Saved Succesfully");
                else
                  notifier.showSuccess("Pay Rates Saved Succesfully");
                this.BonusCollections = [];
                this.PeriodTabView = [];
                this.MonthDisplay = [];
                let allBonusesCollection: any = response;
                this.BonusCollections = allBonusesCollection.getBonuses;
                this.PeriodTabView = allBonusesCollection.getPeriodTabView[0];
                this.MonthDisplay = allBonusesCollection.getMonthDisplay;
                var selectedIndex = this.Activetabmenu;
                this.bonusCommisionStartIndex = parseInt(selectedIndex) * 12;
                this.bonusCommisionEndIndex = parseInt(this.bonusCommisionStartIndex + 11);
              } catch (error) {
                this.SpinnerService.hide();
              }
            })
        }
        let forecastNodeid = localStorage.getItem('ForecastId');
        this.PayrolltableoptionsServices.CalculatePayeeRecord(forecastNodeid).subscribe(
          (response: any) => {
          }
        )
      }
      if (options == 'close') {
        this._router.navigateByUrl("/forecast");
      }

    }

  }
  monthCalculationbysetValue(getBonusesValue, indexValue, $event) {
    let displaydata = {
      values: [],
      derivationType: String,
      NodeType: String,
      ValueNodeID: Number,
      setAllValue: 0,
      total: 0,
      startindex: 0,
      endindex: 11,
      opening: Number,
      value: null,
      index: parseInt(indexValue),
    }
    let convertjson = {
      NodeID: Number,
      ValueNodeID: Number,
      DisplayBudgetData: [displaydata]
    }
    let startindex = 0;
    let endindex = 11;
    let ActiveyearsIndex = this.Activetabmenu;
    if (ActiveyearsIndex != null) {
      startindex = ActiveyearsIndex * 12;
      endindex = startindex + 11;
    }
    convertjson.NodeID = getBonusesValue[0].nodeId;
    convertjson.ValueNodeID = getBonusesValue[0].valuesNodeId;
    displaydata.ValueNodeID = getBonusesValue[0].valuesNodeId;
    displaydata.startindex = startindex;
    displaydata.endindex = endindex;
    displaydata.value = parseFloat($event.target.value).toFixed(2);
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.SetValuesSaveBonusesPayrollService(convertjson).subscribe(
      (response: any) => {
        try {
          this.SpinnerService.hide();
          this.BonusCollections = [];
          this.PeriodTabView = [];
          this.MonthDisplay = [];
          let allBonusesCollection: any = response;
          this.BonusCollections = allBonusesCollection.getBonuses;
          this.PeriodTabView = allBonusesCollection.getPeriodTabView[0];
          this.MonthDisplay = allBonusesCollection.getMonthDisplay;
          var selectedIndex = this.Activetabmenu;
          this.bonusCommisionStartIndex = parseInt(selectedIndex) * 12;
          this.bonusCommisionEndIndex = parseInt(this.bonusCommisionStartIndex + 11);
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
  }

  //Pension Section

  AddPayPension() {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.AddPensionCollection(this.ForecastNodeId, this.PensionActiveRecord).subscribe(
      (AddPayPensionresponse => {
        this.PensionCollections = [];
        this.PensionCollectionsItems = [];
        this.SpinnerService.show();
        this.PayrolltableoptionsServices.getPensionCollections(this.ForecastNodeId).subscribe(
          (async response => {
            try {
              this.PensionCollectionsItems = response;
              this.PensionCollections = this.PensionCollectionsItems[parseInt(this.PensionActiveRecord)];
              let result: any = AddPayPensionresponse;
              this.PensionActiveRecord = parseInt(result);
              this.SpinnerService.hide();
            } catch (error) {
              this.SpinnerService.hide();
            }

          })
        )

      })
    )

  }
  DeletePension() {
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.DeletePensionCollection(this.ForecastNodeId, this.PensionActiveRecord).subscribe(
      (AddPayPensionresponse => {
        this.PensionCollections = [];
        this.PensionCollectionsItems = [];
        this.SpinnerService.show();
        this.PayrolltableoptionsServices.getPensionCollections(this.ForecastNodeId).subscribe(
          (async response => {
            try {
              this.PensionCollectionsItems = response;
              this.PensionCollections = this.PensionCollectionsItems[parseInt(this.PensionActiveRecord)];
              let result: any = AddPayPensionresponse;
              this.PensionActiveRecord = parseInt(result);
              this.SpinnerService.hide();
            } catch (error) {
              this.SpinnerService.hide();
            }

          })
        )

      })
    )
  }

  //NI Section

  AddNI() {
    this.PayrolltableoptionsServices.AddNICollection(this.ForecastNodeId, this.NIActiveRecord).subscribe(
      (AddPayNIresponse => {
        this.NICollections = [];
        this.NICollectionsItems = [];
        this.SpinnerService.show();
        this.PayrolltableoptionsServices.getNICollections(this.ForecastNodeId).subscribe(
          (async response => {
            try {
              this.NICollectionsItems = response;
              this.DefaultNI = this.NICollectionsItems[0].defaultIndex;
              this.NICollections = this.NICollectionsItems[parseInt(this.DefaultNI)];
              // console.log(this.NICollections);
              let result: any = AddPayNIresponse;
              this.NIActiveRecord = parseInt(result);
              this.SpinnerService.hide();
            } catch (error) {
              this.SpinnerService.hide();
            }
          })
        )
      })
    )

  }
  DeleteNI() {
    this.PayrolltableoptionsServices.DeleteNICollection(this.ForecastNodeId, this.NIActiveRecord).subscribe(
      (DeletePayNIresponse => {
        this.NICollections = [];
        this.NICollectionsItems = [];
        this.SpinnerService.show();
        this.PayrolltableoptionsServices.getNICollections(this.ForecastNodeId).subscribe(
          (async response => {
            try {
              this.NICollectionsItems = response;
              this.DefaultNI = this.NICollectionsItems[0].defaultIndex;
              this.NICollections = this.NICollectionsItems[parseInt(this.DefaultNI)];
              let result: any = DeletePayNIresponse;
              this.NIActiveRecord = parseInt(result);
              this.SpinnerService.hide();
            } catch (error) {
              this.SpinnerService.hide();
            }
          })
        )

      })
    )
  }

  // NI Employer and Employee
  AddEmployee(index) {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.PensionCollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.Employeeaddbanding(NodeId, this.SelectedEmployee).subscribe(
      (response => {
        try {
          this.PensionDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }
  DeleteEmployee(index) {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.PensionCollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployeedeleteBanding(NodeId, this.SelectedEmployee).subscribe(
      (response => {
        try {
          this.PensionDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }

  AddNIEmployer() {
    let selectedID = this.NIActiveRecord;
    let NodeId = this.NICollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.Employeraddbanding(NodeId, this.SelectedEmployer).subscribe(
      (response => {
        //this.PensionDetailsOpen();
        try {
          this.NIDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }
  DeleteNIEmployer() {
    let selectedID = this.NIActiveRecord;
    let NodeId = this.NICollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerdeleteBanding(NodeId, this.SelectedEmployer).subscribe(
      (response => {
        try {
          this.NIDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
        //this.PensionDetailsOpen();
      })
    )
  }
  // Pension
  AddNIEmployee(index) {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.NICollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.Employeeaddbanding(NodeId, this.SelectedEmployee).subscribe(
      (response => {
        try {
          this.NIDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }
  DeleteNIEmployee(index) {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.NICollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployeedeleteBanding(NodeId, this.SelectedEmployee).subscribe(
      (response => {
        try {
          this.NIDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )
  }


  AddEmployer() {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.PensionCollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.Employeraddbanding(NodeId, this.SelectedEmployer).subscribe(
      (response => {
        //this.PensionDetailsOpen();
        try {
          this.PensionDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }
  DeleteEmployer() {
    let selectedID = this.PensionActiveRecord;
    let NodeId = this.PensionCollectionsItems[selectedID].nodeId;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerdeleteBanding(NodeId, this.SelectedEmployer).subscribe(
      (response => {
        try {
          this.PensionDetailsOpen();
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
        //this.PensionDetailsOpen();
      })
    )
  }

  UpdateEmployerAmount($event, details) {
    if ($event.target.value == "") {
      return;
    }
    let amount = $event.target.value;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerUpdateAmountbanding(details.nodeId, amount).subscribe(
      (response => {
        try {
          details.amount = amount;
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )

  }

  UpdateEmployerPercentage($event, details) {
    if ($event.target.value == "") {
      return;
    }
    let amount = $event.target.value;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerUpdatePercentagebanding(details.nodeId, amount).subscribe(
      (response => {
        try {
          details.percentage = amount;
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  UpdateNIEmployerAmount($event, details) {
    if ($event.target.value == "") {
      return;
    }
    let amount = $event.target.value;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerUpdateAmountbanding(details.nodeId, amount).subscribe(
      (response => {
        try {
          details.amount = amount;
          this.SpinnerService.hide();
          this.NIDetailsUpdate();
        } catch (error) {
          this.SpinnerService.hide();
          this.NIDetailsUpdate();
        }
      })
    )

  }

  UpdateNIEmployerPercentage($event, details) {
    if ($event.target.value == "") {
      return;
    }
    let amount = $event.target.value;
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.EmployerUpdatePercentagebanding(details.nodeId, amount).subscribe(
      (response => {
        try {
          details.percentage = amount;
          this.SpinnerService.hide();
          this.NIDetailsUpdate();
        } catch (error) {
          this.NIDetailsUpdate();
          this.SpinnerService.hide();

        }
      })
    )
  }


  NIDetailsUpdate() {
    this.activetab = "NIDetails";
    this.NICollections = [];
    this.NICollectionsItems = [];
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.getNICollections(this.ForecastNodeId).subscribe(
      (async response => {
        try {
          this.NICollectionsItems = response;
          // this.DefaultNI = this.NICollectionsItems[0].defaultIndex;
          this.NICollections = this.NICollectionsItems[parseInt(this.NIActiveRecord)];
          //this.NIActiveRecord = this.DefaultNI;
          // console.log(this.NICollections);
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
        }
      })
    )
  }

  PensionAmountChange() {

  }
  PensionNameChange(index, details, $event) {
    let convertjson = {
      "NodeId": details.nodeId,
      "Name": $event.target.value
    }
    if (details.name == $event.target.value) {
      return;
    }
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.PensionNameChange(convertjson).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.PensionDetailsOpen();

        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )

  }
  NINameChange(index, details, $event) {
    let convertjson = {
      "NodeId": details.nodeId,
      "Name": $event.target.value
    }
    this.SpinnerService.show();
    this.PayrolltableoptionsServices.NINameChange(convertjson).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          this.NIDetailsOpen();
        } catch (error) {
          this.SpinnerService.hide();
        }

      })
    )

  }

  PensionPercentagesChange() {

  }
  RenderReportMenu() {
    this.NavitemSubmenu = [];
    this.NavitemSubmenu = [
      { name: 'Pay Rates', icon: 'home', type: 'Payrates' },
      { name: 'Pension Details', icon: 'money_off', type: 'PensionDetails' },
    ]
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  converttoDisplayValue(decimalValue) {
    //console.log("converttoDisplayValue", decimalValue);
    //var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // console.log("validatebynumberFormate", validatebynumberFormate);
      $event.target.value = validatebynumberFormate;
    }
  }

}
