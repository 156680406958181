import { Component, OnInit, Input} from '@angular/core';
import { MethodserviceService } from "../../component/record/method/service/methodservice.service";
import { TreeviewService } from '../../service/treeview.service';
import { StockComponentService } from '../../service/stock-component.service';
import { NgxSpinnerService } from "ngx-spinner"; 
import { ValuelistComponent } from '../record/valuelist/valuelist.component';
import { recordSaveModel } from 'src/app/model/recordSaveModel';

@Component({
  selector: 'app-stockmadecomponet',
  templateUrl: './stockmadecomponet.component.html',
  styleUrls: ['./stockmadecomponet.component.scss']
})

export class StockmadecomponetComponent implements OnInit {

  @Input() stockComponentInput;
  @Input() startIndex;
  TabscollectionResult: any = [];
  TabscollectionInsidebudgetResult : any = [];
  TabscollectionInsidebudget : any = [];
  activeNodeId: any;
  IsValidArray: any;
  BindingClassname = '';
  DisplayBudgetDataCollection:any;
  DisplayDerrivationCollection:any;
  ActivePeriodTabStockComponent:any
  constructor( private methodservice: MethodserviceService , 
    public valuelistComponent:ValuelistComponent,
    private SpinnerService: NgxSpinnerService, private TreeviewServices: TreeviewService , private stockComponentService: StockComponentService) { 
    this.IsValidArray = "";
  }


 ngOnAfterViewInit(){
   this.IsValidArray = '';
 } 
 
ngOnInit( ) { 
   this.DefualtCollections();
  }
  ngOnChanges(){ 
    this.DefualtCollections();
    if(this.startIndex!=null){
      this.ActivePeriodTabStockComponent = this.startIndex;
    } else {
      this.ActivePeriodTabStockComponent = 0;
    }
  }
  ChangeName(Result, event){
    if(event.target.value!=null && event.target.value!=""){
      let value = event.target.value;
      this.stockComponentService.ChangeStockComponentName(Result.nodeId, value).subscribe(
        (response=>{

        })
      )
    }
  }
 async DefualtCollections() {
   this.IsValidArray = "";
   this.TabscollectionInsidebudgetResult = [];
   this.TabscollectionInsidebudget = [];
   this.TabscollectionResult = [];
   let validArray = [];
    if ( this.stockComponentInput != '') { 
      this.SpinnerService.show();
      let RecordNodeId  = this.stockComponentInput;
      this.activeNodeId = RecordNodeId;
      this.stockComponentService.getAllStockMadeComponentDetails(RecordNodeId).subscribe(
        (response=>{ 
          try {
            let result:any = response;
            this.TabscollectionResult = result.stockComponentResults;
            this.TabscollectionInsidebudget = result.stockComponentResults;
            this.SpinnerService.hide();
          } catch (error) {
            this.SpinnerService.hide();
          }
         
          
        })
      )
        
    } 
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  AddStockComponent($event) { 
    this.SpinnerService.show();
    this.stockComponentService.addStockMadeComponent($event).subscribe( 
      async (response: any) => {  
        this.SpinnerService.hide();
        try {
         this.addDeleteDefual($event);
        } catch (error) {
          //alert(error);
          this.addDeleteDefual($event);
        }
        this.SpinnerService.hide();
      })
  }

 async addDeleteDefual($event){ 
 
  let validArray = [];
  this.SpinnerService.show();
  this.stockComponentService.getAllStockMadeComponentDetails(parseInt($event)).subscribe(
    (response=>{
      
      let result:any = response;
        this.TabscollectionResult = result.stockComponentResults;
        this.TabscollectionInsidebudget = result.stockComponentResults;
        this.SpinnerService.hide();
    })
  )
    this.SpinnerService.hide();
  }
  DeleteStockComponent($event , index) {
    this.TabscollectionResult = [];
    this.TabscollectionInsidebudgetResult = [];
    this.TabscollectionInsidebudget = [];
    let validArray = [];
    this.SpinnerService.show();
    this.stockComponentService.deleteStockMadeComponent($event , index).subscribe( 
      async (response: any) => { 
        this.SpinnerService.hide();
        this.addDeleteDefual($event);
      })
  }

  recordModified(record) { 
    this.valuelistComponent.recordModified(record);
  }

}

