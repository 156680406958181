import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Userregistrationmodel } from 'src/app/model/userregistrationmodel';
import { UserregistrationService } from 'src/app/service/userregistration.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegisterValidators } from 'src/app/core/validators/register-validators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creat-password',
  templateUrl: './creat-password.component.html',
  styleUrls: ['./creat-password.component.scss']
})
export class CreatPasswordComponent implements OnInit {

  isSubmitted = false;
  busy = false;
  userId = 0;
  Password = new UntypedFormControl('', [Validators.required,
    Validators.minLength(8),
    RegisterValidators.regexValidator(new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), { 'password': 'not valid' })])
  ConfirmPassword = new UntypedFormControl('', [Validators.required])
  userModel: Userregistrationmodel;


  userCreatPasswordForm = new UntypedFormGroup({
    Password: this.Password,
    ConfirmPassword: this.ConfirmPassword,

  }, [RegisterValidators.match('Password', 'ConfirmPassword')]);

  constructor(private httpService: HttpClient, 
    private userregistrationService: UserregistrationService, 
    private SpinnerService: NgxSpinnerService, 
    private _router:Router,
    private injector: Injector,) { }

  ngOnInit() {
    let routerUrl = this._router.url;
    if(routerUrl!=null){
       let strArray = routerUrl.split("/");
       this.userId = parseInt(strArray[2]);
    }
  }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    if (this.userCreatPasswordForm.invalid) {
      return;
    }
    if (this.userCreatPasswordForm.value.Password != this.userCreatPasswordForm.value.ConfirmPassword) {
      return;
    }
    this.SpinnerService.show();
    let Password = this.userCreatPasswordForm.value.Password;
    this.userregistrationService.updatePassword(this.userId, Password).subscribe(
      (response: boolean) => {
        this.SpinnerService.hide();
        if (response) {
          notifier.showSuccess("Password Update Successfully");
          this._router.navigateByUrl('/login');
        }
      }),
      err => {
        this.SpinnerService.hide();
        throw err;
      }

  }

}
