import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, pipe } from 'rxjs';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class EmployeeGroupService {

  constructor(private http:HttpClient) { }

    getEmployeeCollection(NodeId):Observable<any>{
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/getEmployeeGroupCollections/"+NodeId)
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }

    getBonusesCollections(NodeId):Observable<any>{
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/getBonusesCollections/"+NodeId)
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }

    UpdateEmployeeCollection(NodeId,SelectedNodeId): Observable<any>{
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/UpdateEmployeeGroup/"+NodeId+"/"+SelectedNodeId)
      .pipe(
        map((response=>{
            return response;
        }))
      )
    }
    

    addEmployeeCollection(SelectedNodeId, NodeId): Observable<any>{
      return this.http.put(environment.apiUrl+"/api/EmployeeGroupCollections/addEmployeeGroupCollection/"+SelectedNodeId+"/"+NodeId, {Response:JSON})
      .pipe(
        map((response=>{
            return response;
        }))
      )
    }
    deleteEmployeeCollection(SelectedNodeId, NodeId): Observable<any>{
      return this.http.delete(environment.apiUrl+"/api/EmployeeGroupCollections/deleteEmployeeGroupCollection/"+SelectedNodeId+"/"+NodeId)
      .pipe(
        map((response=>{
            return response;
        }))
      )
    }

    saveEmployeeGroupCollection(JsonArray):Observable<any>{
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/savingEmployeeGroupCollection";
      return this.http.post(url, JsonArray, {headers:reqHeaders})
        .pipe(map(
          (response: any) =>
          {
            return response;
          }
        ));
    }

    saveGrossWageRate(valuesNodeId, SelectedIndex, Value){
      let value  = parseFloat(Value).toFixed(2);
      return this.http.put(environment.apiUrl+"/api/EmployeeGroupCollections/saveEmployeeGrossWageRate/"+valuesNodeId+"/"+SelectedIndex+"/"+value,
       {Response:"json"})
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }

   

    getGrossWageRate(valuesNodeId, SelectedIndex){
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/getEmployeeGrossWageRate/"+valuesNodeId+"/"+SelectedIndex)
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }

    getBonusesCommisonCollection(ValueNodeId):Observable<any>{
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/getBonusesCommisonEntry/"+ValueNodeId)
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }
    changeBonusesCommisonDerrivation(DerrivationNodeId,ValueNodeId):Observable<any>{
      return this.http.get(environment.apiUrl+"/api/EmployeeGroupCollections/ChangeBonusCommisonDerivation/"+DerrivationNodeId+"/"+ValueNodeId)
      .pipe(
        map((response=>{
          return response;
        }))
      )
    }
    SetAllAndTotalSaveService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetValueFromBonusDisplayDBD";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }
    SetValuesSaveService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetValueFromBonusesCommisionDisplayDBD";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }

    SetAllAndTotalBonusesService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetAllValueFromBonuses";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }
    SetValuesSaveBonusesService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetValuesFromBonusesCommision";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }

    SetAllAndTotalBonusesRecordService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetAllValueFromRecordBonuses";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }
    SetValuesSaveBonusesRecordService( saveRecordValuelist): Observable<any> { 
      let reqHeaders = new HttpHeaders().set('Content-Type','application/json');
      let url = environment.url+"/api/EmployeeGroupCollections/SetValuesFromRecordBonuses";
      return this.http.post(url, saveRecordValuelist, {headers:reqHeaders})
         .pipe(map(
           (response) =>
           {
             return response;
           }
         ));
    }

     /**
     * selectedNdde API
     */

      selectedNodeService( seletedid , NodeId, ValuenodeId):Observable<any>{
        let url = environment.url+"/api/EmployeeGroupCollections/SelectNode/"+NodeId+"/"+seletedid+"/"+ValuenodeId;
        return this.http.get(url, { responseType: "json" })
         .pipe(map(
           (response: any) =>
           {
             return response;
           }
         ));
      }
  
      unSelectedNodeService(Unseletedid , NodeId, ValuenodeId):Observable<any>{
        let url = environment.url+"/api/EmployeeGroupCollections/UnSelectNode/"+NodeId+"/"+Unseletedid+"/"+ValuenodeId;
        return this.http.get(url, { responseType: "json" })
         .pipe(map(
           (response: any) =>
           {
             return response;
           }
         ));
      }
    

}
