import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceService } from '../services/service.service'
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ListForecastComponent } from '../list-forecast.component';
import * as moment from 'moment';
import { Forecastmodel } from '../services/forecastmodel';
import { NotificationService } from 'src/app/core/services/notification.service';
declare const $: any;
@Component({
  selector: 'app-newconsolidation',
  templateUrl: './newconsolidation.component.html',
  styleUrls: ['./newconsolidation.component.scss']
})
export class NewconsolidationComponent implements OnInit {
  createForecastForm: UntypedFormGroup;
  details: any = [];
  months = []
  title = 'New Consolidation';
  currency = []
  consolidationType = [];
  ForecastStartdatevalue: any;
  RegionalCollection: any;
  selectedConsolidationType: any;
  startdate: any;
  public date = new Date();
  forecaststartDate: Date;
  forecastlist = [];
  UserId = localStorage.getItem('CurrentUserNodeId');
  selectedconsolidationType = "ByName";
  ForecastClientCountryCollection = [];
  constructor(private calendar: NgbCalendar,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private NewForecastService: ServiceService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private listForecastComponent: ListForecastComponent, private injector: Injector) {
    var DateStart = new Date();
    this.startdate = moment(DateStart, "MMM YYYY").format("MMM YYYY");
    this.createForecastForm = this.formBuilder.group({
      company_name: [''],
      Subheading_name: [''],
      Description: [''],
      numberofyears: ['1'],
      ForecastCountry: ['0'],
      startdate: [this.startdate],
      LocalCurrency: ['0'],
      FirstdataPeriod: ['0'],
      FirstDailyCashFlowPeriod: ['0'],
      FirstdailyPeriodindex: ['0'],
      ConsolidationType: ['']
    });
    this.fillCOnsolidationType();
    this.fillForecasts();
  }
  onToggleOpening(evet) {
    console.log("onToggleOpening", evet);
  }
  fillForecasts() {
    if (this.UserId != null) {
      this.NewForecastService.getForecastList(this.UserId).subscribe(
        (response: Forecastmodel[]) => {
          this.SpinnerService.hide();
          this.forecastlist = response;
          console.log("console Forecast", this.forecastlist);
        })
    }

  }

  fillCOnsolidationType() {
    this.consolidationType.push('By Name');
    this.consolidationType.push('By GL Code');
  }

  validatestartDate($event: any, nodeId: any) {
    let forecastM: any;
    var isChecked: boolean;
    this.forecastlist.forEach(e => {
      if (e.nodeId === nodeId) {
        forecastM = e;
        if (e.consolSelected === true) {
          isChecked = true;
        }
      }
    })
    console.log("$event consol", $event);
    if (isChecked) {
      $event.checked = false;
      $event.owner.checked = false;
      this.forecastlist.forEach(e => {
        if (e.nodeId === nodeId) {
          e.consolSelected = false;
        }
      })
    }
    else {
      const notifier = this.injector.get(NotificationService);
      var consolDate: any = (<HTMLInputElement>document.getElementById('datepickerCon'));
      let consolStartDate: any = consolDate.value

      let startD = moment(new Date(forecastM.startdate), "MMM YYYY").format("MMM YY");

      if (consolStartDate !== startD) {
        notifier.showError("Consolidation start date has to match with Forecast start date.");
      }
      this.forecastlist.forEach(e => {
        if (e.nodeId === nodeId) {
          e.consolSelected = (consolStartDate === startD);

        }
      })
      $event.checked = (consolStartDate === startD);
      $event.owner.checked = (consolStartDate === startD);
    }

  }
  async ngOnInit() {
    this.getloopyears();
    let newDate = '';
    $("#datepickerCon").on("change", function () {
      this.setAttribute(
        "value",
        this.value
      )
    }).trigger("change")
    $("#datepickerCon").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months"
    });
    let JsonCollectionArray = localStorage.getItem("ForecastClientCountryCollection");
    //console.log("JsonCollectionArray", JsonCollectionArray);
    if (JsonCollectionArray != null && JsonCollectionArray != undefined && JsonCollectionArray != "null") {
      this.ForecastClientCountryCollection = JSON.parse(JsonCollectionArray);
      this.RegionalCollection = this.ForecastClientCountryCollection;
      this.currency = [];
      this.RegionalCollection.forEach((element, index) => {
        if (index == 0)
          element.regionalCurrencyCollection.forEach(children => {
            this.currency.push(children);
          });
        localStorage.setItem("Currency", JSON.stringify(this.currency));
        var storedNames = JSON.parse(localStorage.getItem("Currency"));
        localStorage.setItem("ForecastClientCountryCollection", JSON.stringify(this.ForecastClientCountryCollection))
        // console.log(storedNames);
      });
    } else {
      this.getRegioanlcollectionAndCurrency(this.UserId);
    }

    await this.delay(300);


  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getRegioanlcollectionAndCurrency(UserId) {
    if (UserId != null) {
      this.SpinnerService.show();
      let jsonCountryCollection = null;
      jsonCountryCollection = localStorage.getItem("ForecastClientCountryCollection");
      console.log("jsonCountryCollection", jsonCountryCollection);
      if (localStorage.getItem("ForecastClientCountryCollection") != "null" && localStorage.getItem("ForecastClientCountryCollection") != null) {
        this.RegionalCollection = JSON.parse(jsonCountryCollection);
        console.log("jsonCountryCollection else of response", this.RegionalCollection);
        if (this.RegionalCollection != null) {
          this.currency = [];
          this.RegionalCollection.forEach((element, index) => {
            if (index == 0)
              element.regionalCurrencyCollection.forEach(children => {
                this.currency.push(children);
              });
            localStorage.setItem("Currency", JSON.stringify(this.currency));
            var storedNames = JSON.parse(localStorage.getItem("Currency"));
          });
        }
        this.SpinnerService.hide();
      } else {
        this.NewForecastService.getRegionalCollection(UserId).subscribe(
          (response: any) => {
            this.SpinnerService.hide();
            this.ForecastClientCountryCollection = response;

            this.RegionalCollection = response;
            console.log("jsonCountryCollection inside response", this.RegionalCollection);
            if (this.RegionalCollection != null) {
              this.currency = [];
              this.RegionalCollection.forEach((element, index) => {
                if (index == 0)
                  element.regionalCurrencyCollection.forEach(children => {
                    this.currency.push(children);
                  });
                localStorage.setItem("Currency", JSON.stringify(this.currency));
                var storedNames = JSON.parse(localStorage.getItem("Currency"));
                localStorage.setItem("ForecastClientCountryCollection", JSON.stringify(this.ForecastClientCountryCollection))
                // console.log(storedNames);
              });
            }
          }
        )
      }
    }
  }
  alertconsolidaitontype($event) {
    if ($event.target.value == "By Name") {
      this.selectedConsolidationType = "ByName";
    } else if ($event.target.value == "By GL Code") {
      this.selectedConsolidationType = "ByGLCode";
    }
  }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    var forecastSelected = [];
    var associateInterest = [];
    this.forecastlist.forEach(element => {
      if (element.consolSelected) {
        forecastSelected.push(element.nodeId);
        var inputValue: any = (<HTMLInputElement>document.getElementById(element.nodeId));
        var nodeid = element.nodeId;
        var associateIneterst = inputValue.value;
        if (parseFloat(associateIneterst) > 0) {
          let json = {
            nodeid,
            associateIneterst
          }
          associateInterest.push(json);

        }

      }
    });
    if (this.currency.length == 0) {
      notifier.showError("An error occured, please load the client again and try to create consolidation.");
      return false;
    }
    if (forecastSelected.length <= 1) {
      notifier.showError("Select atleast 2 Forecasts to consolidate.");
      return false;
    }
    var inputValue: any = (<HTMLInputElement>document.getElementById('datepickerCon'));
    this.converttoDate(inputValue.value);
    let CompanyName = this.createForecastForm.value.company_name;
    let SubHeading = this.createForecastForm.value.Subheading_name;
    let NumberOfYears = parseInt(this.createForecastForm.value.numberofyears);
    let SelectedCountryIndex = parseInt(this.createForecastForm.value.ForecastCountry);

    let ForecastStartDate: any = moment(this.forecaststartDate).format('DD/MM/YYYY');
    let SelectedCurrencyIndex = parseInt(this.createForecastForm.value.LocalCurrency);

    let FirstDataPeriodIndex = parseInt(this.createForecastForm.value.FirstdataPeriod);
    if (Number.isNaN(FirstDataPeriodIndex)) {
      FirstDataPeriodIndex = 0;
    }
    let selectedconsolidationType = this.createForecastForm.value.consolidationType;
    if (selectedconsolidationType === undefined) {
      selectedconsolidationType = 'ByName';
    }

    let FirstDCPeriodIndex = parseInt(this.createForecastForm.value.FirstDailyCashFlowPeriod);
    if (Number.isNaN(FirstDCPeriodIndex)) {
      FirstDCPeriodIndex = 0;
    }
    let FirstdailyCashPeriodindex = parseInt(this.createForecastForm.value.FirstdailyPeriodindex);
    if (Number.isNaN(FirstdailyCashPeriodindex)) {
      FirstdailyCashPeriodindex = 0;
    }
    let UserId = this.UserId;

    let clientID = localStorage.getItem("clientID");
    let jsonArray = {
      UserId,
      CompanyName,
      SubHeading,
      NumberOfYears,
      SelectedCountryIndex,
      ForecastStartDate,
      SelectedCurrencyIndex,
      FirstDataPeriodIndex,
      FirstDCPeriodIndex,
      FirstdailyCashPeriodindex,
      selectedconsolidationType,
      clientID,
      forecastSelected,
      associateInterest
    }

    // console.log(jsonArray);
    this.SpinnerService.show();

    this.NewForecastService.CreateConsolidation(jsonArray).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        localStorage.setItem("consolidaitonCalculation", "true");
        localStorage.setItem("ConsolidaitonForecast", response);
        console.log("ConsolidaitonForecast", response);
        window.location.reload();
      },
      error => {
        this.SpinnerService.hide();
        throw error;
      }
    )
  }

  ngOnChange() {
    $("#datepickerCon").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months",
    });

  }

  converttoDate(date: any) {
    let selectedDate = '1 ' + date;
    let Newdate: Date = new Date(selectedDate);
    this.forecaststartDate = Newdate;
  }


  onOptionsSelected($event) {
    this.currency = [];
    this.RegionalCollection.forEach((element, index) => {
      if (index == parseInt($event.target.value)) {
        element.regionalCurrencyCollection.forEach(children => {
          this.currency.push(children);
        });
        // console.log(this.currency)
      }

    });

  }
  changecompayname_event($event) {
    this.title = $event.target.value;
  }
  ngAfterContentChecked() {
    var inputValue: any = (<HTMLInputElement>document.getElementById('datepickerCon'));
    if (inputValue.value != '' && inputValue.value != undefined) {
      this.months = [];
      let Newdate = moment(inputValue.value, "MMM YYYY").format("MMM YYYY");
      var date = new Date(Newdate);
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      for (var i = 0; i < 12; i++) {
        this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() + 1);
      }
    }
  }
  getloopyears() {
    var date = new Date();
    // console.log(date)
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (var i = 0; i < 12; i++) {
      this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
      date.setMonth(date.getMonth() + 1);
    }
  }
  closeForecast() {
    this.forecastlist.forEach(e => {
      e.consolSelected = false;
    })
    this.listForecastComponent.closenewconsolidationtab();
  }

}
