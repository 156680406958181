import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { formatDate } from '@angular/common';
import { ServiceService } from '../../../component/list-forecast/services/service.service'
import * as moment from 'moment';
import { Forecastmodel } from '../../list-forecast/services/forecastmodel';
declare const $: any;
import { ForecastSettingService } from '../../../service/SettingServices/forecastSettingService/forecast-setting.service';

import { DetailservicesService } from 'src/app/common/detail/details-services/detailservices.service';

@Component({
  selector: 'app-forecast-setting-details',
  templateUrl: './forecast-setting-details.component.html',
  styleUrls: ['./forecast-setting-details.component.scss']
})
export class ForecastSettingDetailsComponent implements OnInit {

  collections: any = [];
  ForecastSettingCollection: any = [];
  ForecastNodeId: any;
  createForecastForm: UntypedFormGroup;
  details: any = [];
  months = []
  startdate: any;
  dateStart = "01-02-2020";
  UserId = localStorage.getItem('CurrentUserNodeId');
  isConsolidation = false;
  constructor(
    //private calendar: NgbCalendar,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private NewForecastService: ServiceService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private ForecastSettingService: ForecastSettingService,
    private DetailservicesServices: DetailservicesService
  ) {
  }

  async ngOnInit() {
    let newDate = '';
    $("#datepickers").on("change", function () {

      this.setAttribute(
        "value",
        this.value
      )
      //this.MonthChangeBasedOnStartDate();
    }).trigger("change")
    $("#datepickers").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months",
    });
    this.ForecastNodeId = localStorage.getItem('ForecastId');
    this.ForecastSettingCollection = [];
    this.ForecastSettingService.getForecastSettingCollections(this.ForecastNodeId).subscribe(
      (async (response: any) => {
        this.ForecastSettingCollection = response;
        var date = new Date(this.ForecastSettingCollection.startdate);
        this.months = response.getMonthDisplay;
        var Month = date.getMonth() + 1;
        var Years = date.getFullYear();
        var day = date.getDate();
        var alldatecombine = Month + " " + date.getFullYear();
        var DateStart = new Date();
        this.startdate = moment(date, "MMM YY").format("MMM YY");
        //this.startdate = alldatecombine;
      })
    )
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
  }


  DatePickerEventClick(ForecastStartingDate, event) {
    $("#datepickers").on("change", function () {
      // console.log(this.value);
      this.setAttribute(
        "value",
        this.value
      )
    }).trigger("change")
    $("#datepickers").datepicker({
      format: "M yy",
      startView: "months",
      minViewMode: "months"
    });

    $("#datepickers").datepicker().focus();
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterContentChecked() {
    if (this.ForecastSettingCollection != '') {
      var inputValue: any = (<HTMLInputElement>document.getElementById('datepickers'));
      if (inputValue != null && inputValue.value != '' && inputValue.value != undefined) {
        let Newdate = moment(inputValue.value, "MMM YYYY").format("MMM YYYY");
        var getFullYears = inputValue.value.split(" ");
        var getMonth = getFullYears[0];
        var getYears = "20" + getFullYears[1];
        var date = new Date(getMonth + " " + getYears);
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        for (var i = 0; i < 12; i++) {
          this.months[i].name = monthNames[date.getMonth()] + ' ' + date.getFullYear();
          //this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
          date.setMonth(date.getMonth() + 1);
        }
      }
    }

  }

  onSubmit() {
    var inputValue: any = (<HTMLInputElement>document.getElementById('datepickers'));
    let CompanyName = (<HTMLInputElement>document.getElementById('companyName')).value;
    let SubHeading = (<HTMLInputElement>document.getElementById('subHeading')).value;
    let description = (<HTMLInputElement>document.getElementById('description')).value;
    let NumberOfYears = parseInt((<HTMLInputElement>document.getElementById('numberOfYears')).value);
    let ForecastStartDate: any = inputValue.value
    var getFullYears = inputValue.value.split(" ");
    var getMonth = getFullYears[0];
    var getYears = "20" + getFullYears[1];
    var getDay = "2";
    var date = new Date(getDay + "-" + getMonth + "-" + getYears);
    ForecastStartDate = new Date(getDay + "/" + getMonth + "/" + getYears);
    let FirstDataPeriodIndex = parseInt((<HTMLInputElement>document.getElementById('FirstdataPeriodindex')).value);
    // if (FirstDataPeriodIndex == NaN) {
    //   FirstDataPeriodIndex = 0;
    // }
    // let FirstDCPeriodIndex = parseInt((<HTMLInputElement>document.getElementById('FirstDailyCashFlowPeriod')).value);
    // if (FirstDCPeriodIndex == NaN) {
    //   FirstDCPeriodIndex = 0;
    // }
    let UserId = this.UserId;

    let jsonArray = {
      UserId,
      CompanyName,
      SubHeading,
      description,
      NumberOfYears,
      ForecastStartDate,
      FirstDataPeriodIndex,
      //FirstDCPeriodIndex,
    }
    let ForecastNodeId = localStorage.getItem("ForecastId");
    this.SpinnerService.show();
    this.DetailservicesServices.UpdateForecastDetails(ForecastNodeId, jsonArray).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ngOnInit();
        //this.router.navigateByUrl('/forecast');
      }
    )

  }
  OnCancel() {
    this.router.navigateByUrl('/forecast');
  }

  ngOnChange() {
    $("#datepickers").on("change", function () {
      this.setAttribute(
        "value",
        this.value
      )
      //this.MonthChangeBasedOnStartDate();
    }).trigger("change")
    this.ForecastNodeId = localStorage.getItem('ForecastId');
    this.ForecastSettingCollection = [];
    this.ForecastSettingService.getForecastSettingCollections(this.ForecastNodeId).subscribe(
      (async (response: any) => {
        this.ForecastSettingCollection = response;
        var date = new Date(this.ForecastSettingCollection.startdate);
        this.months = response.getMonthDisplay;
        var alldatecombine = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
        //this.startdate = moment(date.getDate(), "dd/mm/yy").format("dd/mm/yy");
        //this.startdate = date;
        var DateStart = new Date();
        this.startdate = moment(DateStart, "MMM YY").format("MMM YY");
      })
    )
  }
  closeForecast() {
  }

}
