import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map, retry } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Sections } from '../model/sections';
import { Record } from '../model/record';

@Injectable({
  providedIn: 'root'
})
export class OpeningService {
  private headers: Headers;
  Opening: Sections[] = [];
  constructor(private http: HttpClient) { }

  /**
   * Function For get all children from section_Id
   * @returns response
   */
  getEnterOpeningDefaultResult(ForecastId): Observable<Sections[]> {
    return this.http.get(environment.url + "/api/forecast/Enteropening/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: Sections[]) => {
          let result: any = response[0];
          return result.row.table;
        }
      ));
  }
  CalculateAllSystemRecords(ForecastId): Observable<any> {
    return this.http.get(environment.url + "/api/forecast/CalculateAllSystemRecords/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  ImportDataEnterOpening(EnterOpeningbudgetData): Observable<Sections[]> {
    console.log("EnterOpeningbudgetData", EnterOpeningbudgetData);
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.url + "/api/Actual/SetValueFromImportExcel", EnterOpeningbudgetData, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          //  console.log(response);
          return response;
        }
      ))
  }

  /**
   * Function For get all children from section_Id
   * @returns response
   */
  EnterActualUpdateValue(ForecastId, Id, LocalCurrency, BudgetDataNodeId, mul, Value, currentAcutalIndex): Observable<Sections[]> {
    return this.http.get(environment.url + "/api/Actual/EnterActualUpdateValue/" + ForecastId + "/" + Id + "/" + LocalCurrency + "/" + BudgetDataNodeId + "/" + mul + "/" + Value + "/" + currentAcutalIndex, { responseType: "json" })
      .pipe(map(
        (response: Sections[]) => {
          return response;
        }
      ));
  }

  /**
  * Function For get all children from section_Id
  * @returns response
  */
  UpdateEnterOpeningValue(ForecastId, Id, LocalCurrency, BudgetDataNodeId, mul, Value): Observable<Sections[]> {
    return this.http.get(environment.url + "/api/Actual/EnteropeningUpdateValue/" + ForecastId + "/" + Id + "/" + LocalCurrency + "/" + BudgetDataNodeId + "/" + mul + "/" + Value, { responseType: "json" })
      .pipe(map(
        (response: Sections[]) => {
          return response;
        }
      ));
  }

  ResetOpening(ForecastId): Observable<any> {
    return this.http.get(environment.url + "/api/forecast/Enteropening/resetOpening/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

  AutoBalance(ForecastId, difference, index): Observable<any> {
    console.log("service", ForecastId, difference);
    return this.http.get(environment.url + "/api/Actual/AutoBalance/" + ForecastId + "/" + index + "/" + difference, { responseType: "json" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }

}
