import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FormulaService {
  constructor(private http: HttpClient) { }
    getFormulaBudgetCollection(NodeId): Observable<any[]> {
      return this.http.get(environment.url + "/api/Formula/DisplayFormulaBudget/" + NodeId)
        .pipe(map(
            (response: any[]) => {
                return response;
            }
        ));
    }
    getFormulaDetails(NodeId): Observable<any[]> {
      return this.http.get(environment.url + "/api/Formula/FormulaPartVM/" + NodeId)
        .pipe(map(
            (response: any[]) => {
                return response;
            }
        ));
    }
    SavetIncludeType(NodeId,value): Observable<any[]> {
      return this.http.get(environment.url + "/api/Formula/SaveIncludeType/" + NodeId+"/"+value)
        .pipe(map(
            (response: any[]) => {
                return response;
            }
        ));
    }
    SaveNegataValue(NodeId,value): Observable<any[]> {
      return this.http.get(environment.url + "/api/Formula/SaveNegataValue/" + NodeId+"/"+value)
        .pipe(map(
            (response: any[]) => {
                return response;
            }
        ));
    }
    
    getSelectedFormulaDetails(NodeId,SelectedNodeId,NewNodeType): Observable<any[]> {
      return this.http.get(environment.url + "/api/Formula/FormulaPartVM/" + NodeId+"/"+SelectedNodeId+"/"+NewNodeType)
        .pipe(map(
            (response: any[]) => {
                return response;
            }
        ));
    }

    addFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/AddFormulaPartVM/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }
    ReplaceFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/ReplaceFormulaPartVm/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }
    DeleteFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/DeleteFormulaPartVm/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }
    ClearFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/ClearFormulaPartVm/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }
    AddLeftParenthesisFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/AddLeftParenthesisFormulaPartVM/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }

    AddRightParenthesisFormulaOparand(NodeId,NodeType,newObject,selectedNodeId): Observable<any[]>{
      return this.http.get(environment.url + "/api/Formula/AddRightParenthesisFormulaPartVM/" + NodeId+"/"+NodeType+"/"+newObject+"/"+selectedNodeId)
      .pipe(map(
          (response: any[]) => {
              return response;
          }
      ));
    }

    
}
