<div class="insidecontainer" style="width: 1209px; height: 685px;overflow: scroll;">
    <igx-dialog-title class="dialogtitle">
        <div class="title-container">
            <div class="dialog-title">Bonuses</div>
        </div>
    </igx-dialog-title>
        <div class="bs-example" style="height: 490px; margin: 6px 0 0 0;" *ngIf="PeriodTabView.length>0">
            <ul class="nav nav-tabs" style="margin: 1px 3px 16px 11px">
                <li class="nav-item" style="margin: 0 8px -2px -1px;">
                    <select  form="carform" class="form-control" style="background: #1e90ff;color: white;font-size: 12px;font-weight: bold;height: 38px;">
                        <option  value="Annual" ng-reflect-value="Annual">Annual</option>
                        <option  value="All" ng-reflect-value="All">All</option>
                </select></li>
                <li class="nav-item" 
                *ngFor="let result of PeriodTabView[0].results;  let i=index" (click)="ChangePeriodType(result, i)">
                    <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}" data-toggle="tab">{{result.longLabel}}</a>
                </li>
                <li class="nav-item">
                    <div class="saveButton">
                        <input type="button" class="OkSaveButtonColor btnsave" value="Calculate & Save"
                            (click)="SaveData()" />
                    </div>
                </li>
            </ul>
            
            <div class="tab-content" *ngIf="Activetabmenu!=null">
                <div class="tab-pane fade testingData active">
                    <div class="col-sm-12" style="padding: 0px;">
                        <div class="container-fluid">
                            <div class="row flex-row flex-nowrap">
                            <div class="listingvaluelist valuelistmain{{getBonusesCollection[0].englishLabel}} col-sm-3"
                            *ngIf="getBonusesCollection!=''">
                                <igx-card elevated>
                                    <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                        <igx-card-header>
                                            <div class="valuelist-title">
                                                <label style="word-break: break-all;"> {{getBonusesCollection[0].englishLabel}}</label>
                                            </div>
                                        </igx-card-header>
                                        <igx-card-content>
                                            <div class="methodandvaluelistcontain">
                                                <app-commombonuses [valuelistdata]='getBonusesCollection[0]'
                                                [valueListDerrivation]=""
                                                [PeriodtabViewData]="BonusesDisplayBudgetResults"
                                                [startIndex]="Activetabmenu"
                                                [ValueListMonthDisplay]="MonthDisplayView"
                                                (recordModifiedEvent)="recordModified($event)">
                                            </app-commombonuses>
                                            </div>
                                        </igx-card-content>
                                    </div>
                                </igx-card>
                            </div>
                            <div class="listingvaluelist valuelistmain{{getCumulativeBonuses[0].englishLabel}} col-sm-3"
                            *ngIf="getCumulativeBonuses!=''">
                                <igx-card elevated>
                                    <div igxLayout igxLayoutDir="column" igxFlex igxFlexGrow="1">
                                        <igx-card-header>
                                            <div class="valuelist-title">
                                                <label style="word-break: break-all;"> {{getCumulativeBonuses[0].englishLabel}}</label>
                                            </div>
                                        </igx-card-header>
                                        <igx-card-content>
                                            <div class="methodandvaluelistcontain">
                                                <app-commombonuses [valuelistdata]='getCumulativeBonuses[0]'
                                                [valueListDerrivation]=""
                                                [PeriodtabViewData]="CumulativeBonusesDisplayBudgetResults"
                                                [startIndex]="Activetabmenu"
                                                [ValueListMonthDisplay]="MonthDisplayView">
                                            </app-commombonuses>
                                            </div>
                                        </igx-card-content>
                                    </div>
                                </igx-card>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</div>

