<div class="">
    <div class="">
        <h3>Contract Retentions Settings</h3>
    </div>
    <div class="col-sm-12" style="margin-top:1%; height: 675px;">

        <div class="col-sm-6">
            <div class="">
                <button class="btn SettingSaveButtonColor col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                    (click)="AddContractRetension()">New</button>
                <button class="btn SettingCancelButtonColor col-sm-3" style="font-size: 16px;"
                    (click)="DeleteContractRetension()">Delete</button>
            </div>
            <table class="table table-bordered">
                <thead>
                    <th scope="col">*</th>
                    <th scope="col">Name</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ContractRetension of ContractRetensionCollection; let i=index;"
                        [ngClass]="{'selected':i==ContractRetensionActiveRecord}" (click)="changeValueList(i)">
                        <td>*</td>
                        <td [ngClass]="{'Active':i==ContractRetensionActiveRecord}">
                            <input type="text" style="border:none" value="{{ContractRetension.name}}"
                                (blur)="ContractRetensionNameChange(i, ContractRetension, $event)"
                                name="ContractRetensionName[i]" class="col-sm-10"
                                [ngClass]="{'ContractRetensionname':i==ContractRetensionActiveRecord}" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-4">
            <div id="Currencytab" style="padding: 5px;">
                <div class="col-sm-12" style="padding: 0px;">
                    <button class="btn SettingSaveButtonColor col-sm-4" style="font-size: 16px;margin: 0 4px 3px 0px;"
                        (click)="AddBanding(ContractRetensionActiveRecord,ContractRetensionBandingActiveRecord)">Add
                        Band</button>
                    <button class="btn SettingCancelButtonColor col-sm-4" style="font-size: 16px;"
                        (click)="DeleteBanding(ContractRetensionActiveRecord,ContractRetensionBandingActiveRecord)">Delete
                        Band</button>
                </div>
                <table class="table table-bordered col-sm-12">
                    <thead>
                        <th scope="col">Amount</th>
                        <th scope="col">Percentage</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ContractRetensionResult of ContractRetesnionBindingArray.budgetValueList; let i=index;"
                            [ngClass]="{'Active': i==ContractRetensionBandingActiveRecord}"
                            (click)="ChangeBandingList(i)">
                            <td>
                                <input type="text" style="border:none"
                                    [ngClass]="{'Active': i==ContractRetensionBandingActiveRecord}"
                                    value="{{ContractRetensionResult.amount}}"
                                    (blur)="AddAmount(ContractRetensionResult, $event)" class="col-sm-10" />
                            </td>
                            <td>
                                <input type="text" style="border:none"
                                    [ngClass]="{'Active': i==ContractRetensionBandingActiveRecord}"
                                    value="{{ContractRetensionResult.percentage}}"
                                    (blur)="AddPercentage(ContractRetensionResult, $event)" class="col-sm-10" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="button-sample row col" style="float: right;">
        <button class="btn OkSaveButtonColor col-sm-1" (click)="SettingSave()"
            style="font-size: 16px;margin: 0px 7px 0 0;">Save</button>
        <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingClose()"
            style="font-size: 16px;margin: 0px 7px 0 7px;">Close</button>

    </div>
</div>
<!-- <div class="button-sample row col" style="float: right;">
    <button class="btn btn-primary col-sm-1" style="font-size: 16px;" >Ok</button>
    <button class="btn btn-secondary col-sm-1" style="font-size: 16px;" >Cancel</button>
</div> -->