import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private router: Router, private jwtHelper: JwtHelperService, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem("jwt");
    const refreshToken = localStorage.getItem("refresh_token");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      
      //console.log("decodetoken", this.jwtHelper.decodeToken(token))
      return true;
    }
    console.log("auth-gaurd tryRefreshingTokens");
    const isRefreshSuccess: boolean = this.tryRefreshingTokens(token);
    //console.log("isRefreshSuccess", isRefreshSuccess);
    if (!isRefreshSuccess) {
      this.router.navigate(["login"]);
    }
    return isRefreshSuccess;

  }



  private tryRefreshingTokens(token: string): boolean {
    const refreshToken = localStorage.getItem("refresh_token");
    if (token == null || refreshToken == null) {
      return false;
    }
    console.log("auth-gaurd refreshtoken called");
    const credentials = { accessToken: token, refreshToken: refreshToken };
    this.authService.refreshToken(credentials).subscribe(res => { }, error => {
      let errorMsg = error;
    });
    const newtoken = localStorage.getItem("jwt");
    if (newtoken && !this.jwtHelper.isTokenExpired(newtoken)) {
      //console.log("decodetoken", this.jwtHelper.decodeToken(newtoken))
      return true;
    }
    else {
      return false;
    }
  }
}
