<app-header></app-header>
<div class="sample-column">
    <div style="width: 100%; display: flex;">
    </div>
    <div class="card-wrapper">
        <igx-content>
            <div class="col-sm-12" style="padding: 0px; margin: 0px;">
                <div class="tab-content clearfix">
                    <div class="tab-pane active">
                        <div class="col-sm-12 settings">
                            <div class="col-sm-8" style="border-right: 1px solid;">
                                <div class="col-sm-12">
                                    <h2 class="headingtitle">Xero Authentication</h2>
                                </div>
                                <div class="col-sm-12" style="margin: 0 0 24px 0;">
                                    <div class="col-sm-2" style="padding: 0px;">
                                        <button class="btn OkSaveButtonColor xeroAuthentication col" (click)="XeroAuthentication()" >Xero Authentication</button>
                                        <button class="hide" id="xeroBuildUrl" (click)="OpenLink()" value="" ></button>
                                    </div>
                                    <!-- <div class="col-sm-2" >
                                        <button class="btn OkSaveButtonColor xeroAuthentication col" style="width: 80%;margin: 0 0 0 3px;" (click)="GetXerotenants()"> Get Xero Tenant</button>
                                    </div> -->
                                    <div class="col-sm-3 {{XeroAutorizationisConnected}}" style="padding: 0px;margin: -26px 0 0 0;">
                                        <div class="col" *ngIf="Xerotenants.length==0">
                                            <label class="col">Organization</label>
                                            <select name="selectedOrganization" (click)="GetXerotenants()" class="xeroOrganization">
                                                <option *ngFor="let tenants of Xerotenants; let i=index" [selected]="tenants.tenantId == selectedTennantId" value="{{tenants.tenantId}}">{{tenants.tenantName}}</option>
                                            </select>
                                        </div>
                                        <div class="col" *ngIf="Xerotenants.length>0">
                                            <label class="col">Organization</label>
                                            <select name="selectedOrganization" (change)="changeOrganization($event)" class="xeroOrganization">
                                                <option *ngFor="let tenants of Xerotenants; let i=index" [selected]="tenants.tenantId == selectedTennantId" value="{{tenants.tenantId}}">{{tenants.tenantName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-5" style="padding: 0px;">
                                        <button class="btn OkSaveButtonColor savecancelButton col-sm-3" 
                                        [ngClass]="{'disabled': !displayConnect}" (click)="SaveXeroOrganization()"> Connect</button>
                                        <button class="btn OkSaveButtonColor savecancelButton col-sm-4" 
                                        [ngClass]="{'disabled': !displayDisconnect}"
                                        (click)="Disconnection()"> Disconnect</button>
                                        <button class="btn OkSaveButtonColor savecancelButton col-sm-4" 
                                        [ngClass]="{'disabled': !displayButtonMaping}"
                                        (click)="Maping()"> Mapping's</button>
                                    </div>
                                    <div class="col-sm-2" style="padding: 0px;">
                                        <button class="btn OkSaveButtonColor savecancelButton col" 
                                        [ngClass]="{'disabled': !displayButtonImport}"
                                        (click)="ImportIntegration()" style="margin: 0 0 0 -16px;">Import</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="col-sm-12 ImportData">
                                    <div class="col-sm-12">
                                        <h2 class="headingtitle">Import Data Setting</h2>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="col-sm-6">
                                            <div class="col" style="padding: 0px;">
                                                <input type="checkbox" class="col-sm-3" [checked]="trackingCodevalue" (change)="trackingcode($event)" style="height: 20px;"/><label>Include Tracking Codes</label>  
                                            </div>
                                            <div class="col" style="padding: 0px;">
                                                <input type="checkbox" class="col-sm-3" [checked]="archiveAccount" (change)="allArchivedcode($event)" style="height: 20px;"/><label>Include Archived Accounts</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="col" style="padding: 0px;">
                                                <input type="checkbox" class="col-sm-3" [checked]="allGLAccount" (change)="allAccountcode($event)" style="height: 20px;"/><label>Include All Accounts</label>
                                            </div>
                                        </div>      
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                       
                        <div class="col-sm-12 Mapping" *ngIf="displayMaping==true">
                           
                            <div class="col-sm-12" *ngIf="displayMaping==true">
                                <app-report-tree></app-report-tree>
                            </div>
                        </div>
                        <div class="col-sm12 Import" *ngIf="displayImport==true">
                            <div class="col-sm-12" *ngIf="displayImport==true">
                                <app-import-data></app-import-data>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </igx-content>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>
