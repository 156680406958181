import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { BaseInputGroupComponent } from '../base-input.component';
import { IgxSnackbarComponent, IGX_INPUT_GROUP_TYPE } from '@infragistics/igniteui-angular';
// code for auth
import { AuthService } from '../core';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PermissionService } from '../core/services/permission.service';
import { NotificationService } from '../core/services/notification.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [{ provide: IGX_INPUT_GROUP_TYPE, useValue: 'box' }]
})
export class LoginComponent extends BaseInputGroupComponent implements OnInit, OnDestroy {
  //@ViewChild('snackbar', { static: true }) public igxSnackBar: IgxSnackbarComponent;
  loginForm: FormGroup;
  isSubmitted = false;
  busy = false;
  username = '';
  password = '';
  loginError = false;
  islogin: boolean;
  private subscription: Subscription;



  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    public igxSnackBar: IgxSnackbarComponent,
    private permissionService: PermissionService, private injector: Injector
  ) {
    super();
  }

  ngOnInit() { 
    
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
 
    localStorage.setItem("ForecastClientCountryCollection", null);
    localStorage.removeItem("treeview");
    this.islogin = false;
    this.subscription = this.authService.user$.subscribe((x) => {
      if (this.route.snapshot.url[0].path === 'login') {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (x && accessToken && refreshToken) {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
          this.router.navigate([returnUrl]);
        }
      }
    });
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    // console.log("Submit");
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      // console.log("Submit Invalid");
      return;
    }
    this.SpinnerService.show();
    this.busy = true;
    localStorage.setItem('userdata', '1');
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';

    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.islogin = true;
          localStorage.setItem("userEmailID", this.loginForm.value.email);
          localStorage.setItem("UpgradePopUp", 'true');
          this.router.navigate([returnUrl]);
          this.SpinnerService.hide();
        },
        err => {
          this.SpinnerService.hide();
          this.loginError = true;
          this.islogin = false;
          this.router.navigateByUrl("/login");
          throw err;

        }
      );
    //this.authService.getUserPermission();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }


}
