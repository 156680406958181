import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeCalculation'
})
export class EmployeeCalculationPipe implements PipeTransform {

  transform(value: any, args?: any): any { 
    console.log(value);
    if(value!=null){ 

      if (this.check(value.toString()) == true) {
        let data = value.replace(/\(/g, '-').replace(/\)/g, '');
        if(data.charAt(0) == '-'){ 
          let decimalconvert = parseFloat(data).toFixed();
          return  '(' + decimalconvert.substring(1, decimalconvert.length) + ')' ;
        } else {
            let decimalconvert = parseFloat(value).toFixed();
            let returnvalue = parseFloat(decimalconvert);
            let convertdecimal = returnvalue.toFixed();
            return  parseFloat(data).toFixed();
        }
      } else {
        let data = value.toString();
        if(data.charAt(0) == '-'){ 
          let decimalconvert = parseFloat(value).toFixed();
          return  '(' + decimalconvert.substring(1, decimalconvert.length) + ')' ;
        } else {
            let decimalconvert = parseFloat(value).toFixed();
            let returnvalue = parseFloat(decimalconvert);
            let convertdecimal = returnvalue.toFixed();
            return  parseFloat(data).toFixed();
        }
      }


      
    } else { 
      return value;
    }
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
