export class recordSaveModel {
    recordNodeID: number
    budgetData: budgetdatamodified[]

    constructor() {
        this.recordNodeID = null;
        this.budgetData = [];
    }
}

export class budgetdatamodified {
    valuenodeId: number
    isDerivation: boolean
    isNodeSelection: boolean
    selectedNodes: number[]
    unselectedNodes: number[]
    valuelist: valuelistModified[]
    hasLoanOtherOptions:boolean
    loanCalculationModels:any
    constructor() {
        this.valuenodeId = null;
        this.isDerivation = false;
        this.valuelist = [];
        this.isNodeSelection = false;
        this.selectedNodes = [];
        this.unselectedNodes = [];
        this.hasLoanOtherOptions = false;
        this.loanCalculationModels = null;
    }
}

export class valuelistModified {
    index: number
    value: string
    constructor() {
        this.index = null;
        this.value = null;
    }
}

