<div class="">
    <div class="" style="margin-top: 1%;">
        <h3>Forecast Setting</h3>
    </div>

    <div class="col-sm-12" style="margin-top:1%; height: 675px;">

        <div class="col-sm-12">
            <div class="col-sm-5 newforecast">
                <div class="new-forecast" *ngIf="ForecastSettingCollection!=''">
                    <div class="company_name form-group row">
                        <label class="col-sm-4 col-form-label">Company Name</label>
                        <input type="text" class="form-control col-sm-6"
                            value="{{ForecastSettingCollection.companyName}}" style="color: black;" name="company_name"
                            id="companyName" />
                    </div>
                    <div class="Subheading_name form-group row">
                        <label class="col-sm-4 col-form-label">SubHeading</label>
                        <input type="text" class="form-control col-sm-6" id="subHeading"
                            value="{{ForecastSettingCollection.subHeading}}" name="Subheading_name"
                            style="color: black;" />
                    </div>
                    <div class="Description form-group row">
                        <label class="col-sm-4 col-form-label">Description</label>
                        <textarea type="text" class="form-control Description col-sm-6" id="description"
                            value="{{ForecastSettingCollection.description}}" name="Description"
                            style="color: black;"></textarea>
                    </div>
                    <div class="start-date form-group row">
                        <label class="col-sm-4 col-form-label">Number of Years</label>
                        <input type="number" value="1" class="form-control col-sm-4" id="numberOfYears"
                            value="{{ForecastSettingCollection.numberOfYears}}" name="numberofyears"
                            style="color: black;" />
                    </div>
                    <div class="start-date form-group row">
                        <label class="col-sm-4 col-form-label">Start Date</label>
                        <div class="form-group">
                            <div class="input-group" style="width: 87%;">
                                <input id="datepickers" type="" placeholder="Enter StartDate" name="startdate"
                                    value="{{startdate}}" (click)="DatePickerEventClick(startdate, event)"
                                    class="form-control" style="margin-left: 3px;" />
                            </div>
                        </div>
                    </div>
                    <div class="Firstdata_period form-group row">
                        <label class="col-sm-4 col-form-label">First data Period</label>
                        <select name="FirstdataPeriod" class="form-control col-sm-4" id="FirstdataPeriodindex"
                            style="color: black;">
                            <option *ngFor="let data of months; let FirstdataPeriodindex=index"
                                [selected]="FirstdataPeriodindex==0" value="{{FirstdataPeriodindex}}">{{data.name}}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="LocalCurrency form-group row">
                        <label class="col-sm-4 col-form-label">Local Currency</label>
                        <select name="LocalCurrency" formControlName="LocalCurrency" class="form-control col-sm-4" style="color: black;">
                            <option *ngFor="Currencylist of currency ; let LocalCurrencyIndex = index" [selected]="LocalCurrencyIndex==0" value="{{LocalCurrencyIndex}}"  >{{currency[LocalCurrencyIndex].name}}</option>
                        </select>
                    </div> -->
                    <div class="PeriodFrequency form-group row">
                        <label class="col-sm-4 col-form-label">First Daily CashFlow Period</label>
                        <select name="FirstDailyCashFlowPeriod" class="form-control col-sm-4" style="color: black;"
                            id="FirstDailyCashFlowPeriod">
                            <option *ngFor="let data of months;  let FirstDailyCashFlowPeriodIndex = index"
                                [selected]="FirstDailyCashFlowPeriodIndex==0" value="{{FirstDailyCashFlowPeriodIndex}}">
                                {{data.name}}</option>
                        </select>
                    </div>
                    <div class="">
                        <button class="btn OkSaveButtonColor col-sm-3" (click)="onSubmit()"
                            style="font-size: 16px;float: left;margin: 100px 0 0 0;">Save</button>
                        <button class="btn SettingCancelButtonColor col-sm-3" (click)="OnCancel()"
                            style="font-size: 16px;float: left;margin: 100px 0 0 7px; ">Close</button>
                    </div>
                </div>
                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: white">Please Wait. </p>
                </ngx-spinner>
            </div>
            <div class="col-sm-7" width="100%" *ngIf="isConsolidation">
                <app-consolidaitondetails></app-consolidaitondetails>
            </div>
        </div>
    </div>
</div>
<!-- <div class="button-sample row col" style="float: right;">
    <button class="btn btn-primary col-sm-1" style="font-size: 16px;" >Ok</button>
    <button class="btn btn-secondary col-sm-1" style="font-size: 16px;" >Cancel</button>
</div> -->