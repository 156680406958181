import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class recordNavigation {
    @Output() recordNavigation = new EventEmitter<string>();

    recordNavigations(recordNodeid: string) {
        this.recordNavigation.emit(recordNodeid);
    }
}