import { Component, Input, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MinussignsparenPipe } from 'src/app/common/commonhtml/minussignsparen.pipe';
import { NgxSpinnerService } from "ngx-spinner";
import { OpeningService } from 'src/app/service/opening.service';

@Component({
  selector: 'app-treeopening',
  templateUrl: './treeopening.component.html',
  styleUrls: ['./treeopening.component.scss']
})
export class TreeopeningComponent implements OnInit {
  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  nodes = '';
  options = {}
  constructor(
    private SpinnerService: NgxSpinnerService,
    private openingService: OpeningService) { }

  @Input() Openingtreeview;
  @Input() selectedNodeIds;

  @ViewChild('tree') tree;

  async ngOnInit() {

    await this.delay(800);

    this.nodes = this.Openingtreeview;
    this.options = {
      //useCheckbox: true
    };
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async onTreeLoad() {
    await this.delay(900);
    this.tree.treeModel.expandAll();
  }
  ngAfterViewInit() {
    // (document.querySelector('.app-alerts') as HTMLElement).style.top = '150px';
  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    console.log(inputValue);
    if (inputValue != null) {
      inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
    } else {
      $event.target.value = "0";
    }


  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    if (inputValue == null || inputValue == "") {
      $event.target.value = 0;
      return;
    }
    //var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    if ($event.target.value == null) {
      return;
    }
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      $event.target.value = validatebynumberFormate;
    }
  }

  updateopeningValue(OpeningValues, $event) {
    // console.log(OpeningValues);
    //console.log($event)
    let NewValue = "0.00";

    if ($event.target.value == '') {
      return;
    } else {
      let removecommavalue = $event.target.value.replaceAll(",", '')
      NewValue = parseFloat(removecommavalue).toFixed(2);
    }
    if (OpeningValues.localCurrency == null) {
      return;
    }
    
    let Id = OpeningValues.id;
    let LocalCurrency = "LocalCurrency";
    let BudgetDataNodeId = OpeningValues.budgetDataNodeId;
    let mul = OpeningValues.mul;
    let OldValue = parseFloat(OpeningValues.localCurrency).toFixed(2);
    if (OldValue == NewValue) {
      return;
    }
    let ForecastId = localStorage.getItem('ForecastId');
    if (BudgetDataNodeId != null && NewValue != 'NaN') {
      this.SpinnerService.show();
      this.openingService.UpdateEnterOpeningValue(ForecastId, Id, LocalCurrency, BudgetDataNodeId, mul, NewValue).subscribe(
        ((response: any) => {
          this.SpinnerService.hide();
          // console.log(response);
        })
      )
    }

  }
}
