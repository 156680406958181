<div *ngIf="event!=''">
<ul class="nav nav-tabs" style="margin: -37px 0 0 0;">
    <li class="nav-item">
      <select name="cars" style="background: #86b6d9;color: white;font-size: 12px;font-weight: bold;" id="cars" class="form-control" form="carform">
        <option value="Annual">Annual</option>
        <option value="All">All</option>
      </select>
    </li>
    <li class="nav-item">
      <a class="nav-link active" style="background: #86b6d9;
      color: white;
      font-size: 11px;
      font-weight: 800;" href="#">2021/22</a>
    </li>
  </ul>
  </div>