import { Component, OnInit,Injector } from '@angular/core';
import { UsermaintenanceService } from '../services/usermaintenance.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegisterValidators } from 'src/app/core/validators/register-validators';
import { EmailTaken } from 'src/app/core/validators/email-taken';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-listsubusers',
  templateUrl: './listsubusers.component.html',
  styleUrls: ['./listsubusers.component.scss']
})
export class ListsubusersComponent implements OnInit {

  listofusersGroup:any = [];
  listofusers:any = [];
  AvailableGroups:any = [];
  Displayadduser:boolean = false;
  addGroups:any;
  removeGroup:any;
  activeAvailabelGroups:any = null;
  activeSelectedGroups:any = null
  ActiveUsersNodeId:any = null;
  ValidGroups = []
  userName = new UntypedFormControl(
    '', 
    [Validators.required]
    )
    
    AddUserGroupForm = new UntypedFormGroup({
      userName: this.userName,
    })

  constructor(
    public usermaintenanceService:UsermaintenanceService,
    private SpinnerService: NgxSpinnerService, 
    private injector: Injector, 
    public emailInvalid: EmailTaken) { }
  ngOnInit() {
      this.usermaintenanceService.getUserGroupList().subscribe(
        (response=>{
          this.listofusersGroup = response;
        })
      )
  }

  ngOnChanges(){
    this.usermaintenanceService.getUserGroupList().subscribe(
      (response=>{
        this.listofusersGroup = response;
      })
    )
  }

  onuserSubmit(){
    console.log("Testing");
    const notifier = this.injector.get(NotificationService);
    if (this.AddUserGroupForm.invalid) {
      return;
    }
    let users = {
      "name":this.AddUserGroupForm.value.userName,
      "active":true,
      "validGroupListCollection": this.ValidGroups 
    }
    this.usermaintenanceService.addUserGroupDeatils(users).subscribe(
      (response=>{
        console.log(response);
      })
    )
  }
  ActiveUsers(NodeId){
    this.ActiveUsersNodeId = NodeId;
  }
  deleteUser(UserID){
    if(UserID !=null && UserID!=''){
      this.usermaintenanceService.deleteUser(UserID).subscribe(
        (response=>{
          this.ngOnInit();
        })
      )
    }
  }

  OepnAddUserDialog(UserManagementAddUser){
    this.Displayadduser = true;
    UserManagementAddUser.open();
    this.usermaintenanceService.getAvailableUsers().subscribe(
      (response=>{
        this.AvailableGroups = response;
      })
    )
  }
  changeUserEvent(SelectedGroups){ 
    this.addGroups = SelectedGroups;
    this.activeAvailabelGroups = SelectedGroups;
  }

  addGroupsEvent(){
    let selectedelemtn = null;
    this.AvailableGroups.forEach((element, index) => {
      if(element.code == this.addGroups){ 
       let select = {
          'name': element.name,
          'code':element.code
        }
        selectedelemtn = index;
        this.ValidGroups.push(select);
      }
    });
    if(selectedelemtn!=null){    
      for (var i = 0; i < this.AvailableGroups.length; i++) {
        for (var j = 0; j < this.ValidGroups.length; j++) { 
            if (JSON.stringify(this.AvailableGroups[i].code) == JSON.stringify(this.ValidGroups[j].code)) {
              this.AvailableGroups.splice(i, 1);
            }
        }
      }
    }
  }
  removeGroups(){
    let selectedelemtn = null;
    this.ValidGroups.forEach((element, index) => {
      if(element.code == this.removeGroup){
        selectedelemtn = index;
        this.AvailableGroups.push(element);
      }
    });
    if(selectedelemtn!=null){
      for (var i = 0; i < this.ValidGroups.length; i++) {
        for (var j = 0; j < this.AvailableGroups.length; j++) { 
            if (JSON.stringify(this.ValidGroups[i].code) == JSON.stringify(this.AvailableGroups[j].code)) {
              this.ValidGroups.splice(i, 1);
            }
        }
    }
    }
  }

  changeValiduserEvent(removeGroup){ 
    this.removeGroup = removeGroup;
    this.activeSelectedGroups = removeGroup;
  }


  

}
