export class supportRequestModel {
    CustomerName: String
    emailId: String
    userId: String
    phone: String
    help: String
    howCritical: String
    isReproducible: boolean
    accessGranted: boolean
    steps: String
    attachments: any[]

    constructor() {
        this.CustomerName = null;
        this.emailId = null;
        this.userId = null;
        this.phone = null;
        this.help = null;
        this.howCritical = null;
        this.isReproducible = false;
        this.accessGranted = true;
        this.steps = null;
        this.attachments = [];
    }

}