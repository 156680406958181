import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { PreferenceService } from '../../../service/SettingServices/PreferencesServices/preference.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-preferences-setting',
  templateUrl: './preferences-setting.component.html',
  styleUrls: ['./preferences-setting.component.scss']
})
export class PreferencesSettingComponent implements OnInit {

  collections: any = [];
  PreferencesCollection: any = [];
  ForecastNodeId: any;
  SetPreferencesValue = {
    'messageSourceVM': ''
  }

  constructor(private PreferenceServices: PreferenceService, private router: Router) {
    this.ForecastNodeId = localStorage.getItem('ForecastId');

  }

  async ngOnInit() {
    this.PreferencesCollection = [];
    this.PreferenceServices.getPreferenceCollections(this.ForecastNodeId).subscribe(
      (async response => {
        this.PreferencesCollection = response;
      })
    )
  }



  async ngOnChanges() {
    this.PreferencesCollection = [];
    this.PreferenceServices.getPreferenceCollections(this.ForecastNodeId).subscribe(
      (async response => {
        this.PreferencesCollection = response;
      })
    )
  }
  CourtesyMessageSourcesClickEvent(Budget) {
    let value = Budget.value;
    this.PreferenceServices.SetmessageSourceVM(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  setMessage($event) {
    let value = $event.target.value;
    // console.log(value);
    this.PreferenceServices.Setmessage(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }

  hideZeroRowsEvent($event) {
    let value = $event.target.checked;
    this.PreferenceServices.SetHideZeroRows(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  SettingSave() {
    this.ngOnInit();
  }
  SettingClose() {
    this.router.navigateByUrl('/forecast');
  }
  exportDescriptionWithPnlEvent($event) {
    let value = $event.target.checked;
    this.PreferenceServices.SetExportDescriptionWithPnl(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  allocateGainsLossesToDepreciationDestEvent($event) {
    let value = $event.target.checked;
    this.PreferenceServices.SetAllocateGainsLossesToDepreciationDest(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }

  validShowLoanBalancesVM(Budget) {
    let value = Budget.value;
    this.PreferenceServices.SelectedShowLoanBalancesVM(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  validStockOnHandCostPerUnitMonthTimingVM(Budget) {
    let value = Budget.value;
    this.PreferenceServices.SetSelectedStockOnHandCostPerUnitMonthTimingVM(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  validWeekendPaymentDayVMs(Budget) {
    let value = Budget.value;
    this.PreferenceServices.SelectedWeekendPaymentDayVM(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }
  SetSeparateCashReceiptsBySections($event) {
    let value = $event.target.checked;
    this.PreferenceServices.SetSeparateCashReceiptsBySections(this.ForecastNodeId, value).subscribe(
      (response => {
        return response;
      })
    )
  }

}
