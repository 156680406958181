import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../core';
import { MenuService } from '../../service/menu.service';
import { Menu } from '../../model/menu'
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() getSelectedNodeIdDetail: any;
  accessToken = '';
  refreshToken = '';
  currentuserName = '';
  SelectedTreenodeEvent = '';
  // getSelectedNodeDetailsFromService: any;
  islogin: boolean;
  isForecastOpened: boolean;
  displaySettingHeader: boolean;
  displaySettingMenus: boolean;
  displayintegrationstatus: boolean;
  displayintegrationValue = '';
  displayIntegrationSetting = '';
  forecastname = '';
  trialPackage: boolean;
  trialTimeRemaning = '';
  DisplayUpgradePackage: boolean;
  clickedevent: boolean;
  clientName = "";
  values = ['Client', 'forecast-list', 'login', 'new-password'];
  UserEmail = '';

  constructor(public authService: AuthService, public menuServices: MenuService,
    private SpinnerService: NgxSpinnerService,
    private _router: Router) {
    this.forecastname = localStorage.getItem('ForecastName');
    this.UserEmail = localStorage.getItem("userEmailID");
    this.clientName = localStorage.getItem("clientName");
    this.displaymenus();

  }
  initials: string;
  ngOnInit() {
    this.displayintegrationstatus = false;
    this.displayintegrationValue = '';
    this.displaySettingHeader = true;
    let DisplayHeader = false;
    let DisplayTrial = false;
    let upgradeDisplay = ['login', 'new-password'];
    this.displayIntegrationSetting = localStorage.getItem("Integration");
    if (this._router.url == '/settings' || this._router.url == '/report') {
      this.displaySettingHeader = false;
    } else {
      this.displaySettingHeader = true;
    }
    this.displaymenus();
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refresh_token');
    let CurrentuserName = localStorage.getItem('CurrentUserName');
    let forecastNodeid = localStorage.getItem('ForecastId');
    this.isForecastOpened = false;
    if (CurrentuserName === null) {
      this.islogin = false;
      this.currentuserName = '';
      this.initials = '';
    } else {
      // console.log('UserNmae: ' + CurrentuserName);
      this.islogin = true;
      this.currentuserName = CurrentuserName;
      this.initials = '';
      this.initials = this.currentuserName.charAt(0);
      let StorageActiveStatus = localStorage.getItem('IntegrationActive');
      console.log(StorageActiveStatus);
      if (forecastNodeid != '' && StorageActiveStatus!=undefined && StorageActiveStatus=="") {
        this.isForecastOpened = true;
        this.SpinnerService.show();
        this.menuServices.GetIntegrationStatus(forecastNodeid).subscribe(
          ((response: any) => {
            this.displayintegrationstatus = true;
            localStorage.setItem('IntegrationActive', "true");
            this.displayintegrationValue = response;
            localStorage.setItem("IntegrationWithName", response);
            console.log(response);
            this.SpinnerService.hide();
          })
        )
      }
    }
  }

  displaymenus() {
    let DisplayHeader = false;
    let DisplayTrial = false;
    let upgradeDisplay = ['login', 'new-password'];
    this.forecastname = localStorage.getItem('ForecastName');
    let stringArray = this._router.url;
    let UrlArray = [];
    if (stringArray != '') {
      UrlArray = stringArray.split('/');
    }
    const urlExist = this.values.forEach(value => {
      if (stringArray.includes('?')) {
        DisplayHeader = true;
      } else {
        if (UrlArray.includes(value)) {
          DisplayHeader = true;
        } else {
        }
      }

    });
    const displayTrial = upgradeDisplay.forEach(value => {
      if (UrlArray.includes(value)) {
        DisplayTrial = true;
      } else {
      }
    });
    if (DisplayHeader) {
      this.displaySettingMenus = false;
      if (!DisplayTrial) {
        this.GetActiveUserLicense();
      }
    } else {
      this.displaySettingMenus = true;
      this.GetActiveUserLicense();
    }
  }
  upgradePackage() {
    var inputValue: any = (<HTMLInputElement>document.getElementById('UpgradePackageevent'));
    inputValue.click();
  }

  GetActiveUserLicense() {
    let getUserPermissionData: any = JSON.parse(localStorage.getItem("UserPermission"));
    console.log(getUserPermissionData);
    if (getUserPermissionData != '' && getUserPermissionData != null) {
      if (getUserPermissionData.demoPermitted == true && getUserPermissionData.productID == "F5WebDemo") {
        this.trialPackage = true;
        var today: any = new Date();
        var Christmas: any = new Date(getUserPermissionData.expiryDate);
        var diffMs = (Christmas - today);
        var diffDays = Math.floor(diffMs / 86400000);
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        let data = diffDays + " days " + diffHrs + " hours " + diffMins + " minutes Remaning"
        this.trialTimeRemaning = data;
        localStorage.setItem("packageTime", this.trialTimeRemaning);
        localStorage.setItem('PackageProduct', getUserPermissionData.productID);
      } else {
        this.trialPackage = false;
      }
      if (this.trialPackage == true && this._router.url == '/Client') {
        this.DisplayUpgradePackage = true;
        var inputValue: any = (<HTMLInputElement>document.getElementById('UpgradePackageevent'));
        let storedValue = localStorage.getItem('clickedPoPupEvent');
        if (inputValue != null && (storedValue == null || storedValue == 'null')) {
          inputValue.click();
          localStorage.setItem('clickedPoPupEvent', 'true');
        }
      } else {
        this.DisplayUpgradePackage = false;
      }
    }

  }
  openForecastList() {

    this._router.navigateByUrl('/forecast-list');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  closePopupEvent(UpgradePackage) {
    UpgradePackage.close();
    localStorage.setItem('clickedPoPupEvent', null);
  }

  openForecast() {
    console.log("openForecast");
    this._router.navigateByUrl('/forecast');
  }

  openReports() {
    this._router.navigateByUrl('/report');
  }

  logout() {
    this.authService.logout();
    this.islogin = false;
    let APIURL = localStorage.getItem('APIURL');
    localStorage.setItem('userdata', null);
    localStorage.setItem("clientID", '');
    localStorage.setItem('CurrentUserName', '');
    localStorage.clear();
    localStorage.setItem('APIURL', APIURL);
    localStorage.setItem('clickedPoPupEvent', null);
    localStorage.setItem("ForecastClientCountryCollection", null);
    localStorage.removeItem("treeview");
  }

  async ngOnChanges() {
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refresh_token');
    let CurrentuserName = localStorage.getItem('CurrentUserName');
    let forecastNodeid = localStorage.getItem('ForecastId');
    this.isForecastOpened = false;
    console.log("forecastNodeid", forecastNodeid);
    if (CurrentuserName === null) {
      this.islogin = false;
      this.currentuserName = '';
      this.initials = '';
    } else {
      // console.log('UserNmae: ' + CurrentuserName);
      this.islogin = true;
      this.currentuserName = CurrentuserName;
      this.initials = '';
      this.initials = this.currentuserName.charAt(0)
      if (forecastNodeid != '') {
        this.isForecastOpened = true;
        // this.menuServices.GetIntegrationStatus(forecastNodeid).subscribe(
        //   ((response: any) => {
        //     this.displayintegrationstatus = true;
        //     this.displayintegrationValue = response;
        //   })
        // )
      }
    }
    this.displaySettingHeader = true;
    let DisplayHeader = false;
    if (this._router.url == '/settings') {
      this.displaySettingHeader = false;
    } else {
      this.displaySettingHeader = true;
    }
    let DisplayTrial = false;
    let upgradeDisplay = ['login', 'new-password'];

    this.forecastname = localStorage.getItem('ForecastName');
    let stringArray = this._router.url;
    let UrlArray = [];
    if (stringArray != '') {
      UrlArray = stringArray.split('/');
    }
    const urlExist = this.values.forEach(value => {
      if (UrlArray.includes(value)) {
        DisplayHeader = true;
      } else {

      }
    });
    const displayTrial = upgradeDisplay.forEach(value => {
      if (UrlArray.includes(value)) {
        DisplayTrial = true;
      } else {
      }
    });
    if (DisplayHeader) {
      this.displaySettingMenus = false;
      if (!DisplayTrial) {
        // this.GetActiveUserLicense();
      }
    } else {
      this.displaySettingMenus = true;
      // this.GetActiveUserLicense();
    }

    // this.getSelectedNodeDetailsFromService = '';
    this.SelectedTreenodeEvent = '';
    this.SelectedTreenodeEvent = this.getSelectedNodeIdDetail;
    // if (this.SelectedTreenodeEvent != null || this.SelectedTreenodeEvent != '') {
    //   if (this.SelectedTreenodeEvent != undefined) {
    //     this.menuServices.getSelectednodeDetails(this.SelectedTreenodeEvent).subscribe((response: Menu[]) => {
    //       let results = response;
    //       this.getSelectedNodeDetailsFromService = results;
    //     })
    //   }
    // }
  }

  BudgetRecordRecalcu() {
    let ForecastNodeId = localStorage.getItem("ForecastId");
    if (ForecastNodeId != null) {
      this.SpinnerService.show();
      this.menuServices.BudgetRecordRecalu(ForecastNodeId).subscribe
        ((response: Menu[]) => {
          this.SpinnerService.hide();
          window.location.reload();
        })
    }
  }

  SystemRecordRecalcu() {
    let ForecastNodeId = localStorage.getItem("ForecastId");
    if (ForecastNodeId != null) {
      this.SpinnerService.show();
      this.menuServices.SystemRecordRecalu(ForecastNodeId).subscribe
        ((response: Menu[]) => {
          this.SpinnerService.hide();
          window.location.reload();
        })
    }
  }

  async AddDepartment(NodeId) {
    let DepartmentAddDetails = {
      "SelectedNodeID": NodeId,
      "NewDepartmentName": "NewSalesDepartment",
      "showGrossProfitPercent": true
    }
    await this.menuServices.SaveAddDepartment(DepartmentAddDetails).subscribe
      ((response: Menu[]) => {
        window.location.reload();
      })
  }

  /**
   * 
   * @param NodeId 
   */
  async DeleteDepartment(NodeId) {
    // console.log(NodeId);
    if (NodeId != null) {
      await this.menuServices.deleteDepartment(NodeId).subscribe
        ((response: Menu[]) => {
          window.location.reload();
        })
    }
  }
}
