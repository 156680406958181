import { DecimalPipe, formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'reportDecimalpipe',
})
export class ReportDecimalpipePipe implements PipeTransform {
  digits = '2.0-1';
  constructor() {
  }
  transform(value: any, args?: any): any {
    if (value != null) {
      let data = value.toString();
      if (data.charAt(0) == '-') {
        return data.charAt(0) == '-' ?
          '(' + this.converttoDisplayValue(parseFloat(data.substring(1, data.length)).toFixed()) + ')' :
          data;
      } else if (isNaN(data)) {
        return data;
      }
      else {
        let result = parseFloat(data).toFixed();
        return this.converttoDisplayValue(result);
        //return this.decimalPipe.transform(parseInt(result)%1000000, this.digits)
      }
    } else {
      return "";
    }

    // return value.charAt(0) == '-' ?
    //        '(' + value.substring(1, value.length) + ')' :
    //        value;
  }
  converttoDisplayValue(decimalValue) {
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

}
