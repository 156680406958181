<div *ngIf="eventMethodName && this.AddEnumvalueInList!=''"  class="BudgetMethods">
    <div *ngIf="AddEnumvalueInList.derivationEngType!='Derived' && AddEnumvalueInList.isConsolidation==false">
        <div *ngIf="AddEnumvalueInList.nodeTypeEnglishLabel !='CapitalPaidNextYear'">
            <div *ngIf="AddEnumvalueInList.nodeTypeEnglishLabel!='TotalAdditions'">
                <div *ngIf="AddEnumvalueInList" style="width: max-content;padding: 0px;">

                    <div style="display: inline-block;width: fit-content;padding: 0 6px 0px 0;">
                        <select name="dropdown-option" style="font-size: 15px;"
                            (change)="changeMethodDarrivations($event.target.value, AddEnumvalueInList)"
                            [disabled]="showActiveWhatIf=='true'" class="form-control" id="dropdown-option">
                            <option *ngFor="let methoddata of AddEnumvalueInList.validDerivationTypes ; let i = index;"
                                [selected]="AddEnumvalueInList.derivationType === methoddata" value="{{methoddata}}">
                                {{AddEnumvalueInList.validDerrivationTypeName[i]}}</option>
                        </select>
                    </div>
                    
                    <div *ngIf="SubDerrivationOptions!='' && !ProfileCollectionBool">
                        <div *ngIf="SubDerrivationOptions.nodeType == '550130'">
                            <select name="dropdown-option" *ngIf="SubDerrivationOptions!=''"
                                class="form-control subderrivation" style="width: auto;font-size: 15px;margin-top: 4px;"
                                [disabled]="showActiveWhatIf=='true'"
                                (change)="changeSubderrivationOptions($event, displayvaluelistdata ,SubDerrivationOptions)">
                                <option *ngFor="let result of SubDerrivationOptions.items; let i = index"
                                    value="{{result.NodeId}}-{{i}}"
                                    [selected]="i == SubDerrivationOptions.selectedIndex"> {{ result._label}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="SubDerrivationOptions.nodeType != '550130'">
                            <select name="dropdown-option" *ngIf="SubDerrivationOptions!=''"
                                class="form-control subderrivation" style="width: auto;font-size: 15px;margin-top: 4px;"
                                [disabled]="showActiveWhatIf=='true'"
                                (change)="changeSubderrivationOptions($event, displayvaluelistdata ,SubDerrivationOptions)">
                                <option *ngFor="let result of SubDerrivationOptions.items; let i = index"
                                    value="{{result.nodeId}}-{{i}}"
                                    [selected]="result.nodeId == SubDerrivationOptions.selectedItem.nodeId"> {{
                                    result._label}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
                <div *ngIf="valueListDerrivation.derivationType==80028" style="width: max-content;padding: 0px;">

                    <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
                        <button class="EmployeeGroupEntry form-control OkSaveButtonColor btnsave" style="font-size: 15px;background: #cccccc;"
                            (click)="openEmployeForm(displayvaluelistdata)">Employee Entry</button>
                                <igx-dialog #employeeform [closeOnOutsideSelect]="false">
                                    <button class="SaveWhatIfAsBudget form-control hide" id="getEmployeegroup" style="font-size: 15px;background: #cccccc;"
                                    (click)="employeeform.open()"></button>
                                    <div *ngIf="DisplayEmployeForm">
                                        <app-employee-group [EmployeeDetails]="EmployeeRecordList"></app-employee-group>
                                        <div igxDialogActions>
                                            <button igxButton (click)="employeeform.close()"
                                                class="deleteCancelButtonColor">CANCEL</button>
                                            <button igxButton (click)="saveEmployeeGroup(employeeform,EmployeeRecordList)"
                                                class="OkSaveButtonColor">OK </button>
                                        </div>
                                    </div>
                                </igx-dialog>
                    </div>
                    <div *ngIf="getEvenSpreadProfileCollection" style="width: fit-content;display: inline-block;">
                        <button class="BonusesEmployeeGroup form-control OkSaveButtonColor btnsave" style="font-size: 15px;background: #cccccc;"
                            (click)="Bonusesform.open()">Bonuses</button>
                        <igx-dialog #Bonusesform [closeOnOutsideSelect]="false">
                            <app-bonuses [BonusesDetails]="displayvaluelistdata"></app-bonuses>
                            <div igxDialogActions>
                                <button igxButton (click)="Bonusesform.close()"
                                    class="deleteCancelButtonColor">CANCEL</button>
                                <button igxButton  (click)="Savebonus(Bonusesform)" class="OkSaveButtonColor">OK</button>
                            </div>
                        </igx-dialog>
                    </div>
                </div>
                <div *ngIf="valueListDerrivation.derivationType==80031" style="width: max-content;padding: 0px;">
                    <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
                        <button class="EmployeeGroupEntry form-control" style="font-size: 15px;background: #cccccc;"
                            (click)="formulaform.open()">Formula</button>
                        <igx-dialog #formulaform [closeOnOutsideSelect]="false">
                            <app-formula [FormulaDetails]="displayvaluelistdata" (opening)="onDialogOpenHandler($event)"
                                (formulaModifiedEvent)="formulaModified($event)"></app-formula>
                            <div igxDialogActions>
                                <button igxButton (click)="formulaform.close()"
                                    class="deleteCancelButtonColor">CANCEL</button>
                                <button igxButton class="OkSaveButtonColor"
                                    (click)="saveFormulaCollection(formulaform,displayvaluelistdata)">Save</button>
                            </div>
                        </igx-dialog>
                    </div>
                </div>
                <div *ngIf="valueListDerrivation.derivationType==84033" style="width: max-content;padding: 0px;">
                    <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
                        <button class="EmployeeGroupEntry form-control"
                            style="width: fit-content;display: inline-block;font-size: 15px;background: dodgerblue;color: white;" 
                             (click)="RefreshHotlink(valueListDerrivation.nodeId)">Refresh</button>
                        <button class="EmployeeGroupEntry form-control"
                            style="width: fit-content;display: inline-block;font-size: 15px;background: dodgerblue;color: white;" (click)="hotlink.open()">Hotlink</button>
                        <igx-dialog #hotlink [closeOnOutsideSelect]="false">
                            <app-hotlink [hotlinkvaluesNodeId]="valueListDerrivation.nodeId"></app-hotlink>
                            <div igxDialogActions>
                                <button igxButton (click)="hotlink.close()"
                                    class="deleteCancelButtonColor">CANCEL</button>
                                <button igxButton class="OkSaveButtonColor">OK</button>
                            </div>
                        </igx-dialog>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="AddEnumvalueInList.derivationEngType =='Derived' && AddEnumvalueInList.englishLabel!='Capital Paid Next Year'"
        >
    </div>
    <div *ngIf="AddEnumvalueInList.derivationEngType =='Derived' && AddEnumvalueInList.englishLabel =='Capital Paid Next Year'"
        style="margin-top: 1%;">
    </div>
</div>