import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,Injector } from '@angular/core';
import { TreeviewService } from 'src/app/service/treeview.service';
import { Treeview } from 'src/app/service/treeview';
import { TreeModel, TreeNode } from '@ali-hm/angular-tree-component';
import { NgxSpinnerService } from "ngx-spinner";
declare const $: any;

import { ITreeState, ITreeOptions } from '@ali-hm/angular-tree-component';
import { v4 } from 'uuid';
import { XeroService } from '../service/xero.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {

  Treeviews: Treeview[] = [];
  RootChartofAccountsVM: any
  nodes = [];
  data = '';
  displayXeroTree = false;
  ProfileAndLoss = [];
  Balance = [];
  Activetabmenu = 0;
  ForecastRecord = [];
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  options: ITreeOptions = {
    getNodeClone: (node) => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`
    })
  };

  IntegrationDetails = [
    {name:"ProfitAndLoss", longLable:"Profit And Loss"},
    {name:"Balance", longLable:"Balance"},
    {name:"ForecastRecord", longLable:"Forecast Record"}
  ]
    
  constructor(private Xeroservice:XeroService, private SpinerService:NgxSpinnerService , private injector: Injector) { }

  ChangePeriodType(data, i){
    this.Activetabmenu = parseInt(i);
    if(data.name == "ProfitAndLoss"){
      this.nodes = this.ProfileAndLoss;
      this.options = {
    }
    } if(data.name == "Balance"){
      this.nodes = this.Balance;
      this.options = {
      }
    }
    if(data.name == "ForecastRecord"){
      this.nodes = this.ForecastRecord;
      this.options = {
    }
    }
    
    this.onUpdateData();
}
  async ngOnInit() { 
    this.delay(900);
    await this.importtreedata();
  }
  @ViewChild('tree') tree;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  Expandmenu(){
    this.tree.treeModel.expandAll();
  }
  onUpdateData() {
    const treeModel = this.tree.treeModel;
    treeModel.expandAll()
  }

  async onTreeLoad() { 
    this.tree.treeModel.expandAll();
  }
  ProfiteAndLossNodeType = [30002,30003,30004,30005];
  ForecastRecordType = [30001];
  async importtreedata(){ 
    this.SpinerService.show();
    this.displayXeroTree = false;
    let displayLoader = false;
    this.Balance = [];
    this.ProfileAndLoss = [];
    this.ForecastRecord = [];
    setTimeout(() => {
      this.SpinerService.show();
    }, 1000);
    let ForecastId = localStorage.getItem("ForecastId");
    if(ForecastId!=null) {
      this.SpinerService.show();
        await this.Xeroservice.ImportData(ForecastId).subscribe(
          ((response:any)=>{ 
            try {
              this.displayXeroTree = true; 
              displayLoader = true;
              this.SpinerService.hide();
              this.RootChartofAccountsVM = [];
                this.RootChartofAccountsVM = response;
                this.Treeviews = response.children;
                console.log(this.Treeviews);
                response.children.forEach(element => {
                  if(this.ProfiteAndLossNodeType.includes(element.nodeType)) {
                      this.ProfileAndLoss.push(element);
                  } else if(this.ForecastRecordType.includes(element.nodeType)){
                      this.ForecastRecord.push(element);
                  } else {
                      this.Balance.push(element)
                  }
                  setTimeout(() => {
                    this.SpinerService.hide();
                  }, 7000);
                });
                //this.nodes = this.Treeviews;
                this.nodes = this.ProfileAndLoss;
                this.options = {
                }
              //this.SpinerService.show();
            } catch (error) {
              this.SpinerService.hide();
            }
          })
        )
    }
    if(displayLoader){
      this.SpinerService.hide();
    } else {
      this.SpinerService.show();
    }
  }

  showXeroRecord(getClass){
    
    var showxerorecord: any = document.getElementById("xerorecord-"+getClass);
    if(showxerorecord.className=="show"){
      showxerorecord.classList.add('hide');
      showxerorecord.classList.remove('show');
    } else {
      showxerorecord.classList.add('show');
      showxerorecord.classList.remove('hide');
    }

  }
  ChangeValidSectionFolderType(data, $event){
    data.selectedSectionFolderType = parseInt($event.target.value);
  }
  ChangeValidDepreciationRecordType(data, result, $event){
    if($event.target.checked){
      data.selectedDepreciationRecordVM = result;
    } else {
      data.selectedDepreciationRecordVM = null;
    }
    
  }
  handleChange($event, Data){
    console.log($event ,Data);
    let selectedArrayValue = [];
    if($event.newSelection.length>0){ 
      $event.newSelection.forEach(element => {
        selectedArrayValue.push(element.recordInfo);
      });
    }
    Data.selectedDepreciationRecordVM = selectedArrayValue;
  }
  SaveImportData(){ 
    const notifier = this.injector.get(NotificationService);
    this.SpinerService.show();
    console.log(this.ProfileAndLoss , this.ForecastRecord, this.Balance);
    let UpdatedData = []
    this.ProfileAndLoss.forEach(element => {
      UpdatedData.push(element);
    });
    this.Balance.forEach(element => {
      UpdatedData.push(element); 
   });
    this.ForecastRecord.forEach(element => {
        UpdatedData.push(element);
    });

    this.RootChartofAccountsVM.children = UpdatedData;
    let saveImportData = {
      "importDataValue": this.RootChartofAccountsVM,
      "nodeId": parseInt(localStorage.getItem("ForecastId"))
    }
    console.log(saveImportData);
    this.Xeroservice.SaveImportData(saveImportData).subscribe(
      ((response:any)=>{ 
        try {
          localStorage.setItem("treeview", "");
          this.SpinerService.hide();
          notifier.showSuccess("Import saved successfully. Go to home page to see changes")
        } catch (error) {
          this.SpinerService.hide();
          notifier.showError("Import not saved successfully. please create mapping and save Import")
        }
        
      })
    )
  }
  filterFn(value: string, treeModel: TreeModel) {
    this.tree.treeModel.filterNodes((node: TreeNode) => this.searchTree(value, node.data.name));
  }

  searchTree(needle: string, haystack: string) {
    if (haystack != null) {
      const haystackLC = haystack.toLowerCase();
      const needleLC = needle.toLowerCase();

      const hlen = haystack.length;
      const nlen = needleLC.length;

      if (nlen > hlen) {
        return false;
      }
      if (nlen === hlen) {
        return needleLC === haystackLC;
      }
      outer: for (let i = 0, j = 0; i < nlen; i++) {
        const nch = needleLC.charCodeAt(i);

        while (j < hlen) {
          if (haystackLC.charCodeAt(j++) === nch) {
            continue outer;
          }
        }
        return false;
      }
      return true;
    }

  }

}
