import { Component, OnInit, Input } from '@angular/core';
import { MemoRecordOptionService } from '../../service/memo-record-option.service';
import { MemoRecordOption } from '../../model/memo-record-option';
import { NgxSpinnerService } from "ngx-spinner";
import { FinacingrecordOptionComponent } from './finacingrecord-option/finacingrecord-option.component';
@Component({
  selector: 'app-record-option',
  templateUrl: './record-option.component.html',
  styleUrls: ['./record-option.component.scss']
})
export class RecordOptionComponent implements OnInit {

  @Input() RecordOption: any;

  RecordOptionlist: any = [];
  RecordOptionNodeType: any;
  displayFixedAsset: boolean;
  displayMemoRecord: boolean;
  displayAccruedPrepaidCost: boolean;
  displayOtherfixedAssestOption: boolean;
  finacingRecord: boolean;
  isConsolidation: boolean;
  DisplayFinanceRecord: boolean;
  constructor(public recordOptionsServices: MemoRecordOptionService, public FinacingrecordOptionComponent: FinacingrecordOptionComponent, private SpinnerService: NgxSpinnerService) {
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
  }

  async ngOnChanges() {
    if (this.RecordOption != null) {
      this.displayFixedAsset = false;
      this.displayMemoRecord = false;
      this.displayAccruedPrepaidCost = false;
      this.displayOtherfixedAssestOption = false;
      this.finacingRecord = false;
      this.SpinnerService.show();
      this.recordOptionsServices.getRecordOptionNodeType(this.RecordOption).subscribe(
        (response: any) => {
          this.RecordOptionNodeType = response;
          if (this.RecordOptionNodeType == 50012) {
            this.recordOptionsServices.getFixedAssetRecordOptionLists(this.RecordOption).subscribe
              ((responseFixedAssest: any) => {
                this.displayFixedAsset = true;
                this.RecordOptionlist = responseFixedAssest;
              })
          }
          if (this.RecordOptionNodeType == 50013) {
            this.recordOptionsServices.getOtherfixedassestRecordOptionLists(this.RecordOption).subscribe
              ((responseFixedAssest: any) => {
                this.displayOtherfixedAssestOption = true;
                this.RecordOptionlist = responseFixedAssest;
              })
          }
          if (this.RecordOptionNodeType == 50022) {
            this.recordOptionsServices.getMemoRecordOptionLists(this.RecordOption).subscribe
              ((MemoRecordresponse: any) => {
                this.RecordOptionlist = MemoRecordresponse;
                this.displayMemoRecord = true;
              })
          }
          if (this.RecordOptionNodeType == 50003) {
            this.recordOptionsServices.getBankAccountRecordOptionLists(this.RecordOption).subscribe
              ((MemoRecordresponse: any) => {
                this.RecordOptionlist = MemoRecordresponse;
                this.displayMemoRecord = true;
              })
          }
          if (this.RecordOptionNodeType == 50001) {
            this.recordOptionsServices.getAccreudPrepaidCostRecordOptionLists(this.RecordOption).subscribe
              ((MemoRecordresponse: any) => {
                this.displayAccruedPrepaidCost = true;
                this.RecordOptionlist = MemoRecordresponse;
              })
          }
          if (this.RecordOptionNodeType == 50002) {
            this.recordOptionsServices.getAccreudPrepaidCostRecordOptionLists(this.RecordOption).subscribe
              ((MemoRecordresponse: any) => {
                this.displayAccruedPrepaidCost = true;
                this.RecordOptionlist = MemoRecordresponse;
              })
          }
          if (this.RecordOptionNodeType == 50031) { 
            this.RecordOptionlist = null;
            this.SpinnerService.show();
            this.recordOptionsServices.getFinacingRecordOptionLists(this.RecordOption).subscribe
              ((MemoRecordresponse: any) => {
                this.SpinnerService.hide();
                this.finacingRecord = true;
                this.RecordOptionlist = MemoRecordresponse;
                this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
                if (this.RecordOptionlist != null && this.RecordOptionlist.length > 0) {
                  localStorage.setItem("isDisplayRecordOptions", JSON.stringify(this.RecordOptionlist));
                  this.isConsolidation = false;
                  if(this.finacingRecord && !this.isConsolidation){
                    this.DisplayFinanceRecord = true;
                    $("#finaacingRecordDisplay").removeClass("hide");
                  }
                  this.FinacingrecordOptionComponent.ngOnChanges();
                } else {
                  localStorage.setItem("isDisplayRecordOptions", null);
                  this.finacingRecord = false;
                  this.DisplayFinanceRecord = true;
                  $("#finaacingRecordDisplay").addClass("hide");
                  this.FinacingrecordOptionComponent.ngOnChanges();
                }
                
              })
          }
          this.SpinnerService.hide();
        }
      )
    }

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  RecordOptionChanged(NodeId, event) {
    if (NodeId != null) {
      let selectedOption = 0;
      if (event.target.checked == true) {
        selectedOption = 1;
      }
      this.recordOptionsServices.changeRecordOption(NodeId, selectedOption).subscribe
        ((response: MemoRecordOption[]) => {
          // console.log("added succesfully");
        })
    }
  }

  ngOnInit() {
  }

}
