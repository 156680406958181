import { Component, OnInit,Injector } from '@angular/core';
import { UsermaintenanceService } from '../services/usermaintenance.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegisterValidators } from 'src/app/core/validators/register-validators';
import { EmailTaken } from 'src/app/core/validators/email-taken';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {

  listofusers:any = [];
  AvailableGroups:any = [];
  Displayadduser:boolean = false;
  addGroups:any;
  removeGroup:any;
  activeAvailabelGroups:any = null;
  activeSelectedGroups:any = null
  ActiveUsersNodeId:any = null;
  ValidGroups = [];
  UpdateValidGroups = [];
  UpdateAvailableGroups = [];
  updateAddGroups:any;
  updateremoveGroup:any;
  activeudpateAvailabelGroups:any = null;
  activeupdateSelectedGroups:any = null
  UpdatedName:any = '';
  useremailId = new UntypedFormControl(
    '', 
    [Validators.required, RegisterValidators.regexValidator(new
    RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), { 'email': 'not valid' })],
    [this.emailInvalid.validate]
    )
    Password = new UntypedFormControl('', [Validators.required,
      RegisterValidators.regexValidator(new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), { 'password': 'not valid' })])
    ConfirmPassword = new UntypedFormControl('', [Validators.required])
    AdduserForm = new UntypedFormGroup({
      useremailId: this.useremailId,
      Password:this.Password,
      ConfirmPassword: this.ConfirmPassword
  },[RegisterValidators.match('Password', 'ConfirmPassword')])

  constructor(
    public usermaintenanceService:UsermaintenanceService,
    private SpinnerService: NgxSpinnerService, 
    private injector: Injector, 
    public emailInvalid: EmailTaken) { }

  ngOnInit() {
    this.SpinnerService.show();
      this.usermaintenanceService.getUserList().subscribe(
        (response=>{
          this.SpinnerService.hide();
          this.listofusers = response;
        })
      )
  }

  ngOnChanges(){
    this.usermaintenanceService.getUserList().subscribe(
      (response=>{
        this.listofusers = response;
      })
    )
  }
  onuserSubmit(){
    const notifier = this.injector.get(NotificationService);
    if (this.AdduserForm.invalid) {
      return;
    }
    if (this.AdduserForm.value.Password != this.AdduserForm.value.ConfirmPassword) {
      return;
    }
    let ParentUser = localStorage.getItem("userEmailID");
    let users = {
      "emailId":this.AdduserForm.value.useremailId,
      "password":this.AdduserForm.value.Password,
      "active":true,
      "usertype":5,
      "parentuser":ParentUser,
      "validGroupListCollection": this.ValidGroups 
    }
    this.usermaintenanceService.addUserDeatils(users).subscribe(
      (response=>{
        console.log(response);
      })
    )
  }
  ActiveUsers(NodeId){
    this.ActiveUsersNodeId = NodeId;
  }
  deleteUser(UserID){
    if(UserID !=null && UserID!=''){
      this.usermaintenanceService.deleteUser(UserID).subscribe(
        (response=>{
          this.ngOnInit();
        })
      )
    }
  }

  OepnAddUserDialog(UserManagementAddUser){
    this.Displayadduser = true;
    UserManagementAddUser.open();
    this.usermaintenanceService.getAvailableGroups().subscribe(
      (response=>{
        this.AvailableGroups = response;
      })
    )
  }
  changeUserEvent(SelectedGroups){ 
    this.addGroups = SelectedGroups;
    this.activeAvailabelGroups = SelectedGroups;
  }

  addGroupsEvent(){
    let selectedelemtn = null;
    this.AvailableGroups.forEach((element, index) => {
      if(element.code == this.addGroups){ 
       let select = {
          'name': element.name,
          'code':element.code
        }
        selectedelemtn = index;
        this.ValidGroups.push(select);
      }
    });
    if(selectedelemtn!=null){    
      for (var i = 0; i < this.AvailableGroups.length; i++) {
        for (var j = 0; j < this.ValidGroups.length; j++) { 
            if (JSON.stringify(this.AvailableGroups[i].code) == JSON.stringify(this.ValidGroups[j].code)) {
              this.AvailableGroups.splice(i, 1);
            }
        }
      }
    }
  }
  removeGroups(){
    let selectedelemtn = null;
    this.ValidGroups.forEach((element, index) => {
      if(element.code == this.removeGroup){
        selectedelemtn = index;
        this.AvailableGroups.push(element);
      }
    });
    if(selectedelemtn!=null){
      for (var i = 0; i < this.ValidGroups.length; i++) {
        for (var j = 0; j < this.AvailableGroups.length; j++) { 
            if (JSON.stringify(this.ValidGroups[i].code) == JSON.stringify(this.AvailableGroups[j].code)) {
              this.ValidGroups.splice(i, 1);
            }
        }
    }
    }
  }

  changeValiduserEvent(removeGroup){ 
    this.removeGroup = removeGroup;
    this.activeSelectedGroups = removeGroup;
  }

  OepnUpdateUserDialog(UserManagementUpdateUser){ 
    if(this.ActiveUsersNodeId!=null && this.ActiveUsersNodeId!=''){
      UserManagementUpdateUser.open();
      this.usermaintenanceService.getUpdateCollectionList(parseInt(this.ActiveUsersNodeId)).subscribe(
        ( (response)=>{
            //console.log(response);
            this.UpdateValidGroups = response.validGroupListCollection;
            this.UpdateAvailableGroups = response.availableGroupListCollection;
            this.UpdatedName = response.name;
        })
      )
    }
    
  }
  UpdateAddGroupsEvent(){
    let selectedelemtn = null;
    this.UpdateAvailableGroups.forEach((element, index) => {
      if(element.code == this.updateAddGroups){ 
       let select = {
          'name': element.name,
          'code':element.code
        }
        selectedelemtn = index;
        this.UpdateValidGroups.push(select);
      }
    });
    if(selectedelemtn!=null){    
      for (var i = 0; i < this.UpdateAvailableGroups.length; i++) {
        for (var j = 0; j < this.UpdateValidGroups.length; j++) { 
            if (JSON.stringify(this.UpdateAvailableGroups[i].code) == JSON.stringify(this.UpdateValidGroups[j].code)) {
              this.UpdateAvailableGroups.splice(i, 1);
            }
        }
      }
    }

  }
  UpdateremoveGroups(){
    let selectedelemtn = null;
    this.UpdateValidGroups.forEach((element, index) => {
      if(element.code == this.updateremoveGroup){
        selectedelemtn = index;
        this.UpdateAvailableGroups.push(element);
      }
    });
    if(selectedelemtn!=null){
      for (var i = 0; i < this.UpdateValidGroups.length; i++) {
        for (var j = 0; j < this.UpdateAvailableGroups.length; j++) { 
            if (JSON.stringify(this.UpdateValidGroups[i].code) == JSON.stringify(this.UpdateAvailableGroups[j].code)) {
              this.UpdateValidGroups.splice(i, 1);
            }
        }
    }
    }
  }

  changeUpdateUserEvent(SelectedGroups){ 
    this.updateAddGroups = SelectedGroups;
    this.activeudpateAvailabelGroups = SelectedGroups;
  }

  changeupdateValiduserEvent(removeGroup){ 
    this.activeupdateSelectedGroups = removeGroup;
    this.updateremoveGroup = removeGroup;
  }

}
