<div>
    <h2>Select Reports</h2>
    <div class="col-sm-12">
        <div class="col-sm-3">
            <label> Select Year</label>
        </div>
        <div class="col-sm-4">
            <select name="select year" *ngIf="Validcycle!=null" (change)="changecycleperiod($event)"
                class="col-sm-6 form-control">
                <option *ngFor="let result of Validcycle.cycleModelPeriodCollection; let i=index"
                    [selected]="i==Validcycle.selectedIndex" [value]="result.nodeId">{{result.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="col-sm-12" style="margin-top: 10px;">
        <div class="col-sm-3">
            <label> Select Template</label>
        </div>
        <div class="col-sm-4">
            <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" />
        </div>
    </div>
    <div class="col-sm-12" style="margin-top: 10px;" *ngIf="isActualStarted">
        <div class="col-sm-3">
            <label> Compare With</label>
        </div>
        <div class="col-sm-4">
            <select name="ForecastBudgetReport" style="margin-top:10px" *ngIf="ForecastbudgetReport!=null"
                class="form-control {{ForecastReportBudgteNodeId}}" (change)="ForecastbudgteReporChange($event)">
                <option *ngFor="let result of ForecastbudgetReport"
                    [selected]="ForecastReportBudgteNodeId == result.nodeId" value="{{result.nodeId}}">
                    {{result.englishLabel}}</option>
            </select>
        </div>
    </div>
    <div class="col-sm-12" style="margin-top: 10px;" *ngIf="isActualStarted">
        <div class="col-sm-3">
            <label> Variance Month</label>
        </div>
        <div class="col-sm-4">
            <select name="variationMonthSelection" style="font-size: 16px;" class="form-control"
                *ngIf="VarianceMonthColleciton!=null" (change)="changevarianceMonth($event)">
                <option></option>
                <option *ngFor="let month of VarianceMonthColleciton"
                    [selected]="month.index== VarianceMonthSelectionIndex" [id]="month.index">
                    {{month.monthDisplay}}
                </option>
            </select>
        </div>
    </div>
    <div class="col-sm-12" style="height: 370px;">
        <div class="col-sm-3">
            <label style="margin: 70% 0 0 0;">Select Reports</label>
        </div>
        <div class="col-sm-9">
            <div class="bs-example" style="height: 350px;margin-top: 2%;" *ngIf="NavitemSubmenu.length>0 ">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let result of NavitemSubmenu;  let i=index">
                        <a href="#{{result.type}}" class="nav-link" [ngClass]="{'active':i==0}"
                            data-toggle="tab">{{result.name}}</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade testingData" *ngFor="let Resultsbody of NavitemSubmenu; let data=index;"
                        id="{{Resultsbody.type}}" [ngClass]="{'active':data == '0'}">
                        <div *ngIf="Resultsbody.type=='Reports'">
                            <div class="col-sm-12" style="padding: 0px;">
                                <div class="col-sm-12" style="padding: 0px;">
                                    <div class="{{result.type}}"
                                        *ngFor="let result of BudgetReportCollection; let i=index">
                                        <div *ngIf="result.type === 'Budget'">
                                            <div class="col-sm-2">
                                                <input type="checkbox" value="{{result.value}}"
                                                    [checked]="result.status"
                                                    (change)="ScheduleReportChangeevent(result, $event, 'budget')">
                                            </div>
                                            <div class="col-sm-10" style="padding: 0px;">
                                                <label> {{result.name}}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Resultsbody.type=='Schedules'">
                            <div class="col-sm-12" style="padding: 0px;">
                                <div class="col-sm-12" style="padding: 0px;">
                                    <div class="" *ngFor="let result of BudgetReportCollection; let i=index">
                                        <div *ngIf="result.type === 'Schedules'">
                                            <div class="col-sm-2">
                                                <input type="checkbox" value="{{result.value}}"
                                                    [checked]="result.status"
                                                    (change)="ScheduleReportChangeevent(result, $event, 'schedules')">
                                            </div>
                                            <div class="col-sm-10" style="padding: 0px;">
                                                <label> {{result.name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Resultsbody.type=='Variance'  && isActualStarted">
                            <div class="col-sm-12" style="padding: 0px;">
                                <div class="col-sm-12" style="padding: 0px;">
                                    <div class="" *ngFor="let result of BudgetReportCollection; let i=index">
                                        <div *ngIf="result.type == 'Variance'">
                                            <div class="col-sm-2">
                                                <input type="checkbox" value="{{result.value}}"
                                                    [checked]="result.status"
                                                    (change)="ScheduleReportChangeevent(result, $event, 'schedules')">
                                            </div>
                                            <div class="col-sm-10" style="padding: 0px;">
                                                <label> {{result.name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12">
        <div class="col-sm-6">

        </div>
        <div class="col-sm-4">
            <button (click)="exportExcel()" class="form-control btn btn-primary" style="
    margin-bottom: 20px;
    width: 100px;
">Export</button>
        </div>
    </div>
    <div class="col-sm-12">

    </div>
    <div class="col-sm-12">

    </div>
</div>