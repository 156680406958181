<input id="filter3" #filter3 (keyup)="filterFn(filter3.value, tree.treeModel)"
style="width: 90%;padding: 14px;height: 38px;font-size: 16px;border-radius: 6px;" placeholder="Search Record" />
<button _ngcontent-foc-c126="" class="ForecastSearchicon" type="submit"><i _ngcontent-foc-c126=""
    class="fa fa-search"></i></button>
<div class="treeMenu"><tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="nodes"
    (initialized)="onTreeLoad()" (activate)="ActiveEvent($event)" (updateData)="onUpdateData()">
    <ng-template #treeNodeTemplate let-node let-index="index">
      <span [attr.draggable]="true" [attr.nodeType]="node.data.nodeType" [attr.aria-grabbed]="true"
        [class.draggable]="true" [ngClass]="{'InActive':Formulatree==node.data.nodeId}" 
        class="w-full flex items-center "
        [ngClass]="{'LongNameDisplay': node.data.name.length>27 , 'ShorNameDisplay': node.data.name.length<=27}" 
        style="padding-bottom: 6px;"
         [class]="node.data.name"
        id="{{node.data.nodeId}}" [class.title]="true">

        <span class="folder-icon-css folder-icon-css material-symbols-outlined text-blue-500" *ngIf="node?.data?.nodeType==40000 
      || node?.data?.nodeType==10001
      || node?.data?.nodeType==10002
      || node?.data?.nodeType==10100
      ">
      <igx-icon style="font-size: 19px;color: blue !important;margin: 5px 0 0 0;" class="folder-icon-css material-symbols-outlined text-blue-500" aria-hidden="true">
        account_tree</igx-icon>
    </span>
        <span class="record-icon-css material-symbols-outlined text-purple-400" *ngIf="node?.data?.children?.length <= 0
      && node?.data?.canAddSection == false
      && node?.data?.levelIndex != 2
      && node?.data?.canAddDepartment==false 
      && node?.data?.nodeType !=40000
      ">
      <igx-icon style="font-size: 19px; color: green !important; margin: 5px 0 0 0;"  aria-hidden="true">
        receipt_long</igx-icon>
    </span>

        <span class="section-icon-css material-symbols-outlined text-yellow-500" *ngIf="node?.data?.nodeType >= 30000 && node?.data?.nodeType <= 30017">
          <igx-icon style="font-size: 19px; color: orange !important;margin: 5px 0 0 0;"  aria-hidden="true">
            folder_open</igx-icon>
        </span>

        <span *ngIf="node?.data?.canDelete === true &&(node?.data?.nodeType==40000 
        || node?.data?.nodeType!==10001
        || node?.data?.nodeType==10002
        || node?.data?.nodeType==10100)
    && node.data?.nodeType!=30002 && node?.data?.nodeType < 30000" class="department-icon-css  material-symbols-outlined text-gray-400">
          <igx-icon style="font-size: 19px; color: gray !important; margin: 5px 0 0 0;"  aria-hidden="true">
            corporate_fare</igx-icon>
        </span>

        <span id="{{node.data.nodeId}}-treemenu" style="line-height: 1.2 !important;padding: 0px 2px 1px 8px !important;" class="pl-2 pr-3 text-sm">{{ node.data.name }}</span></span>
    </ng-template>
  </tree-root></div>