import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyServiceService } from 'src/app/service/SettingServices/CurrencyService/currency-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormulaService } from '../../service/FormulaService/formula.service';
import { FormulaPermission } from 'src/app/model/FormulaPermissions';
import { FormulaOperandEnum, FormulaModel, FormulaDetailModel } from 'src/app/model/FormulaModel'
import { TreeviewService } from 'src/app/service/treeview.service';


@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {

  @Input() FormulaDetails: any;
  @Output() formulaModifiedEvent = new EventEmitter<FormulaModel>();

  enum: typeof FormulaOperandEnum = FormulaOperandEnum;
  FormulaValueList: any = [];
  FormulaPartVm: any = [];
  FormualModelObj: FormulaModel;
  SelectedFormulaPartVm: FormulaPermission;
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  formulatree: any;
  formulavalueNodeId: any;
  FormulaOparand: FormulaOperandEnum;
  selectedIndex: any;
  formulatreerecordactive: any;
  formulatreerecordnodetypeactive: any
  formulatreeActiveRecord: any;
  Treeviews = [];
  ActiveOprandId: any = 0;
  loadingFormulaTreeView:boolean;

  formulaselecteNodeId: any;
  NumericNumber: any;
  constructor(private CurrencyServiceServices: CurrencyServiceService,
    private SpinnerService: NgxSpinnerService,
    private formulaService: FormulaService,
    private treeview: TreeviewService) {

  }

  async ngOnInit() {
    this.loadingFormulaTreeView = false;
    this.FormulaValueList = [];
    this.FormulaPartVm = [];
    this.FormualModelObj = new FormulaModel();
    this.selectedIndex = 0;
    this.SelectedFormulaPartVm = new FormulaPermission();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.getPeriodCollection(ForecastNodeId);
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }

  IncludeTypeChange($event) {
    let value = parseInt($event.target.value);
    this.SpinnerService.show();
    try {
      this.formulaService.SavetIncludeType(this.FormulaDetails[0].valuesNodeId, value).subscribe(
        (async (response: any) => {
          this.SpinnerService.hide();
        })
      )
    } catch (error) {
      this.SpinnerService.hide();
    }

  }
  NagateValueChange($event) {
    let value = $event.target.checked;
    this.SpinnerService.show();
    this.formulaService.SaveNegataValue(this.FormulaDetails[0].valuesNodeId, value).subscribe(
      (async (response: any) => {
        this.SpinnerService.hide();
        this.ngOnChanges();
      })
    )
  }

  FormulaTreeEventClicked($event) {
    this.formulatreerecordactive = parseInt($event.nodeId);
    this.formulatreerecordnodetypeactive = 210010;
    this.FormulaOparand = FormulaOperandEnum.FormulaDataOperand;
    this.formulatreeActiveRecord = $event;
    this.GenerateFormulaPermission(this.FormulaOparand);
  }

  GenerateFormulaPermission(formulaOpp: FormulaOperandEnum) {

    this.SelectedFormulaPartVm = new FormulaPermission();
    this.SelectedFormulaPartVm.canAdd = this.canAddItem(formulaOpp);
    this.SelectedFormulaPartVm.canAddLeftParenthesis = this.canAddItem(FormulaOperandEnum.FormulaLeftParenthesis);
    this.SelectedFormulaPartVm.canAddRightParenthesis = this.canAddItem(FormulaOperandEnum.FormulaRightParenthesis);
    this.SelectedFormulaPartVm.canClear = this.canClearItem();
    this.SelectedFormulaPartVm.canDelete = this.canDeleteItem(formulaOpp);
    this.SelectedFormulaPartVm.canReplace = this.canReplaceItem(formulaOpp);
  }

  canClearItem() {
    if (this.FormualModelObj == null) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails.length == 0) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails.length > 0) {
      return true;
    }
  }

  canAddItem(FormulaOp: FormulaOperandEnum) {
    // console.log("FormulaOp", FormulaOp);
    if (this.FormualModelObj == null) {
      return true;
    }
    if (this.FormualModelObj.FormulaDetails == null) {
      return true;
    }
    if (this.FormualModelObj.FormulaDetails.length == 0) {
      return true;
    }
    if (this.FormualModelObj.FormulaDetails[this.selectedIndex] == null) {
      return true;
    }
    if (FormulaOp == undefined) {
      return false;
    }
    switch (FormulaOp) {
      case FormulaOperandEnum.FormulaDataOperand:
      case FormulaOperandEnum.FormulaDecimalOperand:
        return this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType != FormulaOperandEnum.FormulaDataOperand &&
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType != FormulaOperandEnum.FormulaDecimalOperand;
      case FormulaOperandEnum.FormulaLeftParenthesis:
        return (this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType != FormulaOperandEnum.FormulaRightParenthesis);
      case FormulaOperandEnum.FormulaRightParenthesis:
        return this.selectedIndex > 0 && (this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDataOperand ||
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDecimalOperand ||
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaRightParenthesis);
      case FormulaOperandEnum.FormulaPlus:
      case FormulaOperandEnum.FormulaMinus:
      case FormulaOperandEnum.FormulaMultiply:
      case FormulaOperandEnum.FormulaDivide:
        return this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDataOperand ||
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDecimalOperand ||
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaRightParenthesis;
    }
  }
  canReplaceItem(FormulaOp: FormulaOperandEnum) {
    if (this.FormualModelObj == null) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails == null) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails.length == 0) {
      return false;
    }

    if (FormulaOp == undefined) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails[this.selectedIndex] == null) {
      return false;
    }
    switch (FormulaOp) {
      case FormulaOperandEnum.FormulaDataOperand:
      case FormulaOperandEnum.FormulaDecimalOperand:
        return this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDataOperand ||
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType == FormulaOperandEnum.FormulaDecimalOperand;
      case FormulaOperandEnum.FormulaLeftParenthesis:
      case FormulaOperandEnum.FormulaRightParenthesis:
        return false;
      case FormulaOperandEnum.FormulaPlus:
      case FormulaOperandEnum.FormulaMinus:
      case FormulaOperandEnum.FormulaMultiply:
      case FormulaOperandEnum.FormulaDivide:
        return this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType != FormulaOperandEnum.FormulaDataOperand &&
          this.FormualModelObj.FormulaDetails[this.selectedIndex].operatorType != FormulaOperandEnum.FormulaDecimalOperand;
    }
  }
  canDeleteItem(FormulaOp: FormulaOperandEnum) {
    if (this.FormualModelObj == null) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails == null) {
      return false;
    }
    if (this.FormualModelObj.FormulaDetails.length == 0) {
      return false;
    }

    // console.log("can delete this.selectedIndex ", this.selectedIndex, this.FormualModelObj.FormulaDetails.length);
    if (this.selectedIndex == this.FormualModelObj.FormulaDetails.length - 1) {
      return true;
    }
    if (FormulaOp == undefined) {
      return true;
    }
    if (this.FormualModelObj.FormulaDetails[this.selectedIndex] == null) {
      return false;
    }
    switch (FormulaOp) {
      case FormulaOperandEnum.FormulaLeftParenthesis:
      case FormulaOperandEnum.FormulaRightParenthesis:
        return true;
      default:
        return false;
    }
  }

  getPeriodCollection(ForecastNodeId) {
    this.PeriodTabViewCollection = [];
    this.DisplayMonthCollection = [];
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      (response => {
        let PeriodViewResponse: any = response;
        // console.log("getPeriodCollection", response);
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }

  /** 
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
    console.log(result , this.PeriodStartIndex , this.PeriodEndIndex , this.FormulaValueList);
  }

  /**
   * 
   * @param index 
   * @param nodeId 
   */

  selectedFormulaChanged(index, nodeId) {
    // console.log("selectedFormulaChanged", nodeId);
    // this.SpinnerService.show();
    var selectedNodeType = 'Undefined';
    // if (this.FormulaOparand != null) {
    //   selectedNodeType = this.FormulaOparand.toString();
    //   this.formulaselecteNodeId = nodeId.nodeId;
    // }
    this.formulaselecteNodeId = nodeId.nodeId;
    this.selectedIndex = index;

    // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, index, selectedNodeType).subscribe(
    //   (async (response: any) => {
    //     this.SpinnerService.hide();
    //     this.SelectedFormulaPartVm = [];
    //     this.SelectedFormulaPartVm = response;

    //   })
    // )
    this.GenerateFormulaPermission(this.FormulaOparand);
  }
  /**
   * 
   * @param $event 
   */
  ChangeNumericNumber($event) {
    // console.log("changeNumeric");
    this.FormulaOparand = FormulaOperandEnum.FormulaDecimalOperand;
    this.NumericNumber = $event.target.value.replaceAll(/,/g, '');
    // this.SpinnerService.show();
    // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, this.selectedIndex, this.FormulaOparand).subscribe(
    //   (async (response: any) => {
    //     this.SpinnerService.hide();
    //     this.SelectedFormulaPartVm = [];
    //     this.SelectedFormulaPartVm = response;
    //   })
    // )
    this.GenerateFormulaPermission(this.FormulaOparand);

  }
  addFormulaOparand() {
    if (this.FormulaOparand != null) {

      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex;
      formualDet.isDeleted = false;

      if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
        formualDet.name = this.formulatreeActiveRecord.name;
        formualDet.nodeId = this.formulatreeActiveRecord.nodeId;
        formualDet.isModified = true;

        formualDet.operatorType = this.FormulaOparand;
        console.log("this.formulatreeActiveRecord.budgetData", this.formulatreeActiveRecord);
        formualDet.valuesArray = this.formulatreeActiveRecord.budgetData.valuesArray;
      }
      else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
        formualDet.name = parseFloat(this.NumericNumber).toString();
        formualDet.nodeId = 0;
        formualDet.isModified = true;

        formualDet.operatorType = this.FormulaOparand;
        for (let j = 0; j < this.DisplayMonthCollection.length; j++) {
          formualDet.valuesArray.push(parseFloat(this.NumericNumber).toString());
        }
        var numericvalue: any = document.getElementsByClassName('numericnumber');
        numericvalue[0].value = 0;
      }
      else {
        var formualDet = new FormulaDetailModel();
        formualDet.index = this.selectedIndex;
        formualDet.nodeId = 0;
        formualDet.isModified = true;
        console.log(this.FormulaOparand);
        switch (this.FormulaOparand) {
          case FormulaOperandEnum.FormulaDivide:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "/";
            this.FormulaOparand = null;
            break;
          case FormulaOperandEnum.FormulaMinus:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "-";
            this.FormulaOparand = null;
            break;
          case FormulaOperandEnum.FormulaMultiply:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "*";
            this.FormulaOparand = null;
            break;
          case FormulaOperandEnum.FormulaPlus:
            formualDet.operatorType = this.FormulaOparand
            this.FormulaOparand = null;
            formualDet.name = "+";
            break;
        }
      }

      if (this.selectedIndex == this.FormualModelObj.FormulaDetails.length - 1) {
        this.FormualModelObj.FormulaDetails.push(formualDet);
      }
      else {
        this.FormualModelObj.FormulaDetails.splice(this.selectedIndex + 1, 0, formualDet);
      }
      this.selectedIndex = this.selectedIndex + 1;
      this.EvaluateBudgetResult();
      //console.log("this.FormualModelObj.FormualDetails ADD", this.FormualModelObj.FormulaDetails);
    }
  }

  getBudgteData() {
    // console.log("getBudgteData", this.FormulaDetails[0].valuesNodeId);
    this.formulaService.getFormulaBudgetCollection(this.FormulaDetails[0].valuesNodeId).subscribe(
      (async (response: any) => {
        this.FormulaValueList = [];
        this.FormulaValueList = response;
        this.formulatree = this.FormulaDetails[0].recordNodeId;
        console.log("getBudgteData response", response);
      })
    )
  }
  replaceFormulaOparand() {
    // this.SpinnerService.show();
    // let selectedNode = this.formulaselecteNodeId;
    // let NewNodeType = this.FormulaOparand;
    // let newObject = 0;
    // if (this.FormulaOparand != null) {
    //   if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.formulatreerecordactive;
    //   } else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.NumericNumber;
    //   } else {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = 0;
    //   }
    // }
    // if (selectedNode == undefined) {
    //   selectedNode = 0;
    // }
    // let nodeId = this.FormulaDetails[0].valuesNodeId;
    // this.formulaService.ReplaceFormulaOparand(nodeId, NewNodeType, newObject, selectedNode).subscribe(
    //   ((response: any) => {
    //     try {
    //       this.SpinnerService.hide();
    //       this.FormulaPartVm = response;
    //       if (this.FormulaPartVm != null && this.FormulaPartVm.getFormulaVm.length != 0) {
    //         this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       }
    //       this.getBudgteData();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
    if (this.FormulaOparand != null) {

      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex;
      formualDet.isDeleted = false;

      if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
        formualDet.name = this.formulatreeActiveRecord.name;
        formualDet.nodeId = this.formulatreeActiveRecord.nodeId;
        formualDet.isModified = true;

        formualDet.operatorType = this.FormulaOparand;
        formualDet.valuesArray = this.formulatreeActiveRecord.budgetData.valuesArray;
      }
      else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
        formualDet.name = this.NumericNumber;
        formualDet.nodeId = 0;
        formualDet.isModified = true;
        formualDet.operatorType = this.FormulaOparand;
        for (let j = 0; j < this.DisplayMonthCollection.length; j++) {
          formualDet.valuesArray.push(this.NumericNumber.toString());
        }
        var numericvalue: any = document.getElementsByClassName('numericnumber');
        numericvalue[0].value = 0;
      }
      else {
        var formualDet = new FormulaDetailModel();
        formualDet.index = this.selectedIndex;
        formualDet.nodeId = 0;
        formualDet.isModified = true;

        switch (this.FormulaOparand) {
          case FormulaOperandEnum.FormulaDivide:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "/";
            break;
          case FormulaOperandEnum.FormulaMinus:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "-";
            break;
          case FormulaOperandEnum.FormulaMultiply:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "*";
            break;
          case FormulaOperandEnum.FormulaPlus:
            formualDet.operatorType = this.FormulaOparand
            formualDet.name = "+";

            break;
        }
      }

      // if (this.selectedIndex == this.FormualModelObj.FormualDetails.length - 1) {
      //   this.FormualModelObj.FormualDetails.push(formualDet);
      // }
      // else {
      //   this.FormualModelObj.FormualDetails.splice(this.selectedIndex, 1, formualDet);
      // }
      this.FormualModelObj.FormulaDetails[this.selectedIndex] = formualDet;
      this.selectedIndex = this.selectedIndex;
      this.EvaluateBudgetResult();
      // console.log("this.FormualModelObj.FormualDetails ADD", this.FormualModelObj.FormulaDetails);
    }
  }

  clearFormulaOparand() {
    // this.SpinnerService.show();
    // let selectedNode = this.formulaselecteNodeId;
    // let NewNodeType = this.FormulaOparand;
    // let newObject = 0;
    // let nodeId = this.FormulaDetails[0].valuesNodeId;
    // if (this.FormulaOparand != null) {
    //   if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.formulatreerecordactive;
    //   } else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.NumericNumber;
    //   } else {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = 0;
    //   }
    // }
    // if (selectedNode == undefined) {
    //   selectedNode = 0;
    // }
    // this.formulaService.ClearFormulaOparand(nodeId, NewNodeType, newObject, selectedNode).subscribe(
    //   ((response: any) => {
    //     try {
    //       this.SpinnerService.hide();
    //       this.FormulaPartVm = response;
    //       if (this.FormulaPartVm != null) {
    //         //this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       }
    //       this.getBudgteData();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
    this.FormualModelObj.FormulaDetails = [];
    this.FormualModelObj.iscleared = true;
    this.EvaluateBudgetResult();
  }

  DeleteFormulaOparand() {
    // this.SpinnerService.show();
    // let selectedNode = this.formulaselecteNodeId;
    // let NewNodeType = this.FormulaOparand;
    // let newObject = 0;
    // let nodeId = this.FormulaDetails[0].valuesNodeId;
    // if (this.FormulaOparand != null) {
    //   if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.formulatreerecordactive;
    //   } else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.NumericNumber;
    //   } else {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = 0;
    //   }
    // }
    // if (selectedNode == undefined) {
    //   selectedNode = 0;
    // }
    // this.formulaService.DeleteFormulaOparand(nodeId, NewNodeType, newObject, selectedNode).subscribe(
    //   ((response: any) => {
    //     try {

    //       this.FormulaPartVm = response;
    //       // if(this.FormulaPartVm!=null){
    //       //   this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       // }
    //       if (this.FormulaPartVm.getFormulaVm.length == 0) {
    //         this.selectedIndex = this.FormulaPartVm.getFormulaVm.length - 1;
    //       } else {
    //         this.selectedIndex = this.selectedIndex - 1;
    //       }
    //       if (this.FormulaPartVm != null && this.FormulaPartVm.getFormulaVm.length != 0) {
    //         this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       }

    //       if (this.FormulaPartVm.getFormulaVm.length != 0) {
    //         var selectedNodeType = 'Undefined';
    //         this.selectedIndex = this.selectedIndex;
    //         // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, this.selectedIndex, selectedNodeType).subscribe(
    //         //   (async (response: any) => {
    //         //     this.SpinnerService.hide();
    //         //     this.SelectedFormulaPartVm = [];
    //         //     this.SelectedFormulaPartVm = response;
    //         //     this.selectedIndex = this.selectedIndex;
    //         //   })
    //         // )
    //         this.GenerateFormulaPermission(this.FormulaOparand);
    //       }

    //       this.getBudgteData();
    //       //this.getBudgteData();
    //       this.SpinnerService.hide();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
    // this.FormualModelObj.FormulaDetails[this.selectedIndex].isDeleted = true;
    // this.FormualModelObj.FormulaDetails[this.selectedIndex].isModified = true;
    this.FormualModelObj.FormulaDetails.splice(this.selectedIndex, 1);
    if (this.selectedIndex > 1) {
      var inputValue: any = (<HTMLInputElement>document.getElementById("FormulaObjectDetails-" + (this.selectedIndex - 1)));
      if (inputValue != null) {
        inputValue.click();
      }
    }


    this.EvaluateBudgetResult();
  }
  AddLeftParenthesisFormulaOparand() {
    // this.SpinnerService.show();
    // let selectedNode = this.formulaselecteNodeId;
    // let NewNodeType = this.FormulaOparand;
    // let newObject = 0;
    // let nodeId = this.FormulaDetails[0].valuesNodeId;
    // if (this.FormulaOparand != null) {
    //   if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.formulatreerecordactive;
    //   } else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.NumericNumber;
    //   } else {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = 0;
    //   }
    // }
    // if (selectedNode == undefined) {
    //   selectedNode = 0;
    // }
    // this.formulaService.AddLeftParenthesisFormulaOparand(nodeId, NewNodeType, newObject, selectedNode).subscribe(
    //   ((response: any) => {
    //     try {
    //       this.FormulaPartVm = response;
    //       // if(this.FormulaPartVm!=null){
    //       //   console.log(this.FormulaPartVm.getFormulaVm.length);
    //       //   this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       // }
    //       if (this.FormulaPartVm != null) {
    //         if (this.FormulaPartVm.getFormulaVm.length > 0) {
    //           this.selectedIndex = this.FormulaPartVm.getFormulaVm.length - 1;
    //         } else {
    //           this.selectedIndex = this.selectedIndex;
    //         }
    //         this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //         // this.getBudgteData();
    //         var selectedNodeType = 'Undefined';
    //         // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, this.selectedIndex, selectedNodeType).subscribe(
    //         //   (async (response: any) => {
    //         //     this.SpinnerService.hide();
    //         //     this.SelectedFormulaPartVm = [];
    //         //     this.SelectedFormulaPartVm = response;
    //         //     this.selectedIndex = this.selectedIndex;
    //         //   })
    //         // )
    //         this.GenerateFormulaPermission(this.FormulaOparand);
    //         this.selectedIndex = this.selectedIndex;

    //       }
    //       this.getBudgteData();
    //       this.SpinnerService.hide();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
    let formuladetailesLength = 1;
    if (this.FormualModelObj.FormulaDetails != null) {
      formuladetailesLength = this.FormualModelObj.FormulaDetails.length;
    }
    if (this.selectedIndex == formuladetailesLength - 1) {
      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex + 1;
      formualDet.isDeleted = false;
      formualDet.name = "(";
      formualDet.nodeId = 0;
      formualDet.isModified = true;
      formualDet.operatorType = FormulaOperandEnum.FormulaLeftParenthesis;
      this.FormualModelObj.FormulaDetails.push(formualDet);
      this.selectedIndex = this.selectedIndex + 1;
    }
    else {
      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex + 1;
      formualDet.isDeleted = false;
      formualDet.name = "(";
      formualDet.nodeId = 0;
      formualDet.isModified = true;
      formualDet.operatorType = FormulaOperandEnum.FormulaLeftParenthesis;
      this.FormualModelObj.FormulaDetails.splice(this.selectedIndex, 0, formualDet);
      this.selectedIndex = this.selectedIndex + 1;
    }
    // console.log("AddLeftParenthesisFormulaOparand", this.FormualModelObj);
  }

  AddRightParenthesisFormulaOparand() {
    // this.SpinnerService.show();
    // let selectedNode = this.formulaselecteNodeId;
    // let NewNodeType = this.FormulaOparand;
    // let newObject = 0;
    // let nodeId = this.FormulaDetails[0].valuesNodeId;
    // if (this.FormulaOparand != null) {
    //   if (this.FormulaOparand == FormulaOperandEnum.FormulaDataOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.formulatreerecordactive;
    //   } else if (this.FormulaOparand == FormulaOperandEnum.FormulaDecimalOperand) {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = this.NumericNumber;
    //   } else {
    //     NewNodeType = this.FormulaOparand;
    //     newObject = 0;
    //   }
    // }
    // if (selectedNode == undefined) {
    //   selectedNode = 0;
    // }
    // this.formulaService.AddRightParenthesisFormulaOparand(nodeId, NewNodeType, newObject, selectedNode).subscribe(
    //   ((response: any) => {
    //     try {
    //       this.FormulaPartVm = response;
    //       // if(this.FormulaPartVm!=null){
    //       //   console.log(this.FormulaPartVm.getFormulaVm.length);
    //       //   this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //       // }
    //       if (this.FormulaPartVm != null) {
    //         if (this.FormulaPartVm.getFormulaVm.length > 0) {
    //           this.selectedIndex = this.FormulaPartVm.getFormulaVm.length - 1;
    //         } else {
    //           this.selectedIndex = this.selectedIndex;
    //         }
    //         this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
    //         // this.getBudgteData();
    //         var selectedNodeType = 'Undefined';
    //         // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, this.selectedIndex, selectedNodeType).subscribe(
    //         //   (async (response: any) => {
    //         //     this.SpinnerService.hide();
    //         //     this.SelectedFormulaPartVm = [];
    //         //     this.SelectedFormulaPartVm = response;
    //         //     this.selectedIndex = this.selectedIndex;
    //         //   })
    //         // )
    //         this.GenerateFormulaPermission(this.FormulaOparand);
    //         this.selectedIndex = this.selectedIndex;
    //       }
    //       this.getBudgteData();
    //       this.SpinnerService.hide();
    //     } catch (error) {
    //       this.SpinnerService.hide();
    //     }
    //   })
    // )
    let formuladetailesLength = 1;
    if (this.FormualModelObj.FormulaDetails != null) {
      formuladetailesLength = this.FormualModelObj.FormulaDetails.length;
    }

    if (this.selectedIndex == formuladetailesLength - 1) {
      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex + 1;
      formualDet.isDeleted = false;
      formualDet.name = ")";
      formualDet.nodeId = 0;
      formualDet.isModified = true;
      formualDet.operatorType = FormulaOperandEnum.FormulaRightParenthesis;
      this.FormualModelObj.FormulaDetails.push(formualDet);
      this.selectedIndex = this.selectedIndex + 1;
      this.EvaluateBudgetResult();
    }
    else {
      var formualDet = new FormulaDetailModel();
      // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
      formualDet.index = this.selectedIndex + 1;
      formualDet.isDeleted = false;
      formualDet.name = ")";
      formualDet.nodeId = 0;
      formualDet.isModified = true;
      formualDet.operatorType = FormulaOperandEnum.FormulaRightParenthesis;
      this.FormualModelObj.FormulaDetails.splice(this.selectedIndex, 0, formualDet);
      this.selectedIndex = this.selectedIndex + 1;
      this.EvaluateBudgetResult();
    }
    // console.log("AddRightParenthesisFormulaOparand", this.FormualModelObj);

  }
  ChangedValidOperationType(NewNodeType, index) {
    // this.SpinnerService.show();

    this.formulatreerecordactive = null;
    this.FormulaOparand = NewNodeType;
    // this.formulatreerecordnodetypeactive = 210010;
    // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, index, NewNodeType).subscribe(
    //   (async (response: any) => {
    //     this.SpinnerService.hide();
    //     this.SelectedFormulaPartVm = [];
    //     this.SelectedFormulaPartVm = response;
    //     this.FormulaOparand = NewNodeType;
    //   })
    // )
    this.GenerateFormulaPermission(this.FormulaOparand);
  }

  ConvertToFormulaModel(formulaDetails: any, valuesNodeiD: any, recordNodeID: any) {
    if (this.FormualModelObj == null) {
      this.FormualModelObj = new FormulaModel();
    }
    // console.log("ConvertToFormulaModel input ", formulaDetails, valuesNodeiD);
    this.FormualModelObj.valuesNodeID = valuesNodeiD;
    this.FormualModelObj.recordNodeID = recordNodeID;

    if (formulaDetails.getFormulaVm.length > 0) {
      this.FormualModelObj.FormulaDetails = [];
      for (let i = 0; i < formulaDetails.getFormulaVm.length; i++) {
        var formualDet = new FormulaDetailModel();
        // console.log("formulaDetails.getFormulaVm[i].formulaOparand", formulaDetails.getFormulaVm[i].formulaOperand.operatorType);
        formualDet.index = i;
        formualDet.isDeleted = false;
        formualDet.isModified = false;
        formualDet.name = formulaDetails.getFormulaVm[i].name;
        formualDet.nodeId = formulaDetails.getFormulaVm[i].formulaOperand.nodeId;
        formualDet.operatorType = formulaDetails.getFormulaVm[i].formulaOperand.operatorType;
        if (formulaDetails.getFormulaVm[i].formulaOperand.operatorType == FormulaOperandEnum.FormulaDataOperand) {
          if (formulaDetails.getFormulaVm[i].budgetData != null)
            formualDet.valuesArray = formulaDetails.getFormulaVm[i].budgetData.valuesArray;
        }
        if (formulaDetails.getFormulaVm[i].formulaOperand.operatorType == FormulaOperandEnum.FormulaDecimalOperand) {
          if (formulaDetails.getFormulaVm[i].decimalValue != null)
            for (let j = 0; j < this.DisplayMonthCollection.length; j++) {
              formualDet.valuesArray.push(formulaDetails.getFormulaVm[i].decimalValue.toString());
            }
        }
        this.FormualModelObj.FormulaDetails.push(formualDet);
      }
    }
    this.EvaluateBudgetResult();
  }

  EvaluateBudgetResult() {
    if (this.FormualModelObj == null) {
      return 0;
    }
    if (this.FormualModelObj.FormulaDetails != null) {
      for (let j = 0; j < this.DisplayMonthCollection.length; j++) {
        let value: string = "";
        let arrayValue = [];
        for (let i = 0; i < this.FormualModelObj.FormulaDetails.length; i++) {
          if (this.FormualModelObj.FormulaDetails[i].isDeleted == false) {
            switch (this.FormualModelObj.FormulaDetails[i].operatorType) {
              case FormulaOperandEnum.FormulaLeftParenthesis:
                value = value + "(";
                break;
              case FormulaOperandEnum.FormulaDataOperand:

              case FormulaOperandEnum.FormulaDecimalOperand:
                value = value + this.FormualModelObj.FormulaDetails[i].valuesArray[j];
                break;
              case FormulaOperandEnum.FormulaDivide:
                value = value + "/";
                break;
              case FormulaOperandEnum.FormulaMinus:
                value = value + "-";
                break;
              case FormulaOperandEnum.FormulaMultiply:
                value = value + "*";
                break;
              case FormulaOperandEnum.FormulaPlus:
                value = value + "+";
                break;
              case FormulaOperandEnum.FormulaRightParenthesis:
                value = value + ")";
                break;
            }
          }
        }
        if (value != "") {
          try {
            this.FormulaValueList[j] = eval(value);
          }
          catch (e) {
            this.FormulaValueList[j] = 0;
          }
        }
        else {
          this.FormulaValueList[j] = 0;
        }
      }
    }
    this.FormualModelObj.ValueList = this.FormulaValueList;
    if (this.FormulaPartVm.negateValues == true) {
      let NegateValuesArray = [];
      this.FormualModelObj.ValueList.forEach((element: any, index) => {
        let negateRealValue: any = element * -1;
        NegateValuesArray[index] = negateRealValue;
      });
      this.FormualModelObj.ValueList = NegateValuesArray;
      this.FormulaValueList = this.FormualModelObj.ValueList;
    }


    this.FormualModelObj.FormulaDetails.forEach(element => {
      if (element.operatorType == 210010) {
        this.ConvertTreeStructure(this.Treeviews, element.name);
        element.nodeId = this.ActiveOprandId;
        this.ActiveOprandId = 0;
      }
    });
    localStorage.setItem("FormulaDetailsToSave", JSON.stringify(this.FormualModelObj));
    this.formulaModifiedEvent.emit(this.FormualModelObj);
  }


  async ngOnChanges() {
    this.FormulaValueList = [];
    this.FormulaPartVm = [];
    this.selectedIndex = 0;
    this.SelectedFormulaPartVm = null;
    let ForecastNodeId = localStorage.getItem('ForecastId');
    if (this.PeriodTabViewCollection.length > 0) {
      this.getPeriodCollection(ForecastNodeId);
    }
    this.formulavalueNodeId = this.FormulaDetails[0].valuesNodeId;
    localStorage.setItem("FormulaTreeUpdateRecordNodeId", this.FormulaDetails[0].recordNodeId);
    let ApiCallFormulaTree = false;
    this.loadingFormulaTreeView = false;
    let storedFormualTree = localStorage.getItem("FormulaRecordTree");
    if (storedFormualTree != null && storedFormualTree != "null") {
      let jsonstoredFormualTree = JSON.parse(storedFormualTree);
      if (jsonstoredFormualTree != null) {
        this.Treeviews = jsonstoredFormualTree;
        ApiCallFormulaTree = true;
      }
    }
    if (!ApiCallFormulaTree) {
      this.loadingFormulaTreeView = true;
      this.treeview.getFormulaTreeMenu(this.formulavalueNodeId).subscribe(
        (response: any) => { 
          this.loadingFormulaTreeView = false;
          this.Treeviews = response;
          localStorage.setItem("FormulaRecordTree", JSON.stringify(this.Treeviews));
        }
      );
    }

    this.formulatree = this.FormulaDetails[0].recordNodeId;
    // this.formulaService.getFormulaBudgetCollection(this.FormulaDetails[0].valuesNodeId).subscribe(
    //   (async (response: any) => {
    //     console.log("getFormulaBudgetCollection onChanges", response);
    //     this.FormulaValueList = response;
    //     this.formulatree = this.FormulaDetails[0].recordNodeId;
    //   })
    // )
    // console.log("this.FormulaDetails[0] before in on change", this.FormulaDetails[0].valuesNodeId);
    this.formulaService.getFormulaDetails(this.FormulaDetails[0].valuesNodeId).subscribe(
      (async (response: any) => {
        this.FormulaPartVm = response;
        // console.log("getFormulaDetails FormulaPartVm onChanges", response);
        this.ConvertToFormulaModel(this.FormulaPartVm, this.FormulaDetails[0].valuesNodeId, this.FormulaDetails[0].recordNodeId);
        if (this.FormulaPartVm.getFormulaVm != null) {
          if (this.FormulaPartVm.getFormulaVm.length > 0) {
            this.formulaselecteNodeId = this.FormulaPartVm.getFormulaVm[this.selectedIndex].formulaOperand.nodeId;
          }

        }

      })
    )
    // this.formulaService.getSelectedFormulaDetails(this.FormulaDetails[0].valuesNodeId, this.selectedIndex, 'Undefined').subscribe(
    //   (async (response: any) => {
    //     this.SelectedFormulaPartVm = response;
    //     console.log(this.SelectedFormulaPartVm);
    //   })
    // )
    console.log(this.FormulaOparand);
    this.GenerateFormulaPermission(this.FormulaOparand);
  }

  ConvertTreeStructure(node, Name) {

    if (node.name == Name) {
      //return node.nodeId;
    }
    if (node.children != null && node.children.length > 0) {
      var treechildren = node.children.filter(child => child.nodeType < 60000);
      if (treechildren != null && treechildren.length > 0) {
        treechildren.forEach(element => {
          if (element.name == Name) {
            this.ActiveOprandId = element.nodeId;
          }
          this.ConvertTreeStructure(element, Name)
        });
      }
    }
    return node.nodeId;
  }

}
