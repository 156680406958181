import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ServiceService } from './services/service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Forecastmodel } from '../list-forecast/services/forecastmodel';
import { NgxSpinnerService } from "ngx-spinner";
import { EnterActualsService } from '../../service/enter-actuals.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { GridSelectionMode, IgxDialogComponent, IgxDialogModule, IgxGridComponent, IgxSnackbarComponent, IRowSelectionEventArgs } from 'igniteui-angular';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MenuService } from 'src/app/service/menu.service';
@Component({
  selector: 'app-list-forecast',
  templateUrl: './list-forecast.component.html',
  styleUrls: ['./list-forecast.component.scss']
})
export class ListForecastComponent implements OnInit {
  @ViewChild('grid1', { static: true }) public grid1: IgxGridComponent;
  ServiceService: ServiceService[] = [];
  mainchildren = [];
  record: Forecastmodel[] = [];
  forecastlist = [];
  details: any = [];
  NodeId: any;
  systemCalculation = false;
  consolidaitonCalculation = false;
  currentUserEmail: any = '';
  clientName: any = '';
  currentUserGroup: any = '';
  ActiveForecastDiv: boolean;
  ActiveForecastConsolidationDiv: boolean;
  Newforecastselected: any;
  userEmailID = "";
  public selectedRows = [19];

  constructor(private listservice: ServiceService,
    private _router: Router,
    private enterActualsService: EnterActualsService,
    private SpinnerService: NgxSpinnerService, private injector: Injector, public menuServices: MenuService) {
    this.clientName = localStorage.getItem("clientName");
    this.userEmailID = localStorage.getItem("userEmailID");
  }

  async ngOnInit() {
    this.ActiveForecastDiv = false;
    this.Newforecastselected = false;
    this.ActiveForecastConsolidationDiv = false;
    this.currentUserEmail = localStorage.getItem("userEmailID");
    let getCurrentuserName = localStorage.getItem('CurrentUserName');
    let accessTokens = localStorage.getItem('access_token');
    this.NodeId = localStorage.getItem('CurrentUserNodeId');
    this.clientName = localStorage.getItem("clientName");
    var systemCalc = localStorage.getItem("systemCalculation");
    var consolidaitonCalc = localStorage.getItem("consolidaitonCalculation");
    var importedForecast = localStorage.getItem("importedForecast");
    var ConsolidaitonForecast = localStorage.getItem("ConsolidaitonForecast");
    localStorage.setItem('ForecastName', '');
    if (systemCalc == "true") {
      this.systemCalculation = true;
    }
    if (consolidaitonCalc == "true") {
      this.consolidaitonCalculation = true;
    }

    this.SpinnerService.show();
    if (this.NodeId == 0 || this.NodeId == null) {
      this.listservice.getCurrentUsernodeId(getCurrentuserName).subscribe(
        (response: Forecastmodel[]) => {
          this.SpinnerService.hide();
          var UserId: any = response;
          localStorage.setItem('CurrentUserNodeId', UserId);
          this.NodeId = UserId;
        }
      )
    }
    let forecastNodeid = localStorage.getItem('ForecastId');
    if (forecastNodeid != "0") {
      localStorage.setItem('ForecastId', '');
      localStorage.setItem('IsActualStarted', '');
    }
    await this.delay(500);
    if (this.NodeId > 0) {
      this.SpinnerService.show();
      this.listservice.getForecastList(this.NodeId).subscribe(
        (response: Forecastmodel[]) => {
          this.SpinnerService.hide();
          var forecastImported: Forecastmodel = null;

          if (this.systemCalculation) {
            if (response.length > 0) {
              response.forEach(x => {
                if (x.name == importedForecast)
                  forecastImported = x;
              })
              if (forecastImported != null) {
                console.log("importedforecast", forecastImported);
                let firstForecastNodeid = forecastImported.nodeId;
                this.SpinnerService.show();
                this.menuServices.SystemRecordRecalu(firstForecastNodeid).subscribe(
                  (response => {
                    this.SpinnerService.hide();
                    localStorage.setItem("systemCalculation", "false");
                  })
                )
              }

            }

          }

          if (this.consolidaitonCalculation) {
            if (response.length > 0) {
              response.forEach(x => {
                if (x.nodeId.toString() == ConsolidaitonForecast)
                  forecastImported = x;
              })
              if (forecastImported != null) {
                console.log("ConsolidaitonForecast", ConsolidaitonForecast);
                let firstForecastNodeid = forecastImported.nodeId;
                this.SpinnerService.show();
                this.menuServices.recalcConsolidation(firstForecastNodeid).subscribe(
                  (response => {
                    this.SpinnerService.hide();

                  })
                )
              }

            }
            localStorage.setItem("consolidaitonCalculation", "false");
          }
          let UserGroupId = localStorage.getItem('CurrentUserNodeId');
          this.listservice.getCurrentUserGroup(UserGroupId).subscribe(
            (getuserGroupName => {
              this.record = response;
              let forecastListCollection = response;
              this.forecastlist = [];
              forecastListCollection.forEach(element => {
                if (element.owner.toLowerCase() == this.currentUserEmail.toLowerCase()) {
                  this.currentUserGroup = element.group;
                }
              });
              forecastListCollection.forEach(element => {
                if (this.currentUserGroup != 'AdminGroup') {
                  if (element.owner.toLowerCase() == this.currentUserEmail.toLowerCase()) {
                    this.forecastlist.push(element);
                  }
                } else {
                  this.forecastlist.push(element);
                }
              });
              console.log("forecastlist", forecastListCollection);
            })
          )
        })
    }
    localStorage.setItem("Treeviews", '');
    localStorage.removeItem("treeview");

  }

  async ngOnChange() {
    this.ActiveForecastDiv = false;
    this.ActiveForecastConsolidationDiv = false;
    let getCurrentuserName = localStorage.getItem('CurrentUserName');
    this.currentUserEmail = localStorage.getItem("userEmailID");
    let accessTokens = localStorage.getItem('access_token');
    this.NodeId = localStorage.getItem('CurrentUserNodeId');
    this.clientName = localStorage.getItem("clientName");
    if (this.NodeId == 0 || this.NodeId == null) {
      this.listservice.getCurrentUsernodeId(getCurrentuserName).subscribe(
        (response: Forecastmodel[]) => {
          var UserId: any = response;
          localStorage.setItem('CurrentUserNodeId', UserId);
          this.NodeId = UserId;
        }
      )
    }

    await this.delay(500);
    if (this.NodeId > 0) {
      this.SpinnerService.show();
      this.listservice.getForecastList(this.NodeId).subscribe(
        (response: Forecastmodel[]) => {
          this.SpinnerService.hide();
          let UserGroupId = localStorage.getItem('CurrentUserNodeId');
          this.listservice.getCurrentUserGroup(UserGroupId).subscribe(
            (getuserGroupName => {
              this.record = response;
              let forecastListCollection = response;
              this.forecastlist = [];
              forecastListCollection.forEach(element => {
                if (element.owner == this.currentUserEmail) {
                  this.currentUserGroup = element.group;
                }
              });
              forecastListCollection.forEach(element => {
                if (this.currentUserGroup != 'AdminGroup') {
                  if (element.owner == this.currentUserEmail) {
                    this.forecastlist.push(element);
                  }
                } else {
                  this.forecastlist.push(element);
                }
              });
            })
          )
        })
    }
  }
  getForecastList() {
    let getCurrentuserName = localStorage.getItem('CurrentUserName');
    let accessTokens = localStorage.getItem('access_token');
    this.NodeId = localStorage.getItem('CurrentUserNodeId');
    if (this.NodeId == 0 || this.NodeId == null) {
      this.listservice.getCurrentUsernodeId(getCurrentuserName).subscribe(
        (response: Forecastmodel[]) => {
          var UserId: any = response;
          localStorage.setItem('CurrentUserNodeId', UserId);
          this.NodeId = UserId;
        }
      )
    }
    if (this.NodeId > 0) {
      this.listservice.getForecastList(this.NodeId).subscribe(
        (response: Forecastmodel[]) => {
          let UserGroupId = localStorage.getItem('CurrentUserNodeId');
          this.listservice.getCurrentUserGroup(UserGroupId).subscribe(
            (getuserGroupName => {
              this.record = response;
              let forecastListCollection = response;
              this.forecastlist = [];
              forecastListCollection.forEach(element => {
                if (element.owner == this.currentUserEmail) {
                  this.currentUserGroup = element.group;
                }
              });
              forecastListCollection.forEach(element => {
                if (this.currentUserGroup != 'AdminGroup') {
                  if (element.owner == this.currentUserEmail) {
                    this.forecastlist.push(element);
                  }
                } else {
                  this.forecastlist.push(element);
                }
              });
            })
          )
        })

    }
  }

  forecastDetail(row) {
    this.openforecastdetail(row.data.nodeId, row.data.name, row.data.isConsolidation, row.data.startdate, row.data);
  }

  newforecast() {
    this.ActiveForecastDiv = true;
    this.Newforecastselected = true;
    var newforecastlistdiv: any = (<HTMLInputElement>document.getElementById("newforecastlist"));
    newforecastlistdiv.classList.remove("hidden");
    var listingdatalist: any = (<HTMLInputElement>document.getElementById("listingdata"));

    //listingdatalist.classList.add('hidden');

  }
  copyforecast() {
    this.SpinnerService.show();
    this.listservice.copyForecast(this.grid1.selectedRows[0]).subscribe(
      (response: boolean) => {
        if (response) {
          this.SpinnerService.hide();
          window.location.reload();
        }
      });

  }
  deleteForecastFromList() {
    if (this.grid1.selectedRows[0] != null)
      this.forecastDeleteFromList(this.grid1.selectedRows[0]);
  }
  forecastDeleteFromList(NodeId) {
    if (NodeId != '' && NodeId != null) {
      this.SpinnerService.show();
      this.listservice.DeleteForecast(NodeId).subscribe(
        ((response: any) => {
          this.SpinnerService.hide();
          this.ngOnChange();
        })
      )
    }
  }
  exportforecast() {
    let forecastID = this.grid1.selectedRows[0];
    let forecastdetailsindex = this.grid1.data.findIndex((r) => r.nodeId == forecastID);
    let forecastDetails = this.grid1.getRowByKey(forecastID);
    console.log("forecastDetails", forecastDetails, forecastdetailsindex);
    let forecastname = forecastDetails.data.name;
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    let ClientId = localStorage.getItem('clientID');
    this.SpinnerService.show();
    this.listservice.exportForecast(forecastID, CurrentUserID, ClientId).subscribe(
      ((response: any) => {
        // this.downloadFile(response);

        this.SpinnerService.hide();
        this.downloadFile(response, forecastname);


      })
    )
  }

  printOTR() {
    this._router.navigateByUrl('/PrintOTR');
    // console.log("forecastsSelected OTR", forecastsSelected);
    // var OTRReport = (<HTMLDialogElement>document.getElementById("OTRReport"));
    // OTRReport.open;
  }
  private downloadFile = (data: string, forecastname: string) => {
    console.log("downloadfile", data);
    const downloadedFile = new Blob([data], { type: 'text/xml' });
    console.log("downloadedFile 2", downloadedFile);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = forecastname + '.xml';
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  importForecast() {

  }
  copyforecastNoValues() {
    this.SpinnerService.show();
    this.listservice.copyForecastNovalues(this.grid1.selectedRows[0]).subscribe(
      (response: boolean) => {
        if (response) {
          this.SpinnerService.hide();
          window.location.reload();
        }
      });
  }
  handleFileInput = (files: FileList) => {
    const notifier = this.injector.get(NotificationService);
    this.SpinnerService.show();
    let fileToUpload = files.item(0);
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    let ClientId = localStorage.getItem('clientID');
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('CurrentUserID', CurrentUserID);
    formData.append('ClientId', ClientId);
    this.listservice.importForecast(formData).subscribe(
      (response: any) => {
        //this.delay(5000);
        console.log("File upload response", response);
        if (response instanceof HttpResponse) {
          var forecastName = response.body;
          this.SpinnerService.hide();
          this.systemCalculation = true;
          localStorage.setItem("systemCalculation", "true");
          localStorage.setItem("importedForecast", forecastName);
          notifier.showSuccess("Forecast Imported Successfully.")
          this._router.navigateByUrl('/forecast');
        }
      })
  }
  newConsolidation() {
    this.ActiveForecastConsolidationDiv = true;
    var newConsolidationdiv: any = (<HTMLInputElement>document.getElementById("newConsolidationCreate"));
    newConsolidationdiv.classList.remove("hidden");
    //var listingdatalist: any = (<HTMLInputElement>document.getElementById("listingdata"));
    //listingdatalist.classList.add('hidden');
  }
  activelistview() {
    var listingview: any = (<HTMLInputElement>document.getElementById("listview"));
    listingview.classList.remove("hidden");
    var gridview: any = (<HTMLInputElement>document.getElementById("gridview"));
    gridview.classList.add('hidden');

    var gridview: any = (<HTMLInputElement>document.getElementById("listgirdviewchangelayout"));
    gridview.classList.remove('col-sm-12');

    var gridview: any = (<HTMLInputElement>document.getElementById("listgirdviewchangelayout"));
    gridview.classList.add('col-sm-12');


    var GridandListIcon: any = (<HTMLInputElement>document.getElementById("GridandListIcon"));
    GridandListIcon.classList.remove("hidden");



    var listactive: any = (<HTMLInputElement>document.getElementById("listviewactivebutton"));
    listactive.classList.add("active");
    var girdactive: any = (<HTMLInputElement>document.getElementById("gridviewactivebutton"));
    girdactive.classList.remove("active");
  }

  activegridview() {
    var listingview: any = (<HTMLInputElement>document.getElementById("listview"));
    listingview.classList.add("hidden");
    var gridview: any = (<HTMLInputElement>document.getElementById("gridview"));
    gridview.classList.remove('hidden');

    var gridview: any = (<HTMLInputElement>document.getElementById("listgirdviewchangelayout"));
    gridview.classList.remove('col-sm-12');

    var gridview: any = (<HTMLInputElement>document.getElementById("listgirdviewchangelayout"));
    gridview.classList.add('col-sm-12');
    var GridandListIcon: any = (<HTMLInputElement>document.getElementById("GridandListIcon"));
    GridandListIcon.classList.add("hidden");

    var listactive: any = (<HTMLInputElement>document.getElementById("listviewactivebutton"));
    listactive.classList.remove("active");

    var girdactive: any = (<HTMLInputElement>document.getElementById("gridviewactivebutton"));
    girdactive.classList.add("active");
  }

  clossnewforecasttab() {
    var newforecastlistdiv: any = (<HTMLInputElement>document.getElementById("newforecastlist"));
    newforecastlistdiv.classList.add("hidden");
  }

  closenewconsolidationtab() {
    var newConsolidationCreatediv: any = (<HTMLInputElement>document.getElementById("newConsolidationCreate"));
    newConsolidationCreatediv.classList.add("hidden");
  }

  async openforecastdetail(NodeId, Nodename, isConsolidation, startdate, listing) {
    localStorage.setItem('ForecastId', NodeId);
    localStorage.setItem('ForecastName', Nodename);
    localStorage.setItem('isConsolidation', isConsolidation);
    localStorage.setItem('Forecaststartdate', startdate);
    localStorage.setItem("CountryName", listing.countryName);
    localStorage.setItem("IsActualStarted", listing.actualStarted);
    localStorage.setItem("Companyname", listing.companyname)
    localStorage.setItem("IntegrationActive","");

    if (listing.actualStarted) { 
      localStorage.setItem('IsCanEnterActual', 'true');
      localStorage.setItem('IsCanStartActual', 'false');
    } else {
      localStorage.setItem('IsCanEnterActual', 'false');
      localStorage.setItem('IsCanStartActual', 'true');
    }
    localStorage.setItem("", listing.actualStarted)
    console.log(listing);

    this.SpinnerService.show();
    if (NodeId != null && listing.countryName!=null) {
      this.listservice.getCountryVersionDetails(NodeId, listing.countryName).subscribe(
        ((response: any) => {
        })
      )
    }
    if (NodeId != null && !isConsolidation) {
      this.SpinnerService.show();
      this.listservice.getIntegrationDetails(NodeId).subscribe(
        ((response: any) => {
          this.SpinnerService.hide();
          localStorage.setItem("Firsttimeload", NodeId);
          this.CanStartActual();
          this.CanEnterActual();
          localStorage.setItem("Integration", response);
          this._router.navigateByUrl('/forecast');
        })
      )
    }
    else {
      localStorage.setItem("Firsttimeload", NodeId);
      this.SpinnerService.hide();
      this.CanStartActual();
      this.CanEnterActual();

      this._router.navigateByUrl('/forecast');
    }

    // //await this.delay(900);


  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  CanStartActual() {
    let forecastNodeId = localStorage.getItem("ForecastId");
    if (forecastNodeId != null) {
      this.enterActualsService.canstartActual(forecastNodeId).subscribe
        ((response: any) => {
          localStorage.setItem('IsCanStartActual', response);
          //return response;
        })
    }
  }

  CanEnterActual() {
    let forecastNodeId = localStorage.getItem("ForecastId");
    if (forecastNodeId != null) {
      this.enterActualsService.canEnterActual(forecastNodeId).subscribe
        ((response: any) => {
          localStorage.setItem('IsCanEnterActual', response);
          // return response;
        })
    }
  }
  deleteforecast(NodeId, DeleteForecastForm) {
    if (NodeId != '' && NodeId != null) {
      this.SpinnerService.show();
      this.listservice.DeleteForecast(NodeId).subscribe(
        ((response: any) => {
          this.SpinnerService.hide();
          DeleteForecastForm.close();
          this.ngOnChange();
        })
      )
    }
  }
}
