import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TreeviewService } from '../../../service/treeview.service';
import { Treeview } from '../../../service/treeview';
import { TreeModel, TreeNode } from '@ali-hm/angular-tree-component';
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from '../../../service/menu.service';
import { FormulaComponent } from '../formula.component';
declare const $: any;

import { ITreeState, ITreeOptions } from '@ali-hm/angular-tree-component';
import { v4 } from 'uuid';

import {
  ConnectedPositioningStrategy,
  IgxDropDownComponent,
  IgxInputGroupComponent
} from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-formulatree-view',
  templateUrl: './formulatree-view.component.html',
  styleUrls: ['./formulatree-view.component.scss']
})
export class FormulatreeViewComponent {

  @Input() Formulatree;
  @Input() formulavaluesNodeId;
  @Output() FormulaeventClicked = new EventEmitter<Event>();
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  options: ITreeOptions = {
    getNodeClone: (node) => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`
    })
  };
  Treeviews: Treeview[] = [];
  nodes = [];
  data = '';
  //@Output() eventClicked = new EventEmitter<Event>();

  @ViewChild('tree') clickroot: ElementRef<HTMLElement>;
  SelectedTreenodeEvent: boolean;
  constructor(private treeview: TreeviewService,
    public FormulaComponent:FormulaComponent,
    public menuServices: MenuService,
    private SpinnerService: NgxSpinnerService) {
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  @ViewChild('tree') tree;

  Expandmenu() {
    this.tree.treeModel.expandAll();
  }
  ngOnInit() {
    if (this.Formulatree != undefined && this.formulavaluesNodeId != undefined) {

      let ApiCallFormulaTree = false;
      let storedFormualTree = localStorage.getItem("FormulaRecordTree");
      if(storedFormualTree!=null && storedFormualTree!="null"){
        let jsonstoredFormualTree = JSON.parse(storedFormualTree);
        if(jsonstoredFormualTree!=null){
          this.Treeviews = jsonstoredFormualTree;
          ApiCallFormulaTree = true;
          this.nodes = [this.Treeviews];
          this.options = {}
        }
      }
      this.SelectedTreenodeEvent = false;
      if(!ApiCallFormulaTree){ 
        this.FormulaComponent.loadingFormulaTreeView = true;
        this.SpinnerService.show();
        this.treeview.getFormulaTreeMenu(this.formulavaluesNodeId).subscribe(
          (response: Treeview[]) => { 
            this.FormulaComponent.loadingFormulaTreeView = false;
            this.SpinnerService.hide();
            this.Treeviews = response;
            this.nodes = [this.Treeviews];
            this.options = {}
          }
        );
      }
     
      
    }
  }
  ngOnChanges() {
    // if (this.Formulatree != undefined) {
    //   this.ngOnInit();
    // }

  }
  ActiveEvent(event) {
    // console.log("ActiveEvent", event.node.data);
    this.FormulaeventClicked.emit(event.node.data);
  }
  onUpdateData() {
    const treeModel = this.tree.treeModel;
    treeModel.expandAll()
  }

  async onTreeLoad() {
    await this.delay(800);
    this.tree.treeModel.expandAll();
  }
  filterFn(value: string, treeModel: TreeModel) {
    this.tree.treeModel.filterNodes((node: TreeNode) => this.searchTree(value, node.data.name));
  }

  searchTree(needle: string, haystack: string) {
    if (haystack != null) {
      const haystackLC = haystack.toLowerCase();
      const needleLC = needle.toLowerCase();

      const hlen = haystack.length;
      const nlen = needleLC.length;

      if (nlen > hlen) {
        return false;
      }
      if (nlen === hlen) {
        return needleLC === haystackLC;
      }
      outer: for (let i = 0, j = 0; i < nlen; i++) {
        const nch = needleLC.charCodeAt(i);

        while (j < hlen) {
          if (haystackLC.charCodeAt(j++) === nch) {
            continue outer;
          }
        }
        return false;
      }
      return true;
    }

  }
}
