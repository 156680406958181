<div>
    <div>
        <h3>Preferences Setting ( Report Settings & Security )</h3>
    </div>

    <div class="col-sm-12" style="margin-top:1%; height: 648px;">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-5" style="border-right: 3px solid #eaeeef">
            <div class="box-content">
                <h4>Courtesy Message</h4>
                <div class="" *ngIf="PreferencesCollection!=''">
                    <igx-select [(ngModel)]="PreferencesCollection.messageSourceVM">
                        <igx-select-item class="testingdata"
                            *ngFor="let results of PreferencesCollection.validCourtesyMessageSourcesVM"
                            [value]="results.value" (click)='CourtesyMessageSourcesClickEvent(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
            </div>


            <div class="box-content">
                <h4>Message</h4>
                <textarea cols="50" rows="6" (change)="setMessage($event)">{{PreferencesCollection.message}}</textarea>
            </div>

            <div class="box-content">
                <h4>Zero Rows</h4>
                <input type="checkbox" [checked]="PreferencesCollection.hideZeroRows"
                    (change)="hideZeroRowsEvent($event)"> Supress Zero Rows
            </div>

            <div class="box-content">
                <h4>Export Record Description with PnL</h4>
                <input type="checkbox" [checked]="PreferencesCollection.exportDescriptionWithPnl"
                    (change)="exportDescriptionWithPnlEvent($event)"> Export Record Description
            </div>

            <div class="box-content">
                <h4>Move Fixed Assets Gains/Losses to Depreciation Destination</h4>
                <input type="checkbox" [checked]="PreferencesCollection.allocateGainsLossesToDepreciationDest"
                    (change)="allocateGainsLossesToDepreciationDestEvent($event)"> Move Gains/Losses to Depreciation
                Destination
            </div>
        </div>
        <div class="col-sm-4">
            <div class="box-content">
                <h4 style="margin: 0 0 14px 0;">Show Loan Balances</h4>
                <div class="" *ngIf="PreferencesCollection!=''">
                    <igx-select [(ngModel)]="PreferencesCollection.selectedShowLoanBalancesVM">
                        <igx-select-item *ngFor="let results of PreferencesCollection.validShowLoanBalancesVM"
                            [value]="results.value" (click)='validShowLoanBalancesVM(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
            </div>
            <div class="box-content">
                <h4 style="margin: 0 0 14px 0;">Stock On Hand Cost Per Unit Timing</h4>
                <div class="" *ngIf="PreferencesCollection!=''" style="margin: -13px 0 0 0;">
                    <igx-select [(ngModel)]="PreferencesCollection.selectedStockOnHandCostPerUnitMonthTimingVM">
                        <igx-select-item
                            *ngFor="let results of PreferencesCollection.validStockOnHandCostPerUnitMonthTimingVM"
                            [value]="results.value" (click)='validStockOnHandCostPerUnitMonthTimingVM(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
            </div>
            <div class="box-content">
                <h4 style="margin: 0 0 14px 0;">Weekend Payment Day</h4>
                <div class="" *ngIf="PreferencesCollection!=''">
                    <igx-select [(ngModel)]="PreferencesCollection.selectedWeekendPaymentDayVM">
                        <igx-select-item *ngFor="let results of PreferencesCollection.validWeekendPaymentDayVMs"
                            [value]="results.value" (click)='validWeekendPaymentDayVMs(results)'>
                            {{results.name}}
                        </igx-select-item>
                    </igx-select>
                </div>
            </div>
            <div class="box-content">
                <h4>Separate Cash Receipts by Sections</h4>
                <input type="checkbox" [checked]="PreferencesCollection.separateCashReceiptsBySections"
                    (change)="SetSeparateCashReceiptsBySections($event)"> Separate Cash Receipts by Sections
            </div>
        </div>
    </div>
    <div class="button-sample row col" style="float: right;">
        <button class="btn OkSaveButtonColor col-sm-1" (click)="SettingSave()"
            style="font-size: 16px;margin: 0px 7px 0 0;">Save</button>
        <button class="btn SettingCancelButtonColor col-sm-1" (click)="SettingClose()"
            style="font-size: 16px;margin: 0px 7px 0 7px;">Close</button>

    </div>
</div>