<!-- <div class="sample-column">
    <igx-navbar [isActionButtonVisible]="true">
        <div igxNavbarTitle>
            <a href="/Client">
                <img src="../../../assets/FullBrandLogowebsite.png" width="120px" height="50px" alt
                    style="margin-top: 7px;">
            </a>
            <div class="headermenu" *ngIf="displaySettingMenus">
                <div class="">
                    <button class="btn SettingCancelButtonColor" style="    margin: 4px 0 0 10px;
                    background: dodgerblue !important;
                    border: 1px solid;
                    border-radius: 10px;" (click)="openForecastList()">Forecast List</button>
                </div>
            </div>
            <div class="headermenu" >
                <div class="">
                    <button class="btn SettingCancelButtonColor" [ngClass]="{'hide':forecastname == null ||  forecastname == ''}" (click)="openForecast()" *ngIf="displaySettingMenus" 
                    style="margin: 4px 0 0 10px; background: dodgerblue !important; border: 1px solid; border-radius: 10px;">Forecast Name: {{forecastname}}</button>
                </div>
            </div>
            <div class="integrationstatus" *ngIf="displayintegrationstatus && displayintegrationValue!=null">
                <button class="btn SettingCancelButtonColor {{displayintegrationValue}}" 
                    style="margin: 4px 0 0 10px; background: dodgerblue !important; border: 1px solid; border-radius: 10px;"
                    *ngIf="displayintegrationValue!='' && displayintegrationstatus ">{{displayintegrationValue}}</button>
            </div>
        </div>
        <div class="user_profile icon" *ngIf="initials!=''">
            <igx-avatar igxavatar="raised" #button initials={{initials}} [igxToggleAction]="toggleRef"
                shape="circle" size="medium" class="initials">
            </igx-avatar>

            <div class="toggle-content" igxToggle #toggleRef="toggle">
                <section class="toggle-section">
                    <a class="dropdown-item" href="#">Dashboard</a>
                    <a class="dropdown-item" href="#">Edit Profile</a>
                    <a class="dropdown-item" (click)="logout()">Log Out</a>
                </section>
            </div>


        </div>
    </igx-navbar>
</div>
<div class="testingdataremoveheader" *ngIf="isForecastOpened && displaySettingHeader" style="width:10%;display: inline-block;z-index: 100;position: fixed;
right: 40px; margin-top: 1px;">
    <app-rightsidebar-menu></app-rightsidebar-menu>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="z-index: 999999;">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>

<div class="test-{{DisplayUpgradePackage}} hide" >
    <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
        <button class="form-control hide" id="UpgradePackageevent"
        style="display: inline-block;width: fit-content;" (click)="UpgradePackage.open()">Upgrade</button>
        <div>
            <igx-dialog #UpgradePackage [closeOnOutsideSelect]="true">
                <div class="insidecontainer" style="width: 1220px;height: 675px; overflow: auto;">
                    <igx-dialog-title class="dialogtitle">
                        <div class="title-container">
                            <div class="dialog-title">Upgrade Licence</div>
                        </div>
                    </igx-dialog-title>
                    <div class="col-sm-12">
                        Upgrade the Packages
                        <app-license-purchase [UpgradeDialog]="true"></app-license-purchase>
                    </div>
                </div>
                <div igxDialogActions>
                    <button igxButton (click)="closePopupEvent(UpgradePackage)"
                        class="deleteCancelButtonColor">Close</button>
                </div>
            </igx-dialog>
        </div>
    </div>
</div> -->



<div class="bg-blue-50 z-10 relative h-24" style="height: 39px;">
    <nav class="flex flex-wrap items-center justify-between  p-4" style="padding: 0.5rem !important;">
      <div class="flex items-center flex-shrink-0 text-white mr-6" style="margin-top:-13px;">
        <a href="/Client" class="text-white no-underline">
          <img
            src="../../../assets/logo.png"
            alt="Forecast 5"
            class="h-12 fill-current inline"
          />
        </a>
      </div>
  
      <div class="flex flex-row w-full md:w-auto items-center">
        <p class="text-black mt-1 md:mt-0 ml-2 md:ml-3">
          <span class="uppercase text-xs text-gray-400 md:block"
            >Selected client:</span>
          <a class="hover:underline" style="color: black;font-weight: 600; cursor: pointer;" *ngIf="clientName!=''"  (click)="openForecastList()"
            >{{clientName}}</a>
        </p>
        <p class="text-black mt-1 md:mt-0 ml-2 md:ml-3" style="margin-left: 20px !important;"
          *ngIf="displaySettingMenus">
          <span class="uppercase text-xs text-gray-400 md:block">Current forecast:</span>
          <a class="hover:underline" style="color: black;font-weight: 600; cursor: pointer;" (click)="openForecast()" *ngIf="forecastname">{{ forecastname }}</a>
        </p>

        <p class="text-black mt-1 md:mt-0 ml-2 md:ml-3"  *ngIf="displayintegrationstatus && displayintegrationValue" style="margin-left: 20px !important;">
            <span class="uppercase text-xs text-gray-400 md:block"
              >Xero Integration:</span>
            <a class="hover:underline" style="color: black;font-weight: 600;cursor: pointer;" *ngIf="displayintegrationValue != ''" 
              >{{displayintegrationValue}}</a>
          </p>

        <!-- <div class="integrationstatus" *ngIf="displayintegrationstatus && displayintegrationValue">
        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full"
          *ngIf="displayintegrationValue != ''">
          {{ displayintegrationValue }}
        </button>
      </div> -->

      </div>

      <div class="flex items-right ml-auto">
          <div class="user_profile icon" *ngIf="initials!=''">
            <igx-avatar igxavatar="raised" #button initials={{initials}} [igxToggleAction]="toggleRef"
                shape="circle" size="medium" class="initials">
            </igx-avatar>

            <div class="toggle-content" igxToggle #toggleRef="toggle">
                <section class="toggle-section">
                    <a class="dropdown-item" href="#">Dashboard</a>
                    <a class="dropdown-item" href="#">Edit Profile</a>
                    <a class="dropdown-item" (click)="logout()">Log Out</a>
                </section>
            </div>
        </div>
      </div>
    </nav>
  
    
  </div>
<!-- <div class="testingdataremoveheader" *ngIf="isForecastOpened && displaySettingHeader" style="width:10%;display: inline-block;z-index: 100;position: fixed;
right: 40px; margin-top: 1px;">
    <app-rightsidebar-menu></app-rightsidebar-menu>
</div> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="z-index: 999999;">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>

<div class="test-{{DisplayUpgradePackage}} hide" >
    <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
        <button class="form-control hide" id="UpgradePackageevent"
        style="display: inline-block;width: fit-content;" (click)="UpgradePackage.open()">Upgrade</button>
        <div>
            <igx-dialog #UpgradePackage [closeOnOutsideSelect]="true">
                <div class="insidecontainer" style="width: 1220px;height: 675px; overflow: auto;">
                    <igx-dialog-title class="dialogtitle">
                        <div class="title-container">
                            <div class="dialog-title">Upgrade Licence</div>
                        </div>
                    </igx-dialog-title>
                    <div class="col-sm-12">
                        Upgrade the Packages
                        <app-license-purchase [UpgradeDialog]="true"></app-license-purchase>
                    </div>
                </div>
                <div igxDialogActions>
                    <button igxButton (click)="closePopupEvent(UpgradePackage)"
                        class="deleteCancelButtonColor">Close</button>
                </div>
            </igx-dialog>
        </div>
    </div>
</div>